import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import Form from "react-bootstrap/Form";
import moment from "moment/moment";
import "./editPost.css";
// import "../../EditModals/editStyle.css";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../JobPostings/style.css";
import { stateToHTML } from "draft-js-export-html";
import { Box } from "@mui/material";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";

const EditPosting = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [allstate, Setallstate] = useState(false);
  const [value, setValue] = useState(150000);
  const [education, Seteducation] = useState();
  const [stream, Setstream] = useState();
  const [degree, Setdergee] = useState();
  const [exp, Setexp] = useState();
  const [jobrole, Setjobrole] = useState();
  const [orgList, setOrgList] = useState([]);
  const [create, setCreate] = useState(false);
  const [jobTitle, setJobtitle] = useState([]);
  const [orgNameID, setOrgNameID] = useState("");
  const [newddEducation, setNewDDEducation] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [whatsappjd, setWhatsappjd] = useState("");
  const [addressChar, setAddressChar] = useState(200);
  const [roleId, setRoleId] = useState("SA");
  const [jobDes, setJobDes] = useState("");
  const reg_id = sessionStorage.getItem("register_id");
  const [age, setAge] = useState([18, 45]);
  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(64);
  const resDATA = sessionStorage.getItem("register_id");
  const [any, setAny] = useState(false);
  const [formDetails, setFormDetails] = useState({
    job_title: "",
    job_type: "",
    jobpostdt: "",
    jobvaliddt: "",
    qualification: "",
    salary: "",
    state: "",
    district: "",
    taluka: "",
    address: "",
    createdby: resDATA,
    course_id: "",
    start_date: "",
    fee: "",
    application: "",
    spoc_name: "",
    spoc_email: "",
    spoc_phone: "",
  });

  const jobType = [
    { label: "One Month", value: "One Month" },
    { label: "Four Months", value: "Four Months" },
    { label: "Six Months", value: "Six Months" },
  ];

  const salaryList = [
    { label: "Onsite", value: "Onsite" },
    { label: "Online", value: "Online" },
  ];

  const [maxAgeOption, setMaxAgeOption] = useState([]);

  const setOptions = (value) =>{
    const array = []
    for (let i = value; i <= 65; i++) {
      array.push({label:i, value:i});
    }
    setMaxAgeOption(array)
  }

  useEffect(() => {
    setJobDes(props.editdata?.jobdesc || "");
  }, [props.editdata]);

  useEffect(() => {
    setAge(props.editdata?.age ? props.editdata?.age : [18, 45]);
    setMinAge(
      props.editdata?.age === "" ||
        props.editdata?.age === undefined ||
        props.editdata?.age === null
        ? 18
        : props.editdata?.age.split(" ")[0]
    );
    setMaxAge(
      props.editdata?.age === "" ||
        props.editdata?.age === undefined ||
        props.editdata?.age === null
        ? 64
        : props.editdata?.age.split(" ")[props.editdata?.age.split(" ").length - 1]
    );
  }, [props.editdata]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleTexteditorChange = (value) => {
    setJobDes(value);
  };

  const handleCheckboxChange = (event) => {
    Setallstate(event.target.checked);
  };

  console.log(props.editdata, "agewala");

  useEffect(() => {
    setFormDetails({
      job_title:
        props.editdata?.Jobname === "" ||
        props.editdata?.Jobname === "nan" ||
        props.editdata?.Jobname === null ||
        props.editdata?.Jobname === undefined
          ? ""
          : props.editdata.Jobname,
      job_type:
        props.editdata?.Jobtype === "" ||
        props.editdata?.Jobtype === "nan" ||
        props.editdata?.Jobtype === null ||
        props.editdata?.Jobtype === undefined
          ? ""
          : props.editdata.Jobtype,
      jobpostdt: props.editdata?.date ? moment(props.editdata?.date).format("YYYY-MM-DD") : "",
      jobvaliddt:
        props.editdata?.jobvaliddt === null ||
        props.editdata?.jobvaliddt === "nan" ||
        props.editdata?.jobvaliddt === ""
          ? ""
          : moment(props.editdata?.jobvaliddt).format("YYYY-MM-DD HH:mm"),
      qualification:
        props.editdata?.qualification === "" ||
        props.editdata?.qualification === "nan" ||
        props.editdata?.qualification === null
          ? ""
          : props.editdata?.qualification,
      salary:
        props.editdata?.salary === "" ||
        props.editdata?.salary === "nan" ||
        props.editdata?.salary === null
          ? ""
          : props.editdata?.salary,
      state:
        props.editdata?.state === "" ||
        props.editdata?.state === "nan" ||
        props.editdata?.state === null
          ? ""
          : props.editdata?.state,
      district:
        props.editdata?.district === "" ||
        props.editdata?.district === "nan" ||
        props.editdata?.district === null
          ? ""
          : props.editdata?.district,
      taluka:
        props.editdata?.taluka === "" ||
        props.editdata?.taluka === "nan" ||
        props.editdata?.taluka === null
          ? ""
          : props.editdata?.taluka,
      address:
        props.editdata?.address === "" ||
        props.editdata?.address === "nan" ||
        props.editdata?.address === null
          ? ""
          : props.editdata?.address,
      createdby: resDATA,
      course_id:
        props.editdata?.course_id === "" ||
        props.editdata?.course_id === "nan" ||
        props.editdata?.course_id === null
          ? ""
          : props.editdata.course_id,
      start_date: props.editdata?.start_date
        ? moment(props.editdata?.start_date).format("YYYY-MM-DD")
        : "",
      fee:
        props.editdata?.fee === "" || props.editdata?.fee === "nan" || props.editdata?.fee === null
          ? ""
          : props.editdata.fee,
      application: "",
      spoc_name:
        props.editdata?.spoc_name === "" ||
        props.editdata?.spoc_name === "nan" ||
        props.editdata?.spoc_name === null
          ? ""
          : props.editdata.spoc_name,
      spoc_email:
        props.editdata?.spoc_email === "" ||
        props.editdata?.spoc_email === "nan" ||
        props.editdata?.spoc_email === null
          ? ""
          : props.editdata.spoc_email,
      spoc_phone:
        props.editdata?.spoc_phone === "" ||
        props.editdata?.spoc_phone === "nan" ||
        props.editdata?.spoc_phone === null
          ? ""
          : props.editdata.spoc_phone,
    });
  }, [props?.editdata]);

  const jobpostform = useFormik({
    enableReinitialize: true,
    initialValues: {
      job_title: formDetails.job_title,
      job_type: formDetails.job_type,
      jobpostdt: formDetails.jobpostdt,
      jobvaliddt: formDetails.jobvaliddt,
      qualification: formDetails.qualification,
      salary: formDetails.salary,
      state: formDetails.state,
      district: formDetails.district,
      taluka: formDetails.taluka,
      address: formDetails.address,
      createdby: resDATA,
      course_id: formDetails.course_id,
      start_date: formDetails.start_date,
      fee: formDetails.fee,
      application: formDetails.application,
      spoc_name: formDetails.spoc_name,
      spoc_email: formDetails.spoc_email,
      spoc_phone: formDetails.spoc_phone,
    },
    validationSchema: Yup.object().shape({
      job_title: Yup.string().required(" Please enter course name"),
      job_type: Yup.string().required(" Please enter duration in days"),
      //jobpostdt: Yup.string().required("Please select course post date"),
      jobvaliddt: Yup.string().required("Please select course valid till date"),
      start_date: Yup.string().required("Please select course start date"),
      qualification: Yup.string().required("Please select highest education"),
      salary: Yup.string().required("Please select mode of course"),
      state: Yup.string().required("Please select state"),
      district: Yup.string().required("Please select district"),
      taluka: Yup.string().required("Please enter taluka"),
      address: Yup.string().required("Please enter address"),
      fee: Yup.string().required("Please enter fee"),
      spoc_name: Yup.string().required("Please enter name"),
      spoc_email: Yup.string().required("Please enter email"),
      spoc_phone: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      application: Yup.mixed()
        // .required("Upload brochure")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
    }),
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("orgid", props.editdata?.orgid ? props.editdata?.orgid : "");
      formData.append("name", values.job_title);
      formData.append("duration", values.job_type);
      formData.append(
        "date_posting",
        props.editdata?.date
          ? moment(props.editdata?.date).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD")
      );
      formData.append("valid_till", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      formData.append("highest_qualification", values.qualification);
      formData.append("mode_of_course", values.salary.toLocaleLowerCase());
      formData.append("state", values.state);
      formData.append("district", values.district);
      formData.append("address", values.address);
      formData.append("taluka", values.taluka);
      formData.append("description", jobDes);
      formData.append("age_lower_range", any ? 18 : minAge ? minAge : 18);
      formData.append("age_upper_range", any ? 64 : maxAge ? maxAge : 64);
      formData.append("createdby", values.createdby);
      formData.append("brochure", values.application);
      formData.append("start_date", values.start_date);
      formData.append("fees", values.fee);
      formData.append("spoc_name", values.spoc_name);
      formData.append("spoc_email", values.spoc_email);
      formData.append("spoc_mobile", values.spoc_phone);
      formData.append("course_id", props.editdata?.course_id ? props.editdata?.course_id : "");
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .patch(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_register`, formData)
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Course has been updated successfully",
              showConfirmButton: false,
              timer: 3000,
              //   showDenyButton: true,
              // showCancelButton: true,
              //   confirmButtonText: "Add More",
              //   denyButtonText: `Search Candidate`,
            }).then(() => {
              props.Seteditadmin(false);
              props.handleOrgFunc();
              props.handleRoleFunc();
              setJobDes("");
              setAge([18, 45]);
              Setallstate(false);
              setWhatsappjd("");
            });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Something went wrong",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
          Setallstate(false);
          setWhatsappjd("");
          setJobDes("");
        });
    },
  });

  const ageOption = [
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 28 },
    { label: 29, value: 29 },
    { label: 30, value: 30 },
    { label: 31, value: 31 },
    { label: 32, value: 32 },
    { label: 33, value: 33 },
    { label: 34, value: 34 },
    { label: 35, value: 35 },
    { label: 36, value: 36 },
    { label: 37, value: 37 },
    { label: 38, value: 38 },
    { label: 39, value: 39 },
    { label: 40, value: 40 },
    { label: 41, value: 41 },
    { label: 42, value: 42 },
    { label: 43, value: 43 },
    { label: 44, value: 44 },
    { label: 45, value: 45 },
    { label: 46, value: 46 },
    { label: 47, value: 47 },
    { label: 48, value: 48 },
    { label: 49, value: 49 },
    { label: 50, value: 50 },
    { label: 51, value: 51 },
    { label: 52, value: 52 },
    { label: 53, value: 53 },
    { label: 54, value: 54 },
    { label: 55, value: 55 },
    { label: 56, value: 56 },
    { label: 57, value: 57 },
    { label: 58, value: 58 },
    { label: 59, value: 59 },
    { label: 60, value: 60 },
    { label: 61, value: 61 },
    { label: 62, value: 62 },
    { label: 63, value: 63 },
    { label: 64, value: 64 },
  ];

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_names`, {
        params: {
          orgid: props.editdata?.orgid ? props.editdata?.orgid : "",
        },
      })
      .then((res) => {
        setJobtitle(res?.data);
      });
  }, [props.editdata]);

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
        setNewDDEducation(res.data.map((ele) => ele.value));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        Setstream(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState === "" ? props.editdata?.state : selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOrgList = () => {
    const params = {
      partner: "samarth",
      type: "dropdown",
      // type: "detail",
    };
    const headers = {
      "access-control-allow-origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_List"]);
        setOrgList(res.data["Organisation_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchIndustryFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry`)
      .then((res) => {
        console.log("industry", res.data);
        setIndustryOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchStateList();
    fetchjobrole();
    fetchexp();
    fetchdegree();
    fetchstream();
    fetcheducation();
    fetchOrgList();
    fetchIndustryFunc();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState, props.editdata?.state]);

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };
  const TermSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#52af77",
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });

  const handleAgeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < 10) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 65 - 10);
        console.log("age slider:", [clamped, clamped + 10]);
        setAge([clamped, clamped + 10]);
      } else {
        const clamped = Math.max(newValue[1], 10);
        setAge(clamped >= 28 ? [clamped - 10, clamped] : [18, 28]);
      }
    } else {
      setAge(newValue);
    }
  };

  return (
    <>
      <Modal
        show={props?.editadmin}
        onHide={() => {
          props?.Seteditadmin(false);
          Setallstate(false);
          setAny(false);
        }}
        onClose={() => {
          Setallstate(false);
          setAny(false);
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Update Course Details
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                props?.Seteditadmin(false);
                Setallstate(false);
                setAny(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2}>
            <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
              <SoftBox
                component="form"
                role="form"
                width="100%"
                onSubmit={jobpostform.handleSubmit}
              >
                <Grid container>
                  <SoftBox mt={2} width="100%" height="auto">
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox width="100%" height="auto" mb={3}>
                        <SoftBox component="form" role="form" width="750px">
                          <SoftBox mb={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox
                                    width="100%"
                                    mb={1}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <SoftBox display="flex" flexDirection="row">
                                      <SoftTypography
                                        fontWeight="medium"
                                        fontSize="16px"
                                        lineHeight="20px"
                                        mb={1}
                                      >
                                        Course Name
                                      </SoftTypography>
                                      <SoftBox
                                        sx={{
                                          color: "red",
                                          position: "inherit",
                                          paddingLeft: "2px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <sup>*</sup>
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      flexDirection="row"
                                      gap="10px"
                                      onClick={() => {
                                        setCreate(!create);
                                      }}
                                    >
                                      <Tooltip
                                        key="2"
                                        title={
                                          !create ? `Create New Job Title` : `Select From Existing `
                                        }
                                        placement="bottom"
                                      >
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15px"
                                            height="15px"
                                            viewBox="0 0 10 10"
                                            fill="none"
                                          >
                                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                                            <path
                                              d="M5 2V8M2 5H8"
                                              stroke="white"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </SoftBox>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                  {create ? (
                                    <SoftInput
                                      type="text"
                                      placeholder="Job title"
                                      name="job_title"
                                      id="job_title"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.job_title}
                                    />
                                  ) : (
                                    <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                      <SoftSelect
                                        placeholder="Please enter job title"
                                        options={jobTitle}
                                        onChange={
                                          (e) => jobpostform.setFieldValue("job_title", e.label)
                                          // setOrgNameID(e.value)
                                        }
                                        value={{ label: jobpostform.values.job_title }}
                                      />
                                    </SoftBox>
                                  )}
                                  {jobpostform.touched.job_title && jobpostform.errors.job_title ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.job_title}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={2}
                                    >
                                      Course Duration
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftInput
                                      type="number"
                                      placeholder="Enter number of days"
                                      name="job_type"
                                      id="job_type"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.job_type}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.job_type && jobpostform.errors.job_type ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.job_type}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Valid Till
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="datetime-local"
                                    placeholder=""
                                    name="jobvaliddt"
                                    id="jobvaliddt"
                                    onChange={jobpostform.handleChange}
                                    value={jobpostform.values.jobvaliddt}
                                  />
                                  {jobpostform.touched.jobvaliddt &&
                                  jobpostform.errors.jobvaliddt ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.jobvaliddt}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              {/*
                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Date of Posting
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="date"
                                    placeholder=""
                                    name="jobpostdt"
                                    id="jobpostdt"
                                    onChange={jobpostform.handleChange}
                                    value={jobpostform.values.jobpostdt}
                                  />
                                  {jobpostform.touched.jobpostdt && jobpostform.errors.jobpostdt ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.jobpostdt}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              */}

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Course Start Date
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="date"
                                    placeholder=""
                                    name="start_date"
                                    id="start_date"
                                    onChange={jobpostform.handleChange}
                                    value={jobpostform.values.start_date}
                                  />
                                  {jobpostform.touched.start_date &&
                                  jobpostform.errors.start_date ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.start_date}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Course Fee
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftInput
                                      type="number"
                                      placeholder="Enter course fee"
                                      name="fee"
                                      id="fee"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.fee}
                                    />
                                  </SoftBox>

                                  {jobpostform.touched.fee && jobpostform.errors.fee ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.fee}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Highest Education
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={education}
                                      onChange={(e) =>
                                        jobpostform.setFieldValue("qualification", e.value)
                                      }
                                      value={{ label: jobpostform.values.qualification }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.qualification &&
                                  jobpostform.errors.qualification ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.qualification}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Mode of Course
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={salaryList}
                                      placeholder="Select mode of course"
                                      onChange={(e) => {
                                        jobpostform.setFieldValue("salary", e.label);
                                      }}
                                      value={{ label: jobpostform.values.salary }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.salary && jobpostform.errors.salary ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.salary}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      State
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={state}
                                      onChange={(currentTarget) => (
                                        setSelectedState(currentTarget.value),
                                        !allstate
                                          ? jobpostform.setFieldValue("state", currentTarget.value)
                                          : ""
                                      )}
                                      value={{ label: jobpostform.values.state }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.state && jobpostform.errors.state ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.state}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      District
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={district}
                                      onChange={(e) =>
                                        !allstate
                                          ? jobpostform.setFieldValue("district", e.value)
                                          : ""
                                      }
                                      value={{ label: jobpostform.values.district }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.district && jobpostform.errors.district ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.district}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Taluka
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftInput
                                      type="text"
                                      placeholder="Enter taluka"
                                      name="taluka"
                                      id="taluka"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.taluka}
                                    />
                                  </SoftBox>

                                  {jobpostform.touched.taluka && jobpostform.errors.taluka ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.taluka}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Address
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftInput
                                      type="text"
                                      placeholder="Enter Address"
                                      name="address"
                                      id="address"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.address}
                                    />
                                  </SoftBox>

                                  {jobpostform.touched.address && jobpostform.errors.address ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.address}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Upload Brochure
                                    </SoftTypography>
                                    {/* <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox> */}
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <input
                                      className="form-control"
                                      type="file"
                                      accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                      id="application"
                                      name="application"
                                      onChange={(e) => {
                                        jobpostform.setFieldValue("application", e.target.files[0]);
                                      }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.application &&
                                  jobpostform.errors.application ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.application}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="column">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Age
                                      {/* : {`${age[0]} - ${age[1]}`} */}
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        background: "#fff",
                                        borderRadius: "0.5rem",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                      }}
                                    >
                                      <Select
                                        placeholder="Min Age"
                                        isDisabled={any}
                                        styles={stylesDD}
                                        options={ageOption}
                                        value={
                                          minAge !== ""
                                            ? {
                                                label: minAge,
                                                value: minAge,
                                              }
                                            : null
                                        }
                                        onChange={
                                          (currentTarget) =>{
                                            // setSelectedState(currentTarget.value),
                                            // !allstate
                                            //   ? jobpostform.setFieldValue("state", currentTarget.value)
                                            //   : ""
                                            setMaxAge("")
                                            setMinAge(currentTarget ? currentTarget.value : "");
                                            setMinAge(currentTarget ? currentTarget.value : "");
                                            currentTarget && setOptions(currentTarget.value) ;
                                            !currentTarget && setMaxAgeOption([]);
                                            // if( maxAge < minAge) setMaxAge("")

                                          // !allstate
                                          //   ? jobpostformone.setFieldValue(
                                          //       "state",
                                          //       currentTarget ? currentTarget.value : ""
                                          //     )
                                          //   : ""
                                        }}
                                      />
                                      <Select
                                        placeholder="Max Age"
                                        isDisabled={any}
                                        styles={stylesDD}
                                        value={
                                          maxAge !== ""
                                            ? {
                                                label: maxAge,
                                                value: maxAge,
                                              }
                                            : null
                                        }
                                        options={maxAgeOption}
                                        onChange={
                                          (currentTarget) =>
                                            // setSelectedState(currentTarget.value),
                                            // !allstate
                                            //   ? jobpostform.setFieldValue("state", currentTarget.value)
                                            //   : ""
                                            setMaxAge(currentTarget ? currentTarget.value : "")
                                          // !allstate
                                          //   ? jobpostformone.setFieldValue(
                                          //       "state",
                                          //       currentTarget ? currentTarget.value : ""
                                          //     )
                                          //   : ""
                                        }
                                      />
                                      <SoftBox
                                        sx={{
                                          border: !any ? "1px solid #e4e4e4" : "1px solid #87c4ff",
                                          borderRadius: "8px",
                                          fontSize: "16px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "100px",
                                          cursor: "pointer",
                                          backgroundColor: !any ? "none" : "#87c4ff",
                                          color: !any ? "none" : "#fff",
                                        }}
                                        onClick={() => (setMinAge(18), setMaxAge(64), setAny(!any))}
                                      >
                                        Any
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox>
                        {/* <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                          Contact Details
                        </SoftTypography> */}
                        <SoftBox component="form" role="form" width="750px">
                          <SoftBox mb={4}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={12}>
                                <SoftBox width="100%" height="100%" pl={0}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Course Description
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>

                                  <ReactQuill
                                    value={jobDes || ""}
                                    defaultValue=""
                                    modules={modules}
                                    formats={formats}
                                    onChange={handleTexteditorChange}
                                  />
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={12}>
                                <SoftTypography fontWeight="medium" fontSize="20px" mb={2}>
                                  SPOC Details
                                </SoftTypography>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Name
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftInput
                                      type="text"
                                      placeholder="Enter name"
                                      name="spoc_name"
                                      id="spoc_name"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.spoc_name}
                                    />
                                  </SoftBox>

                                  {jobpostform.touched.spoc_name && jobpostform.errors.spoc_name ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.spoc_name}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Mobile Number
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftInput
                                      type="tel"
                                      placeholder="Enter mobile number"
                                      inputProps={{
                                        maxLength: "10",
                                      }}
                                      id="spoc_phone"
                                      name="spoc_phone"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.spoc_phone}
                                    />
                                  </SoftBox>

                                  {jobpostform.touched.spoc_phone &&
                                  jobpostform.errors.spoc_phone ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.spoc_phone}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <SoftBox width="100%">
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Email
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftInput
                                      type="email"
                                      placeholder="Enter email"
                                      name="spoc_email"
                                      id="spoc_email"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.spoc_email}
                                    />
                                  </SoftBox>

                                  {jobpostform.touched.spoc_email &&
                                  jobpostform.errors.spoc_email ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.spoc_email}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto" }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={jobpostform.handleSubmit}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Submit
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.Seteditadmin(false);
                  jobpostform.resetForm();
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Cancel
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>
    </>
  );
};
EditPosting.propTypes = {
  editadmin: PropTypes.bool.isRequired,
  Seteditadmin: PropTypes.func.isRequired,
  editdata: PropTypes.object.isRequired,
  handleOrgFunc: PropTypes.func.isRequired,
  handleRoleFunc: PropTypes.func.isRequired,
  //   fetchadminList: PropTypes.func.isRequired,
};
export default EditPosting;
