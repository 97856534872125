import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Select from "react-select";
import PropTypes from "prop-types";
import "./SideDrawer.css";
import Backdrop from "./BackDrop";
import axios from "axios";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const SideDrawer = (props) => {
  //   const {
  //     candData,
  //     setCandData,
  //     veridetails,
  //     setVeridetails,
  //     handleOpenModal,
  //     ShowI,
  //     setShowI,
  //     verifyDetail,
  //     setVerifyDetail,
  //     HandleFetchCrm,
  //   } = props;
  const {
    orgId,
    jobId,
    setOpen,
    setScoreBased,
    scoreBased,
    setSearchData,
    Setadmindata,
    desgination,
    salary,
    selectedFilterState,
    selectedFilterDistrict,
    qualification,
    experience,
    stream,
    specialization,
    selectedDSF,
    setDesignation,
    setSalary,
    setSelectedFilterState,
    setSelectedFilterDistrict,
    setQualification,
    setExperirnce,
    setStream,
    setSpecialization,
    setSelectedDSF,
    fetchFilteredCandidates,
    paginationState,
    setPaginationState,
    filterOffsetFunc,
    setAge,
    age,
    gendertype,
    setGenderType,
    selectedCPs,
    setSelectedCPs,
    resetFunc,
    setFunc,
  } = props;
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }

  const TermSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#52af77",
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });

  const handleAgeChange = (event, newValue, activeThumb) => {
    // setAge(newValue === "" ? 26 : newValue);
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < 10) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 65 - 10);
        console.log("age slider:", [clamped, clamped + 10]);
        setAge([clamped, clamped + 10]);
      } else {
        const clamped = Math.max(newValue[1], 10);
        console.log("age slider:", [clamped - 10, clamped]);
        setAge([clamped - 10, clamped]);
      }
    } else {
      console.log("age slider:", newValue);
      setAge(newValue);
    }
  };

  const genderOption = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    // {
    //   label: "All",
    //   value: "All",
    // },
  ];

  const [JSFilterdataSource, setJSFilterDataSource] = useState([]);
  //   const [selectedDSF, setSelectedDSF] = useState("");
  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  console.log("default data: ", desgination, qualification, stream, specialization);

  /* ** Multi dd array states  ** */
  const [multiDS, setMultiDS] = useState([]);

  const [multiDistrict, setMultiDistrict] = useState([]);
  const [multiRole, setMultiRole] = useState([]);
  const [multiEducation, setMultiEducation] = useState([]);
  const [multiSpecialization, setMultiSpecialization] = useState([]);
  const [multiStream, setMultiStream] = useState([]);
  const [multiSalary, setMultiSalary] = useState([]);
  const [multigender, setMultigender] = useState([]);
  const [multiCps, setMultiCps] = useState([]);
  /* ** Multi dd array states  ** */

  const [education, Seteducation] = useState();
  const [streamdata, SetstreamData] = useState();
  const [degree, Setdergee] = useState();
  const [exp, Setexp] = useState();
  const [jobrole, Setjobrole] = useState();

  const [filterState, setFilterState] = useState([]);
  const [filterDistrict, setFilterDistrict] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [cpList, setCPList] = useState([]);
  const [cplabel, setCPLabel] = useState("");



  useEffect(() => {
    if (salary === "") {
      setMultiSalary([]);
    }
  }, [salary]);

  useEffect(() => {
    if (gendertype === "") {
      setMultigender([]);
    }
  }, [gendertype]);

  useEffect(() => {
    if (specialization === "") {
      setMultiSpecialization([]);
    }
  }, [specialization]);

  useEffect(() => {
    if (stream === "") {
      setMultiStream([]);
    }
  }, [stream]);

  useEffect(() => {
    if (qualification === "") {
      setMultiEducation([]);
    }
  }, [qualification]);

  useEffect(() => {
    if (desgination === "") {
      setMultiRole([]);
    }
  }, [desgination]);

  useEffect(() => {
    if (selectedFilterDistrict === "") {
      setMultiDistrict([]);
    }
  }, [selectedFilterDistrict]);

  useEffect(() => {
    if (selectedCPs === "") {
      setMultiCps([]);
    }
  }, [selectedCPs]);

  useEffect(() => {
    if (selectedDSF === "") {
      setMultiDS([]);
    }
  }, [selectedDSF]);

  const cpListFunc = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/account/details`,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      })
      .then((res) => {
        console.log("dd for :", res.data["cp_data"]);
        setCPList(res.data["cp_data"]);
      })
      .catch((err) => {
        console.log("dd for err:", err.response.data);
      });
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        //   setState(res.data);
        setFilterState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: sessionStorage.getItem("state")?sessionStorage.getItem("state"):''
      ,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        //   setDistrict(res.data);
        setFilterDistrict(res.data);
        // setSelectedFilterDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };





  const fetchOrgList = () => {
    const params = {
      // partner: "samarth",
      type: "dropdown",
      // type: "detail",
    };
    // const headers = {
    //   "access-control-allow-origin": "*",
    //   "Content-type": "application/json; charset=UTF-8",
    // };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // `${ngrokLink}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_List"]);
        setOrgList(res.data["Organisation_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOgrRole = () => {
    const params = {
      orgid: orgId,
      type: "dropdown",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?${paramString}`
        // `${ngrokLink}/superadmin/candidatesearch/jobpostdata?${paramString}`
      )
      .then((res) => {
        // setDistrict(res.data);
        setRoleList(res.data["JobPost_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        SetstreamData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDataSource = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`)
      .then((res) => {
        console.log("datasource", res.data["datasource_list"]);
        setJSFilterDataSource(
          res.data["datasource_list"]
            .filter((item) => item?.toString() !== "")
            .filter((item) => item?.toString() !== "null")
            .filter((item) => !item.toString().startsWith("SMR-CP"))
            .concat(["Channel Partner"])
            // .push((item) => [...item, "Channel Partner"])
            .map((ele, i) => ({ label: ele, value: ele }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };

 
  useEffect(() => {
    fetchOrgList();
    fetchStateList();
    fetchDataSource();
    // fetchJobSeekersKPIs();
    fetchexp();
    fetchjobrole();
    fetchSalary();
    fetcheducation();
    fetchdegree();
    fetchstream();
    fetchDataSource();
    cpListFunc();
  }, []);
  useEffect(() => {
    fetchDistricts();
  }, [selectedFilterState]);
  useEffect(() => {
    fetchOgrRole();
  }, [orgId]);

  console.log("list of cps:", selectedCPs);

  return (
    <div className={drawerClasses}>
      <SoftBox
        sx={{
          height: "90%",
          backgroundColor: "#FFF",
          // borderBottom: "1px solid black",
          // borderRadius: "2px",
          cursor: "pointer",
          //   overFlowY: "scroll",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
        width="100%"
      >
        <SoftBox
          width="100%"
          sx={{
            height: "100%",
            backgroundColor: "#FFF",
          
            cursor: "pointer",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            // justifyConten="flex-end"
            alignItems="left"
            sx={{
              // height: "100%",
              backgroundColor: "#FFF",
     
              padding: "0px 20px",
              cursor: "pointer",
            }}
          >
            <SoftTypography>{scoreBased ? `Relevancy Matching` : "Quick Filter"}</SoftTypography>
            <SoftBox onClick={() => props.setOpen(false)}>
              {" "}
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path
                  d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 
                  13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                ></path>
              </svg>{" "} */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style={{ height: "20px" }}
              >
                <path
                  d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 
                              13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                ></path>
              </svg>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" sx={{ overflowY: "scroll" }}>
            <SoftBox display="flex" flexDirection="column" gap="20px" p={3}>
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Data Source
                </SoftTypography>
                <Select
                  isClearable
                  isMulti
                  // placeholder="Search...."
                  value={
                    selectedDSF !== ""
                      ? selectedDSF?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiDS
                  }
                  placeholder="Select data source"
                  options={JSFilterdataSource}
                  onChange={(e) => {
                    if (e) {
                      setMultiDS(e.map((item) => item));
                    } else {
                      setMultiDS([]);
                    }
                    setSelectedDSF(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />
              </SoftBox>
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Channel Partner
                </SoftTypography>
                <Select
                  isClearable
                  isMulti
                  // placeholder="Search...."
                  value={
                    cplabel !== ""
                      ? cplabel?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiCps
                  }
                  placeholder="select channel partner"
                  options={cpList}
                  // onChange={(e) => setSelectedDSF(e.value)}
                  onChange={(e) => {
                    if (e) {
                      setMultiCps(e.map((item) => item));
                    } else {
                      setMultiCps([]);
                    }
                    setSelectedCPs(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                    setCPLabel(
                      e
                        .map((ele) => ele)
                        .map((item) => item.label)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />
              </SoftBox>
         
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  District
                </SoftTypography>

                <Select
                  isMulti
                  placeholder="Search...."
             
                  options={filterDistrict}
                  // onChange={(e) => setSelectedFilterDistrict(e.value)}
                  styles={stylesDD}
                  value={
                    selectedFilterDistrict !== "" && selectedFilterDistrict !== null
                      ? selectedFilterDistrict?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiDistrict
                  }
                  onChange={(e) => {
                    // console.log("district:");
                    if (e) {
                      setMultiDistrict(e.map((item) => item));
                    } else {
                      setMultiDistrict([]);
                    }
                    setSelectedFilterDistrict(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                  }}
                />

                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Role
                </SoftTypography>

                <Select
                  placeholder="Search...."
                  isMulti
                  options={jobrole}
                  value={
                    desgination !== ""
                      ? desgination?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiRole
                  }
                  onChange={(e) => {
                    console.log("multi desig:", e);
                    if (e) {
                      setMultiRole(e.map((item) => item));
                    } else {
                      setMultiRole([]);
                    }
                    setDesignation(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />

                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Qualification
                </SoftTypography>

                <Select
                  isMulti
                  options={education}
                  value={
                    qualification !== ""
                      ? qualification?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiEducation
                  }
                  placeholder="Search...."
                  onChange={(e) => {
                    if (e) {
                      setMultiEducation(e.map((item) => item));
                    } else {
                      setMultiEducation([]);
                    }
                    setQualification(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />

                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Stream
                </SoftTypography>

                <Select
                  
                  options={streamdata}
                  isMulti
                  value={
                    stream !== ""
                      ? stream?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiStream
                  }
                  placeholder="Search...."
                  onChange={(e) => {
                    if (e) {
                      setMultiStream(e.map((item) => item));
                    } else {
                      setMultiStream([]);
                    }
                    setStream(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />
                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Specialization
                </SoftTypography>
                {/* <SoftSelect
                              placeholder="Search...."
                              value={
                                specialization !== ""
                                  ? { label: specialization, value: specialization }
                                  : null
                              }
                              options={degree}
                              onChange={(e) => setSpecialization(e.value)}
                            /> */}
                <Select
        
                  isMulti
                  placeholder="Search...."
                  options={degree}
                  value={
                    specialization !== ""
                      ? specialization?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiSpecialization
                  }
                  onChange={(e) => {
                    if (e) {
                      setMultiSpecialization(e.map((item) => item));
                    } else {
                      setMultiSpecialization([]);
                    }
                    setSpecialization(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />
                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Experience
                </SoftTypography>

                <Select
                  isClearable
                  placeholder="Search...."
                  value={
                    experience !== ""
                      ? experience?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : null
                  }
                  options={exp}
                  onChange={(e) => (e ? setExperirnce(e.value) : setExperirnce(""))}
                  styles={stylesDD}
                />
                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>
              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Salary
                </SoftTypography>

                <Select
                  isClearable
                  isMulti
                  placeholder="Search...."
                  value={
                    salary !== ""
                      ? salary?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiSalary
                  }
                  options={salaryList}
                  // onChange={(e) => (e ? setSalary(e.value) : setSalary(""))}
                  onChange={(e) => {
                    if (e) {
                      setMultiSalary(e.map((item) => item));
                    } else {
                      setMultiSalary([]);
                    }
                    setSalary(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />
                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>

              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Gender
                </SoftTypography>

                <Select
                  isClearable
                  isMulti
                  options={genderOption}
                  value={
                    gendertype !== ""
                      ? gendertype?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multigender
                  }
                  onChange={(e) => {
                    if (e) {
                      setMultigender(e.map((item) => item));
                    } else {
                      setMultigender([]);
                    }
                    setGenderType(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />
                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>

              <SoftBox>
                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  {/* Age: {age} */}
                  Age: {`${age[0]} - ${age[1]}`}
                </SoftTypography>

             
                <ThemeProvider theme={TermSlider}>
                  <Slider
                    // name="termValue"
                    getAriaLabel={() => "Minimum distance shift"}
                    valueLabelDisplay="auto"
                    // aria-label="Default"
                    // defaultValue={[26 - 36]}
                    min={18}
                    max={65}
                    value={age}
                    disableSwap
                    onChange={handleAgeChange}
                  />
                </ThemeProvider>
                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="center"
            gap="35px"
            px={3}
            py="24px 0px"
          >
            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#3B5092",
                borderRadius: "100px",
                cursor: "pointer",
              }}
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              // p="10px 25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={{ lg: "150px", sm: "100%", xs: "50%" }}
              onClick={() => (
             
                setOpen(false), resetFunc()
              )}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "small",
                  lineHeight: "20px",
                }}
              >
                Reset
              </SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#3B5092",
                borderRadius: "100px",
                cursor: "pointer",
              }}
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              // p="10px 25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={{ lg: "150px", sm: "100%", xs: "50%" }}
              onClick={() => (
                setOpen(false),
          
                filterOffsetFunc(),
                fetchFilteredCandidates()
                // setFunc()
              )}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "small",
                  lineHeight: "20px",
                }}
              >
                Apply Filter
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </div>
  );
};

SideDrawer.propTypes = {
  show: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  setScoreBased: PropTypes.boolean,
  scoreBased: PropTypes.boolean,
  orgId: PropTypes.string,
  jobId: PropTypes.string,
  admindata: PropTypes.array,
  Setadmindata: PropTypes.array,
  searchData: PropTypes.array,
  setSearchData: PropTypes.array,
  desgination: PropTypes.string,
  salary: PropTypes.string,
  selectedFilterState: PropTypes.string,
  selectedFilterDistrict: PropTypes.string,
  qualification: PropTypes.string,
  experience: PropTypes.string,
  stream: PropTypes.string,
  specialization: PropTypes.string,
  selectedDSF: PropTypes.string,
  setDesignation: PropTypes.string,
  setSalary: PropTypes.string,
  setSelectedFilterState: PropTypes.string,
  setSelectedFilterDistrict: PropTypes.string,
  setQualification: PropTypes.string,
  setExperirnce: PropTypes.string,
  setStream: PropTypes.string,
  setSpecialization: PropTypes.string,
  setSelectedDSF: PropTypes.string,
  fetchFilteredCandidates: PropTypes.func,
  setPaginationState: PropTypes.object,
  paginationState: PropTypes.object,
  filterOffsetFunc: PropTypes.func,
  setAge: PropTypes.string,
  age: PropTypes.number,
  gendertype: PropTypes.string,
  setGenderType: PropTypes.string,
  selectedCPs: PropTypes.string,
  setSelectedCPs: PropTypes.string,
  resetFunc: PropTypes.func,
  setFunc: PropTypes.func,
};

export default SideDrawer;
