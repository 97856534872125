/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import 'react-bootstrap';
import axios from "axios";
import Swal from "sweetalert2";

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
import useMediaQuery from "@mui/material/useMediaQuery";
import image from "assets/sba_images/png/home_screen.png";
import { copy } from "stylis";

function Basic() {
  const [agreement, setAgremment] = useState(true);
  const navigate = useNavigate();

  const handleSetAgremment = () => setAgremment(!agreement);

  // const questions = [
  //   {
  //     label: "What was the house number and street name you lived in as a child?",
  //     value: "What was the house number and street name you lived in as a child?",
  //   },
  //   {
  //     label: "What were the last four digits of your childhood telephone number?",
  //     value: "What were the last four digits of your childhood telephone number?",
  //   },
  //   {
  //     label: "What primary school did you attend?",
  //     value: "What primary school did you attend?",
  //   },
  //   {
  //     label: "In what town or city was your first full time job?",
  //     value: "In what town or city was your first full time job?",
  //   },
  //   {
  //     label: "In what town or city did you meet your spouse or partner?",
  //     value: "In what town or city did you meet your spouse or partner?",
  //   },
  //   {
  //     label: "What is the middle name of your oldest child?",
  //     value: "What is the middle name of your oldest child?",
  //   },
  //   {
  //     label: "What are the last five digits of your driver's license number?",
  //     value: "What are the last five digits of your driver's license number?",
  //   },
  //   {
  //     label: "What is your grandmother's (on your mother's side) maiden name?",
  //     value: "What is your grandmother's (on your mother's side) maiden name?",
  //   },
  //   {
  //     label: "What is your spouse or partner's mother's maiden name?",
  //     value: "What is your spouse or partner's mother's maiden name?",
  //   },
  //   {
  //     label: "In what town or city did your parents meet?",
  //     value: "In what town or city did your parents meet?",
  //   },
  //   {
  //     label: "What time of the day were you born? (hh:mm)",
  //     value: "What time of the day were you born? (hh:mm)",
  //   },
  //   {
  //     label: "What time of the day was your first child born? (hh:mm)",
  //     value: "What time of the day was your first child born? (hh:mm)",
  //   },
  // ];
  const xs = useMediaQuery("(min-width:800px)");

  // const [registrationDetails,setRegistrationDetails] = useState({
  //   firstnm:"",
  //   lastnm:"",
  //   mobnum:"",
  //   email:"",
  //   security_ques:"",
  //   security_ans:"",
  //   password:"",
  //   confirm_password:""
  // })

  // const handleRegistration = ()=>{
  //   console.log("registration details",registrationDetails);

  //   // const url = "https://f060-2409-4072-8b-17b6-b93a-20e4-b9da-eef2.in.ngrok.io";
  //   {if(registrationDetails.firstnm!=="" && registrationDetails.lastnm!=="" && registrationDetails.email!==""
  //       && registrationDetails.mobnum!=="" && registrationDetails.password!=="" && registrationDetails.confirm_password!==""
  //       && registrationDetails.security_ques!=="" && registrationDetails.security_ans!==""
  //   ){
  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/superadmin/register`,
  //     {
  //       firstnm: registrationDetails.firstnm,
  //       lastnm: registrationDetails.lastnm,
  //       email: registrationDetails.email,
  //       mobnum: registrationDetails.mobnum,
  //       password: registrationDetails.password,
  //       // confirm_password: registrationDetails.confirm_password,
  //       security_ques: registrationDetails.security_ques,
  //       security_ans: registrationDetails.security_ans,
  //       role_id: "SA",
  //       creator_role:"SA",
  //       createdby: "Sapio"
  //     })
  // 		.then(res=>{
  //       console.log("profile Post api called");
  //       console.log("data to post ",res.data);
  //       alert('User Registerd');
  //       if(res.status===200){
  //         return <Navigate to="/main/dashboard" />
  //       }
  //     })
  // 		.catch((err)=>{
  // 			console.log(err);
  // 			alert('Error message',err)
  // 		})
  //   } }
  // }
  const [autopass, Setautopass] = useState(true);

  const [copyPassword, setCopyPassword] = useState({
    value: "Admin@1234",
    copied: false,
  });
  const [questions, setQuestions] = useState([]);

  const registrationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstnm: "",
      lastnm: "",
      mobnum: "",
      email: "",
      security_ques: "",
      security_ans: "",
      password: copyPassword.value ?? "",
      confirm_password: copyPassword.value ?? "",
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
      security_ques: Yup.string().required("Please select a question "),
      security_ans: Yup.string().required("Please enter your answer"),
      password: Yup.string().min(8).required("Please enter password"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
    }),
    onSubmit: (values) => {
      delete values.confirm_password;
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL_REGISTER}/superadmin/register`,
          {
            firstnm: values.firstnm,
            lastnm: values.lastnm,
            mobnum: values.mobnum ? String(values.mobnum) : "",
            email: values.email,
            security_ques: values.security_ques,
            security_ans: values.security_ans,
            password: values.password,
            role_id: "SA",
            creator_role: "SA",
            createdby: "SA-112036",
          })
        .then((res) => {
          if (res.status === 201) {
            Swal.fire({
              icon: "success",
              text: res.data["Developer Message"],
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              navigate("/login");
            });
          } else {
            Swal.fire({
              icon: "warning",
              text: res.data["Developer Message"],
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "",
            // text: "User registered successfully",
            text: err["message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            navigate("/signup");
          });
        });
    },
  });
  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data.Password });
    });
  };
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    // const resDATA = sessionStorage.getItem("register_id");
    // const creatorRole = sessionStorage.getItem("creator_role");
    // console.log(resDATA);
    // console.log(creatorRole);
    // if (resDATA) {
    //   setRegisterID(() => resDATA);
    // }
    // if (creatorRole) {
    //   setRoleId(creatorRole);
    // }
    // console.log("createdby :", registerID);
    // console.log("creator_role :", roleId);
  }, [autopass]);
  useEffect(() => {
    securityQuestionFunc();
  }, []);
  console.log("genpass", copyPassword.value);
  return xs ? (
    // <BasicLayout
    //   title="Super Admin Registration"
    //   // description="Use these awesome forms to login or create new account in your project for free."
    //   image={landingBg}
    // >
    <PageLayout>
      {/* <DefaultNavbar
      routes={pageRoutes}
      action={{
        type: "external",
        route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
        label: "buy now",
      }}
      transparent
      light
    /> */}
      <SoftBox
        display="flex"
        justifyContent="center"
        height="120vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <SoftBox width="981px" height="482px" mt={0}>
          <SoftBox
            display="flex"
            justifyContent="center"
            mt={5}
            mb={2}
            textAlign="center"
            fontSize="35px"
            color="white"
            lineHeight="44px"
            fontWeight="medium"
          >
            Super Admin Registration
          </SoftBox>
          <SoftBox component="form" role="form" onSubmit={registrationForm.handleSubmit}>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox display="flex" flexDirection="column" alignItems="flex-start" pl={3}>
                <SoftTypography fontWeight="medium" mb={0} mt={2}>
                  Registration Details
                </SoftTypography>
                {/*
                <SoftBox display="flex" flexDirection="row" mb={2}>
                  <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                    <sup>*</sup>
                  </SoftBox>
                  <SoftTypography fontSize="12px" fontStyle="italic">
                    all fields are mandatory
                  </SoftTypography>
                </SoftBox>
                */}
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="30px"
                  justifyContent="space-between"
                  mb={3}
                >
                  <SoftBox width="429px">
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="14px">
                        First Name{" "}
                      </SoftTypography>

                      <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="First Name"
                      id="firstnm"
                      name="firstnm"
                      onChange={registrationForm.handleChange}
                      value={registrationForm.values.firstnm}
                    />
                    {registrationForm.touched.firstnm && registrationForm.errors.firstnm ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {registrationForm.errors.firstnm}
                      </span>
                    ) : null}
                  </SoftBox>
                  <SoftBox width="429px">
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="14px">
                        Last Name{" "}
                      </SoftTypography>
                      <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="Last Name"
                      id="lastnm"
                      name="lastnm"
                      onChange={registrationForm.handleChange}
                      value={registrationForm.values.lastnm}
                    />
                    {registrationForm.touched.lastnm && registrationForm.errors.lastnm ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {registrationForm.errors.lastnm}
                      </span>
                    ) : null}
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="30px"
                  justifyContent="space-between"
                  mb={3}
                >
                  <SoftBox width="429px">
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="14px">
                        Email{" "}
                      </SoftTypography>
                      <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftInput
                      type="email"
                      placeholder="Email"
                      id="email"
                      name="email"
                      onChange={registrationForm.handleChange}
                      value={registrationForm.values.email}
                    />
                    {registrationForm.touched.email && registrationForm.errors.email ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {registrationForm.errors.email}
                      </span>
                    ) : null}
                  </SoftBox>
                  <SoftBox width="429px">
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="14px">
                        Mobile Number{" "}
                      </SoftTypography>

                      <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>

                    <SoftInput
                      type="number"
                      placeholder="Mobile Number"
                      id="mobnum"
                      name="mobnum"
                      onChange={registrationForm.handleChange}
                      value={registrationForm.values.mobnum}
                    />
                    {registrationForm.touched.mobnum && registrationForm.errors.mobnum ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {registrationForm.errors.mobnum}
                      </span>
                    ) : null}
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="30px"
                  justifyContent="space-between"
                  mb={3}
                >
                  <SoftBox width="429px">
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      height="75px"
                      sx={{
                        border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                        bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                      }}
                    >
                      <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                        <input
                          name="password"
                          type="radio"
                          defaultChecked="true"
                          onChange={(e) => {
                            if (e.target.checked) {
                              Setautopass(true);
                            } else {
                              Setautopass(false);
                            }
                          }}
                        />
                      </SoftBox>

                      <SoftBox display="flex" flexDirection="column" my="5px">
                        <SoftTypography fontWeight="medium" fontSize="14px">
                          Auto Generate Password
                        </SoftTypography>
                        <SoftTypography fontWeight="small" fontSize="13px" lineHeight="16px">
                          Password will be automatically generated after the registration is done.
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox width="429px">
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      height="75px"
                      sx={{
                        border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                        bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                      }}
                    >
                      <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                        <input
                          name="password"
                          type="radio"
                          onChange={() => {
                            registrationForm.setFieldValue("password", "");
                            registrationForm.setFieldValue("confirm_password", "");
                            Setautopass(false);
                          }}
                        />
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column" my="5px">
                        <SoftTypography fontWeight="medium" fontSize="14px">
                          Custom Password
                        </SoftTypography>
                        <SoftTypography fontWeight="small" fontSize="13px" lineHeight="16px">
                          You can create a custom password for the user
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="30px"
                  justifyContent="space-between"
                  mb={3}
                >
                  <SoftBox width="429px">
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="14px">
                        Password {" "}
                      </SoftTypography>

                      <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftInput
                      type="password"
                      placeholder=""
                      disabled={autopass}
                      id="password"
                      name="password"
                      onChange={registrationForm.handleChange}
                      value={registrationForm.values.password}
                    />
                    {registrationForm.touched.password && registrationForm.errors.password ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {registrationForm.errors.password}
                      </span>
                    ) : null}
                  </SoftBox>
                  <SoftBox width="429px">
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="14px">
                        Confirm Password {" "}
                      </SoftTypography>

                      <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftInput
                      type="password"
                      placeholder=""
                      disabled={autopass}
                      id="confirm_password"
                      name="confirm_password"
                      onChange={registrationForm.handleChange}
                      value={registrationForm.values.confirm_password}
                    />
                    {registrationForm.touched.confirm_password &&
                      registrationForm.errors.confirm_password ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {registrationForm.errors.confirm_password}
                      </span>
                    ) : null}
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="30px"
                  justifyContent="space-between"
                  mb={5}
                >
                  <SoftBox width="429px">
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="14px">
                        Security Question {" "}
                      </SoftTypography>

                      <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftSelect
                      placeholder="Select question"
                      options={questions}
                      id="security_ques"
                      name="security_ques"
                      onChange={(e) => {
                        console.log(e);
                        registrationForm.setFieldValue("security_ques", e.label);
                      }}
                      value={{ label: registrationForm.values.security_ques }}
                    />
                    {registrationForm.touched.security_ques &&
                      registrationForm.errors.security_ques ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {registrationForm.errors.security_ques}
                      </span>
                    ) : null}
                  </SoftBox>
                  <SoftBox width="429px">
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="14px">
                        Answer {" "}
                      </SoftTypography>

                      <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="Answer"
                      id="security_ans"
                      name="security_ans"
                      onChange={registrationForm.handleChange}
                      value={registrationForm.values.security_ans}
                    />
                    {registrationForm.touched.security_ans &&
                      registrationForm.errors.security_ans ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {registrationForm.errors.security_ans}
                      </span>
                    ) : null}
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Card>
            <SoftBox mt={2} display="flex" justifyContent="center" gap="10px">
              <button className="login-btn" onClick={registrationForm.handleSubmit}>
                <span className="btn-txt">Submit</span>
              </button>

              <button className="login-btn2" onClick={() => navigate('/login')}>
                <span className="btn-txt2">Cancel</span>
              </button>

              {/*
              <SoftBox
                sx={{
                  backgroundColor: "#FF5C00",
                  width: "225px",
                  padding: "13px 84px",
                  cursor: "pointer",
                }}
                borderRadius="5px"
              >
                <SoftTypography
                  fontSize="18px"
                  color="white"
                  variant="button"
                  onClick={registrationForm.handleSubmit}
                >
                  Submit
                </SoftTypography>
              </SoftBox>

              */}

              {/*
              <SoftBox
                sx={{
                  backgroundColor: "#fff",
                  width: "225px",
                  padding: "13px 84px",
                  cursor: "pointer",
                }}
                borderRadius="5px"
              >
                <SoftTypography variant="button" fontSize="18px" color="#3B5092">
                  Cancel
                </SoftTypography>
              </SoftBox>
              */}
            </SoftBox>
          </SoftBox>
          <SoftBox mt={1} textAlign="center">
            <SoftTypography variant="button" color="white" fontWeight="regular" fontSize="14px">
              Already have an account?&nbsp;
              <SoftTypography
                component={Link}
                // to="/login"
                to="/login"
                variant="button"
                // color="gradient"
                fontWeight="bold"
                fontSize="14px"
                // textGradient
                // sx={{ color: "white" }}
                color="white"
              >
                Sign in
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </PageLayout>
  ) : (
    <BasicLayout
      title="Super Admin Registration"
      // description="Use these awesome forms to login or create new account in your project for free."
      image={image}
    >
      <Card sx={{ overflow: "visible" }}>
        <SoftBox p={1} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Register
          </SoftTypography>
          <SoftBox display="flex" flexDirection="row" mb={2} ml={3}>
            <SoftBox sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}>
              <sup>*</sup>
            </SoftBox>
            <SoftTypography fontSize="12px" fontStyle="italic">
              all fields are mandatory
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        {/* <SoftBox mb={2}>
        <Socials />
      </SoftBox> */}
        {/* <Separator /> */}
        <SoftBox pt={2} pb={3} px={5}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2} sm={12}>
              {/* <SoftInput placeholder="First Name" /> */}
              <SoftBox display="flex" flexDirection="row">
                <SoftTypography fontWeight="medium" fontSize="12px">
                  First Name
                </SoftTypography>
                {/* <SoftBox sx={{ color: "red", position: "inherit", fontSize: "10px" }}>*</SoftBox> */}
              </SoftBox>
              <SoftInput
                type="text"
                placeholder="First Name"
                id="firstnm"
                name="firstnm"
                onChange={registrationForm.handleChange}
                value={registrationForm.values.firstnm}
              />
              {registrationForm.touched.firstnm && registrationForm.errors.firstnm ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {registrationForm.errors.firstnm}
                </span>
              ) : null}
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox display="flex" flexDirection="row">
                <SoftTypography fontWeight="medium" fontSize="12px">
                  Last Name
                </SoftTypography>
                {/* <SoftBox sx={{ color: "red", position: "inherit", fontSize: "10px" }}>*</SoftBox> */}
              </SoftBox>
              <SoftInput
                placeholder="Last Name"
                id="lastnm"
                name="lastnm"
                onChange={registrationForm.handleChange}
                value={registrationForm.values.lastnm}
              />
              {registrationForm.touched.lastnm && registrationForm.errors.lastnm ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {registrationForm.errors.lastnm}
                </span>
              ) : null}
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox display="flex" flexDirection="row">
                <SoftTypography fontWeight="medium" fontSize="12px">
                  Email
                </SoftTypography>
                {/* <SoftBox sx={{ color: "red", position: "inherit", fontSize: "10px" }}>*</SoftBox> */}
              </SoftBox>
              <SoftInput
                type="email"
                placeholder="Email"
                id="email"
                name="email"
                onChange={registrationForm.handleChange}
                value={registrationForm.values.email}
              />
              {registrationForm.touched.email && registrationForm.errors.email ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {registrationForm.errors.email}
                </span>
              ) : null}
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox display="flex" flexDirection="row">
                <SoftTypography fontWeight="medium" fontSize="12px">
                  Mobile Number
                </SoftTypography>
                {/* <SoftBox sx={{ color: "red", position: "inherit", fontSize: "10px" }}>*</SoftBox> */}
              </SoftBox>
              <SoftInput
                type="number"
                placeholder="Mobile No"
                id="mobnum"
                name="mobnum"
                onChange={registrationForm.handleChange}
                value={registrationForm.values.mobnum}
              />
              {registrationForm.touched.mobnum && registrationForm.errors.mobnum ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {registrationForm.errors.mobnum}
                </span>
              ) : null}
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox
                display="flex"
                flexDirection="row"
                height="auto"
                sx={{
                  border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                  bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                }}
              >
                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                  <input
                    name="password"
                    type="radio"
                    defaultChecked="true"
                    onChange={(e) => {
                      if (e.target.checked) {
                        Setautopass(true);
                      } else {
                        Setautopass(false);
                      }
                    }}
                  />
                </SoftBox>

                <SoftBox display="flex" flexDirection="column" my="5px">
                  <SoftTypography fontWeight="medium" fontSize="18px">
                    Auto Generate Password
                  </SoftTypography>
                  <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                    Password will be automatically generated after the registration is done.
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox
                display="flex"
                flexDirection="row"
                height="autp"
                sx={{
                  border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                  bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                }}
              >
                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                  <input
                    name="password"
                    type="radio"
                    onChange={() => {
                      registrationForm.setFieldValue("password", "");
                      registrationForm.setFieldValue("confirm_password", "");
                      Setautopass(false);
                    }}
                  />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column" my="5px">
                  <SoftTypography fontWeight="medium" fontSize="18px">
                    Custom Password
                  </SoftTypography>
                  <SoftTypography fontWeight="small" fontSize="15px" lineHeight="16px">
                    You can create a custom password for the user
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox display="flex" flexDirection="row">
                <SoftTypography fontWeight="medium" fontSize="12px">
                  Password
                </SoftTypography>
                {/* <SoftBox sx={{ color: "red", position: "inherit", fontSize: "10px" }}>*</SoftBox> */}
              </SoftBox>
              <SoftInput
                type="password"
                placeholder="Password"
                disabled={autopass}
                id="password"
                name="password"
                onChange={registrationForm.handleChange}
                value={registrationForm.values.password}
              />
              {registrationForm.touched.password && registrationForm.errors.password ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {registrationForm.errors.password}
                </span>
              ) : null}
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox display="flex" flexDirection="row">
                <SoftTypography fontWeight="medium" fontSize="12px">
                  Confirm Password
                </SoftTypography>
                {/* <SoftBox sx={{ color: "red", position: "inherit", fontSize: "10px" }}>*</SoftBox> */}
              </SoftBox>
              <SoftInput
                type="password"
                placeholder="Confirm Password"
                disabled={autopass}
                id="confirm_password"
                name="confirm_password"
                onChange={registrationForm.handleChange}
                value={registrationForm.values.confirm_password}
              />
              {registrationForm.touched.confirm_password &&
                registrationForm.errors.confirm_password ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {registrationForm.errors.confirm_password}
                </span>
              ) : null}
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox display="flex" flexDirection="row">
                <SoftTypography fontWeight="medium" fontSize="12px">
                  Security Question
                </SoftTypography>
                {/* <SoftBox sx={{ color: "red", position: "inherit", fontSize: "10px" }}>*</SoftBox> */}
              </SoftBox>
              <SoftSelect
                placeholder="select questions"
                options={questions}
                id="security_ques"
                name="security_ques"
                onChange={(e) => {
                  console.log(e);
                  registrationForm.setFieldValue("security_ques", e.label);
                }}
                value={{ label: registrationForm.values.security_ques }}
              />
              {registrationForm.touched.security_ques && registrationForm.errors.security_ques ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {registrationForm.errors.security_ques}
                </span>
              ) : null}
            </SoftBox>
            <SoftBox mb={2} sm={12}>
              <SoftBox display="flex" flexDirection="row">
                <SoftTypography fontWeight="medium" fontSize="12px">
                  Answer
                </SoftTypography>
                {/* <SoftBox sx={{ color: "red", position: "inherit", fontSize: "10px" }}>*</SoftBox> */}
              </SoftBox>
              <SoftInput
                type="text"
                placeholder="Answer"
                id="security_ans"
                name="security_ans"
                onChange={registrationForm.handleChange}
                value={registrationForm.values.security_ans}
              />
              {registrationForm.touched.security_ans && registrationForm.errors.security_ans ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {registrationForm.errors.security_ans}
                </span>
              ) : null}
            </SoftBox>

            <SoftBox mt={4} mb={1} display="flex" flexDirection="column" gap="10px">
              <SoftBox
                md={12}
                width={{ lg: "225px" }}
                // component={Link}
                // to='/agent/business'
                // variant="gradient"
                // color="warning"
                display="flex"
                justifyContent="center"
                sx={{
                  backgroundColor: "#FF5C00",
                  padding: "13px 84px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                borderRadius="5px"
                // onClick={handleRegistration}
                fullWidth
              >
                <SoftTypography
                  color="white"
                  // onClick={handleRegistration}
                  // type="submit" fontSize="18px"
                  onClick={registrationForm.handleSubmit}
                  variant="button"
                >
                  Submit
                </SoftTypography>
              </SoftBox>
              <SoftButton
                md={12}
                width={{ lg: "225px" }}
                component={Link}
                to="/agent/business"
                // variant="gradient"
                // color="dark"
                sx={{ backgroundColor: "#fff", padding: "13px 84px", color: "#3B5092" }}
                fullWidth
              >
                Cancel
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <SoftTypography
                  component={Link}
                  // to="/login"
                  to="/login"
                  variant="button"
                  color="#FFFFFF"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
