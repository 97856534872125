import { Card, Stack } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import SoftSelect from "../../components/SoftSelect";
import SoftInput from "components/SoftInput";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { width } from "@mui/system";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const copy = require("clipboard-copy");
const Channelprtnerregister = () => {
  const ref = useRef();
  const [resumefile, setResumeFile] = useState("");
  const [autopass, Setautopass] = useState(true);
  const [check, setCheck] = useState(false);
  const [crmOptinYes, setCrmOptinYes] = useState(false);
  const [crmOptinNo, setCrmOptinNo] = useState(false);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });
  const [copyPassword1, setCopyPassword1] = useState({
    value: "",
    copied: false,
  });
  const [copyPassword2, setCopyPassword2] = useState({
    value: "",
    copied: false,
  });
  const [valueCP, setValueCP] = useState("");
  const [selectedCP, setSelectedCP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [registerID, setRegisterID] = useState("");
  const [roleId, setRoleId] = useState("SA");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [questions, setQuestions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  // const [formLink,setFormLink] = useState("");
  const [show, setShow] = useState(false);
  const [subcptype, setSubcptype] = useState([]);
  const [showpassword, Setshowpassword] = useState(false);
  const [displayPassword, setDisplayPassword] = useState("");
  const [addressChar, setAddressChar] = useState(250);
  // const [centerOwnerId,setCenterOwnerId]= useState("");
  const RoleOptions = [
    { label: "Requirement Based", value: "Requirement Based" },
    { label: "End to End", value: "End to End" },
  ];
  const DatabaseOptions = [
    { label: "All", value: "All" },
    { label: "Self Database", value: "Self Database" },
    { label: "Samarth’s Database", value: "Samarth’s Database" },
  ];
  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob(
            [`Auto Generate Password : ${displayPassword}`],

      // [`Auto Generate Password : ${showpassword ? displayPassword : copyPassword.value}`],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };
  const handleResume = (File) => {
    const validExt = ["jpeg", "png"];
    const fileExt = File.name.split(".")[1];
    return validExt.includes(fileExt);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file);
    if (file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  const sizeOptions = [
    {
      label: "Tiny Unit",
      value: "Tiny Unit",
    },
    {
      label: "Small Unit",
      value: "Small Unit",
    },
    {
      label: "Medium Unit",
      value: "Medium Unit",
    },
    {
      label: "Large Unit",
      value: "Large Unit",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const industryOptions1 = [
    { label: "Textile and Clothing", value: "Textile and Clothing" },
    { label: "Building and Construction", value: "Building and Construction" },
    { label: "Automobile and Transportation", value: "Automobile and Transportation" },
    { label: "Organized and Unorganized Retail", value: "Organized and Unorganized Retail" },
    { label: "Gems and Jewellery", value: "Gems and Jewellery" },
    { label: "IT & Computers", value: "IT & Computers" },
    { label: "Furniture and Furnishing", value: "Furniture and Furnishing" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Leather and Leather Goods", value: "Leather and Leather Goods" },
    { label: "Tourism and Hospitality", value: "Tourism and Hospitality" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Mining", value: "Mining" },
    { label: "Households", value: "Households" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
    { label: "Others", value: "Others" },
  ];

  const sectorOption = [
    {
      label: "Public",
      value: "Public",
    },
    {
      label: "Private",
      value: "Private",
    },
    {
      label: "PSU",
      value: "PSU",
    },
  ];

  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data?.Password });
    });
  };
  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchIndustryFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setIndustryOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, [autopass]);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
    securityQuestionFunc();
    fetchIndustryFunc();
  }, []);

  const option = [
    { label: "Saksham SRS", value: "SRS" },
    { label: "Vakrangee", value: "Vakrangee" },
    { label: "SBA SRS", value: "Job Center" },
    { label: "JIO", value: "JIO" },
  ];

  useEffect(() => {
    const params = {
      cp_type: selectedCP,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/channelpartner/cpsubtype?${paramString}`
        )
        .then((res) => {
          setSubcptype(
            res.data?.map((v) => {
              return {
                label: v.value,
                value: v.value,
              };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchCity();
  }, [selectedCP]);

  useEffect(() => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);

  const showAlert = () =>
    Swal.fire({
      //position: "top",
      icon: "success",
      title: "Registration Successful",
      //text: "Channel Partner Registered Successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      navigate("/channel-partner-list-page");
    });

  const navigate = useNavigate();
  const [formNew, setFormNew] = useState({
    email: "",
    firstnm: "",
    lastnm: "",
    mobnum: "",
    security_ques: "",
    security_ans: "",
    org_name: "",
    org_address: "",
    org_state: "",
    org_district: "",
    org_pincode: "",
    type: "",
    subtype: "",
    application: "",
    cover_letter: "",
    pan_card: "",
    gst_cert: "",
    financial_report: "",
    emd: "",
    tender_fee: "",
    other_cert: "",
    reg_cert: "",
    udyam_cert: "",
    declaration_cert: "",
    part_of: "",
  });

  const registrationChannelPartner = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: formNew.email ? formNew.email : "",
      firstnm: formNew.firstnm ? formNew.firstnm : "",
      lastnm: formNew.lastnm ? formNew.lastnm : "",
      mobnum: formNew.mobnum ? formNew.mobnum : "",
      security_ques: formNew.security_ques ? formNew.security_ques : "",
      security_ans: formNew.security_ans ? formNew.security_ans : "",
      password: autopass ? copyPassword.value : copyPassword2.value,
      confirm_password: autopass ? copyPassword.value : copyPassword1.value,
      org_name: formNew.org_name ? formNew.org_name : "",
      org_address: formNew.org_address ? formNew.org_address : "",
      org_state: formNew.org_state ? formNew.org_state : "",
      org_district: formNew.org_district ? formNew.org_district : "",
      org_pincode: formNew.org_pincode ? formNew.org_pincode : "",
      type: formNew.type ? formNew.type : "",
      subtype: formNew.subtype ? formNew.subtype : "",
      application: formNew.application ? formNew.application : "",
      cover_letter: formNew.cover_letter ? formNew.cover_letter : "",
      pan_card: formNew.pan_card ? formNew.pan_card : "",
      gst_cert: formNew.gst_cert ? formNew.gst_cert : "",
      financial_report: formNew.financial_report ? formNew.financial_report : "",
      emd: formNew.emd ? formNew.emd : "",

      tender_fee: formNew.tender_fee ? formNew.tender_fee : "",
      other_cert: formNew.other_cert ? formNew.other_cert : "",
      reg_cert: formNew.reg_cert ? formNew.reg_cert : "",
      udyam_cert: formNew.udyam_cert ? formNew.udyam_cert : "",
      declaration_cert: formNew.declaration_cert ? formNew.declaration_cert : "",
      part_of: formNew.part_of ? formNew.part_of : "",
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
      // security_ques: Yup.string().required("Please select a question "),
      // security_ans: Yup.string().required("Please enter your answer"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
      security_ques: Yup.string().required("Please select a question "),
      security_ans: Yup.string().required("Please enter your answer"),
      org_name: Yup.string().required("Please enter organization name"),
      type: Yup.string().required("Please select channel partner type"),
      org_address: Yup.string().required("Please enter address"),
      org_state: Yup.string().required("Please enter state"),
      org_district: Yup.string().required("Please enter district"),
      org_pincode: Yup.string()
        .notRequired()
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(6, "Please enter 6 digit number"),
      // application: Yup.string().required("Please upload the file"),
      application: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      cover_letter: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      // pan_card: Yup.mixed()
      //   //.notRequired()
      //   .required("Upload Pan Card")
      //   .test(
      //     "FILE_FORMAT",
      //     "Invalid format",
      //     (value) =>
      //       !value ||
      //       (value &&
      //         [
      //           "image/png",
      //           "image/jpg",
      //           "image/jpeg",
      //           "application/pdf",
      //           "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      //           "application/msword",
      //         ].includes(value?.type))
      //   )
      //   .test(
      //     "FILE_SIZE",
      //     "Please upload file < 5mb",
      //     (value) => !value || (value && value?.size / 1024 < 5120)
      //   ),
      gst_cert: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      financial_report: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      reg_cert: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      udyam_cert: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      declaration_cert: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      emd: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      tender_fee: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
      other_cert: Yup.mixed()
        .notRequired()
        //.required("Upload Application")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
    }),
    onSubmit: (values) => {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      delete values.confirm_password;
      // delete values.partner_association;
      setDisplayPassword(values.password);
      setIsLoading(true);
      let formData = new FormData();
      // formData.append("role_id", "CP");
      // formData.append("creator_role", roleId);
      formData.append("createdby", registerID);
      formData.append("wa_optin", check ? "1" : "0");
      formData.append("firstnm", values.firstnm);
      formData.append("lastnm", values.lastnm);
      formData.append("mobnum", values.mobnum);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("security_ques", values.security_ques);
      formData.append("security_ans", values.security_ans);
      formData.append("org_name", values.org_name);
      formData.append("org_address", values.org_address);
      formData.append("org_state", values.org_state);
      formData.append("org_district", values.org_district);
      formData.append("org_pincode", values.org_pincode);
      formData.append("type", values.type);
      formData.append("sub_type", values.subtype);
      formData.append("application", values.application);
      formData.append("cover_letter", values.cover_letter);
      formData.append("pan_card", values.pan_card);
      formData.append("gst_cert", values.gst_cert);
      formData.append("financial_report", values.financial_report);
      formData.append("reg_cert", values.reg_cert);
      formData.append("udyam_cert", values.udyam_cert);
      formData.append("declaration_cert", values.declaration_cert);
      formData.append("emd", values.emd);
      formData.append("tender_fee", values.tender_fee);
      formData.append("other_cert", values.other_cert);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/channelpartner/register`,
          //  {
          //   ...values,
          //   role_id: "JP",
          //   creator_role: roleId,
          //   // creator_role: "SA",
          //   createdby: registerID,
          //   wa_notify: check ? "true" : "false",
          // }
          formData
        )
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          setFormNew({
            email: "",
            firstnm: "",
            lastnm: "",
            mobnum: "",
            security_ques: "",
            security_ans: "",
            org_name: "",
            org_address: "",
            org_state: "",
            org_district: "",
            org_pincode: "",
            type: "",
            subtype: "",
            application: "",
            cover_letter: "",
            pan_card: "",
            gst_cert: "",
            financial_report: "",
            emd: "",
            tender_fee: "",
            other_cert: "",
            reg_cert: "",
            udyam_cert: "",
            declaration_cert: "",
            part_of: "",
          });
          if (res.status === 201) {
            setShow(true);
            setResumeFile("");
            setIsLoading(false);
          } else {
            Swal.fire({
              icon: "info",
              title: res.data["Developer Message"]
                ? res.data["Developer Message"]
                : "Something went wrong!",
              text: res.data["User Message"] ? res.data["User Message"] : "Something went wrong!",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Something went wrong!",
            text: err.response.data["User Message"]
              ? err.response.data["User Message"]
              : "Something went wrong!",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            // navigate("/superadmin/dashboard");
            setIsLoading(false);
          });
        });
    },
  });
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              Channel Partners Registration
            </SoftTypography>
            <Grid container minWidth="70%">
              <SoftBox mt={4} width="100%" height="auto">
                <Card minWidth="100%" sx={{ overflow: "visible" }}>
                  <SoftBox pl={4} pr={14} pt={2} pb={3}>
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                      Organization Details
                    </SoftTypography>
                    <SoftBox component="form" role="form">
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Name of Organization
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_name"
                                name="org_name"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormNew({
                                    ...formNew,
                                    org_name: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.org_name}
                              />
                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_name &&
                              registrationChannelPartner.errors.org_name ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_name}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Address
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_address"
                                name="org_address"
                                inputProps={{
                                  maxLength: "250",
                                }}
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormNew({
                                    ...formNew,
                                    org_address: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.org_address}
                              />
                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {registrationChannelPartner.values.org_address?.length}/
                                {addressChar}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_address &&
                              registrationChannelPartner.errors.org_address ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_address}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  State
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftSelect
                                options={state}
                                placeholder="Select State"
                                id="org_state"
                                name="org_state"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_state", e.label);
                                  setSelectedState(e.label);
                                  setFormNew({
                                    ...formNew,
                                    org_state: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationChannelPartner.values.org_state }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_state &&
                              registrationChannelPartner.errors.org_state ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_state}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  District
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftSelect
                                options={district}
                                placeholder=""
                                id="org_district"
                                name="org_district"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_district", e.label);
                                  setFormNew({
                                    ...formNew,
                                    org_district: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationChannelPartner.values.org_district }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_district &&
                              registrationChannelPartner.errors.org_district ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_district}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Pincode
                                </SoftTypography>
                                {/*
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                                */}
                              </SoftBox>
                              <SoftInput
                                type="tel"
                                placeholder=""
                                inputProps={{
                                  maxLength: "6",
                                }}
                                id="org_pincode"
                                name="org_pincode"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormNew({
                                    ...formNew,
                                    org_pincode: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.org_pincode}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_pincode &&
                              registrationChannelPartner.errors.org_pincode ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_pincode}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Channel Partner Type
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftSelect
                                options={option}
                                placeholder="Select channel partner type"
                                id="type"
                                name="type"
                                onChange={(e) => {
                                  console.log("type cp:", e.value);
                                  setValueCP(e.label);
                                  registrationChannelPartner.setFieldValue("type", e.value);
                                  setSelectedCP(e.value);
                                  setFormNew({
                                    ...formNew,
                                    type: e ? e.value : "",
                                  });
                                }}
                                // value={{ label: registrationChannelPartner.values.type }}
                                value={{ label: valueCP }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.type &&
                              registrationChannelPartner.errors.type ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.type}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Sub Channel Partner Type
                                </SoftTypography>
                              </SoftBox>
                              <SoftSelect
                                options={subcptype}
                                placeholder="Select Sub channel partner type"
                                id="subtype"
                                name="subtype"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("subtype", e.label);
                                  setFormNew({
                                    ...formNew,
                                    subtype: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationChannelPartner.values.subtype }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.subtype &&
                              registrationChannelPartner.errors.subtype ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.subtype}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          {/*
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Size of Unit
                                </SoftTypography>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontSize="14px" fontWeight="small">
                                    (Revenue based)
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                              <SoftSelect
                                options={sizeOptions}
                                placeholder="Select Size of unit"
                                id="org_size"
                                name="org_size"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_size", e.label);
                                  // setSelectedState(e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_size }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_size &&
                                registrationChannelPartner.errors.org_size ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_size}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          */}

                          {/*
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                CIN Number
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_cin_num"
                                name="org_cin_num"
                                inputProps={{ maxLength: 21, minLength: 21 }}
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.org_cin_num}
                              />
                              {registrationChannelPartner.touched.org_cin_num &&
                                registrationChannelPartner.errors.org_cin_num ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_cin_num}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                         */}
                        </Grid>
                      </SoftBox>

                      {/* <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Organization Logo
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                placeholder=""
                                accept="image/png, image/jpeg"
                                // ref={ref}
                                onChange={handleFileUpload}
                              />
                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {
                                  "Please upload files < 5mb and make sure format is either jpeg or png."
                                }
                              </SoftTypography>
                              {registrationChannelPartner.touched.org_logo &&
                              registrationChannelPartner.errors.org_logo ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_logo}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox> */}
                    </SoftBox>
                  </SoftBox>

                  <SoftBox pl={4} pr={14} pt={0} pb={0}>
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4}>
                      Contact Details
                    </SoftTypography>
                    <SoftBox
                      component="form"
                      role="form"
                      onSubmit={registrationChannelPartner.handleSubmit}
                    >
                      {/* <SoftBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        mb={3}
                      >
                        <SoftBox width="429px">
                          <SoftTypography fontWeight="medium" fontSize="16px">
                            Email
                          </SoftTypography>
                          <SoftInput type="email" placeholder="" />
                        </SoftBox>
                        <SoftBox width="429px">
                          <SoftTypography fontWeight="medium" fontSize="16px">
                            Mobile Number
                          </SoftTypography>
                          <SoftInput type="number" placeholder="" />
                          <SoftBox display="flex" mt={1}>
                            <input type="checkbox" placeholder="" />
                            <SoftTypography fontWeight="medium" fontSize="12px">
                              &nbsp; You&apos;ll be Receiving Updates On{" "}
                              <Icon icon="mdi:whatsapp" color="green" width="12" inline={true} />{" "}
                              Whatsapp
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox> */}
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Email
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="email"
                                placeholder=""
                                id="email"
                                name="email"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormNew({
                                    ...formNew,
                                    email: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.email}
                              />
                              {registrationChannelPartner.touched.email &&
                              registrationChannelPartner.errors.email ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.email}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Mobile Number
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="tel"
                                placeholder=""
                                inputProps={{
                                  maxLength: "10",
                                }}
                                id="mobnum"
                                name="mobnum"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormNew({
                                    ...formNew,
                                    mobnum: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.mobnum}
                              />
                              <SoftBox display="flex" mt={1}>
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  checked={check}
                                  onChange={() => setCheck(!check)}
                                  placeholder=""
                                />
                                <SoftTypography fontWeight="medium" fontSize="12px">
                                  &nbsp; You&apos;ll be receiving updates on{" "}
                                  <Icon
                                    icon="mdi:whatsapp"
                                    color="green"
                                    width="12"
                                    inline={true}
                                  />{" "}
                                  Whatsapp
                                </SoftTypography>
                              </SoftBox>
                              {registrationChannelPartner.touched.mobnum &&
                              registrationChannelPartner.errors.mobnum ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.mobnum}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  First Name
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="firstnm"
                                name="firstnm"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormNew({
                                    ...formNew,
                                    firstnm: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.firstnm}
                              />
                              {registrationChannelPartner.touched.firstnm &&
                              registrationChannelPartner.errors.firstnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.firstnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Last Name
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="lastnm"
                                name="lastnm"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormNew({
                                    ...formNew,
                                    lastnm: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.lastnm}
                              />
                              {registrationChannelPartner.touched.lastnm &&
                              registrationChannelPartner.errors.lastnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.lastnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                            <SoftBox
                              // pl={2}
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              height="auto"
                              sx={{
                                border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  defaultChecked="true"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      Setautopass(true);
                                    } else {
                                      Setautopass(false);
                                    }
                                  }}
                                />
                              </SoftBox>

                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="14px">
                                  Auto Generate Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="13px"
                                  lineHeight="16px"
                                >
                                  Password will be automatically generated after the registration is
                                  done.
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                            <SoftBox
                              // pl={2}
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              height="75px"
                              sx={{
                                border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  onChange={() => {
                                    setDisplayPassword(" ")

                                    registrationChannelPartner.setFieldValue("password", "");
                                    registrationChannelPartner.setFieldValue("confirm_password","");

                                    Setautopass(false);
                                  }}
                                />
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="14px">
                                  Custom Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="13px"
                                  lineHeight="16px"
                                >
                                  You can create a custom password for the user
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={5}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Password
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="password"
                                placeholder=""
                                disabled={autopass}
                                id="password"
                                name="password"
                                onChange={(e) => {
                                  // registrationChannelPartner.handleChange;
                                  setCopyPassword2({ ...copyPassword, value: e.target.value });
                                }}   
                                // onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.password}
                              />
                              {registrationChannelPartner.touched.password &&
                              registrationChannelPartner.errors.password ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.password}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Confirm Password
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="password"
                                placeholder=""
                                disabled={autopass}
                                id="confirm_password"
                                name="confirm_password"
                                onChange={(e) => {
                                  //  registrationChannelPartner.handleChange;
  
  
                                    setCopyPassword1({ ...copyPassword, value: e.target.value });
                                  }}   
                                // onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.confirm_password}
                              />
                              {registrationChannelPartner.touched.confirm_password &&
                              registrationChannelPartner.errors.confirm_password ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.confirm_password}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Security Question <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>
                              <SoftSelect
                                placeholder="select questions"
                                options={questions}
                                id="security_ques"
                                name="security_ques"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "security_ques",
                                    e.label
                                  );
                                  setFormNew({
                                    ...formNew,
                                    security_ques: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationChannelPartner.values.security_ques }}
                              />
                              {registrationChannelPartner.touched.security_ques &&
                              registrationChannelPartner.errors.security_ques ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ques}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Answer <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="security_ans"
                                name="security_ans"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("security_ans", e.label);
                                  setSelectedState(e.label);
                                  setFormNew({
                                    ...formNew,
                                    security_ans: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.security_ans}
                              />
                              {registrationChannelPartner.touched.security_ans &&
                              registrationChannelPartner.errors.security_ans ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ans}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      {/* access based  */}
                      {/* access based  */}

                      {/* CRM Servicess access */}
                      {/* <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={5}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Security Question
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              
                              <SoftSelect
                                placeholder="select questions"
                                options={questions}
                                id="security_ques"
                                name="security_ques"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "security_ques",
                                    e.label
                                  );
                                }}
                                value={{ label: registrationChannelPartner.values.security_ques }}
                              />
                              {registrationChannelPartner.touched.security_ques &&
                              registrationChannelPartner.errors.security_ques ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ques}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Answer
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox> *
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="security_ans"
                                name="security_ans"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.security_ans}
                              />
                              {registrationChannelPartner.touched.security_ans &&
                              registrationChannelPartner.errors.security_ans ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ans}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox> */}
                    </SoftBox>
                  </SoftBox>

                  <SoftBox pl={4} pr={14} pt={2} pb={3}>
                    <SoftTypography fontWeight="medium" fontSize="20px">
                      Documents
                    </SoftTypography>
                    <SoftTypography fontWeight="small" fontSize="15px" mb={4.5}>
                      {/* <sup style={{ color: "red" }}>*</sup>{" "} */}
                      {
                        "Please upload files < 5mb and make sure format is either jpg, jpeg, png, pdf, docx or doc."
                      }
                    </SoftTypography>
                    <SoftBox component="form" role="form">
                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Application
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="application"
                                name="application"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "application",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    application: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.application &&
                              registrationChannelPartner.errors.application ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.application}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Cover letter
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="cover_letter"
                                name="cover_letter"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "cover_letter",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    cover_letter: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.cover_letter &&
                              registrationChannelPartner.errors.cover_letter ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.cover_letter}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>

                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Pan Card
                                </SoftTypography>

                                {/* <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="pan_card"
                                name="pan_card"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "pan_card",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    pan_card: e.target.files[0],
                                  });
                                }}
                              />
                              {/* {registrationChannelPartner.touched.pan_card &&
                                registrationChannelPartner.errors.pan_card ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.pan_card}
                                </span>
                              ) : null} */}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  GST Certificate
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="gst_cert"
                                name="gst_cert"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "gst_cert",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    gst_cert: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.gst_cert &&
                              registrationChannelPartner.errors.gst_cert ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.gst_cert}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>

                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Financial Year Report (CA approved copy)
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="financial_report"
                                name="financial_report"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "financial_report",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    financial_report: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.financial_report &&
                              registrationChannelPartner.errors.financial_report ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.financial_report}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Registration Certificate
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="reg_cert"
                                name="reg_cert"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "reg_cert",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    reg_cert: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.reg_cert &&
                              registrationChannelPartner.errors.reg_cert ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.reg_cert}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>

                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Udyam Certificate
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="udyam_cert"
                                name="udyam_cert"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "udyam_cert",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    udyam_cert: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.udyam_cert &&
                              registrationChannelPartner.errors.udyam_cert ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.udyam_cert}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Declaration Letter (As per the given format)
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="declaration_cert"
                                name="declaration_cert"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "declaration_cert",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    declaration_cert: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.declaration_cert &&
                              registrationChannelPartner.errors.declaration_cert ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.declaration_cert}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>

                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  EMD
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="emd"
                                name="emd"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "emd",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    emd: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.emd &&
                              registrationChannelPartner.errors.emd ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.emd}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Tender Fee (Upload Screenshot of your transaction)
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="dorg_tender"
                                name="tender_fee"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "tender_fee",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    tender_fee: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.tender_fee &&
                              registrationChannelPartner.errors.tender_fee ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.tender_fee}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>

                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Other Documents (Work Orders, Credentials)
                                </SoftTypography>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                id="other_cert"
                                name="other_cert"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "other_cert",
                                    e.target.files[0]
                                  );
                                  setFormNew({
                                    ...formNew,
                                    other_cert: e.target.files[0],
                                  });
                                }}
                              />
                              {registrationChannelPartner.touched.other_cert &&
                              registrationChannelPartner.errors.other_cert ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.other_cert}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: isLoading ? "no-drop" : "pointer",
                      opacity: isLoading ? 0.5 : 1,
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={!isLoading && registrationChannelPartner.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Submit
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      navigate("/admin-register");
                      setFormNew({
                        email: "",
                        firstnm: "",
                        lastnm: "",
                        mobnum: "",
                        security_ques: "",
                        security_ans: "",
                        password: copyPassword.value ?? "",
                        confirm_password: copyPassword.value ?? "",
                        org_name: "",
                        org_address: "",
                        org_state: "",
                        org_district: "",
                        org_pincode: "",
                        type: "",
                        application: "",
                        cover_letter: "",
                        pan_card: "",
                        gst_cert: "",
                        financial_report: "",
                        emd: "",
                        tender_fee: "",
                        other_cert: "",
                        reg_cert: "",
                        udyam_cert: "",
                        declaration_cert: "",
                        part_of: "",
                      });
                    }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
      {/* Modal After Submit */}

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          showAlert();
        }}
        centered
      >
        <Modal.Header closeButton>Retrieve Password</Modal.Header>

        <Modal.Body>
          <SoftBox display="flex">
            {/* Password: {showpassword ? copyPassword.value : "**************"} */}
            {console.log("password modal:", displayPassword)}
            Password: {showpassword ? displayPassword : "**************"}
            <SoftBox
              ml={25}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                Setshowpassword(true);
                                copy(`${displayPassword}`);

                copy(`${copyPassword.value}`);
              }}
            >
              <Icon icon="mdi:content-copy" color="#3b5092" width="20" />
            </SoftBox>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => downloadfile()}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Download
            </SoftTypography>
          </SoftBox>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => {
              setShow(false);
              showAlert();
            }}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Cancel
            </SoftTypography>
          </SoftBox>
        </Modal.Footer>
      </Modal>
      {/* Modal After Submit */}
    </>
  );
};

export default Channelprtnerregister;
