import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Card } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import { height } from "@mui/system";
import SoftSelect from "components/SoftSelect";
// import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { borderColor } from "@mui/system";
import Range from "@atlaskit/range";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
// import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Select, { StylesConfig } from "react-select";
import styles from "components/SoftSelect/styles";
import Tooltip from "@mui/material/Tooltip";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import "./style.css";
import { Box } from "@mui/material";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual';
import { useDispatch } from 'react-redux';

const CreatePosting = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [allstate, Setallstate] = useState(false);
  const [value, setValue] = useState(150000);
  const [education, Seteducation] = useState();
  const [stream, Setstream] = useState();
  const [degree, Setdergee] = useState();
  const [addressChar, setAddressChar] = useState(200);
  const [exp, Setexp] = useState();
  const [jobrole, Setjobrole] = useState();
  const [orgList, setOrgList] = useState([]);
  const [create, setCreate] = useState(false);
  const [jobTitle, setJobtitle] = useState([]);
  const [orgNameID, setOrgNameID] = useState("");
  const [salaryList, setSalaryList] = useState([]);
  const [jobType, SetjobType] = useState([]);
  const [newddEducation, setNewDDEducation] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [jobDes, setJobDes] = useState("");
  // const [age, setAge] = useState([18, 65]); for range slider
  const [age, setAge] = useState(26);
  const resDATA = sessionStorage.getItem("register_id");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [whatsappjd, setWhatsappjd] = useState("");

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
      ['clean']
    ],
  }
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleTexteditorChange = (value) => {
    setJobDes(value)
  }

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  // dd style
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // dd style

  const ngrokLink = "https://72e2-2401-4900-6288-532f-29d1-73fb-262e-d817.ngrok-free.app";
  const reporting = [
    {
      label: "Relience digital",
      value: "Relience digital",
    },
    {
      label: "Tata Counsultant serviecs",
      value: "Tata Counsultant serviecs",
    },
    {
      label: "Uber",
      value: "Uber",
    },
    {
      label: "Swiggy",
      value: "Swiggy",
    },
  ];

  const wfm = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
    {
      label: "Hybrid",
      value: "Hybrid",
    },
  ];

  const jtype = [
    {
      label: "Full",
      value: "Full",
    },
    {
      label: "Part",
      value: "Part",
    },
    {
      label: "Intership",
      value: "Intership",
    },
  ];
  const genderOption = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "All",
      value: "All",
    },
  ];

  const jobpostformone = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      designation: "",
      applicantct: "",
      salary: "",
      jobpostdt: "",
      jobvaliddt: "",
      state: "",
      district: "",
      industry: "",
      qualification: "",
      stream: "",
      specialization: "",
      workfromhome: "",
      //jobdesc: "",
      createdby: resDATA,
      creator_role: "SA",
      headerimg: "",
      Experiance: "",
      age: "",
      gender: "",
      whatsapp_jd: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select organization name"),
      job_title: Yup.string().required("Please enter job title"),
      jobpostdt: Yup.string().required("Please select job post date"),
      jobvaliddt: Yup.string().required("Please select job post valid till date"),
      //state: Yup.string().required("Please select state"),
      whatsapp_jd: Yup.string().required("Please fill above field"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const job = new FormData();
      job.append("org_name", values.org_name);
      job.append("org_id", orgNameID);
      job.append("job_title", values.job_title);
      job.append("job_type", values.job_type);
      job.append("designation", values.designation);
      job.append("applicantct", values.applicantct);
      job.append("salary", values.salary);
      job.append("jobpostdt", values.jobpostdt);
      job.append("jobvaliddt", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      job.append("state", !allstate ? values.state : "");
      job.append("district", !allstate ? values.district : "");
      job.append("qualification", values.qualification);
      job.append("stream", values.stream);
      job.append("specialization", values.specialization);
      job.append("workfromhome", values.workfromhome);
      job.append("jobdesc", jobDes);
      job.append("createdby", values.createdby);
      job.append("creator_role", values.creator_role);
      job.append("experience", values.Experiance);
      job.append("age", age);
      job.append("gender", values.gender);
      job.append("whatsapp_jd", values.whatsapp_jd);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
          job
        )
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "success",
          text: "Job posting has been created successfully",
          showDenyButton: true,
          confirmButtonText: "Add More",
          denyButtonText: `Search Candidate`,
        }).then((result) => {
          
          if (result.isConfirmed) {
            jobpostform.resetForm();
            setOrgNameID("");
            setSelectedState();
          } else if (result.isDenied) {
            sessionStorage.setItem("job_id", res.data["JobID"]);
            console.log(res.data["JobID"]);
            sessionStorage.setItem("searchdata_org_name", values.org_name);
            sessionStorage.setItem("searchdata_org_id", orgNameID);
            sessionStorage.setItem("searchdata_state", values.state);
            sessionStorage.setItem("searchdata_district", values.district);
            resetForm()
            navigate("/searchbyposting/createposting/recommendedPostList");
          }
        })
      }
      catch (err) {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  })

  useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);

  const jobpostform = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      designation: "",
      applicantct: "",
      salary: "",
      jobpostdt: "",
      jobvaliddt: "",
      state: "",
      district: "",
      industry: "",
      qualification: "",
      stream: "",
      specialization: "",
      workfromhome: "",
      //jobdesc: "",
      createdby: resDATA,
      creator_role: "SA",
      headerimg: "",
      Experiance: "",
      age: "",
      gender: "",
      whatsapp_jd: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select organization name"),
      job_title: Yup.string().required("Please enter job title"),
      // job_type: Yup.string().required(" Please enter Job Type"),
      // designation: Yup.string().required(" Please Select Designation"),
      // applicantct: Yup.string().required(" Please Select Applicanctct"),
      // salary: Yup.string().required(" Please Select Salary"),
      jobpostdt: Yup.string().required("Please select job post date"),
      jobvaliddt: Yup.string().required("Please select job post valid till date"),
      state: Yup.string().required("Please select state"),
      whatsapp_jd: Yup.string().required("Please fill above field"),
      // district: Yup.string().required(" Please Select District"),
      //industry: Yup.string().required(" Please Select Industry"),
      // qualification: Yup.string().required(" Please Select Qualifaction"),
      // stream: Yup.string().required(" Please Select Stream"),
      // specialization: Yup.string().required(" Please Select Specialization"),
      // workfromhome: Yup.string().required(" Please Select "),
      // jobdesc: Yup.string().required(" Please Enter Job Description "),
      // Experiance: Yup.string().required(" Please Select Experiance"),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      const job = new FormData();
      job.append("org_name", values.org_name);
      job.append("org_id", orgNameID);
      job.append("job_title", values.job_title);
      job.append("job_type", values.job_type);
      job.append("designation", values.designation);
      job.append("applicantct", values.applicantct);
      job.append("salary", values.salary);
      job.append("jobpostdt", values.jobpostdt);
      job.append("jobvaliddt", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      job.append("state", !allstate ? values.state : "");
      job.append("district", !allstate ? values.district : "");
      job.append("qualification", values.qualification);
      job.append("stream", values.stream);
      job.append("specialization", values.specialization);
      job.append("workfromhome", values.workfromhome);
      job.append("jobdesc", jobDes);
      job.append("createdby", values.createdby);
      job.append("creator_role", values.creator_role);
      job.append("experience", values.Experiance);
      //job.append("age", `${age[0]} - ${age[1]}`);
      job.append("age", age);
      job.append("gender", values.gender);
      job.append("whatsapp_jd", values.whatsapp_jd);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
          // `${ngrokLink}/superadmin/candidatesearch/jobpost`,
          job
        )
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            //setJobDes("")
            Swal.fire({
              icon: "success",
              text: "Job posting has been created successfully",
              showDenyButton: true,
              // showCancelButton: true,
              confirmButtonText: "Add More",
              denyButtonText: `Search Candidate`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Swal.fire("Saved!", "", "success");
                jobpostform.resetForm();
                setOrgNameID("");
                setSelectedState();
                // location.reload();
                // jobpostform.setFieldValue("org_name", "");
                // window.reload();
                // navigate("/searchbyposting/createposting");
                // console.log("hello");
              } else if (result.isDenied) {
                // Swal.fire("Changes are not saved", "", "info");
                sessionStorage.setItem("job_id", res.data["JobID"]);
                console.log(res.data["JobID"]);
                sessionStorage.setItem("searchdata_org_name", values.org_name);
                sessionStorage.setItem("searchdata_org_id", orgNameID);
                // sessionStorage.setItem("searchdata_designation", values.designation);
                // sessionStorage.setItem("searchdata_salary", values.salary);
                sessionStorage.setItem("searchdata_state", values.state);
                sessionStorage.setItem("searchdata_district", values.district);
                // sessionStorage.setItem("searchdata_industry", values.industry);
                // sessionStorage.setItem("searchdata_qualification", values.qualification);
                // sessionStorage.setItem("searchdata_experience", values.Experiance);
                // sessionStorage.setItem("searchdata_stream", values.stream);
                // sessionStorage.setItem("searchdata_specialization", values.specialization);
                navigate("/searchbyposting/createposting/recommendedPostList");
              }
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Oops...",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  });

  const TermSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#52af77",
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });
  const handleAgeChange = (event, newValue, activeThumb) => {
    setAge(newValue === "" ? 26 : newValue);
    // jobpostform.handleChange;

    // for range slider
    // if (!Array.isArray(newValue)) {
    //   return;
    // }

    // if (newValue[1] - newValue[0] < 10) {
    //   if (activeThumb === 0) {
    //     const clamped = Math.min(newValue[0], 65 - 10);
    //     console.log("age slider:", [clamped, clamped + 10]);
    //     setAge([clamped, clamped + 10]);
    //   } else {
    //     const clamped = Math.max(newValue[1], 10);
    //     console.log("age slider:", [clamped - 10, clamped]);
    //     setAge([clamped - 10, clamped]);
    //   }
    // } else {
    //   console.log("age slider:", newValue);
    //   setAge(newValue);
    // }
  };
  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobtitle`, {
  //       params: {
  //         org_name: orgNameID,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res.data.org_title);
  //       setJobtitle(
  //         res.data.org_title.map((ele) => ({
  //           label: ele.job_title,
  //           value: ele.job_title,
  //         }))
  //       );
  //     });
  // }, [orgNameID]);
  console.log("panindia ", allstate);
  useEffect(() => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobtitle`,
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`, {
        params: {
          // org_name: orgNameID,
          orgid: orgNameID,
          type: "dropdown",
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        },
      })
      .then((res) => {
        console.log(res.data.org_title);
        setJobtitle(res.data["JobPost_List"]);
        // setJobtitle(
        //   res.data.org_title.map((ele) => ({
        //     label: ele.job_title,
        //     value: ele.job_title,
        //   }))
        // );
      });
  }, [orgNameID]);

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
        setNewDDEducation(res.data.map((ele) => ele.value));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        Setstream(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchjobtype = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/jobtype`)
      .then((res) => {
        SetjobType(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOrgList = () => {
    const params = {
      partner: "samarth",
      type: "dropdown",
      created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
      // type: "detail",
    };
    const headers = {
      "access-control-allow-origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_List"]);
        setOrgList(res.data["Organisation_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };
  useEffect(() => {
    fetchStateList();
    fetchjobrole();
    fetchexp();
    fetchdegree();
    fetchstream();
    fetcheducation();
    fetchOrgList();
    fetchSalary();
    fetchjobtype();
  }, []);

  useEffect(() => {
    fetchDistricts();
    // setDistrict([]);
  }, [selectedState]);


  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox style={{ borderRadius:"20px", background:"white" , padding:"20px" , overflowY:"scroll" , height:"670px" , }}  
                      width="1100px"
 >
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftBox
              display="flex"
              flexDirection="column"
              gap="20px"
              justifyContent="space-between"
              width="1000px"
            >
              <SoftTypography
                fontStyle="normal"
                fontWeight="medium"
                fontSize="24px"
                lineHeight="30px"
              >
                Job Posting
              </SoftTypography>
            </SoftBox>
            <SoftBox component="form" role="form" onSubmit={jobpostformone.handleSubmit}>
              <Grid container>
                <SoftBox mt={4} width="1000px" height="auto">
                  <SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              mb={1.5}
                            >
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography
                                  fontWeight="medium"
                                  fontSize="16px"
                                  lineHeight="20px"
                                >
                                  Organization Name
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftBox
                                display="flex"
                                flexDirection="row"
                                gap="10px"
                                onClick={() => {
                                  navigate("/jobprovider-registration");
                                }}
                              >
                                <Tooltip key="1" title="Add a new organisation" placement="bottom">
                                  <SoftBox
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15px"
                                      height="15px"
                                      viewBox="0 0 10 10"
                                      fill="none"
                                    >
                                      <rect width="10" height="10" rx="5" fill="#3B5092" />
                                      <path
                                        d="M5 2V8M2 5H8"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </SoftBox>
                                </Tooltip>
                                {/* <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="13px"
                                  lineHeight="17px"
                                >
                                  Add a new organisation
                                </SoftTypography> */}
                              </SoftBox>
                            </SoftBox>
                            {/* <SoftInput type="text" placeholder="" /> */}
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                placeholder="Select organization name"
                                options={orgList}
                                // value={orgList.filter((ele) => ele.value === orgNameID)}
                                onChange={(e) => (
                                  jobpostformone.setFieldValue("org_name", e ? e.label : ""),
                                  setOrgNameID(e ? e.value : "")
                                )}
                              />
                            </SoftBox>
                            {jobpostformone.touched.org_name && jobpostformone.errors.org_name ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.org_name}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox
                              width="100%"
                              mb={1}
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography
                                  fontWeight="medium"
                                  fontSize="16px"
                                  lineHeight="20px"
                                  mb={1}
                                >
                                  Job Title
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftBox
                                display="flex"
                                flexDirection="row"
                                gap="10px"
                                onClick={() => {
                                  setCreate(!create);
                                }}
                              >
                                <Tooltip
                                  key="2"
                                  title={!create ? `Create New Job Title` : `Select From Existing `}
                                  placement="bottom"
                                >
                                  <SoftBox
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15px"
                                      height="15px"
                                      viewBox="0 0 10 10"
                                      fill="none"
                                    >
                                      <rect width="10" height="10" rx="5" fill="#3B5092" />
                                      <path
                                        d="M5 2V8M2 5H8"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </SoftBox>
                                </Tooltip>
                                {/* <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="10px"
                                  lineHeight="17px"
                                >
                                  {!create ? `Create New Job Title` : `Select From Existing `}
                                </SoftTypography> */}
                              </SoftBox>
                            </SoftBox>
                            {create ? (
                              <SoftInput
                                type="text"
                                placeholder="Job title"
                                name="job_title"
                                id="job_title"
                                onChange={jobpostformone.handleChange}
                                value={jobpostformone.values.job_title}
                              />
                            ) : (
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  isClearable
                                  placeholder="Please enter job title"
                                  options={jobTitle}
                                  onChange={
                                    (e) => jobpostformone.setFieldValue("job_title", e ? e.label : "")
                                    // setOrgNameID(e.value)
                                  }
                                />
                              </SoftBox>
                            )}
                            {/* <Card >
                                                        <SoftSelect   options={reporting} />
                                                    </Card> */}
                            {jobpostformone.touched.job_title && jobpostformone.errors.job_title ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.job_title}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Job Type
                            </SoftTypography>
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                placeholder="Select job type"
                                options={jobType}
                                onChange={(e) => {
                                  jobpostformone.setFieldValue("job_type", e ? e.label : "");
                                }}
                              />
                            </SoftBox>
                            {jobpostformone.touched.job_type && jobpostform.errors.job_type ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.job_type}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox display="flex">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Valid Till
                              </SoftTypography>
                              <SoftBox
                                sx={{
                                  color: "red",
                                  position: "inherit",
                                  paddingLeft: "2px",
                                  fontSize: "15px",
                                }}
                              >
                                <sup>*</sup>
                              </SoftBox>
                            </SoftBox>
                            <SoftInput
                              type="datetime-local"
                              placeholder=""
                              name="jobvaliddt"
                              id="jobvaliddt"
                              onChange={jobpostformone.handleChange}
                              value={jobpostformone.values.jobvaliddt}
                            />
                            {jobpostformone.touched.jobvaliddt && jobpostformone.errors.jobvaliddt ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.jobvaliddt}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              No.of Professional
                            </SoftTypography>
                            <SoftInput
                              type="number"
                              placeholder=""
                              name="applicantct"
                              id="applicantct"
                              onChange={jobpostformone.handleChange}
                              value={jobpostformone.values.applicantct}
                            />
                            {jobpostformone.touched.applicantct && jobpostformone.errors.applicantct ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.applicantct}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Role
                            </SoftTypography>
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                placeholder="Select role"
                                options={jobrole}
                                onChange={(e) => {
                                  jobpostformone.setFieldValue("designation", e ? e.label : "");
                                }}
                              />
                            </SoftBox>
                            {jobpostformone.touched.designation && jobpostformone.errors.designation ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.designation}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox display="flex">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Date of Posting
                              </SoftTypography>
                              <SoftBox
                                sx={{
                                  color: "red",
                                  position: "inherit",
                                  paddingLeft: "2px",
                                  fontSize: "15px",
                                }}
                              >
                                <sup>*</sup>
                              </SoftBox>
                            </SoftBox>
                            <SoftInput
                              type="date"
                              placeholder=""
                              name="jobpostdt"
                              id="jobpostdt"
                              onChange={jobpostformone.handleChange}
                              value={jobpostformone.values.jobpostdt}
                            />
                            {jobpostformone.touched.jobpostdt && jobpostformone.errors.jobpostdt ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.jobpostdt}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Salary Range
                            </SoftTypography>
                            {/* <SoftBox display="flex" flexDirection="column">
                              {
                              
                              <Range
                                step={1}
                                defaultValue={150000}
                                min={100000}
                                max={300000}
                                onChange={(value) => {
                                  console.log("new value", value);
                                  setValue(value);
                                  jobpostform.setFieldValue("salary", value);
                                }}
                              />
                              <SoftTypography fontWeight="medium" fontSize="15px" lineHeight="15px">
                                Salary : {value}
                              </SoftTypography>
                             
                              {jobpostform.touched.salary && jobpostform.errors.salary ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {jobpostform.errors.salary}{" "}
                                </span>
                              ) : null}
                            </SoftBox> */}
                            {/* <SoftBox display="flex" flexDirection="row" gap="20px"> */}
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                options={salaryList}
                                placeholder="Select salary range"
                                onChange={(e) => {
                                  jobpostformone.setFieldValue("salary", e ? e.label : "");
                                }}
                              />
                            </SoftBox>
                            {jobpostformone.touched.salary && jobpostformone.errors.salary ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.salary}{" "}
                              </span>
                            ) : null}
                            {/* <Card><SoftSelect options={reporting} /></Card>  */}
                            {/* <input type="range" width="100%" min={25000} max={90000}/> */}
                            {/* </SoftBox> */}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox
                              width="100%"
                              mb={1}
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography
                                  fontWeight="medium"
                                  fontSize="16px"
                                  lineHeight="20px"
                                  mb={1}
                                >
                                  Gender
                                </SoftTypography>
                                {/* <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                placeholder="Select gender"
                                options={genderOption}
                                // value={
                                //   jobpostform.values.gender !== null
                                //     ? {
                                //         label: jobpostform.values.gender,
                                //         value: jobpostform.values.gender,
                                //       }
                                //     : jobpostform.setFieldValue("gender", "")
                                // }
                                onChange={(e) => {
                                  // jobpostform.values.gender !== null
                                  //   ? jobpostform.setFieldValue("gender", e.label)
                                  //   : jobpostform.setFieldValue("gender", "");
                                  jobpostformone.setFieldValue("gender", e ? e.label : "");
                                }}
                              />
                            </SoftBox>
                            {jobpostformone.touched.gender && jobpostformone.errors.gender ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.gender}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              {/* Age: {`${age[0]} - ${age[1]}`} */}
                              Age: {age}
                            </SoftTypography>
                            <SoftBox>
                              {/* <PrettoSlider
                                // defaultValue={26}
                                aria-label="pretto slider"
                                valueLabelDisplay="auto"
                                min={18}
                                max={65}
                                value={age}
                                onChange={handleAgeChange}
                                // name="age"
                                // id="age"
                                // onChange={jobpostform.handleChange}
                              /> */}
                              <ThemeProvider theme={TermSlider}>
                                <Slider
                                  name="termValue"
                                  // getAriaLabel={() => "Minimum distance shift"}
                                  // disableSwap
                                  valueLabelDisplay="auto"
                                  aria-label="Default"
                                  min={18}
                                  max={65}
                                  value={age}
                                  defaultValue={26}
                                  onChange={handleAgeChange}
                                />
                              </ThemeProvider>
                            </SoftBox>

                            {jobpostformone.touched.age && jobpostformone.errors.age ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.age}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={1}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2} display="flex">
                            <Form.Check
                              type="checkbox"
                              id="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  Setallstate(true);
                                } else {
                                  Setallstate(false);
                                }
                              }}
                            />
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              ml={2}
                              mt={1}
                            >
                              Pan India
                            </SoftTypography>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    {!allstate && (
                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography
                                  fontWeight="medium"
                                  fontSize="16px"
                                  lineHeight="20px"
                                  mb={1}
                                >
                                  State
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  isClearable
                                  options={state}
                                  onChange={(currentTarget) => (
                                    // setSelectedState(currentTarget.value),
                                    // !allstate
                                    //   ? jobpostform.setFieldValue("state", currentTarget.value)
                                    //   : ""
                                    setSelectedState(currentTarget ? currentTarget.value : ""),
                                    !allstate
                                      ? jobpostformone.setFieldValue(
                                        "state",
                                        currentTarget ? currentTarget.value : ""
                                      )
                                      : ""
                                  )}
                                />
                              </SoftBox>
                              {jobpostformone.touched.state && jobpostformone.errors.state ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {jobpostformone.errors.state}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                District
                              </SoftTypography>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  isClearable
                                  isMulti
                                  options={district}
                                  onChange={(e) => (
                                    // !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                                    console.log(
                                      "multi district:",
                                      e.map((ele) => ele.value).join(",")
                                    ),
                                    !allstate
                                      ? jobpostformone.setFieldValue(
                                        "district",
                                        e.map((ele) => ele.value).join(",")
                                      )
                                      : ""
                                  )}
                                />
                              </SoftBox>
                              {jobpostformone.touched.district && jobpostformone.errors.district ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {jobpostformone.errors.district}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    )}

                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Highest Education
                            </SoftTypography>
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                isMulti
                                options={education}
                                // onChange={(e) =>
                                //   jobpostform.setFieldValue("qualification", e ? e.value : "")
                                // }
                                onChange={(e) => (
                                  // !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                                  console.log(
                                    "multi education:",
                                    e.map((ele) => ele.value).join(",")
                                  ),
                                  jobpostformone.setFieldValue(
                                    "qualification",
                                    e.map((ele) => ele.value).join(",")
                                  )
                                )}
                              />
                            </SoftBox>
                            {jobpostformone.touched.qualification &&
                              jobpostformone.errors.qualification ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.qualification}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Stream
                            </SoftTypography>
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                options={stream}
                                onChange={(e) =>
                                  jobpostformone.setFieldValue("stream", e ? e.value : "")
                                }
                              />
                            </SoftBox>
                            {jobpostformone.touched.stream && jobpostformone.errors.stream ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.stream}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Specialization
                            </SoftTypography>
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                options={degree}
                                onChange={(e) =>
                                  jobpostformone.setFieldValue("specialization", e ? e.value : "")
                                }
                              />
                            </SoftBox>
                            {jobpostformone.touched.qualification &&
                              jobpostformone.errors.qualification ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.qualification}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Experience
                            </SoftTypography>
                            <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                              <Select
                                styles={stylesDD}
                                isClearable
                                options={exp}
                                onChange={(e) =>
                                  jobpostformone.setFieldValue("Experiance", e ? e.value : "")
                                }
                              />
                            </SoftBox>

                            {jobpostformone.touched.Experiance && jobpostformone.errors.Experiance ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.Experiance}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={3}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox
                            width="100%"
                            pl={2}
                            display="flex"
                            flexDirection="row"
                            // alignItems="center"
                            gap="30px"
                          // justifyContent=""
                          >
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Work from home availability
                            </SoftTypography>
                            {/* <Card>
                              <SoftSelect
                                options={wfm}
                                onChange={(e) => jobpostform.setFieldValue("workfromhome", e.value)}
                              />
                            </Card> */}
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              gap="20px"
                            >
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <input
                                  type="radio"
                                  value="Yes"
                                  name="keyword"
                                  // defaultChecked
                                  // checked={jobpostform.workfromhome === "Yes"}
                                  style={{ cursor: "pointer" }}
                                  onChange={(e) => (
                                    console.log("valuesss ", e.target.value),
                                    jobpostformone.setFieldValue("workfromhome", e.target.value),
                                    // )
                                    console.log("value radio ", jobpostformone.workfromhome)
                                  )}
                                // onChange={jobpostform.handleChange}
                                />
                                {/* <input
                                  type="checkbox"
                                  name="checkbox"
                                  value
                                  checked={check}
                                  onChange={() => setCheck(!check)}
                                  placeholder=""
                                /> */}
                                <SoftTypography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "small",
                                    fontSize: "15px",
                                    lineHeight: "19px",
                                  }}
                                >
                                  Yes
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <input
                                  type="radio"
                                  value="No"
                                  name="keyword"
                                  style={{ cursor: "pointer" }}
                                  // checked={jobpostform.workfromhome === "No"}
                                  onChange={(e) => (
                                    console.log("valuesss ", e.target.value),
                                    jobpostformone.setFieldValue("workfromhome", e.target.value),
                                    console.log("value radio ", jobpostformone.workfromhome)
                                  )}
                                />
                                <SoftTypography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "small",
                                    fontSize: "15px",
                                    lineHeight: "19px",
                                  }}
                                >
                                  No
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                display="flex"
                                flexDirection="row"
                                gap="5px"
                                sx={{ cursor: "pointer" }}
                              >
                                <input
                                  type="radio"
                                  value="Hybrid"
                                  name="keyword"
                                  // style={{ cursor: "pointer" }}
                                  // checked={jobpostform.workfromhome === "Hybrid"}
                                  onChange={(e) => (
                                    console.log("valuesss ", e.target.value),
                                    jobpostformone.setFieldValue("workfromhome", e.target.value),
                                    console.log("value radio ", jobpostformone.workfromhome)
                                  )}
                                />
                                <SoftTypography
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "small",
                                    fontSize: "15px",
                                    lineHeight: "19px",
                                    // cursor: "pointer",
                                  }}
                                >
                                  Hybrid
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox pl={2}>
                            {jobpostformone.touched.workfromhome && jobpostformone.errors.workfromhome ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.workfromhome}{" "}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        {/* <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              New
                            </SoftTypography>
                            <Card>
                              <Select
                                options={education}
                                closeMenuOnSelect={false}
                                value={educationList}
                                isMulti
                                onChange={(e) => {
                                  // console.log("before ", e.target.value),
                                  if (e) {
                                    setEducationList(e.map((item) => item));
                                  } else {
                                    setEducationList([]);
                                  }
                                  jobpostform.setFieldValue(
                                    "education",
                                    educationList.map((ele) => ele.value).toString()
                                  );
                                }}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    // borderColor: state.isFocused ? "grey" : "red",
                                    fontSize: "14px",
                                  }),
                                  option: (base) => ({
                                    ...base,
                                    // border: `1px dotted red`,
                                    height: "100%",
                                    fontSize: "14px",
                                  }),
                                }}
                              />
                            </Card>
                          </SoftBox>
                        </Grid> */}
                      </Grid>
                    </SoftBox>
                    <SoftBox mb={0}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={12} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox display="flex">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                WhatsApp Job Description
                              </SoftTypography>

                              <SoftBox
                                sx={{
                                  color: "red",
                                  position: "inherit",
                                  paddingLeft: "2px",
                                  fontSize: "15px",
                                }}
                              >
                                <sup>*</sup>
                              </SoftBox>
                            </SoftBox>

                            <SoftInput
                              type="text"
                              placeholder=""
                              multiline
                              rows={5}
                              id="whatsapp_jd"
                              name="whatsapp_jd"
                              inputProps={{
                                maxLength: "200",
                              }}
                              onChange={jobpostformone.handleChange}
                              value={jobpostformone.values.whatsapp_jd}
                            />
                            <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                              {jobpostformone.values.whatsapp_jd?.length}/{addressChar}
                            </SoftTypography>

                            {jobpostformone.touched.whatsapp_jd && jobpostformone.errors.whatsapp_jd ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostformone.errors.whatsapp_jd}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={12} container spacing={3}>
                          <SoftBox width="100%" height="100%" pl={2}>
                            <SoftTypography
                              fontWeight="medium"
                              fontSize="16px"
                              lineHeight="20px"
                              mb={1}
                            >
                              Job Description
                            </SoftTypography>

                            <Box sx={{ background: '#FFFFFF' }}>
                              <ReactQuill
                                value={jobDes || ''}
                                defaultValue=''
                                modules={modules}
                                formats={formats}
                                onChange={handleTexteditorChange}
                              />
                            </Box>
                            <br />

                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                              <SoftBox
                                m={1}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                  height: "45px",
                                  backgroundColor: "#3B5092",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  //opacity: isLoading ? 0.5 : 1,
                                }}
                                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                //onClick={!isLoading && jobpostform.handleSubmit}
                                onClick={jobpostformone.handleSubmit}
                              >
                                <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  sx={{
                                    color: "#fff",
                                    fontSize: "15px",
                                    fontWeight: "medium",
                                    lineHeight: "19px",
                                  }}
                                >
              {translate('submit')}       
                                </SoftTypography>
                              </SoftBox>

                              <SoftBox
                                pl={2}
                                m={1}
                                sx={{
                                  height: "45px",
                                  backgroundColor: "#FFFFFF",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                // onClick={() => navigate(-1)}
                              onClick={() => navigate("/searchbyposting/allposting")}
                              >
                                <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  sx={{
                                    color: "#3B5092",
                                    fontSize: "15px",
                                    fontWeight: "medium",
                                    lineHeight: "19px",
                                  }}
                                >
              {translate('cancel')}       
                                </SoftTypography>
                              </SoftBox>
                            </Box>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default CreatePosting;
