import { Card, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Modal from "react-bootstrap/Modal";
import SoftSelect from "../../components/SoftSelect";
import SoftInput from "components/SoftInput";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { width } from "@mui/system";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const copy = require("clipboard-copy");
const Jobseekarreg = () => {
  // const [autopass, Setautopass] = useState(false);
  const navigate = useNavigate();
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [autopass, Setautopass] = useState(true);
  const [check, setCheck] = useState(false);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });
  const [questions, setQuestions] = useState([]);
  const [show, setShow] = useState(false);
  const [showpassword, Setshowpassword] = useState(false);
  // const questions = [
  //   {
  //     label: "What was the house number and street name you lived in as a child?",
  //     value: "What was the house number and street name you lived in as a child?",
  //   },
  //   {
  //     label: "What were the last four digits of your childhood telephone number?",
  //     value: "What were the last four digits of your childhood telephone number?",
  //   },
  //   {
  //     label: "What primary school did you attend?",
  //     value: "What primary school did you attend?",
  //   },
  //   {
  //     label: "In what town or city was your first full time job?",
  //     value: "In what town or city was your first full time job?",
  //   },
  //   {
  //     label: "In what town or city did you meet your spouse or partner?",
  //     value: "In what town or city did you meet your spouse or partner?",
  //   },
  //   {
  //     label: "What is the middle name of your oldest child?",
  //     value: "What is the middle name of your oldest child?",
  //   },
  //   {
  //     label: "What are the last five digits of your driver's license number?",
  //     value: "What are the last five digits of your driver's license number?",
  //   },
  //   {
  //     label: "What is your grandmother's (on your mother's side) maiden name?",
  //     value: "What is your grandmother's (on your mother's side) maiden name?",
  //   },
  //   {
  //     label: "What is your spouse or partner's mother's maiden name?",
  //     value: "What is your spouse or partner's mother's maiden name?",
  //   },
  //   {
  //     label: "In what town or city did your parents meet?",
  //     value: "In what town or city did your parents meet?",
  //   },
  //   {
  //     label: "What time of the day were you born? (hh:mm)",
  //     value: "What time of the day were you born? (hh:mm)",
  //   },
  //   {
  //     label: "What time of the day was your first child born? (hh:mm)",
  //     value: "What time of the day was your first child born? (hh:mm)",
  //   },
  // ];

  const showAlert = () =>
    Swal.fire({
      //position: "top",
      icon: "success",
      title: "Registration Successful",
      text: "Job Seekar registered successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      window.location = "/jobseeker-list-page";
    });

  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [`Auto Generate Password : ${showpassword ? displayPassword : copyPassword.value}`],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };
  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data?.Password });
    });
  };
  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const registrationChannelPartner = useFormik({
    enableReinitialize: true,
    initialValues: {
      //   "createdby": "SA1",
      // "creator_role": "SA",
      email: "",
      firstnm: "",
      lastnm: "",
      mobnum: "",
      password: copyPassword.value ?? "",
      role_id: "",
      security_ans: "",
      security_ques: "",
      experience: "",
      skills: "",
      confirm_password: copyPassword.value ?? "",
      //   form_link: formLink,
      //   partner_association: "",
      //   assign_to_owner: "",
      // "assign_to_owner": "SA"
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Enter email"),
      security_ques: Yup.string().required("Please select a question "),
      security_ans: Yup.string().required("Please enter your answer"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
      experience: Yup.string().required("Please enter experience"),
      skills: Yup.string().required("Please add your skills"),
    }),
    onSubmit: (values) => {
      delete values.confirm_password;
      // delete values.partner_association;
      // let formData = new FormData();
      // formData.append("role_id", "JP");
      // // formData.append("creator_role", roleId);
      // formData.append("createdby", registerID);
      // formData.append("wa_notify", check ? "true" : "false");
      // formData.append("firstnm", values.firstnm);
      // formData.append("lastnm", values.lastnm);
      // formData.append("mobnum", values.mobnum);
      // formData.append("email", values.email);
      // formData.append("security_ques", values.security_ques);
      // formData.append("security_ans", values.security_ans);
      // formData.append("password", values.password);
      // formData.append("org_name", values.org_name);
      // formData.append("org_address", values.org_address);
      // formData.append("org_state", values.org_state);
      // formData.append("org_district", values.org_district);
      // formData.append("org_pincode", values.org_pincode);
      // formData.append("org_cin_num", values.org_cin_num);
      // formData.append("org_size", values.org_size);
      // formData.append("org_type", values.org_type);
      // formData.append("org_sector", values.org_sector);
      // formData.append("org_logo", resumefile);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL_REGISTER}/jobseeker/register`,
          {
            ...values,
            role_id: "JS",
            creator_role: roleId,
            // creator_role: "SA",
            createdby: registerID,
            wa_notify: check ? "true" : "false",
          }
          // formData
        )
        .then((res) => {
          if (res.status === 201) {
            setShow(true);
            setResumeFile("");
          } else {
            Swal.fire({
              icon: "info",
              title: res.data["Developer Meassage"] ? res.data["Developer Meassage"] : "",
              text: res.data["User Message"],
              showConfirmButton: false,
              timer: 3000,
            });
            // .then(() => {
            //   navigate("/jobprovider-registration");
            // });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? `${err.response.data["Developer Message"]}`
              : "Oops...",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
          // .then(() => {
          //   navigate("/superadmin/dashboard");
          // });
        });
    },
  });

  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, [autopass]);
  useEffect(() => {
    securityQuestionFunc();
  }, []);
  console.log("copypass ", copyPassword);
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              Job Seekar Registration
            </SoftTypography>
            <Grid container minWidth="70%">
              <SoftBox mt={4} width="100%" height="auto">
                <Card minWidth="100%" sx={{ overflow: "visible" }}>
                  <SoftBox pl={4} pr={14} pt={2} pb={3}>
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                      Job Seekar Details
                    </SoftTypography>
                    <SoftBox component="form" role="form" mb={3}>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Email
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder=""
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.email}
                              />
                              {registrationChannelPartner.touched.email &&
                              registrationChannelPartner.errors.email ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.email}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Moile Number
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder=""
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.mobnum}
                              />
                              <SoftBox display="flex" mt={1}>
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  placeholder=""
                                  checked={check}
                                  onChange={() => setCheck(!check)}
                                />
                                <SoftTypography fontWeight="medium" fontSize="12px">
                                  &nbsp; You&apos;ll be Receiving Updates On{" "}
                                  <Icon
                                    icon="mdi:whatsapp"
                                    color="green"
                                    width="12"
                                    inline={true}
                                  />{" "}
                                  Whatsapp
                                </SoftTypography>
                              </SoftBox>
                              {registrationChannelPartner.touched.mobnum &&
                              registrationChannelPartner.errors.mobnum ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.mobnum}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={0}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                First Name
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder=""
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.firstnm}
                              />
                              {registrationChannelPartner.touched.firstnm &&
                              registrationChannelPartner.errors.firstnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.firstnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Last Name
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder=""
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.lastnm}
                              />
                              {registrationChannelPartner.touched.lastnm &&
                              registrationChannelPartner.errors.lastnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.lastnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pl={4} pr={14} pt={0} pb={4}>
                    <SoftBox component="form" role="form">
                      {/* <SoftBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        mb={3}
                      >
                        <SoftBox width="429px">
                          <SoftTypography fontWeight="medium" fontSize="16px">
                            Email
                          </SoftTypography>
                          <SoftInput type="email" placeholder="" />
                        </SoftBox>
                        <SoftBox width="429px">
                          <SoftTypography fontWeight="medium" fontSize="16px">
                            Mobile Number
                          </SoftTypography>
                          <SoftInput type="number" placeholder="" />
                        </SoftBox>
                      </SoftBox> */}
                      {/* <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              width="440px"
                              height="68px"
                              sx={{
                                border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  defaultChecked="true"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      Setautopass(true);
                                    } else {
                                      Setautopass(false);
                                    }
                                  }}
                                />
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="18px">
                                  Auto Generate Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="15px"
                                  lineHeight="16px"
                                >
                                  Password will be automatically generated after the registration is
                                  done.
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              width="440px"
                              height="68px"
                              sx={{
                                border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  // defaultChecked="true"
                                  onChange={() => Setautopass(false)}
                                />
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="18px">
                                  Custom Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="15px"
                                  lineHeight="14px"
                                >
                                  You can create a custom password for the user
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox> */}
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                            <SoftBox
                              // pl={2}
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              height="auto"
                              sx={{
                                border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  defaultChecked="true"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      Setautopass(true);
                                    } else {
                                      Setautopass(false);
                                    }
                                  }}
                                />
                              </SoftBox>

                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="18px">
                                  Auto Generate Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="15px"
                                  lineHeight="16px"
                                >
                                  Password will be automatically generated after the registration is
                                  done.
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                            <SoftBox
                              // pl={2}
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              height="75px"
                              sx={{
                                border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  onChange={() => {
                                    registrationChannelPartner.setFieldValue("password", "");
                                    registrationChannelPartner.setFieldValue(
                                      "confirm_password",
                                      ""
                                    );
                                    Setautopass(false);
                                  }}
                                />
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="18px">
                                  Custom Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="15px"
                                  lineHeight="16px"
                                >
                                  You can create a custom password for the user
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Password
                              </SoftTypography>
                              <SoftInput
                                type="number"
                                placeholder=""
                                disabled={autopass}
                                id="password"
                                name="password"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.password}
                              />
                              {registrationChannelPartner.touched.password &&
                              registrationChannelPartner.errors.password ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.password}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Confirm Password
                              </SoftTypography>
                              <SoftInput
                                type="number"
                                placeholder=""
                                disabled={autopass}
                                id="confirm_password"
                                name="confirm_password"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.confirm_password}
                              />
                              {registrationChannelPartner.touched.confirm_password &&
                              registrationChannelPartner.errors.confirm_password ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.confirm_password}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Security Question
                              </SoftTypography>
                              {/* <SoftInput component="select" /> */}
                              <SoftSelect
                                placeholder="select questions"
                                options={questions}
                                id="security_ques"
                                name="security_ques"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "security_ques",
                                    e.label
                                  );
                                }}
                                value={{ label: registrationChannelPartner.values.security_ques }}
                              />
                              {registrationChannelPartner.touched.security_ques &&
                              registrationChannelPartner.errors.security_ques ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ques}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Answer
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="security_ans"
                                name="security_ans"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.security_ans}
                              />
                              {registrationChannelPartner.touched.security_ans &&
                              registrationChannelPartner.errors.security_ans ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ans}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Year Of Expirence
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder="add experience"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.experience}
                              />
                              {registrationChannelPartner.touched.experience &&
                              registrationChannelPartner.errors.experience ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.experience}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Skill Set
                              </SoftTypography>
                              <SoftInput
                                type="number"
                                placeholder="add skills"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.skills}
                              />
                              {registrationChannelPartner.touched.skills &&
                              registrationChannelPartner.errors.skills ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.skills}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={registrationChannelPartner.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Submit
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => navigate("/super-admin-register")}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
            {/* <SoftBox mt={2} display="flex" gap="15px">
              {/* <SoftButton variant="gradient" color="info" size="large" onClick={showAlert}>
                        Submit
                    </SoftButton>
                    <SoftButton size="large" ml={2}>
                        Cancel
                    </SoftButton> *
              <SoftBox
                sx={{
                  width: "225px",
                  height: "45px",
                  backgroundColor: "#3B5092",
                  padding: "13px 84px",
                  borderRadius: "5px",
                }}
                onClick={registrationChannelPartner.handleSubmit}
              >
                <SoftTypography
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Submit
                </SoftTypography>
              </SoftBox>
              <SoftBox
                sx={{
                  width: "225px",
                  height: "45px",
                  backgroundColor: "#FFFFFF",
                  padding: "13px 84px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/super-admin-register")}
              >
                <SoftTypography
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Cancel
                </SoftTypography>
              </SoftBox>
            </SoftBox> */}
          </SoftBox>
        </SoftBox>
        {/* Modal After Submit */}

        <Modal
          show={show}
          onHide={() => {
            setShow(false);
            showAlert();
          }}
          centered
        >
          <Modal.Header closeButton>Retrieve Password</Modal.Header>

          <Modal.Body>
            <SoftBox display="flex">
              Password: {showpassword ? copyPassword.value : "**************"}
              <SoftBox
                ml={25}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  Setshowpassword(true);
                  copy(`${copyPassword.value}`);
                }}
              >
                <Icon icon="mdi:content-copy" color="#3b5092" width="20" />
              </SoftBox>
            </SoftBox>
          </Modal.Body>
          <Modal.Footer>
            <SoftBox
              pl={2}
              sx={{
                height: "45px",
                backgroundColor: "#3B5092",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => downloadfile()}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
              >
                Download
              </SoftTypography>
            </SoftBox>
            <SoftBox
              pl={2}
              sx={{
                height: "45px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => {
                setShow(false);
                showAlert();
              }}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{
                  color: "#3B5092",
                  fontSize: "15px",
                  fontWeight: "medium",
                  lineHeight: "19px",
                }}
              >
                Cancel
              </SoftTypography>
            </SoftBox>
          </Modal.Footer>
        </Modal>
        {/* Modal After Submit */}
      </DashboardLayout>
    </>
  );
};

export default Jobseekarreg;
