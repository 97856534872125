import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
// import Select from "react-select";
import { Icon } from "@iconify/react";
import "../style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import MapCoOrdinates from "pages/MapCoordinates/MapCoOrdinates";
import { SiGooglemaps } from "react-icons/si";

const copy = require("clipboard-copy");
const CounsellourRegistration = () => {
  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({
    // role_id: "",
    firstnm: "",
    lastnm: "",
    mobnum: "",
    email: "",
    security_ques: "",
    security_ans: "",
    assigned_to_owner: "", //org_id
    profile_picture: "",
    // password: copyPassword.value ?? "",
    // createdby: "Sapio",
    // creator_role: "SA",
    // confirm_password: copyPassword.value ?? "",
  });
  const [registerID, setRegisterID] = useState("SA1");
  //   const [roleId, setRoleId] = useState("SA");
  const [show, setShow] = useState(false);
  const [autopass, Setautopass] = useState(true);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });
  const [questions, setQuestions] = useState([]);
  //   const [centerID, setCenterID] = useState("");
  const [centerOwnerList, setCenterOwnerList] = useState([]);
  const [projectOwnerList, setProjectOwnerList] = useState([]);
  const [selectProjectOwner, setSelectProjectOwner] = useState("");
  const [selectCenterOwner, setSelectCenterOwner] = useState("");
  const [showpassword, Setshowpassword] = useState(false);
  const [displayPassword, setDisplayPassword] = useState("");
  const [resumefile, setResumeFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //   const [show, Setshow] = useState(false);
  //   const [centerLocationList,setCenterLocationList] = useState([]);
  //   const [centerOwnerList,setCenterOwnerList] = useState([]);
  //   const [State,setState] = useState([]);
  //   const [District,setDistrict] = useState([]);
  //   const [Taluka,setTaluka] = useState([]);
  //   const [selectedState,setSelectedState] = useState("");
  //   const [selectedDistrict,setSelectedDistrict] = useState("");
  //   const [selectedTaluka,setSelectedTaluka] = useState("");
  //   const [selectedLocation,setSelectedLocation] = useState("");

  // const [centerOwner,setCenterOwner] = useState("samarth");
  //   const [centerNameList, setCenterNameList] = useState([]);

  const ref = useRef(null);
  const roleOptions = [
    { label: "Head", value: "Head" },
    { label: "Associate", value: "Associate" },
  ];
  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [`Auto Generate Password : ${showpassword ? displayPassword : copyPassword.value}`],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };
  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data.Password });
      setFormDetails({
        firstnm: counsellorform.values.firstnm,
        lastnm: counsellorform.values.lastnm,
        mobnum: counsellorform.values.mobnum,
        email: counsellorform.values.email,
        security_ques: counsellorform.values.security_ques,
        security_ans: counsellorform.values.security_ans,
        assigned_to_owner: counsellorform.values.assigned_to_owner, //org_id
        profile_picture: resumefile,
      });
    });
  };

  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const partnerFunc = () => {
    const params = {
      partner_association: "samarth",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partner?${paramString}`
        // {
        //     "partner_association":"samarth",
        //     // "partner_association": selectProjectOwner
        //     //getting option from backend
        // }
      )
      .then((res) => {
        console.log(res.data);
        setProjectOwnerList(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  useEffect(() => {
    setSelectCenterOwner("");
    securityQuestionFunc();
    partnerFunc();
  }, []);

  useEffect(() => {
    if (autopass) {
      console.log("auto pass", autopass);
      fatchAutopass();
      console.log("password :", copyPassword.value);
      const resDATA = sessionStorage.getItem("register_id");
      //   const creatorRole = sessionStorage.getItem("creator_role");
      console.log(resDATA);
      //   console.log(creatorRole);
      if (resDATA) {
        setRegisterID(() => resDATA);
      }
      //   if (creatorRole) {
      //     setRoleId(creatorRole);
      //   }
      console.log("createdby :", registerID);
      //   console.log("creator_role :", roleId);
    }
  }, [autopass]);
  //   useEffect(()=>{

  //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/partner`,
  //     {
  //         "partner_association":"samarth",
  //         // "partner_association": selectProjectOwner
  //         //getting option from backend
  //     }).then((res)=>{
  //         console.log(res.data);
  //         setProjectOwnerList(res.data);
  //     }).catch((err)=>{
  //         console.log(err.data);
  //     })

  //   },[]);
  useEffect(() => {
    const params = {
      partner_association: selectProjectOwner,
      created_by: sessionStorage.getItem("register_id")
      ? sessionStorage.getItem("register_id")
      : "",

    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partneradmin?${paramString}`
        // {
        //     "partner_association": selectProjectOwner
        // }
      )
      .then((res) => {
        console.log(res.data);
        setCenterOwnerList(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, [selectProjectOwner]);

  const counsellorform = useFormik({
    enableReinitialize: true,
    initialValues: {
      createdby: "SA1",
      //   creator_role: "SA",
      role_id: "CO",
      email: formDetails.email ? formDetails.email : "",
      mobnum: formDetails.mobnum ? formDetails.mobnum : "",
      firstnm: formDetails.firstnm ? formDetails.firstnm : "",
      lastnm: formDetails.lastnm ? formDetails.lastnm : "",
      password: copyPassword.value ?? "",
      confirm_password: copyPassword.value ?? "",
      security_ques: formDetails.security_ques ? formDetails.security_ques : "",
      security_ans: formDetails.security_ans ? formDetails.security_ans : "",
      role_type: "Associate", //role_type
      assigned_to_owner: formDetails.assigned_to_owner ? formDetails.assigned_to_owner : "", //org_id
      profile_picture: formDetails.profile_picture ? formDetails.profile_picture : "",
      partner_association: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
      mobnum: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 Digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      // aadhar: Yup.string().required("Please enter aadhar number").length(12,"Aadhar number should be 12 Digit"),
      // pannum: Yup.string().required("Please enter pan cand number").length(10,"Pancard number should be 10 Alpanumeric"),
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required("Please enter last name"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
      security_ques: Yup.string().required("Please select question"),
      security_ans: Yup.string().required("Please enter answer"),
      // role_type: Yup.string().required("Please select role type"),
      //partner_association: Yup.string().required("Please assign project owner"),
      assigned_to_owner: Yup.string().required("Please assign center owner"),
      // profile_picture: Yup.string().required("Please upload profile photo "),
    }),
    onSubmit: (values) => {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      delete values.confirm_password;
      setDisplayPassword(values.password);
      setIsLoading(true);
      let formData = new FormData();
      formData.append("role_id", "CO");
      // formData.append("creator_role", roleId);
      formData.append("createdby", registerID);
      formData.append("firstnm", values.firstnm);
      formData.append("lastnm", values.lastnm);
      formData.append("mobnum", values.mobnum);
      formData.append("email", values.email);
      formData.append("security_ques", values.security_ques);
      formData.append("security_ans", values.security_ans);
      formData.append("password", values.password);
      formData.append("counsellor_type", values.role_type);
      formData.append("assigned_to_owner", values.assigned_to_owner);
      formData.append("assigned_to_owner_name", selectCenterOwner);
      formData.append("partner_association", "samarth");
      formData.append("profile_picture", resumefile);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL_REGISTER}/counsellor/register`,
          //  { ...values }superadmin/register/counsellor/register
          formData
        )
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          if (res.status === 201) {
            setShow(true);
            setResumeFile("");
            setIsLoading(false);
          } else {
            Swal.fire({
              icon: "warning",
              title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
              title: `${res.data["User Message"]}`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Something went wrong!",
            text: err.response.data["User Message"]
              ? err.response.data["User Message"]
              : "Something went wrong!",
            showConfirmButton: false,
            timer: 3000,
          });
        });

      // axios.post(`${process.env.REACT_APP_BACKEND_URL}/counselor/register`,
      //   { ...values, role_id: "CON",
      //   // creator_role: "SA",
      //   creator_role: roleId,
      //   createdby: registerID,
      //     center_id :centerID
      //   // assign_to_owner: "SA"
      // })
      //       .then(res=>{
      //   console.log("profile Post api called");
      //   if(res.status===201){
      //     Swal.fire({
      //       icon: "success",
      //       title: res.data["Status"],
      //       text: res.data["Developer Message"],
      //       showConfirmButton: false,
      //       timer: 3000,
      //     }).then(()=>{
      //       navigate("/counselor-list-page");
      //     })
      //   }
      // })
      // .catch((err)=>{
      //     console.log(err);
      //     // alert('Error message',err);
      //     Swal.fire({
      //         icon: "error",
      //         title: "",
      //         // text: "User registered successfully",
      //         text: err["message"],
      //         showConfirmButton: false,
      //         timer: 3000,
      //     }).then(()=>{
      //         navigate("/superadmin/dashborad");
      //     })
      // })
    },
  });

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file?.name.length !== 0 && file?.size / 1024 < 5120) {
      setResumeFile(file);
    } else if (file?.name.length !== 0 && file?.size / 1024 > 5120) {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpg, jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    } else {
      console.log("No file");
    }
  };

  const showAlert = () =>
    Swal.fire({
      //position: "top",
      icon: "success",
      title: "Registration Successful",
      //text: "Counselor registered successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      navigate("/counselor-list-page");
    });
  console.log("owner name", selectCenterOwner);
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              Counsellor Registration
            </SoftTypography>
            <SoftBox component="form" role="form" onSubmit={counsellorform.handleSubmit}>
              <Grid container>
                <SoftBox mt={4} width="100%" height="auto">
                  <Card sx={{ overflow: "visible" }}>
                    <SoftBox mt={2} width="100%" height="auto">
                      <SoftBox pl={2} pr={4} pt={0} pb={0}>
                        <SoftBox
                          p={1}
                          mb={1}
                          textAlign="baseline"
                          fontWeight="medium"
                          fontSize="16px"
                          // mb={4.5}
                          display="flex"
                          flexDirection="row"
                        >
                          <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                            Basic Details
                          </SoftTypography>

                          {/*
                          <SoftBox display="flex" flexDirection="row" mt={1} ml={1}>
                            <SoftBox
                              sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}
                            >
                              <sup>*</sup>
                            </SoftBox>
                            <SoftTypography fontSize="12px" fontStyle="italic">
                              all fields are mandatory
                            </SoftTypography>
                          </SoftBox>
                          */}
                        </SoftBox>
                        <SoftBox component="form" role="form" width="100%">
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      First Name <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="email"
                                    placeholder=""
                                    id="firstnm"
                                    name="firstnm"
                                    onChange={counsellorform.handleChange}
                                    value={counsellorform.values.firstnm}
                                  />
                                  {counsellorform.touched.firstnm &&
                                  counsellorform.errors.firstnm ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.firstnm}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Last Name <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="lastnm"
                                    name="lastnm"
                                    onChange={counsellorform.handleChange}
                                    value={counsellorform.values.lastnm}
                                  />
                                  {counsellorform.touched.lastnm && counsellorform.errors.lastnm ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.lastnm}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Email <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="email"
                                    placeholder=""
                                    id="email"
                                    name="email"
                                    onChange={counsellorform.handleChange}
                                    value={counsellorform.values.email}
                                  />
                                  {counsellorform.touched.email && counsellorform.errors.email ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.email}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Mobile Number <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="tel"
                                    inputProps={{
                                      maxLength: "10",
                                    }}
                                    placeholder=""
                                    id="mobnum"
                                    name="mobnum"
                                    onChange={counsellorform.handleChange}
                                    value={counsellorform.values.mobnum}
                                  />
                                  {counsellorform.touched.mobnum && counsellorform.errors.mobnum ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.mobnum}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Upload Profile Picture
                                    </SoftTypography>
                                    <SoftBox
                                      display="flex"
                                      flexDirection="row"
                                      // alignItems="baseline"
                                      sx={{ color: "red", position: "inherit" }}
                                    >
                                      {/* <sup>*</sup> */}
                                      <SoftTypography fontSize="14px" fontWeight="small">
                                        (Optional)
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                  <input
                                    type="file"
                                    placeholder=""
                                    accept="image/png, image/jpeg, image/jpg"
                                    ref={ref}
                                    onChange={handleFileUpload}
                                    className="form-control"
                                  />
                                  <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                    {
                                      "Please upload files < 5mb and make sure format is either jpg, jpeg or png."
                                    }
                                  </SoftTypography>

                                  {counsellorform.touched.profile_picture &&
                                  counsellorform.errors.profile_picture ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.profile_picture}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              {/* <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Counsellor Role <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftSelect
                                    placeholder="select role type"
                                    options={roleOptions}
                                    id="role_type"
                                    name="role_type"
                                    onChange={(e) =>
                                      counsellorform.setFieldValue("role_type", e.label)
                                    }
                                    value={{ label: counsellorform.values.role_type }}
                                  />
                                  {counsellorform.touched.role_type &&
                                  counsellorform.errors.role_type ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.role_type}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid> */}
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                                <SoftBox
                                  // pl={2}
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                  height="auto"
                                  sx={{
                                    border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                    bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                    <input
                                      name="password"
                                      type="radio"
                                      defaultChecked="true"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          Setautopass(true);
                                        } else {
                                          Setautopass(false);
                                        }
                                      }}
                                    />
                                  </SoftBox>

                                  <SoftBox display="flex" flexDirection="column" my="5px">
                                    <SoftTypography fontWeight="medium" fontSize="14px">
                                      Auto Generate Password
                                    </SoftTypography>
                                    <SoftTypography
                                      fontWeight="small"
                                      fontSize="13px"
                                      lineHeight="16px"
                                    >
                                      Password will be automatically generated after the
                                      registration is done.
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                                <SoftBox
                                  // pl={2}
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                  height="75px"
                                  sx={{
                                    border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                    bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                    <input
                                      name="password"
                                      type="radio"
                                      onChange={() => {
                                        counsellorform.setFieldValue("password", "");
                                        counsellorform.setFieldValue("confirm_password", "");
                                        Setautopass(false);
                                      }}
                                    />
                                  </SoftBox>
                                  <SoftBox display="flex" flexDirection="column" my="5px">
                                    <SoftTypography fontWeight="medium" fontSize="14px">
                                      Custom Password
                                    </SoftTypography>
                                    <SoftTypography
                                      fontWeight="small"
                                      fontSize="13px"
                                      lineHeight="16px"
                                    >
                                      You can create a custom password for the user
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Password <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="password"
                                    placeholder=""
                                    disabled={autopass}
                                    id="password"
                                    name="password"
                                    onChange={counsellorform.handleChange}
                                    value={counsellorform.values.password}
                                  />
                                  {counsellorform.touched.password &&
                                  counsellorform.errors.password ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.password}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Confirm Password <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="password"
                                    placeholder=""
                                    disabled={autopass}
                                    id="confirm_password"
                                    name="confirm_password"
                                    onChange={counsellorform.handleChange}
                                    value={counsellorform.values.confirm_password}
                                  />
                                  {counsellorform.touched.confirm_password &&
                                  counsellorform.errors.confirm_password ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.confirm_password}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>

                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Security Question <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftSelect
                                    placeholder="select questions"
                                    options={questions}
                                    id="security_ques"
                                    name="security_ques"
                                    onChange={(e) =>
                                      counsellorform.setFieldValue("security_ques", e.label)
                                    }
                                    value={{ label: counsellorform.values.security_ques }}
                                  />
                                  {counsellorform.touched.security_ques &&
                                  counsellorform.errors.security_ques ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.security_ques}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Answer <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="security_ans"
                                    name="security_ans"
                                    onChange={counsellorform.handleChange}
                                    value={counsellorform.values.security_ans}
                                  />
                                  {counsellorform.touched.security_ans &&
                                  counsellorform.errors.security_ans ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.security_ans}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox pl={0} pr={2} mt={5}>
                            <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                              Assign To
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              {/*
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Created For <sup style={{ color: 'red' }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftSelect
                                    placeholder="Assign Manager"
                                    options={projectOwnerList}
                                    id="partner_association"
                                    name="partner_association"
                                    onChange={(e) => {
                                      counsellorform.setFieldValue("partner_association", e.value);
                                      setSelectProjectOwner(e.value);
                                    }}
                                    value={{ label: counsellorform.values.partner_association }}
                                  />
                                  {counsellorform.touched.partner_association &&
                                    counsellorform.errors.partner_association ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.partner_association}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              */}

                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Supervisor <sup style={{ color: "red" }}>*</sup>
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      {/* <sup>*</sup> */}
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftSelect
                                    placeholder="Assign Manager"
                                    options={centerOwnerList}
                                    id="assigned_to_owner"
                                    name="assigned_to_owner"
                                    onChange={(e) => {
                                      counsellorform.setFieldValue("assigned_to_owner", e.value);
                                      setSelectCenterOwner(e.label);
                                    }}
                                    // value={{ label: counsellorform.values.assigned_to_owner }}
                                    value={{ label: selectCenterOwner }}
                                  />
                                  {counsellorform.touched.assigned_to_owner &&
                                  counsellorform.errors.assigned_to_owner ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {counsellorform.errors.assigned_to_owner}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
              </Grid>

              <Grid container mt={4} ml={2}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                    <SoftBox
                      pl={2}
                      sx={{
                        height: "45px",
                        backgroundColor: "#3B5092",
                        borderRadius: "5px",
                        cursor: isLoading ? "no-drop" : "pointer",
                        opacity: isLoading ? 0.5 : 1,
                      }}
                      p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                      width={{ lg: "100%", sm: "100%", xs: "50%" }}
                      onClick={!isLoading && counsellorform.handleSubmit}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        Submit
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                    <SoftBox
                      pl={2}
                      sx={{
                        height: "45px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                      width={{ lg: "100%", sm: "100%", xs: "50%" }}
                      onClick={() => navigate("/admin-register")}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#3B5092",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        Cancel
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
            showAlert();
          }}
          centered
        >
          <Modal.Header closeButton>Retrieve Password</Modal.Header>

          <Modal.Body>
            <SoftBox display="flex">
              Password: {showpassword ? displayPassword : "********"}
              <SoftBox
                mx={15}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  Setshowpassword(true);
                  copy(`${copyPassword.value}`);
                  // showAlert();
                }}
              >
                <Icon icon="mdi:content-copy" color="#3b5092" width="35" />
              </SoftBox>
            </SoftBox>
          </Modal.Body>
          <Modal.Footer>
            <SoftBox
              pl={2}
              sx={{
                height: "45px",
                backgroundColor: "#3B5092",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => downloadfile()}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
              >
                Download
              </SoftTypography>
            </SoftBox>
            <SoftBox
              pl={2}
              sx={{
                height: "45px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => {
                showAlert();
              }}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{
                  color: "#3B5092",
                  fontSize: "15px",
                  fontWeight: "medium",
                  lineHeight: "19px",
                }}
              >
                Cancel
              </SoftTypography>
            </SoftBox>
          </Modal.Footer>
        </Modal>
      </DashboardLayout>
    </>
  );
};
export default CounsellourRegistration;
