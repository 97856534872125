import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table } from "ant-table-extensions";
import { Form, Popconfirm, Typography } from "antd";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import SoftBox from "components/SoftBox";
import { Box } from "@mui/material";
import SoftInput from "components/SoftInput";
import Select from "react-select";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";

const Profiling = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const [editModal, setEditModal] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [question, setQuestions] = useState("");
  const [role, setRole] = useState("");
  const [arrayList, setArrayList] = useState(["Communication", "Excel"]);
  const [newList, setNewList] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [newRoleList, setNewRoleList] = useState(null);
  const [profileData, setProfileData] = useState([]);
  const [industryName, setIndustryName] = useState("");
  const [rowData, setRowData] = useState({});
  const [rowDataRole, setRowDataRole] = useState({});
  const [jobrole, Setjobrole] = useState();
  const industryOptions = [
    { label: "Textile and Clothing", value: "Textile and Clothing" },
    { label: "Building and Construction", value: "Building and Construction" },
    { label: "Automobile and Transportation", value: "Automobile and Transportation" },
    { label: "Organized and Unorganized Retail", value: "Organized and Unorganized Retail" },
    { label: "Gems and Jewellery", value: "Gems and Jewellery" },
    { label: "IT & Computers", value: "IT & Computers" },
    { label: "Furniture and Furnishing", value: "Furniture and Furnishing" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Leather and Leather Goods", value: "Leather and Leather Goods" },
    { label: "Tourism and Hospitality", value: "Tourism and Hospitality" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Mining", value: "Mining" },
    { label: "Households", value: "Households" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
  ];
  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
      width: "100%",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };
  const colName = [
    {
      title: "Industry",
      dataIndex: "industry",
      fixed: "left",
      width: 200,
      //   render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      //   sorter: {
      //     // compare: (a, b) => a.name - b.name,
      //     compare: (a, b) => a?.firstnm?.localeCompare(b?.firstnm),
      //     multiple: 1,
      //   },
    },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    //   width: 150,
    // },
    // {
    //   title: "Question",
    //   dataIndex: "question",
    //   width: 150,
    // },
    {
      title: "Action",
      width: 150,
      render: (text) => {
        console.log("row data role:", text);
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() =>
              // setArrayList(text.profile_screening), setRowData(text), setEditModal(true)
              {
                setRoleModal(true);
                setRowDataRole(text);
                setRoleList(text.role_list);
              }
            }
          >
            View | Add
          </span>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(data, newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // const data = [
  //   {
  //     key: 1,
  //     industry: "Textile and Clothing",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 2,
  //     industry: "Building and Construction",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 3,
  //     industry: "Automobile and Transportation",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 4,
  //     industry: "Organized and Unorganized Retail",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 5,
  //     industry: "Gems and Jewellery",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 6,
  //     industry: "IT & Computers",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 7,
  //     industry: "Furniture and Furnishing",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 8,
  //     industry: "Healthcare",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 9,
  //     industry: "Leather and Leather Goods",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 10,
  //     industry: "Tourism and Hospitality",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 11,
  //     industry: "Agriculture",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 12,
  //     industry: "Mining",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 13,
  //     industry: "Households",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  //   {
  //     key: 14,
  //     industry: "Media and Entertainment",
  //     role: "Full Stack",
  //     question: "communication",
  //   },
  // ];

  const expandedRowRender = (row, records) => {
    console.log("row", row);
    console.log("row records ", records);
    const columns = [
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        // sorter: {
        //   compare: (a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
        //   multiple: 1,
        // },
      },
      //   {
      //     title: "Question",
      //     dataIndex: "question",
      //     width: 150,
      //     editable: true,
      //   },
      {
        title: "Action",
        // dataIndex: "action",
        render: (text) => {
          console.log("row data:", text);
          return (
            <p style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => {
                  setArrayList(text.profile_screening), setRowData(text), setEditModal(true);
                }}
              >
                View | Edit
              </span>
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => {
                  console.log("delete:", text);
                  deleteRoleFunction(text);
                }}
              >
                {" "}
                Delete Role
              </span>
            </p>
          );
        },
        // render: (_, record) => {
        //   const editable = isEditing(record);
        //   return editable ? (
        //     <span>
        //       <Typography.Link
        //         onClick={() => save(record.key)}
        //         style={{
        //           marginRight: 8,
        //         }}
        //       >
        //         Save
        //       </Typography.Link>
        //       <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
        //         <a>Cancel</a>
        //       </Popconfirm>
        //     </span>
        //   ) : (
        //     <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
        //       Edit
        //     </Typography.Link>
        //   );
        // },
      },
    ];

    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i,
        role: "Full stack",
        question: "Coding",
      });
    }
    const onSelectChange = (newSelectedRowKeys) => {
      console.log("newkey", newSelectedRowKeys);
      //   setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    return (
      <Table
        // loading={subTable.length === 0 ? true : false}
        columns={columns}
        dataSource={row.role}
        // columns={mergedColumns}
        // dataSource={data}
        pagination={false}
        // rowSelection={rowSelection}
        // exportableProps={{ showColumnPicker: true }}
        // components={{
        //   body: {
        //     cell: EditableCell,
        //   },
        // }}
      />
    );
  };
  const fetchIndustry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry/profile`)
      .then((res) => {
        console.log("industry fetch: ", res.data);
        setProfileData(
          res.data.profile_data.map((item, id) => {
            console.log("industry id:", item.industry_list[0].prof_id);
            // setIndustryId(item.industry_list[0].prof_id);
            // setIndustryName(item.industry);
            return {
              //   key: item.id + 1,
              key: id + 1,
              industry: item.industry,
              role: item.industry_list,
              role_list: item.role,
              //   question: item.industry_list.map((ele, id) => item.profile_screening),
            };
          })
        );
      })
      .catch((err) => {
        console.log("industry fetch err: ", err.response);
      });
  };
  //   const fetchIndustryRoles = async () => {
  //     const params = {
  //       id: industryId,
  //     };
  //     const paramString = new URLSearchParams(params).toString();
  //     await axios
  //       .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry/profile?${paramString}`)
  //       .then((res) => {
  //         console.log("industry fetch: ", res.data);
  //         setProfileData(
  //           res.data.profile_data.map((item, id) => {
  //             console.log("industry id:", item.industry_list[0].prof_id);
  //             // setIndustryId(item.industry_list[0].prof_id);
  //             return {
  //               //   key: item.id + 1,
  //               key: item.industry_list[0].prof_id,
  //               industry: item.industry,
  //               //   question: item.industry_list.map((ele, id) => item.profile_screening),
  //             };
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         console.log("industry fetch err: ", err.response);
  //       });
  //   };
  const addQuestionFuction = async (obj) => {
    console.log("object: ", obj);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry/profile`, {
        prof_id: obj.prof_id,
        industry: obj.industry,
        profile_screening: arrayList.join(","),
        role: obj.role,
      })
      .then((res) => {
        console.log("industry fetch: ", res.data);
        Swal.fire({
          icon: "success",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          //title: "Success",
          title: "Question Saved Successfully",
          showConfirmButton: false,
          timer: 3000,
        }).then((res) => {
          setEditModal(false);
          fetchIndustry();
        });

        // setProfileData(
        //   res.data.profile_data.map((item, id) => {
        //     console.log("industry id:", item.industry_list[0].prof_id);
        //     setIndustryId(item.industry_list[0].prof_id);
        //     return {
        //       //   key: item.id + 1,
        //       key: item.industry_list[0].prof_id,
        //       industry: item.industry,
        //       //   question: item.industry_list.map((ele, id) => item.profile_screening),
        //     };
        //   })
        // );
      })
      .catch((err) => {
        console.log("industry fetch err: ", err.response);
        Swal.fire({
          icon: "error",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          title: "Oops...",
          text: "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const addQuestion = (value) => {
    // setArrayList([...arrayList, value]);
    const data = arrayList.filter((item, id) => item !== newList);
    console.log("filter data for deleted", data);
    setArrayList([...data, value]);
    setQuestions("");
  };
  const deleteFunction = (value, obj) => {
    setNewList(value);
    const data = arrayList.filter((item, id) => item !== value);
    setArrayList([...data]);
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry/profile`, {
        prof_id: obj.prof_id,
        industry: obj.industry,
        profile_screening: data.join(","),
        role: obj.role,
      })
      .then((res) => {
        console.log("industry fetch: ", res.data);
        // fetchIndustry();
        Swal.fire({
          icon: "success",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          //title: "Success",
          title: "Question Deleted Successfully",
          showConfirmButton: false,
          timer: 3000,
        }).then((res) => {
          fetchIndustry();
        });
      })
      .catch((err) => {
        console.log("industry fetch err: ", err.response);
        Swal.fire({
          icon: "error",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          title: "Oops...",
          text: "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const addRoles = (value) => {
    // setArrayList([...arrayList, value]);
    const data = roleList.filter((item, id) => item !== newRoleList);
    console.log("filter data for deleted", data);
    setRoleList([...data, value]);
    setRole("");
  };
  const addRoleFuction = async (obj) => {
    console.log("object: ", obj);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry/profile`, {
        // prof_id: obj.prof_id,
        role: roleList.join(","),
        industry: obj.industry,
        // profile_screening: arrayList.join(","),
        // role: obj.role,
      })
      .then((res) => {
        console.log("industry fetch: ", res.data);
        Swal.fire({
          icon: "success",
          //title: "Success",
          title: "Role Saved Successfully",
          showConfirmButton: false,
          timer: 3000,
        }).then((res) => {
          setRoleModal(false);
          fetchIndustry();
        });

        // setProfileData(
        //   res.data.profile_data.map((item, id) => {
        //     console.log("industry id:", item.industry_list[0].prof_id);
        //     setIndustryId(item.industry_list[0].prof_id);
        //     return {
        //       //   key: item.id + 1,
        //       key: item.industry_list[0].prof_id,
        //       industry: item.industry,
        //       //   question: item.industry_list.map((ele, id) => item.profile_screening),
        //     };
        //   })
        // );
      })
      .catch((err) => {
        console.log("industry fetch err: ", err.response);
        Swal.fire({
          icon: "error",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          //title: "Oops...",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const deleteRoleFunction = (value) => {
    setNewRoleList(value);
    const data = roleList.filter((item, id) => item !== value);
    setRoleList([...data]);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry/profile`, {
        // prof_id: value.prof_id,
        // role: roleList.join(","),
        // industry: obj.industry,
        // profile_screening: data.join(","),
        // role: obj.role,
        data: {
          prof_id: value.prof_id,
        },
      })
      .then((res) => {
        console.log("industry fetch: ", res.data);
        // fetchIndustry();
        Swal.fire({
          icon: "success",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          //title: "Success",
          title: "Role Deleted Successfully",
          showConfirmButton: false,
          timer: 3000,
        }).then((res) => {
          fetchIndustry();
        });
      })
      .catch((err) => {
        console.log("industry fetch err: ", err.response);
        Swal.fire({
          icon: "error",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          title: "Oops...",
          text: "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchIndustry();
    fetchjobrole();
  }, []);
  //   useEffect(() => {
  //     fetchIndustryRoles();
  //   }, [industryId]);
  console.log("arraylist :", arrayList);
  console.log("rowData :", rowData);
  console.log("roleList :", roleList);
  console.log("rowDatarole :", rowDataRole);
  // setArrayList(rowData.profile_screening);
  // const mainTable = profileData?.map((ele, index) => {
  //   return {
  //     key: index,
  //     industry: ele.industry,
  //     //   question: ele.industry_list.map((item, id) => item.profile_screening),
  //   };
  // });
  const mainTable = [
    {
      industry_id: 1,
      industry_name: "Textile and Clothing",
      roles: [
        {
          role_id: 1,
          role_name: "Full Stack",
          question: ["Communication", "Excel"],
        },
        {
          role_id: 2,
          role_name: "Backend Developer",
          question: ["Communication", "Excel"],
        },
        {
          role_id: 3,
          role_name: "Frontend Developer",
          question: ["Communication", "Excel"],
        },
      ],
    },
    {
      industry_id: 2,
      industry_name: "Organized and Unorganized Retail",
      roles: [
        {
          role_id: 1,
          role_name: "Full Stack",
          question: ["Communication", "Excel"],
        },
        {
          role_id: 2,
          role_name: "Backend Developer",
          question: ["Communication", "Excel"],
        },
        {
          role_id: 3,
          role_name: "Frontend Developer",
          question: ["Communication", "Excel"],
        },
      ],
    },
    {
      industry_id: 3,
      industry_name: "IT & Computers",
      roles: [
        {
          role_id: 1,
          role_name: "Full Stack",
          question: ["Communication", "Excel"],
        },
        {
          role_id: 2,
          role_name: "Backend Developer",
          question: ["Communication", "Excel"],
        },
        {
          role_id: 3,
          role_name: "Frontend Developer",
          question: ["Communication", "Excel"],
        },
      ],
    },
  ];
  const Table1 = profileData?.map((ele, id) => {
    console.log("main table:", ele);
    return {
      key: ele.key,
      industry: ele.industry,
      role: ele.role,
      role_list: ele.role_list,
      // key: ele.industry_id,
      // industry: ele.industry_name,
      // role: ele.roles,
    };
  });
  // const Table2 = mainTable?.map((ele, id) => {
  //   console.log("Table 2: ", ele.roles);
  //   return ele.roles?.map((item, i) => {
  //     return {
  //       key: item.role_id,
  //       role: item.role_name,
  //     };
  //   });
  // });
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <Table
          // {...tableProps}
          //  pagination={{position:["bottomCenter"]}}
          //   rowSelection={rowSelection}
          columns={colName}
          // dataSource={profileData}
          dataSource={Table1}
          expandable={{ expandedRowRender }}
          // exportable
          // exportableProps={{ showColumnPicker: true }}
          // searchable
        />
      </DashboardLayout>
      {/* modal for export */}
      <Modal
        show={editModal}
        onHide={() => {
          setEditModal(false);
        }}
        centered
      >
        <Modal.Body>
          <SoftBox sx={{ m: 0 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p className="HeaderCSV">Add Question for Candidate Profiling</p>

              <button className="CloseBtn" onClick={() => setEditModal(false)}>
                <span>
                  <CloseIcon fontSize="small" />
                </span>
              </button>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
              {/* <input type="text" style={{ height: "25px" }} /> */}
              <input
                type="text"
                // sx={{ height: "25px" }}
                className="input-box"
                value={question}
                onChange={(e) => setQuestions(e.target.value)}
              />
              <button className="login-btn4">
                <span className="login-btn4-Text" onClick={() => addQuestion(question)}>
                  Add
                </span>
              </button>
            </Box>
            {console.log("rowdata:", rowData)}
            {/* {rowData?.profile_screening
              ?.filter((item, id) => item !== newList)
              ?.map((ele, index) => (
                <>
                  <ul className="UlCSV" key={index}>
                    <li className="ListCSV" style={{ margin: "5px" }}>
                      &nbsp;<span>{ele}</span>
                      &nbsp;&nbsp;&nbsp;
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={(e) => {
                          console.log("delete ele:", e.target.value);
                          setNewList(ele);
                        }}
                      >
                        {/* Delete *
                        <Icon icon="ic:baseline-delete" />
                      </span>
                    </li>
                  </ul>
                </>
              ))} */}
            {arrayList
              .filter((item, id) => item !== newList)
              ?.map((ele, index) => (
                <>
                  <ul className="UlCSV" key={index}>
                    <li className="ListCSV" style={{ margin: "5px" }}>
                      &nbsp;<span>{ele}</span>
                      &nbsp;&nbsp;&nbsp;
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          console.log("delete ele:", ele);
                          console.log("delete item:", rowData);
                          // arrayList.filter((ele) => {
                          //   ele !== e.target.value;
                          // });
                          deleteFunction(ele, rowData);
                          // setNewList(ele);
                        }}
                      >
                        {/* Delete */}
                        <Icon icon="ic:baseline-delete" />
                      </span>
                    </li>
                  </ul>
                </>
              ))}
            {/* <Box> */}
            {/* <ul> */}
            {/* <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    /> */}
            {/* {arrayList.map((ele, index) => (
                <li key={index}>{ele}</li>
              ))} */}
            {/* </ul> */}
            {/* </Box> */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="login-btn4" onClick={() => setEditModal(false)}>
                <span className="login-btn4-Text">Cancel</span>
              </button>

              <button className="login-btn3">
                {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                {/* <CSVLink
                  data={searchData}
                  //data={selectedCSVData}
                  filename={fileName}
                  headers={colHeaders.filter((ele) => ele.isExportable === true)}
                >
                  <span className="login-btn3-Text">Export</span>
                </CSVLink> */}
                <span className="login-btn3-Text" onClick={() => addQuestionFuction(rowData)}>
                  Save
                </span>
              </button>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>
      {/* modal for export */}

      {/* add role modal for export */}
      <Modal
        show={roleModal}
        onHide={() => {
          setRoleModal(false);
        }}
        centered
      >
        <Modal.Body>
          <SoftBox sx={{ m: 0 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p className="HeaderCSV">Add Role </p>

              <button className="CloseBtn" onClick={() => setRoleModal(false)}>
                <span>
                  <CloseIcon fontSize="small" />
                </span>
              </button>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}
            >
              {/* <input type="text" style={{ height: "25px" }} /> */}
              {/* <input
                type="text"
                // sx={{ height: "25px" }}
                className="input-box"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              /> */}
              <Select
                styles={stylesDD}
                placeholder="Select role/desgination"
                options={jobrole}
                value={role !== "" ? { label: role, value: role } : null}
                onChange={(e) => {
                  // jobpostform.setFieldValue("designation", e.label);
                  setRole(e.label);
                }}
                // width="100%"
              />
              <button className="login-btn4">
                <span className="login-btn4-Text" onClick={() => addRoles(role)}>
                  Add
                </span>
              </button>
            </Box>
            {console.log("rowdata role:", rowDataRole)}
            {/* {rowData?.profile_screening
              ?.filter((item, id) => item !== newList)
              ?.map((ele, index) => (
                <>
                  <ul className="UlCSV" key={index}>
                    <li className="ListCSV" style={{ margin: "5px" }}>
                      &nbsp;<span>{ele}</span>
                      &nbsp;&nbsp;&nbsp;
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={(e) => {
                          console.log("delete ele:", e.target.value);
                          setNewList(ele);
                        }}
                      >
                        {/* Delete *
                        <Icon icon="ic:baseline-delete" />
                      </span>
                    </li>
                  </ul>
                </>
              ))} */}
            {roleList
              .filter((item, id) => item !== newRoleList)
              ?.map((ele, index) => (
                <>
                  <ul className="UlCSV" key={index}>
                    <li className="ListCSV" style={{ margin: "5px" }}>
                      &nbsp;<span>{ele}</span>
                      &nbsp;&nbsp;&nbsp;
                      {/* <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          console.log("delete ele:", ele);
                          console.log("delete item:", rowDataRole);
                          // arrayList.filter((ele) => {
                          //   ele !== e.target.value;
                          // });
                          deleteRoleFunction(ele, rowDataRole);
                          // setNewList(ele);
                        }}
                      >
                        {/* Delete *
                        <Icon icon="ic:baseline-delete" />
                      </span> */}
                    </li>
                  </ul>
                </>
              ))}
            {/* <Box> */}
            {/* <ul> */}
            {/* <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    /> */}
            {/* {arrayList.map((ele, index) => (
                <li key={index}>{ele}</li>
              ))} */}
            {/* </ul> */}
            {/* </Box> */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="login-btn4" onClick={() => setRoleModal(false)}>
                <span className="login-btn4-Text">Cancel</span>
              </button>

              <button className="login-btn3">
                {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                {/* <CSVLink
                  data={searchData}
                  //data={selectedCSVData}
                  filename={fileName}
                  headers={colHeaders.filter((ele) => ele.isExportable === true)}
                >
                  <span className="login-btn3-Text">Export</span>
                </CSVLink> */}
                <span className="login-btn3-Text" onClick={() => addRoleFuction(rowDataRole)}>
                  Save
                </span>
              </button>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>
      {/* add role modal for export */}
    </>
  );
};

export default Profiling;
