import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
// import userRoleAccess from "./userRoleAccess.js";

const notSkilling = sessionStorage.getItem("role_access") !== "Skilling Institute" ? true : false;

export const TypographyList = styled(Typography)({
  // fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "16px",
  color: "#000000",
});

export const TypographyTabHeading = styled(Typography)(({ roleAccess }) => ({
  fontFamily: roleAccess !== "Skilling Institute" ? "Inter" : "Montserrat",
  fontWeight: 500,
  fontSize: "24px",
  color: roleAccess !== "Skilling Institute" ? "#000EDD" : "#FF5C00",
  marginLeft: "18px",
  padding: "12px",
  paddingBottom: "0px",
}));

export const TypographyHeading1 = styled(Typography)({
  // fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "16px",
  color: "#000000",
  width: "100px",
});

export const TypographyHeading3 = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  color: "#000000",
});

export const BoxHeading1 = styled(Box)({
  minWidth: "300px",
  display: "flex",
  paddingBottom: "8px",
  paddingRight: "8px",
});

export const BoxOddLine = styled(Box)((roleAccess) => ({
  background: "#F7D3B56B",
  paddingLeft: "18px",
  paddingTop: "8px",
  // display: "flex",
  // alignItems: "center",
}));

export const BoxInfoContainer1 = styled(Box)(({ roleAccess }) => ({
  display: "flex",
  flexWrap: roleAccess !== "Skilling Institute" ? "wrap" : "no-wrap",
  flexDirection: roleAccess !== "Skilling Institute" ? "none" : "column",
  maxWidth: "700px",
  marginLeft: "18px",
  padding: "12px",
  paddingTop: "4px",
}));

export const BoxInfoContainer1A = styled(Box)(({ roleAccess }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  // maxWidth: "700px",
  // marginLeft: "18px",
  padding: "12px 20px",
  // paddingTop: "4px",
  background: "#F7D3B56B",
  // justifyContent: "space-between",
}));

export const BoxInfoContainer2A = styled(Box)(({ roleAccess }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  // maxWidth: "700px",
  // marginLeft: "18px",
  padding: "12px 20px",
  // gap: matchsm ? "10px" : "150px",
  // paddingTop: "4px",
  // background: "#FFFCF5",
  // justifyContent: "space-between",
}));

export const BoxHeadingContainer1 = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginRight: "24px",
});

export const BoxGrid1 = styled(Box)(({ roleAccess }) => ({
  width: "100%",
  background:
    roleAccess !== "Skilling Institute" && roleAccess !== "Default" ? "#FFFFFF" : "#FFFCF5",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  margin: "12px",
  minHeight: "230px",
}));
export const BoxGrid2 = styled(Box)(({ roleAccess }) => ({
  width: "100%",
  background: "#FFFCF5",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  marginBottom: "12px",
  minHeight: "230px",
  padding: "20px",
  display: "flex",
  // flexDirection: roleAccess === "Default" ? "column" : "row",
}));

export const BoxButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "no-wrap",
});

export const TypographyCompanyinfo = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  color: "#423232",
});

export const TypographyCompanyDes = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "18px",
  color: "#848383",
  marginBottom: "8px",
});

export const BoxCompanyInfo = styled(Box)({
  padding: "24px",
  paddingBottom: "0px",
  marginTop: "-12px",
});

export const TypographyLocation = styled(Typography)(({ roleAccess }) => ({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "16px",
  color: "#848383",
  marginBottom: "12px",
}));

export const BoxMain2 = styled(Box)({
  paddingLeft: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  padding: "8px",
  paddingTop: "0px",
});

export const TypographyOrg1 = styled(Typography)(({ roleAccess }) => ({
  // fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "24px",
  color: "#000000",
  marginBottom: "12px",
}));

export const TypographyOrg2 = styled(Typography)(({ roleAccess }) => ({
  // fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "17px",
  color: "#848383",
}));

export const BoxImg = styled(Box)({
  width: "161.54px",
  height: "120px",
  background: "#FFFFFF",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
});

export const BoxMain1 = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  padding: "24px",
  flexWrap: "wrap",
  padding: "24px",
  marginTop: "-60px",
});

export const BoxMain3 = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  flexWrap: "wrap",
  marginBottom: "8px",
});

export const TypographyHeading2 = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  color: "#000000",
  paddingRight: "16px",
});

export const BoxFlex1 = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  marginLeft: "24px",
});

export const TypographyXs = styled(Typography)({
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "26px",
  fontFamily: "Montserrat",
  color: "#000000",
});

export const TypographySans = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "26px",
  fontFamily: "Public Sans",
  color: "#072AC8",
});

export const SHeading = styled(Typography)({
  fontSize: "20px",
  fontWeight: 600,
  paddingLeft: "16px",
  color: "#000000",
  fontFamily: "Montserrat",
});

export const SBox1 = styled(Box)({
  width: "100%",
  background: "#FFFFFF",
  margin: "16px",
  marginTop: "0px",
  marginBottom: "0px",
  padding: "8px",
  borderRadius: "8px",
});

export const SBox2 = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
});

export const SVerticalBox = styled(Box)({
  height: "61px",
  width: "5px",
  background: "#3F4ED1",
  borderRadius: "10px",
});

export const SBox3 = styled(Box)({
  paddingLeft: "16px",
  paddingRight: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  background: "#FFFFFF",
});

export const SKPIHeading = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#6C6C6C",
  fontStyle: "normal",
  fontFamily: "Roboto",
  display: "flex",
  flexDirection: "row",
  // justifyContent: "space-between",
  alignItems: "center",
  gap: 3,
});

export const SKPICount = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  fontStyle: "normal",
  fontFamily: "Roboto",
});

export const SKPISubHeading1 = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#848383",
  fontStyle: "normal",
  fontFamily: "Roboto",
});

export const SKPISubHeading2 = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#249557",
  fontStyle: "normal",
  fontFamily: "Roboto",
});

export const SBox4 = styled(Box)({
  margin: "4px",
  background: "#FFFFFF",
  borderRadius: "7px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
});

export const SGridBox = styled(Box)({
  width: "100%",
  margin: "16px",
  marginTop: "0px",
  marginBottom: "0px",
  padding: "8px",
  background: "#FFFFFF",
  minHeight: "500px",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)",
  borderRadius: "8px",
});

export const SGraphSubHeading = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#404040",
  fontStyle: "normal",
  // fontFamily: 'Inter',
  padding: "4px",
});

export const SBox5 = styled(Box)({
  padding: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const SUnderlineBox = styled(Box)({
  padding: "8px",
  paddingBottom: "0px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #BDC3C7",
});

export const SGHeading = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#000000",
  lineHeight: "1.625px",
  letterSpacing: "0.00938em",
  //   fontStyle: "normal",
  // fontFamily: 'Inter'
});

export const SGSubHeading = styled(Typography)({
  fontSize: "12px",
  fontWeight: 500,
  color: "#848383",
  fontStyle: "normal",
  // fontFamily: 'Inter'
});

export const SGTitles = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#404040",
  fontStyle: "normal",
  // fontFamily: 'Inter',
  padding: "8px",
});

export const SGToggles = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: "#404040",
  fontStyle: "normal",
  fontFamily: "Public Sans",
  padding: "8px",
});

export const SBoxGrid = styled(Box)({
  minHeight: "220px",
  width: "100%",
  background: "#FFFFFF",
  borderRadius: "8px",
  padding: "8px",
  paddingTop: "8px",
  boxShadow: "0px 4px 4px 0px #00000040",
});
