import React, { useState } from 'react'
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

// const MyMapComponent = (props) => {
//   return (

//   )
// }
const MyMapComponent = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props =>( 
    // const [state,setState] = useState({
    //     lat:"",
    //     longi:""
    // });
    // const {isMarkerShown, childCallback, onMarkerClick } = props;
    console.log(props.parentState),
    (
        <>
    <GoogleMap
      onClick={ev => {
        console.log("latitide = ", ev.latLng.lat());
        console.log("longitude = ", ev.latLng.lng());
        const newLocation = {
          ...props.location,
          latitude: ev.latLng.lat(),
          longitude: ev.latLng.lng(),
        }
        console.log(newLocation);
        props.handleLocationChange(newLocation);
        // {props.parentCallback(
        //   ()=>props.parentState({
        //     latitude:ev.latLng.lat(),
        //     longitude:ev.latLng.lng()
        //   })
        // )}
        // setState({
        //     lat: ev.latLng.lat(),
        //     longi: ev.latLng.lng()
        // })
        // childCallback({
        //     lat: ev.latLng.lat(),
        //     longi: ev.latLng.lng()
        // })
      }}
      defaultZoom={6}
      defaultCenter={{ lat: 20.15, lng: 73.00 }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: -34.397, lng: 150.644 }}
          onClick={props.onMarkerClick}
        />
      )}
    </GoogleMap>
    </>
    )
  ));

export default MyMapComponent
