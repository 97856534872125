import React,{useState, useEffect, useMemo } from 'react'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import PageLayout from 'examples/LayoutContainers/PageLayout'
import DefaultNavbar from 'examples/Navbars/DefaultNavbar'
import pageRoutes from "page.routes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../assets/theme";
import CssBaseline from "@mui/material/CssBaseline";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import channelpartnerroute from "../../channelpartnerroute";
import Sidenav from "examples/Sidenav";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ct.png";

import SoftBox from 'components/SoftBox';

const Channelpartnerpage = () => {
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const { pathname } = useLocation();
  
    // Cache for the rtl
    useMemo(() => {
      const cacheRtl = createCache({
        key: "rtl",
        stylisPlugins: [rtlPlugin],
      });
  
      setRtlCache(cacheRtl);
    }, []);
  
    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
      if (miniSidenav && !onMouseEnter) {
        setMiniSidenav(dispatch, false);
        setOnMouseEnter(true);
      }
    };
  
    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
      if (onMouseEnter) {
        setMiniSidenav(dispatch, true);
        setOnMouseEnter(false);
      }
    };
  
    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  
    // Setting the dir attribute for the body element
    useEffect(() => {
      document.body.setAttribute("dir", direction);
    }, [direction]);
  
    // Setting page scroll to 0 when changing the route
    useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }, [pathname]);
  
    const getRoutes = (allRoutes) =>
      allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
  
        if (route.route) {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
  
        return null;
      });
  return (
    <>
     <DashboardNavbar />
   <PageLayout>
        <DefaultNavbar
          routes={pageRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
          light
        />
        </PageLayout>
        <ThemeProvider theme={theme} >
      <CssBaseline />      
        <Sidenav       
            color={sidenavColor}
            brand={brand}
            brandName="Soft UI Dashboard PRO"
            routes={channelpartnerroute}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />  
         
          <SoftBox ml={0} mt={2}>
            <Outlet/>
        
          </SoftBox>
         
          </ThemeProvider>
       
    </>
  )
}

export default  Channelpartnerpage