import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import "../editStyle.css";

const AdminEdit = (props) => {
  const ref = useRef();
  const { fetchadminList } = props;
  const [resumefile, setResumeFile] = useState("");
  const [autopass, Setautopass] = useState(true);
  const [check, setCheck] = useState(false);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });

  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [questions, setQuestions] = useState([]);
  // const [formLink,setFormLink] = useState("");
  const [show, setShow] = useState(false);
  const [showpassword, Setshowpassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  // const [centerOwnerId,setCenterOwnerId]= useState("");

  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob([`Auto Generate Password : ${copyPassword.value}`], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };
  const handleResume = (File) => {
    const validExt = ["jpeg", "png"];
    const fileExt = File.name.split(".")[1];
    return validExt.includes(fileExt);
  };
  const RoleOptions = [
    { label: "Requirement Based", value: "Requirement Based" },
    { label: "End to End", value: "End to End" },
  ];

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file.size / 1024);
    if (5120 > file.size / 1024) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  const sizeOptions = [
    {
      label: "Tiny Unit",
      value: "Tiny Unit",
    },
    {
      label: "Small Unit",
      value: "Small Unit",
    },
    {
      label: "Medium Unit",
      value: "Medium Unit",
    },
    {
      label: "Large Unit",
      value: "Large Unit",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const industryOptions = [
    { label: "Textile and Clothing", value: "Textile and Clothing" },
    { label: "Building and Construction", value: "Building and Construction" },
    { label: "Automobile and Transportation", value: "Automobile and Transportation" },
    { label: "Organized and Unorganized Retail", value: "Organized and Unorganized Retail" },
    { label: "Gems and Jewellery", value: "Gems and Jewellery" },
    { label: "IT & Computers", value: "IT & Computers" },
    { label: "Furniture and Furnishing", value: "Furniture and Furnishing" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Leather and Leather Goods", value: "Leather and Leather Goods" },
    { label: "Tourism and Hospitality", value: "Tourism and Hospitality" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Mining", value: "Mining" },
    { label: "Households", value: "Households" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
  ];

  const sectorOption = [
    {
      label: "Public",
      value: "Public",
    },
    {
      label: "Private",
      value: "Private",
    },
    {
      label: "PSU",
      value: "PSU",
    },
  ];

  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data?.Password });
    });
  };
  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, [autopass]);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
    securityQuestionFunc();
  }, []);

  useEffect(() => {
    const params = {
      state: selectedState === "" ? props.editdata.org_state : selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [props.editdata, selectedState]);
  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];
  //   console.log("resumefile ", file);
  //   if (file.size / 1024 < 5120) {
  //     setResumeFile(file);
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "",
  //       text: "Please upload files < 5mb and make sure format is either jpeg or png.",
  //       showConfirmButton: false,
  //       timer: 2000,
  //     });
  //     ref.current.value = "";
  //     setResumeFile("");
  //   }
  // };
  const navigate = useNavigate();
  const registrationJP = useFormik({
    enableReinitialize: true,
    initialValues: {
      //   "createdby": "SA1",
      // "creator_role": "SA",
      email: props.editdata?.email ?? "",
      firstnm: props.editdata?.firstnm ?? "",
      lastnm: props.editdata?.lastnm ?? "",
      mobnum: props.editdata?.mobnum ?? "",
      // password: copyPassword.value ?? props.editdata?."",
      role_id: props.editdata?.role_id ?? "",
      security_ans: props.editdata?.security_ans ?? "",
      security_ques: props.editdata?.security_ques ?? "",
      org_name: props.editdata?.org_name ?? "",
      org_address: props.editdata?.org_address ?? "",
      org_state: props.editdata?.org_state ?? "",
      org_district: props.editdata?.org_district ?? "",
      org_pincode: props.editdata?.org_pincode ?? "",
      org_cin_num: props.editdata?.org_cin_num ?? "",
      org_size: props.editdata?.org_size ?? "",
      org_type: props.editdata?.org_type ?? "",
      org_sector: props.editdata?.org_sector ?? "",
      org_logo: props.editdata?.org_logo ?? "",
      role_access: props.editdata?.role_access ?? "",
      //   org_website: "",
      // confirm_password: copyPassword.value ?? "",
      //   form_link: formLink,
      //   partner_association: "",
      //   assign_to_owner: "",
      // "assign_to_owner": "SA"
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Enter email"),
      //security_ques: Yup.string().required("Please select a question "),
      //security_ans: Yup.string().required("Please enter your answer"),
      // password: Yup.string()
      //   .min(8, "Password must be 8 characters long")
      //   .matches(/[0-9]/, "Password requires a number")
      //   .matches(/[a-z]/, "Password requires a lowercase letter")
      //   .matches(/[A-Z]/, "Password requires an uppercase letter")
      //   .matches(/[^\w]/, "Password requires a special charator"),
      // confirm_password: Yup.string()
      //   .required("Please enter confirm password")
      //   .oneOf([Yup.ref("password"), null], "Password must match"),
      org_name: Yup.string().required("Please enter organization name"),
      //org_address: Yup.string().required("Please enter address"),
      //org_state: Yup.string().required("Please enter state"),
      //org_district: Yup.string().required("Please enter district"),
      org_pincode: Yup.string()
        .notRequired()
        //.required("Please enter pincode")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(6, "Please enter 6 digit number"),
      role_access: Yup.string().required("Please select role access"),
      //org_cin_num: Yup.string()
      //.required("Please enter CIN number")
      //.length(21, "CIN number should be 21 digits"),
      //org_size: Yup.string().required("Please select size of the organization"),
      //org_type: Yup.string().required("Please select indutry type"),
      //org_sector: Yup.string().required("Please select sector type"),
      // org_logo: Yup.string().required(
      //   "Please upload files < 5mb and make sure format is either jpeg or png"
      // ),
      // org_website: Yup.string().required("Please enter org website"),
      //   form_link: Yup.string().required("Please enter form link"),
      //   partner_association: Yup.string().required("Please assign project owner"),
      //   assign_to_owner: Yup.string().required("Please assign center owner"),
    }),
    onSubmit: (values) => {
      delete values.confirm_password;
      // delete values.partner_association;
      let formData = new FormData();
      formData.append("reg_id", props.editdata.key),
        formData.append("updatedby", registerID),
        formData.append("updator_role", roleId);
      formData.append("role_id", "JP");
      formData.append("creator_role", roleId);
      formData.append("createdby", registerID);
      formData.append("wa_notify", check);
      formData.append("firstnm", values.firstnm);
      formData.append("lastnm", values.lastnm);
      formData.append("mobnum", values.mobnum);
      formData.append("email", values.email);
      formData.append("security_ques", props.editdata?.security_ques);
      formData.append("security_ans", props.editdata?.security_ans);
      // formData.append("password", values.password);
      formData.append("org_name", values.org_name);
      formData.append("org_address", values.org_address);
      formData.append("org_state", values.org_state);
      formData.append("org_district", values.org_district);
      formData.append("org_pincode", values.org_pincode);
      formData.append("org_cin_num", values.org_cin_num);
      formData.append("org_size", values.org_size);
      formData.append("org_type", values.org_type);
      formData.append("org_sector", values.org_sector);
      formData.append("org_logo", resumefile !== "" ? resumefile : values.org_logo);
      formData.append("role_access", values.role_access);
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL_REGISTER}/jobprovider/register`,
          //  {
          //   ...values,
          //   role_id: "JP",
          //   creator_role: roleId,
          //   // creator_role: "SA",
          //   createdby: registerID,
          //   wa_notification: check ? "true" : "false",
          // }
          formData
        )
        .then((res) => {
          if (res.status === 201) {
            // Setshow(true);
            Swal.fire({
              //position: "top",
              icon: "success",
              title: "Profile updated successfully",
              //text: "Profile updated successfully",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              props.Seteditadmin(false);
              fetchadminList();
              navigate("/jobprovider-list-page");
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "",
              title: `${res.data["Developer Message"]}`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err?.response?.data["Developer Message"] ? err.response.data["Developer Message"] : "Something went wrong",
            text: err?.response?.data["User Message"] ? err.response.data["User Message"] : "Something went wrong",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            props.Seteditadmin(false);
            navigate("/jobprovider-list-page");
          });
        });
    },
  });

  const showAlert = () => {
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Registration Successful",
      text: "Super Admin Created successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      navigate("/admin/admin-list-page");
    });
  };

  return (
    <>
      <Modal
        show={props?.editadmin}
        onHide={() => {
          props?.Seteditadmin(false);
        }}
        size="lg"
        centered
        style={{ marginTop: "52px" }}
      >
        <Modal.Header closeButton>Update Job Provider Details</Modal.Header>
        <Modal.Body>
          <SoftBox component="form" role="form" onSubmit={registrationJP.handleSubmit}>
            <Grid container>
              <Card sx={{ overflow: "visible" }}>
                <SoftBox p={2}>
                  <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                    Organization Details
                  </SoftTypography>
                  <SoftBox component="form" role="form">
                    <SoftBox
                      // display="flex"
                      // flexDirection="row"
                      // justifyContent="space-between"
                      mb={3}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              Name of Organization <sup style={{ color: "red" }}>*</sup>
                            </SoftTypography>

                            <SoftInput
                              type="text"
                              placeholder=""
                              id="org_name"
                              name="org_name"
                              onChange={registrationJP.handleChange}
                              value={registrationJP.values.org_name}
                            />
                            {registrationJP.touched.org_name && registrationJP.errors.org_name ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.org_name}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              Address
                            </SoftTypography>

                            <SoftInput
                              type="text"
                              placeholder=""
                              id="org_address"
                              name="org_address"
                              onChange={registrationJP.handleChange}
                              value={registrationJP.values.org_address}
                            />
                            {registrationJP.touched.org_address &&
                              registrationJP.errors.org_address ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.org_address}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              State
                            </SoftTypography>

                            <SoftSelect
                              options={state}
                              placeholder="Select State"
                              id="org_state"
                              name="org_state"
                              onChange={(e) => {
                                registrationJP.setFieldValue("org_state", e.label);
                                setSelectedState(e.label);
                              }}
                              value={{ label: registrationJP.values.org_state }}
                            />
                            {registrationJP.touched.org_state && registrationJP.errors.org_state ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.org_state}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              District
                            </SoftTypography>

                            <SoftSelect
                              options={district}
                              placeholder=""
                              id="org_district"
                              name="org_district"
                              onChange={(e) => {
                                registrationJP.setFieldValue("org_district", e.label);
                              }}
                              value={{
                                label: registrationJP.values.org_district,
                              }}
                            />
                            {registrationJP.touched.org_district &&
                              registrationJP.errors.org_district ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.org_district}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              PINCODE
                            </SoftTypography>

                            <SoftInput
                              type="number"
                              placeholder=""
                              id="org_pincode"
                              name="org_pincode"
                              onChange={registrationJP.handleChange}
                              value={registrationJP.values.org_pincode}
                            />
                            {registrationJP.touched.org_pincode &&
                              registrationJP.errors.org_pincode ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.org_pincode}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              Sector Type
                            </SoftTypography>

                            <SoftSelect
                              options={industryOptions}
                              placeholder="Select industry type"
                              id="org_type"
                              name="org_type"
                              onChange={(e) => {
                                registrationJP.setFieldValue("org_type", e.label);
                              }}
                              value={{
                                label: registrationJP.values.org_type,
                              }}
                            />
                            {registrationJP.touched.org_type && registrationJP.errors.org_type ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.org_type}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              Industry Logo
                            </SoftTypography>

                            <input
                              className="form-control"
                              accept=".jpg, jpeg, .png"
                              type="file"
                              placeholder=""
                              ref={ref}
                              onChange={handleFileUpload}
                            />
                            <SoftTypography fontWeight="medium" fontSize="12px">
                              {
                                "Please upload file < 5mb and make sure format is either jpg, jpeg or png."
                              }
                            </SoftTypography>

                            {registrationJP.touched.org_logo && registrationJP.errors.org_logo ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.org_logo}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        {/*
                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftBox display="flex" flexDirection="row">
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Size of Unit&nbsp;
                              </SoftTypography>
                              <SoftTypography
                                isClearable
                                fontSize="10px"
                                fontWeight="small"
                                // display="flex"
                                // justifyContent="flex-end"
                                pt="7px"
                              >
                                (Revenue based)
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              options={sizeOptions}
                              placeholder="Select Size of unit"
                              id="org_size"
                              name="org_size"
                              onChange={(e) => {
                                registrationJP.setFieldValue("org_size", e.label);
                                setSelectedState(e.label);
                              }}
                              value={{ label: registrationJP.values.org_size }}
                            />
                            {registrationJP.touched.org_size &&
                              registrationJP.errors.org_size ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.org_size}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        */}
                      </Grid>
                    </SoftBox>
                    {/* <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={1}
                        ><Grid container spacing={8}>
                                <Grid item xs={12} lg={6} container spacing={3} >
                                    <SoftBox width="100%" pl={2} >
                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                            Website
                                        </SoftTypography>
                                        <SoftInput type="text" placeholder=""
                                            id="org_website"
                                            name="org_website"
                                            onChange={registrationJP.handleChange}
                                            value={registrationJP.values.org_website}
                                        />
                                        {registrationJP.touched.org_website && registrationJP.errors.org_website ?
                                            (<span style={{ color: "red", fontSize: "12px" }}>{registrationJP.errors.org_website}</span>) : null
                                        }
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox> */}
                  </SoftBox>
                </SoftBox>

                <SoftBox p={2}>
                  <SoftTypography fontWeight="medium" fontSize="20px" mb={4}>
                    Contact Details
                  </SoftTypography>
                  <SoftBox component="form" role="form" onSubmit={registrationJP.handleSubmit}>
                    {/* <SoftBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        mb={3}
                      >
                        <SoftBox width="429px">
                          <SoftTypography fontWeight="medium" fontSize="16px">
                            Email
                          </SoftTypography>
                          <SoftInput type="email" placeholder="" />
                        </SoftBox>
                        <SoftBox width="429px">
                          <SoftTypography fontWeight="medium" fontSize="16px">
                            Mobile Number
                          </SoftTypography>
                          <SoftInput type="number" placeholder="" />
                          <SoftBox display="flex" mt={1}>
                            <input type="checkbox" placeholder="" />
                            <SoftTypography fontWeight="medium" fontSize="12px">
                              &nbsp; You&apos;ll be Receiving Updates On{" "}
                              <Icon icon="mdi:whatsapp" color="green" width="12" inline={true} />{" "}
                              Whatsapp
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox> */}
                    <SoftBox
                      // display="flex"
                      // flexDirection="row"
                      // justifyContent="space-between"
                      mb={3}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              Email <sup style={{ color: "red" }}>*</sup>
                            </SoftTypography>

                            <SoftInput
                              type="email"
                              placeholder=""
                              id="email"
                              name="email"
                              onChange={registrationJP.handleChange}
                              value={registrationJP.values.email}
                            />
                            {registrationJP.touched.email && registrationJP.errors.email ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.email}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              Mobile Number <sup style={{ color: "red" }}>*</sup>
                            </SoftTypography>

                            <SoftInput
                              type="tel"
                              inputProps={{
                                pattern: "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                                maxLength: "10",
                                minLength: "10",
                              }}
                              placeholder=""
                              id="mobnum"
                              name="mobnum"
                              onChange={registrationJP.handleChange}
                              value={registrationJP.values.mobnum}
                            />
                            <SoftBox display="flex" mt={1}>
                              <input
                                type="checkbox"
                                name="checkbox"
                                checked={check}
                                onChange={() => setCheck(!check)}
                                placeholder=""
                              />
                              <SoftTypography fontWeight="medium" fontSize="12px">
                                &nbsp; You&apos;ll be Receiving Updates On{" "}
                                <Icon icon="mdi:whatsapp" color="green" width="12" inline={true} />{" "}
                                Whatsapp
                              </SoftTypography>
                            </SoftBox>
                            {registrationJP.touched.mobnum && registrationJP.errors.mobnum ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.mobnum}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              First Name <sup style={{ color: "red" }}>*</sup>
                            </SoftTypography>

                            <SoftInput
                              type="text"
                              placeholder=""
                              id="firstnm"
                              name="firstnm"
                              onChange={registrationJP.handleChange}
                              value={registrationJP.values.firstnm}
                            />
                            {registrationJP.touched.firstnm && registrationJP.errors.firstnm ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.firstnm}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              Last Name <sup style={{ color: "red" }}>*</sup>
                            </SoftTypography>

                            <SoftInput
                              type="text"
                              placeholder=""
                              id="lastnm"
                              name="lastnm"
                              onChange={registrationJP.handleChange}
                              value={registrationJP.values.lastnm}
                            />
                            {registrationJP.touched.lastnm && registrationJP.errors.lastnm ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.lastnm}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <SoftBox>
                            <SoftTypography fontWeight="medium" fontSize="16px">
                              Select Role <sup style={{ color: "red" }}>*</sup>
                            </SoftTypography>

                            <SoftSelect
                              options={RoleOptions}
                              placeholder="Select role"
                              id="role_access"
                              name="role_access"
                              value={{
                                label: registrationJP.values.role_access,
                                value: registrationJP.values.role_access,
                              }}
                              onChange={(e) => {
                                registrationJP.setFieldValue("role_access", e.label);
                                setSelectedRole(e.label);
                              }}
                            />
                            {registrationJP.touched.role_access &&
                              registrationJP.errors.role_access ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.role_access}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>

                    {/* <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                                <SoftBox
                                  // pl={2}
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                  height="auto"
                                  sx={{
                                    border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                    bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                    <input
                                      name="password"
                                      type="radio"
                                      defaultChecked="true"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          Setautopass(true);
                                        } else {
                                          Setautopass(false);
                                        }
                                      }}
                                    />
                                  </SoftBox>

                                  <SoftBox display="flex" flexDirection="column" my="5px">
                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                      Auto Generate Password
                                    </SoftTypography>
                                    <SoftTypography
                                      fontWeight="small"
                                      fontSize="15px"
                                      lineHeight="16px"
                                    >
                                      Password will be automatically generated after the
                                      registration is done.
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                                <SoftBox
                                  // pl={2}
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                  height="75px"
                                  sx={{
                                    border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                    bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                    <input
                                      name="password"
                                      type="radio"
                                      onChange={() => {
                                        registrationJP.setFieldValue("password", "");
                                        registrationJP.setFieldValue(
                                          "confirm_password",
                                          ""
                                        );
                                        Setautopass(false);
                                      }}
                                    />
                                  </SoftBox>
                                  <SoftBox display="flex" flexDirection="column" my="5px">
                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                      Custom Password
                                    </SoftTypography>
                                    <SoftTypography
                                      fontWeight="small"
                                      fontSize="15px"
                                      lineHeight="16px"
                                    >
                                      You can create a custom password for the user
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox> */}
                    {/* <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Password
                                    </SoftTypography>
                                    {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> *
                                  </SoftBox>
                                  <SoftInput
                                    type="password"
                                    placeholder=""
                                    disabled={autopass}
                                    id="password"
                                    name="password"
                                    onChange={registrationJP.handleChange}
                                    value={registrationJP.values.password}
                                  />
                                  {registrationJP.touched.password &&
                                  registrationJP.errors.password ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationJP.errors.password}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Confirm Password
                                    </SoftTypography>
                                    {/* <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  <sup>*</sup>
                                </SoftBox> *
                                  </SoftBox>
                                  <SoftInput
                                    type="password"
                                    placeholder=""
                                    disabled={autopass}
                                    id="confirm_password"
                                    name="confirm_password"
                                    onChange={registrationJP.handleChange}
                                    value={registrationJP.values.confirm_password}
                                  />
                                  {registrationJP.touched.confirm_password &&
                                  registrationJP.errors.confirm_password ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationJP.errors.confirm_password}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox> */}

                    {/*
                    <SoftBox
                      // display="flex"
                      // flexDirection="row"
                      // justifyContent="space-between"
                      mb={5}
                    >
                      <Grid container spacing={8}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox display="flex" flexDirection="row">
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Security Question
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              placeholder="select questions"
                              options={questions}
                              id="security_ques"
                              name="security_ques"
                              onChange={(e) => {
                                registrationJP.setFieldValue("security_ques", e.label);
                              }}
                              value={{
                                label: registrationJP.values.security_ques,
                              }}
                            // hhbkbnkj()
                            />
                            {registrationJP.touched.security_ques &&
                              registrationJP.errors.security_ques ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.security_ques}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={6} container spacing={3}>
                          <SoftBox width="100%" pl={2}>
                            <SoftBox display="flex" flexDirection="row">
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Answer
                              </SoftTypography>
                              
                            </SoftBox>
                            <SoftInput
                              type="text"
                              placeholder=""
                              id="security_ans"
                              name="security_ans"
                              onChange={registrationJP.handleChange}
                              // value={registrationJP.values.security_ans}
                              value={registrationJP.values.security_ans}
                            />
                            {registrationJP.touched.security_ans &&
                              registrationJP.errors.security_ans ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {registrationJP.errors.security_ans}
                              </span>
                            ) : null}
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    */}
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center", pb: 2 }}>
            <Box sx={{ p: 1 }}>
              <SoftBox
                pl={2}
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width={{ lg: "200px", sm: "100%", xs: "50%" }}
                onClick={registrationJP.handleSubmit}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Submit
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                pl={2}
                sx={{
                  height: "45px",
                  backgroundColor: "#c4c4c4",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width={{ lg: "200px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  props.Seteditadmin(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Cancel
                </SoftTypography>
              </SoftBox>
            </Box>
            <br />
          </Box>

          {/*
          <Grid container my={2} ml={0}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6} sm={6} container spacing={1}>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "45px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={registrationJP.handleSubmit}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Submit
                  </SoftTypography>
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6} sm={6} container spacing={1}>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "45px",
                    backgroundColor: "#c4c4c4",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    props.Seteditadmin(false);
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#3B5092",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Cancel
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

AdminEdit.propTypes = {
  editadmin: PropTypes.bool.isRequired,
  Seteditadmin: PropTypes.func.isRequired,
  editdata: PropTypes.object.isRequired,
  fetchadminList: PropTypes.func.isRequired,
  // h4: PropTypes.string.isRequired,
  // v1: PropTypes.string.isRequired,
  // v2: PropTypes.string.isRequired,
  // v3: PropTypes.string.isRequired,
  // v4: PropTypes.string.isRequired,
};
export default AdminEdit;
