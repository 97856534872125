import SoftBadge from "components/SoftBadge";
import { SelectColumnFilter } from "components/Filter/Filter";

// ProductsList page components
import ProductCell from "layouts/ecommerce/products/products-list/components/ProductCell";



// Badges
const cancelled = (
    <SoftBadge variant="contained" color="error" size="xs" badgeContent="Cancelled" container />
);
const completed = (
    <SoftBadge variant="contained" color="success" size="xs" badgeContent="Active" container />
);

const pending = (
    <SoftBadge variant="contained" color="warning" size="xs" badgeContent="In -Active" container />
);

const renderDash = ({ value }) => (value === null || value === undefined ? "-" : value);

// custom filter func to display all rows, if "All"(value = "") is selected
// does not support Multi Select feature
const customFilterFn = (rows, columnIds, filterValue) => {
    return filterValue === ""
        ? rows
        : rows.filter((row) => filterValue.includes(String(row.original[columnIds])));
};

/* eslint-disable react/prop-types */

const jobseekartable = {
    columns: [
        {
            Header: "Name",
            accessor: "product",
            width: "15%",
            align: "left",
            disableFilters: true,
            Cell: ({ value: [name, data] }) => (
                <ProductCell
                    // image={data.image}
                    name={name}
                    checked={data.checked}
                // imageHeight="24px"
                // imageWidth="36px"
                />
            ),
        },
        {
            Header: "Location",
            accessor: "category",
            Cell: renderDash,
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
        {
            Header: "Organization",
            accessor: "company",
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%"
        },
         {
            Header: "Last Modified",
            accessor: "doc",
            Cell: renderDash,
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
        {
            Header: "Job Status",
            accessor: "status",
            Cell: ({ value }) =>
                value === "Pending" ? pending : value === "Completed" ? completed : cancelled,
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
               
        {
            Header: "Last Active",
            accessor: "la",
            disableFilters: true,
            Cell: renderDash,
            width: "15%",
        },

       
        {
            Header: "Phone Number",
            accessor: "pn",
            width: "15%",
            disableFilters: true,
            Cell: renderDash,
        },
        {
            Header: "Email",
            accessor: "price",
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
    ],

    rows: [
        {
            product: ["Rajesh Shah", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 0,
            status: "Completed",
            paymentStatus: "Done",
            company: "Reliance LTD",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Verma", { checked: true }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Hardware",
            quantity: 0,
            status: "Cancelled",
            paymentStatus: "Rejected",
            company: "Adani Power",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Rajesh Shah", { checked: false }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Hardware",
            quantity: 978,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Reliance LTD",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Verma", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Workshop",
            quantity: 0,
            status: "Cancelled",
            paymentStatus: "Rejected",
            company: "Adani Power",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Rajesh Shah", { checked: false }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 725,
            status: "Completed",
            paymentStatus: "Done",
            company: "Reliance LTD",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Verma", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Hardware",
            quantity: 725,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Adani Power",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Rajesh Shah", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 725,
            status: "Completed",
            paymentStatus: "Done",
            company: "Reliance LTD",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Verma", { checked: true }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku: "Hardware",
            quantity: 51293,
            status: "Completed",
            paymentStatus: "Done",
            company: "Adani Power",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Rajesh Shah", { checked: true }],
            category: "Dahisar",
            price: "jay@gmail.com",
            sku: "Workshop",
            quantity: 34,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Reliance LTD",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Verma", { checked: false }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku:"Hardware",
            quantity: 0,
            status: "Cancelled",
            paymentStatus: "Rejected",
            company: "Adani Power",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Rajesh Shah", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 32,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Reliance LTD",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Verma", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com,999",
            sku:"Hardware",
            quantity: 22,
            status: "Completed",
            paymentStatus: "Done",
            company: "Adani Power",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Rajesh Shah", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Hardware",
            quantity: 725,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Reliance LTD",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Verma", { checked: false }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 0,
            status: "Cancelled",
            paymentStatus: "Rejected",
            company: "Adani Power",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Rajesh Shah", { checked: true }],
            category: "Dahisar",
            price: "jay@gmail.com",
            sku: "Hardware",
            quantity: 725,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Reliance LTD",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
    ],
};

export default jobseekartable;