import React from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

const Channlpartendashboard = () => {
  return (
    <><DashboardLayout>
    <SoftBox>
        <SoftTypography fontSize="24px" mb={2} fontWeight="medium">
            Overview
        </SoftTypography>
    </SoftBox>
    </DashboardLayout>
    </>
  )
}

export default Channlpartendashboard