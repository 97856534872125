import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Row, Col } from "react-bootstrap";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from "axios";

const Centerjobprovider = () => {
    const employeeform = useFormik({
        initialValues: {
            email: "",
            mobnum: "",
            firstnm: "",
            lastnm: "",
            password: "",
            confirmpassword: "",
            security_ques: "",
            security_ans: ""
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Please enter valid email").required("Enter Email"),
            mobnum: Yup.string().required(" Please enter mobile number").matches(/^[0-9]+$/, "Must be only digits").length(10, "Please enter 10 digit number"),
            firstnm: Yup.string().required("Please enter first name"),
            lastnm: Yup.string().required(" Please enter last name"),
            password: Yup.string().min(8, 'Password must be 8 characters long')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter')
                .matches(/[^\w]/, 'Password requires a special charator'),
            security_ques: Yup.string().required("Please select a question "),
            security_ans: Yup.string().required("Please enter your answer"),
            confirmpassword: Yup.string().required("Please enter confirm password").oneOf([Yup.ref('password'), null], 'Password must match')
        }),
        onSubmit: values => {
            console.log(values)
        }
    })

    return (
        <>
            <DashboardLayout>
                <SoftBox mt={0} width="100%">
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
                        <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                            Job Provider
                        </SoftTypography>
                        <SoftBox width="100%" component="form" role="form"
                            onSubmit={employeeform.handleSubmit}
                        >
                            <Grid container >
                                <SoftBox mt={4} width="100%" height="auto">
                                    <Card sx={{ overflow: "visible" }}>
                                        <SoftBox mt={4} width="100%" height="auto" >
                                            <SoftBox pl={2} pr={4} pt={2} pb={3}>
                                                <SoftTypography fontWeight="medium" fontSize="16px" mb={4.5}>
                                                    Organization Details
                                                </SoftTypography>
                                                <SoftBox component="form" role="form" width="100%" >
                                                    <SoftBox
                                                        mb={3}
                                                    >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                                <SoftBox width="100%" pl={2} >
                                                                    <SoftBox display="flex" flexDirection="row">
                                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                                            Name Of Organization
                                                                        </SoftTypography>
                                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                    <SoftInput type="email" placeholder=""
                                                                        id="email"
                                                                        name="email"
                                                                        onChange={employeeform.handleChange}
                                                                        value={employeeform.values.email}
                                                                    />
                                                                    {employeeform.touched.email && employeeform.errors.email ?
                                                                        (<span style={{ color: "red", fontSize: "12px" }}>{employeeform.errors.email}</span>) : null
                                                                    }
                                                                </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                                <SoftBox width="100%" pl={2} >
                                                                    <SoftBox display="flex" flexDirection="row">
                                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                                            Address
                                                                        </SoftTypography>
                                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                    <SoftInput type="text" placeholder=""
                                                                        id="mobnum"
                                                                        name="mobnum"
                                                                        onChange={employeeform.handleChange}
                                                                        value={employeeform.values.mobnum}
                                                                    />
                                                                    {employeeform.touched.mobnum && employeeform.errors.mobnum ?
                                                                        (<span style={{ color: "red", fontSize: "12px" }}>{employeeform.errors.mobnum}</span>) : null
                                                                    }
                                                                </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                    </SoftBox>
                                                    <SoftBox
                                                        mb={3}
                                                    >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                                <SoftBox width="100%" pl={2} >
                                                                    <SoftBox display="flex" flexDirection="row">
                                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                                            State
                                                                        </SoftTypography>
                                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                    <SoftInput type="text" placeholder=""
                                                                        id="firstnm"
                                                                        name="firstnm"
                                                                    />

                                                                </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                                <SoftBox width="100%" pl={2} >
                                                                    <SoftBox display="flex" flexDirection="row">
                                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                                            City
                                                                        </SoftTypography>
                                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                    <SoftInput type="text" placeholder=""
                                                                        id="lastnm"
                                                                        name="lastnm"
                                                                        onChange={employeeform.handleChange}
                                                                        value={employeeform.values.lastnm}
                                                                    />
                                                                    {employeeform.touched.lastnm && employeeform.errors.lastnm ?
                                                                        (<span style={{ color: "red", fontSize: "12px" }}>{employeeform.errors.lastnm}</span>) : null
                                                                    }
                                                                </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                    </SoftBox>
                                                    <SoftBox
                                                        mb={3}
                                                    >
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                                <SoftBox width="100%" pl={2} >
                                                                    <SoftBox display="flex" flexDirection="row">
                                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                                            PinCode
                                                                        </SoftTypography>
                                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                    <SoftInput type="text" placeholder=""
                                                                        id="firstnm"
                                                                        name="firstnm"
                                                                    />

                                                                </SoftBox>
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} container spacing={3} >
                                                                <SoftBox width="100%" pl={2} >
                                                                    <SoftBox display="flex" flexDirection="row">
                                                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                                                            Industry Type
                                                                        </SoftTypography>
                                                                        <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                    <SoftInput type="text" placeholder=""
                                                                        id="lastnm"
                                                                        name="lastnm"
                                                                        onChange={employeeform.handleChange}
                                                                        value={employeeform.values.lastnm}
                                                                    />
                                                                    {employeeform.touched.lastnm && employeeform.errors.lastnm ?
                                                                        (<span style={{ color: "red", fontSize: "12px" }}>{employeeform.errors.lastnm}</span>) : null
                                                                    }
                                                                </SoftBox>
                                                            </Grid>
                                                        </Grid>
                                                    </SoftBox>
                                                    <SoftBox pl={2} pr={4} pt={2} pb={4}>
                                                        <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                                            Contact Details
                                                        </SoftTypography>

                                                        <SoftBox
                                                            // display="flex"
                                                            // flexDirection="row"
                                                            // justifyContent="space-between"
                                                            mb={3}
                                                        >
                                                            <Grid container spacing={8}>
                                                                <Grid item xs={12} lg={6} container spacing={3} >
                                                                    <SoftBox width="100%" pl={2}>
                                                                        <SoftBox display="flex" flexDirection="row">
                                                                            <SoftTypography fontWeight="medium" fontSize="16px">
                                                                                Contact Person&apos;s Name
                                                                            </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                        <SoftInput type="email" placeholder=""
                                                                            id="email"
                                                                            name="email"
                                                                        />

                                                                    </SoftBox>
                                                                </Grid>
                                                                <Grid item xs={12} lg={6} container spacing={3} >
                                                                    <SoftBox width="100%" pl={2}>
                                                                        <SoftBox display="flex" flexDirection="row">
                                                                            <SoftTypography fontWeight="medium" fontSize="16px">
                                                                                Phone Number
                                                                            </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                        <SoftInput type="text" placeholder=""
                                                                            id="mobilenumber"
                                                                            name="mobilenumber"
                                                                        />

                                                                    </SoftBox>
                                                                </Grid>
                                                            </Grid>
                                                        </SoftBox>
                                                        <SoftBox
                                                            // display="flex"
                                                            // flexDirection="row"
                                                            // justifyContent="space-between"
                                                            mb={3}
                                                        >
                                                            <Grid container spacing={8}>
                                                                <Grid item xs={12} lg={6} container spacing={3} >
                                                                    <SoftBox width="100%" pl={2}>
                                                                        <SoftBox display="flex" flexDirection="row">
                                                                            <SoftTypography fontWeight="medium" fontSize="16px">
                                                                                Email
                                                                            </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                        <SoftInput type="email" placeholder=""
                                                                            id="email"
                                                                            name="email"
                                                                        />

                                                                    </SoftBox>
                                                                </Grid>

                                                            </Grid>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </SoftBox>
                            </Grid>
                        </SoftBox>
                        <Grid container mt={4} ml={2}>
                            <Grid container spacing={8}>
                                <Grid item xs={12} lg={6} sm={6} container spacing={3} >

                                    <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                        width={{ lg: "100%", sm: "100%", xs: "50%" }}
                                        onClick={employeeform.handleSubmit}
                                    >
                                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                            Sumbit
                                        </SoftTypography>
                                    </SoftBox >

                                </Grid>
                                <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                                    <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#FFFFFF", borderRadius: "5px" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                        width={{ lg: "100%", sm: "100%", xs: "50%" }} >
                                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                            Cancel
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>

                            </Grid>
                        </Grid>

                    </SoftBox>
                </SoftBox>
            </DashboardLayout>
        </>
    )
}

export default Centerjobprovider