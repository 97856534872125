import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftSelect from "../../../components/SoftSelect";
import SoftInput from "components/SoftInput";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import downArrow from "assets/icons/down_arrow.png";
import { IoShareSocial } from "react-icons/io5";
import axios from "axios";
import { Table } from "ant-table-extensions";
// import { Table } from "antd";
import { Badge, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import SoftBadge from "components/SoftBadge";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Button, Card } from "@mui/material";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import refreshBtn from "../../../assets/images/refresh_btn.png";
import { useDispatch } from 'react-redux'
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import FadeLoader from "react-spinners/FadeLoader";
import { Line, Bar } from "react-chartjs-2";

import { faker } from "@faker-js/faker";
import { Box } from "@mui/system";
import EditPosting from "./editPosting";
import { Tooltip as Tool } from "@mui/material";




const JobPosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const translate = useTranslate()


  const registerID = sessionStorage.getItem("register_id");
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [jobPosted, setJobPosted] = useState("");
  const [jobApplied, setJobApplied] = useState("");
  const [applicantSelected, setApplicantSelected] = useState("");
  const [applicantShortlisted, setApplicantShortlisted] = useState("");
  const [neworgId, setNewOrdId] = useState("");
  const [name, setName] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [newSearchData, setNewSearchData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [show, setShow] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [editadmin, Seteditadmin] = useState(false);
  const [editdata, Seteditdata] = useState({});
  const [newName, setNewName] = useState("");
  const [searchSet, setSearchSet] = useState(true);
  const [trendingJobs, setTrendingJobs] = useState([]);
  const [cpList, setCPList] = useState([]);
  const [assignedCPList, setAssignedCPList] = useState([]);
  const [cpId, setCPId] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [assignCount, setAssignCount] = useState("");
  const [tablerow, setTablerow] = useState([])
  const [cpAssignPayload, setCPAssignPayload] = useState({
    jobId: "",
    orgId: "",
    candidates: "",
    jobRole: "",
  });
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };

  const filterOffsetFunc = () => {
    setPaginationState({ ...paginationState, page: 1 });
  };

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };
  useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);


  // const min = 0;
  // const max = cpAssignPayload.candidates;
  const callback = () => {
    // toggle(!isToggled);
    // onClick(!isToggled);
    console.log("toggle state1:", toggle);
    setToggle(!toggle);
  };
  const handleChange = (event) => {
    const value = Math.max(0, Math.min(cpAssignPayload.candidates, Number(event.target.value)));
    setAssignCount(value);
  };
  console.log("toggle state2:", toggle);

  const callback1 = () => {
    setAmt(0);
    setToggle1(!toggle1);
  };

  console.log("toggle state2:", toggle);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobprovider/dashboard/trending/jobs`
      );
      const data = await res.data;
      setTrendingJobs(data?.joblist);
    } catch (err) {
      console.log(err);
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };



  const expandedRowRender = (row) => {
    console.log("row", row);
    // setRoleList([]);
    const columns = [
      {
        title: "Date",
        //dataIndex: "date",
        key: "date",
        sorter: {
          compare: (a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
          multiple: 1,
        },
        render: (text) => <>{text.date ? moment(text.date).format("DD-MM-YYYY") : "-"}</>,
      },
      {
        title: "Job Name",
        dataIndex: "Jobname",
        key: "Jobname",
        sorter: {
          compare: (a, b) => a?.Jobname?.localeCompare(b?.Jobname),
          multiple: 2,
        },
      },
      {
        title: "Job Type",
        dataIndex: "Jobtype",
        key: "Jobtype",
        sorter: {
          compare: (a, b) => a?.Jobtype?.localeCompare(b?.Jobtype),
          multiple: 3,
        },
      },
      {
        title: "Requirement",
        dataIndex: "reqirement",
        key: "reqirement",
        sorter: {
          compare: (a, b) => a.reqirement - b.reqirement,
          multiple: 4,
        },
      },
      // { title: "Location", dataIndex: "location", key: "location" },
      { title: "State", dataIndex: "state", key: "state" },
      { title: "District", dataIndex: "district", key: "district" },
      {
        title: "Job Status",
        /*
        key: "job_status",
        sorter: {
          compare: (a, b) => a?.job_status?.localeCompare(b?.job_status),
          multiple: 2,
        },
        */
        render: (text, record) => (
          console.log(text),
          (
            // <span>
            //   <SoftBadge
            //     variant="contained"
            //     color={text.job_status === true ? "active" : "primary"}
            //     size="xs"
            //     badgeContent={text.job_status === true ? "Active" : "In active"}
            //     container
            //   />
            // </span>
            <div
              // className="dropdown action-label text-center toggle-btn"
              className="dropdown"
              style={{
                width: "100%",
                height: "30px",
                // border: "1px solid ",
                borderRadius: "15px",
                backgroundColor: text.job_status ? "#BDFDC8" : "#EFD6FF",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  // padding: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  padding: "5px 0px 5px",
                }}
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                {text.job_status ? "Active" : "Deactivate"}
              </p>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() =>
                    text.job_status ? InactiveToggle(text.key) : activeToggle(text.key)
                  }
                >
                  {/* <i
                className={
                  text.job_status
                    ? "fa fa-dot-circle-o text-danger"
                    : "fa fa-dot-circle-o text-success"
                }
              /> */}
                  {text.job_status ? "Deactivate" : "Active"}
                </a>
              </div>
            </div>
          )
        ),
      },
      {
        title: "Action",
        // dataIndex: "operation",
        // key: "operation",
        render: (text, record) => (
          console.log("active toggle butn", text.key),
          (
            <div className="edit_icons">
              <div className="dropdown">
                <Button
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Icon icon="ion:ellipsis-vertical" color="black" />
                </Button>
                <ul
                  style={{
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    border: "0px",
                    padding: "3px",
                  }}
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {/* <li>
                    <a
                      className="dropdown-item"
                      href="#EditUser"
                      // onClick={() =>{ Seteditadmincenter(true); Seteditdata(centerdata)}}
                      onClick={() => {
                        activeToggle(text.key);
                      }}
                    >
                      Active
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      // href="#Deleteuser"
                      //  onClick={() => {deactivateCenter(centerdata);  }}
                      onClick={() => {
                        InactiveToggle(text.key);
                      }}
                    >
                      In Active
                    </a>
                  </li> */}
                  <li>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <a
                        className="dropdown-item"
                        style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                        onClick={() => {
                          sessionStorage.setItem("job_id", text.key);

                          sessionStorage.setItem("org_id", text.orgid);
                          sessionStorage.setItem("job_role", text.Jobname);
                          sessionStorage.setItem("org_name", orgName);
                          sessionStorage.setItem("shortlisted_local", true);
                          navigate(`/shortlisted?org_id=${text.orgid}&job_id=${text.key}`);
                          // navigate("")
                        }}
                      >
                        <div style={{ width: "20px" }}>
                          <Icon icon="gridicons:multiple-users" />
                        </div>
                        &nbsp; Shortlisted
                      </a>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <a
                        className="dropdown-item"
                        style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                        // href="#Deleteuser"
                        //  onClick={() => {deactivateCenter(centerdata);  }}
                        onClick={() => {
                          // InactiveToggle(text.key);
                          sessionStorage.setItem("job_id", text.key);
                          console.log(text.key, "jobids");
                          sessionStorage.setItem("searchdata_org_name", orgName);
                          sessionStorage.setItem("searchdata_org_id", text.orgid);
                          sessionStorage.setItem("searchdata_designation", text.designation);
                          sessionStorage.setItem("searchdata_salary", text.salary);
                          sessionStorage.setItem("searchdata_state", text.state);
                          sessionStorage.setItem("searchdata_district", text.district);
                          // sessionStorage.setItem("searchdata_industry", text.industry);
                          sessionStorage.setItem("searchdata_qualification", text.qualification);
                          sessionStorage.setItem("searchdata_experience", text.experience);
                          sessionStorage.setItem("searchdata_stream", text.stream);
                          sessionStorage.setItem("searchdata_specialization", text.specialization);
                          navigate("/searchbyposting/createposting/recommendedPostList");
                        }}
                      >
                        <div style={{ width: "20px" }}>
                          <Icon icon="material-symbols:search" />
                        </div>
                        &nbsp; Search
                      </a>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <a
                        href="#editposting"
                        className="dropdown-item"
                        style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                        onClick={() => {
                          Seteditadmin(true);
                          Seteditdata(text);
                        }}
                      >
                        <div style={{ width: "20px" }}>
                          <Icon icon="mdi:lead-pencil" />
                        </div>
                        &nbsp;Edit Post
                      </a>
                    </div>
                  </li>
                  {text?.is_active ? (
                    <li>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <a
                          href="#editposting"
                          className="dropdown-item"
                          style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                          onClick={() => {
                            // Seteditadmin(true);
                            // Seteditdata(text);
                            console.log("assign cp:", text);
                            // sessionStorage.setItem("job_id", text.key);
                            setCPAssignPayload({
                              ...cpAssignPayload,
                              jobId: text.key,
                              candidates: text.reqirement,
                              orgId: text.orgid,
                              jobRole: text.Jobname,
                            });
                            setModal2(true);
                          }}
                        >
                          <div style={{ width: "20px" }}>
                            {/* <Icon icon="mdi:lead-pencil" /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="18"
                              viewBox="0 0 40 40"
                            >
                              <g fill="currentColor">
                                <path
                                  d="M24.748 26.1c4.015 0 7.281-3.266 7.281-7.279c0-4.017-3.267-7.283-7.281-7.283c-4.015 0-7.282 3.267-7.282 7.283c0 4.013 3.267 7.279 7.282 7.279zm0-13.364a6.09 6.09 0 0 1 6.081 6.084a6.087 6.087 0 0 1-6.081 6.08a6.088 6.088 0 0 1-6.082-6.08c.001-3.354 2.729-6.084 6.082-6.084zm13.297 20.256c-.319-5.684-5.483-6.032-5.568-6.035l-15.492.001c-.052.002-5.217.351-5.535 6.067v4.308a.6.6 0 0 0 .6.6h25.395a.6.6 0 0 0 .6-.6v-4.341zm-1.199 3.741H12.651l-.001-3.675c.256-4.595 4.234-4.893 4.368-4.902l15.424-.001c.169.011 4.146.309 4.403 4.87v3.708zm-24.228-17.79h.004c1.233-.009 2.301-.451 3.174-1.316c.874-.873 1.316-1.941 1.316-3.176c0-1.241-.443-2.311-1.316-3.177c-.874-.873-1.942-1.316-3.176-1.316c-1.241 0-2.311.443-3.175 1.315c-.874.867-1.317 1.937-1.317 3.178c0 1.234.443 2.303 1.316 3.175c.866.866 1.933 1.309 3.174 1.317zm-2.641-7.139c.73-.737 1.596-1.096 2.644-1.096c1.042 0 1.908.359 2.647 1.099c.737.73 1.096 1.596 1.096 2.645c0 1.043-.359 1.908-1.096 2.644c-.737.731-1.604 1.091-2.647 1.099c-1.05-.008-1.916-.366-2.646-1.098c-.738-.736-1.097-1.602-1.097-2.645c0-1.05.359-1.915 1.099-2.648z"
                                  className="clr-i-solid clr-i-solid-path-1"
                                />
                                <path
                                  d="M12.039 16.15a.37.37 0 0 0 .245.092a.377.377 0 0 0 .283-.129l2.539-2.91a.374.374 0 1 0-.565-.492l-2.293 2.628l-1.128-.975a.373.373 0 1 0-.49.566l1.409 1.22zM20.13 9.8s.174-.25.618-.4c.343-.116.562-.151.562-.151V6.33c0-.768-.568-1.434-1.434-1.434h-2.194v1.18h2.448V9.8zM8.993 7.793h7.241c.37 0 .717-.26.717-.731V5.35c0-.652-.519-1.185-1.17-1.185h-.278C15.181 2.915 13.959 2 12.606 2c-1.353 0-2.575.915-2.896 2.165h-.264c-.651 0-1.184.533-1.184 1.185v1.712c0 .424.309.731.731.731zm3.613-4.345c.802 0 1.448.646 1.448 1.448c0 .802-.646 1.448-1.448 1.448a1.445 1.445 0 0 1-1.448-1.448c0-.802.646-1.448 1.448-1.448z"
                                  className="clr-i-solid clr-i-solid-path-1"
                                />
                                <path
                                  d="M15.952 22.558H5.096V6.076h2.448v-1.18H5.351c-.768 0-1.419.665-1.419 1.434v15.988c0 .768.651 1.404 1.419 1.404h11.728c0 .001-.888-.367-1.127-1.164z"
                                  className="clr-i-solid clr-i-solid-path-1"
                                />
                              </g>
                            </svg>
                          </div>
                          &nbsp;Assign Channel Partner
                        </a>
                      </div>
                    </li>
                  ) : null}

                  <li>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <a
                        href="#editposting"
                        className="dropdown-item"
                        style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                        onClick={() => {
                          // Seteditadmin(true);
                          // Seteditdata(text);
                          setModal3(true);
                          fetchAssignedCPList(text);
                        }}
                      >
                        <div style={{ width: "20px" }}>
                          {/* <Icon icon="mdi:lead-pencil" /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="16"
                            viewBox="0 0 18 16"
                            fill="none"
                          >
                            <path
                              d="M7.97315 15.0173L9.12316 13.8673C9.26583 13.7246 9.34294 13.5391 9.34294 13.3394C9.34294 13.1397 9.26596 12.9514 9.12316 12.8116C8.98049 12.6689 8.79499 12.5918 8.59532 12.5918C8.40129 12.5918 8.2158 12.666 8.07603 12.8029L6.55514 14.3238C6.55225 14.3267 6.54949 14.3294 6.54371 14.3323L6.53228 14.3438C6.38961 14.4864 6.3125 14.6719 6.3125 14.8716C6.3125 15.0713 6.38948 15.2597 6.53228 15.3994C6.81473 15.6819 7.30842 15.6819 7.59098 15.3994L7.97315 15.0173Z"
                              fill="black"
                            />
                            <path
                              d="M7.67278 12.4157C7.67567 12.4128 7.67843 12.4101 7.68421 12.4072L8.2378 11.8536C8.38047 11.7109 8.45759 11.5254 8.45759 11.3257C8.45759 11.1261 8.3806 10.9377 8.2378 10.7979C8.09514 10.6552 7.90964 10.5781 7.70997 10.5781C7.5103 10.5781 7.3219 10.6551 7.18213 10.7979L5.11041 12.8696C4.96774 13.0123 4.89062 13.1978 4.89062 13.3975C4.89062 13.5971 4.96761 13.7855 5.11041 13.9253C5.3901 14.205 5.87511 14.2078 6.15771 13.9338L7.67278 12.4157Z"
                              fill="black"
                            />
                            <path
                              d="M3.45009 10.8343L4.97962 9.30475C5.12229 9.16208 5.1994 8.97659 5.1994 8.77692C5.1994 8.57724 5.12242 8.38885 4.97962 8.24908C4.83695 8.10641 4.65145 8.0293 4.45178 8.0293C4.25211 8.0293 4.06372 8.10628 3.92395 8.24908L2.39166 9.77861C2.24899 9.92128 2.17188 10.1068 2.17188 10.3064C2.17188 10.5061 2.24886 10.6945 2.39166 10.8343C2.6854 11.1254 3.15908 11.1254 3.45009 10.8343Z"
                              fill="black"
                            />
                            <path
                              d="M6.85615 10.3321C6.99882 10.1894 7.07593 10.0039 7.07593 9.80426C7.07593 9.60459 6.99895 9.41619 6.85615 9.27642C6.71059 9.13087 6.51944 9.05664 6.32831 9.05664C6.13719 9.05664 5.9459 9.13086 5.80048 9.27642L3.60896 11.4679C3.31785 11.7591 3.31785 12.2355 3.60896 12.5266C3.90008 12.8177 4.37655 12.8178 4.66766 12.5266L6.85615 10.3321Z"
                              fill="black"
                            />
                            <path
                              d="M10.2432 14.5134L9.71817 13.9883C9.66115 14.0854 9.59836 14.1767 9.5156 14.2594L8.5625 15.2125L9.05331 15.7033C9.36715 16.0201 9.92653 16.0201 10.2433 15.7033C10.403 15.5436 10.4916 15.3324 10.4916 15.1098C10.4916 14.8873 10.4059 14.6789 10.249 14.5192L10.2432 14.5134Z"
                              fill="black"
                            />
                            <path
                              d="M1.33858 5.81062L4.76825 2.17513L4.9481 1.98399L3.72966 0.833984L0 4.78897L1.21569 5.93884L1.33858 5.81062Z"
                              fill="black"
                            />
                            <path
                              d="M10.3357 5.09631L10.0331 5.40161C9.2227 6.21205 7.9073 6.21205 7.09686 5.40161C6.58033 4.88508 6.58033 4.04612 7.09686 3.52972L7.73323 2.89336C6.91714 2.52816 6.004 2.42818 5.11658 2.61656L1.80354 6.12627C1.65509 7.12218 1.87199 8.14372 2.39706 8.99121L3.53563 7.85264C4.04352 7.34475 4.87114 7.34475 5.379 7.85264C5.60154 8.07518 5.71861 8.35778 5.74709 8.64586C6.23501 8.40046 6.84574 8.47468 7.25379 8.88272C7.49919 9.12812 7.6362 9.45629 7.6362 9.80441C7.6362 9.88139 7.62766 9.9585 7.61334 10.0327C7.97854 10.0042 8.35531 10.124 8.63488 10.4037C8.88028 10.6491 9.01729 10.9773 9.01729 11.3254C9.01729 11.5879 8.94031 11.839 8.79475 12.0531C9.06012 12.093 9.31694 12.2101 9.52242 12.4155C9.76782 12.6609 9.90484 12.9891 9.90484 13.3372C9.90484 13.3515 9.90195 13.3657 9.90195 13.38L10.6439 14.122C10.6468 14.1249 10.6468 14.1249 10.6496 14.1276L11.2574 14.7355C11.577 15.0551 12.1306 15.0551 12.4501 14.7355C12.7783 14.4073 12.7783 13.8766 12.453 13.5484L10.1816 11.277C10.0732 11.1686 10.0732 10.9946 10.1816 10.8861C10.29 10.7777 10.4641 10.7777 10.5726 10.8861L12.9181 13.2315C13.2319 13.5454 13.7969 13.5454 14.1051 13.2315C14.4333 12.9005 14.4333 12.3669 14.1051 12.0388L12.5928 10.5265C12.4844 10.4181 12.4844 10.244 12.5928 10.1355C12.7012 10.0271 12.8752 10.0271 12.9837 10.1355L14.0396 11.1856C14.3563 11.4994 14.9156 11.4994 15.2295 11.1856C15.3893 11.0258 15.4778 10.8146 15.4778 10.5921C15.4778 10.3666 15.3894 10.1555 15.2295 9.99854L14.4077 9.09683L10.3357 5.09631Z"
                              fill="black"
                            />
                            <path
                              d="M12.5625 1.14985L16.1892 4.99083V4.99372L16.2947 5.10499L17.5104 3.95498L13.7808 0L12.5625 1.14985Z"
                              fill="black"
                            />
                            <path
                              d="M12.1937 1.56866C10.901 1.38027 9.59121 1.81406 8.67251 2.73286L7.48256 3.91992C7.1829 4.21958 7.1829 4.70749 7.48256 5.00714C8.07607 5.60066 9.0406 5.60066 9.63415 5.00714L10.1335 4.50779C10.1878 4.45353 10.2591 4.42792 10.3304 4.42792C10.4018 4.42792 10.4702 4.45354 10.5245 4.50779L14.5965 8.5112C15.4155 7.64941 15.8206 6.49087 15.7208 5.3038L12.1937 1.56866Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                        &nbsp;Assigned Channel Partner
                      </a>
                    </div>
                  </li>
                  <li>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <a
                        className="dropdown-item"
                        style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                        href={`${process.env.REACT_APP_JOB_GATE_WAY}/login/${text.key}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/* <Link to={`${process.env.JOB_GATE_WAY}/login/${text.key}`} target="_blank"> */}
                        <div style={{ width: "20px" }}>
                          {/* <Icon icon="gridicons:multiple-users" /> */}
                          <IoShareSocial />
                        </div>
                        &nbsp; Share Posting
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )
        ),
      },
    ];

    setNewOrdId(row.key);
    setOrgName(row.organization);
    // const fetchOgrRole = () => {
    //   const params = {
    //     // orgid: orgId,
    //     orgid: row.key,
    //     type: "detail",
    //   };
    //   const paramString = new URLSearchParams(params).toString();
    //   axios
    //     // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
    //     .get(
    //       `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?${paramString}`
    //     )
    //     .then((res) => {
    //       // setDistrict(res.data);
    //       console.log("Role tabel", res.data["JobPost_List"]);
    //       setRoleList(res.data["JobPost_List"]);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i,
        date: "7-8-22",
        Jobname: "House Keeping",
        Jobtype: "Full Time",
        reqirement: "56",
        location: "location",
      });
    }
    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    // useEffect(() => {
    //   fetchOgrRole();
    // }, [orgId]);

    return (
      <Table
        loading={loading}
        columns={columns}
        // dataSource={data}
        dataSource={subTable}
        // pagination={false}
        rowSelection={rowSelection}
        exportableProps={{ showColumnPicker: true }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100", "500"],
        }}
      // onRow={(record, rowIndex) => {
      //   console.log(`record:${rowIndex}`, record);
      //   return expandedRowRender;
      //   // return expandedRowRender;
      //   // onClick: () => {
      //   //   console.log(`record:${rowIndex}`, record);
      //   //   return expandedRowRender;
      //   // };
      // }}
      />
    );
  };

  const [colHeaders, setColHeaders] = useState([
    {
      label: "Organisation",
      key: "organization",
      isExportable: true,
    },
    {
      label: "SPOC Name",
      key: "createdby",
      isExportable: true,
    },
    {
      label: "Date of Association",
      key: "doa",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "mobnum",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
  ]);

  const colName = [
    {
      title: "Organisation",
      // dataIndex: "organization",
      sorter: {
        compare: (a, b) => a?.organization?.localeCompare(b?.organization),
        multiple: 1,
      },
      render: (text, record) => (
        // sessionStorage.setItem()
        <>{text.organization}</>
      ),
    },
    {
      title: "SPOC Name",
      dataIndex: "createdby",
      sorter: {
        compare: (a, b) => a?.createdby?.localeCompare(b?.createdby),
        multiple: 2,
      },
    },
    // {
    //   title: "Role Access",
    //   dataIndex: "roleacess",
    //   sorter: {
    //     compare: (a, b) => a.location - b.location,
    //     multiple: 2,
    //   },
    // },
    {
      title: "Date Of Association",
      dataIndex: "doa",
      sorter: {
        compare: (a, b) => moment(a.doa, "DD-MM-YYYY") - moment(b.doa, "DD-MM-YYYY"),
        multiple: 3,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      sorter: {
        compare: (a, b) => a.mobnum - b.mobnum,
        multiple: 4,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 5,
      },
    },
  ];
  const cpColumns = [
    {
      title: "Organization",
      dataIndex: "organization",
      width: 200,
      sorter: {
        compare: (a, b) => a?.organization?.localeCompare(b?.organization),
        multiple: 1,
      },
      // render: (text, record) => (
      //   // sessionStorage.setItem()
      //   <>{text.name}</>
      // ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
        multiple: 2,
      },
    },
    {
      title: "Date Of Acceptance",
      dataIndex: "accepteddt",
      width: 150,
      sorter: {
        compare: (a, b) => moment(a.accepteddt, "DD-MM-YYYY") - moment(b.accepteddt, "DD-MM-YYYY"),
        multiple: 3,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      width: 150,
      sorter: {
        compare: (a, b) => a.mobile - b.mobile,
        multiple: 4,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 5,
      },
    },
    {
      title: "Word Order ID",
      dataIndex: "work_order_id",
      width: 200,
      // sorter: {
      //   compare: (a, b) => a.work_order_id - b.work_order_id,
      //   multiple: 6,
      // },
    },
    {
      title: "Status",
      // dataIndex: "status",
      width: 200,
      // sorter: {
      //   compare: (a, b) => a.email - b.email,
      //   multiple: 5,
      // },
      render: (text, record) => (
        // sessionStorage.setItem()

        <span>
          <SoftBadge
            variant="contained"
            color={
              text.status === "accepted" ? "success" : text.status === "declined" ? "error" : "info"
            }
            size="lg"
            badgeContent={
              text.status === "accepted"
                ? "Accepted"
                : text.status === "declined"
                  ? "Declined"
                  : "Requested"
            }
            container
          />
        </span>
      ),
    },
    {
      title: "Candidates Shared",
      dataIndex: "uploaded_cand",
      width: 200,
      sorter: {
        compare: (a, b) => a.uploaded_cand - b.uploaded_cand,
        multiple: 6,
      },
    },
  ];

  const data = [];
  for (let index = 0; index < 50; index++) {
    data.push({
      key: index,
      organization: `organization${index}`,
      roleacess: `Admin`,
      doa: `${index}-1-2023`,
      mobnum: `1234567${index}`,
      email: `${index}h@ty.com`,
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [download, Setdownload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amt, setAmt] = useState("");

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log(newSelectedRowKeys);
    setTablerow(selectedRows)
    setSelectedRowKeys(newSelectedRowKeys);
    Setdownload(
      selectedRows?.map((item, index) => {
        return {
          date_of_creation: item.date_of_creation,
          email: item.email,
          isActive: item.isActive,
          key: item.key,
          location: item.location,
          name: item.name,
          organization: item.organization,
          phone_number: item.phone_number,
          registration_id: item.registration_id,
        };
      })
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };
  const fetchJobPostKpi = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostkpi`,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      })
      .then((res) => {
        console.log("all kpi", res.data);
        setJobPosted(res.data.Job_Count);
        console.log(jobPosted);
        setJobApplied(res.data.CandidateApplied_Count);
        setApplicantSelected(res.data.CandidateSelected_Count);
        setApplicantShortlisted(res.data.Shortlisted_Count);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("job post")

  const fetchOgrRole = () => {
    const params = {
      orgid: expandedRowKeys[0],
      created_by: sessionStorage.getItem("register_id")
      ? sessionStorage.getItem("register_id")
      : "",
      type: "detail",
    };

    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?${paramString}`
      )
      .then((res) => {
        // setDistrict(res.data);
        setLoading(false);
        console.log("Role tabel", res.data["JobPost_List"]);
        setRoleList(res.data["JobPost_List"]);
      })
      .catch((err) => {
        console.log(err);
                setLoading(false);

      });
  };
  const fetchOrgList = () => {
    const params = {
      // partner: "",
      // type: "dropdown",
      type: "detail",
    };
    // const headers = {
    //   "access-control-allow-origin": "*",
    //   "Content-type": "application/json; charset=UTF-8",
    // };
    const paramString = new URLSearchParams(params).toString();
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`,
        {
          params: {
            // limit: 10,
            limit: pageSize,
            offset: paginationState.page - 1,
            search_data: name,
            created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
          },
        }
        // headers
      )
      .then((res) => {
        setLoading(false);
        console.log("org list", res.data["Organisation_Detail"]);
        setOrgList(
          res.data["Organisation_Detail"]?.map((ele) => {
            return {
              key: ele.registration_id,
              organization: ele.org_name,
              doa: moment(ele.createddt).format("DD-MM-YYYY"),
              mobnum: ele?.mobnum?.length === 12 ? ele.mobnum.substring(2) : ele.mobnum,
              email: ele.email,
              state: ele.state,
              district: ele.district,
              createdby: `${ele?.firstnm[0].toUpperCase() + ele?.firstnm.slice(1)} ${ele?.lastnm[0].toUpperCase() + ele?.lastnm.slice(1)
                }`,
            };
          })
        );
        setSearchData(
          res.data["Organisation_Detail"]?.map((ele) => {
            return {
              key: ele.registration_id,
              organization: ele.org_name,
              doa: moment(ele.createddt).format("DD-MM-YYYY"),
              mobnum: ele?.mobnum?.length === 12 ? ele.mobnum.substring(2) : ele.mobnum,
              email: ele.email,
              state: ele.state,
              district: ele.district,
              createdby: `${ele?.firstnm[0].toUpperCase() + ele?.firstnm.slice(1)} ${ele?.lastnm[0].toUpperCase() + ele?.lastnm.slice(1)
                }`,
            };
          })
        );
        setPaginationState({ ...paginationState, dataSize: res.data["count"] });
      })
      .catch((err) => {
        console.log(err);
        //setLoading(false)
      });
  };
  const activeToggle = (value) => {
    console.log("active toggle");
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/activatedeactivatejobpost`,
        {
          jobid: value,
          activate: "true",
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Activated successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchOgrRole();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err?.response?.data["Developer Message"]
            : "Something went wrong!",
          text: err?.response?.data ? err?.response?.data["User Message"] : "Something went wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const InactiveToggle = (value) => {
    console.log("active toggle");
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/activatedeactivatejobpost`,
        {
          jobid: value,
          activate: "false",
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Deactivated successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchOgrRole();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err?.response?.data["Developer Message"]
            : "Something went wrong!",
          text: err?.response?.data ? err?.response?.data["User Message"] : "Something went wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const fetchShortlistedCandidate = () => {
    const params = {
      orgid: neworgId,
      jobid: "",

    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/shortlistedcandidate?${paramString}`
      )
      .then((res) => {
        // setDistrict(res.data);
        console.log("Role tabel", res.data["JobPost_List"]);
        //  setRoleList(res.data["JobPost_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchAssignedCPList = async (data) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/dashboard/cp/details`, {
        params: {
          jobid: data.key,
        },
      })
      .then((res) => {
        console.log("list:", res.data);
        setAssignedCPList(
          res.data["cp_details"]?.map((ele, id) => ({
            key: ele.cp_id,
            organization: ele.cp_org_name,
            name: ele.cp_first_name + " " + ele.cp_last_name,
            firstnm: ele.cp_first_name,
            lastnm: ele.cp_last_name,
            jobpost_id: ele.jobpost_id,
            accepteddt: ele.accepteddt ? moment(ele.accepteddt).format("DD-MM-YYYY") : " - ",
            declineddt: ele.declineddt ? moment(ele.declineddt).format("DD-MM-YYYY") : " - ",
            modifieddt: ele.modifieddt ? moment(ele.modifieddt).format("DD-MM-YYYY") : " - ",
            uploaded_cand: ele.uploaded_cand,
            mobile: ele.cp_mobile,
            email: ele.cp_email,
            work_order_id: ele.work_order_id ? ele.work_order_id : " - ",
            status: ele.request_status,
            candidates: ele.candidate_count,
            candidate_list: ele.candidate_list,
          }))
        );
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };

  const cpListFunc = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/account/details` ,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      })
      .then((res) => {
        console.log("dd for :", res.data["cp_data"]);
        setCPList(res.data["cp_data"]);
      })
      .catch((err) => {
        console.log("dd for err:", err.response.data);
      });
  };

  const AssignCP = async () => {
    console.log(
      {
        jobid: cpAssignPayload.jobId,
        org_id: cpAssignPayload.orgId,
        cp_id: cpId.map((ele) => ele.value).join(","),
        requested_by: registerID,
        request_status: "Requested",
        candidate_count: cpAssignPayload.candidates,
        cost: amt !== "" ? amt : "0",
        candidate_required: toggle ? assignCount : cpAssignPayload.candidates,
      },
      "ppp"
    );
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/transactions/request/job`,
        {
          jobid: cpAssignPayload.jobId,
          org_id: cpAssignPayload.orgId,
          cp_id: cpId.map((ele) => ele.value).join(","),
          requested_by: registerID,
          request_status: "Requested",
          candidate_count: cpAssignPayload.candidates,
          cost: amt !== "" ? amt : "0",
          candidate_required: toggle ? assignCount : cpAssignPayload.candidates,
        }
      )
      .then((res) => {
        // console.log("dd for :", res.data["cp_data"]);
        // setCPList(res.data["cp_data"]);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            // text: res.data["User Message"],
            text: "Channel Partner Assigned Successfully",
            showConfirmButton: false,
            //position: "top",
            timer: 3000,
          }).then(() => {
            // navigate("/shortlisted");
            setModal2(false);
            setToggle(false);
            setCPAssignPayload({
              jobId: "",
              candidates: "",
              orgId: "",
              jobRole: "",
            });
            setCPId([]);
            setAmt("");
            setAssignCount("");
          });
        }
      })
      .catch((err) => {
        console.log("dd for err:", err.response.data);
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  console.log("after assign:", cpAssignPayload);
  const handleSearch = () => {
    const newData = orgList.filter((ele) => {
      return newName?.toLocaleLowerCase() === ""
        ? ele
        : ele.organization?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
          newName?.toLocaleLowerCase() === ""
          ? ele
          : ele.createdby?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
            newName?.toLocaleLowerCase() === ""
            ? ele
            : String(ele.mobnum?.toLocaleLowerCase()).includes(String(newName.toLocaleLowerCase()));
    });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch();
  }, [newName]);

  useEffect(() => {
    fetchJobPostKpi();
    fetchOrgList();
    getData();
    cpListFunc();
  }, []);

  useEffect(() => {
     expandedRowKeys[0] !== undefined &&  fetchOgrRole();
  }, [expandedRowKeys[0]]);
  // useEffect(() => {
  //   fetchAssignedCPList();
  // }, [modal3 === true]);

 
  const subTable = roleList?.map((ele, i) => {
    console.log("element of posting", ele);
    return {
      key: ele.jobid,
      date: ele.jobpostdt,
      Jobname: ele.job_title,
      Jobtype: ele.job_type ? ele.job_type : " - ",
      reqirement: ele.applicantct,
      // location: `${ele.state} ,${ele.district}`,
      job_status: ele.is_active,
      state: ele.state,
      district: ele.district,
      designation: ele.designation,
      experience: ele.experience,
      orgid: ele.orgid,
      qualification: ele.qualification,
      salary: ele.salary,
      specialization: ele.specialization,
      stream: ele.stream,
      ///
      // org_id: ele.orgid,
      org_name: ele.org_name,
      gender: ele.gender,
      jobvaliddt: ele.jobvaliddt,
      industry: ele.industry,
      skills: ele.skills,
      workfromhome: ele.workfromhome,
      jobdesc: ele.jobdesc,
      age: ele.age,
      is_active: ele.is_active,
      whatsapp_jd: ele.whatsapp_jd,
    };

    // "jobid": "SMR-JOB-225539",
    // "org_name": "Test Provider Org",
    // "gender": null,
    // "job_title": "Tets 2",
    // "job_type": null,
    // "designation": null,
    // "applicantct": 0,
    // "salary": "",
    // "experience": "",
    // "jobpostdt": "2023-06-07",
    // "jobvaliddt": "2023-06-30T14:08:00+05:30",
    // "state": "Maharashtra",
    // "district": null,
    // "industry": null,
    // "qualification": null,
    // "stream": null,
    // "specialization": null,
    // "skills": null,
    // "workfromhome": null,
    // "jobdesc": null,
    // "is_active": true,
    // "createdby": "SMR-SA-764877",
    // "creator_role": "SA",
    // // "age": 20,
    // "createddt": "2023-06-07T14:08:17.313039+05:30",
    // "modifieddt": "2023-06-07T14:08:17.313058+05:30",
    // "orgid": "SMR-JP-415134"
  });

  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };
  const onTableRowExpand = async (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.key); // I have set my record.id as row key. Check the documentation for more details.
    }

    await setExpandedRowKeys(keys);
  };
    console.log(expandedRowKeys[0],'record2')

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              {translate('jobposting')}       
              </SoftTypography>
              {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                Brief summary  Channel Partner
              </SoftTypography> */}
            </SoftBox>

            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#FFF",
                borderRadius: "2px",
                cursor: "pointer",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              width="40px"
              onClick={() => {
                fetchOrgList();
                fetchJobPostKpi();
              }}
            >
              <SoftBox>
                <img src={refreshBtn} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17090.424170128532!2d-73.95640777191161!3d40.77986536399378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2589a018531e3%3A0xb9df1f7387a94119!2sCentral%20Park!5e0!3m2!1sen!2sin!4v1664606322665!5m2!1sen!2sin"
            width="91%"
            height="350"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe> */}
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="92%"
            mt={2}
          >
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                sx={{ minHeight: "65px", alignItems: "center" }}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {jobPosted === "" ? (
                    <FadeLoader color="#FFFFFF" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    jobPosted
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={3} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {/* Total Job Posted */}
                  {translate('allposting')}       
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                sx={{ minHeight: "65px", alignItems: "center" }}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 2050 */}
                  {jobApplied === "" ? (
                    <FadeLoader color="#FFFFFF" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    jobApplied
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={3} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {translate('totalcandidates')}       
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                sx={{ minHeight: "65px", alignItems: "center" }}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 160 */}
                  {applicantSelected === "" ? (
                    <FadeLoader color="#FFFFFF" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    applicantSelected
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={3} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {translate('totalplacedcandidates')}       
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                sx={{ minHeight: "65px", alignItems: "center" }}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 250 */}
                  {applicantShortlisted === "" ? (
                    <FadeLoader color="#FFFFFF" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    applicantShortlisted
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={3} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {translate('Totalmatch')}       
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftTypography
            mb={1}
            mt={2}
            fontStyle="normal"
            fontWeight="medium"
            fontSize="24px"
          // ml={2}
          >
            {" "}
            {translate('jobposting')}       
          </SoftTypography>
          <SoftBox display="flex" flexDirection="row" justifyContent="space-between" width="92%">
            {trendingJobs.map((ele, index) => {
              return (
                <SoftBox
                  width="30%"
                  key={index}
                  display=""
                  sx={{
                    minHeight: "100px",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    cursor: "pointer",
                    padding: "16px",
                  }}
                >
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-around"
                    mt={2}
                    sx={{ minHeight: "50px" }}
                  >
                    <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                      {" "}
                      {ele}
                    </SoftTypography>
                    <SoftBadge
                      mr="16px"
                      variant="contained"
                      color="success"
                      size=""
                      badgeContent="Trending"
                      sx={{ height: "40px" }}
                      container
                    />
                  </SoftBox>
               
                </SoftBox>
              );
            })}
          </SoftBox>

          {/* <SoftBox display="flex" flexDirection="row" justifyContent="space-between" width="92%">
            
            <SoftBox width="45%" backgroundColor="#fff">
              <Line options={options} data={chartdata} />
            </SoftBox>
            
            <SoftBox width="45%">
              <Bar options={options} data={chartdata} />
            </SoftBox>
          </SoftBox> */}

          <SoftBox mt={2}>
            <SoftBox
              mb={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="92%"
            >
              <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              {translate('allposting')}       
              </SoftTypography>
              <SoftBox display="flex" flexDirection="row" gap="10px">
               
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" justifyContent="space-between">
              <SoftBox width={{ lg: "500px", md: "400px" }}>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="10px"
                  justifyContent="space-between"
                >
                  <SoftInput
                    isClearable
                    // width={{ lg: "1000px", md: "600px" }}
                    // width="1000px"
                    id="search-bar"
                    className="text"
                    // onInput={(e) => {
                    //   setSearchQuery(e.target.value);
                    // }}
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    variant="outlined"
                    placeholder="Search by organisation name, SPOC name or phone number"
                    size="small"
                  />

                  {/*
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "40px",
                      backgroundColor: "#3B5092",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    p="10px 22px"
                    width={{ lg: "97px", sm: "100%", xs: "50%" }}
                    onClick={() => searchFunc()}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: "medium",
                        lineHeight: "20px",
                      }}
                    >
                      Search
                    </SoftTypography>
                  </SoftBox>
                    */}
                </SoftBox>
              </SoftBox>

              {/*
              <SoftBox width={{ lg: "500px", md: "400px" }}>
                <SoftInput
                  isClearable
                  // width={{ lg: "1000px", md: "600px" }}
                  // width="1000px"
                  id="search-bar"
                  className="text"
                  // onInput={(e) => {
                  //   setSearchQuery(e.target.value);
                  // }}
                  // height="40px"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  placeholder="Search by name and contact number"
                  size="small"
                />
              </SoftBox>
              */}

              {/*
              <SoftBox
                width="120px"
                // ml={{ lg: "1070px", md: "600px", xs: "300px" }}
                sx={{
                  // position: "absolute",
                  // marginLeft:"1000px",
                  // marginLeft: "1000px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "32px",
                  // backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  border: "1px solid ",
                  cursor: "pointer",
                }}
                // onclick to deactivate all
              >
                <SoftTypography
                  sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                  onClick={() => (
                    setShow(!show),
                    // setFileName(() => {
                    //   getFileName();
                    // })
                    getFileName()
                  )}
                >
                  Export CSV
                </SoftTypography>
              </SoftBox>
              */}

              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox
                  sx={{
                    height: "33px",
                    backgroundColor: "#3B5092",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="40px"
                  onClick={() => navigate("/searchbyposting/createposting")}
                >
                  <SoftBox sx={{ color: "#FFF" }}>+</SoftBox>
                </SoftBox>

                <Tool key="1" title={tablerow.length === 0 ? "Download All" : "Download"} placement="top">
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#000000",
                      borderRadius: "2px",
                      cursor: "pointer",
                      ml: "8px",
                      mr: "8px",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => (
                      setShow(!show),
                      // setFileName(() => {
                      //   getFileName();
                      // })
                      getFileName()
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="34"
                      viewBox="0 0 46 34"
                      fill="none"
                    >
                      <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                      <path
                        d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.9531 16.5098V23.0102"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </Tool>

                {/*
                <Tool key="1" title="Filter off" placement="top">
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => {
                      setNewName("");
                      setSearchSet(true);
                    }}
                  >
                    <SoftBox>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="39"
                        height="34"
                        viewBox="0 0 39 34"
                        fill="none"
                      >
                        <rect width="38.8889" height="34" rx="2" fill="white" />
                        <path
                          d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                          stroke="#A4A4A4"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </SoftBox>
                  </SoftBox>
                </Tool>
                */}
              </SoftBox>
            </SoftBox>

            <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="100%">
              <Table
                // {...tableProps}
                ///loading={mainTable.length === 0 ? true : false}
                loading={loading}
                rowSelection={rowSelection}
                // defaultExpandAllRows={true}
                columns={colName}
                // onExpand={onTableRowExpand}
                // dataSource={data}
                // dataSource={mainTable}
                dataSource={searchData.filter((ele) => {
                  return newName?.toLocaleLowerCase() === ""
                    ? ele
                    : ele.organization?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
                      newName?.toLocaleLowerCase() === ""
                      ? ele
                      : ele.createdby?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
                        newName?.toLocaleLowerCase() === ""
                        ? ele
                        : String(ele.mobnum?.toLocaleLowerCase()).includes(String(newName.toLocaleLowerCase()));
                })}
                pagination={{
                  // defaultPageSize: ,
                  // position: ["none", "bottomCenter"],
                  pageSizeOptions: ["10", "20", "50", "100", "500"],
                  total: paginationState.dataSize,
                  showSizeChanger: true,
                  // onShowSizeChange: { onShowSizeChange },
                  onShowSizeChange: handlePageSizeChange,
                  current: paginationState.page,
                  onChange: (page, pageSize) =>
                    setPaginationState({ ...paginationState, page: page }),
                }}
                expandable={{
                  expandedRowKeys: expandedRowKeys,
                  // defaultExpandedRowKeys: ["0"],
                  expandedRowRender,
                  onExpand: onTableRowExpand,
                }}
              // onRow={(record, rowIndex) => {
              //   console.log(`record:${rowIndex}`, record);
              //   return expandedRowRender;
              //   // return expandedRowRender;
              //   // onClick: () => {
              //   //   console.log(`record:${rowIndex}`, record);
              //   //   return expandedRowRender;
              //   // };
              // }}
              // searchable
              />
            </SoftBox>
            <EditPosting
              editadmin={editadmin}
              Seteditadmin={Seteditadmin}
              editdata={editdata}
              handleOrgFunc={fetchOrgList}
              handleRoleFunc={fetchOgrRole}
            // fetchadminList={fetchOgrRole}
            />
          </SoftBox>
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
          style={{ margin: "0px" }}
        >
          {/*<Modal.Header closeButton></Modal.Header>*/}
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {colHeaders.map((ele, index) => (
                <>
                  <ul key={index} className="UlCSV">
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={tablerow.length === 0 ? searchData : tablerow}
                    // filename={"my-file.csv"}
                    filename={fileName}
                    headers={colHeaders.filter((ele) => ele.isExportable === true)}
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* modal for export */}

        {/* Modal for cp assign */}
        <Modal
          show={modal2}
          onHide={() => {
            setModal2(false);
          }}
          centered
          style={{ margin: "0px" }}
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Assign Channel Partner</p>

                <button className="CloseBtn" onClick={() => setModal2(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                  gap: "20px",
                }}
              >
                <SoftTypography
                  sx={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "small",
                    lineHeight: "19px",
                  }}
                >
                  Select Channel Partner :
                </SoftTypography>
                <SoftBox display="flex" flexDirection="column" sx={{ width: "230px" }}>
                  <Select
                    isMulti
                    placeholder="Select Channel Partner"
                    options={cpList}
                    styles={stylesDD}
                    onChange={(e) => {
                      if (e) {
                        console.log(e);
                        setCPId(e.map((item) => item));
                      } else {
                        setCPId([]);
                      }
                    }}
                  />
                </SoftBox>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                  gap: "20px",
                }}
              >
                <SoftTypography
                  sx={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "small",
                    lineHeight: "19px",
                  }}
                >
                  Job Role :
                </SoftTypography>
                <SoftBox display="flex" flexDirection="row" alignItems="center">
                  <SoftTypography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "small",
                      lineHeight: "19px",
                      pr: "12px",
                    }}
                  >
                    {cpAssignPayload.jobRole}
                  </SoftTypography>
                </SoftBox>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                  marginBottom: "20px",
                  gap: "20px",
                }}
              >
                <SoftTypography
                  sx={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "small",
                    lineHeight: "19px",
                  }}
                >
                  Requirement :
                </SoftTypography>
                <SoftBox display="flex" flexDirection="row" alignItems="center">
                  <SoftTypography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "small",
                      lineHeight: "19px",
                      pr: "12px",
                    }}
                  >
                    {cpAssignPayload.candidates}
                  </SoftTypography>
                </SoftBox>
              </Box>
              <hr></hr>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginTop: "30px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <SoftTypography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "small",
                      lineHeight: "19px",
                      margin: "0px 10px",
                    }}
                  >
                    {`Don\'t Assign`}
                  </SoftTypography>
                  <label className="switch">
                    <input type="checkbox" defaultChecked={false} onClick={callback} />
                    {/* <SoftInput className="input" type="checkbox" checked /> */}
                    <span className="slider round"></span>
                  </label>
                  {/* <Toggle label="Toggle me" toggled={true} onClick={logState} /> */}
                  <SoftTypography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "small",
                      lineHeight: "19px",
                      margin: "0px 10px",
                    }}
                  >
                    Assign
                  </SoftTypography>
                </Box>
              </Box>

              {toggle && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "30px",
                      gap: "20px",
                    }}
                  >
                    <SoftTypography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "small",
                        lineHeight: "19px",
                      }}
                    >
                      Assign Candidates :
                    </SoftTypography>
                    <SoftBox display="flex" flexDirection="row" alignItems="center">
                      <SoftBox width="210px">
                        <SoftInput
                          type="number"
                          // maxValue={cpAssignPayload.candidates}
                          value={assignCount}
                          // onChange={(e) => setAssignCount(e.target.value)}
                          onChange={handleChange}
                        />
                      </SoftBox>
                    </SoftBox>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginTop: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <SoftTypography
                        sx={{
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "small",
                          lineHeight: "19px",
                          margin: "0px 10px",
                        }}
                      >
                        No Payout
                      </SoftTypography>
                      <label className="switch">
                        <input type="checkbox" defaultChecked={false} onClick={callback1} />
                        {/* <SoftInput className="input" type="checkbox" checked /> */}
                        <span className="slider round"></span>
                      </label>
                      {/* <Toggle label="Toggle me" toggled={true} onClick={logState} /> */}
                      <SoftTypography
                        sx={{
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "small",
                          lineHeight: "19px",
                          margin: "0px 10px",
                        }}
                      >
                        Payout
                      </SoftTypography>
                    </Box>
                  </Box>

                  {toggle1 ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "30px",
                          gap: "20px",
                        }}
                      >
                        <SoftTypography
                          sx={{
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "small",
                            lineHeight: "19px",
                          }}
                        >
                          Cost per Candidate :
                        </SoftTypography>
                        <SoftBox display="flex" flexDirection="row" alignItems="center">
                          <SoftTypography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "small",
                              lineHeight: "19px",
                              pr: "12px",
                            }}
                          >
                            ₹
                          </SoftTypography>

                          <SoftBox width="210px">
                            <SoftInput
                              type="number"
                              value={amt}
                              onChange={(e) => setAmt(e.target.value)}
                            />
                          </SoftBox>
                        </SoftBox>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          // flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "30px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "20px",
                            // marginTop: "30px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                              stroke="#6C6C6C"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 5V9"
                              stroke="#6C6C6C"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 13H9.01"
                              stroke="#6C6C6C"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <SoftTypography
                            sx={{
                              color: "#6C6C6C",
                              fontSize: "15px",
                              fontWeight: "small",
                              lineHeight: "19px",
                            }}
                          >
                            {`maximum payout amount will be for ${assignCount}x${amt} = ${amt * assignCount
                              } ₹`}
                          </SoftTypography>
                        </Box>
                      </Box>
                    </>
                  ) : null}
                </>
              )}

              <Box
                sx={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "40px" }}
              >
                <button
                  disabled={toggle === false}
                  className="login-btn3"
                  onClick={() => {
                    AssignCP();
                  }}
                >
                  <span className="login-btn3-Text">Assign</span>
                </button>
                <button className="login-btn4" onClick={() => setModal2(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* Modal for cp assign */}

        {/* Modal for list of assigned cp */}
        <Modal
          show={modal3}
          onHide={() => {
            setModal3(false);
          }}
          centered
          size="xl"
          style={{ margin: "0px" }}
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Assigned Channel Partner List</p>

                <button className="CloseBtn" onClick={() => setModal3(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Table columns={cpColumns} dataSource={assignedCPList} exportable />
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* Modal for list of assigned cp */}
      </DashboardLayout>
    </>
  );
};

export default JobPosting;
