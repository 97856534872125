import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
// import Map from "./Map/Map";
import Map from "react-map-gl";

const Mapdata = (props) => {
  let { h1, h2, h3, h4, v1, v2, v3, v4 } = props;
  const [lng, setLng] = useState("84.94");
  const [lat, setLat] = useState("26.84");
  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiYW1hbnRlY2g5MCIsImEiOiJjazk0bDZqY3gwYXYwM2xydXN6Nm5rdnV6In0.53O4AygO6OizydqLshDXnw";
  return (
    <>
      <Grid container>
        <SoftBox mt={4} width="100%" height="auto" mb={3}>
          <SoftBox pt={5} pb={3}>
            <SoftBox component="form" role="form">
              <SoftBox
                // display="flex"
                // flexDirection="row"
                // justifyContent="space-between"
                mb={3}
                pl={2}
              >
                <Grid container spacing={6}>
                  {/* <Grid item xs={12} lg={6} container spacing={3}> */}
                  {/* <SoftBox
                      width="100%"
                      //  sx={{border:"2px solid black",borderRadius:"15px"}}
                    // >
                      {/* <SoftTypography fontWeight="medium" fontSize="16px"> */}
                  {/* map */}
                  {/* </SoftTypography> */}
                  {/* <Map
                                data={mapData}
                                location={selectedState}
                                // location="Maharashtra"
                                type={selectedCity && !jsonError ? "city" : "state"}
                                dataLayer={dataLayer2}
                                dataLayer2={dataLayer3}
                                jsonError={jsonError}
                                toolTip={ToolTipRender}
                                onClick={onClick}
                                mapError={mapError}
                              /> */}
                  {/* <Map
                        mapboxAccessToken={MAPBOX_TOKEN}
                        mapStyle="mapbox://styles/mapbox/streets-v11"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "15px",
                          border: "2px solid blue",
                        }}
                        initialViewState={{
                          longitude: lng,
                          latitude: lat,
                          zoom: 5,
                        }}
                      /> */}
                  {/* </SoftBox>  */}
                  {/* </Grid> */}
                  <Grid item xs={12} lg={6} container spacing={0}>
                    <SoftBox width="100%" height="305px" pl={5} mt={2}>
                      <SoftBox display="flex" flexWrap="wrap">
                        <SoftBox
                          width="40%"
                          height="135px"
                          ml={1}
                          mb={3}
                          sx={{
                            border: "5px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <SoftTypography
                            fontWeight="small"
                            lineHeight="18px"
                            fontSize="14px"
                            ml={1}
                            mt={1}
                          >
                            {h1}
                          </SoftTypography>
                          <SoftTypography
                            fontWeight="medium"
                            lineHeight="63px"
                            fontSize={{ lg: "40px", xs: "30px" }}
                            ml={1}
                          >
                            {v1}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          width="40%"
                          height="135px"
                          ml={3}
                          mb={1}
                          sx={{
                            border: "0px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <SoftTypography
                            fontWeight="small"
                            lineHeight="18px"
                            fontSize="14px"
                            ml={1}
                            mt={1}
                          >
                            {h2}
                          </SoftTypography>
                          <SoftTypography
                            fontWeight="medium"
                            lineHeight="63px"
                            fontSize={{ lg: "40px", xs: "30px" }}
                            ml={1}
                          >
                            {v2}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          width="40%"
                          height="135px"
                          ml={1}
                          mb={1}
                          sx={{
                            border: "0px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <SoftTypography
                            fontWeight="small"
                            lineHeight="18px"
                            fontSize="14px"
                            ml={1}
                            mt={1}
                          >
                            {h3}
                          </SoftTypography>
                          <SoftTypography
                            fontWeight="medium"
                            lineHeight="63px"
                            fontSize={{ lg: "40px", xs: "30px" }}
                            ml={1}
                          >
                            {v3}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          width="40%"
                          height="135px"
                          ml={3}
                          mb={1}
                          sx={{
                            border: "0px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <SoftTypography
                            fontWeight="small"
                            lineHeight="18px"
                            fontSize="14px"
                            ml={1}
                            mt={1}
                          >
                            {h4}
                          </SoftTypography>
                          <SoftTypography
                            fontWeight="medium"
                            lineHeight="63px"
                            fontSize={{ lg: "40px", xs: "30px" }}
                            ml={1}
                          >
                            {v4}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} container spacing={0}>
                    <SoftBox width="100%" height="305px" pl={5} mt={2}>
                      <SoftBox display="flex" flexWrap="wrap">
                        <SoftBox
                          width="40%"
                          height="135px"
                          ml={1}
                          mb={3}
                          sx={{
                            border: "5px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <SoftTypography
                            fontWeight="small"
                            lineHeight="18px"
                            fontSize="14px"
                            ml={1}
                            mt={1}
                          >
                            {h1}
                          </SoftTypography>
                          <SoftTypography
                            fontWeight="medium"
                            lineHeight="63px"
                            fontSize={{ lg: "40px", xs: "30px" }}
                            ml={1}
                          >
                            {v1}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          width="40%"
                          height="135px"
                          ml={3}
                          mb={1}
                          sx={{
                            border: "0px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <SoftTypography
                            fontWeight="small"
                            lineHeight="18px"
                            fontSize="14px"
                            ml={1}
                            mt={1}
                          >
                            {h2}
                          </SoftTypography>
                          <SoftTypography
                            fontWeight="medium"
                            lineHeight="63px"
                            fontSize={{ lg: "40px", xs: "30px" }}
                            ml={1}
                          >
                            {v2}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          width="40%"
                          height="135px"
                          ml={1}
                          mb={1}
                          sx={{
                            border: "0px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <SoftTypography
                            fontWeight="small"
                            lineHeight="18px"
                            fontSize="14px"
                            ml={1}
                            mt={1}
                          >
                            {h3}
                          </SoftTypography>
                          <SoftTypography
                            fontWeight="medium"
                            lineHeight="63px"
                            fontSize={{ lg: "40px", xs: "30px" }}
                            ml={1}
                          >
                            {v3}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          width="40%"
                          height="135px"
                          ml={3}
                          mb={1}
                          sx={{
                            border: "0px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          }}
                        >
                          <SoftTypography
                            fontWeight="small"
                            lineHeight="18px"
                            fontSize="14px"
                            ml={1}
                            mt={1}
                          >
                            {h4}
                          </SoftTypography>
                          <SoftTypography
                            fontWeight="medium"
                            lineHeight="63px"
                            fontSize={{ lg: "40px", xs: "30px" }}
                            ml={1}
                          >
                            {v4}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Grid>
    </>
  );
};
Mapdata.propTypes = {
  h1: PropTypes.string.isRequired,
  h2: PropTypes.string.isRequired,
  h3: PropTypes.string.isRequired,
  h4: PropTypes.string.isRequired,
  v1: PropTypes.string.isRequired,
  v2: PropTypes.string.isRequired,
  v3: PropTypes.string.isRequired,
  v4: PropTypes.string.isRequired,
};

export default Mapdata;
