import SoftBadge from "components/SoftBadge";
import { SelectColumnFilter } from "components/Filter/Filter";

// ProductsList page components
import ProductCell from "layouts/ecommerce/products/products-list/components/ProductCell";

// Images
import car from "assets/images/product-icon/two_wheeler_icon.svg";
import health from "assets/images/product-icon/health_icon.svg";
import life from "assets/images/product-icon/term_icon.svg";
import travel from "assets/images/product-icon/travel_icon.svg";

// Badges
const cancelled = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="Cancelled" container />
);
const completed = (
  <SoftBadge variant="contained" color="success" size="xs" badgeContent="Completed" container />
);

const pending = (
  <SoftBadge variant="contained" color="warning" size="xs" badgeContent="Pending" container />
);

const renderDash = ({ value }) => (value === null || value === undefined ? "-" : value);

// custom filter func to display all rows, if "All"(value = "") is selected
// does not support Multi Select feature
const customFilterFn = (rows, columnIds, filterValue) => {
  return filterValue === ""
    ? rows
    : rows.filter((row) => filterValue.includes(String(row.original[columnIds])));
};

/* eslint-disable react/prop-types */

const dataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "product",
      width: "15%",
      align: "left",
      disableFilters: true,
      Cell: ({ value: [name, data] }) => (
        <ProductCell
         // image={data.image}
          name={name}
          checked={data.checked}
         // imageHeight="24px"
         // imageWidth="36px"
        />
      ),
    },
    { Header: "Location", 
    accessor: "category", 
    Filter: SelectColumnFilter, 
    disableFilters: true, 
    width: "10%" 
  },
    {
      Header: "Organization Type",
      accessor: "company",
      Cell: renderDash,
      Filter: SelectColumnFilter,
      disableFilters: true,
      width: "10%",
    },
    { Header: "Last Posted", accessor: "price", disableFilters: true, Cell: renderDash, width: "15%" },
    {
      Header: "Email",
      accessor: "policy no.",
      disableFilters: true,
      Cell: renderDash,
      width: "15%",
    },
    {
      Header: "Phone Number",
      accessor: "premium",
      width: "15%",
      disableFilters: true,
      Cell: renderDash,
    },
    {
      Header: "Job Status",
      accessor: "status",
      Cell: ({ value }) =>
        value === "Pending" ? pending : value === "Completed" ? completed : cancelled,
      Filter: SelectColumnFilter,
      disableFilters: true,
      width: "10%",
    },
    {
      Header: "Last Active",
      accessor: "paymentStatus",
      Filter: SelectColumnFilter,
      disableFilters: true,
      width: "30%",
    },
  ],

  rows: [
    {
      checked: ["checked", {  checked: true }],
      product: ["Health Insurance", {  checked: true }],
      category: "Individual",
      price: "$1,321",
      sku: 243598234,
      quantity: 0,
      status: "Completed",
      paymentStatus: "Done",
      company: "ACKO",
    },
    {
      product: ["Car Insurance", { checked: true }],
      category: "GCV",
      price: "$1,869",
      sku: 877712,
      quantity: 0,
      status: "Cancelled",
      paymentStatus: "Rejected",
      company: "Reliance",
    },
    {
      product: ["Travel Insurance", {  checked: false }],
      category: "Individual",
      price: "$99",
      sku: "0134729",
      quantity: 978,
      status: "Pending",
      paymentStatus: "Pending",
      company: "Edelweiss",
    },
    {
      product: ["Car Insurance", {  checked: false }],
      category: "Four Wheeler",
      price: "$2,999",
      sku: 113213,
      quantity: 0,
      status: "Cancelled",
      paymentStatus: "Rejected",
    },
    {
      product: ["Life Insurance", {  checked: false }],
      category: "Term",
      price: "$869",
      sku: 634729,
      quantity: 725,
      status: "Completed",
      paymentStatus: "Done",
    },
    {
      product: ["Car Insurance", { checked: false }],
      category: "Two Wheeler",
      price: "$1,869",
      sku: 634729,
      quantity: 725,
      status: "Pending",
      paymentStatus: "Pending",
    },
    {
      product: ["Health Insurance", { checked: true }],
      category: "Family",
      price: "$869",
      sku: 634729,
      quantity: 725,
      status: "Completed",
      paymentStatus: "Done",
    },
    {
      product: ["Car Insurance", {  checked: true }],
      category: "Two Wheeler",
      price: "$1,199",
      sku: "00121399",
      quantity: 51293,
      status: "Completed",
      paymentStatus: "Done",
    },
    {
      product: ["Car Insurance", {  checked: true }],
      category: "Four Wheeler",
      price: "$1,900",
      sku: 434729,
      quantity: 34,
      status: "Pending",
      paymentStatus: "Pending",
    },
    {
      product: ["Travel Insurance", {  checked: false }],
      category: "Family",
      price: "$149",
      sku: 928341,
      quantity: 0,
      status: "Cancelled",
      paymentStatus: "Rejected",
    },
    {
      product: ["Health Insurance", {  checked: true }],
      category: "Family",
      price: "$2,099",
      sku: 9912834,
      quantity: 32,
      status: "Pending",
      paymentStatus: "Pending",
    },
    {
      product: ["Life Insurance", {  checked: false }],
      category: "Term",
      price: "$2,999",
      sku: 561151,
      quantity: 22,
      status: "Completed",
      paymentStatus: "Done",
    },
    {
      product: ["Health Insurance", {  checked: true }],
      category: "Family",
      price: "$869",
      sku: 634729,
      quantity: 725,
      status: "Pending",
      paymentStatus: "Pending",
    },
    {
      product: ["Car Insurance", {  checked: false }],
      category: "PCV",
      price: "$999",
      sku: "01827391",
      quantity: 0,
      status: "Cancelled",
      paymentStatus: "Rejected",
    },
    {
      product: ["Health Insurance", { checked: true }],
      category: "Family",
      price: "$869",
      sku: 63472,
      quantity: 725,
      status: "Pending",
      paymentStatus: "Pending",
    },
  ],
};

export default dataTableData;
