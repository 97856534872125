import React from "react";
// import MainPage from "./Components/MainPage.js";
import SideDrawer from "./SideDrawer";
import Backdrop from "./Backdrop.js";
import PropTypes from "prop-types";

const Index = (props) => {
  const {
    open,
    setOpen,
    statefilter,
    setStatefilter,
    districtfilter,
    setDistrictfilter,
    datefilter,
    setDatefilter,
    resetfunc,
    jffilter,
    setJffilter,
    table,
    educationfilter,
    setEducationfilter,
    rolefilter,
    setRolefilter,
    degreefilter,
    setDegreefilter,
    boardingfilter,
    setBoardingfilter
  } = props;
  const backdropClickHandler = () => {
    setOpen(false);
  };
  let backdrop;
  if (open) {
    backdrop = <Backdrop close={backdropClickHandler} />;
  }
  return (
    <div>
      <SideDrawer
        show={open}
        setOpen={setOpen}
        statefilter={statefilter}
        setStatefilter={setStatefilter}
        districtfilter={districtfilter}
        setDistrictfilter={setDistrictfilter}
        datefilter={datefilter}
        setDatefilter={setDatefilter}
        resetfunc={resetfunc}
        jffilter={jffilter}
        setJffilter={setJffilter}
        table={table}
        educationfilter={educationfilter}
        setEducationfilter={setEducationfilter}
        rolefilter={rolefilter}
        setRolefilter={setRolefilter}
        degreefilter={degreefilter}
        setDegreefilter={setDegreefilter}
        boardingfilter={boardingfilter}
        setBoardingfilter={setBoardingfilter}
      />
      {backdrop}
    </div>
  );
};
Index.propTypes = {
  open: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  statefilter: PropTypes.string,
  setStatefilter: PropTypes.string,
  districtfilter: PropTypes.string,
  setDistrictfilter: PropTypes.string,
  datefilter: PropTypes.string,
  setDatefilter: PropTypes.string,
  resetfunc: PropTypes.func,
  jffilter: PropTypes.string,
  setJffilter: PropTypes.string,
  table: PropTypes.string,
  educationfilter: PropTypes.string,
  setEducationfilter: PropTypes.string,
  rolefilter: PropTypes.string,
  setRolefilter: PropTypes.string,
  degreefilter: PropTypes.string,
  setDegreefilter: PropTypes.string,
  boardingfilter: PropTypes.string,
  setBoardingfilter: PropTypes.string,
};
export default Index;
