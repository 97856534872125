import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";
import centertable from "./centertable";
import SoftSelect from "../../components/SoftSelect";
import { Link, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import React, { useState, useEffect } from "react";

import download from "assets/icons/download_icon.png";
import Mapdata from "pages/Mapdata/Mapdata";

import { Table } from "ant-table-extensions";
// importing data for table temporirly from superadmin table.js
import { data } from "../Suparadmin/suparadmintable";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import { MdMoreVert } from "react-icons/md";
import AdminEdit from "pages/EditModals/Centers/centerEdit";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import "../style.css";

const Center = () => {
  const navigate = useNavigate();
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [admindata, Setadmindata] = useState([]);
  const [editadmin, Seteditadmin] = useState(false);
  const [editdata, Seteditdata] = useState({});
  const colName = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 1,
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: {
        compare: (a, b) => a.address - b.address,
        multiple: 2,
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: {
        compare: (a, b) => a.location - b.location,
        multiple: 3,
      },
    },
    {
      title: "Create By",
      dataIndex: "created_by",
      sorter: {
        compare: (a, b) => a.created_by - b.created_by,
        multiple: 4,
      },
    },
    {
      title: "Center Head",
      dataIndex: "center_head",
      sorter: {
        compare: (a, b) => a.center_head - b.center_head,
        multiple: 5,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      sorter: {
        compare: (a, b) => a.phone_number - b.phone_number,
        multiple: 6,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 7,
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      width: "5%",
      render: (admindata) => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </Button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="mdi:lead-pencil" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#EditUser"
                    onClick={() => {
                      Seteditadmin(true);
                      Seteditdata(admindata);
                    }}
                  >
                    Edit Profile
                  </a>
                </div>
              </li>
              {/* <li>
                <div style={{display:"flex", flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                  <div style={{marginLeft:"10px"}}><Icon icon="mdi:password-reset" /></div>
                  <a
                    className="dropdown-item"
                    href="#EditUser"
                    onClick={() =>{ setResetPassword(true); Seteditdata(admindata)}}
                  >
                    Reset  Password
                  </a>
                </div>
                
              </li> */}
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="fluent-mdl2:deactivate-orders" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#Deleteuser"
                    // onClick={() => {
                    //   if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
                    //     DeleteClientUser(e.id)
                    //   } else {
                    //     toast.error("Admin Cannot Delete")
                    //   }

                    // }}
                    onClick={() => {
                      deActiveFun(admindata);
                    }}
                  >
                    Deactivate
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   fixed: 'right',
    //   width: 50,
    //   render: () => <MdMoreVert/>,
    // },
  ];
  const [colHeaders, setColHeaders] = useState([
    {
      label: "Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "Location",
      key: "location",
      isExportable: true,
    },
    {
      label: "Address",
      key: "address",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "phone_number",
      isExportable: true,
    },
    {
      label: "Created By",
      key: "created_by",
      isExportable: true,
    },
    {
      label: "Center Head",
      key: "center_head",
      isExportable: true,
    },
  ]);
  // const data =admindata?.map((item ,id)=>{return {
  //   key: item.registration_id,
  //   // organization: item.org_name ,
  //   // location: item.org_address,
  //   name: `${item.firstnm+" "+item.lastnm}`,
  //   firstnm: item.firstnm,
  //   lastnm: item.lastnm,
  //   sequrity_ques: item.sequrity_ques,
  //   sequrity_ans: item.sequrity_ans,
  //   email: item.email,
  //   mobnum: item.mobnum,
  //   date_of_creation: moment(item.createddt).utc().format('DD-MM-YYYY') ,
  // }});

  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectCol, setSelectCol] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [val, setVal] = useState("");
  const [searchData, setSearchData] = useState(data);
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [show, setShow] = useState(false);
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(data, newSelectedRowKeys);
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", selectedCSVData);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };

  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        deactivate_role: roleId,
        "partner-association": "samarth",
        deactivatedby: registerID,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "",
          text: res.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: e.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);

  const fetchadminList = () => {
    axios
      .get(`${process.env.REACT_APP_DASHBOARD_URL}/superadmin/centerlist`)
      .then((res) => {
        console.log(res.data["SuperAdmin_List"]);
        Setadmindata(res.data["SuperAdmin_List"]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchadminList();
    fetchStateList();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
                Job Center
              </SoftTypography>
              {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                Brief summary  Channel Partner
              </SoftTypography> */}
            </SoftBox>
          </SoftBox>
          <Mapdata
            h1="Total Channel Partner Add"
            h2="Total Job Center Created"
            h3="Total JobSeekers Add"
            h4="Total Provider Registered"
            v1="50"
            v2="20"
            v3="4"
            v4="20"
          />
          <SoftBox display="flex" alignItems="flex-start" mb="29px" width="100%" flexWrap="wrap">
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  width="350px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                  }}
                >
                  <SoftSelect
                    placeholder="State"
                    options={state}
                    // value={search}
                    onChange={(currentTarget) => {
                      setSelectedState(currentTarget.value);
                    }}
                  />
                  {/* <Select  placeholder="State" /> */}
                </SoftBox>
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  width="350px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                  }}
                >
                  <SoftSelect
                    placeholder="District"
                    options={district}
                    onChange={(currentTarget) => {
                      setSelectedDistrict(currentTarget.value);
                    }}
                  />
                </SoftBox>
              </Grid>
              {/* <SoftBox width={{ lg: "24%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{
                border: "0px",
                backgroundColor: "#fff",
                marginRight: "13px",
                borderRadius: "5px",
              }}>
              <SoftSelect
                placeholder="Taluka"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              />
            </SoftBox> */}
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-center"
                  justifyContent="center"
                  gap="24px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  width="350px"
                  mb={1}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  padding={{ lg: "10px 5%", sm: "12px 80px", xs: "10px 70px" }}
                  onClick={() => navigate("/job-center-register")}
                >
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="0"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    +
                  </SoftTypography>
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="1"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Centers
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="100%">
            {/* <DataTable            
                table={centertable}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
              /> */}
            {selectedRowKeys.length > 1 && (
              // <Button variant="contained" color="success" >
              //   Deactivate
              // </Button>
              <SoftBox
                width="120px"
                sx={{
                  position: "absolute",
                  marginLeft: "850px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "32px",
                  // backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  border: "1px solid red",
                  cursor: "pointer",
                }}
                // onclick to deactivate all
              >
                <SoftTypography
                  sx={{ color: "red", fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                >
                  Deactivate All
                </SoftTypography>
              </SoftBox>
            )}
            <SoftBox
              width="120px"
              sx={{
                position: "absolute",
                // marginLeft:"1000px",
                marginLeft: "1000px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "32px",
                // backgroundColor: "#3B5092",
                borderRadius: "5px",
                border: "1px solid ",
                cursor: "pointer",
              }}
              // onclick to deactivate all
            >
              <SoftTypography
                sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                onClick={() => (
                  setShow(!show),
                  // setFileName(() => {
                  //   getFileName();
                  // })
                  getFileName()
                )}
              >
                Export CSV
              </SoftTypography>
              {/* <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink> */}
            </SoftBox>
            <Table
              {...tableProps}
              //  pagination={{position:["bottomCenter"]}}
              rowSelection={rowSelection}
              columns={colName}
              dataSource={data}
              // exportable
              // exportableProps={{ showColumnPicker: true }}
              searchable
            />
            <AdminEdit editadmin={editadmin} Seteditadmin={Seteditadmin} editdata={editdata} />
          </SoftBox>

          {/* </Card> */}
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <SoftBox>
              {colHeaders.map((ele, index) => (
                <>
                  <ul key={index}>
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <label> {ele.label}</label>
                  </ul>
                </>
              ))}
            </SoftBox>
          </Modal.Body>
          <Modal.Footer>
            <SoftBox
              pl={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "45px",
                backgroundColor: "#fff",
                border: "1px solid",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
            >
              {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
              <CSVLink
                data={selectedCSVData}
                filename={fileName}
                headers={colHeaders.filter((ele) => ele.isExportable === true)}
              >
                export
              </CSVLink>
            </SoftBox>
          </Modal.Footer>
        </Modal>
        {/* modal for export */}
      </DashboardLayout>
    </>
  );
};

export default Center;
