import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";

// Custom Components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import img1 from "../../assets/images/Samarth - Logo 1.svg";
import image1 from "../../assets/images/jobfairsvgs/jobfairBG.png";
// Utilities
import { AGENT } from "utils/constant";
import { ADMIN } from "utils/constant";
import { spacing } from "@mui/system";
import Swal from "sweetalert2";

// Images
// import image from "../../assets/images/landing_img.png";
import image from "assets/sba_images/png/home_screen.png";
import logo from "../../assets/sba_images/png/SamarthaLogo.png";
import pageRoutes from "page.routes";
import Card from "@mui/material/Card";
import axios from "axios";
import { outlinedInputClasses } from "@mui/material";
import link from "assets/theme/components/link";
import Button from "@girishsawant999/react-loading-button";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import session from "redux-persist/lib/storage/session";
import { withTranslate, IntlActions, useTranslate } from "react-redux-multilingual";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "#B3B6B7",
            borderRadius: "8px",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0dcaf0",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0dcaf0",
            },
          },
        },
      },
    },
  });

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: "white",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  fontSize: "12px",
}));

const JobFairAdminLogin = () => {
  const translate = useTranslate();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const outerTheme = useTheme();
  const matchsm = useMediaQuery("(max-width:768.5px)");
  const transparent = false;
  const light = false;
  const [userRole, setUserRole] = useState(AGENT);
  const [OTP, setOTP] = useState({
    mobileno: "",
    otp: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [updates, setUpdates] = useState(false);

  const [counter, setCounter] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const [loginEmail, setLoginEmail] = useState({
    email: "",
    password: "",
  });

  const [otpbutton, Setotpbuton] = useState(false);
  const [otpinput, Setotpinput] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const sendOtp = () => {
    // Setotpinput(true);
    Setotpbuton(true);

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL_AUTH}/login/sendotp`, {
        mobnum: OTP.mobileno,
        partner_association: "Samarth",
      })
      .then((res) => {
        Setotpinput(true);
        Setotpbuton(true);
        Swal.fire({
          //position: "top",
          icon: "sucess",
          title: `${res.response.data["User Message"]}`,
          // text: res.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch((err) => {
        Setotpbuton(false);
        // Setotpinput(false);
        Swal.fire({
          //position: "top",
          icon: "warning",
          // title: `${err.response.data["User Message"]}`,
          title: "Oops...",
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const changeUser = (e, type) => {
    // double clicking on same option deselects and sets type as null
    if (type !== null) {
      setUserRole(type);
    }
  };

  const handleLogin = async () => {
    dispatch(IntlActions.setLocale("en"));
    sessionStorage.setItem("lang", "en");
    setIsLoading(true);
    if (userRole === "admin") {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL_AUTH}/login/phone`, {
          mobile_number: OTP.mobileno,
          otp: OTP.otp,
          partner_association: "Samarth",
        })
        .then((res) => {
          console.log(res.data, "res1");
          if (res.status === 200) {
            // console.log("user name:", CapitalizeFunc(res.data.Name));
            sessionStorage.setItem("register_id", res.data["Registration ID"]);
            sessionStorage.setItem("token", res.data.Token.refresh);
            sessionStorage.setItem("user_name", res.data.Name);
            // navigate("/superadmin/dashboard");
            // navigate("/admin-dashboard");
            navigate("/admin-jobfair-dashboard");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          Swal.fire({
            //position: "top",
            icon: "warning",
            title: `${err.response.data["Developer Message"]}`,
            showConfirmButton: false,
            timer: 3000,
          });
          setIsLoading(false);
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/auth/login/email`, {
          email: loginEmail.email,
          password: loginEmail.password,
          partner_association: "Samarth",
        })
        .then((res) => {
          console.log(res.data, "res2");
          sessionStorage.setItem("email", loginEmail.email);
          // const arr = res.data?.Name.split(" ");
          // for (let i = 0; i < arr.length; i++) {
          //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].splice(1);
          // }
          // const newStr = arr.join(" ");
          if (res.status === 200) {
            // console.log("user name:", newStr);
            sessionStorage.setItem("register_id", res.data["Registration ID"]);
            sessionStorage.setItem("creator_role", res.data["Creator_Role"]);
            sessionStorage.setItem("token", res.data["Token"].access);
            sessionStorage.setItem("user_name", res.data?.Name);
            sessionStorage.setItem("state", res.data.state);
            sessionStorage.setItem("district", res.data.district);
            sessionStorage.setItem("admin_type", res.data.admin_role);
            // navigate("/superadmin/dashboard");
            // navigate("/admin-dashboard");
            // navigate("/admin-dashboard");
            navigate("/admin-jobfair-dashboard");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          Swal.fire({
            //position: "top",
            icon: "warning",
            title: `${
              err.response?.message
                ? err.response?.message
                : err.response?.data["Developer Message"]
            }`,
            showConfirmButton: false,
            timer: 3000,
          });
          setIsLoading(false);
        });
    }
  };

  /*
  const handleKeyPress = async (e) => {
    setIsLoading(true);
    if (e.key === "Enter") {
      console.log("you have clicked enter button");
    }
  };
  */

  //  useEffect(()=>{
  //   sessionStorage.getItem('lang')==='' ||  sessionStorage.getItem('lang')===undefined  ?
  //    sessionStorage.setItem('lang','en'):'';

  //                                  dispatch(IntlActions.setLocale('en'))

  //  },[])

  useEffect(() => {
    if (otpbutton) {
      setTimeout(() => {
        Setotpbuton(false);
        Setotpinput(false);
      }, 59000);
    }
  }, [otpbutton]);

  useEffect(() => {
    if (otpinput) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, otpinput]);
  const CapitalizeFunc = (str) => {
    const arr = str.split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].splice(1);
    }
    const newStr = arr.join(" ");
    return newStr;
  };
  return (
    // <BasicLayout title="Welcome!" description="Select user type" image={curved6}>
    <>
      <PageLayout>
        <DefaultNavbar
          routes={pageRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
          light
        />
        <SoftBox
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image1 &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.1),
                rgba(gradients.dark.state, 0.1)
              )}, url(${image1})`,
            // {rgba(59,80,146,0.85),url({image}),
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          // bgColor="#CBE3FF"
        >
          <SoftBox
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: !matchsm ? "row" : "column",
              // gap: !matchsm ? "none" : "20px",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {/*{!matchsm ? (
              <SoftBox
                sx={{
                  display: "flex",
                  gap: "20px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SoftBox>
                  <SoftTypography
                    color="#023090"
                    fontFamily="PT Serif Caption"
                    fontSize="26px"
                    fontWeight="400"
                    lineHeight="26px"
                  >
                    Empowering India through Employment.
                  </SoftTypography>
                </SoftBox>
                <SoftBox>
                  <img src={image1} height="480px" />
                </SoftBox>
              </SoftBox>
            ) : (
              <SoftBox
                sx={{
                  display: "flex",
                  gap: "20px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SoftBox>
                  <img src={img1} height={60} />
                </SoftBox>
              </SoftBox>
            )} */}
            <Card
              sx={{
                width: !matchsm ? "400px" : "90%",
                height: "470px",
                mt: { lg: "70px" },
              }}
            >
              <SoftBox
                px={{ xs: transparent ? 2 : 3, sm: transparent ? 1 : 2, lg: "10px" }}
                pt="46px"
                pb="59px"
                height="550px"
                borderRadius="sm"
                shadow={transparent ? "none" : "md"}
                color={light ? "white" : "dark"}
                display="flex"
                flexDirection="column"
                gap="1em"
                // justifyContent="center"
                alignItems="center"
                zIndex={3}
                sx={({
                  palette: { transparent: transparentColor, white },
                  functions: { rgba },
                }) => ({
                  backgroundColor: transparent ? "primary" : "#ffff",
                  backdropFilter: transparent ? "none" : `blur(4px)`,
                })}
              >
                <SoftBox>
                  {/* <SoftTypography variant="h10" color="black" fontWeight="500" fontSize="24px">
                    Login
                  </SoftTypography> */}
                  <SoftBox>
                    <img src={img1} height={60} />
                  </SoftBox>
                </SoftBox>
                {/* <SoftBox></SoftBox> */}
                {/*
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="223"
                height="112"
                viewBox="0 0 223 112"
                fill="none"
              >
                <rect width="223" height="112" fill="url(#pattern0)" />
                <defs>
                  <pattern
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                  >
                    <use
                      xlinkHref="#image0_730_2852"
                      transform="matrix(0.0031746 0 0 0.00622227 -0.301587 -0.609783)"
                    />
                  </pattern>
                  <image
                    id="image0_730_2852"
                    width="503"
                    height="356"
                    
                  />
                </defs>
              </svg>
            */}
                {/*
              <StyledToggleButtonGroup
                color="primary"
                value={userRole}
                exclusive
                onChange={changeUser}
                aria-label="Platform"
              >
                <StyledToggleButton value={AGENT}>Login With Email</StyledToggleButton>
                <StyledToggleButton value={ADMIN}>Login With Phone</StyledToggleButton>
              </StyledToggleButtonGroup>
              */}
                <SoftBox
                  borderRadius="sm"
                  sx={{
                    width: !matchsm ? "100%" : "90%",
                    background: "white",
                  }}
                >
                  {userRole !== ADMIN ? (
                    <>
                      <SoftBox pt={2} px={1}>
                        {/* <SoftBox 
                         sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap:'10px',
                      
                          // disabled: `${otpbutton}`,
                        }}    >
                        <button
                            width="100%"
                            className="login-btnNew1"
                            onClick={() => {
                              dispatch(IntlActions.setLocale('hi'))
                              sessionStorage.setItem('lang','hi');
                                                            setUpdates(true)


                              
                            }}                            // onKeyPress={(e) => handleKeyPress(e)}
                          >
                            <span className="btn-txt1">Hindi</span>
                          </button>
                          <button
                            width="100%"
                            className="login-btnNew1"
                            onClick={() => {
                              dispatch(IntlActions.setLocale('en'))
                              sessionStorage.setItem('lang','en');
                              setUpdates(true)

                            }}              
                          >
                            <span className="btn-txt1">English</span>
                          </button>
                        </SoftBox> */}

                        <SoftBox mb={2}>
                          <SoftTypography
                            variant="h10"
                            color="black"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            {translate("email")}
                          </SoftTypography>
                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                              id="outlined-size-small"
                              size="small"
                              fullWidth
                              type="email"
                              placeholder="Email"
                              value={loginEmail.email}
                              onChange={(e) => {
                                setLoginEmail({ ...loginEmail, email: e.target.value });
                              }}
                              onKeyPress={(e) => handleKeyPress(e)}
                            />
                          </ThemeProvider>
                        </SoftBox>
                        <SoftBox mb={4}>
                          <SoftTypography
                            variant="h10"
                            color="black"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            {translate("password")}
                          </SoftTypography>

                          <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField
                              id="outlined-size-small"
                              size="small"
                              fullWidth
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" sx={{ ml: "auto" }}>
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowPassword((show) => !show)}
                                      onMouseDown={handleChange}
                                      edge="end"
                                      sx={{ color: "#B3B6B7", ml: "auto" }}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Password"
                              value={loginEmail.password}
                              onChange={(e) => {
                                setLoginEmail({ ...loginEmail, password: e.target.value });
                              }}
                              onKeyPress={(e) => handleKeyPress(e)}
                            />
                          </ThemeProvider>
                        </SoftBox>
                      </SoftBox>
                    </>
                  ) : (
                    <>
                      <SoftBox pt={2} px={1}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            variant="h10"
                            color="black"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            Mobile Number
                          </SoftTypography>
                          <SoftBox display="flex">
                            <SoftInput
                              type="text"
                              placeholder="Mobile Number"
                              sx={{ mr: 1 }}
                              onChange={(e) => {
                                setOTP({ ...OTP, mobileno: e.target.value });
                              }}
                            />
                            {!otpbutton && (
                              <SoftBox
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#3B5092",
                                  color: "#FFF",
                                  minWidth: "94px",
                                  fontSize: "14px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  // disabled: `${otpbutton}`,
                                }}
                                onClick={(e) => sendOtp()}
                              >
                                Get OTP
                              </SoftBox>
                            )}
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      {otpinput && (
                        <SoftBox mb={4}>
                          <SoftTypography
                            variant="h10"
                            color="black"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            OTP Expiry in {counter} Second
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            placeholder="OTP"
                            onChange={(e) => {
                              setOTP({ ...OTP, otp: e.target.value });
                            }}
                          />
                          {/* <OtpInput                           
                          value={OTP}
                          onChange={setOTP}
                          separator={<span></span>}
                          numInputs={6}                         
                        /> */}
                        </SoftBox>
                      )}
                    </>
                  )}
                  <SoftBox align="right">
                    <Link to="/forgotpassword1">
                      <SoftTypography color="blue" fontWeight="350" fontSize="14px">
                        {translate("forgotpass")}?
                      </SoftTypography>
                    </Link>
                  </SoftBox>
                  <SoftBox px={2} width="100%" display="flex" justifyContent="center" mt={2} mb={1}>
                    {/* <SoftBox
                      mt={4}
                      mb={1}
                      align="center"
                      width="100%"
                      bgColor="#60A7FF"
                      borderRadius="10px"
                      onClick={handleLogin}
                      cursor='pointer'

                    > */}
                    {/* <Link to="/agent/business"> */}
                    {!isLoading ? (
                      <>
                        <button
                          width="100%"
                          className="login-btnNew"
                          onClick={handleLogin}
                          // onKeyPress={(e) => handleKeyPress(e)}
                        >
                          <span className="btn-txt">{translate("login")}</span>
                        </button>
                      </>
                    ) : (
                      // <button
                      //   className="btn btn-primary btn-sm mb-2"
                      //   width="100px"
                      //   type="button"
                      //   disabled
                      // >
                      //   <span
                      //     className="spinner-border spinner-border-sm"
                      //     role="status"
                      //     aria-hidden="true"
                      //     style={{ padding: "auto" }}
                      //   ></span>
                      //   <span style={{ padding: "auto 5px" }}>Loading...</span>
                      // </button>
                      <Button loading={isLoading} className="login-btn btn-txt">
                        Loading
                      </Button>
                    )}

                    {/* </SoftBox> */}
                  </SoftBox>
                </SoftBox>
              </SoftBox>{" "}
            </Card>
          </SoftBox>
        </SoftBox>
      </PageLayout>
    </>
  );
};

export default JobFairAdminLogin;
