import { Card, Stack, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";
import SoftSelect from "../../components/SoftSelect";
import channelpartnertable from "./channelpartnertable";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
// import { Icon } from '@iconify/react';

import download from "assets/icons/download_icon.png";
import Mapdata from "pages/Mapdata/Mapdata";
// import { Table } from "ant-table-extensions";
import { Table, Dropdown } from "antd";
import FadeLoader from "react-spinners/FadeLoader";
// importing data for table temporirly from superadmin table.js
import { data } from "../Suparadmin/suparadmintable";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import { MdMoreVert } from "react-icons/md";
import AdminEdit from "pages/EditModals/ChannelPartner/channelPartnerEdit";
import PasswordResetChannelPartner from "pages/EditModals/ChannelPartner/channelPartnerPasswordReset";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import moment from "moment/moment";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import "../style.css";
import SoftBadge from "components/SoftBadge";
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual';
import { useDispatch } from 'react-redux';

const Channelpartner = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [resumeModal, setResumeModal] = useState(false);
  const [resumefile, setResumeFile] = useState("");
  const [orgData, setOrgData] = useState({})
  const [applyModal, setApplyModal] = useState(false);
  const [actionModal1, setactionModal1] = useState(false);
  const [admindata, Setadmindata] = useState([]);
  const [editadmin, Seteditadmin] = useState(false);
  const [rowData, setRowData] = useState({});
  const [resetPassword, setResetPassword] = useState(false);
  const [cosList, setConsList] = useState([]);
  const [cosId, setCosId] = useState({});
  const [cpId, setCPId] = useState("");
  const [editdata, Seteditdata] = useState({});
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [paginationStateJob, setPaginationStateJob] = useState({
    page: 1,
    dataSize: "",
  });
  const [paginationStateCand, setPaginationStateCand] = useState({
    page: 1,
    dataSize: "",
  });
  const [pageSize, setPageSize] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [canShareModal, setCanShareModal] = useState(false);
  const [channelPartners, setChannelPartners] = useState([]);
  const [candidateShared, setCandidateShared] = useState([]);
  const [jobdesignation, setJobdesignation] = useState([]);
  const [tablerow, setTablerow] = useState([]);
  const [candid, setCandid] = useState([]);
  const [orgId, setOrgId] = useState("")
  const [partnerId, setPartnerId] = useState("");

  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };

  const items = [
    {
      key: '1',
      label: (
        <a
          style={{ display: "flex" }}
          className="dropdown-item"
          href="#EditUser"
        >
          <div>
            <Icon icon="mdi:lead-pencil" />
          </div>
          &nbsp; Edit Profile
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          className="dropdown-item"
          href="#EditUser"
          style={{ display: "flex" }}
        >
          <div>
            <Icon icon="mdi:password-reset" />
          </div>
          &nbsp; Reset Password
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          className="dropdown-item"
          style={{ display: "flex" }}
        >
          <div>
            <Icon icon="mdi:lead-pencil" />
          </div>
          &nbsp;
          Edit Document
        </a>
      ),
    },
  ];
  useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);

  const handleConsPostData = () => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/assign`, {
        orgid: orgData?.orgid ? orgData.orgid : "",
        jobid: [orgData?.jobid ? orgData.jobid : ""],
        candid: candid?.length !== 0 ? candid : rowData.key?.split(" "),
        counid: cosId.value,
        assignee_id: sessionStorage.getItem("register_id"),
      })
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data["User Message"],
          showConfirmButton: false,
          //position: "top",
          timer: 3000,
        })
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      setIsLoading(false);
      }); 
  };



  const sendMessageFunc = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/whatsappverification`,
        {
          broadcast_job: [orgData?.jobid ? orgData.jobid : ""],
          broadcast_cand: rowData.key?.split(" "),
          broadcast_mobnum: [rowData?.phone],
          cand_name: [rowData?.candidate],
          job_designation: [orgData?.job_title ? orgData.job_title : ""],
          broadcastedby: [orgData?.orgid ? orgData.orgid : ""],
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          // text: res.data["User Message"],
          text: "WhatsApp Message Queued",
          showConfirmButton: false,
          //position: "top",
          timer: 3000,
        })
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const ResumeUploadFunc = async (data) => {
    console.log("resume row:", data);
    let docData = new FormData();
    docData.append("jsid", data.key);
    docData.append("type", "resume");
    docData.append("resume", resumefile);
    // docData.append("updatedby", registerID);
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/register`,
        docData
      )
      .then((res) => {
        console.log("upload", res);
        Swal.fire({
          icon: "success",
          title: "",
          text: res.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        }).then(() => (setResumeModal(false)));
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const colName = [
    {
      title: "Registration ID",
      dataIndex: "key",
      width: 200,
      fixed: "left",
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
        multiple: 1,
      },
    },
    {
      title: "Organization",
      dataIndex: "organization",
      fixed: "left",
      width: 200,
      sorter: {
        compare: (a, b) => a?.organization?.localeCompare(b?.organization),
        multiple: 1,
      },
      render: (text, record) => {
        return (
          <p
            onClick={() => {
              setPartnerId(record.key);
              getChannelPartners(record.key);
              console.log("texttt", record);
              setOpenModal(true);
            }}
            style={{
              cursor: "pointer",
              marginBottom: "-2px",
              color: "#3B5092",
              textDecorationLine: "underline",
            }}
          >
            {text}
          </p>
        );
      },
    },
    // {
    //   title: "Channel Lead",
    //   dataIndex: "channel_lead",
    //   sorter: {
    //     compare: (a, b) => a.channel_lead - b.channel_lead,
    //     multiple: 3,
    //   },
    // },
    {
      title: "SPOC Name",
      dataIndex: "name",
      width: 200,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
        multiple: 2,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      width: 200,
      sorter: {
        compare: (a, b) => a.mobile - b.mobile,
        multiple: 3,
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      width: 150,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 7,
      },
    },
    {
      title: "State",
      //dataIndex: "state",
      width: 200,
      sorter: {
        compare: (a, b) => a?.state?.localeCompare(b?.state),
        multiple: 4,
      },
      render: (text) => (
        <>{text.state === "" || text.state === "nan" || text.state === null ? "-" : text.state}</>
      )
    },
    {
      title: "District",
      //dataIndex: "district",
      width: 150,
      sorter: {
        compare: (a, b) => a?.district?.localeCompare(b?.district),
        multiple: 5,
      },
      render: (text) => (
        <>{text.district === "" || text.district === "nan" || text.district === null ? "-" : text.district}</>
      )
    },
    {
      title: "Address",
      //dataIndex: "address",
      width: 200,
      sorter: {
        compare: (a, b) => a?.address?.localeCompare(b?.address),
        multiple: 6,
      },
      render: (text) => (
        <>{text.address === "" || text.address === "nan" || text.address === null ? "-" : text.address}</>
      )
    },
    {
      title: "Date of Creation",
      dataIndex: "date_of_creation",
      width: 200,
      sorter: {
        compare: (a, b) => moment(a.date_of_creation,"DD-MM-YYYY") - moment(b.date_of_creation,"DD-MM-YYYY"),
        multiple: 5,
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      width: 150,
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 7,
      },
      render: (text, record) => (
        console.log(text),
        (
          <div
            className="dropdown"
            style={{
              width: "100%",
              height: "30px",
              // border: "1px solid ",
              borderRadius: "15px",
              backgroundColor: text.status ? "#BDFDC8" : "#EFD6FF",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                // padding: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                padding: "5px 0px 5px",
              }}
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              {text.status ? "Active" : "Deactivate"}
            </p>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => (text.status ? deActiveFun(text) : ActiveFun(text))}
              >
                {/* <i
                className={
                  text.job_status
                    ? "fa fa-dot-circle-o text-danger"
                    : "fa fa-dot-circle-o text-success"
                }
              /> */}
                {text.status ? "Deactivate" : "Activate"}
              </a>
            </div>
          </div>
        )
      ),
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      width: 100,
      render: (admindata) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === '1') {
                  Seteditadmin(true);
                  Seteditdata(admindata);
                }
                else if (key === '2') {
                  setResetPassword(true);
                  Seteditdata(admindata);
                }
                else {
                  setShowModal(true);
                  setCPId(admindata.key);
                }
              }
            }}>

            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];


  const columns = [
    {
      title: "Organization Name",
      dataIndex: "org_name",
      key: "org_name",
      fixed: "left",
      render: (text) => {
        return text ? text : " - ";
      },
      sorter: {
        compare: (a, b) => a?.org_name?.localeCompare(b?.org_name)
      },
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      render: (text) => {
        return text ? text : " - ";
      },
      sorter: {
        compare: (a, b) => a?.job_title?.localeCompare(b?.job_title)
      },
    },
    // {
    //   title: 'Job Role',
    //   dataIndex: 'job_role',
    //   key: 'job_role',
    // },
    {
      title: "Acceptance Status",
      dataIndex: "request_status",
      key: "request_status",
      // render: (text) => { return text ? text : " - " }
      render: (text) => (
        <span>
          <SoftBadge
            variant="contained"
            color={text === "accepted" ? "success" : text === "declined" ? "error" : "info"}
            size="lg"
            badgeContent={
              text === "accepted" ? "Accepted" : text === "declined" ? "Declined" : "Requested"
            }
            container
          />
        </span>
      ),
      sorter: {
        compare: (a, b) => a?.request_status?.localeCompare(b?.request_status)
      },
    },
    {
      title: "Work Order ID",
      dataIndex: "work_order_id",
      key: "work_order_id",
      render: (text) => {
        return text ? text : " - ";
      },
      sorter: {
        compare: (a, b) => a?.work_order_id?.localeCompare(b?.work_order_id)
      },
    },
    {
      title: "Cost per Candidate",
      dataIndex: "amount_per_candidate",
      key: "amount_per_candidate",
      sorter: {
        compare: (a, b) => a?.amount_per_candidate - b?.amount_per_candidate
      },
      render: (text) => {
        return text ? (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              // fontSize: "15px",
              marginBottom: "-2px",
            }}
          >
            {text}
          </p>
        ) : (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              // fontSize: "15px",
              marginBottom: "-2px",
            }}
          >
            {" - "}
          </p>
        );
      },
    },
    {
      title: "Candidate Required",
      dataIndex: "candidate_required",
      key: "candidate_required",
      sorter: {
        compare: (a, b) => a?.candidate_required - b?.candidate_required
      },
      render: (text) => {
        return text ? (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "15px",
              marginBottom: "-2px",
            }}
          >
            {text}
          </p>
        ) : (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              // fontSize: "15px",
              marginBottom: "-2px",
            }}
          >
            {" - "}
          </p>
        );
      },
    },
    {
      title: "Candidates shared",
      dataIndex: "number_of_applicants",
      key: "number_of_applicants",
      sorter: {
        compare: (a, b) => a?.number_of_applicants - b?.number_of_applicants
      },
      render: (text, record) => {
        return (
          <p
            onClick={() => {
              setOrgData(record)
              getCandidatesShared(record.key, record.jobid);
              setCanShareModal(true);
              setOpenModal(false);
            }}
            style={{
              cursor: "pointer",
              marginBottom: "-2px",
              color: text !== 0 ? "#3B5092" : "#000",
              textDecorationLine: text !== 0 ? "underline" : "none",
              display: "flex",
              justifyContent: "center",
              fontSize: "15px",
            }}
          >
            {text}
          </p>
        );
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      width: 150,
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status)
      },
      render: (text, record) => (
        console.log(text),
        (
          <div
            className="dropdown"
            style={{
              width: "100%",
              height: "30px",
              // border: "1px solid ",
              borderRadius: "15px",
              backgroundColor: text.request_status === "accepted" ? "#BDFDC8" : "#EFD6FF",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                // padding: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                padding: "5px 0px 5px",
              }}
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              {text.request_status === "accepted" ? "Active" : "Deactivate"}
            </p>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={() =>
                  text.request_status !== "accepted" ? handleAccept(text) : handleDecline(text)
                }
              >
                {/* <i
                className={
                  text.job_status
                    ? "fa fa-dot-circle-o text-danger"
                    : "fa fa-dot-circle-o text-success"
                }
              /> */}
                {text.request_status === "accepted" ? "Deactivate" : "Activate"}
              </a>
            </div>
          </div>
        )
      ),
    },
  ];

  const columns2 = [
    {
      title: "Shortlisted Date",
      dataIndex: "shortlist_date",
      fixed: "left",
      key: "shortlist_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.shortlist_date, "DD-MM-YYYY") - moment(b.shortlist_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
    },
    {
      title: "Candidate Names",
      width: 200,
      dataIndex: "candidate",
      key: "candidate",
      fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a?.candidate?.localeCompare(b?.candidate) },
      // render : (text, record)=>{
      // return (<p
      //   onClick={()=>{
      //     setCanId(record.key);
      //     HandleFetchCandDetails(record.key);
      //     console.log("text", canId);
      //     // setOpenModal(true)
      //   }}
      //   style={{
      //       cursor: "pointer",
      //       marginBottom: "-2px",
      //       color: "#3B5092",
      //       textDecorationLine: "underline",
      //     }}
      // >{text}</p>)}
    },
    {
      title: "Phone Number",
      width: 150,
      dataIndex: "phone",
      key: "phone",
      fixed: "left",
      sorter: { compare: (a, b) => a?.phone - b?.phone },
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      width: 200,
      sorter: { compare: (a, b) => a?.industry?.localeCompare(b?.industry) },
      render: (text) => <span>{text !== null ? text : " - "}</span>,
      isExportable: true,
    },
    {
      title: "Roles",
      dataIndex: "job_role",
      key: "job_role",
      width: 150,
      sorter: { compare: (a, b) => a?.job_role?.localeCompare(b?.job_role) },
      isExportable: true,
    },

    {
      title: "Education",
      dataIndex: "education",
      key: "1",
      width: 150,
      sorter: { compare: (a, b) => a?.education?.localeCompare(b?.education) },
      isExportable: true,
    },
    {
      title: "Modified On",
      dataIndex: "modified",
      key: "2",
      width: 150,
      sorter: {
        compare: (a, b) =>
          moment(a.modified_on, "DD-MM-YYYY") - moment(b.modified_on, "DD-MM-YYYY"),
      },
      isExportable: true,
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "3",
      width: 150,
      sorter: { compare: (a, b) => a?.skills?.localeCompare(b?.skills) },
      isExportable: true,
    },
    {
      title: "Experience (yrs)",
      dataIndex: "experience",
      key: "4",
      width: 150,
      //sorter: { compare: (a, b) => a?.exp?.localeCompare(b?.exp) },
      isExportable: true,
    },
    {
      title: "Salary Requirement",
      dataIndex: "salary_req",
      key: "5",
      width: 180,
      //sorter: { compare: (a, b) => a?.skills?.localeCompare(b?.skills) },
      isExportable: true,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 150,
      sorter: { compare: (a, b) => a?.state?.localeCompare(b?.state) },
      isExportable: true,
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 150,
      sorter: { compare: (a, b) => a?.district?.localeCompare(b?.district) },
      isExportable: true,
    },
    {
      title: "Taluka",
      dataIndex: "taluka",
      key: "4",
      width: 150,
      sorter: { compare: (a, b) => a?.taluka?.localeCompare(b?.taluka) },
      isExportable: true,
    },
    {
      title: "Data Source",
      dataIndex: "datasource",
      key: "datasource",
      width: 150,
      sorter: { compare: (a, b) => a?.datasource?.localeCompare(b?.datasource) },
      isExportable: true,
    },
    {
      title: "Last Active",
      dataIndex: "lastactive",
      key: "5",
      width: 150,
      sorter: {
        compare: (a, b) => moment(a?.last_active, "DD-MM-YYYY") - moment(b?.active, "DD-MM-YYYY"),
      },
      isExportable: true,
    },
    // {
    //   title: "Relevancy Score",
    //   // dataIndex: "score",
    //   sorter: { compare: (a, b) => a.score - b.score },
    //   key: "6",
    //   width: 180,
    //   isExportable: true,
    //   // render: (text, record) => (
    //   //   <span>
    //   //     <SoftBadge
    //   //       variant="contained"
    //   //       color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
    //   //       size="xs"
    //   //       badgeContent={
    //   //         text >= 80
    //   //           ? "Excellent Match"
    //   //           : text < 80 && text >= 45
    //   //           ? "Medium Match"
    //   //           : "Poor Match"
    //   //       }
    //   //       container
    //   //     />
    //   //   </span>
    //   // ),
    //   render: (text) => (
    //     <span
    //       style={{
    //         backgroundColor:
    //           text.score > 80
    //             ? "#B7F4BB"
    //             : text.score < 80 && text.score >= 45
    //             ? "#EFB7F4"
    //             : "#FFD3D3",
    //         color:
    //           text.score > 80
    //             ? "#049C2F"
    //             : text.score < 80 && text.score >= 45
    //             ? "#62049C"
    //             : "#FF0000",
    //         paddingTop: "3px",
    //         paddingBottom: "3px",
    //         paddingLeft: "15px",
    //         paddingRight: "15px",
    //         borderRadius: "10px",
    //       }}
    //     >
    //       {text.score >= 80
    //         ? "Excellent Match"
    //         : text.score < 80 && text >= 45
    //         ? "Average Match"
    //         : "Less Match"}
    //     </span>
    //   ),
    // },
    {
      title: "Approach Status",
      // dataIndex: "sent_status",
      sorter: { compare: (a, b) => a?.sent_status.localeCompare(b?.sent_status) },
      key: "sent_status",
      width: 200,
      render: (text, record) => (
        <span>
          <SoftBadge
            variant="contained"
            color={
              text.sent_status === "success"
                ? "success"
                : text.sent_status === "fail"
                  ? "error"
                  : "info"
            }
            size="lg"
            badgeContent={
              // text >= 80
              //   ? "Excellent Match"
              //   : text < 80 && text >= 45
              //   ? "Medium Match"
              //   : "Poor Match"
              // text.sent_status === "success"
              //   ? "Broadcast Sent"
              //   : text.sent_status === "fail"
              //   ? "Broadcast Failed"
              //   : "Broadcast Not Sent"
              text.sent_status === "success"
                ? "Approached"
                : text.sent_status === "fail"
                  ? "Failed"
                  : "Not Yet Approached"
            }
            container
          />
        </span>
      ),
    },
    {
      title: "Interest Level",
      // dataIndex: "status",
      sorter: { compare: (a, b) => a?.broadcast_status.localeCompare(b?.broadcast_status) },
      key: "7",
      width: 200,
      render: (text) => (
        <div
          style={{
            backgroundColor:
              text.broadcast_status === "0"
                ? "#E9E9E9"
                : text.broadcast_status === "1"
                  ? "#EFB7F4"
                  : text.broadcast_status === "2"
                    ? "#C0CFFF"
                    : "#E9E9E9",
            color:
              text.broadcast_status === "0"
                ? "#767676"
                : text.broadcast_status === "1"
                  ? "#62049C"
                  : text.broadcast_status === "2"
                    ? "#3B5092"
                    : "#767676",
            // text.broadcast_status === "sent"
            //   ? "#3B5092"
            //   : // : text.status === "rejected"
            //     // ? "#62049C"
            //     "#767676",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            paddingRight: "10px",
            borderRadius: "10px",
            gap: "5px",
            // width: "106px",
          }}
        >
          {/* {text.status} */}
          {console.log("status ", text.broadcast_status)}
          {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}> */}
          <p style={{ marginBottom: "0px" }}>
            {text.broadcast_status === "0" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.22451 0.285366C7.6715 -0.0951218 8.3285 -0.0951222 8.77549 0.285366L9.39455 0.812325C9.66835 1.04539 10.0325 1.14296 10.3861 1.07802L11.1857 0.931186C11.7631 0.825167 12.3321 1.15367 12.5289 1.70668L12.8016 2.47257C12.9221 2.81131 13.1887 3.07786 13.5274 3.19845L14.2933 3.47109C14.8463 3.66795 15.1748 4.23693 15.0688 4.81428L14.922 5.61388C14.857 5.96753 14.9546 6.33165 15.1877 6.60545L15.7146 7.22451C16.0951 7.6715 16.0951 8.3285 15.7146 8.77549L15.1877 9.39455C14.9546 9.66835 14.857 10.0325 14.922 10.3861L15.0688 11.1857C15.1748 11.7631 14.8463 12.3321 14.2933 12.5289L13.5274 12.8016C13.1887 12.9221 12.9221 13.1887 12.8016 13.5274L12.5289 14.2933C12.3321 14.8463 11.7631 15.1748 11.1857 15.0688L10.3861 14.922C10.0325 14.857 9.66835 14.9546 9.39455 15.1877L8.77549 15.7146C8.3285 16.0951 7.6715 16.0951 7.22451 15.7146L6.60545 15.1877C6.33165 14.9546 5.96753 14.857 5.61388 14.922L4.81428 15.0688C4.23693 15.1748 3.66795 14.8463 3.47109 14.2933L3.19845 13.5274C3.07786 13.1887 2.81131 12.9221 2.47257 12.8016L1.70668 12.5289C1.15367 12.3321 0.825167 11.7631 0.931185 11.1857L1.07802 10.3861C1.14296 10.0325 1.0454 9.66835 0.812325 9.39455L0.285366 8.77549C-0.0951218 8.3285 -0.0951222 7.6715 0.285366 7.22451L0.812325 6.60545C1.04539 6.33165 1.14296 5.96753 1.07802 5.61388L0.931186 4.81428C0.825167 4.23693 1.15367 3.66795 1.70668 3.47109L2.47257 3.19845C2.81131 3.07786 3.07786 2.81131 3.19845 2.47257L3.47109 1.70668C3.66795 1.15367 4.23693 0.825167 4.81428 0.931185L5.61388 1.07802C5.96753 1.14296 6.33165 1.0454 6.60545 0.812325L7.22451 0.285366Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M7.70864 10.0036V7.62992H8.5982C9.20843 7.62992 9.65965 7.46827 9.95187 7.14498C10.2527 6.82169 10.4031 6.42608 10.4031 5.95816C10.4031 5.60934 10.33 5.31157 10.1839 5.06485C10.0378 4.80962 9.83155 4.61819 9.56511 4.49058C9.30727 4.35446 9.01075 4.2864 8.67555 4.2864C8.28878 4.2864 7.95359 4.36722 7.66996 4.52886C7.39493 4.682 7.18006 4.89469 7.02535 5.16694C6.87065 5.43068 6.79329 5.73696 6.79329 6.08577H5.60721C5.60721 5.58382 5.71895 5.11589 5.94241 4.682C6.17447 4.24811 6.51826 3.89504 6.97378 3.6228C7.42931 3.35055 7.99656 3.21443 8.67555 3.21443C9.27718 3.21443 9.79287 3.33779 10.2226 3.58451C10.6609 3.82273 10.9961 4.14602 11.2282 4.55439C11.4689 4.95425 11.5892 5.39665 11.5892 5.88159V6.03472C11.5892 6.51116 11.4732 6.9493 11.2411 7.34916C11.009 7.74902 10.6609 8.06806 10.1968 8.30628C9.7413 8.53598 9.17834 8.65084 8.50795 8.65084L8.97207 8.06381L8.94629 10.0036H7.70864ZM7.57971 12.7856V11.2287H9.07521V12.7856H7.57971Z"
                  fill="#767676"
                />
              </svg>
            ) : text.broadcast_status === "1" ? (
              ""
            ) : text.broadcast_status === "2" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M9.39455 0.812325L8.77549 0.285366C8.3285 -0.0951222 7.6715 -0.0951218 7.22451 0.285366L6.60545 0.812325C6.33165 1.0454 5.96753 1.14296 5.61388 1.07802L4.81428 0.931185C4.23693 0.825167 3.66795 1.15367 3.47109 1.70668L3.19845 2.47257C3.07786 2.81131 2.81131 3.07786 2.47257 3.19845L1.70668 3.47109C1.15367 3.66795 0.825167 4.23693 0.931186 4.81428L1.07802 5.61388C1.14296 5.96753 1.0454 6.33165 0.812325 6.60545L0.285366 7.22451C-0.0951222 7.6715 -0.0951218 8.3285 0.285366 8.77549L0.812325 9.39455C1.0454 9.66835 1.14296 10.0325 1.07802 10.3861L0.931185 11.1857C0.825167 11.7631 1.15367 12.3321 1.70668 12.5289L2.47257 12.8016C2.81131 12.9221 3.07786 13.1887 3.19845 13.5274L3.47109 14.2933C3.66795 14.8463 4.23693 15.1748 4.81428 15.0688L5.61388 14.922C5.96753 14.857 6.33165 14.9546 6.60545 15.1877L7.22451 15.7146C7.6715 16.0951 8.3285 16.0951 8.77549 15.7146L9.39455 15.1877C9.66835 14.9546 10.0325 14.857 10.3861 14.922L11.1857 15.0688C11.7631 15.1748 12.3321 14.8463 12.5289 14.2933L12.8016 13.5274C12.9221 13.1887 13.1887 12.9221 13.5274 12.8016L14.2933 12.5289C14.8463 12.3321 15.1748 11.7631 15.0688 11.1857L14.922 10.3861C14.857 10.0325 14.9546 9.66835 15.1877 9.39455L15.7146 8.77549C16.0951 8.3285 16.0951 7.6715 15.7146 7.22451L15.1877 6.60545C14.9546 6.33165 14.857 5.96753 14.922 5.61388L15.0688 4.81428C15.1748 4.23693 14.8463 3.66795 14.2933 3.47109L13.5274 3.19845C13.1887 3.07786 12.9221 2.81131 12.8016 2.47257L12.5289 1.70668C12.3321 1.15367 11.7631 0.825167 11.1857 0.931186L10.3861 1.07802C10.0325 1.14296 9.66835 1.0454 9.39455 0.812325Z"
                  fill="#849DED"
                />
                <path
                  d="M12.5354 5.22656L6.30208 11.4399L3.46875 8.61565"
                  stroke="#3B5092"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.22451 0.285366C7.6715 -0.0951218 8.3285 -0.0951222 8.77549 0.285366L9.39455 0.812325C9.66835 1.04539 10.0325 1.14296 10.3861 1.07802L11.1857 0.931186C11.7631 0.825167 12.3321 1.15367 12.5289 1.70668L12.8016 2.47257C12.9221 2.81131 13.1887 3.07786 13.5274 3.19845L14.2933 3.47109C14.8463 3.66795 15.1748 4.23693 15.0688 4.81428L14.922 5.61388C14.857 5.96753 14.9546 6.33165 15.1877 6.60545L15.7146 7.22451C16.0951 7.6715 16.0951 8.3285 15.7146 8.77549L15.1877 9.39455C14.9546 9.66835 14.857 10.0325 14.922 10.3861L15.0688 11.1857C15.1748 11.7631 14.8463 12.3321 14.2933 12.5289L13.5274 12.8016C13.1887 12.9221 12.9221 13.1887 12.8016 13.5274L12.5289 14.2933C12.3321 14.8463 11.7631 15.1748 11.1857 15.0688L10.3861 14.922C10.0325 14.857 9.66835 14.9546 9.39455 15.1877L8.77549 15.7146C8.3285 16.0951 7.6715 16.0951 7.22451 15.7146L6.60545 15.1877C6.33165 14.9546 5.96753 14.857 5.61388 14.922L4.81428 15.0688C4.23693 15.1748 3.66795 14.8463 3.47109 14.2933L3.19845 13.5274C3.07786 13.1887 2.81131 12.9221 2.47257 12.8016L1.70668 12.5289C1.15367 12.3321 0.825167 11.7631 0.931185 11.1857L1.07802 10.3861C1.14296 10.0325 1.0454 9.66835 0.812325 9.39455L0.285366 8.77549C-0.0951218 8.3285 -0.0951222 7.6715 0.285366 7.22451L0.812325 6.60545C1.04539 6.33165 1.14296 5.96753 1.07802 5.61388L0.931186 4.81428C0.825167 4.23693 1.15367 3.66795 1.70668 3.47109L2.47257 3.19845C2.81131 3.07786 3.07786 2.81131 3.19845 2.47257L3.47109 1.70668C3.66795 1.15367 4.23693 0.825167 4.81428 0.931185L5.61388 1.07802C5.96753 1.14296 6.33165 1.0454 6.60545 0.812325L7.22451 0.285366Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M7.70864 10.0036V7.62992H8.5982C9.20843 7.62992 9.65965 7.46827 9.95187 7.14498C10.2527 6.82169 10.4031 6.42608 10.4031 5.95816C10.4031 5.60934 10.33 5.31157 10.1839 5.06485C10.0378 4.80962 9.83155 4.61819 9.56511 4.49058C9.30727 4.35446 9.01075 4.2864 8.67555 4.2864C8.28878 4.2864 7.95359 4.36722 7.66996 4.52886C7.39493 4.682 7.18006 4.89469 7.02535 5.16694C6.87065 5.43068 6.79329 5.73696 6.79329 6.08577H5.60721C5.60721 5.58382 5.71895 5.11589 5.94241 4.682C6.17447 4.24811 6.51826 3.89504 6.97378 3.6228C7.42931 3.35055 7.99656 3.21443 8.67555 3.21443C9.27718 3.21443 9.79287 3.33779 10.2226 3.58451C10.6609 3.82273 10.9961 4.14602 11.2282 4.55439C11.4689 4.95425 11.5892 5.39665 11.5892 5.88159V6.03472C11.5892 6.51116 11.4732 6.9493 11.2411 7.34916C11.009 7.74902 10.6609 8.06806 10.1968 8.30628C9.7413 8.53598 9.17834 8.65084 8.50795 8.65084L8.97207 8.06381L8.94629 10.0036H7.70864ZM7.57971 12.7856V11.2287H9.07521V12.7856H7.57971Z"
                  fill="#767676"
                />
              </svg>
            )}
          </p>
          <p style={{ marginBottom: "0px" }}>
            {text.broadcast_status === "0"
              ? "Yet to start"
              : text.broadcast_status === "1"
                ? "In progress"
                : text.broadcast_status === "2"
                  ? "Intrested"
                  : "Yet to start"}
          </p>
          {/* </div> */}
        </div>
      ),
      ellipsis: {
        showTitle: false,
      },
      // render: (text) => (
      //   <span>
      //     <SoftBadge
      //       variant="contained"
      //       color={text.status === "sent" ? "seconday" : "dark"}
      //       size="xs"
      //       badgeContent={text.status === "sent" ? "Sent" : "Yet to send"}
      //       container
      //     />
      //   </span>
      //   // <Tooltip placement="topLeft" title={text.status}>
      //   //   {text.status}
      //   // </Tooltip>
      // ),
    },
    {
      title: "Hiring Status",
      // dataIndex: "hiring_status",
      sorter: { compare: (a, b) => a?.hiring_status?.localeCompare(b?.hiring_status) },
      key: "hiring_status",
      width: 300,
      render: (text) => (
        <span
          style={{
            backgroundColor:
              text.completion_status === "completed"
                ? "#BDFDC8"
                : text.completion_status === "rejected"
                  ? "#EFD6FF"
                  : "#FFE1C3",
            color:
              text.completion_status === "completed"
                ? "#049C2F"
                : text.completion_status === "rejected"
                  ? "#62049C"
                  : "#EA7500",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "50px",
          }}
        >
          {text.candidate_status}
        </span>
      ),
    },
    // { title: "Column 8", dataIndex: "address", key: "8" },
    {
      title: "Candidate Profiling Score",
      // dataIndex: "ratings",
      sorter: { compare: (a, b) => a?.ratings - b?.ratings },
      key: "ratings",
      width: 230,
      render: (text, record) => (
        console.log("profile score: ", text),
        (
          // <SoftBox>
          //   <SoftBadge
          //     variant="contained"
          //     color={text >= 3.5 ? "success" : text < 3.5 && text >= 2 ? "warning" : "error"}
          //     size="xs"
          //     badgeContent={
          //       text >= 3.5
          //         ? "Excellent Match"
          //         : text < 3.5 && text >= 2
          //         ? "Medium Match"
          //         : "Poor Match"
          //     }
          //     container
          //   />
          // </SoftBox>
          <>
            {/* {console.log("ratings text:", text)}, */}
            {/* <span>{text.ratings}</span> */}
            <div
              className="dropdown"
              style={{
                width: "100%",
                height: "30px",
                // border: "1px solid ",
                borderRadius: "15px",
                backgroundColor:
                  text.ratings >= "4"
                    ? "#B7F4BB"
                    : text.ratings < "4" && text.ratings >= "2.5"
                      ? "#FFD2A5"
                      : text.ratings === "0"
                        ? "#abe9f7"
                        : "#FFD3D3",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  // padding: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  padding: "5px 0px 5px",
                  color:
                    text.ratings >= "4"
                      ? "#049C2F"
                      : text.ratings < "4" && text.ratings >= "2.5"
                        ? "#EA7500"
                        : text.ratings === "0"
                          ? "#08a1c4"
                          : "#FF0000",
                }}
              // className="dropdown-toggle"
              // data-bs-toggle="dropdown"
              >
                {/* {text === null ? "Placed" : "Not Placed"} */}
                {text.ratings !== "0" && text.ratings !== "" ? text.ratings : "Yet to be rated"}
              </p>
            </div>
          </>
        )
      ),
    },
    {
      title: "Counsellor Assigned",
      width: 200,
      dataIndex: "counsellor_name",
      key: "counsellor_name",
      // fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a?.counsellor_name?.localeCompare(b?.counsellor_name) },
    },
    {
      title: "Assigned Date",
      width: 200,
      dataIndex: "assign_date",
      key: "assign_date",
      // fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => moment(a?.assign_date, "DD-MM-YYYY") - moment(b?.assign_date, "DD-MM-YYYY") },
    },
    {
      title: "Counsellor Comments",
      // dataIndex: "comments",
      width: 200,
      key: "2",
      render: (text) => (
        <span>{text.display_comment !== null ? text.display_comment : " - "}</span>
        // <span>{text.job_role}</span>
      ),
      sorter: { compare: (a, b) => a?.display_comment?.localeCompare(b?.display_comment) },
    },
    {
      title: "Candidate Feedback",
      // dataIndex: "feedback",
      width: 200,
      key: "2",
      sorter: { compare: (a, b) => a?.feedback?.localeCompare(b?.feedback) },
      render: (text) => (
        <span>{text.feedback !== null ? text.feedback : " - "}</span>
        // <span>{text.job_role}</span>
      ),
    },
    {
      title: "Resume",
      width: 150,
      key: "resume",
      render: (text) =>
        text?.resume === undefined || text?.resume === null || text?.resume === "" ? (
          <span onClick={() => (setResumeModal(true), setRowData(text))}>
            <SoftBadge
              variant="contained"
              color="info"
              size="lg"
              badgeContent={
                "Click to Upload"
              }
              container
            />
          </span>
        ) : (
          (console.log("a", text.resume),
            (
              <a href={text.resume} target="_blank" rel="noreferrer">
                <SoftBadge
                  variant="contained"
                  color="success"
                  size="lg"
                  badgeContent={
                    "View"
                  }
                  container
                />
              </a>
            ))
        ),
    },
    {
      title: "Action",
      // title: (
      //   // <SoftTypography fontWeight="large" fontSize="24px">
      //   //   ...
      //   // </SoftTypography>
      //   <MdMoreHoriz />
      // ),
      key: "operation",
      // fixed: "right",
      width: 100,
      // render: () => <MdMoreHoriz />,
      render: (text) => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-horizontal" color="black" />
            </Button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <p
                  className="dropdown-item"
                  // href="#EditUser"
                  onClick={() => {
                    console.log("whatsapp verification modal");
                    setApplyModal(true);
                    setRowData(text);
                    //sendMessageFunc()
                    // setStep2(true);
                  }}
                >
                  Whatsapp Verification
                </p>
              </li>
              <li>
                <p
                  className="dropdown-item"
                  // href="#Deleteuser"
                  onClick={() => {
                    setactionModal1(true);
                    setRowData(text);
                    // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                  }}
                >
                  Counsellor
                </p>
              </li>
              {/* <li>
                <p
                  className="dropdown-item"
                  // href="#Deleteuser"
                  onClick={() => {
                    console.log("sms verification");
                  }}
                >
                  SMS Verification
                </p>
              </li> */}

              {/*
              {text.resume === null || "" ?
                <li>
                  <p
                    className="dropdown-item"
                    // href="#Deleteuser"
                    onClick={() => {
                      GenerateData(text);
                    }}
                  >
                    Generate Resume
                  </p>
                </li> :
                null
              }
              */}

              <li>
                <p
                  className="dropdown-item"
                  // href="#Deleteuser"
                  onClick={() => {
                    console.log("sms verification");
                    setResumeModal(true);
                    setRowData(text);
                  }}
                >
                  Upload Resume
                </p>
              </li>
              <li>
                <p
                  className="dropdown-item"
                  // href="#Deleteuser"
                  onClick={() => {
                    console.log("sms verification");
                  }}
                >
                  Send to Job Provider
                </p>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  const dummyData = [
    {
      key: "1",
      org_name: "Mike",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "Yes",
      order_id: 12345,
      candidates_shared: 2,
    },
    {
      key: "2",
      org_name: "John",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "No",
      order_id: 12347,
      candidates_shared: 4,
    },
    {
      key: "1",
      org_name: "Mike",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "Yes",
      order_id: 12345,
      candidates_shared: 2,
    },
    {
      key: "2",
      org_name: "John",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "No",
      order_id: 12347,
      candidates_shared: 4,
    },
    {
      key: "1",
      org_name: "Mike",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "Yes",
      order_id: 12345,
      candidates_shared: 2,
    },
    {
      key: "2",
      org_name: "John",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "No",
      order_id: 12347,
      candidates_shared: 4,
    },
    {
      key: "1",
      org_name: "Mike",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "Yes",
      order_id: 12345,
      candidates_shared: 2,
    },
    {
      key: "2",
      org_name: "John",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "No",
      order_id: 12347,
      candidates_shared: 4,
    },
    {
      key: "1",
      org_name: "Mike",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "Yes",
      order_id: 12345,
      candidates_shared: 2,
    },
    {
      key: "2",
      org_name: "John",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "No",
      order_id: 12347,
      candidates_shared: 4,
    },
    {
      key: "1",
      org_name: "Mike",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "Yes",
      order_id: 12345,
      candidates_shared: 2,
    },
    {
      key: "2",
      org_name: "John",
      job_title: "Sales",
      job_role: "Sales Executive",
      status: "No",
      order_id: 12347,
      candidates_shared: 4,
    },
  ];
  const [colHeaders, setColHeaders] = useState([
    {
      label: "Registration ID",
      key: "key",
      isExportable: true,
    },
    {
      label: "Organization",
      key: "organization",
      isExportable: true,
    },
    {
      label: "SPOC Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "mobile",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Address",
      key: "address",
      isExportable: true,
    },
    {
      label: "Date of Creation",
      key: "date_of_creation",
      isExportable: true,
    },
    {
      label: "Status",
      key: "status",
      isExportable: true,
    },
  ]);
  // const data =admindata?.map((item ,id)=>{return {
  //   key: item.registration_id,
  //   // organization: item.org_name ,
  //   // location: item.org_address,
  //   name: `${item.firstnm+" "+item.lastnm}`,
  //   firstnm: item.firstnm,
  //   lastnm: item.lastnm,
  //   sequrity_ques: item.sequrity_ques,
  //   sequrity_ans: item.sequrity_ans,
  //   email: item.email,
  //   mobnum: item.mobnum,
  //   date_of_creation: moment(item.createddt).utc().format('DD-MM-YYYY') ,
  // }});

  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectCol, setSelectCol] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rowJobId, setRowJobId] = useState([]);
  const [rowName, setRowName] = useState([]);
  const [val, setVal] = useState("");
  const [searchData, setSearchData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const [name, setName] = useState("");
  const [rowMob, setRowMob] = useState([]);
  const [KPIObj, setKPIObj] = useState({
    total_cp: "0",
    total_job_requested: "0",
    total_job_accepted: "0",
    total_job_rejected: "0",
  });
  const documentType = [
    { label: "Application", value: "application" },
    { label: "Cover letter", value: "cover_letter" },
    { label: "Pan Card", value: "pan_card" },
    { label: "GST Certificate", value: "gst_cert" },
    { label: "Financial Year Report (CA approved copy)", value: "financial_report" },
    { label: "Registration Certificate", value: "reg_cert" },
    { label: "Udyam Certificate", value: "udyam_cert" },
    { label: "Declaration Letter (As per the given format)", value: "declaration_cert" },
    { label: "EMD", value: "org_emd" },
    { label: "Tender Fee (Upload Screenshot of your transaction)", value: "org_tender" },
    { label: "Other Documents (Work Orders, Credentials)", value: "other_cert" },
  ];
  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
      width: "300px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };
  const [selectDoc, setSelectDoc] = useState("");
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setTablerow(selectedRows)
    setRowMob(selectedRows.map((item, i) => item.phone));
    setSelectedRowKeys(newSelectedRowKeys);
    setRowJobId(selectedRows.map((item, i) => item.jobid));
    filterData(admindata, newSelectedRowKeys);
    setRowName(selectedRows.map((item, i) => item.candidate_name));
    setJobdesignation(selectedRows.map((item, i) => item.job_role));
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", data);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };

  const KpiFunc = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/dashboard/channelpartner/kpi`,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      })
      .then((res) => {
        setKPIObj({
          total_cp: res.data["Channel Partner KPI"].total_cp,
          total_job_requested: res.data["Channel Partner KPI"].total_job_requested,
          total_job_accepted: res.data["Channel Partner KPI"].total_job_accepted,
          total_job_rejected: res.data["Channel Partner KPI"].total_job_rejected,
        });
      });
  };
  console.log("piddd", partnerId);

  const getChannelPartners = async (value) => {
    console.log("pid", value);
    setIsLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/dashboard/superadmin/fetch/assignjob`,
        {
          params: {
            cpid: value,
          },
        }
      )
      .then((res) => {
        console.log("partners", res.data["Assigned Jobs"]);
        setChannelPartners(res.data["Assigned Jobs"]);
        setPaginationStateJob({
          ...paginationStateJob,
          dataSize: res.data["Assigned Jobs"].length,
        });
        // setOpenModal(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("getChannelPartners", err);
      });
  };

  const getCandidatesShared = async (cpi_id, job_id) => {
    console.log("piddd", cpi_id, job_id);
    setIsLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/dashboard/superadmin/fetch/assignjobcandidatedetail`,
        {
          params: {
            cpid: cpi_id ? cpi_id : partnerId,
            jobid: job_id,
          },
        }
      )
      .then((res) => {
        console.log("partnersss", res.data["Candidate Data"]);
        setCandidateShared(
          res.data["Candidate Data"]?.map((index, id) => {
            return {
              key: index.registration_id,
              candidate: `${index.first_name} ${index.last_name}`,
              email: index.email === null ? " - " : index.email !== "" ? index.email : " - ",
              phone: index?.mobile?.length === 12 ? index?.mobile?.substring(2) : index?.mobile,
              education: index.highest_education ? index.highest_education : " - ",
              modified: moment(index.modifieddt).format("DD-MM-YYYY"),
              // skill: `skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index}`,
              skills: index.skills ? index.skills : " - ",
              job_role: index.job_role ? index.job_role : " - ",
              state: index.state !== "" ? index.state : " - ",
              district: index.district !== "" ? index.district : " - ",
              taluka: index.taluka ? index.taluka : " - ",
              datasource: index.datasource !== "" ? index.datasource : " - ",
              lastactive: moment(index.last_activedate).format("DD-MM-YYYY"),
              data_status_type: index.data_status_type,
              score: index.score !== "" ? index.score : 0,
              degree: index.degree !== "" ? index.degree : " - ",
              stream: index.stream !== "" ? index.stream : " - ",
              salary_req: index.desired_salary !== "" ? index.desired_salary : " - ",
              experience: index.experience !== "" ? index.experience : " - ",
              industry: index.industry !== "" ? index.industry : " - ",
              first_name: index.first_name !== "" ? index.first_name : " - ",
              last_name: index.last_name !== "" ? index.last_name : " - ",
              sent_status: index.sent_status !== "" ? index.sent_status : " - ",
              broadcast_status: index.broadcast_status !== "" ? index.broadcast_status : " - ",
              candidate_status: index.candidate_status !== "" ? index.candidate_status : " - ",
              ratings: index.rating,
              counsellor_name: index.counsellor_name !== "" ? index.counsellor_name : " - ",
              shortlist_date:
                moment(index.shortlisteddt).format("DD-MM-YYYY") != ""
                  ? moment(index.shortlisteddt).format("DD-MM-YYYY")
                  : " - ",
              assign_date:
                moment(index.assigneddt).format("DD-MM-YYYY") != ""
                  ? moment(index.assigneddt).format("DD-MM-YYYY")
                  : " - ",
              display_comment: index.display_comment !== "" ? index.display_comment : " - ",
              feedback: index.feedback !== "" ? index.feedback : " - ",
              resume: index.resume_cv !== "" ? index.resume_cv : " - ",
              whatsapp_status: index.whatsapp_status !== "" ? index.whatsapp_status : " - ",
            };
          })
        );
        // setOpenModal(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("getChannelPartners", err);
      });
  };

  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        deactivatedby: registerID,
        active: "false",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: res.data["Developer Message"]
            ? res.data["Developer Message"]
            : "Channel Partner Deactivated",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          fetchadminList();
        });
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data["User Message"]
            ? err.response.data["User Message"]
            : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const ActiveFun = (data) => {
    console.log(roleId, data.key, "BBB");
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID

        // deactivate_role: roleId,
        // "partner-association": "samarth",
        deactivate_id: data.key,
        deactivatedby: registerID,
        active: "true",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: res.data["Developer Message"]
            ? res.data["Developer Message"]
            : "Channel Partner Activated",
          //text: res.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          fetchadminList();
        });
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data["User Message"]
            ? err.response.data["User Message"]
            : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const handleDecline = async (data) => {
    console.log("data declined:", data);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/transactions/modify/job/status`,
        {
          jobid: data.jobid,
          cp_id: partnerId,
          request_status: "declined",
          candidate_count: data.applicantct,
          counter: data.counter,
        }
      )
      .then((res) => {
        // AcceptFunc();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Request Declined Successfully",
          showConfirmButton: false,
          timer: 3000,
        }).then(() =>
          // RequestedList()
          getChannelPartners(partnerId)
        );
      })
      .catch((err) => {
        console.log("err:", err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const handleAccept = async (data) => {
    console.log("data accept:", data);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/transactions/modify/job/status`,
        {
          jobid: data.jobid,
          cp_id: partnerId,
          orgid: data.orgid,
          request_status: "accepted",
          candidate_count: data.applicantct,
          counter: data.counter,
        }
      )
      .then((res) => {
        // AcceptFunc(res.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Request Accepted Successfully",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => getChannelPartners(partnerId));
      })
      .catch((err) => {
        console.log("err:", err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const handleConsPost = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/dashboard/fetch/details`, {
        params: { createdby: sessionStorage.getItem("register_id") },
      })
      .then((res) => {
        setConsList(res.data.counsellor_details);
        console.log(res.data, "eee");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
    handleConsPost()
  }, []);

  const fetchadminList = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/channelpartner/register`,
        {
          params: {
            // limit: 10,
            limit: pageSize,
            offset: paginationState.page - 1,
            search_data: name,
            created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
          },
        }
      )
      .then((res) => {
        console.log('abc', res)
        Setadmindata(
          res.data["Developer Message"]?.map((item, id) => ({
            key: item.registration_id,
            organization: item.org_name,
            name: `${item?.firstnm[0].toUpperCase() + item.firstnm.slice(1)} ${item?.lastnm ? item.lastnm[0].toUpperCase() + item.lastnm.slice(1) : ""
              }`,
            mobile: item?.mobnum?.length === 12 ? item?.mobnum?.substring(2) : item?.mobnum,
            email: item.email,
            formLink: item.form_link,
            role: item.role_id,
            cp_type: item.type,
            firstnm: item.firstnm,
            lastnm: item.lastnm,
            state: item.org_state,
            district: item.org_district,
            address: item.org_address,
            pincode: item.org_pincode,
            website: item.org_website,
            security_ques: item.security_ques,
            security_ans: item.security_ans,
            source: item.source,
            date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
            date_of_modify: moment(item.modifieddt).utc().format("DD-MM-YYYY"),
            status: item.is_active,
          }))
        );
        setSearchData(
          res.data["Developer Message"]?.map((item, id) => ({
            key: item.registration_id,
            organization: item.org_name,
            name: `${item?.firstnm[0].toUpperCase() + item.firstnm.slice(1)} ${item?.lastnm ? item.lastnm[0].toUpperCase() + item.lastnm.slice(1) : ""
              }`,
            mobile: item?.mobnum?.length === 12 ? item?.mobnum?.substring(2) : item?.mobnum,
            email: item.email,
            formLink: item.form_link,
            role: item.role_id,
            cp_type: item.type,
            firstnm: item.firstnm,
            lastnm: item.lastnm,
            state: item.org_state,
            district: item.org_district,
            address: item.org_address,
            security_ques: item.security_ques,
            security_ans: item.security_ans,
            pincode: item.org_pincode,
            website: item.org_website,
            source: item.source,
            date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
            date_of_modify: moment(item.modifieddt).utc().format("DD-MM-YYYY"),
            status: item.is_active,
          }))
        );
        setPaginationState({ ...paginationState, dataSize: res.data["count"] });
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  console.log(searchData, 'abc')
  const EditDocsFunc = async () => {
    let docData = new FormData();
    docData.append("cp_id", cpId);
    docData.append("type", selectDoc);
    docData.append("document", file);
    docData.append("updatedby", registerID);
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/channelpartner/register`,
        docData
      )
      .then((res) => {
        setSelectDoc("");
        setFile("");
        Swal.fire({
          icon: "success",
          //title: "",
          title: res.data["Developer Message"]
            ? res.data["Developer Message"]
            : "Document uploaded successfully",
          showConfirmButton: false,
          timer: 1500,
        })
        setShowModal(false);
      })
      .catch((err) => {
        setSelectDoc("")
        setFile("")
        Swal.fire({
          icon: "error",
          title: err?.response?.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data["User Message"]
            ? err.response.data["User Message"]
            : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSearch = () => {
    const newData = admindata.filter((ele) => {
      return name?.toLocaleLowerCase() === ""
        ? ele
        : ele.organization?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
          name.toLocaleLowerCase() === ""
          ? ele
          : String(ele.mobile).toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
            name.toLocaleLowerCase() === ""
            ? ele
            : String(ele.name).toLocaleLowerCase().includes(name.toLocaleLowerCase());
    });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    setSearchData(newData);
  };
  useEffect(() => {
    handleSearch();
  }, [name]);
  useEffect(() => {
    fetchadminList();
    fetchStateList();
    KpiFunc();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  console.log(searchData, 'ABBBC')

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={2}>
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              {translate('cp')}       
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('tcp')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_cp === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_cp
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('tjpr')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_job_requested === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_job_requested
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('tajp')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_job_accepted === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_job_accepted
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('djp')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_job_rejected === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_job_rejected
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="100%">
            {/* <DataTable            
                table={channelpartnertable}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
              /> */}
            {/* <Card> */}

            <SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                mb={1}
              >
                <SoftBox width={{ lg: "500px", md: "400px" }}>
                  <SoftInput
                    isClearable
                    // width={{ lg: "1000px", md: "600px" }}
                    // width="1000px"
                    id="search-bar"
                    className="text"
                    // onInput={(e) => {
                    //   setSearchQuery(e.target.value);
                    // }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                    placeholder="Search by organization, name and phone number"
                    size="small"
                  />
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="10px"
                  justifyContent="space-between"
                >
                  {/* {selectedRowKeys.length > 1 && (
                // <Button variant="contained" color="success" >
                //   Deactivate
                // </Button>
                <SoftBox
                  // width={{ lg: "120px", md: "80px", xs: "60px" }}
                  width="120px"
                  ml={{ lg: "920px", md: "450px", xs: "180px" }}
                  sx={{
                    position: "absolute",
                    // marginLeft: "850px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    // backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    border: "1px solid red",
                    cursor: "pointer",
                  }}
                  // onclick to deactivate all
                >
                  <SoftTypography
                    sx={{
                      color: "red",
                      fontWeight: "medium",
                      fontSize: "15px",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      padding: "auto",
                    }}
                    // fontSize={{ lg: "15px", md: "12px", xs: "7px" }}
                  >
                    Deactivate All
                  </SoftTypography>
                </SoftBox>
              )} */}
                  <Tooltip key="1" title={tablerow.length === 0 ? "Download All" : "Download"} placement="top">
                    <SoftBox
                      sx={{
                        height: "33px",
                        backgroundColor: "#000000",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      width="40px"
                      onClick={() => (
                        setShow(!show),
                        // setFileName(() => {
                        //   getFileName();
                        // })
                        getFileName()
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="46"
                        height="34"
                        viewBox="0 0 46 34"
                        fill="none"
                      >
                        <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                        <path
                          d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22.9531 16.5098V23.0102"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </SoftBox>
                  </Tooltip>

                  {/*
                  <SoftBox
                    width="120px"
                    // ml={{ lg: "1070px", md: "600px", xs: "300px" }}
                    sx={{
                      // position: "absolute",
                      // marginLeft:"1000px",
                      // marginLeft: "1000px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "32px",
                      // backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      border: "1px solid ",
                      cursor: "pointer",
                    }}
                  // onclick to deactivate all
                  >
                    <SoftTypography
                      sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                      onClick={() => (
                        setShow(!show),
                        // setFileName(() => {
                        //   getFileName();
                        // })
                        getFileName()
                      )}
                    >
                      Export CSV
                    </SoftTypography>
                    <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink>
                  </SoftBox>
                  */}

                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#3B5092",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => navigate("/channel-partner-registration-page")}
                  >
                    <SoftBox sx={{ color: "#FFF" }}>+</SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Table
              // {...tableProps}
              // pagination={{ position: ["bottomCenter"] }}
              loading={isLoading}
              rowSelection={rowSelection}
              columns={colName}
              dataSource={searchData}
              scroll={searchData.length <= 2 ? { x: 1300, y: 400 } : { x: 1300 }}
              pagination={{
                // defaultPageSize: ,
                // position: ["none", "bottomCenter"],
                pageSizeOptions: ["10", "20", "50", "100", "500"],
                total: paginationState.dataSize,
                showSizeChanger: true,
                // onShowSizeChange: { onShowSizeChange },
                onShowSizeChange: handlePageSizeChange,
                current: paginationState.page,
                onChange: (page, pageSize) =>
                  setPaginationState({ ...paginationState, page: page }),
              }}
            // exportable
            // exportableProps={{ showColumnPicker: true }}
            // searchable
            />

            {/* </Card> */}
            <AdminEdit
              editadmin={editadmin}
              Seteditadmin={Seteditadmin}
              editdata={editdata}
              Seteditdata={Seteditdata}
            />

            {/* reset password  */}
            <PasswordResetChannelPartner
              editadmin={resetPassword}
              Seteditadmin={setResetPassword}
              editdata={editdata}
            />
          </SoftBox>
          {/* </Card> */}
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {colHeaders.map((ele, index) => (
                <>
                  <ul className="UlCSV" key={index}>
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={tablerow.length === 0 ? searchData : tablerow}
                    //data={selectedCSVData}
                    filename={fileName}
                    headers={colHeaders.filter((ele) => ele.isExportable === true)}
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* modal for export */}

        {/* modal for document edit */}
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          centered
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <SoftBox sx={{ m: 0 }}>
            <Modal.Body>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Edit Document </p>

                <button className="CloseBtn" onClick={() => { setShowModal(false); setSelectDoc(""); setFile("") }}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                  gap: "20px",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <SoftBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                    // marginLeft: "0px",
                    // marginTop: "30px",
                  }}
                >
                  <SoftTypography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "small",
                      lineHeight: "19px",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "0px",
                    }}
                  >
                    Select Document{" : "}
                  </SoftTypography>
                  <Select
                    placeholder="Select..."
                    // isClearable
                    styles={stylesDD}
                    options={documentType}
                    // sx={{ width: "300px" }}
                    // value={selectDoc!==""?selectDoc}
                    onChange={(e) => setSelectDoc(e ? e.value : "")}
                  />
                </SoftBox>
                <input
                  className="form-control"
                  type="file"
                  accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                  id="datasource"
                  name="datasource"
                  onChange={(e) => {
                    // const file = e.target.files[0],
                    console.log("resumefile ", e.target.files[0]), setFile(e.target.files[0]);
                  }}
                />
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "40px" }}
              >
                <button
                  className="login-btn3"
                  style={file === "" || selectDoc === "" ? { cursor: 'no-drop' } : { cursor: 'pointer' }}
                  onClick={() => file === "" || selectDoc === "" ? console.log('text') : EditDocsFunc()}
                >
                  <span className="login-btn3-Text">Save</span>
                </button>
                <button className="login-btn4" onClick={() => { setShowModal(false); setSelectDoc(""); setFile("") }}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>
              </Box>
            </Modal.Body>
          </SoftBox>
        </Modal>
        {/* modal for document edit */}

        <Modal
          show={openModal}
          onHide={() => {
            setOpenModal(false);
            // setShowEditModal(false);
          }}
          centered
          size="xl"
          closeButton
          contentClassName="modal-height"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <SoftTypography>Assigned Posts</SoftTypography>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SoftBox>
              {console.log("Hiii", channelPartners)}
              <Table
                columns={columns}
                dataSource={channelPartners}
                loading={isLoading}
                // rowSelection={rowSelection}
                scroll={{ x: 1300, y: 400 }}
                pagination={{
                  total: paginationStateJob.dataSize,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  current: paginationStateJob.page,
                  onChange: (page, pageSize) =>
                    setPaginationStateJob({ ...paginationStateJob, page: page }),
                }}
              />
            </SoftBox>
          </Modal.Body>
        </Modal>

        <Modal
          show={canShareModal}
          onHide={() => {
            setCanShareModal(false);
            setOpenModal(true);
            // setShowEditModal(false);
          }}
          centered
          size="xl"
          closeButton
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <SoftTypography>Candidates Shared</SoftTypography>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SoftBox>
              <Table
                loading={isLoading}
                columns={columns2}
                dataSource={candidateShared}
                scroll={{ x: 1300, y: 400 }}
                pagination={{
                  total: paginationStateCand.dataSize,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  current: paginationStateCand.page,
                  onChange: (page, pageSize) =>
                    setPaginationStateCand({ ...paginationStateCand, page: page }),
                }}
              />
            </SoftBox>
          </Modal.Body>
        </Modal>

        <Modal
          show={resumeModal}
          onHide={() => {
            setResumeModal(false);
          }}
          centered
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <SoftBox sx={{ m: 0 }}>
            {console.log("rowdata for resume:", rowData)}
            <Modal.Body>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Upload Resume </p>

                <button className="CloseBtn" onClick={() => setResumeModal(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                  gap: "20px",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <input
                  className="form-control"
                  type="file"
                  accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                  id="datasource"
                  name="datasource"
                  onChange={(e) => {
                    // const file = e.target.files[0],
                    console.log("resumefile ", e.target.files[0]),
                      setResumeFile(e ? e.target.files[0] : "");
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "40px" }}>
                <button
                  className="login-btn3"
                  onClick={() => {
                    ResumeUploadFunc(rowData);
                  }}
                >
                  <span className="login-btn3-Text">Save</span>
                </button>
                <button className="login-btn4" onClick={() => setResumeModal(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>
              </Box>
            </Modal.Body>
          </SoftBox>
        </Modal>

        <Modal
          show={applyModal}
          onHide={() => {
            setApplyModal(false);
            // showAlert();
          }}
          centered
        >
          <Modal.Header>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SoftTypography>
                <b>Candidate Connect Message</b>
              </SoftTypography>

              <CloseIcon size="small" onClick={() => setApplyModal(false)} />
            </Box>
          </Modal.Header>

          <Modal.Body>
            <SoftBox display="flex" flexDirection="column">
              {/* <SoftBox display="flex" justifyContent="center" mt="20px" mb="15px">
              <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                Candidate connect message
              </SoftTypography>
            </SoftBox> */}
              <SoftBox display="flex" flexDirection="column" justifyContent="center">
                <SoftTypography fontWeight="small" fontSize="14px" lineHeight="19px">
                  Dear Candidate,
                </SoftTypography>
                <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                  Greetings from SAMARTH
                </SoftTypography>
                <SoftTypography fontWeight="small" fontSize="14px" lineHeight="19px">
                  Based on our evaluation of your skills, we have been assigned by your local public
                  administrator to help you with your job search. We have identified job requirements
                  that may suit your requirements. Are you actively looking for new job opportunities?
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Modal.Body>
          <Modal.Footer className="apply-modal">
            <SoftBox
              // pl={2}
              sx={{
                height: "45px",
                backgroundColor: "#3B5092",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => {
                setApplyModal(false);
                // setStep1(false);
                // setStep2(true);
                sendMessageFunc();
              }}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
              >
                Send
              </SoftTypography>
            </SoftBox>
          </Modal.Footer>
        </Modal>

        <Modal
          show={actionModal1}
          onHide={() => {
            setactionModal1(false);
            // showAlert();
          }}
          size="sm"
          centered
        >
          <Modal.Header>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SoftTypography>
                <b>Assign Counsellor</b>
              </SoftTypography>

              <CloseIcon size="small" onClick={() => setactionModal1(false)} />
            </Box>
          </Modal.Header>

          <Modal.Body>
            <SoftTypography
              sx={{ color: "black", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Select Counsellor
            </SoftTypography>
            <SoftBox display="flex" flexDirection="column">
              <SoftSelect
                placeholder="Select Counsellor"
                options={cosList}
                value={cosId}
                onChange={(e) =>
                  // jobpostform.setFieldValue("org_name", e.label),
                  setCosId(e)
                }
              />
            </SoftBox>
          </Modal.Body>
          <Modal.Footer className="apply-modal">
            <SoftBox
              // pl={2}
              sx={{
                height: "45px",
                backgroundColor: "#3B5092",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => {
                // setApplyModal(true);
                setactionModal1(false);
                handleConsPostData();
                // setStep1(false);
                // setStep2(true);
                // Swal.fire()
              }}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
              >
                Apply
              </SoftTypography>
            </SoftBox>
          </Modal.Footer>
        </Modal>
      </DashboardLayout>
    </>
  );
};

export default Channelpartner;
