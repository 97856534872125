import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftSelect from "../../../components/SoftSelect";
import SoftInput from "components/SoftInput";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import downArrow from "assets/icons/down_arrow.png";
import axios from "axios";
import { Table } from "ant-table-extensions";
// import { Table } from "antd";
import { Badge, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import SoftBadge from "components/SoftBadge";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Button, Card } from "@mui/material";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import refreshBtn from "../../../assets/images/refresh_btn.png";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import FadeLoader from "react-spinners/FadeLoader";
import { Line, Bar } from "react-chartjs-2";

import { faker } from "@faker-js/faker";
import { Box } from "@mui/system";
import EditPosting from "./editPosting";
import { Tooltip as Tool } from "@mui/material";
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual'
import { useDispatch } from 'react-redux'

const menu = (
  <Menu>
    <Menu.Item>Action 1</Menu.Item>
    <Menu.Item>Action 2</Menu.Item>
  </Menu>
);

const CoursePostings = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const registerID = sessionStorage.getItem("register_id");
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [KPIObj, setKPIObj] = useState({
    skiling_partners: "0",
    courses: "0",
    matched: "0",
    placed: "0",
  });
  const [neworgId, setNewOrdId] = useState("");
  const [name, setName] = useState("");
  const [jobsid, setJobsid] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [jobslist, setJobslist] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [newSearchData, setNewSearchData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [show, setShow] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [editadmin, Seteditadmin] = useState(false);
  const [editdata, Seteditdata] = useState({});
  const [newName, setNewName] = useState("");
  const [searchSet, setSearchSet] = useState(true);
  const [trendingJobs, setTrendingJobs] = useState([]);
  const [cpList, setCPList] = useState([]);
  const [assignedCPList, setAssignedCPList] = useState([]);
  const [cpId, setCPId] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [assignCount, setAssignCount] = useState("");
  const [tablerow, setTablerow] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [adminName, setAdminName] = useState("");
  const [adminId, setAdminId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [cpAssignPayload, setCPAssignPayload] = useState({
    jobId: "",
    orgId: "",
    candidates: "",
    jobRole: "",
  });
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };

  const filterOffsetFunc = () => {
    setPaginationState({ ...paginationState, page: 1 });
  };

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };
    useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);

  // const min = 0;
  // const max = cpAssignPayload.candidates;
  const callback = () => {
    // toggle(!isToggled);
    // onClick(!isToggled);
    console.log("toggle state1:", toggle);
    setToggle(!toggle);
  };
  const handleChange = (event) => {
    const value = Math.max(0, Math.min(cpAssignPayload.candidates, Number(event.target.value)));
    setAssignCount(value);
  };
  console.log("toggle state2:", toggle);

  const callback1 = () => {
    setAmt(0);
    setToggle1(!toggle1);
  };

  console.log("toggle state2:", toggle);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobprovider/dashboard/trending/jobs`
      );
      const data = await res.data;
      setTrendingJobs(data?.joblist);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchadminList = () => {
    axios
      .get(`${process.env.REACT_APP_DASHBOARD_URL}/admin/adminlist`, {
        params: {
          // limit: 10,
          limit: pageSize,
          offset: paginationState.page - 1,
          search_data: name,
        },
      })
      .then((res) => {
        setAdminList(
          res.data["Admin_List"]?.map((item, id) => {
            return {
              id: item.registration_id,
              label:
                item?.firstnm[0].toUpperCase() +
                item.firstnm.slice(1) +
                " " +
                item?.lastnm[0].toUpperCase() +
                item.lastnm.slice(1),
              value:
                item?.firstnm[0].toUpperCase() +
                item.firstnm.slice(1) +
                " " +
                item?.lastnm[0].toUpperCase() +
                item.lastnm.slice(1),
            };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchadminList();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };

  const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const chartdata = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const expandedRowRender = (row) => {
    console.log("row", row);
    // setRoleList([]);
    const columns = [
      {
        title: "Date",
        //dataIndex: "date",
        key: "date",
        sorter: {
          compare: (a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
          multiple: 1,
        },
        render: (text) => <>{text.date ? moment(text.date).format("DD-MM-YYYY") : "-"}</>,
      },
      {
        title: "Course Name",
        dataIndex: "Jobname",
        key: "Jobname",
        sorter: {
          compare: (a, b) => a?.Jobname?.localeCompare(b?.Jobname),
          multiple: 2,
        },
      },
      {
        title: "Course Duration",
        //dataIndex: "Jobtype",
        key: "Jobtype",
        sorter: {
          compare: (a, b) => a?.Jobtype - b?.Jobtype,
          multiple: 3,
        },
        render: (text) => (
          <>
            {text.Jobtype === "" || text.Jobtype === null || text.Jobtype === undefined
              ? "-"
              : text.Jobtype === 1
              ? `${text.Jobtype} day`
              : `${text.Jobtype} days`}
          </>
        ),
      },
      { title: "State", dataIndex: "state", key: "state" },
      { title: "District", dataIndex: "district", key: "district" },
      { title: "Taluka", dataIndex: "taluka", key: "taluka" },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        sorter: {
          compare: (a, b) => a?.address?.localeCompare(b?.address),
          multiple: 4,
        },
      },
      {
        title: "Course Status",
        render: (text, record) => (
          console.log(text),
          (
            <div
              className="dropdown"
              style={{
                width: "100%",
                height: "30px",
                borderRadius: "15px",
                backgroundColor: text.job_status === 1 ? "#BDFDC8" : "#EFD6FF",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  padding: "5px 0px 5px",
                }}
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                {text.job_status === 1 ? "Active" : "Deactivate"}
              </p>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() =>
                    text.job_status ? InactiveToggle(text.key) : activeToggle(text.key)
                  }
                >
                  {text.job_status !== 0 ? "Deactivate" : "Active"}
                </a>
              </div>
            </div>
          )
        ),
      },
      {
        title: "Action",
        render: (text, record) => (
          console.log("active toggle butn", text.key),
          (
            <div className="edit_icons">
              <div className="dropdown">
                <Button
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Icon icon="ion:ellipsis-vertical" color="black" />
                </Button>
                <ul
                  style={{
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    border: "0px",
                    padding: "3px",
                  }}
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <a
                        className="dropdown-item"
                        style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                        onClick={() => {
                          sessionStorage.setItem("course_id", text.key);
                          sessionStorage.setItem("org_id", text.orgid);
                          sessionStorage.setItem("course_name", text.Jobname);
                          sessionStorage.setItem("org_name", orgName);
                          sessionStorage.setItem("shortlisted_local", true);
                          navigate(
                            `/skilling-shortlisted?org_id=${text.orgid}&course_id=${text.key}`
                          );
                        }}
                      >
                        <div style={{ width: "20px" }}>
                          <Icon icon="gridicons:multiple-users" />
                        </div>
                        &nbsp; Shortlisted
                      </a>
                    </div>
                  </li>
                  {/* <li>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <a
                        className="dropdown-item"
                        style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                        onClick={() => {
                          sessionStorage.setItem("course_id", text.key);
                          sessionStorage.setItem("searchdata_org_name", orgName);
                          sessionStorage.setItem("searchdata_org_id", text.orgid);
                          sessionStorage.setItem("searchdata_designation", text.designation);
                          sessionStorage.setItem("searchdata_salary", text.salary);
                          sessionStorage.setItem("searchdata_state", text.state);
                          sessionStorage.setItem("searchdata_district", text.district);
                          sessionStorage.setItem("searchdata_qualification", text.qualification);
                          sessionStorage.setItem("searchdata_experience", text.experience);
                          sessionStorage.setItem("searchdata_stream", text.stream);
                          sessionStorage.setItem("searchdata_specialization", text.specialization);
                          navigate("/searchbyposting/createposting/recommendedPostList");
                        }}
                      >
                        <div style={{ width: "20px" }}>
                          <Icon icon="material-symbols:search" />
                        </div>
                        &nbsp; Search
                      </a>
                    </div>
                  </li> */}
                  <li>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <a
                        href="#editcourse"
                        className="dropdown-item"
                        style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                        onClick={() => {
                          Seteditadmin(true);
                          Seteditdata(text);
                        }}
                      >
                        <div style={{ width: "20px" }}>
                          <Icon icon="mdi:lead-pencil" />
                        </div>
                        &nbsp;Edit Course
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )
        ),
      },
    ];

    setNewOrdId(row.key);
    setOrgName(row.organization);
    // const fetchOgrRole = () => {
    //   const params = {
    //     // orgid: orgId,
    //     orgid: row.key,
    //     type: "detail",
    //   };
    //   const paramString = new URLSearchParams(params).toString();
    //   axios
    //     // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
    //     .get(
    //       `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?${paramString}`
    //     )
    //     .then((res) => {
    //       // setDistrict(res.data);
    //       console.log("Role tabel", res.data["JobPost_List"]);
    //       setRoleList(res.data["JobPost_List"]);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i,
        date: "7-8-22",
        Jobname: "House Keeping",
        Jobtype: "Full Time",
        reqirement: "56",
        location: "location",
      });
    }
    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    // useEffect(() => {
    //   fetchOgrRole();
    // }, [orgId]);

    return (
      <Table
        // loading={subTable.length === 0 ? true : false}
        loading={loading}
        columns={columns}
        // dataSource={data}
        dataSource={subTable}
        // pagination={false}
        rowSelection={rowSelection}
        searchableProps={{ fuzzySearch: true }}
        exportableProps={{ showColumnPicker: true }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100", "500"],
          showTotal: (total, range) => (
            <span>
              Showing{" "}
              <strong>
                {range[0]} - {range[1]}
              </strong>{" "}
              results of {total}
            </span>
          ),
        }}
        // onRow={(record, rowIndex) => {
        //   console.log(`record:${rowIndex}`, record);
        //   return expandedRowRender;
        //   // return expandedRowRender;
        //   // onClick: () => {
        //   //   console.log(`record:${rowIndex}`, record);
        //   //   return expandedRowRender;
        //   // };
        // }}
      />
    );
  };

  const [colHeaders, setColHeaders] = useState([
    {
      label: "Date",
      key: "date",
      isExportable: true,
    },
    {
      label: "Course Name",
      key: "Jobname",
      isExportable: true,
    },
    {
      label: "Institute",
      key: "org_name",
      isExportable: true,
    },
    {
      label: "Course Duration",
      key: "Jobtype",
      isExportable: true,
    },
    {
      label: "Start Date",
      key: "start_date",
      isExportable: true,
    },
    {
      label: "Age",
      key: "age",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Taluka",
      key: "taluka",
      isExportable: true,
    },
    {
      label: "Address",
      key: "address",
      isExportable: true,
    },
    {
      label: "Qualification Required",
      key: "qualification",
      isExportable: true,
    },
    {
      label: "Mode of Course",
      key: "mode_of_course",
      isExportable: true,
    },
    {
      label: "Course Fee",
      key: "fee",
      isExportable: true,
    },
    {
      label: "SPOC Name",
      key: "spoc_name",
      isExportable: true,
    },
    {
      label: "SPOC Email",
      key: "spoc_email",
      isExportable: true,
    },
    {
      label: "SPOC Mobile",
      key: "spoc_phone",
      isExportable: true,
    },
    {
      label: "Course Status",
      key: "job_status",
      isExportable: true,
    },
  ]);

  const colName = [
    {
      title: "Organisation",
      // dataIndex: "organization",
      sorter: {
        compare: (a, b) => a?.organization?.localeCompare(b?.organization),
        multiple: 1,
      },
      render: (text, record) => (
        // sessionStorage.setItem()
        <>{text.organization}</>
      ),
    },
    {
      title: "SPOC Name",
      dataIndex: "createdby",
      sorter: {
        compare: (a, b) => a?.createdby?.localeCompare(b?.createdby),
        multiple: 2,
      },
    },
    // {
    //   title: "Role Access",
    //   dataIndex: "roleacess",
    //   sorter: {
    //     compare: (a, b) => a.location - b.location,
    //     multiple: 2,
    //   },
    // },
    {
      title: "Date Of Association",
      dataIndex: "doa",
      sorter: {
        compare: (a, b) => moment(a.doa, "DD-MM-YYYY") - moment(b.doa, "DD-MM-YYYY"),
        multiple: 3,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      sorter: {
        compare: (a, b) => a.mobnum - b.mobnum,
        multiple: 4,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 5,
      },
    },
  ];
  const colNameNew = [
    {
      title: "Date",
      //dataIndex: "date",
      fixed: "left",
      width: 200,
      key: "date",
      sorter: {
        compare: (a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
        multiple: 1,
      },
      render: (text) => <>{text.date ? moment(text.date).format("DD-MM-YYYY") : "-"}</>,
    },
    {
      title: "Course Name",
      fixed: "left",
      width: 200,
      dataIndex: "Jobname",
      key: "Jobname",
      sorter: {
        compare: (a, b) => a?.Jobname?.localeCompare(b?.Jobname),
        multiple: 2,
      },
    },
    {
      title: "Institute",
      width: 200,
      dataIndex: "org_name",
      key: "org_name",
      sorter: {
        compare: (a, b) => a?.org_name?.localeCompare(b?.org_name),
        multiple: 3,
      },
    },
    {
      title: "Course Duration",
      width: 200,
      //dataIndex: "Jobtype",
      key: "Jobtype",
      sorter: {
        compare: (a, b) => a?.Jobtype - b?.Jobtype,
        multiple: 4,
      },
      render: (text) => (
        <>
          {text.Jobtype === "" || text.Jobtype === null || text.Jobtype === undefined
            ? "-"
            : text.Jobtype === 1
            ? `${text.Jobtype} day`
            : `${text.Jobtype} days`}
        </>
      ),
    },
    {
      title: "Start Date",
      //dataIndex: "date",
      width: 200,
      key: "start_date",
      sorter: {
        compare: (a, b) => moment(a.start_date, "DD-MM-YYYY") - moment(b.start_date, "DD-MM-YYYY"),
        multiple: 8,
      },
      render: (text) => <>{text.start_date ? moment(text.start_date).format("DD-MM-YYYY") : "-"}</>,
    },
    {
      title: "Age",
      width: 200,
      dataIndex: "age",
      key: "age",
      sorter: {
        compare: (a, b) => a?.age?.localeCompare(b?.age),
        multiple: 12,
      },
    },
    { title: "State", width: 200, dataIndex: "state", key: "state" },
    { title: "District", width: 200, dataIndex: "district", key: "district" },
    { title: "Taluka", width: 200, dataIndex: "taluka", key: "taluka" },
    {
      title: "Address",
      width: 200,
      dataIndex: "address",
      key: "address",
      sorter: {
        compare: (a, b) => a?.address?.localeCompare(b?.address),
        multiple: 4,
      },
    },
    {
      title: "Qualification Required",
      width: 200,
      dataIndex: "qualification",
      key: "qualification",
      sorter: {
        compare: (a, b) => a?.qualification?.localeCompare(b?.qualification),
        multiple: 5,
      },
    },
    {
      title: "Mode of Course",
      width: 200,
      dataIndex: "mode_of_course",
      key: "mode_of_course",
      sorter: {
        compare: (a, b) => a?.mode_of_course?.localeCompare(b?.mode_of_course),
        multiple: 6,
      },
    },
    {
      title: "Course Fee",
      width: 200,
      // dataIndex: "fee",
      key: "fee",
      sorter: {
        compare: (a, b) => a?.fee?.localeCompare(b?.fee),
        multiple: 7,
      },

      render: (text) => <>&#x20B9; {text.fee ? `${text.fee}` : " - "} </>,
    },
    {
      title: "SPOC Name",
      width: 200,
      dataIndex: "spoc_name",
      key: "spoc_name",
      sorter: {
        compare: (a, b) => a?.spoc_name?.localeCompare(b?.spoc_name),
        multiple: 9,
      },
    },
    {
      title: "SPOC Email",
      width: 200,
      dataIndex: "spoc_email",
      key: "spoc_email",
      sorter: {
        compare: (a, b) => a?.spoc_email?.localeCompare(b?.spoc_email),
        multiple: 10,
      },
    },
    {
      title: "SPOC Mobile",
      width: 200,
      dataIndex: "spoc_phone",
      key: "spoc_phone",
      sorter: {
        compare: (a, b) => a?.spoc_phone?.localeCompare(b?.spoc_phone),
        multiple: 11,
      },
    },
    {
      title: "Course Status",
      width: 200,
      render: (text, record) => (
        console.log(text),
        (
          <div
            className="dropdown"
            style={{
              width: "100%",
              height: "30px",
              borderRadius: "15px",
              backgroundColor: text.job_status === 1 ? "#BDFDC8" : "#EFD6FF",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                padding: "5px 0px 5px",
              }}
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              {text.job_status === 1 ? "Active" : "Deactivate"}
            </p>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={() =>
                  text.job_status ? InactiveToggle(text.key) : activeToggle(text.key)
                }
              >
                {text.job_status !== 0 ? "Deactivate" : "Active"}
              </a>
            </div>
          </div>
        )
      ),
    },
    {
      title: "Action",
      width: 150,
      render: (text, record) => (
        console.log("active toggle butn", text.key),
        (
          <div className="edit_icons">
            <div className="dropdown">
              <Button
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Icon icon="ion:ellipsis-vertical" color="black" />
              </Button>
              <ul
                style={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  border: "0px",
                  padding: "3px",
                }}
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <a
                      className="dropdown-item"
                      style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                      onClick={() => {
                        sessionStorage.setItem("course_id", text.key);
                        sessionStorage.setItem("org_id", text.orgid);
                        sessionStorage.setItem("course_name", text.Jobname);
                        sessionStorage.setItem("org_name", text.org_name);
                        sessionStorage.setItem("shortlisted_local", true);
                        navigate(
                          `/skilling-shortlisted?org_id=${text.orgid}&course_id=${text.key}`
                        );
                      }}
                    >
                      <div style={{ width: "20px" }}>
                        <Icon icon="gridicons:multiple-users" />
                      </div>
                      &nbsp; Shortlisted
                    </a>
                  </div>
                </li>
                {/* <li>
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <a
                      className="dropdown-item"
                      style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                      onClick={() => {
                        sessionStorage.setItem("course_id", text.key);
                        sessionStorage.setItem("searchdata_org_name", orgName);
                        sessionStorage.setItem("searchdata_org_id", text.orgid);
                        sessionStorage.setItem("searchdata_designation", text.designation);
                        sessionStorage.setItem("searchdata_salary", text.salary);
                        sessionStorage.setItem("searchdata_state", text.state);
                        sessionStorage.setItem("searchdata_district", text.district);
                        sessionStorage.setItem("searchdata_qualification", text.qualification);
                        sessionStorage.setItem("searchdata_experience", text.experience);
                        sessionStorage.setItem("searchdata_stream", text.stream);
                        sessionStorage.setItem("searchdata_specialization", text.specialization);
                        navigate("/searchbyposting/createposting/recommendedPostList");
                      }}
                    >
                      <div style={{ width: "20px" }}>
                        <Icon icon="material-symbols:search" />
                      </div>
                      &nbsp; Search
                    </a>
                  </div>
                </li> */}
                <li>
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <a
                      href="#editcourse"
                      className="dropdown-item"
                      style={{ display: "flex", fontFamily: "Open Sans", fontSize: "14px" }}
                      onClick={() => {
                        Seteditadmin(true);
                        Seteditdata(text);
                      }}
                    >
                      <div style={{ width: "20px" }}>
                        <Icon icon="mdi:lead-pencil" />
                      </div>
                      &nbsp;Edit Course
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )
      ),
    },
  ];
  const cpColumns = [
    {
      title: "Organization",
      dataIndex: "organization",
      width: 200,
      sorter: {
        compare: (a, b) => a?.organization?.localeCompare(b?.organization),
        multiple: 1,
      },
      // render: (text, record) => (
      //   // sessionStorage.setItem()
      //   <>{text.name}</>
      // ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
        multiple: 2,
      },
    },
    {
      title: "Date Of Acceptance",
      dataIndex: "accepteddt",
      width: 150,
      sorter: {
        compare: (a, b) => moment(a.accepteddt, "DD-MM-YYYY") - moment(b.accepteddt, "DD-MM-YYYY"),
        multiple: 3,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      width: 150,
      sorter: {
        compare: (a, b) => a.mobile - b.mobile,
        multiple: 4,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 5,
      },
    },
    {
      title: "Word Order ID",
      dataIndex: "work_order_id",
      width: 200,
      // sorter: {
      //   compare: (a, b) => a.work_order_id - b.work_order_id,
      //   multiple: 6,
      // },
    },
    {
      title: "Status",
      // dataIndex: "status",
      width: 200,
      // sorter: {
      //   compare: (a, b) => a.email - b.email,
      //   multiple: 5,
      // },
      render: (text, record) => (
        // sessionStorage.setItem()

        <span>
          <SoftBadge
            variant="contained"
            color={
              text.status === "accepted" ? "success" : text.status === "declined" ? "error" : "info"
            }
            size="lg"
            badgeContent={
              text.status === "accepted"
                ? "Accepted"
                : text.status === "declined"
                ? "Declined"
                : "Requested"
            }
            container
          />
        </span>
      ),
    },
    {
      title: "Candidates Shared",
      dataIndex: "uploaded_cand",
      width: 200,
      sorter: {
        compare: (a, b) => a.uploaded_cand - b.uploaded_cand,
        multiple: 6,
      },
    },
  ];

  const data = [];
  for (let index = 0; index < 50; index++) {
    data.push({
      key: index,
      organization: `organization${index}`,
      roleacess: `Admin`,
      doa: `${index}-1-2023`,
      mobnum: `1234567${index}`,
      email: `${index}h@ty.com`,
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [download, Setdownload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amt, setAmt] = useState("");

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log(newSelectedRowKeys);
    setTablerow(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
    Setdownload(
      selectedRows?.map((item, index) => {
        return {
          date_of_creation: item.date_of_creation,
          email: item.email,
          isActive: item.isActive,
          key: item.key,
          location: item.location,
          name: item.name,
          organization: item.organization,
          phone_number: item.phone_number,
          registration_id: item.registration_id,
        };
      })
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };
  const fetchJobPostKpi = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/kpi_superadmin`,{
        params: {
                created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        },
      })
      .then((res) => {
        console.log("all kpi", res.data);
        setKPIObj({
          skiling_partners: res.data.total_skilling_partner,
          courses: res.data.total_courses,
          matched: res.data.matched_candidates,
          placed: res.data.total_placed,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const fetchOgrRole = () => {
    const params = {
      // orgid: expandedRowKeys[0],
      orgid: "",
    };
    const paramString = new URLSearchParams(params).toString();
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_register`,{
        params: {
                created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        },
      })
      .then((res) => {
        setLoading(false);
        console.log("Role tabel", res?.data);
        setRoleList(
          res?.data.map((ele, id) => {
            return {
              ...ele,
              age_upper_range: ele.age_upper_range !== null ? ele.age_upper_range : "",
              age_lower_range: ele.age_lower_range !== null ? ele.age_lower_range : "",
              age:
                ele.age_upper_range !== null && ele.age_lower_range !== null
                  ? `${ele.age_lower_range}  to  ${ele.age_upper_range}`
                  : " - ",
            };
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const fetchOrgList = () => {
    const params = {
      // partner: "",
      // type: "dropdown",
      type: "detail",
    };
    // const headers = {
    //   "access-control-allow-origin": "*",
    //   "Content-type": "application/json; charset=UTF-8",
    // };
    const paramString = new URLSearchParams(params).toString();
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`,
        {
          params: {
            // limit: 10,
            limit: pageSize,
            offset: paginationState.page - 1,
            search_data: name,
          },
        }
        // headers
      )
      .then((res) => {
        setLoading(false);
        console.log(
          "org list",
          res.data["Organisation_Detail"].filter((v) => v.role_access === "Skilling Institute")
        );
        setOrgList(
          res.data["Organisation_Detail"]
            ?.filter((v) => v.role_access === "Skilling Institute")
            ?.map((ele) => {
              return {
                key: ele.registration_id,
                organization: ele.org_name,
                doa: moment(ele.createddt).format("DD-MM-YYYY"),
                mobnum: ele?.mobnum?.length === 12 ? ele.mobnum.substring(2) : ele.mobnum,
                email: ele.email,
                state: ele.state,
                district: ele.district,
                createdby: `${ele?.firstnm} ${ele?.lastnm}`,
                role_access: ele.role_access,
                taluka: ele.taluka,
                address: ele.address,
                // createdby: `${ele?.firstnm[0].toUpperCase() + ele?.firstnm.slice(1)} ${ele?.lastnm[0].toUpperCase() + ele?.lastnm.slice(1)
                //   }`,
              };
            })
        );
        setSearchData(
          res.data["Organisation_Detail"]
            ?.filter((v) => v.role_access === "Skilling Institute")
            ?.map((ele) => {
              return {
                key: ele.registration_id,
                organization: ele.org_name,
                doa: moment(ele.createddt).format("DD-MM-YYYY"),
                mobnum: ele?.mobnum?.length === 12 ? ele.mobnum.substring(2) : ele.mobnum,
                email: ele.email,
                taluka: ele.taluka,
                address: ele.address,
                state: ele.state,
                district: ele.district,
                createdby: `${ele?.firstnm} ${ele?.lastnm}`,
              };
            })
        );
        setPaginationState({ ...paginationState, dataSize: res.data["count"] });
      })
      .catch((err) => {
        console.log(err);
        //setLoading(false)
      });
  };

  console.log(jobs, "ooo");

  useEffect(() => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobtitle`,
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`, {
        params: {
          // org_name: orgNameID,
          orgid: companyId,
          type: "dropdown",
        },
      })
      .then((res) => {
        setJobslist(res.data["JobPost_List"]);
      });
  }, [companyId]);

  const activeToggle = (value) => {
    console.log("active toggle");
    axios
      .patch(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_register`, {
        course_id: value,
        active: 1,
        created_by: sessionStorage.getItem("register_id")
        ? sessionStorage.getItem("register_id")
        : "",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Activated successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchOgrRole();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err?.response?.data["Developer Message"]
            : "Something went wrong!",
          text: err?.response?.data ? err?.response?.data["User Message"] : "Something went wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const InactiveToggle = (value) => {
    console.log("active toggle");
    axios
      .patch(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_register`, {
        course_id: value,
        active: 0,
        created_by: sessionStorage.getItem("register_id")
        ? sessionStorage.getItem("register_id")
        : "",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Deactivated successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchOgrRole();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err?.response?.data["Developer Message"]
            : "Something went wrong!",
          text: err?.response?.data ? err?.response?.data["User Message"] : "Something went wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const fetchShortlistedCandidate = () => {
    const params = {
      orgid: neworgId,
      jobid: "",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/shortlistedcandidate?${paramString}`
      )
      .then((res) => {
        // setDistrict(res.data);
        console.log("Role tabel", res.data["JobPost_List"]);
        //  setRoleList(res.data["JobPost_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchAssignedCPList = async (data) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/dashboard/cp/details`, {
        params: {
          jobid: data.key,
        },
      })
      .then((res) => {
        console.log("list:", res.data);
        setAssignedCPList(
          res.data["cp_details"]?.map((ele, id) => ({
            key: ele.cp_id,
            organization: ele.cp_org_name,
            name: ele.cp_first_name + " " + ele.cp_last_name,
            firstnm: ele.cp_first_name,
            lastnm: ele.cp_last_name,
            jobpost_id: ele.jobpost_id,
            accepteddt: ele.accepteddt ? moment(ele.accepteddt).format("DD-MM-YYYY") : " - ",
            declineddt: ele.declineddt ? moment(ele.declineddt).format("DD-MM-YYYY") : " - ",
            modifieddt: ele.modifieddt ? moment(ele.modifieddt).format("DD-MM-YYYY") : " - ",
            uploaded_cand: ele.uploaded_cand,
            mobile: ele.cp_mobile,
            email: ele.cp_email,
            work_order_id: ele.work_order_id ? ele.work_order_id : " - ",
            status: ele.request_status,
            candidates: ele.candidate_count,
            candidate_list: ele.candidate_list,
          }))
        );
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };

  const cpListFunc = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/account/details`)
      .then((res) => {
        console.log("dd for :", res.data["cp_data"]);
        setCPList(res.data["cp_data"]);
      })
      .catch((err) => {
        console.log("dd for err:", err.response.data);
      });
  };

  const AssignAdmin = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/transactions/request/job`,
        {
          jobid: "",
          org_id: "",
        }
      )
      .then((res) => {
        // console.log("dd for :", res.data["cp_data"]);
        // setCPList(res.data["cp_data"]);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            // text: res.data["User Message"],
            title: "Admin Assigned Successfully",
            showConfirmButton: false,
            //position: "top",
            timer: 3000,
          }).then(() => {
            setModal4(false);
            setToggle(false);
            setAdminId("");
            setAdminName("");
            setJobs([]);
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong!",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const AssignCP = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/transactions/request/job`,
        {
          jobid: cpAssignPayload.jobId,
          org_id: cpAssignPayload.orgId,
          cp_id: cpId.map((ele) => ele.value).join(","),
          requested_by: registerID,
          request_status: "Requested",
          candidate_count: cpAssignPayload.candidates,
          cost: amt !== "" ? amt : "0",
          candidate_required: toggle ? assignCount : cpAssignPayload.candidates,
        }
      )
      .then((res) => {
        // console.log("dd for :", res.data["cp_data"]);
        // setCPList(res.data["cp_data"]);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            //title: "Success",
            // text: res.data["User Message"],
            title: "Channel Partner Assigned Successfully",
            showConfirmButton: false,
            //position: "top",
            timer: 3000,
          }).then(() => {
            // navigate("/shortlisted");
            setModal2(false);
            setToggle(false);
            setCPAssignPayload({
              jobId: "",
              candidates: "",
              orgId: "",
              jobRole: "",
            });
            setCPId([]);
            setAmt("");
            setAssignCount("");
          });
        }
      })
      .catch((err) => {
        console.log("dd for err:", err.response.data);
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  console.log("after assign:", cpAssignPayload);
  const handleSearch = () => {
    const newData = orgList.filter((ele) => {
      return newName?.toLocaleLowerCase() === ""
        ? ele
        : ele.organization?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
          newName?.toLocaleLowerCase() === ""
        ? ele
        : ele.createdby?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
          newName?.toLocaleLowerCase() === ""
        ? ele
        : String(ele.mobnum?.toLocaleLowerCase()).includes(String(newName.toLocaleLowerCase()));
    });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch();
  }, [newName]);

  useEffect(() => {
    fetchJobPostKpi();
    // fetchOrgList();
    fetchOgrRole();
    getData();
    cpListFunc();
  }, []);

  // useEffect(() => {
  //   expandedRowKeys[0] !== undefined && fetchOgrRole();
  // }, [expandedRowKeys[0]]);
  // useEffect(() => {
  //   fetchAssignedCPList();
  // }, [modal3 === true]);

  const mainTable = orgList?.map((ele, i) => {
    return {
      key: ele.registration_id,
      organization: ele.org_name,
      doa: moment(ele.createddt).format("DD-MM-YYYY"),
      mobnum: ele.mobnum,
      email: ele.email,
      state: ele.state,
      district: ele.district,
    };
  });
  const subTable = roleList?.map((ele, i) => {
    return {
      key: ele.course_id,
      date: ele.createddt,
      address: ele.address === "" || ele.address === null ? " - " : ele.address,
      taluka: ele.taluka === "" || ele.taluka === null ? " - " : ele.taluka,
      Jobname: ele.name === "" || ele.name === null ? " - " : ele.name,
      Jobtype: ele.duration,
      reqirement: ele.applicantct,
      job_status: ele.active,
      state: ele.state === "" || ele.state === null ? " - " : ele.state,
      district: ele.district === "" || ele.district === null ? " - " : ele.district,
      designation: ele.designation === "" || ele.designation === null ? " - " : ele.designation,
      experience: ele.experience === "" || ele.experience === null ? " - " : ele.experience,
      orgid: ele.orgid,
      qualification:
        ele.highest_qualification === "" || ele.highest_qualification == null
          ? " - "
          : ele.highest_qualification,
      salary: ele.mode_of_course === "" || ele.mode_of_course === null ? " - " : ele.mode_of_course,
      // : ele.mode_of_course.charAt(0).toUpperCase() + ele.mode_of_course.slice(1),
      specialization:
        ele.specialization === "" || ele.specialization === null ? " - " : ele.specialization,
      stream: ele.stream === "" || ele.stream === null ? " - " : ele.stream,
      course_id: ele.course_id === "" || ele.course_id === null ? " - " : ele.course_id,
      org_name: ele.org_name === "" || ele.org_name === null ? " - " : ele.org_name,
      gender: ele.gender === "" || ele.gender === null ? " - " : ele.gender,
      jobvaliddt: ele.jobvaliddt,
      industry: ele.industry === "" || ele.industry === null ? " - " : ele.industry,
      skills: ele.skills === "" || ele.skills === null ? " - " : ele.skills,
      workfromhome: ele.workfromhome === "" || ele.workfromhome === null ? " - " : ele.workfromhome,
      jobdesc: ele.description === "" || ele.description === null ? " - " : ele.description,
      // age: `${ele.age_lower_range} to ${ele.age_upper_range}`,
      age: ele.age,
      is_active: ele.is_active,
      whatsapp_jd: ele.whatsapp_jd,
      start_date: ele.start_date,
      fee: ele.fees,
      application: ele.brochure,
      spoc_name: ele.spoc_name === "" || ele.spoc_name === null ? " - " : ele.spoc_name,
      spoc_email: ele.spoc_email === "" || ele.spoc_email === null ? " - " : ele.spoc_email,
      spoc_phone: ele.spoc_mobile === "" || ele.spoc_mobile === null ? " - " : ele.spoc_mobile,
      mode_of_course:
        ele.mode_of_course === "" || ele.mode_of_course === null ? " - " : ele.mode_of_course,
    };
  });

  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };
  const onTableRowExpand = async (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.key); // I have set my record.id as row key. Check the documentation for more details.
    }
    // fetchOgrRole(expandedRowKeys[0]);
    await setExpandedRowKeys(keys);
  };
  console.log(expandedRowKeys[0], "record2");

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              {translate('allcourses')}       
              </SoftTypography>
            </SoftBox>

            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#FFF",
                borderRadius: "2px",
                cursor: "pointer",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              width="40px"
              onClick={() => {
                // fetchOrgList();
                fetchOgrRole();
                fetchJobPostKpi();
              }}
            >
              <SoftBox>
                <img src={refreshBtn} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mt={2}
          >
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                sx={{ minHeight: "65px", alignItems: "center" }}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {KPIObj.skiling_partners === "" ? (
                    <FadeLoader color="#FFFFFF" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.skiling_partners
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={3} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
              {translate('totalskiling')}       
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                sx={{ minHeight: "65px", alignItems: "center" }}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 2050 */}
                  {KPIObj.courses === "" ? (
                    <FadeLoader color="#FFFFFF" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.courses
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={3} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
              {translate('totalcourses')}       
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                sx={{ minHeight: "65px", alignItems: "center" }}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 160 */}
                  {KPIObj.matched === "" ? (
                    <FadeLoader color="#FFFFFF" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.matched
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={3} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
              {translate('matchcandidates')}       
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="24%"
              display=""
              sx={{
                height: "30%",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
                sx={{ minHeight: "65px", alignItems: "center" }}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="30px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  {/* 250 */}
                  {KPIObj.placed === "" ? (
                    <FadeLoader color="#FFFFFF" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.placed
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="" mt={3} mb={2}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="15px"
                  ml={2}
                  sx={{ color: "#fff" }}
                >
              {translate('candidatesplaced')}       
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2}>
            <SoftBox display="flex" flexDirection="row" justifyContent="space-between">
              <SoftBox width={{ lg: "500px", md: "400px" }}>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="10px"
                  justifyContent="space-between"
                >
                  <SoftInput
                    isClearable
                    // width={{ lg: "1000px", md: "600px" }}
                    // width="1000px"
                    id="search-bar"
                    className="text"
                    // onInput={(e) => {
                    //   setSearchQuery(e.target.value);
                    // }}
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    variant="outlined"
                    placeholder="Search by course name, institute name, SPOC name or phone number"
                    size="small"
                  />
                </SoftBox>
              </SoftBox>

              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox
                  sx={{
                    height: "33px",
                    backgroundColor: "#3B5092",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="40px"
                  onClick={() => navigate("/searchbyposting/createcourse")}
                >
                  <SoftBox sx={{ color: "#FFF" }}>+</SoftBox>
                </SoftBox>

                <Tool
                  key="1"
                  title={tablerow.length === 0 ? "Download All" : "Download"}
                  placement="top"
                >
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#000000",
                      borderRadius: "2px",
                      cursor: "pointer",
                      ml: "8px",
                      mr: "8px",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => (
                      setShow(!show),
                      // setFileName(() => {
                      //   getFileName();
                      // })
                      getFileName()
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="34"
                      viewBox="0 0 46 34"
                      fill="none"
                    >
                      <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                      <path
                        d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.9531 16.5098V23.0102"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </Tool>

                {/*
                <Tool key="1" title="Filter off" placement="top">
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => {
                      setNewName("");
                      setSearchSet(true);
                    }}
                  >
                    <SoftBox>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="39"
                        height="34"
                        viewBox="0 0 39 34"
                        fill="none"
                      >
                        <rect width="38.8889" height="34" rx="2" fill="white" />
                        <path
                          d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                          stroke="#A4A4A4"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </SoftBox>
                  </SoftBox>
                </Tool>
                */}
              </SoftBox>
            </SoftBox>

            <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="100%">
              <Table
                // loading={subTable.length === 0 ? true : false}
                loading={loading}
                columns={colNameNew}
                // dataSource={data}
                dataSource={subTable.filter((ele) => {
                  return newName?.toLocaleLowerCase() === ""
                    ? ele
                    : ele.Jobname?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
                      newName?.toLocaleLowerCase() === ""
                    ? ele
                    : ele.org_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
                      newName?.toLocaleLowerCase() === ""
                    ? ele
                    : ele.spoc_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
                      newName?.toLocaleLowerCase() === ""
                    ? ele
                    : String(ele.spoc_phone?.toLocaleLowerCase()).includes(
                        String(newName.toLocaleLowerCase())
                      );
                })}
                // pagination={false}
                rowSelection={rowSelection}
                // searchableProps={{ fuzzySearch: true }}
                // exportableProps={{ showColumnPicker: true }}
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100", "500"],
                  showTotal: (total, range) => (
                    <span>
                      Showing{" "}
                      <strong>
                        {range[0]} - {range[1]}
                      </strong>{" "}
                      results of {total}
                    </span>
                  ),
                }}
                scroll={{ x: 2500 }}
                // onRow={(record, rowIndex) => {
                //   console.log(`record:${rowIndex}`, record);
                //   return expandedRowRender;
                //   // return expandedRowRender;
                //   // onClick: () => {
                //   //   console.log(`record:${rowIndex}`, record);
                //   //   return expandedRowRender;
                //   // };
                // }}
              />
              {/* <Table
                // {...tableProps}
                ///loading={mainTable.length === 0 ? true : false}
                loading={loading}
                rowSelection={rowSelection}
                columns={colName}
                dataSource={searchData.filter((ele) => {
                  return newName?.toLocaleLowerCase() === ""
                    ? ele
                    : ele.organization?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
                      newName?.toLocaleLowerCase() === ""
                    ? ele
                    : ele.createdby?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
                      newName?.toLocaleLowerCase() === ""
                    ? ele
                    : String(ele.mobnum?.toLocaleLowerCase()).includes(
                        String(newName.toLocaleLowerCase())
                      );
                })}
                pagination={{
                  // defaultPageSize: ,
                  // position: ["none", "bottomCenter"],
                  pageSizeOptions: ["10", "20", "50", "100", "500"],
                  total: paginationState.dataSize,
                  showSizeChanger: true,
                  // onShowSizeChange: { onShowSizeChange },
                  onShowSizeChange: handlePageSizeChange,
                  current: paginationState.page,
                  onChange: (page, pageSize) =>
                    setPaginationState({ ...paginationState, page: page }),
                  showTotal: (total, range) => (
                    <span>
                      Showing{" "}
                      <strong>
                        {range[0]} - {range[1]}
                      </strong>{" "}
                      results of {total}
                    </span>
                  ),
                }}
                scroll={{ y: 500 }}
                expandable={{
                  expandedRowKeys: expandedRowKeys,
                  // defaultExpandedRowKeys: ["0"],
                  expandedRowRender,
                  onExpand: onTableRowExpand,
                }}
              /> */}
            </SoftBox>
            <EditPosting
              editadmin={editadmin}
              Seteditadmin={Seteditadmin}
              editdata={editdata}
              handleOrgFunc={fetchOrgList}
              handleRoleFunc={fetchOgrRole}
              // fetchadminList={fetchOgrRole}
            />
          </SoftBox>
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
          style={{ margin: "0px" }}
        >
          {/*<Modal.Header closeButton></Modal.Header>*/}
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {colHeaders.map((ele, index) => (
                <>
                  <ul key={index} className="UlCSV">
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={tablerow.length === 0 ? subTable : tablerow}
                    // filename={"my-file.csv"}
                    filename={fileName}
                    headers={colHeaders.filter((ele) => ele.isExportable === true)}
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>

        <Modal
          show={modal4}
          onHide={() => {
            setModal4(false);
          }}
          centered
          style={{ margin: "0px" }}
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <SoftTypography fontWeight="medium" fontSize="20px">
                  Assign Admin
                </SoftTypography>

                <SoftBox
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  onClick={() => {
                    setModal4(false);
                    setJobs([]);
                    setAdminName("");
                    setAdminId("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9.16992 14.8299L14.8299 9.16992"
                      stroke="#3A3A3A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.8299 14.8299L9.16992 9.16992"
                      stroke="#3A3A3A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      stroke="#3A3A3A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SoftBox>
              </SoftBox>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <SoftBox sx={{ width: "200px", display: "flex", justifyContent: "flex-end" }}>
                  <SoftTypography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "small",
                      lineHeight: "19px",
                      mr: 0.5,
                    }}
                  >
                    Select Admin:
                  </SoftTypography>
                </SoftBox>

                <SoftBox display="flex" flexDirection="column" sx={{ width: "100%" }}>
                  <Select
                    placeholder="Select Admin"
                    options={adminList}
                    styles={stylesDD}
                    value={adminName !== "" ? { label: adminName, value: adminName } : null}
                    onChange={(e) => {
                      setAdminName(e ? e.value : "");
                      setAdminId(e ? e.id : "");
                    }}
                  />
                </SoftBox>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <SoftBox sx={{ width: "200px", display: "flex", justifyContent: "flex-end" }}>
                  <SoftTypography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "small",
                      lineHeight: "19px",
                      mr: 0.5,
                    }}
                  >
                    Select Job Role(s):
                  </SoftTypography>
                </SoftBox>

                <SoftBox display="flex" flexDirection="column" sx={{ width: "100%" }}>
                  <Select
                    isMulti
                    placeholder="Select Job Role(s)"
                    options={jobslist}
                    styles={stylesDD}
                    onChange={(e) => {
                      if (e) {
                        console.log(e);
                        setJobs(e.map((item) => item));
                      } else {
                        setJobs([]);
                      }
                    }}
                  />
                </SoftBox>
              </Box>

              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ ml: "auto", mr: "auto", mt: 1 }}
              >
                <Box sx={{ p: 1 }}>
                  <SoftBox
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor:
                        jobs.length === 0 || adminName === "" || adminId === ""
                          ? "no-drop"
                          : "pointer",
                    }}
                    onClick={() =>
                      jobs?.length === 0 || adminName === "" || adminId === ""
                        ? console.log("")
                        : AssignAdmin()
                    }
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Assign
                    </SoftTypography>
                  </SoftBox>
                </Box>

                <Box sx={{ p: 1 }}>
                  <SoftBox
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #3B5092",
                      borderRadius: "5px",
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setModal4(false);
                      setJobs([]);
                      setAdminName("");
                      setAdminId("");
                    }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Box>
              </SoftBox>
            </SoftBox>
          </Modal.Body>
        </Modal>
      </DashboardLayout>
    </>
  );
};

export default CoursePostings;
