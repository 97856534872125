import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { Box, Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PropTypes from "prop-types";
import Select from "react-select";

const JobseekerEdit = (props) => {
  console.log(props, "abc");
  const [resumefile, setResumeFile] = useState("");
  const [questions, setQuestions] = useState([]);
  const [centerOwnerList, setCenterOwnerList] = useState([]);
  const [selectCenterOwner, setSelectCenterOwner] = useState("");
  const [selectProjectOwner, setSelectProjectOwner] = useState("");
  const [registerID, setRegisterID] = useState("SA1");
  const [selectedState, setSelectedState] = useState("");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState("");

  const [education, Seteducation] = useState();
  const [stream, Setstream] = useState();
  const [degree, Setdergee] = useState();
  const [exp, Setexp] = useState();
  const [jobrole, Setjobrole] = useState();
  const [orgList, setOrgList] = useState([]);
  const [create, setCreate] = useState(false);
  const [jobTitle, setJobtitle] = useState([]);
  const [orgNameID, setOrgNameID] = useState("");
  const [salaryList, setSalaryList] = useState([]);
  const [jobType, SetjobType] = useState([]);
  const [skillsOption, SetSkillsOption] = useState([]);
  const [newddEducation, setNewDDEducation] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const ref = useRef(null);
  const navigate = useNavigate();

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file?.name.length !== 0 && file?.size / 1024 < 5120) {
      setResumeFile(file);
    } else if (file?.name.length !== 0 && file?.size / 1024 > 5120) {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpg, jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    } else {
      console.log("No file");
    }
  };

  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const partnerFunc = () => {
    const params = {
      partner_association: "samarth",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partner?${paramString}`
        // {
        //     "partner_association":"samarth",
        //     // "partner_association": selectProjectOwner
        //     //getting option from backend
        // }
      )
      .then((res) => {
        console.log(res.data);
        setProjectOwnerList(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  useEffect(() => {
    const params = {
      partner_association: selectProjectOwner,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partneradmin?${paramString}`
        // {
        //     "partner_association": selectProjectOwner
        // }
      )
      .then((res) => {
        console.log(res.data);
        setCenterOwnerList(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, [selectProjectOwner]);

  useEffect(() => {
    setSelectCenterOwner("");
    securityQuestionFunc();
    partnerFunc();
  }, []);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
    securityQuestionFunc();
  }, []);

  useEffect(() => {
    const params = {
      state: selectedState === "" ? props.editdata.org_state : selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [props.editdata, selectedState]);

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        Setstream(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchjobtype = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/jobtype`)
      .then((res) => {
        SetjobType(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSkills = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/skills`)
      .then((res) => {
        SetSkillsOption(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
        setNewDDEducation(res.data.map((ele) => ele.value));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchSkills();
    fetchjobtype();
    fetchjobrole();
    fetchexp();
    fetchdegree();
    fetchstream();
    fetchSalary();
    fetcheducation();
  }, [props.editdata]);

  const roleOptions = [
    { label: "Head", value: "Head" },
    { label: "Associate", value: "Associate" },
  ];

  console.log(props.editdata,'293')

  const counsellorform = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: props?.editdata?.email,
      mobnum: props?.editdata?.phone ? props?.editdata?.phone : "",
      firstnm: props?.editdata?.first_name,
      lastnm: props?.editdata?.last_name,
      org_state: props?.editdata?.state,
      org_district: props?.editdata?.district,
      stream: props?.editdata?.stream,
      degree: props?.editdata?.degree,
      desired_salary: props?.editdata?.desired_salary,
      experience: props?.editdata?.experience,
      skill: props?.editdata?.skill,
      education: props?.editdata?.education,
      stream: props?.editdata?.stream,
      desgination: props?.editdata?.desgination,
      desired_salary: props?.editdata?.desired_salary,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
      mobnum: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 Digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required("Please enter last name"),
    }),
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("firstnm", values.firstnm);
      formData.append("lastnm", values.lastnm);
      formData.append("mobnum", values.mobnum);
      formData.append("email", values.email);
      formData.append("stream", values.stream);
      formData.append("state", values.org_state);
      formData.append("skill", values.skill);
      formData.append("district", values.org_district);
      formData.append("experience", values.experience);
      formData.append("education", values.education);
      formData.append("desired_salary", values.desired_salary);
      formData.append("desgination", values.desgination);

      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL_REGISTER}/jobseeker/register`,
          formData
        );
        const data = await res.data;
        Swal.fire({
          icon: "success",
          title: data["Developer Message"] ? data["Developer Message"] : "",
          title: `${data["User Message"]}`,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          props?.Seteditadmin(false);
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          props?.Seteditadmin(false);
        });
      }
    },
  });

  return (
    <>
      <Modal
        show={props?.editadmin}
        onHide={() => {
          props?.Seteditadmin(false);
        }}
        size="lg"
        centered
        style={{ marginTop: "52px" }}
      >
        <Modal.Header closeButton>Update Candidate Details</Modal.Header>

        <Modal.Body>
          <SoftBox mt={0}>
            <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
              {/* <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                                Admin Registration
                            </SoftTypography> */}
              <SoftBox component="form" role="form" onSubmit={counsellorform.handleSubmit}>
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox width="100%" height="auto">
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                      Candidate Details
                    </SoftTypography>
                    <SoftBox component="form" role="form">
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  First Name <sup style={{ color: "red" }}>*</sup>
                                </SoftTypography>
                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  {/* <sup>*</sup> */}
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="firstnm"
                                name="firstnm"
                                onChange={counsellorform.handleChange}
                                value={counsellorform.values.firstnm}
                              />
                              {counsellorform.touched.firstnm && counsellorform.errors.firstnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.firstnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Last Name <sup style={{ color: "red" }}>*</sup>
                                </SoftTypography>
                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  {/* <sup>*</sup> */}
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="lastnm"
                                name="lastnm"
                                onChange={counsellorform.handleChange}
                                value={counsellorform.values.lastnm}
                              />
                              {counsellorform.touched.lastnm && counsellorform.errors.lastnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.lastnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Email <sup style={{ color: "red" }}>*</sup>
                                </SoftTypography>
                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  {/* <sup>*</sup> */}
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="email"
                                placeholder=""
                                id="email"
                                name="email"
                                onChange={counsellorform.handleChange}
                                value={counsellorform.values.email}
                              />
                              {counsellorform.touched.email && counsellorform.errors.email ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.email}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Mobile Number <sup style={{ color: "red" }}>*</sup>
                                </SoftTypography>
                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                  {/* <sup>*</sup> */}
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="tel"
                                inputProps={{
                                  maxLength: "10",
                                }}
                                placeholder=""
                                id="mobnum"
                                name="mobnum"
                                onChange={counsellorform.handleChange}
                                value={counsellorform.values.mobnum}
                              />
                              {counsellorform.touched.mobnum && counsellorform.errors.mobnum ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.mobnum}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                State
                              </SoftTypography>

                              <Select
                                styles={stylesDD}
                                options={state}
                                placeholder="Select State"
                                id="org_state"
                                name="org_state"
                                onChange={(e) => {
                                  counsellorform.setFieldValue("org_state", e.label);
                                  setSelectedState(e.label);
                                }}
                                value={{ label: counsellorform.values.org_state }}
                              />
                              {counsellorform.touched.org_state &&
                                counsellorform.errors.org_state ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.org_state}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                District
                              </SoftTypography>

                              <Select
                                styles={stylesDD}
                                options={district}
                                placeholder=""
                                id="org_district"
                                name="org_district"
                                onChange={(e) => {
                                  counsellorform.setFieldValue("org_district", e.label);
                                }}
                                value={{
                                  label: counsellorform.values.org_district,
                                }}
                              />
                              {counsellorform.touched.org_district &&
                                counsellorform.errors.org_district ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.org_district}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox width="100%">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Education
                              </SoftTypography>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  options={education}
                                  placeholder="Select education"
                                  value={{
                                    label: counsellorform.values.education,
                                  }}
                                  onChange={(e) =>
                                    counsellorform.setFieldValue("education", e ? e.value : "")
                                  }
                                />
                              </SoftBox>
                              {counsellorform.touched.education &&
                                counsellorform.errors.education ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.education}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox width="100%">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Specialization
                              </SoftTypography>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  options={degree}
                                  placeholder="Select degree"
                                  value={{
                                    label: counsellorform.values.degree,
                                  }}
                                  onChange={(e) =>
                                    counsellorform.setFieldValue("degree", e ? e.value : "")
                                  }
                                />
                              </SoftBox>
                              {counsellorform.touched.degree && counsellorform.errors.degree ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.degree}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox width="100%">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Skill
                              </SoftTypography>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  options={skillsOption}
                                  placeholder="Select skill"
                                  value={{
                                    label: counsellorform.values.skill,
                                  }}
                                  onChange={(e) =>
                                    counsellorform.setFieldValue("skill", e ? e.value : "")
                                  }
                                />
                              </SoftBox>
                              {counsellorform.touched.skill && counsellorform.errors.skill ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.skill}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox width="100%">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Role
                              </SoftTypography>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  options={jobrole}
                                  placeholder="Select role"
                                  value={{
                                    label: counsellorform.values.desgination,
                                  }}
                                  onChange={(e) =>
                                    counsellorform.setFieldValue("desgination", e ? e.value : "")
                                  }
                                />
                              </SoftBox>

                              {counsellorform.touched.desgination &&
                                counsellorform.errors.desgination ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.desgination}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox width="100%">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Experience
                              </SoftTypography>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  options={exp}
                                  placeholder="Select experience"
                                  value={{
                                    label: counsellorform.values.experience,
                                  }}
                                  onChange={(e) =>
                                    counsellorform.setFieldValue("experience", e ? e.value : "")
                                  }
                                />
                              </SoftBox>

                              {counsellorform.touched.experience &&
                                counsellorform.errors.experience ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.experience}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox width="100%">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Salary
                              </SoftTypography>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  options={salaryList}
                                  placeholder="Select salary"
                                  value={{
                                    label: counsellorform.values.desired_salary,
                                  }}
                                  onChange={(e) =>
                                    counsellorform.setFieldValue("desired_salary", e ? e.value : "")
                                  }
                                />
                              </SoftBox>

                              {counsellorform.touched.desired_salary &&
                                counsellorform.errors.desired_salary ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.desired_salary}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox width="100%">
                              <SoftTypography
                                fontWeight="medium"
                                fontSize="16px"
                                lineHeight="20px"
                                mb={1}
                              >
                                Stream
                              </SoftTypography>
                              <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                <Select
                                  styles={stylesDD}
                                  options={stream}
                                  placeholder="Select degree"
                                  value={{
                                    label: counsellorform.values.stream,
                                  }}
                                  onChange={(e) =>
                                    counsellorform.setFieldValue("stream", e ? e.value : "")
                                  }
                                />
                              </SoftBox>
                              {counsellorform.touched.stream && counsellorform.errors.stream ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {counsellorform.errors.stream}{" "}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer>
          <SoftBox
            pb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto" }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                pl={2}
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width={{ lg: "100%", sm: "100%", xs: "50%" }}
                onClick={counsellorform.handleSubmit}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Submit
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                pl={2}
                sx={{
                  height: "45px",
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  border: "1px solid #3B5092",
                  cursor: "pointer",
                }}
                p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width={{ lg: "225px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  props.Seteditadmin(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Cancel
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Footer>
      </Modal>
    </>
  );
};
JobseekerEdit.propTypes = {
  editadmin: PropTypes.bool.isRequired,
  Seteditadmin: PropTypes.func.isRequired,
  editdata: PropTypes.object.isRequired,
  // h4: PropTypes.string.isRequired,
  // v1: PropTypes.string.isRequired,
  // v2: PropTypes.string.isRequired,
  // v3: PropTypes.string.isRequired,
  // v4: PropTypes.string.isRequired,
};
export default JobseekerEdit;
