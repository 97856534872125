import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
// import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Timeline from "../../Timeline/Timeline";
import Profiling from "./Profilling";
import Notes from "./Notes";

// import Typography from "@mui/material/Typography";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Alltabs = (props) => {
  const {
    time,
    setTime,
    candData,
    veridetails,
    isLoading,
    setIsLoading,
    HandleFetchCandDetails,
    jobId,
    orgId,
  } = props;
  const [value, setValue] = React.useState(0);
  const [activeStep, setActiveStep] = useState(2);
  const steps = [
    {
      label: "Select campaign settings",
      description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
    },
    {
      label: "Create an ad group",
      description: "An ad group contains one or more ads which target a shared set of keywords.",
    },
    {
      label: "Create an ad",
      description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
    },
  ];
  {
    console.log("timliendata", time);
  }
  console.log("candata all tabs: ", candData);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Timeline" {...a11yProps(0)} />
          <Tab label="Candidate Profiling" {...a11yProps(1)} />
          <Tab label="Notes" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Timeline
          time={time.reverse()}
          setTime={setTime}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* Candidate Profiling */}
        <Profiling time={time} setTime={setTime} candData={candData} veridetails={veridetails} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* Notes */}
        <Notes
          time={time}
          jobId={jobId}
          orgId={orgId}
          setTime={setTime}
          candData={candData}
          HandleFetchCandDetails={HandleFetchCandDetails}
        />
      </TabPanel>
    </Box>
  );
};

Alltabs.propTypes = {
  time: PropTypes.array,
  setTime: PropTypes.array,
  candData: PropTypes.object,
  veridetails: PropTypes.object,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.bool,
  jobId: PropTypes.string,
  orgId: PropTypes.string,
  HandleFetchCandDetails: PropTypes.func,
};

export default Alltabs;
