// eslint-disable-next-line react/prop-types
import React, { useRef } from "react";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import "./jobfair.css";
import locationSVG from "../../assets/images/jobfairsvgs/location1.svg";
import dateSVG from "../../assets/images/jobfairsvgs/calendar1.svg";
import timeSVG from "../../assets/images/jobfairsvgs/clock1.svg";
import compLogo from "../../assets/images/jobfairsvgs/Company Registration.png";
import seekerLogo from "../../assets/images/jobfairsvgs/Job Seeker Registration.png";

const QRComponent = (props) => {
  const componentRef = useRef();

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  const handleDownloadImage = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      // link.download = 'VolunteerQR_Date.jpg';
      link.download = `${props.Qrlink.jfName}_${
        props.QRType === "JP" ? "company_reg" : "seeker_reg"
      }.jpg`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  console.log(props);
  return (
    <div>
      <div ref={componentRef} className="print">
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <p
            style={{
              paddingTop: "70px",
            }}
          >
            <img
              src={props.QRType === "JP" ? compLogo : seekerLogo}
              alt={props.QRType === "JP" ? "Company Registration" : "Job Seeker Registration"}
            />
          </p>
          <p
            style={{
              paddingTop: "70px",
              fontSize: "20px",
              justifyContent: "center",
              display: "flex",
              fontWeight: "500",
              color: "#000",
            }}
          >
            {" "}
            {props.QRType === "JP" ? "Company Registration" : "Job Seeker Registration"}
          </p>
        </div>

        <img
          src={props?.test}
          style={{
            height: "210px",
            width: "215px",
            paddingTop: "7px",
            marginLeft: "117px",
            position: "relative",
          }}
          // className="qrimage"
        />
        <p
          style={{
            fontSize: "24px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          {props.Qrlink.jfName}
        </p>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <img src={dateSVG} alt="calendar" />
            <p style={{ display: "flex", alignItems: "end", marginBottom: 0 }}>
              Date :{" "}
              <span style={{ fontSize: "20px", fontWeight: 500, marginLeft: "10px" }}>
                {props.Qrlink.date}
              </span>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <img src={timeSVG} alt="clock" />
            <p style={{ display: "flex", alignItems: "end", marginBottom: 0 }}>
              Time :{" "}
              <span style={{ fontSize: "20px", fontWeight: 500, marginLeft: "10px" }}>
                {props.Qrlink.time}
              </span>
            </p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <img src={locationSVG} alt="location" />
            <p style={{ display: "flex", alignItems: "end", marginBottom: 0 }}>
              Venue :{" "}
              <span style={{ fontSize: "20px", fontWeight: 500, marginLeft: "10px" }}>
                {props.Qrlink.venue}
              </span>
            </p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {/* <img src={locationSVG} alt="location" /> */}
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                marginBottom: 0,
              }}
            >
              {/* Link :{" "} */}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  marginLeft: "10px",
                  textAlign: "center",
                  color: "#3B5092",
                }}
              >
                {props.QRType === "JP" ? props.Qrlink.jpLink : props.Qrlink.jsLink}
              </span>
            </p>
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          handleDownloadImage();
        }}
        style={{
          fontSize: "14px",
          width: "100%",
          backgroundColor: "#3b5092",
          color: "white",
          marginTop: "20px",
        }}
      >
        Download QR
      </button>
    </div>
  );
};
QRComponent.propTypes = {
  Qrlink: PropTypes.object,
  test: PropTypes.object,
  setTest: PropTypes.object,
  QRType: PropTypes.string,
};
export default QRComponent;
