export const gradientLCData = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Quotes",
      color: "dark",
      data: [50, 45, 60, 60, 80, 65, 90, 80, 100],
    },
  ],
};

export const incomeChart = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Income",
      color: "info",
      data: [1160, 2080, 175, 1090, 1167, 1200, 3090, 1210, 2120],
    },
  ],
  customTick: "$",
};
