import React from "react";
// import MainPage from "./Components/MainPage.js";
import SideDrawer from "./SideDrawer";
import Backdrop from "./BackDrop.js";
import PropTypes from "prop-types";

const Index = (props) => {
  const {
    open,
    setOpen,
    broadCast,
    setBroadCast,
    waStatus,
    setWaStatus,
    stList,
    setStList,
    searchFunc,
    resetfunc,
    cosList1,
    setConsList1,
    counsellorName,
    setCounsellorName,
    shortlistDate,
    setShortlistDate,
    broadcastdate,
    setBroadcastdate,
    selectedDSF,
    setSelectedDSF,
    scoreft,
    setScoreft,
    step,
    setStep,
    candidateRt,
    setCandidateRt,
    setResponseStatus,
    responseStatus,
    stateFilter,
    setStateFilter,
    districtFilter,
    setDistrictFilter,
  } = props;
  const backdropClickHandler = () => {
    setOpen(false);
  };
  let backdrop;
  if (open) {
    backdrop = <Backdrop close={backdropClickHandler} />;
  }
  return (
    <div>
      <SideDrawer
        show={open}
        setOpen={setOpen}
        broadCast={broadCast}
        setBroadCast={setBroadCast}
        waStatus={waStatus}
        setWaStatus={setWaStatus}
        stList={stList}
        setStList={setStList}
        searchFunc={searchFunc}
        resetfunc={resetfunc}
        cosList1={cosList1}
        setConsList1={setConsList1}
        counsellorName={counsellorName}
        setCounsellorName={setCounsellorName}
        shortlistDate={shortlistDate}
        setShortlistDate={setShortlistDate}
        broadcastdate={broadcastdate}
        setBroadcastdate={setBroadcastdate}
        selectedDSF={selectedDSF}
        setSelectedDSF={setSelectedDSF}
        scoreft={scoreft}
        setScoreft={setScoreft}
        step={step}
        setStep={setStep}
        candidateRt={candidateRt}
        setCandidateRt={setCandidateRt}
        responseStatus={responseStatus}
        setResponseStatus={setResponseStatus}
        stateFilter={stateFilter}
        setStateFilter={setStateFilter}
        districtFilter={districtFilter}
        setDistrictFilter={setDistrictFilter}
      />
      {backdrop}
    </div>
  );
};
Index.propTypes = {
  open: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  broadCast: PropTypes.string,
  setBroadCast: PropTypes.string,
  waStatus: PropTypes.string,
  setWaStatus: PropTypes.string,
  stList: PropTypes.string,
  setStList: PropTypes.string,
  searchFunc: PropTypes.func,
  resetfunc: PropTypes.func,
  cosList1: PropTypes.string,
  setConsList1: PropTypes.string,
  counsellorName: PropTypes.string,
  setCounsellorName: PropTypes.string,
  shortlistDate: PropTypes.string,
  setShortlistDate: PropTypes.string,
  broadcastdate: PropTypes.string,
  setBroadcastdate: PropTypes.string,
  selectedDSF: PropTypes.string,
  setSelectedDSF: PropTypes.string,
  scoreft: PropTypes.string,
  setScoreft: PropTypes.string,
  step: PropTypes.string,
  setStep: PropTypes.string,
  candidateRt: PropTypes.string,
  setCandidateRt: PropTypes.string,
  responseStatus: PropTypes.string,
  setResponseStatus: PropTypes.string,
  stateFilter: PropTypes.string,
  setStateFilter: PropTypes.string,
  districtFilter: PropTypes.string,
  setDistrictFilter: PropTypes.string,
};
export default Index;
