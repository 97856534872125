import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import axios from "axios";
import Swal from "sweetalert2";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box } from "@mui/material";
import "./styles.css";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const Notes = (props) => {
  const [jobDes, setJobDes] = useState("");
  console.log(props, 'xyu')

  useEffect(() => {
    setJobDes(props.candData?.notes || "")
  }, [props?.candData])

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
      ['clean']
    ],
  }
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleTexteditorChange = (value) => {
    setJobDes(value)
  }

  //const orgId = sessionStorage.getItem("org_id");
  //const jobId = sessionStorage.getItem("job_id");
  const counsellor_id = sessionStorage.getItem("register_id") ? sessionStorage.getItem("register_id") : "";

  const addNotes = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/notes`, {
        notes: jobDes,
        candid: props.candData?.registration_id,
        // orgid: props?.orgId ? props?.orgId : "",
        course_id: props?.jobId ? props?.jobId : "",
        // counsellor_id: counsellor_id
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Notes added successfully",
          showConfirmButton: false,
          timer: 3000,
        })
        /*.then(()=>{
          props?.HandleFetchCandDetails();
        })
        */
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <Box textAlign={"left"} sx={{ height: "210px" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", m: 1, mt: 0 }}>
        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#3B5092",
            color: "#fff",
            width: "70px",
            borderRadius: "8px",
            fontSize: "15px",
            marginRight: "8px",
            cursor: props?.orgId === null || props?.orgId === "" || props?.jobId === null || props?.jobId === "" ? "no-drop" : "pointer"
          }}
          onClick={() => props?.orgId === null || props?.orgId === "" || props?.jobId === null || props?.jobId === "" ? console.log("") : addNotes()}
        >
          Save
        </SoftBox>
      </Box>

      <Box sx={{ height: "160px", overflow: "auto" }}>
        <ReactQuill
          value={jobDes || ''}
          defaultValue=''
          modules={modules}
          formats={formats}
          onChange={handleTexteditorChange}
        />
      </Box>
      <br />
    </Box>
  );
};

export default Notes;

Notes.propTypes = {
  candData: PropTypes.object,
  //   veridetails: PropTypes.object,
  time: PropTypes.array,
  setTime: PropTypes.array,
  HandleFetchCandDetails: PropTypes.func,
  jobId: PropTypes.string,
  orgId: PropTypes.string,
};
