import React, { useState,useEffect } from 'react'
import MyMapComponent from './MyMapComponent';
// import props from "react/prop-types";


const MapCoOrdinates = (props) => {
    const [isMarkerShown,setIsMarkerShown] = useState(false);
    // const [state,setState] = useState({
    //     latitude: "",
    //     longitude:""
    // })
    const  delayedShowMarker = () => {
        setTimeout(() => {
        //   this.setState({ isMarkerShown: true });
        setIsMarkerShown(true);
        }, 3000);
    };

    const  handleMarkerClick = () => {
        setIsMarkerShown(false);
        delayedShowMarker();
    };

    // const handleChildCallback = (obj)=>{
    //     // setState({
    //     //     ladtitude: obj.lat,
    //     //     longitude: obj.longi
    //     // })
    //     props.parentCallback(obj);
    // }

    useEffect(()=>{
        delayedShowMarker();
    },[])
  return (
    <MyMapComponent
        isMarkerShown={isMarkerShown}
        onMarkerClick={handleMarkerClick}
        {...props}
        // parentState={props.parentState}
        // parentCallback={props.parentCallback}
    />
  )
}

export default MapCoOrdinates

