import Grid from "@mui/material/Unstable_Grid2";
import { Card, Stack } from "@mui/material";
import { useMemo } from "react";

import SoftBadgeDot from "components/SoftBadgeDot";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import ThinBarChart from "examples/Charts/BarCharts/ThinBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import defaultLineCartData from "./data/defaultLineChartData";
import { gradientLCData, incomeChart } from "./data/gradientLineChartData";
import thinBarChartData from "./data/thinBarChartData";
import dataTableData from "./data/tableData";

//*********** */
// import App from '../../App';

const Business = () => {
  const totalIncome = useMemo(() => {
    return incomeChart.datasets[0].data.reduceRight((sum, curr) => {
      console.log(sum, curr);
      return sum + curr;
    }, 0);
  }, [incomeChart]);

  
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>  
        {/* <App/>       */}
        <SoftBox>
          <SoftTypography variant="h1" mb={2} fontWeight="semibold">
            Business
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          <Grid container columnSpacing={{ lg: 2 }}>
            <Grid xs={12} lg={6}>
              <SoftBox mb={2}>
                <GradientLineChart
                  description={
                    <SoftBox display="flex" ml={-1}>
                      <SoftBadgeDot color="info" size="sm" badgeContent="Business Available" />
                      <SoftBadgeDot color="dark" size="sm" badgeContent="Business Generated" />
                    </SoftBox>
                  }
                  chart={defaultLineCartData}
                />
              </SoftBox>
              <SoftBox mb={2}>
                <GradientLineChart
                  description={
                    <SoftBox display="flex" alignItems="center">
                      <SoftTypography component="span" mr={1} fontWeight="light">
                        Total
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {totalIncome}
                      </SoftTypography>
                    </SoftBox>
                  }
                  chart={incomeChart}
                />
              </SoftBox>
            </Grid>
            <Grid xs={12} lg={6}>
              <SoftBox mb={2}>
                <ThinBarChart title="Income per day" chart={thinBarChartData} height="19.125rem" />
              </SoftBox>
              <SoftBox mb={2}>
                <GradientLineChart title="Quotes per month" chart={gradientLCData} />
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={4}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
              <SoftBox lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Business Details
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Brief summary of the transactions / business done
                </SoftTypography>
              </SoftBox>
              <Stack spacing={1} direction="row">
                {/* <Link to="/ecommerce/products/new-product">
                  <SoftButton variant="gradient" color="info" size="small">
                    + new product
                  </SoftButton>
                </Link> */}
                {/* <SoftButton variant="outlined" color="info" size="small">
                  import
                </SoftButton> */}
                <SoftButton variant="outlined" color="info" size="small">
                  export
                </SoftButton>
              </Stack>
            </SoftBox>
            <DataTable
              table={dataTableData}
              entriesPerPage={{
                defaultValue: 7,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              canSearch
            />
          </Card>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default Business;
