import Default from "layouts/dashboards/default";
import Automotive from "layouts/dashboards/automotive";
import SmartHome from "layouts/dashboards/smart-home";
import VRDefault from "layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Security from "layouts/pages/account/security";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import NewProject from "layouts/pages/projects/new-project";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/pages/notifications";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import Analytics from "layouts/applications/analytics";
import Overview from "layouts/ecommerce/overview";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import ProductsList from "layouts/ecommerce/products/products-list";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Referral from "layouts/ecommerce/referral";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import ResetBasic from "layouts/authentication/reset-password/basic";
import ResetCover from "layouts/authentication/reset-password/cover";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import LockBasic from "layouts/authentication/lock/basic";
import LockCover from "layouts/authentication/lock/cover";
import LockIllustration from "layouts/authentication/lock/illustration";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";

// custom pages
import Login from "pages/Login/Login";
import Business from "pages/Business";
import Channelpartner from "pages/Channelpartner/channelpartner";
import Centerhead from "pages/Centerhead/centerhead";
import Centeremployee from "pages/Centeremployee/centeremployee";
import Center from "pages/Center/center";
// import Jobfair from "pages/Jobfair/jobfair";
import JobFairs from "pages/JobFairMaster/jobfairs";
import Rates from "pages/Commission/Rates";
import Received from "pages/Commission/Received";

// material ui icons
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ScreenSearchDesktopRoundedIcon from "@mui/icons-material/ScreenSearchDesktopRounded";

// icons for side barss
import dashboard from "assets/icons/dashboard_icon.png";
import jobsearch from "assets/icons/jobsearch_icon.png";
import channelPartner from "assets/icons/cp_icon.png";
import centerIcon from "assets/icons/center_icon.png";
import adminIcon from "assets/icons/admin_icon.png";
import eventIcon from "assets/icons/event_icon.png";
import seekerIcon from "assets/icons/seeker_icon.png";
import providerIcon from "assets/icons/provider_icon.png";
import hrcIcon from "assets/icons/hrc_icon.png";

// additional created pages
import Register from "pages/Register";
import { Jobseekar } from "pages/Jobseekar/jobseekar";
import Jobprovider from "pages/Jobprovider/jobprovider";
import { Hrconsults } from "pages/Hrconsults/hrconsults";
import Admin from "pages/Admin/admin";
import Suparadmin from "pages/Suparadmin/suparadmin";
import Channelprtnerregister from "pages/Channelpartner/channelprtnerregister";
import Centerreg from "pages/Center/centerreg";
import SuperAdminOverview from "pages/Suparadmin/superAdminOverview";
import AdminOverview from "Admin/layout/adminOverview";
import AdminRegister from "Admin/layout/adminRegister";

const adminroutes = [
  {
    type: "collapse",
    name: "Overview",
    key: "dashboard",
    // icon: <GridViewRoundedIcon size='12px'/>,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="15"
        viewBox="0 0 13 13"
        fill="none"
      >
        <path
          d="M6.19047 1.93455V4.25597C6.18964 4.76875 5.98558 5.26028 5.62295 5.6229C5.26034 5.98552 4.7688 6.18957 4.25602 6.19042H1.9346C1.42172 6.18959 0.930186 5.98553 0.567567 5.6229C0.204948 5.26028 0.000902008 4.76875 5.30319e-05 4.25597V1.93455C0.00088211 1.42166 0.20494 0.930133 0.567567 0.567514C0.930194 0.204895 1.42172 0.000848976 1.9346 0H4.25602C4.7688 0.000829078 5.26034 0.204887 5.62295 0.567514C5.98557 0.930133 6.18962 1.42166 6.19047 1.93455ZM11.0654 0H8.74403C8.23125 0.000829078 7.73972 0.204887 7.3771 0.567514C7.01448 0.930133 6.81043 1.42166 6.80958 1.93455V4.25597C6.81041 4.76875 7.01447 5.26028 7.3771 5.6229C7.73972 5.98552 8.23125 6.18957 8.74403 6.19042H11.0654C11.5783 6.18959 12.0699 5.98553 12.4325 5.6229C12.7951 5.26028 12.9992 4.76875 13 4.25597V1.93455C12.9992 1.42166 12.7951 0.930133 12.4325 0.567514C12.0699 0.204895 11.5783 0.000848976 11.0654 0ZM4.25586 6.80958H1.93455C1.42166 6.81041 0.930133 7.01447 0.567514 7.3771C0.204895 7.73972 0.000848976 8.23125 0 8.74403V11.0654C0.000829078 11.5783 0.204887 12.0699 0.567514 12.4325C0.930141 12.7951 1.42166 12.9992 1.93455 13H4.25597C4.76875 12.9992 5.26028 12.7951 5.6229 12.4325C5.98552 12.0699 6.18957 11.5783 6.19042 11.0654V8.74403C6.18959 8.23125 5.98553 7.73972 5.6229 7.3771C5.26028 7.01448 4.76865 6.81043 4.25586 6.80958ZM11.0654 6.80958H8.74403C8.23125 6.81041 7.73972 7.01447 7.3771 7.3771C7.01448 7.73972 6.81043 8.23125 6.80958 8.74403V11.0654C6.81041 11.5783 7.01447 12.0699 7.3771 12.4325C7.73972 12.7951 8.23125 12.9992 8.74403 13H11.0654C11.5783 12.9992 12.0699 12.7951 12.4325 12.4325C12.7951 12.0699 12.9992 11.5783 13 11.0654V8.74403C12.9992 8.23125 12.7951 7.73972 12.4325 7.3771C12.0699 7.01448 11.5783 6.81043 11.0654 6.80958Z"
          fill="black"
        />
      </svg>
    ),
    route: "/admin",
    component: <AdminOverview />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Register",
    key: "register",
    // icon: <GroupAddRoundedIcon size="12px"/>,
    icon: (
      <div width="12px" height="15px">
        <img src={jobsearch} alt="dashboard_icon" />
      </div>
    ),
    route: "/admin/register",
    component: <AdminRegister />,
    noCollapse: true,
  },
  { type: "title", title: "JOBS", key: "title-pages" },
  {
    type: "collapse",
    name: "Job Posting",
    key: "job_posting",
    // icon: <GroupAddRoundedIcon size='12px'/>,
    icon: (
      <div width="12px" height="15px">
        <img src={jobsearch} alt="dashboard_icon" />
      </div>
    ),
    //route: "/agent/business",
    component: <AdminOverview />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Post Jobs",
    key: "post_jobs",
    // icon: <GroupAddRoundedIcon size='12px'/>,
    icon: (
      <div width="12px" height="15px">
        <img src={jobsearch} alt="dashboard_icon" />
      </div>
    ),
    //route: "/agent/business",
    component: <AdminOverview />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Search Job Seekers",
    key: "search_job_seekers",
    // icon: <GroupAddRoundedIcon size='12px'/>,
    icon: <img width="12px" height="15px" src={jobsearch} alt="dashboard_icon" />,
    //route: "/agent/business",
    component: <AdminOverview />,
    noCollapse: true,
  },

  { type: "title", title: "MASTERS", key: "title-docs" },
  //   {
  //     type:"collapse",
  //     name: "Super Admin",
  //     key:"super_admin",
  //     // icon: <PersonAddAltRoundedIcon size="12px"/>,
  //     icon: <img width="15px" height="15px" src={adminIcon} alt="dashboard_icon"/>,
  //     route:"/super-admins-list-page",
  //     component: <Suparadmin/>,
  //     noCollapse: true
  //   },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    // icon: <PersonAddAltRoundedIcon size="12px"/>,
    icon: <img width="15px" height="15px" src={adminIcon} alt="dashboard_icon" />,
    //route:"/admin/admins-list-page",
    component: <Admin />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Channel Partner",
    key: "register",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
      >
        <path
          d="M7.97315 15.0173L9.12316 13.8673C9.26583 13.7246 9.34294 13.5391 9.34294 13.3394C9.34294 13.1397 9.26596 12.9514 9.12316 12.8116C8.98049 12.6689 8.79499 12.5918 8.59532 12.5918C8.40129 12.5918 8.2158 12.666 8.07603 12.8029L6.55514 14.3238C6.55225 14.3267 6.54949 14.3294 6.54371 14.3323L6.53228 14.3438C6.38961 14.4864 6.3125 14.6719 6.3125 14.8716C6.3125 15.0713 6.38948 15.2597 6.53228 15.3994C6.81473 15.6819 7.30842 15.6819 7.59098 15.3994L7.97315 15.0173Z"
          fill="black"
        />
        <path
          d="M7.67278 12.4157C7.67567 12.4128 7.67843 12.4101 7.68421 12.4072L8.2378 11.8536C8.38047 11.7109 8.45759 11.5254 8.45759 11.3257C8.45759 11.1261 8.3806 10.9377 8.2378 10.7979C8.09514 10.6552 7.90964 10.5781 7.70997 10.5781C7.5103 10.5781 7.3219 10.6551 7.18213 10.7979L5.11041 12.8696C4.96774 13.0123 4.89062 13.1978 4.89062 13.3975C4.89062 13.5971 4.96761 13.7855 5.11041 13.9253C5.3901 14.205 5.87511 14.2078 6.15771 13.9338L7.67278 12.4157Z"
          fill="black"
        />
        <path
          d="M3.45009 10.8343L4.97962 9.30475C5.12229 9.16208 5.1994 8.97659 5.1994 8.77692C5.1994 8.57724 5.12242 8.38885 4.97962 8.24908C4.83695 8.10641 4.65145 8.0293 4.45178 8.0293C4.25211 8.0293 4.06372 8.10628 3.92395 8.24908L2.39166 9.77861C2.24899 9.92128 2.17188 10.1068 2.17188 10.3064C2.17188 10.5061 2.24886 10.6945 2.39166 10.8343C2.6854 11.1254 3.15908 11.1254 3.45009 10.8343Z"
          fill="black"
        />
        <path
          d="M6.85615 10.3321C6.99882 10.1894 7.07593 10.0039 7.07593 9.80426C7.07593 9.60459 6.99895 9.41619 6.85615 9.27642C6.71059 9.13087 6.51944 9.05664 6.32831 9.05664C6.13719 9.05664 5.9459 9.13086 5.80048 9.27642L3.60896 11.4679C3.31785 11.7591 3.31785 12.2355 3.60896 12.5266C3.90008 12.8177 4.37655 12.8178 4.66766 12.5266L6.85615 10.3321Z"
          fill="black"
        />
        <path
          d="M10.2432 14.5134L9.71817 13.9883C9.66115 14.0854 9.59836 14.1767 9.5156 14.2594L8.5625 15.2125L9.05331 15.7033C9.36715 16.0201 9.92653 16.0201 10.2433 15.7033C10.403 15.5436 10.4916 15.3324 10.4916 15.1098C10.4916 14.8873 10.4059 14.6789 10.249 14.5192L10.2432 14.5134Z"
          fill="black"
        />
        <path
          d="M1.33858 5.81062L4.76825 2.17513L4.9481 1.98399L3.72966 0.833984L0 4.78897L1.21569 5.93884L1.33858 5.81062Z"
          fill="black"
        />
        <path
          d="M10.3357 5.09631L10.0331 5.40161C9.2227 6.21205 7.9073 6.21205 7.09686 5.40161C6.58033 4.88508 6.58033 4.04612 7.09686 3.52972L7.73323 2.89336C6.91714 2.52816 6.004 2.42818 5.11658 2.61656L1.80354 6.12627C1.65509 7.12218 1.87199 8.14372 2.39706 8.99121L3.53563 7.85264C4.04352 7.34475 4.87114 7.34475 5.379 7.85264C5.60154 8.07518 5.71861 8.35778 5.74709 8.64586C6.23501 8.40046 6.84574 8.47468 7.25379 8.88272C7.49919 9.12812 7.6362 9.45629 7.6362 9.80441C7.6362 9.88139 7.62766 9.9585 7.61334 10.0327C7.97854 10.0042 8.35531 10.124 8.63488 10.4037C8.88028 10.6491 9.01729 10.9773 9.01729 11.3254C9.01729 11.5879 8.94031 11.839 8.79475 12.0531C9.06012 12.093 9.31694 12.2101 9.52242 12.4155C9.76782 12.6609 9.90484 12.9891 9.90484 13.3372C9.90484 13.3515 9.90195 13.3657 9.90195 13.38L10.6439 14.122C10.6468 14.1249 10.6468 14.1249 10.6496 14.1276L11.2574 14.7355C11.577 15.0551 12.1306 15.0551 12.4501 14.7355C12.7783 14.4073 12.7783 13.8766 12.453 13.5484L10.1816 11.277C10.0732 11.1686 10.0732 10.9946 10.1816 10.8861C10.29 10.7777 10.4641 10.7777 10.5726 10.8861L12.9181 13.2315C13.2319 13.5454 13.7969 13.5454 14.1051 13.2315C14.4333 12.9005 14.4333 12.3669 14.1051 12.0388L12.5928 10.5265C12.4844 10.4181 12.4844 10.244 12.5928 10.1355C12.7012 10.0271 12.8752 10.0271 12.9837 10.1355L14.0396 11.1856C14.3563 11.4994 14.9156 11.4994 15.2295 11.1856C15.3893 11.0258 15.4778 10.8146 15.4778 10.5921C15.4778 10.3666 15.3894 10.1555 15.2295 9.99854L14.4077 9.09683L10.3357 5.09631Z"
          fill="black"
        />
        <path
          d="M12.5625 1.14985L16.1892 4.99083V4.99372L16.2947 5.10499L17.5104 3.95498L13.7808 0L12.5625 1.14985Z"
          fill="black"
        />
        <path
          d="M12.1937 1.56866C10.901 1.38027 9.59121 1.81406 8.67251 2.73286L7.48256 3.91992C7.1829 4.21958 7.1829 4.70749 7.48256 5.00714C8.07607 5.60066 9.0406 5.60066 9.63415 5.00714L10.1335 4.50779C10.1878 4.45353 10.2591 4.42792 10.3304 4.42792C10.4018 4.42792 10.4702 4.45354 10.5245 4.50779L14.5965 8.5112C15.4155 7.64941 15.8206 6.49087 15.7208 5.3038L12.1937 1.56866Z"
          fill="black"
        />
      </svg>
    ),
    // icon: <img width="15px" height="15px" src={channelPartner} alt="dashboard_icon"/>,
    route: "/admin/channel-partners-list-page",
    component: <Channelpartner />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Center",
    key: "center",
    // icon: <LocationOnRoundedIcon size="12px"/>,
    icon: <img width="12px" height="16px" src={centerIcon} alt="dashboard_icon" />,
    route: "/admin/centers-list-page",
    component: <Center />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Center Head",
    key: "center_head",
    // icon: <HandshakeRoundedIcon size="12px"/>,
    icon: <img width="12px" height="16px" src={centerIcon} alt="dashboard_icon" />,
    route: "/admin/centers-head-page",
    component: <Centerhead />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Center Employees",
    key: "center_employess",
    // icon: <HandshakeRoundedIcon size="12px"/>,
    icon: <img width="12px" height="16px" src={centerIcon} alt="dashboard_icon" />,
    route: "/admin/super-admin-centeremployee",
    component: <Centeremployee />,
    noCollapse: true,
  },

  { type: "title", title: "SUPPLY", key: "title-docs" },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    // icon: <CalendarMonthRoundedIcon size="12px"/>,
    icon: <img width="14px" height="15px" src={eventIcon} alt="dashboard_icon" />,
    route: "/admin/job-fair-events-list-pages",
    component: <JobFairs />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Job Seekers",
    key: "job_seekers",
    // icon: <ScreenSearchDesktopRoundedIcon size="12px"/>,
    icon: <img width="20px" height="14px" src={seekerIcon} alt="dashboard_icon" />,
    route: "/admin/jobseeker-list-page",
    component: <Jobseekar />,
    noCollapse: true,
    // collapse:[
    //   {
    //     name: "Whatsapp",
    //     key: "whatsapp",
    //     href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
    //   },
    //   {
    //     name: "Website",
    //     key: "website",
    //     href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
    //   },
    //   {
    //     name: "Third party",
    //     key: "third_party",
    //     href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
    //   },
    // ]
  },
  { type: "title", title: "DEMAND", key: "title-docs" },
  {
    type: "collapse",
    name: "Job Providers",
    key: "job_provider",
    icon: <ScreenSearchDesktopRoundedIcon size="12px" />,
    // icon: <div width="15px" height="15px"><img width="25px" height="25px" src={providerIcon} alt="dashboard_icon"/></div>,
    route: "/admin/jobprovider-list-page",
    component: <Jobprovider />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "HR Consultans",
    key: "hrc",
    // icon: <ScreenSearchDesktopRoundedIcon size="12px"/>,
    icon: <img width="14px" height="14px" src={hrcIcon} alt="dashboard_icon" />,
    route: "/admin/hr-list-page",
    component: <Hrconsults />,
    noCollapse: true,
  },
];

export default adminroutes;
