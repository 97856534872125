import React,{useState} from 'react'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DataTable from 'examples/Tables/DataTable'
import jobseekartable from 'pages/Jobseekar/jobseekartable'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Icon } from '@iconify/react';
import { Card, Stack } from "@mui/material";
import SoftButton from 'components/SoftButton'
import download from "assets/icons/download_icon.png";
import image from "../../assets/images/kunal.PNG";
import qrcode from "../../assets/images/Qrcode.jpg"
import SoftBadge from 'components/SoftBadge';
import Switch from '@mui/material/Switch';

import {Table} from "ant-table-extensions";

const Centerheaddashboard = () => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const colName = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 1,
      },

    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: {
        compare: (a, b) => a.location - b.location,
        multiple: 2,
      },
    },
    {
      title: "Organization",
      dataIndex: "organization",
      sorter: {
        compare: (a, b) => a.organization - b.organization,
        multiple: 3,
      },
    },
    {
      title: "Job Status",
      dataIndex: "job_status",
      sorter: {
        compare: (a, b) => a.job_status - b.job_status,
        multiple: 4,
      },

    },
    {
      title: "Last Active",
      dataIndex: "last_active",
      sorter: {
        compare: (a, b) => a.last_active - b.last_active,
        multiple: 5,
      },

    },
    

    {
      title: "Phone Number",
      dataIndex: "phone_number",
      sorter: {
        compare: (a, b) => a.phone_number - b.phone_number,
        multiple: 6,
      },

    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 7,
      },

    },

    {
      title: 'Action',
      key: 'action',
      sorter: false,
      width: '5%',
      render: (admindata) => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button             
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </Button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a
                  className="dropdown-item"
                  href="#EditUser"
                  onClick={() =>{ Seteditadmin(true); Seteditdata(admindata)}}
                >
                  Edit Profile
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#EditUser"
                  onClick={() =>{ Seteditadmin(true); Seteditdata(admindata)}}
                >
                  Reset  Password
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#Deleteuser"
                  // onClick={() => {
                  //   if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
                  //     DeleteClientUser(e.id)
                  //   } else {
                  //     toast.error("Admin Cannot Delete")
                  //   }
    
                  // }}
                  >
                  Deactivate
                </a>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ]
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: ()=>"showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  }



  return (
    <>
      <DashboardLayout>
        <SoftBox display="flex" >
          <SoftTypography fontSize="24px" mb={2} fontWeight="medium">
            Overview
          </SoftTypography>

          <SoftTypography fontSize="24px" mb={2} fontWeight="medium" sx={{marginLeft:"72%"}}>
            Center QR
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex">
          <SoftBox width="70%" >
            <Card>
              <SoftBox display="flex" pb={2} pt={2}>
                <SoftBox width="30%" >
                  <img src={image} style={{ width: "180px", height: "180px", marginLeft: "20px" }}></img>
                  <SoftTypography fontWeight="regular" fontSize="18px" ml={4} mt={2}><Icon icon="majesticons:award-line" color="gray" width="20" /> &nbsp;Center Head</SoftTypography>
                  <SoftTypography fontWeight="regular" fontSize="18px" ml={4} mt={1}><Icon icon="material-symbols:location-on-outline" color="gray" width="20" /> &nbsp; Dahisar(West)</SoftTypography>
                  <SoftTypography fontWeight="regular" fontSize="18px" ml={4} mt={1}> <Icon icon="bi:person" color="gray" width="20" /> &nbsp;ID:400378</SoftTypography>
                  <SoftTypography fontWeight="regular" fontSize="18px" ml={4} mt={1}><Icon icon="ic:round-call" color="gray" width="20" />&nbsp; 98211 15038</SoftTypography>
                </SoftBox>
                <SoftBox width="100%" px={2} mt={2}>
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="medium" fontSize="25px">Kunal Jaiswal
                      <SoftBadge variant="contained" mb={1} backgroundColor="#adffb3b8" color="success" size="sm" badgeContent="Centerhead" circular />
                    </SoftTypography>
                    <SoftTypography fontWeight="regular" fontSize="17px" sx={{ marginLeft: "300px" }}>Candidates</SoftTypography>
                    &nbsp; <Switch {...label} defaultChecked /> &nbsp;
                    <SoftTypography fontWeight="regular" fontSize="17px">Job Provider</SoftTypography>

                  </SoftBox>

                  <SoftTypography fontWeight="regular" fontSize="18px"><Icon icon="ic:outline-email" color="gray" width="20" /> &nbsp;Kunal@sapioglobal.com</SoftTypography>
                  <SoftTypography fontWeight="bold" fontSize="18px" mt={2}>Total Data Collected</SoftTypography>
                  <SoftBox display="flex" width="100%">
                    <SoftBox width="20%" height="62px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", borderWidth: "2px", textAlign: "center" }} mr={2} >
                      <SoftTypography fontWeight="bold" fontSize="17px" sx={{ color: "#8172728c" }}>Today</SoftTypography>
                      <SoftTypography fontWeight="bold" fontSize="17px">25</SoftTypography>
                    </SoftBox>
                    <SoftBox width="20%" height="62px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", textAlign: "center", borderWidth: "2px" }} mr={2}>
                      <SoftTypography fontWeight="bold" fontSize="17px" sx={{ color: "#8172728c" }}>This Week</SoftTypography>
                      <SoftTypography fontWeight="bold" fontSize="17px">250 <Icon icon="ic:baseline-arrow-upward" color="red" width="20" height="25" rotate={2} /></SoftTypography>
                    </SoftBox>
                    <SoftBox width="20%" height="62px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", textAlign: "center", borderWidth: "2px" }} mr={2}>
                      <SoftTypography fontWeight="bold" fontSize="17px" sx={{ color: "#8172728c" }}>This Month</SoftTypography>
                      <SoftTypography fontWeight="bold" fontSize="17px">5500 <Icon icon="ic:baseline-arrow-upward" color="green" width="20" height="25" /></SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftTypography fontWeight="bold" fontSize="18px" mt={2}> Primary Data Distribution</SoftTypography>
                  <SoftBox display="flex" width="100%">
                    <SoftBox width="30%" height="120px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", textAlign: "center", borderWidth: "2px" }} mr={2}>
                      <SoftTypography fontWeight="regular" fontSize="15px" mb={1}>Tranding Job Roles</SoftTypography>
                      <SoftBox display="flex" flexWrap="wrap" sx={{ textAlign: "center" }}>
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" background="#a0c4f1" color="#a0c4f1" size="sm" badgeContent="Plumber" circular />
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="Carpainter" circular />
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="Welder" circular />
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="Housekeeping" circular />
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="Receptionist" circular />
                      </SoftBox>
                    </SoftBox>
                    <SoftBox width="25%" height="120px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", textAlign: "center", borderWidth: "2px" }} mr={2}>
                      <SoftTypography fontWeight="regular" fontSize="15px" mb={1}>Tranding Qualifaction</SoftTypography>
                      <SoftBox display="flex" flexWrap="wrap">
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="SSC" circular />
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="HSC" circular />
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="Gradute" circular />
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="ITI" circular />
                        <SoftBadge sx={{ marginBottom: "2px" }} variant="contained" mb={1} color="#063fbf" size="sm" badgeContent="Diploma" circular />
                      </SoftBox>
                    </SoftBox>
                    <SoftBox width="20%" height="120px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", textAlign: "center", borderWidth: "2px" }} mr={2}>
                      <SoftTypography fontWeight="regular" fontSize="15px" mb={1}>Tranding Age</SoftTypography>
                      <SoftTypography fontWeight="bold" fontSize="17px">20-25</SoftTypography>
                    </SoftBox>
                    <SoftBox width="20%" height="120px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", textAlign: "center", borderWidth: "2px" }} mr={2}>
                      <SoftTypography fontWeight="regular" fontSize="15px">Gender Ratio</SoftTypography>
                      <SoftTypography fontWeight="regular" fontSize="10px" sx={{ color: "#8172728c" }}>M:F</SoftTypography>
                      <SoftTypography fontWeight="bold" fontSize="15px">8:2</SoftTypography>
                      <SoftTypography fontWeight="regular" fontSize="10px" sx={{ color: "#8172728c" }}>21160:5290</SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftTypography fontWeight="bold" fontSize="18px" mt={2}  >Placement Statics</SoftTypography>
                  <SoftBox display="flex" width="100%" mr={2}>
                    <SoftBox width="30%" height="60px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", textAlign: "center", borderWidth: "2px" }} mr={2}>
                      <SoftTypography fontWeight="regular" fontSize="15px">In-Pipeline</SoftTypography>
                      <SoftTypography fontWeight="bold" fontSize="17px">25<Icon icon="ic:baseline-arrow-upward" color="green" width="20" height="25" /></SoftTypography>
                    </SoftBox>
                    <SoftBox width="30%" height="62px" sx={{ borderStyle: "dotted", borderColor: "rgb(221 221 221)", textAlign: "center", borderWidth: "2px" }} mr={2}>
                      <SoftTypography fontWeight="regular" fontSize="15px">Placed</SoftTypography>
                      <SoftTypography fontWeight="bold" fontSize="17px">250 <Icon icon="ic:baseline-arrow-upward" color="green" width="20" height="25" /></SoftTypography>
                    </SoftBox>

                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Card>
          </SoftBox>
          <SoftBox width="20%" mx={5}>

            <SoftBox display="flex" pb={2} pt={2}>
              <SoftBox width="100%">
                <SoftBox px={3}>
                  <img src={qrcode} style={{ width: "250px", height: "250px" }}></img> </SoftBox>
                <SoftTypography fontWeight="medium" fontSize="18px" mt={2} px={7}>Center Form Link</SoftTypography>

                <SoftBox width="100%" height="50px" mt={1} px={5}>
                  <Card>
                    <SoftTypography py={1} px={1} fontWeight="regular" fontSize="15px">https://www.google.com.sapioglobal.com/form</SoftTypography>
                  </Card>
                  <SoftBox display="flex">
                    <SoftBox mt={2} px={2}>
                      <Icon icon="logos:whatsapp-icon" color="green" width="25" inline={true} /> </SoftBox>
                    <SoftBox mt={2}> <Icon icon="logos:google-gmail" width="25" inline={true} /></SoftBox>
                  </SoftBox>
                </SoftBox>


              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" mb={2} mt={2}>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="7px"
            width={{ lg: "15%", sm: "48%", xs: "95%" }}
            mb={1}
            sx={{
              height: "40px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              padding: "10px",
              marginRight: "11px",
            }}
          >
            <SoftTypography
              flex="none"
              flexGrow="0"
              order="1"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px", cursor: "pointer" }}

            >
              Job Seekars
            </SoftTypography>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="7px"
            width={{ lg: "15%", sm: "48%", xs: "95%" }}
            mb={1}
            sx={{
              height: "40px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              padding: "10px",
              marginRight: "11px",
            }}
          >
            <SoftTypography
              flex="none"
              flexGrow="0"
              order="1"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px", cursor: "pointer" }}

            >
              Job Provider
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="100%" >
          {/* <DataTable
            table={jobseekartable}
            entriesPerPage={{
              defaultValue: 13,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            canSearch
          /> */}
          <Table {...tableProps}
              //  pagination={{position:["bottomCenter"]}}
                rowSelection={rowSelection} columns={colName} 
                // dataSource={data}
                // exportable
                exportableProps={{ showColumnPicker: true }}
                searchable
              />
          {/* <SoftBox position={{ lg: "absolute" }}
            mt={{ lg: "-800px", sm: "-673px", xs: "-780px" }} ml={{ lg: "74%", sm: "61%", xs: "0px" }}
          >
            <Stack spacing={1} direction="row">

              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                variant="contained"
                sx={{
                  backgroundColor: "#111727",
                  width: "156px",
                  height: "40px",
                  padding: "8px 26px",
                  borderRadius: "5px",
                }}
              >
                <SoftBox
                  component="img"
                  src={download}
                  flex="none"
                  flexGrow="0"
                  order="0"
                  width="15px"
                />
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        sx={{ color: "white !important", fontSize: "15px" }}
                        {...bindTrigger(popupState)}
                      >  Download
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}>
                          <Icon icon="mdi:file" inline={true} /> excel file
                        </MenuItem>
                        <MenuItem onClick={popupState.close}>
                          <Icon icon="mdi:file" inline={true} />
                          csv file
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </SoftBox>
            </Stack>
          </SoftBox> */}
        </SoftBox>
      </DashboardLayout>
    </>
  )
}

export default Centerheaddashboard