import React from "react";

import { Outlet, Navigate } from "react-router-dom";
const PrivateRoute = () => {
  let userid = sessionStorage.getItem("token") == null ? false : true;
  console.log(userid);
  return <>{userid ? <Outlet /> : <Navigate to="/login" />};</>;
};

export default PrivateRoute;
