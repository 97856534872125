import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import SoftSelect from "../../components/SoftSelect";
import jobseekartable from "../../pages/Jobseekar/jobseekartable";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React from "react";
import { Icon } from '@iconify/react';
import { Link, useNavigate } from "react-router-dom";

import download from "assets/icons/download_icon.png";


const Centerjobseekers = () => {
    const navigate =useNavigate();
  return (
    <>
    <DashboardLayout>
        <SoftBox mt={0}>
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              Candidates
              </SoftTypography>
              {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                Brief summary  Channel Partner
              </SoftTypography> */}
            </SoftBox>
          </SoftBox>
       

          <SoftBox display="flex" alignItems="flex-start" mb="29px" width="100%" flexWrap="wrap">
            <SoftBox
              width={{ lg: "20%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{ border: "0px", backgroundColor: "#fff", marginRight: "13px", borderRadius: "5px" }}
            >
              <SoftSelect
                placeholder="State"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}             
              />
              {/* <Select  placeholder="State" /> */}
            </SoftBox>
            <SoftBox width={{ lg: "20%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{ border: "0px", backgroundColor: "#fff", marginRight: "13px", borderRadius: "5px" }}>
              <SoftSelect
                placeholder="District"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              /></SoftBox>
            <SoftBox width={{ lg: "20%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{ border: "0px", backgroundColor: "#fff", marginRight: "13px", borderRadius: "5px" }}>
              <SoftSelect
                placeholder="Taluka"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              />
            </SoftBox>

            <SoftBox
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap="7px"
              width={{ lg: "15%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{
                height: "40px",
                backgroundColor: "#3B5092",
                borderRadius: "5px",
                padding: "10px",
                marginRight: "11px",
                cursor: "pointer"
              }}
              onClick={() => navigate("/centerhead/centerjobseekars")}
            >
              <SoftTypography flex="none" flexGrow="0" order="0" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                +
              </SoftTypography>

              <SoftTypography flex="none" flexGrow="0" order="1" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                Add JobSeekers
              </SoftTypography>
            </SoftBox>

            <SoftBox 
             display="flex"
             flexDirection="row"
             justifyContent="center"
             alignItems="center"
             gap="7px"
             width={{ lg: "15%", sm: "48%", xs: "95%" }}
             mb={1}
             sx={{
               height: "40px",
               backgroundColor: "#3B5092",
               borderRadius: "5px",
               padding: "13px 19px",
             }}>
              <SoftTypography flex="none" flexGrow="0" order="0" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                +
              </SoftTypography>
              <SoftTypography flex="none" flexGrow="0" order="1" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                Add Bulk
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="100%" >
              <DataTable            
                table={jobseekartable}
                entriesPerPage={{
                  defaultValue: 13,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
              />
              <SoftBox position={{ lg: "absolute" }}
                mt={{ lg: "-775px", sm: "-673px", xs: "-780px" }} ml={{ lg: "74%", sm: "61%", xs: "0px" }}
              >
                <Stack spacing={1} direction="row">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          sx={{
                            color: "white !important",
                            backgroundColor: "#81BBFF",
                            width: "104px",
                            height: "35px",
                          }}
                          {...bindTrigger(popupState)}
                        >
                          {" "}
                          Action
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem onClick={popupState.close}>
                            <Icon icon="mdi:lead-pencil" inline={true} /> &nbsp;Modify
                          </MenuItem>
                          <MenuItem onClick={popupState.close}>
                            <Icon icon="ic:round-delete-forever" inline={true} />
                            &nbsp;Deactivate
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>

                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="10px"
                    variant="contained"
                    sx={{
                      backgroundColor: "#111727",
                      width: "156px",
                      height: "40px",
                      padding: "8px 26px",
                      borderRadius: "5px",
                    }}
                  >
                    <SoftBox
                      component="img"
                      src={download}
                      flex="none"
                      flexGrow="0"
                      order="0"
                      width="15px"
                    />
                    {/* <SoftTypography sx={{color:"#fff",fontSize:"15px"}} flex="none" flexGrow="1" order="1">Download</SoftTypography> */}
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          {/* {...bindTrigger(popupState)} onClick={popupState.open}*/}
                          <Button
                            sx={{ color: "white !important", fontSize: "15px" }}
                            {...bindTrigger(popupState)}
                          >
                            Download
                          </Button>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close}>
                              <Icon icon="mdi:file" inline={true} /> excel file
                            </MenuItem>
                            <MenuItem onClick={popupState.close}>
                              <Icon icon="mdi:file" inline={true} />
                              csv file
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </SoftBox>
                </Stack>
              </SoftBox>
            </SoftBox>  
          {/* </Card> */}
        </SoftBox>
      </DashboardLayout>

    </>
  )
}

export default Centerjobseekers