import { Card, Stack, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import SoftSelect from "../../components/SoftSelect";
import SoftInput from "components/SoftInput";
// import { suparadminTableData } from "./suparadmintable";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import React from "react";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import download from "assets/icons/download_icon.png";
import Mapdata from "pages/Mapdata/Mapdata";
import { useState, useEffect } from "react";
import axios from "axios";
import { Table, Dropdown } from "antd";
import Grid from "@mui/material/Unstable_Grid2";
import AdminEdit from "pages/EditModals/Admin/adminEdit";
import PasswordResetAdmin from "pages/EditModals/Admin/AdminPasswordReset";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import "../style.css";
import Swal from "sweetalert2";
import FadeLoader from "react-spinners/FadeLoader";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual';
import { useDispatch } from 'react-redux';

const Admin = () => {
  const navigate = useNavigate();
  const translate = useTranslate()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [admindata, Setadmindata] = useState([]);
  const [editadmin, Seteditadmin] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [editdata, Seteditdata] = useState({});
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectCol, setSelectCol] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [val, setVal] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tablerow, setTablerow] = useState([]);
  const data = admindata?.map((item, id) => {
    return {
      key: item.registration_id,
      // organization: item.org_name ,
      // location: item.org_address,
      name: `${item.firstnm + " " + item.lastnm}`,
      firstnm: item.firstnm,
      lastnm: item.lastnm,
      sequrity_ques: item.security_ques,
      sequrity_ans: item.security_ans,
      email: item.email,
      mobnum: item.mobnum.substring(2),
      date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
    };
  });
  const [KPIObj, setKPIObj] = useState({
    job_seeker_on_hold: "",
    job_seeker_in_pipeline: "",
    job_seekers_hired: "",
    total_jobseekers: "",
  });
  const [jobPosted, setJobPosted] = useState("");
  const [adminCount, setAdminCount] = useState("");
  // const [searchData, setSearchData] = useState(data);
  const [name, setName] = useState("");
  const [load, setLoad] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(data, newSelectedRowKeys);
    setTablerow(selectedRows);
  };
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };

  const items = [
    {
      key: "1",
      label: (
        <a style={{ display: "flex" }} className="dropdown-item" href="#EditUser">
          <div>
            <Icon icon="mdi:lead-pencil" />
          </div>
          &nbsp; Edit Profile
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a className="dropdown-item" href="#EditUser" style={{ display: "flex" }}>
          <div>
            <Icon icon="mdi:password-reset" />
          </div>
          &nbsp; Reset Password
        </a>
      ),
    },
  ];

  const filterOffsetFunc = () => {
    setPaginationState({ ...paginationState, page: 1 });
  };

  const colName = [
    // {title:"Organization",
    //   dataIndex:"organization",
    //   sorter: {
    //     compare: (a, b) => a.organization - b.organization,
    //     multiple: 1,
    //   },
    // },
    //   {title:"Location",
    //   dataIndex:"location",
    //       sorter: {
    //       compare: (a, b) => a.location - b.location,
    //       multiple: 2,
    //     },
    // },
    {
      title: "Registration ID",
      dataIndex: "key",
      fixed: "left",
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
        multiple: 1,
      },
    },
    {
      title: "Name",
      // dataIndex: "name",
      fixed: "left",
      render: (text, record) => (
        <>
          {`${text?.firstnm[0].toUpperCase() + text?.firstnm.slice(1)}` +
            " " +
            `${text?.lastnm[0].toUpperCase() + text?.lastnm.slice(1)}`}
        </>
      ),
      sorter: {
        compare: (a, b) => a?.firstnm?.localeCompare(b?.firstnm),
        multiple: 1,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 2,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      sorter: {
        compare: (a, b) => a.mobnum - b.mobnum,
        multiple: 3,
      },
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: {
        compare: (a, b) => a?.state?.localeCompare(b?.state),
        multiple: 2,
      },
    },
    {
      title: "District",
      dataIndex: "district",
      sorter: {
        compare: (a, b) => a?.district?.localeCompare(b?.district),
        multiple: 2,
      },
    },
    {
      title: "Date Of Creation",
      dataIndex: "date_of_creation",
      sorter: {
        compare: (a, b) =>
          moment(a.date_of_creation, "DD-MM-YYYY") - moment(b.date_of_creation, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   fixed: 'right',
    //   width: 50,
    //   render: () => <ActionBtn/>,
    // },
    {
      title: "Status",
      // dataIndex: "status",
      width: 150,
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 7,
      },
      render: (text, record) => (
        console.log(text),
        (
          <div
            className="dropdown"
            style={{
              width: "100%",
              height: "30px",
              // border: "1px solid ",
              borderRadius: "15px",
              backgroundColor: text.status ? "#BDFDC8" : "#EFD6FF",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                // padding: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                padding: "5px 0px 5px",
              }}
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              {text.status ? "Active" : "Deactivate"}
            </p>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => (text.status ? deActiveFun(text) : ActiveFun(text))}
              >
                {/* <i
                className={
                  text.job_status
                    ? "fa fa-dot-circle-o text-danger"
                    : "fa fa-dot-circle-o text-success"
                }
              /> */}
                {text.status ? "Deactivate" : "Activate"}
              </a>
            </div>
          </div>
        )
      ),
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      width: 100,
      render: (admindata) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "1") {
                  Seteditadmin(true);
                  Seteditdata(admindata);
                } else {
                  setResetPassword(true);
                  Seteditdata(admindata);
                }
              },
            }}
          >
            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
  const [colHeaders, setColHeaders] = useState([
    // {
    //   label: "Organization",
    //   key: "organization",
    //   isExportable: true,
    // },
    // {
    //   label: "Location",
    //   key: "location",
    //   isExportable: true,
    // },
    {
      label: "Registration ID",
      key: "key",
      isExportable: true,
    },
    {
      label: "Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "mobnum",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Date of Creation",
      key: "date_of_creation",
      isExportable: true,
    },
    {
      label: "Status",
      key: "status",
      isExportable: true,
    },
  ]);

  console.log(searchData);
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );

  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", selectedCSVData);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      // {
      //   key: "odd",
      //   text: "Select Odd Row",
      //   onSelect: (changeableRowKeys) => {
      //     let newSelectedRowKeys = [];
      //     newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
      //       if (index % 2 !== 0) {
      //         return false;
      //       }
      //       return true;
      //     });
      //     setSelectedRowKeys(newSelectedRowKeys);
      //   },
      // },
      // {
      //   key: "even",
      //   text: "Select Even Row",
      //   onSelect: (changeableRowKeys) => {
      //     let newSelectedRowKeys = [];
      //     newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
      //       if (index % 2 !== 0) {
      //         return true;
      //       }
      //       return false;
      //     });
      //     setSelectedRowKeys(newSelectedRowKeys);
      //   },
      // },
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };

  useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);
  const deActiveFun = (data) => {
    console.log(roleId, data.key, "BBB");
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        // deactivate_role: roleId,
        // "partner-association": "samarth",
        deactivate_id: data.key,
        deactivatedby: registerID,
        active: "false",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Admin Deactivated",
          //text: "Super Admin Deactivated",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchadminList();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const ActiveFun = (data) => {
    console.log(roleId, data.key, "BBB");
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID

        // deactivate_role: roleId,
        // "partner-association": "samarth",
        deactivate_id: data.key,
        deactivatedby: registerID,
        active: "true",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Admin Activated",
          //text: "Super Admin Activated",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchadminList();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);

  const fetchadminList = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_DASHBOARD_URL}/admin/adminlist`, {
        params: {
          // limit: 10,
          limit: pageSize,
          offset: paginationState.page - 1,
          search_data: name,
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
          
        },
      })
      .then((res) => {
        setLoad(false);
        console.log(res.data["Admin_List"]);
        setAdminCount(res.data["Admin_List"].length);
        Setadmindata(
          res.data["Admin_List"]?.map((item, id) => {
            return {
              key: item.registration_id,
              // organization: item.org_name ,
              // location: item.org_address,
              name:
                item?.firstnm[0].toUpperCase() +
                item.firstnm.slice(1) +
                " " +
                item?.lastnm[0].toUpperCase() +
                item.lastnm.slice(1),
              firstnm: item.firstnm,
              lastnm: item.lastnm,
              sequrity_ques: item.security_ques,
              sequrity_ans: item.security_ans,
              email: item.email,
              mobnum: item.mobnum.substring(2),
              date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
              status: item.is_active,
              state: item.org_state ? item.org_state : " - ",
              district: item.org_city ? item.org_city : " - ",
            };
          })
        );
        setSearchData(
          res.data["Admin_List"]?.map((item, id) => {
            return {
              key: item.registration_id,
              // organization: item.org_name ,
              // location: item.org_address,
              name:
                item?.firstnm[0].toUpperCase() +
                item.firstnm.slice(1) +
                " " +
                item?.lastnm[0].toUpperCase() +
                item.lastnm.slice(1),
              firstnm: item.firstnm,
              lastnm: item.lastnm,
              sequrity_ques: item.security_ques,
              sequrity_ans: item.security_ans,
              email: item.email,
              mobnum: item.mobnum.substring(2),
              date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
              status: item.is_active,
              state: item.org_state ? item.org_state : " - ",
              district: item.org_city ? item.org_city : " - ",
            };
          })
        );
        setIsLoading(false);
        setPaginationState({ ...paginationState, dataSize: res.data["count"] });
      })
      .catch((e) => {
        console.log(e);
        setLoad(false);
      });
  };

  // const fetchStateList = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_URL}/generic/state`,
  //     )
  //     .then((res) => {

  //       setState(res.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // const fetchDistricts = () => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BACKEND_URL}/generic/district`,
  //       {
  //         state: selectedState,
  //       }
  //     )
  //     .then((res) => {
  //       setDistrict(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchJobSeekersKPIs = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/fetch/kpi`,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "", 
        }
      })
      .then((res) => {
        setKPIObj({
          job_seeker_on_hold: res.data["job_seeker_on_hold"],
          job_seeker_in_pipeline: res.data["job_seeker_in_pipeline"],
          job_seekers_hired: res.data["job_seekers_hired"],
          total_jobseekers: res.data["total_jobseekers"],
        });
        // setKPIObj({ ...KPIObj, job_seekers_hired: res.data["job_seekers_hired"] });
        // setKPIObj({ ...KPIObj, jobs_created: res.data["jobs_created"] });
        // setKPIObj({ ...KPIObj, registered_providers: res.data["registered_providers"] });
      })
      .catch((err) => {
        console.log("error kpi", err);
      });
  };
  const fetchJobPostKpi = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostkpi`,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      })
      .then((res) => {
        console.log("all kpi", res.data);
        setJobPosted(res.data.Job_Count);
        console.log(jobPosted);
        // setJobApplied(res.data.CandidateApplied_Count);
        // setApplicantSelected(res.data.CandidateSelected_Count);
        // setApplicantShortlisted(res.data.Shortlisted_Count);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const fetchAdminCountKpi = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/overall_kpi`,{
        params:{
          admin_id: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      })
      .then((res) => {
        console.log("admin kpi", res.data["admin_count"]);
        // setJobPosted(res.data.Job_Count);
        setAdminCount(res.data["admin_count"]);
        console.log("admin count", adminCount);
        // setJobApplied(res.data.CandidateApplied_Count);
        // setApplicantSelected(res.data.CandidateSelected_Count);
        // setApplicantShortlisted(res.data.Shortlisted_Count);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSearch = () => {
    const newData = admindata.filter((ele) => {
      return name?.toLocaleLowerCase() === ""
        ? ele
        : ele.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
          name.toLocaleLowerCase() === ""
        ? ele
        : String(ele.mobnum).toLocaleLowerCase().includes(name.toLocaleLowerCase());
    });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    setSearchData(newData);
  };
  useEffect(() => {
    handleSearch();
  }, [name]);

  useEffect(() => {
    fetchadminList();
    fetchStateList();
    fetchJobSeekersKPIs();
    fetchJobPostKpi();
    fetchAdminCountKpi();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  console.log(KPIObj, "PQR");

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={2}>
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              {translate('admin')}       
              </SoftTypography>
              {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                Brief summary  Channel Partner
              </SoftTypography> */}
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totaladmin')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {adminCount === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    adminCount
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totaljobcreate')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {jobPosted === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    jobPosted
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalcandidatesplaced')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seekers_hired === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seekers_hired
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('holdcandidate')}  
                   
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_on_hold === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_on_hold
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* <Mapdata
            h1="Total Admin Registered"
            h2="Total Job Created"
            h3="Total Candidates Hired"
            h4="Total Candidates On Hold"
            v1={adminCount}
            v2={jobPosted}
            v3={KPIObj.job_seekers_hired}
            v4={KPIObj.job_seeker_on_hold}
          /> */}

          {/* <SoftBox display="flex" alignItems="flex-start" mb="29px" width="100%" flexWrap="wrap">
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  width="350px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                  }}
                >
                  <SoftSelect
                    placeholder="State"
                    options={state}
                    // value={search}
                    onChange={(currentTarget) => {
                      setSelectedState(currentTarget.value);
                    }}
                  />
                  {/* <Select  placeholder="State" /> *
                </SoftBox>
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  width="350px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                  }}
                >
                  <SoftSelect
                    placeholder="District"
                    options={district}
                    onChange={(currentTarget) => {
                      setSelectedDistrict(currentTarget.value);
                    }}
                  />
                </SoftBox>
              </Grid>
              {/* <SoftBox width={{ lg: "24%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{
                border: "0px",
                backgroundColor: "#fff",
                marginRight: "13px",
                borderRadius: "5px",
              }}>
              <SoftSelect
                placeholder="Taluka"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              />
            </SoftBox> *
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-center"
                  justifyContent="center"
                  gap="24px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  width="350px"
                  mb={1}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  padding={{ lg: "10px 5%", sm: "12px 80px", xs: "10px 70px" }}
                  onClick={() => navigate("/super-admin-registration-page")}
                >
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="0"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    +
                  </SoftTypography>
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="1"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Super Admin
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox> */}
          {/* <SoftBox>
            Select Columns
            
            <Select options={colName} sx={{width:"100px"}}
            defaultValue={[]}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            // options={empList}
            // components={{
            //   Option: InputOption,
            // }}
            allowSelectAll={true}
            value={selectCol}
            onChange={(e) => {
              if (e) {
                setSelectCol(e.map((item) => item));
              } else {
                setSelectCol([]);
              }
            }}
            />
          </SoftBox> */}
          {/* <SoftBox width="50%" alignItems="flex-start">
            <Input.Search placeholder="search"  value={val} 
              onChange={(e)=>{
                const currValue = e.target.value
                setVal(currValue);
                const filteredVals = data.filter(entry =>
                  entry.val.includes(currValue)
                )
                setSearchData(filteredVals);
              }}
            />
          </SoftBox> */}
          <SoftBox
            alignItems="flex-start"
            position="relative"
            borderRadius="10px"
            sx={{ sm: { width: "50%" }, md: { width: "100%" } }}
          >
            {/* <DataTable            
                table={suparadminTableData}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
              /> */}
            {/* {selectedRowKeys.length > 1 && (
              // <Button variant="contained" color="success" >
              //   Deactivate
              // </Button>
              <SoftBox
                width="120px"
                ml={{ sm: "450px", md: "850px" }}
                sx={{
                  position: "absolute",
                  // marginLeft: "850px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "32px",
                  // backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  border: "1px solid red",
                  cursor: "pointer",
                }}
                // onclick to deactivate all
              >
                <SoftTypography
                  sx={{ color: "red", fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                >
                  Deactivate All
                </SoftTypography>
              </SoftBox>
            )}

            <SoftBox
              width="120px"
              ml={{ xs: "600px", md: "1075px" }}
              // mr="25px"
              sx={{
                position: "absolute",
                // marginLeft:"1000px",
                // marginRight: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "32px",
                // backgroundColor: "#3B5092",
                borderRadius: "5px",
                border: "1px solid ",
                cursor: "pointer",
              }}
              // onclick to deactivate all
            >
              <SoftTypography
                sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                onClick={() => (
                  setShow(!show),
                  // setFileName(() => {
                  //   getFileName();
                  // })
                  getFileName()
                )}
              >
                Export CSV
              </SoftTypography>
              {/* <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink> *
            </SoftBox> */}
            <SoftBox display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
              <SoftBox width={{ lg: "500px", md: "400px" }}>
                <SoftInput
                  isClearable
                  // width={{ lg: "1000px", md: "600px" }}
                  // width="1000px"
                  id="search-bar"
                  className="text"
                  // onInput={(e) => {
                  //   setSearchQuery(e.target.value);
                  // }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  placeholder="Search by name and phone number"
                  size="small"
                />
              </SoftBox>
              {/* {selectedRowKeys.length > 1 && (
                // <Button variant="contained" color="success" >
                //   Deactivate
                // </Button>
                <SoftBox
                  // width={{ lg: "120px", md: "80px", xs: "60px" }}
                  width="120px"
                  ml={{ lg: "920px", md: "450px", xs: "180px" }}
                  sx={{
                    position: "absolute",
                    // marginLeft: "850px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    // backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    border: "1px solid red",
                    cursor: "pointer",
                  }}
                  // onclick to deactivate all
                >
                  <SoftTypography
                    sx={{
                      color: "red",
                      fontWeight: "medium",
                      fontSize: "15px",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      padding: "auto",
                    }}
                    // fontSize={{ lg: "15px", md: "12px", xs: "7px" }}
                  >
                    Deactivate All
                  </SoftTypography>
                </SoftBox>
              )} */}
              <SoftBox display="flex" flexDirection="row" gap="10px" justifyContent="space-between">
                {/*
                <SoftBox
                  width="120px"
                  // ml={{ lg: "1070px", md: "600px", xs: "300px" }}
                  sx={{
                    // position: "absolute",
                    // marginLeft:"1000px",
                    // marginLeft: "1000px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    // backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    border: "1px solid ",
                    cursor: "pointer",
                  }}
                // onclick to deactivate all
                >
                  
                  <SoftTypography
                    sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                    onClick={() => (
                      setShow(!show),
                      // setFileName(() => {
                      //   getFileName();
                      // })
                      getFileName()
                    )}
                  >
                    Export CSV
                  </SoftTypography>
                  <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink>
                </SoftBox>
                */}

                <Tooltip
                  key="1"
                  title={tablerow.length === 0 ? "Download All" : "Download"}
                  placement="top"
                >
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#000000",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => (
                      setShow(!show),
                      // setFileName(() => {
                      //   getFileName();
                      // })
                      getFileName()
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="34"
                      viewBox="0 0 46 34"
                      fill="none"
                    >
                      <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                      <path
                        d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.9531 16.5098V23.0102"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </Tooltip>

                <SoftBox
                  sx={{
                    height: "33px",
                    backgroundColor: "#3B5092",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="40px"
                  onClick={() => navigate("/admin-registration-page")}
                >
                  <SoftBox sx={{ color: "#FFF" }}>+</SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <Table
              // {...tableProps}
              //  pagination={{position:["bottomCenter"]}}
              loading={isLoading}
              rowSelection={rowSelection}
              columns={colName}
              dataSource={searchData}
              scroll={searchData.length <= 2 ? { x: 1800, y: 400 } : { x: 1800 }}
              pagination={{
                // defaultPageSize: ,
                // position: ["none", "bottomCenter"],
                pageSizeOptions: ["10", "20", "50", "100", "500"],
                total: paginationState.dataSize,
                showSizeChanger: true,
                // onShowSizeChange: { onShowSizeChange },
                onShowSizeChange: handlePageSizeChange,
                current: paginationState.page,
                onChange: (page, pageSize) =>
                  setPaginationState({ ...paginationState, page: page }),
              }}
              // exportable
              // exportableProps={{ showColumnPicker: true }}
              // searchable
            />

            <AdminEdit
              editadmin={editadmin}
              Seteditadmin={Seteditadmin}
              editdata={editdata}
              fetchadminList={fetchadminList}
            />

            {/* reset password  */}
            <PasswordResetAdmin
              editadmin={resetPassword}
              Seteditadmin={setResetPassword}
              editdata={editdata}
            />
          </SoftBox>
          {/* </Card> */}
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {colHeaders.map((ele, index) => (
                <>
                  <ul className="UlCSV" key={index}>
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={tablerow.length === 0 ? searchData : tablerow}
                    //data={selectedCSVData}
                    filename={fileName}
                    headers={colHeaders.filter((ele) => ele.isExportable === true)}
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* modal for export */}
      </DashboardLayout>
    </>
  );
};

export default Admin;
