import React, { useState } from "react";
import DatamapsIndia from "react-datamaps-india";
import India from "@svg-maps/india";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";

const MapChart = () => {
  const [stateCode, setStateCode] = useState("");
  const [stateName, setStateName] = useState("");

  function onLocationClick(event) {
    setStateCode(3000);
    setStateName(event.target.getAttribute("name"));
    // console.log(event.target);
    console.log("Id", event.target.id);
    console.log("Name", event.target.getAttribute("name"));
  }
  return (
    <div
      style={{
        width: "500px",
        height: "550px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          marginLeft: "12rem",
          width: "252px",
          borderRadius: "13px",
          boxShadow: "0px 4px 12px 0px #00000033",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",

          background: "#ffff",
          padding:'0 5px',
        }}
      >
        <p style={{ fontWeight: "bold"  , marginTop:'20px'}}>{stateName}</p>
        <span> : </span>
        <span style={{ color: "#FF8C0E" }}>{stateCode}</span>
      </div>

      <SVGMap style={{ marginLeft: "12rem" }} map={India} onLocationMouseOver={onLocationClick} />
    </div>
  );
};

export default MapChart;
