import { Card, Stack, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";
// import centertable from "./centertable";
import SoftSelect from "../../components/SoftSelect";
import SoftBadge from "components/SoftBadge";
import { Link, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import download from "assets/icons/download_icon.png";
import Mapdata from "pages/Mapdata/Mapdata";
import SoftInput from "components/SoftInput";
// import { Table } from "ant-table-extensions";
// importing data for table temporirly from superadmin table.js
// import { data } from "../Suparadmin/suparadmintable";
import { Table, Dropdown } from "antd";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import { MdMoreVert } from "react-icons/md";
import AdminEdit from "pages/EditModals/Centers/centerEdit";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment/moment";
import { Modal } from "react-bootstrap";
import ReactSwitch from "react-switch";
import Swal from "sweetalert2";
import "../style.css";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual';
import { useDispatch } from 'react-redux';
import CounsellorEdit from "pages/EditModals/Counsellor/CounsellorEdit";
import CounsellorEditProfile from "pages/EditModals/Counsellor/CounsellorEditProfile";
const Counsellour = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [registerID, setRegisterID] = useState(sessionStorage.getItem("register_id"));
  const [roleId, setRoleId] = useState("SA");
  const [admindata, Setadmindata] = useState([]);
  const [tablerow, setTablerow] = useState([]);
  const [editadmin, Seteditadmin] = useState(false);
  const [editdata, Seteditdata] = useState({});
  const [checked, setChecked] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [pageSize, setPageSize] = useState(10);
  const handleChange = (val) => {
    setChecked(val);
  };
  useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);
  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };

  const filterOffsetFunc = () => {
    setPaginationState({ ...paginationState, page: 1 });
  };

  const items = [
    {
      key: '1',
      label: (
        <a
          style={{ display: "flex" }}
          className="dropdown-item"
          href="#EditUser"
        >
          <div>
            <Icon icon="mdi:lead-pencil" />
          </div>
          &nbsp; Edit Profile
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          className="dropdown-item"
          href="#EditUser"
          style={{ display: "flex" }}
        >
          <div>
            <Icon icon="mdi:password-reset" />
          </div>
          &nbsp; Reset Password
        </a>
      ),
    },
  ];

  const colName = [
    {
      title: "Registration ID",
      dataIndex: "key",
      width: 200,
      fixed: "left",
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
        multiple: 1,
      },
    },
    {
      title: "Name",
      // dataIndex: "name",
      fixed: "left",
      render: (text, record) => (
        <>
          {`${text?.firstnm[0].toUpperCase() + text?.firstnm.slice(1)}` +
            " " +
            `${text?.lastnm[0].toUpperCase() + text?.lastnm.slice(1)}`}
        </>
      ),
      width: 200,
      sorter: {
        compare: (a, b) => a?.firstnm?.localeCompare(b?.firstnm),
        multiple: 1,
      },
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   sorter: {
    //     compare: (a, b) => a.email - b.email,
    //     multiple: 2,
    //   },
    // },
    {
      title: "Role",
      dataIndex: "role",
      width: 150,
      sorter: {
        compare: (a, b) => a?.role?.localeCompare(b?.role),
        multiple: 3,
      },
    },
    // {
    //   title: "State",
    //   dataIndex: "org_state",
    //   sorter: {
    //     compare: (a, b) => a.org_state - b.org_state,
    //     multiple: 3,
    //   },
    // },
    // {
    //   title: "District",
    //   dataIndex: "org_district",
    //   sorter: {
    //     compare: (a, b) => a.org_district - b.org_district,
    //     multiple: 3,
    //   },
    //   render: (text) => <p>{text === null ? " - " : text}</p>,
    // },
    {
      title: "Created For",
      dataIndex: "partner_association",
      width: 150,
      sorter: {
        compare: (a, b) => a?.partner_association?.localeCompare(b?.partner_association),
        multiple: 4,
      },
    },
    {
      title: "Supervisor",
      dataIndex: "supervisor",
      width: 150,
      sorter: {
        compare: (a, b) => a?.supervisor?.localeCompare(b?.supervisor),
        multiple: 5,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      width: 150,
      sorter: {
        compare: (a, b) => a.mobnum - b.mobnum,
        multiple: 6,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 7,
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      width: 150,
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 7,
      },
      render: (text, record) => (
        <div
          className="dropdown"
          style={{
            width: "100%",
            height: "30px",
            // border: "1px solid ",
            borderRadius: "15px",
            backgroundColor: text.status ? "#BDFDC8" : "#EFD6FF",
            cursor: "pointer",
          }}
        >
          <p
            style={{
              // padding: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              padding: "5px 0px 5px",
            }}
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            {text.status ? "Active" : "Deactivate"}
          </p>
          <div className="dropdown-menu">
            <a
              className="dropdown-item"
              href="#"
              onClick={() => (text.status ? deActiveFun(text) : ActiveFun(text))}
            >
              {/* <i
                className={
                  text.job_status
                    ? "fa fa-dot-circle-o text-danger"
                    : "fa fa-dot-circle-o text-success"
                }
              /> */}
              {text.status ? "Deactivate" : "Activate"}
            </a>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      width: 100,
      render: (admindata) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === '1') {
                  Seteditadmin(true);
                  Seteditdata(admindata);
                }
                else {
                  setResetPassword(true);
                  Seteditdata(admindata);
                }
              }
            }}>

            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   fixed: 'right',
    //   width: 50,
    //   render: () => <MdMoreVert/>,
    // },
  ];
  const [colHeaders, setColHeaders] = useState([
    {
      label: "Registration ID",
      key: "key",
      isExportable: true,
    },
    {
      label: "Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "Role",
      key: "role",
      isExportable: true,
    },
    {
      label: "Created For",
      key: "partner_association",
      isExportable: true,
    },
    {
      label: "Supervisor",
      key: "supervisor",
      isExportable: true,
    },
    // {
    //   label: "Location",
    //   key: "location",
    //   isExportable: true,
    // },
    //{
    //   label: "Created By",
    //   key: "created_by",
    //   isExportable: true,
    // },
    // {
    //   label: "Center Head",
    //   key: "center_head",
    //   isExportable: true,
    // },

    {
      label: "Phone Number",
      key: "mobnum",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Status",
      key: "status",
      isExportable: true,
    },
  ]);
  // const data =admindata?.map((item ,id)=>{return {
  //   key: item.registration_id,
  //   // organization: item.org_name ,
  //   // location: item.org_address,
  //   name: `${item.firstnm+" "+item.lastnm}`,
  //   firstnm: item.firstnm,
  //   lastnm: item.lastnm,
  //   sequrity_ques: item.sequrity_ques,
  //   sequrity_ans: item.sequrity_ans,
  //   email: item.email,
  //   mobnum: item.mobnum,
  //   date_of_creation: moment(item.createddt).utc().format('DD-MM-YYYY') ,
  // }});

  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectCol, setSelectCol] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [val, setVal] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [name, setName] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [show, setShow] = useState(false);
  const [KPIObj, setKPIObj] = useState({
    selected_jobseeker: "",
    total_calls: "",
    total_counsellors: "",
    total_jobseekers: "",
  });
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(admindata, newSelectedRowKeys);
    setTablerow(selectedRows)
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", selectedCSVData);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };
  const ActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        // deactivate_role: roleId,
        // "partner-association": "samarth",
        active: "true",
        deactivatedby: registerID,
      })
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: res.data["Developer Message"],
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        fetchadminList();
        // fetchOrgList();
      })
      .catch((e) => {
        console.log("err", e);
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        // deactivate_role: roleId,
        // "partner-association": "samarth",
        active: "false",
        deactivatedby: registerID,
      })
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: res.data["Developer Message"],
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        fetchadminList();
        // fetchOrgList();
      })
      .catch((e) => {
        console.log("err", e);
        Swal.fire({
          icon: "error",
          //title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          // text: e.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);

  const fetchadminList = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/dashboard/fetch/all/details`, {
        params: { createdby: registerID },
      })
      .then((res) => {
        console.log(res.data["counsellor_details"], "counsellor_details");
        setIsLoad(false);
        // Setadmindata(res.data["SuperAdmin_List"]);
        Setadmindata(
          res.data["counsellor_details"]?.map((item, id) => ({
            key: item.registration_id,
            // organization: item.org_name ,
            // location: item.org_address,
            name: item.firstnm + " " + item.lastnm,
            role: item.counsellor_type,
            firstnm: item.firstnm,
            lastnm: item.lastnm,
            // location: `${item.org_state} ${","} ${item.org_district}`,
            // org_address: item.org_address,
            // org_state: item.org_state,
            // org_district: item.org_district,
            // org_size: item.org_size,
            // industry: item.org_type,
            // org_sector: item.org_sector,
            // logindt: item.logindt,
            // job_status: item.is_active,
            // security_ans: item.security_ans,
            // security_ques: item.security_ques,
            assigned_to_owner: item.assigned_to_owner,
            email: item.email,
            mobnum: item?.mobnum?.length === 12 ? item?.mobnum?.substring(2) : item?.mobnum,
            supervisor: item.supervisor_name,
            partner_association: item.partner_association,
            security_ques: item.security_ques,
            security_ans: item.security_ans,
            // org_pincode: item.org_pincode,
            // org_address: item.org_address,
            // org_cin_num: item.org_cin_num,
            // org_district: item.org_district,
            // org_state: item.org_state,
            // org_type: item.org_type,
            // org_name: item.org_name,
            date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
            status: item.is_active,
          }))
        );
        setSearchData(
          res.data["counsellor_details"]?.map((item, id) => ({
            key: item.registration_id,
            // organization: item.org_name ,
            // location: item.org_address,
            name:
              item?.firstnm[0].toUpperCase() +
              item.firstnm.slice(1) +
              " " +
              item?.lastnm[0].toUpperCase() +
              item.lastnm.slice(1),
            role: item.counsellor_type,
            firstnm: item.firstnm,
            lastnm: item.lastnm,
            assigned_to_owner: item.assigned_to_owner,
            // location: `${item.org_state} ${","} ${item.org_district}`,
            // org_address: item.org_address,
            // org_state: item.org_state,
            // org_district: item.org_district,
            // org_size: item.org_size,
            // industry: item.org_type,
            // org_sector: item.org_sector,
            // logindt: item.logindt,
            // job_status: item.is_active,
            // firstnm: item.firstnm,
            // lastnm: item.lastnm,
            // security_ans: item.security_ans,
            // security_ques: item.security_ques,
            email: item.email,
            mobnum: item?.mobnum?.length === 12 ? item?.mobnum?.substring(2) : item?.mobnum,
            supervisor: item.supervisor_name,
            partner_association: item.partner_association,
            security_ques: item.security_ques,
            security_ans: item.security_ans,
            // org_pincode: item.org_pincode,
            // org_address: item.org_address,
            // org_cin_num: item.org_cin_num,
            // org_district: item.org_district,
            // org_state: item.org_state,
            // org_type: item.org_type,
            // org_name: item.org_name,
            date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
            status: item.is_active,
          }))
        );
        setPaginationState({ ...paginationState, dataSize: res.data["count"] });
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoad(false);
      });
  };

  console.log(searchData);

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCounsellorsKPIs = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/dashboard/superadmin/fetch/counsellor/kpi`,
        {
          params: {
            createdby: registerID,
          },
        }
      )
      .then((res) => {
        setKPIObj({
          selected_jobseeker: res.data["selected_jobseeker"],
          total_calls: res.data["total_calls"],
          total_counsellors: res.data["total_counsellors"],
          total_jobseekers: res.data["total_jobseekers"],
        });
      })
      .catch((err) => {
        console.log("error kpi", err);
      });
  };
  const handleSearch = () => {
    const newData = admindata.filter((ele) => {
      return name?.toLocaleLowerCase() === ""
        ? ele
        : ele.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
          name.toLocaleLowerCase() === ""
          ? ele
          : String(ele.mobnum).toLocaleLowerCase().includes(name.toLocaleLowerCase());
    });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    setSearchData(newData);
  };
  useEffect(() => {
    handleSearch();
  }, [name]);
  useEffect(() => {
    fetchadminList();
    fetchStateList();
    fetchCounsellorsKPIs();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              {translate('counsellor')}       
              </SoftTypography>
              {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                Brief summary  Channel Partner
              </SoftTypography> */}
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalcounsellor')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_counsellors === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_counsellors
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalcandidates')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_jobseekers === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_jobseekers
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalcall')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_calls === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_calls
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="25%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('selectedcand')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.selected_jobseeker === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.selected_jobseeker
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* <Mapdata
            h1="Total Channel Partner Add"
            h2="Total Job Center Created"
            h3="Total JobSeekers Add"
            h4="Total Provider Registered"
            v1="50"
            v2="20"
            v3="4"
            v4="20"
          /> */}
          {/* <SoftBox display="flex" alignItems="flex-start" mb="29px" width="100%" flexWrap="wrap">
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  width="350px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                  }}
                >
                  <SoftSelect
                    placeholder="State"
                    options={state}
                    // value={search}
                    onChange={(currentTarget) => {
                      setSelectedState(currentTarget.value);
                    }}
                  />
                  {/* <Select  placeholder="State" /> *
                </SoftBox>
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  width="350px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                  }}
                >
                  <SoftSelect
                    placeholder="District"
                    options={district}
                    onChange={(currentTarget) => {
                      setSelectedDistrict(currentTarget.value);
                    }}
                  />
                </SoftBox>
              </Grid>
              {/* <SoftBox width={{ lg: "24%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{
                border: "0px",
                backgroundColor: "#fff",
                marginRight: "13px",
                borderRadius: "5px",
              }}>
              <SoftSelect
                placeholder="Taluka"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              />
            </SoftBox> *
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-center"
                  justifyContent="center"
                  gap="24px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  width="350px"
                  mb={1}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  padding={{ lg: "10px 5%", sm: "12px 80px", xs: "10px 70px" }}
                  onClick={() => navigate("/counselor-registration-page")}
                >
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="0"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    +
                  </SoftTypography>
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="1"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Counsellor
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox> */}
          <SoftBox alignItems="flex-start" position="relative">
            {/* <DataTable            
                table={centertable}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
              /> */}
            <SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                mb={1}
              >
                <SoftBox width={{ lg: "500px", md: "400px" }}>
                  <SoftInput
                    isClearable
                    // width={{ lg: "1000px", md: "600px" }}
                    // width="1000px"
                    id="search-bar"
                    className="text"
                    // onInput={(e) => {
                    //   setSearchQuery(e.target.value);
                    // }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                    placeholder="Search by name and phone number"
                    size="small"
                  />
                </SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="10px"
                  justifyContent="space-between"
                >
                  {/* {selectedRowKeys.length > 1 && (
                // <Button variant="contained" color="success" >
                //   Deactivate
                // </Button>
                <SoftBox
                  // width={{ lg: "120px", md: "80px", xs: "60px" }}
                  width="120px"
                  ml={{ lg: "920px", md: "450px", xs: "180px" }}
                  sx={{
                    position: "absolute",
                    // marginLeft: "850px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    // backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    border: "1px solid red",
                    cursor: "pointer",
                  }}
                  // onclick to deactivate all
                >
                  <SoftTypography
                    sx={{
                      color: "red",
                      fontWeight: "medium",
                      fontSize: "15px",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      padding: "auto",
                    }}
                    // fontSize={{ lg: "15px", md: "12px", xs: "7px" }}
                  >
                    Deactivate All
                  </SoftTypography>
                </SoftBox>
              )} */}
                  <Tooltip key="1" title={tablerow.length === 0 ? "Download All" : "Download"} placement="top">
                    <SoftBox
                      sx={{
                        height: "33px",
                        backgroundColor: "#000000",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      width="40px"
                      onClick={() => (
                        setShow(!show),
                        // setFileName(() => {
                        //   getFileName();
                        // })
                        getFileName()
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="46"
                        height="34"
                        viewBox="0 0 46 34"
                        fill="none"
                      >
                        <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                        <path
                          d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22.9531 16.5098V23.0102"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </SoftBox>
                  </Tooltip>

                  {/*
                  <SoftBox
                    width="120px"
                    // ml={{ lg: "1070px", md: "600px", xs: "300px" }}
                    sx={{
                      // position: "absolute",
                      // marginLeft:"1000px",
                      // marginLeft: "1000px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "32px",
                      // backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      border: "1px solid ",
                      cursor: "pointer",
                    }}
                  // onclick to deactivate all
                  >
                    <SoftTypography
                      sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                      onClick={() => (
                        setShow(!show),
                        // setFileName(() => {
                        //   getFileName();
                        // })
                        getFileName()
                      )}
                    >
                      Export CSV
                    </SoftTypography>
                    <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink>
                  </SoftBox>
                  */}

                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#3B5092",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => navigate("/counselor-registration-page")}
                  >
                    <SoftBox sx={{ color: "#FFF" }}>+</SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Table
              // {...tableProps}
              // pagination={{ position: ["bottomCenter"] }}
              loading={isLoading}
              rowSelection={rowSelection}
              columns={colName}
              dataSource={searchData}
              scroll={searchData.length <= 2 ? { x: 1300, y: 400 } : { x: 1300 }}
              pagination={{
                // defaultPageSize: ,
                // position: ["none", "bottomCenter"],
                pageSizeOptions: ["10", "20", "50", "100", "500"],
                total: paginationState.dataSize,
                showSizeChanger: true,
                // onShowSizeChange: { onShowSizeChange },
                onShowSizeChange: handlePageSizeChange,
                current: paginationState.page,
                onChange: (page, pageSize) =>
                  setPaginationState({ ...paginationState, page: page }),
              }}
            // exportable
            // exportableProps={{ showColumnPicker: true }}
            // searchable
            />
            <CounsellorEdit
              editadmin={resetPassword}
              Seteditadmin={setResetPassword}
              editdata={editdata}
            />
            <CounsellorEditProfile
              editadmin={editadmin}
              Seteditadmin={Seteditadmin}
              editdata={editdata}
            />
          </SoftBox>

          {/* </Card> */}
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {colHeaders.map((ele, index) => (
                <>
                  <ul className="UlCSV" key={index}>
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={tablerow.length === 0 ? searchData : tablerow}
                    //data={selectedCSVData}
                    filename={fileName}
                    headers={colHeaders.filter((ele) => ele.isExportable === true)}
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* modal for export */}
      </DashboardLayout>
    </>
  );
};

export default Counsellour;
