import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import defaultDoughnutChartData from "layouts/pages/charts/data/defaultDoughnutChartData";
import ProgressDoughnutChart from "examples/Charts/DoughnutCharts/ProgressDoughnutChart";
import axios from "axios";
import Select from "react-select";
import SoftInput from "components/SoftInput";
import {
  TextField,
  Box,
  Grid as MuiGrid,
  Button,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
// import editicon from "../../assets/images/editicon.png";
import { Details } from "@material-ui/icons";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import refreshBtn from "../../assets/images/refresh_btn.png";
import { useNavigate } from "react-router-dom";
// import PostProfile from "./postProfile";
import FadeLoader from "react-spinners/FadeLoader";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { width } from "@mui/system";
import noData from "../../assets/images/no-data.jpg";
import { useTheme } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
// import SquareIcon from "@mui/icons-material/Square";
// import edit1 from "../../assets/images/edit1.png";
// import briefcase1 from "../../assets/images/briefcase1.png";
// import calender1 from "../../assets/images/calendar1.png";
// import location1 from "../../assets/images/location1.png";
// import refresh from "../../assets/images/refresh.png";
import defaultimg from "../../assets/images/jobfairsvgs/enterprise.png";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Table } from "ant-table-extensions";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import locationicon from "../../assets/images/jobfairsvgs/location1.png";
import editiconimg from "../../assets/images/jobfairsvgs/edit-2.png";
import hiring1img from "../../assets/images/jobfairsvgs/human-resources 2.png";
import hiring2img from "../../assets/images/jobfairsvgs/suitcase (3) 1.png";
import spocimg from "../../assets/images/jobfairsvgs/user (9) 1.png";
import cmpimg from "../../assets/images/jobfairsvgs/business-and-trade (3) 1.png";
import styled from "@emotion/styled";
import "./jobfair.css";
// import useRoleAccess from "./userRoleAccess";
import {
  BoxButtonContainer,
  BoxCompanyInfo,
  BoxFlex1,
  BoxGrid1,
  BoxHeading1,
  BoxHeadingContainer1,
  BoxImg,
  BoxInfoContainer1A,
  BoxInfoContainer2A,
  BoxMain1,
  BoxMain2,
  BoxMain3,
  TypographyCompanyDes,
  TypographyCompanyinfo,
  TypographyHeading1,
  TypographyHeading2,
  TypographyHeading3,
  TypographyList,
  TypographyLocation,
  TypographyOrg1,
  TypographyOrg2,
  TypographySans,
  TypographyTabHeading,
  TypographyXs,
} from "./profileStyle";
ChartJS.register(ArcElement, Tooltip, Legend);

const notSkilling = sessionStorage.getItem("role_access") !== "Skilling Institute" ? true : false;

const TabButton = styled(Box)({
  width: "170px",
  textAlign: "center",
  cursor: "pointer",
  fontWeight: 600,
  fontFamily: "Montserrat",
  fontSize: "14px",
  padding: "4px",
  paddingBottom: "8px",
  paddingTop: "8px",
  color: "#000EDD",
  background: "#E0E0E0",
  borderBottom: "4px solid #000EDD",
});

const TabButtonLeft = styled(Box)({
  // width: "170px",
  textAlign: "center",
  cursor: "pointer",
  fontWeight: 600,
  fontFamily: "Montserrat",
  fontSize: "14px",
  // padding: "4px",
  paddingBottom: "8px",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "8px",
  color: "#FF5C00",
  background: "#E0E0E0",
  borderTop: "4px solid #FF5C00",
  // borderBottom:
  //   sessionStorage.getItem("role_access") !== "Skilling Institute" ? "4px solid #000EDD" : "none",
  // borderTop: notSkilling ? "none" : "4px solid #FF5C00",
  // borderBottomLeftRadius: "10px",
});

const TabButtonClick = styled(Box)({
  width: "170px",
  textAlign: "center",
  cursor: "pointer",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 600,
  padding: "4px",
  paddingBottom: "8px",
  paddingTop: "8px",
  color: "#423232",
  background: "#FFFFFF",
  borderBottom: "4px solid #DDDDDD",
});

const TabButtonClickLeft = styled(Box)({
  width: "170px",
  textAlign: "center",
  cursor: "pointer",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 600,
  padding: "4px",
  paddingBottom: "8px",
  paddingTop: "8px",
  color: "#423232",
  background: "#FFFFFF",
  borderBottom: "4px solid #DDDDDD",
  borderBottomLeftRadius: "10px",
});

const ProfilePage = (props) => {
  //   const roleSkilling = useRoleAccess();
  const [editOne, setEditOne] = useState(false);
  const [editTwo, setEditTwo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [datatable, setDatatable] = useState([]);
  const [jftable, setJftable] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dd, setDD] = useState([{ label: "All", value: "" }]);
  const [selectdd, setSelectDD] = useState("");
  const [step, setStep] = useState(0);
  const theme = useTheme();
  const matchlg = useMediaQuery(theme.breakpoints.up("lg"));
  const matchmd = useMediaQuery(theme.breakpoints.up("md"));
  const matchsm = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const [chartsOptions, setChatsOptions] = useState([{ label: "All", value: "" }]);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (current, size) => {
    // console.log("Selected page size:", size);
    setPageSize(size);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(jfList, newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const data = [
    {
      id: "ruby",
      label: "ruby",
      value: 171,
      color: "hsl(174, 70%, 50%)",
    },
    // {
    //   id: "erlang",
    //   label: "erlang",
    //   value: 160,
    //   color: "hsl(193, 70%, 50%)",
    // },
    // {
    //   id: "python",
    //   label: "python",
    //   value: 560,
    //   color: "hsl(80, 70%, 50%)",
    // },
    // {
    //   id: "elixir",
    //   label: "elixir",
    //   value: 291,
    //   color: "hsl(112, 70%, 50%)",
    // },
    {
      id: "lisp",
      label: "lisp",
      value: 424,
      color: "hsl(48, 70%, 50%)",
    },
  ];
  const searchInOption = [
    { label: "Last 15 days", value: "15" },
    { label: "Last 30 days", value: "30" },
    { label: "Last 60 days", value: "60" },
  ];

  const industry = [
    { label: "Textile and Clothing", value: "Textile and Clothing" },
    { label: "Building and Construction", value: "Building and Construction" },
    { label: "Automobile and Transportation", value: "Automobile and Transportation" },
    { label: "Organized and Unorganized Retail", value: "Organized and Unorganized Retail" },
    { label: "Gems and Jewellery", value: "Gems and Jewellery" },
    { label: "IT & Computers", value: "IT & Computers" },
    { label: "Furniture and Furnishing", value: "Furniture and Furnishing" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Leather and Leather Goods", value: "Leather and Leather Goods" },
    { label: "Tourism and Hospitality", value: "Tourism and Hospitality" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Mining", value: "Mining" },
    { label: "Households", value: "Households" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
  ];

  const jpId = sessionStorage.getItem("register_id");
  const jpType = sessionStorage.getItem("role_access");
  const [details, setDetails] = useState({});
  const [serachIn, setSearchIn] = useState("15");
  const [hiringRole, setHiringRoles] = useState([]);
  const [hiringSector, setHiringSector] = useState([]);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditProfileNew, setShowEditProfileNew] = useState(false);
  const [showEditA, setShowEditA] = useState(false);
  const [showEditB, setShowEditB] = useState(false);
  const [showEditC, setShowEditC] = useState(false);
  const [showEditD, setShowEditD] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullLastName, setFullLastName] = useState("");
  const [cin, setCin] = useState("");
  const [indType, setIndType] = useState("");
  const [orgName, setOrgName] = useState("");
  const [title, setTitle] = useState("");
  const [imgLogo, setImgLogo] = useState("");
  const [regId, setRegId] = useState("");
  const [chartData, setChartData] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [jobPostTimeline, setJobPostTimline] = useState([]);
  const [orgType, setOrgType] = useState("");
  const [orgSector, setOrgSector] = useState("");
  const [cmp, setCmp] = useState("");
  const [logo, setLogo] = useState("");
  const [view, setView] = useState(true);
  const [rowData, setRowData] = useState({});
  const [editImg, setEditImg] = useState(false);
  const [imgNew, setImgNew] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const jpDetailsNew = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/admin-profile`,
        {
          params: {
            registration_id: jpId,
          },
        }
      );
      console.log(res.data, "www");
      // console.log(res.data["JobProvider_Detail"], "www");
      //setImgLogo(res?.data?.JobProvider_Detail?.org_logo);
      setImgNew(res?.data?.data?.profile);
      setDetails(res.data["data"]);
      setFullName(res?.data?.data?.firstnm);
      setFullLastName(res?.data?.data?.lastnm);
      setPhone(res?.data?.data?.mobnum?.slice(2));
      setEmail(res?.data?.data?.email);
      setCin(res?.data?.data?.org_cin_num);
      setOrgType(res?.data?.data?.org_type);
      setOrgName(res?.data?.data?.org_name);
      setOrgSector(res?.data?.data?.org_sector);
      setCmp(res?.data?.data?.company_info);
      setRegId(res?.data?.data?.registration_id);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    jpDetailsNew();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    // console.log("img ", file);
    //setEditImg(true);
    if (file?.size / 1024 < 5120) {
      setImgLogo(file);
    } else {
      // console.log("Error");
      //ref.current.value = "";
      setImgLogo("");
    }
  };

  const colName = [
    {
      title: "Hiring Roles",
      dataIndex: "job_role",
      width: !matchsm ? 100 : 200,
      sorter: {
        compare: (a, b) => a?.job_role?.localeCompare(b?.job_role),
        multiple: 1,
      },
    },
    {
      title: "No. of Candidates",
      dataIndex: "candidate_req",
      width: !matchsm ? 100 : 200,
      sorter: {
        compare: (a, b) => a?.candidate_req - b?.candidate_req,
        multiple: 2,
      },
    },
    {
      title: "Job Description",
      dataIndex: "job_desc",
      width: !matchsm ? 200 : 200,
      sorter: {
        compare: (a, b) => a?.job_desc?.localeCompare(b?.job_desc),
        multiple: 3,
      },
    },
  ];

  const getDDList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`,
        {
          params: {
            orgid: jpId,
            type: "dropdown",
          },
        }
      );
      const data = await res.data;
      setDD(
        // ...dd,
        // dd.concat(
        //   data?.JobPost_List.map((v) => {
        //     return {
        //       label: v.label,
        //       value: v.jobid,
        //     };
        //   })
        // )
        data?.JobPost_List.map((v) => {
          return {
            label: v.label,
            value: v.jobid,
          };
        }).concat(dd)
      );
    } catch (err) {
      // console.log(err);
    }
  };

  // setDD(() => dd.push({ label: "Overall", value: "" }));
  const jpDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/admin-profile`, {
        params: {
          registration_id: jpId,
        },
      })
      .then((res) => {
        console.log("de", res.data?.data[0]);
        setDetails(res.data?.data[0]);
        // setFullName(res?.data?.JobProvider_List[0].firstnm);
        // setEmail(res?.data?.JobProvider_List[0].email);
        // setPhone(res?.data?.JobProvider_List[0].mobnum?.slice(2));
        // setRegId(res?.data?.JobProvider_List[0].registration_id);
      })
      .catch((err) => {
        // console.log(err.response.data);
      });
  };

  const EditLogo = async () => {
    if (imgLogo === "") {
      Swal.fire({
        icon: "error",
        title: "Please upload image < 5mb",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        setEditImg(false);
        setImgLogo("");
      });
    } else {
      setEditImg(false);
      let formdata = new FormData();
      formdata.append("registration_id", sessionStorage.getItem("register_id"));
      formdata.append("profile", imgLogo);
      // formdata.append("role_access", sessionStorage.getItem("role_access"));
      await axios
        .patch(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/admin-profile-update`,
          formdata
        )
        .then((res) => {
          Swal.fire({
            //position: "top",
            icon: "success",
            //title: res?.data["Developer Message"] ? res?.data["Developer Message"] : "Success",
            title: res?.data["User Message"] ? res?.data["User Message"] : "Upload Successfully!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            jpDetailsNew();
          });
        })
        .catch((err) => {
          console.log("patch call:", err);
          setEditImg(false);
          Swal.fire({
            icon: "error",
            title: err?.response?.data["Developer Message"]
              ? err?.response?.data["Developer Message"]
              : "Error",
            text: err?.response?.data["User Message"]
              ? err?.response?.data["User Message"]
              : "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            setEditImg(false);
            setImgLogo("");
          });
        });
    }
  };

  const EditProfile = async () => {
    if (fullName === "" || fullLastName === "" || phone === "" || email === "") {
      Swal.fire({
        icon: "error",
        title: "Fill all mandatory fields",
        html: `<p>(marked with <span style='color:red'>*</span>)</p>`,
        showConfirmButton: false,
        timer: 1500,
      });
      setFullName(details.firstnm);
      setFullLastName(details.lastnm);
      setPhone(details.mobnum.substring(2));
      setEmail(details.email);
    } else {
      setEditImg(false);
      let formdata = new FormData();
      formdata.append("reg_id", regId);
      formdata.append("email", email);
      formdata.append("mobnum", phone);
      formdata.append("firstnm", fullName);
      formdata.append("lastnm", fullLastName);
      formdata.append("org_cin_num", cin);
      formdata.append("org_type", orgType);
      formdata.append("org_name", orgName);
      formdata.append("company_info", cmp);
      formdata.append("org_sector", orgSector);
      formdata.append("role_access", sessionStorage.getItem("role_access"));
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await axios
        .patch(`${process.env.REACT_APP_BACKEND_URL_REGISTER}/jobprovider/register`, formdata)
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);
          Swal.fire({
            icon: "success",
            title: res?.data["Developer Message"]
              ? res?.data["Developer Message"]
              : "Profile updated successfully",
            //text: res?.data["User Message"] ? res?.data["User Message"] : "Register Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          jpDetailsNew();
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);
          Swal.fire({
            icon: "error",
            title: err?.response?.data["Developer Message"]
              ? err?.response?.data["Developer Message"]
              : "Error",
            text: err?.response?.data["User Message"]
              ? err?.response?.data["User Message"]
              : "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const sectorOption = [
    {
      label: "Public",
      value: "Public",
    },
    {
      label: "Private",
      value: "Private",
    },
    {
      label: "PSU",
      value: "PSU",
    },
  ];

  const CapitalizeFunc = (str) => {
    const arr = str.split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].splice(1);
    }
    const newStr = arr.join(" ");
    return newStr;
  };

  const dataChart = {
    //labels: chartData?.labels ? chartData?.labels : [],
    datasets: [
      {
        data: chartData?.datasets?.data ? chartData?.datasets?.data : [],
        backgroundColor: ["#D4A373", "#A2D2FF", "#CDB4DB"],
        borderColor: ["#D4A373", "#A2D2FF", "#CDB4DB"],
        // backgroundColor: ["#A2FF86", "#A2D2FF", "#CDB4DB"],
        // borderColor: ["#A2FF86", "#A2D2FF", "#CDB4DB"],
        borderWidth: 1,
      },
    ],
  };

  const dataChart1 = {
    datasets: [
      {
        data: chartData1?.datasets?.data ? chartData1?.datasets?.data : [],
        backgroundColor: [
          "rgba(255, 174, 94, 1)",
          "rgba(0, 152, 26, 0.69)",
          "rgba(255, 0, 0, 0.56)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const JopPostTimline = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobprovider/dashboard/jobpost/timeline`,
        {
          params: {
            org_id: jpId,
            days_before: serachIn,
          },
        }
      );
      setLoading(false);
      const data = await res.data;
      setJobPostTimline(data?.job_post_data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const newStylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: "180px",
      fontSize: "14px",
      fontFamily: "Montserrat",
    }),
    option: (base) => ({
      ...base,
      height: "100%",
      fontSize: "14px",
      fontFamily: "Montserrat",
    }),
  };

  // useEffect(() => {
  //   sessionStorage.getItem("role_access") !== "Default" &&
  //     (HiringDetails(), getDDList(), ChartsFunc());
  // }, []);

  // useEffect(() => {
  //   sessionStorage.getItem("role_access") !== "Default" && JopPostTimline();
  // }, [serachIn]);
  // useEffect(() => {
  //   sessionStorage.getItem("role_access") !== "Default" && (ChartsFunc(), setCount(0));
  // }, [selectdd]);

  // useEffect(() => {
  //   jpDetails();
  // }, []);

  const handleStateFunc = () => {
    setView(true);
    sessionStorage.getItem("role_access") === "Requirement Based"
      ? navigate("/jobproviderrb/all-postings")
      : navigate("/jobprovidere2e/all-postings");
    // navigate(-1);
  };

  console.log("details", details);

  return (
    <>
      <DashboardNavbar />
      {view && (
        <DashboardLayout>
          <Grid containter spacing={2}>
            <Grid item lg={12} xs={12}>
              <BoxGrid1>
                <div
                  className={
                    "skill-bg-img"
                    // sessionStorage.getItem("role_access") !== "Skilling Institute"
                    //   ? "rectangleimg"
                    //   : "skill-bg-img"
                  }
                />

                <BoxMain1>
                  <BoxMain3>
                    <label htmlFor="imgupload">
                      <BoxImg>
                        <img
                          src={imgNew === "" || imgNew === null ? defaultimg : imgNew}
                          alt=""
                          style={{
                            width: "160px",
                            height: "119px",
                            borderRadius: "5px",
                            border: "1px solid #C4C4C4",
                          }}
                        />

                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 0.25,
                          }}
                        >
                          <img
                            src={editiconimg}
                            alt="edit"
                            height={"24"}
                            width={"24"}
                            style={{ cursor: "pointer", mt: -1 }}
                          />
                        </Box>

                        <input
                          id="imgupload"
                          style={{ display: "none" }}
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          onChange={(e) => {
                            handleFileUpload(e);
                            setEditImg(true);
                          }}
                        />
                      </BoxImg>
                    </label>

                    {sessionStorage.getItem("role_access") !== "Default" ? (
                      <Box sx={{ paddingLeft: "12px", minWidth: "300px", paddingTop: "8px" }}>
                        <TypographyOrg1 variant="h3">
                          {/* {details.org_name ? details.org_name : "NA"} */}
                          {details.firstnm || details.lastnm
                            ? `${details.firstnm} ${details.lastnm}`
                            : "NA"}
                        </TypographyOrg1>

                        {/* <TypographyOrg2 variant="h3">
                          {details.org_type ? details.org_type : "NA"}
                        </TypographyOrg2> */}
                      </Box>
                    ) : (
                      <Box sx={{ paddingLeft: "12px", minWidth: "300px", paddingTop: "8px" }}>
                        <TypographyOrg1 variant="h3">
                          {details.org_name ? details.org_name : "NA"}
                        </TypographyOrg1>

                        <TypographyOrg2 variant="h3">
                          {details.org_type ? details.org_type : "NA"}
                        </TypographyOrg2>
                      </Box>
                    )}
                  </BoxMain3>

                  <BoxMain2>
                    {/* <TypographyLocation variant="h3">
                      <img src={locationicon} alt="location" height={"20.17"} width={"16.5"} />
                      &nbsp;{details.org_address ? details.org_address : "NA"}
                    </TypographyLocation> */}
                    {!editOne && editImg && (
                      <Box sx={{ display: "flex" }}>
                        <IconButton
                          sx={{ m: 0, p: 0, ml: 0.5, mr: 0.5 }}
                          onClick={() => {
                            EditLogo();
                          }}
                        >
                          <CheckCircleIcon sx={{ color: "#3B5092" }} />
                        </IconButton>

                        <IconButton
                          sx={{ m: 0, p: 0, ml: 0.5, mr: 0.5 }}
                          onClick={() => {
                            setEditImg(false);
                            setImgLogo("");
                          }}
                        >
                          <CancelIcon sx={{ color: "#6C6C6C" }} />
                        </IconButton>
                      </Box>
                    )}

                    {/* {!editOne ? (
                      <>
                        {editImg ? (
                          <Box sx={{ display: "flex" }}>
                            <IconButton
                              sx={{ m: 0, p: 0, ml: 0.5, mr: 0.5 }}
                              onClick={() => {
                                EditLogo();
                              }}
                            >
                              <CheckCircleIcon sx={{ color: "#3B5092" }} />
                            </IconButton>

                            <IconButton
                              sx={{ m: 0, p: 0, ml: 0.5, mr: 0.5 }}
                              onClick={() => {
                                setEditImg(false);
                                setImgLogo("");
                              }}
                            >
                              <CancelIcon sx={{ color: "#6C6C6C" }} />
                            </IconButton>
                          </Box>
                        ) : (
                          <img
                            src={editiconimg}
                            alt="edit"
                            height={"24"}
                            width={"24"}
                            style={{ cursor: "pointer" }}
                            onClick={() => setEditOne(true)}
                          />
                        )}
                      </>
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <IconButton
                          sx={{ m: 0, p: 0, ml: 0.5, mr: 0.5 }}
                          onClick={() => {
                            EditProfile();
                            setEditOne(false);
                          }}
                        >
                          <CheckCircleIcon sx={{ color: "#3B5092" }} />
                        </IconButton>

                        <IconButton
                          sx={{ m: 0, p: 0, ml: 0.5, mr: 0.5 }}
                          onClick={() => {
                            setEditOne(false);
                          }}
                        >
                          <CancelIcon sx={{ color: "#6C6C6C" }} />
                        </IconButton>
                      </Box>
                    )} */}
                  </BoxMain2>
                </BoxMain1>

                {/* <BoxCompanyInfo>
                  <TypographyCompanyDes variant="h3">
                    <img src={cmpimg} height={"26px"} width={"26px"} />
                    &nbsp;
                    {sessionStorage.getItem("role_access") !== "Skilling Institute"
                      ? "Company Description"
                      : "Skilling Institute Description"}
                  </TypographyCompanyDes>

                  {editOne ? (
                    <SoftInput
                      multiline
                      rows={3}
                      type="text"
                      name="jobdesc"
                      id="jobdesc"
                      defaultValue={cmp}
                      inputProps={{
                        maxLength: "1000",
                      }}
                      onChange={(e) => setCmp(e.target.value)}
                    />
                  ) : (
                    <Box sx={{ height: "114px", overflow: "auto" }}>
                      <TypographyCompanyinfo variant="body1">
                        {details.company_info ? details.company_info : "NA"}
                      </TypographyCompanyinfo>
                    </Box>
                  )}

                  {editOne ? (
                    <span className="spancharacters">
                      {cmp !== null ? cmp.length : 0} of 1000 characters
                    </span>
                  ) : null}
                </BoxCompanyInfo> */}
                {/* {sessionStorage.getItem("role_access") !== "Skilling Institute" && <hr />} */}

                {/* <BoxCompanyInfo>
                  <TypographyCompanyDes variant="h3">
                    <img src={cmpimg} height={"26px"} width={"26px"} />
                    &nbsp;
                    {sessionStorage.getItem("role_access") !== "Skilling Institute"
                      ? "Company Description"
                      : "Skilling Institute Description"}
                  </TypographyCompanyDes>

                  {editOne ? (
                    <SoftInput
                      multiline
                      rows={3}
                      type="text"
                      name="jobdesc"
                      id="jobdesc"
                      defaultValue={cmp}
                      inputProps={{
                        maxLength: "1000",
                      }}
                      onChange={(e) => setCmp(e.target.value)}
                    />
                  ) : (
                    <Box sx={{ height: "114px", overflow: "auto" }}>
                      <TypographyCompanyinfo variant="body1">
                        {details.company_info ? details.company_info : "NA"}
                      </TypographyCompanyinfo>
                    </Box>
                  )}

                  {editOne ? (
                    <span className="spancharacters">
                      {cmp !== null ? cmp.length : 0} of 1000 characters
                    </span>
                  ) : null}
                </BoxCompanyInfo> */}
                {/* {sessionStorage.getItem("role_access") !== "Skilling Institute" && <hr />} */}

                <BoxButtonContainer>
                  {sessionStorage.getItem("role_access") !== "Skilling Institute" ? (
                    <>
                      {step === 0 ? (
                        <TabButtonLeft>Admin Details</TabButtonLeft>
                      ) : (
                        <TabButtonClickLeft onClick={() => setStep(0)}>
                          Admin Details
                        </TabButtonClickLeft>
                      )}
                    </>
                  ) : null}
                </BoxButtonContainer>

                {/* <BoxInfoContainer2A sx={{ gap: !matchsm ? "10px" : "200px" }}>
                  <BoxHeading1>
                    <TypographyHeading1 variant="h3">
                      Name of Organisation{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                    </TypographyHeading1>
                    <TypographyList variant="body1">
                      &nbsp;
                      {details.org_name ? details.org_name : "NA"}
                    </TypographyList>
                  </BoxHeading1>
                  <BoxHeading1>
                    <TypographyHeading1 variant="h3">
                      Address{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                    </TypographyHeading1>
                    <TypographyList variant="body1">
                      &nbsp;
                      {details.org_address ? details.org_address : "NA"}
                    </TypographyList>
                  </BoxHeading1>
                </BoxInfoContainer2A> */}
                <BoxInfoContainer1A sx={{ gap: !matchsm ? "10px" : "200px" }}>
                  <BoxHeading1>
                    <TypographyHeading1 variant="h3">
                      State{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                    </TypographyHeading1>
                    <TypographyList variant="body1">
                      &nbsp;
                      {details.org_state ? details.org_state : "NA"}
                    </TypographyList>
                  </BoxHeading1>
                  <BoxHeading1>
                    <TypographyHeading1 variant="h3">
                      District{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                    </TypographyHeading1>
                    <TypographyList variant="body1">
                      &nbsp;
                      {details.org_city ? details.org_city : "NA"}
                    </TypographyList>
                  </BoxHeading1>
                </BoxInfoContainer1A>

                {/* <SoftBox
                  display="flex"
                  // flexDirection="row"
                  // gap="20px"
                  // justifyContent="space-between"
                  width={!matchsm ? "100%" : "900px"}
                  mx="18px"
                  borderBottom="1px solid #0000004D"
                  px="12px"
                  mb="10px"
                  mt="20px"
                >
                  <SoftTypography
                    fontStyle="normal"
                    fontWeight="medium"
                    fontSize="18px"
                    lineHeight="30px"
                  >
                    SPOC Details
                  </SoftTypography>
                </SoftBox> */}
                <BoxInfoContainer2A sx={{ gap: !matchsm ? "10px" : "200px" }}>
                  <BoxHeading1>
                    <TypographyHeading1 variant="h3">
                      Email
                      {editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                    </TypographyHeading1>
                    <TypographyList variant="body1">
                      &nbsp;{details.email ? details.email : "NA"}
                    </TypographyList>
                  </BoxHeading1>
                  <BoxHeading1>
                    <TypographyHeading1 variant="h3">
                      Mobile Number{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                    </TypographyHeading1>
                    <TypographyList variant="body1">
                      &nbsp;
                      {details.mobnum?.length === 12
                        ? details.mobnum.substring(2)
                        : details.mobnum
                        ? details.mobnum
                        : "NA"}
                    </TypographyList>
                  </BoxHeading1>
                </BoxInfoContainer2A>
                {/* <BoxInfoContainer1A sx={{ gap: !matchsm ? "10px" : "200px" }}>
                  <BoxHeading1>
                    <TypographyHeading1 variant="h3">
                      Designation{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                    </TypographyHeading1>
                    <TypographyList variant="body1">
                      &nbsp;{details.contact_designation ? details.contact_designation : "NA"}
                    </TypographyList>
                  </BoxHeading1>
                </BoxInfoContainer1A> */}
              </BoxGrid1>
            </Grid>

            {/* <Grid item lg={12} xs={12}>
              {step === 0 ? (
                <BoxGrid1 >
                  <BoxHeadingContainer1>
                    <TypographyTabHeading  variant="h3">
                      <img src={spocimg} height={"24px"} width={"24px"} />
                      &nbsp;SPOC Details
                    </TypographyTabHeading>

                    {!editTwo ? (
                      <img
                        src={editiconimg}
                        alt="edit"
                        height={"24"}
                        width={"24"}
                        style={{ cursor: "pointer" }}
                        onClick={() => setEditTwo(true)}
                      />
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <IconButton
                          sx={{ m: 0, p: 0, ml: 0.5, mr: 0.5 }}
                          onClick={() => {
                            setEditTwo(false);
                            EditProfile();
                          }}
                        >
                          <CheckCircleIcon sx={{ color: "#3B5092" }} />
                        </IconButton>

                        <IconButton
                          sx={{ m: 0, p: 0, ml: 0.5, mr: 0.5 }}
                          onClick={() => {
                            setEditTwo(false);
                            setFullName(details.firstnm);
                            setFullLastName(details.lastnm);
                            setPhone(details.mobnum.substring(2));
                            setEmail(details.email);
                          }}
                        >
                          <CancelIcon sx={{ color: "#6C6C6C" }} />
                        </IconButton>
                      </Box>
                    )}
                  </BoxHeadingContainer1>
                  <hr />

                  <BoxInfoContainer1A >
                    <BoxHeading1>
                      <TypographyHeading1 variant="h3">
                        First Name{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                      </TypographyHeading1>

                      {editTwo ? (
                        <TextField
                          type="text"
                          size="small"
                          defaultValue={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      ) : (
                        <TypographyList variant="body1">
                          &nbsp;{details.firstnm ? details.firstnm : "NA"}
                        </TypographyList>
                      )}
                    </BoxHeading1>

                    <BoxHeading1>
                      <TypographyHeading1 variant="h3">
                        Last Name{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                      </TypographyHeading1>

                      {editTwo ? (
                        <TextField
                          type="text"
                          size="small"
                          defaultValue={fullLastName}
                          onChange={(e) => setFullLastName(e.target.value)}
                        />
                      ) : (
                        <TypographyList variant="body1">
                          &nbsp;{details.lastnm ? details.lastnm : "NA"}
                        </TypographyList>
                      )}
                    </BoxHeading1>

                    <BoxHeading1>
                      <TypographyHeading1 variant="h3">
                        Phone{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                      </TypographyHeading1>

                      {editTwo ? (
                        <TextField
                          type="tel"
                          size="small"
                          defaultValue={phone}
                          inputProps={{
                            maxLength: "10",
                          }}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      ) : (
                        <TypographyList variant="body1">
                          &nbsp;
                          {details.mobnum
                            ? `+${details.mobnum?.substring(0, 2)}-${details.mobnum?.slice(2)}`
                            : "NA"}
                        </TypographyList>
                      )}
                    </BoxHeading1>

                    <BoxHeading1>
                      <TypographyHeading1 variant="h3">
                        Email{editTwo ? <sup style={{ color: "red" }}>*</sup> : ":"}
                      </TypographyHeading1>

                      {editTwo ? (
                        <TextField
                          type="email"
                          size="small"
                          defaultValue={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      ) : (
                        <TypographyList variant="body1">
                          &nbsp;{details.email ? details.email : "NA"}
                        </TypographyList>
                      )}
                    </BoxHeading1>

                    {sessionStorage.getItem("role_access") !== "Skilling Institute" && (
                      <BoxHeading1>
                        <TypographyHeading1 variant="h3">CIN:</TypographyHeading1>

                        {editTwo ? (
                          <TextField
                            type="text"
                            size="small"
                            defaultValue={cin}
                            inputProps={{
                              maxLength: "21",
                            }}
                            onChange={(e) => setCin(e.target.value)}
                          />
                        ) : (
                          <TypographyList variant="body1">
                            &nbsp;{details.org_cin_num ? details.org_cin_num : "NA"}
                          </TypographyList>
                        )}
                      </BoxHeading1>
                    )}
                  </BoxInfoContainer1A>
                </BoxGrid1>
              ) : step === 1 ? (
                <BoxGrid1>
                  <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TypographyTabHeading variant="h3">
                        <img src={hiring2img} height={"24px"} width={"24px"} />
                        &nbsp;Hiring Sectors
                      </TypographyTabHeading>
                      <hr />

                      <ul style={{ marginLeft: "12px" }}>
                        {hiringSector.map((v, i) => {
                          return (
                            <li key={i}>
                              <TypographyList variant="body1">{v}</TypographyList>
                            </li>
                          );
                        })}
                      </ul>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TypographyTabHeading variant="h3">
                        <img src={hiring1img} height={"24px"} width={"24px"} />
                        &nbsp;Hiring Roles
                      </TypographyTabHeading>
                      <hr />

                      <ul style={{ marginLeft: "12px" }}>
                        {hiringRole.sort().map((v, i) => {
                          return (
                            <li key={i}>
                              <TypographyList variant="body1">{v}</TypographyList>
                            </li>
                          );
                        })}
                      </ul>
                    </Grid>
                  </Grid>
                </BoxGrid1>
              ) : step === 2 ? (
                <BoxGrid1>
                  <TypographyTabHeading variant="h3">Placement Statistics</TypographyTabHeading>
                  <hr />

                  <Grid container>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      {sessionStorage.getItem("role_access") === "Default" ? null : (
                        <BoxFlex1>
                          <TypographyHeading2 variant="h3">Select Job Role</TypographyHeading2>

                          <Select
                            isClearable
                            options={dd}
                            styles={newStylesDD}
                            defaultValue={selectdd === "" && { label: "All", value: "" }}
                            onChange={(e) => {
                              setSelectDD(e ? e.value : "");
                            }}
                          />
                        </BoxFlex1>
                      )}

                      {count1 !== 3 ? (
                        <>
                          {sessionStorage.getItem("role_access") === "Default" ? (
                            <>
                              {chartData1?.datasets?.labels?.map((ele, i) => {
                                return (
                                  <Box
                                    key={i}
                                    sx={{
                                      mt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "24px",
                                    }}
                                  >
                                    <SquareIcon
                                      fontSize="small"
                                      sx={
                                        i === 0
                                          ? { color: "rgba(255, 174, 94, 1)" }
                                          : i === 1
                                          ? { color: "rgba(0, 152, 26, 0.69)" }
                                          : { color: "rgba(255, 0, 0, 0.56)" }
                                      }
                                    />

                                    <TypographyList variant="body1">&nbsp;{ele}</TypographyList>
                                  </Box>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {chartData?.labels?.map((ele, i) => {
                                return (
                                  <Box
                                    key={i}
                                    sx={{
                                      mt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "24px",
                                    }}
                                  >
                                    <SquareIcon
                                      fontSize="small"
                                      sx={
                                        ele === "Shortlisted"
                                          ? { color: "#D4A373" }
                                          : ele === "Placed"
                                          ? { color: "#A2D2FF" }
                                          : ele === "Interviewed"
                                          ? { color: "#CDB4DB" }
                                          : { color: "#CDB4DB" }
                                      }
                                    />

                                    <TypographyList variant="body1">&nbsp;{ele}</TypographyList>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : null}
                    </Grid>

                    {count1 !== 3 ? (
                      <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Box
                          sx={{
                            width: "200px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            ml: "auto",
                            mr: "auto",
                          }}
                        >
                          {loading1 ? (
                            <Box
                              sx={{
                                display: "flex",
                                height: "270px",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FadeLoader
                                color="#B3B6B7"
                                height={12}
                                width={5}
                                radius={2}
                                margin={2}
                              />
                            </Box>
                          ) : (
                            <Doughnut
                              data={
                                sessionStorage.getItem("role_access") === "Default"
                                  ? dataChart1
                                  : dataChart
                              }
                            />
                          )}
                        </Box>
                        <br />
                      </Grid>
                    ) : null}

                    {count1 !== 3 ? null : (
                      <Grid item xs={12}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 15 15"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              d="m9.5 5.5l-4 4m5-9h-8a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-10l-3-3Zm-3 10a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z"
                            />
                          </svg>
                        </Box>
                        <br />
                      </Grid>
                    )}
                  </Grid>
                </BoxGrid1>
              ) : step === 3 && sessionStorage.getItem("role_access") !== "Default" ? (
                <BoxGrid1>
                  <TypographyTabHeading variant="h3">Job Posted</TypographyTabHeading>
                  <hr />

                  <BoxFlex1>
                    <TypographyHeading2 variant="h3">Recent Job Posted</TypographyHeading2>

                    <Select
                      options={searchInOption}
                      defaultValue={{ label: "Last 15 days", value: "15" }}
                      styles={newStylesDD}
                      onChange={(e) => {
                        setSearchIn(e.value);
                      }}
                    />
                  </BoxFlex1>

                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "270px",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                    </Box>
                  ) : (
                    <>
                      {jobPostTimeline.length === 0 ? (
                        <Box
                          sx={{
                            display: "flex",
                            minHeight: "270px",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={noData} alt="" height={200} />
                        </Box>
                      ) : (
                        <Box sx={{ height: "280px", overflow: "auto", padding: "24px" }}>
                          {jobPostTimeline.map((ele, id) => {
                            return (
                              <Box
                                key={id}
                                sx={{ m: 1, ml: 0, cursor: "pointer" }}
                                onClick={() =>
                                  sessionStorage.getItem("role_access") === "Requirement Based"
                                    ? navigate(
                                        `/jobproviderrb/all-postings?jobid=${
                                          ele.jobid
                                        }&view=${false}`
                                      )
                                    : navigate(
                                        `/jobprovidere2e/all-postings?jobid=${
                                          ele.jobid
                                        }&view=${false}`
                                      )
                                }
                              >
                                <MuiGrid container>
                                  <MuiGrid item lg={2} md={2} sm={12} xs={12}>
                                    <Box>
                                      <Box
                                        sx={{
                                          m: 1,
                                          mt: 0,
                                          width: "90px",
                                          border: "1px solid #C4C4C4",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        <img src={imgNew} alt="" width={"85px"} height={"49px"} />
                                      </Box>
                                    </Box>
                                  </MuiGrid>

                                  <MuiGrid item lg={10} md={10} sm={12} xs={12}>
                                    <TypographySans variant="body2">
                                      {ele.job_title ? ele.job_title : "NA"}
                                    </TypographySans>

                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                      <Box sx={{ display: "flex", m: 0.5 }}>
                                        <img
                                          src={location1}
                                          height={"18px"}
                                          alt=""
                                          style={{ marginRight: "4px" }}
                                        />

                                        <TypographyXs variant="body2">
                                          {ele.district !== "null" ? ele.district : "NA"}
                                        </TypographyXs>
                                      </Box>

                                      <Box sx={{ display: "flex", m: 0.5 }}>
                                        <img
                                          src={briefcase1}
                                          height={"18px"}
                                          alt=""
                                          style={{ marginRight: "4px" }}
                                        />

                                        <TypographyXs variant="body2">
                                          {ele.job_type ? ele.job_type : "NA"}
                                        </TypographyXs>
                                      </Box>

                                      <Box sx={{ display: "flex", m: 0.5 }}>
                                        <img
                                          src={calender1}
                                          height={"18px"}
                                          alt=""
                                          style={{ marginRight: "4px" }}
                                        />

                                        <TypographyXs variant="body2">
                                          {ele.jobpostdt ? ele.jobpostdt : "NA"}
                                        </TypographyXs>
                                      </Box>
                                    </Box>
                                    <hr />
                                    <Box>
                                      <Box sx={{ display: "flex", mb: 0.5 }}>
                                        <Box sx={{ textAlign: "left" }}>
                                          <TypographyHeading3 variant="h3">
                                            Education:&nbsp;
                                          </TypographyHeading3>
                                        </Box>
                                        <Box>
                                          <TypographyList variant="body1">
                                            {ele.qualification && ele.qualification !== "null"
                                              ? ele.qualification
                                              : "NA"}
                                          </TypographyList>
                                        </Box>
                                      </Box>

                                      <Box sx={{ display: "flex", mb: 0.5 }}>
                                        <Box sx={{ textAlign: "left" }}>
                                          <TypographyHeading3 variant="h3">
                                            Key Skills:&nbsp;
                                          </TypographyHeading3>
                                        </Box>
                                        <Box>
                                          <TypographyList variant="body1">
                                            {ele.skills ? ele.skills : "NA"}
                                          </TypographyList>
                                        </Box>
                                      </Box>

                                      <Box sx={{ display: "flex", mb: 0.5 }}>
                                        <Box sx={{ textAlign: "left" }}>
                                          <TypographyHeading3 variant="h3">
                                            Roles and Responsibilites:&nbsp;
                                          </TypographyHeading3>
                                        </Box>
                                        <Box>
                                          <TypographyList variant="body1">
                                            {ele.designation && ele.designation !== "null"
                                              ? ele.designation
                                              : "NA"}
                                          </TypographyList>
                                        </Box>
                                      </Box>

                                      <Box sx={{ display: "flex" }}>
                                        <Box sx={{ textAlign: "left" }}>
                                          <TypographyHeading3 variant="h3">
                                            Creator Role:&nbsp;
                                          </TypographyHeading3>
                                        </Box>
                                        <Box>
                                          <TypographyList variant="body1">
                                            {ele.creator_role === "JP" ? "Job Provider" : "NA"}
                                          </TypographyList>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </MuiGrid>
                                </MuiGrid>

                                {jobPostTimeline.length === 1 ? null : (
                                  <hr style={{ marginBottom: "12px" }} />
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </>
                  )}
                </BoxGrid1>
              ) : null}
            </Grid> */}
          </Grid>
        </DashboardLayout>
      )}
      {/* {!view && (
        <PostProfile
          rowData={rowData}
          state={view}
          setView={setView}
          buttonFunc={handleStateFunc}
        />
      )} */}
    </>
  );
};

export default ProfilePage;
