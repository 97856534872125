import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Grid } from "@mui/material";
import { Card } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Edit from "../../../assets/images/Edit2.png";
import SoftInput from "components/SoftInput";
import pdfView from "../../../assets/images/logos/PDF_file_icon.png";
import Tabs from "@mui/material/Tabs";
import { columns } from "pages/AntTableData/shortlistCandidateData";
import { Table, Tooltip, Dropdown } from "antd";
import PropTypes, { string } from "prop-types";
import SoftSelect from "components/SoftSelect";
import Modal from "react-bootstrap/Modal";
import "../csStyle.css";
import axios from "axios";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import { Icon } from "@iconify/react";
import { Box, Button } from "@mui/material";
import { MdMoreHoriz } from "react-icons/md";
import Swal from "sweetalert2";
import moment from "moment/moment";
import refreshBtn from "../../../assets/images/logos/refresh_btn.png";
import Select from "react-select";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import { CSVLink, CSVDownload } from "react-csv";
//import Index from "pages/FilterSlider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Form from "react-bootstrap/Form";
import saveimg from "../../../assets/images/save_btn.svg"
import {
  statusList,
  WA_statusList,
  broadcast_statusList,
  retentionStatus,
  joiningStatus,
  selectedStatus,
  callStatus,
  JobStatus,
  pwdOption,
  readyResume,
  nextStepStatus,
  jpStatus,
  interviewStatus,
} from "../crmStatusConst";
import Index from "../index";
import Alltabs from "../SkillingShortlist/Tab";
import intimg from "../../../assets/images/Component151.png";
import FadeLoader from "react-spinners/FadeLoader";

const NewViewCandidates = (props) => {
  const {
    orgname,
    jobrole,
    data,
    buttonFunc,
    // orgId,
    // jobId,
    shortData,
    setShortData,
    handleShortlistedApi,
    isLoading,
    setIsLoading,
    ViewPage,
  } = props;
  const navigate = useNavigate();
  const [stored, setStored] = useState("");
  const [stored1, setStored1] = useState("");
  const [stored2, setStored2] = useState("");
  const superadminId = sessionStorage.getItem("register_id");
  const [selectedDSF, setSelectedDSF] = useState("");
  const [bucketOption, setBucketOption] = useState(false);
  const [broadCast, setBroadCast] = useState("");
  const [shortlistDate, setShortlistDate] = useState([]);
  const [modifiedDate, setModifiedDate] = useState([]);
  const [lastDate, setLastDate] = useState([]);
  const [assignDate, setAssignDate] = useState([]);
  const [waStatus, setWaStatus] = useState("");
  const [stList, setStList] = useState("");
  const [step, setStep] = useState("All");
  const [actionModal, setactionModal] = useState(false);
  const [actionModal1, setactionModal1] = useState(false);
  const [applyModal, setApplyModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rowJobId, setRowJobId] = useState([]);
  const [rowMob, setRowMob] = useState([]);
  const [rowName, setRowName] = useState([]);
  const [jobdesignation, setJobdesignation] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [cosList, setConsList] = useState([]);
  const [cosList1, setConsList1] = useState([]);
  const [counsellorName, setCounsellorName] = useState("");
  const [candid, setCandid] = useState([]);
  const [cosId, setCosId] = useState({});
  const [name, setName] = useState("");
  const [broadcastdate, setBroadcastdate] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [newSearchData, setNewSearchData] = useState([]);
  const [newSearchData1, setNewSearchData1] = useState([]);
  const [newSearchData2, setNewSearchData2] = useState([]);
  const [newSearchData4, setNewSearchData4] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newName, setNewName] = useState("");
  const [searchSet, setSearchSet] = useState(false);
  const [fileName, setFileName] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [state, setState] = useState([]);
  const [resumeLink, setResumeLink] = useState("");
  const [editImg, setEditImg] = useState(false);
  const [imgLogo, setImgLogo] = useState("");
  const [resumeModal, setResumeModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [resumefile, setResumeFile] = useState("");
  const [showTemplate, setShowTemplate] = useState(false);
  const [bulkResume, setBulkResume] = useState(false);
  const [uploadResume, setUploadResume] = useState(false);
  const [showshortlistModal1, setShowShortlistModal1] = useState(false);
  const [showshortlistModal5, setShowShortlistModal5] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  const [uploadExcel, setUploadExcel] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [orgIdA, setOrgIdA] = useState("");
  const [jobIdA, setJobIdA] = useState("");
  const [shortlistObjA, setShortlistObjA] = useState({});
  const [showshortlistModal1A, setShowShortlistModal1A] = useState(false);
  const [showShortlistModal2A, setShowShortlistModal2A] = useState(false);
  const [showShortlistModal3A, setShowShortlistModal3A] = useState(false);
  const [create, setCreate] = useState(false);
  const [orgNameID, setOrgNameID] = useState("");
  const [jobTitle, setJobtitle] = useState([]);
  const [jobType, SetjobType] = useState([]);
  const [allstate, Setallstate] = useState(false);
  const [filterState, setFilterState] = useState([]);
  const [filterDistrict, setFilterDistrict] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedFilterState, setSelectedFilterState] = useState("");
  const [selectedFilterDistrict, setSelectedFilterDistrict] = useState("");
  const [education, Seteducation] = useState([]);
  const [streamdata, SetstreamData] = useState([]);
  const [degree, Setdergee] = useState();
  const [exp, Setexp] = useState([]);
  const [createDS, setCreateDS] = useState(false);
  const [selectedDS, setSelectedDS] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [jobrolenew, Setjobrolenew] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [industryfilter, setIndustryfilter] = useState("");
  const [rolefilter, setRolefilter] = useState("");
  const [educationfilter, setEducationfilter] = useState("");
  const [skillfilter, setSkillfilter] = useState("");
  const [salaryfilter, setSalaryfilter] = useState("");
  const [expfilter, setExpfilter] = useState("");
  const [agefilter, setAgefilter] = useState([0, 100]);
  const [useAge, setUseAge] = useState(false);
  const [selfOpp, setSelfOpp] = useState("");
  const [genderfilter, setGenderfilter] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [timelineLoading, setTimeLineLoading] = useState(false);
  const [time, setTime] = useState([]);
  const [candData, setCandData] = useState({});
  const [tablerow, setTablerow] = useState([]);
  const [veridetails, setVeridetails] = useState({});
  const [jobname, setJobname] = useState("");
  const [multiSkill, setMultiSkill] = useState([]);
  const [skillsOption, SetSkillsOption] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [JSFilterDisplay, setJSFilterDisplay] = useState([]);
  const [params, setParams] = useSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [newOrgId, setNewOrgId] = useState(params.get("org_id"));
  const [newCourseId, setNewCourseId] = useState(params.get("course_id"));
  const [prevState, setPrevState] = useState({
    first_call: "",
    call_status: "",
    job_status: "",
    industry: "",
    ready_resume: "",
    next_step_status: "",
    suggested_job_bucket: "",
    display_comment: "",
    is_disabled: "",

    jp_status: "",

    interview_status: "",
    interview_date: "",

    selection_status: "",

    joining_status: "",
    retention_status: "",
    feedback: "",
  });

  const [showModal1, setShowModal1] = useState(false);
  const [items, setItems] = useState([
    // {
    //   key: "1",
    //   label: (
    //     <a style={{ display: "flex" }} className="dropdown-item">
    //       Activate AI Bot
    //     </a>
    //   ),
    // },
    {
      key: "2",
      label: (
        <a className="dropdown-item" style={{ display: "flex" }}>
          Assign Counsellor
        </a>
      ),
    },
    // {
    //   key: "4",
    //   label: (
    //     <a className="dropdown-item" style={{ display: "flex" }}>
    //       Reject Candidate
    //     </a>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <a className="dropdown-item" style={{ display: "flex" }}>
    //       Upload Resume
    //     </a>
    //   ),
    // },
  ]);
  const [veridetailsnew, setVeridetailsnew] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    date_of_birth: "",
    highest_education: "",
    current_salary: "",
    experience: "",
    job_role: "",
    skills: "",
    dataSource: "",
    state: "",
    taluka: "",
    district: "",
    lastactive: "",
    modified: "",
  });
  const [scoreft, setScoreft] = useState("");
  const [candidateRt, setCandidateRt] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [canId, setCanId] = useState("");
  const [counsellorId, setCounsellorId] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [ShowI, setShowI] = useState(false);
  const [showCallStatus, setShowCallStatus] = useState(false);
  const [showJobPost, setShowJobPost] = useState(false);
  const [agenew, setAgenew] = useState(26);
  const [view, setView] = useState(true);
  const [isLoadingCandData, setIsLoadingCandData] = useState(false);
  const [multiSalary, setMultiSalary] = useState([]);
  const resDATA = sessionStorage.getItem("register_id");
  const [addressChar, setAddressChar] = useState(200);
  const [streams, setStreams] = useState([]);
  const [jobDes, setJobDes] = useState("");
  const [filterStatus, setFilterStatus] = useState({
    approach_status: "",
    interest_level: "",
    hiring_status: "",
  });
  const [jobRole, setJobRole] = useState([]);
  const [stateFilter, setStateFilter] = useState("");
  const [districtFilter, setDistrictFilter] = useState("");

  const handleCloseShortlistModal1A = () => {
    setShowShortlistModal1A(false);
  };
  const handleCloseShortlistModal2A = () => {
    setShowShortlistModal2A(false);
  };
  const handleCloseShortlistModal3A = () => {
    setShowShortlistModal3A(false);
  };
  const [allData, setAllData] = useState(true);
  const [data1, setData1] = useState(data);
  const [data2, setData2] = useState([]);

  const [stage1, setStage1] = useState({
    // counsellor_id: superadminId,
    // candidate_id: candData.registration_id,
    // first_call: "2023-04-15",
    // call_status: "Answered",
    // job_status: "Actively looking for job",
    // industry: "Building and Construction",
    // ready_resume: "Yes",
    // next_step_status: "Placed Now",
    // suggested_job_bucket: "",
    first_call: "",
    call_status: "",
    job_status: "",
    industry: "",
    ready_resume: "",
    next_step_status: "",
    suggested_job_bucket: "",
    display_comment: "",
    is_disabled: "",
    // call_editable: false,
    // job_editable: false,
  });
  const [stage2, setStage2] = useState({
    // counsellor_id: superadminId,
    // candidate_id: candData?.registration_id,
    jp_status: "",
  });
  const [stage3, setStage3] = useState({
    // counsellor_id: superadminId,
    // candidate_id: candData?.registration_id,
    interview_status: "",
    interview_date: "",
  });
  const [stage4, setStage4] = useState({
    // counsellor_id: superadminId,
    // candidate_id: candData?.registration_id,
    selection_status: "",
  });
  const [stage5, setStage5] = useState({
    // counsellor_id: superadminId,
    // candidate_id: candData?.registration_id,
    joining_status: "",
    retention_status: "",
    feedback: "",
  });
  const con_id = sessionStorage.getItem("cons_id");
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleTexteditorChange = (value) => {
    setJobDes(value);
  };

  const handleChangeStatus = (e, ele) => {
    console.log(ele);
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_crm`, {
        id: candData.shortlist_id,
        stage_value: ele.label==="feedback"?stored1:ele.value,
        candid: candData.registration_id,
        course_id: searchParams.get("course_id"),
        action_by: sessionStorage.getItem("register_id"),

        datatype: ele.datatype,
        stage: ele.label,
      })
      .then((res) => {
        console.log(res.data);
        HandleFetchCandDetails(candData.registration_id);
        setStored1("")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const TermSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#52af77",
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });

  const handleAgeChangenew = (event, newValue, activeThumb) => {
    setAgenew(newValue === "" ? 26 : newValue);
  };

  const genderOption = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    // {
    //   label: "All",
    //   value: "All",
    // },
  ];

  const showAlert = () => {
    Swal.fire({
      icon: "success",
      title: "Details Recorded ",
      text: "Details have been recorded successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const calAge = (birthdate) => {
    console.log(birthdate, "111");
    const currentDate = new Date();
    const diffTime = currentDate - new Date(birthdate);
    const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const age = Math.floor(totalDays / 365.25);
    return age;
  };

  const handleFormChange111 = (event, index, state, setState) => {
    let data = candData.stage_values;
    console.log(data)
    console.log(index);
    console.log(event);
    data[index]["value"] = event.target.value;
    // data[index][event] = event.value;
    setCandData({ ...candData, stage_values: data });
  };

  const errorAlert = () => {
    Swal.fire({
      icon: "error",
      //title: "Details Recorded ",
      text: "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const [candidateData, setCandidateData] = useState({
    phone: "",
    email: "",
    dob: "",
    qualification: "",
    currentSalary: "",
    preferredWork: "",
    desiredRole: "",
    company: "",
    location: "",
    skills: "",
    experience: "",
    sourceOfData: "",
    addDataSource: "",
    referenceValue: "",
    botNurturingDone: "No",
    Resume: "",
  });

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        SetstreamData(res.data);
        setStreams(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
        setFilterState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState || selectedFilterState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
        setFilterDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDistricts();
  }, [selectedFilterState, selectedState]);

  const fetchSkills = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/skills`)
      .then((res) => {
        SetSkillsOption(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDataSources = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`)
      .then((res) => {
        setJSFilterDisplay(
          res.data["datasource_list"]
            .map((ele) => ele.value)
            .filter((item) => item?.toString() !== "")
            .filter((item) => item?.toString() !== "null")
            .filter((item) => !item.toString().startsWith("SMR-CP"))
            .concat(["Channel Partner"])
            .map((ele, i) => ({ label: ele, value: ele }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchState = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setStates(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistrict = () => {
    const params = {
      state: veridetailsnew?.state,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistricts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchState();
    fetchSkills();
    fetchDataSources();
  }, []);

  useEffect(() => {
    fetchDistrict();
  }, [veridetailsnew.state]);

  useEffect(() => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobtitle`,
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`, {
        params: {
          // org_name: orgNameID,
          orgid: orgNameID,
          type: "dropdown",
        },
      })
      .then((res) => {
        console.log(res.data.org_title);
        setJobtitle(res.data["JobPost_List"]);
        // setJobtitle(
        //   res.data.org_title.map((ele) => ({
        //     label: ele.job_title,
        //     value: ele.job_title,
        //   }))
        // );
      });
  }, [orgNameID]);
  useEffect(() => {
    fetchjobtype();
  }, []);

  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrolenew(res.data);
        setJobRole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobtype = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/jobtype`)
      .then((res) => {
        SetjobType(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeStatus1 = (ele) => {
    console.log(ele,'new');
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_crm`, {
        id: candData.shortlist_id,
        stage_value: ele.value,
        candid: candData.registration_id,
        course_id: candData.course_id,
        action_by: sessionStorage.getItem("register_id"),

        datatype: ele.datatype,
        stage: ele.label,
      })
      .then((res) => {
        console.log(res.data);
        HandleFetchCandDetails(candData.registration_id, candData.course_id);
        setStored("")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateDetails = useFormik({
    enableReinitialize: true,
    initialValues: {
      //   "createdby": "SA1",
      // "creator_role": "SA",
      registration_id: candData.registration_id,
      email: candData?.email ?? "",
      firstnm: candData?.firstname ?? "",
      lastnm: candData?.lastname ?? "",
      mobnum: candData?.mobnum ? candData?.mobnum?.length === 12 ? candData?.mobnum?.substring(2) : candData?.mobnum : "",
      // password: copyPassword.value ?? candData?."",
      // role_id: candData?.role_id ?? "",
      date_of_birth: candData?.date_of_birth ?? "",
      highest_education: candData?.highest_education ?? "",
      current_salary: candData?.current_salary ?? "",
      job_role: candData?.job_role ?? "",
      experience: candData?.experience ?? "",
      state: candData?.state ?? "",
      district: candData?.district ?? "",
      taluka: candData?.taluka ?? "",
      skills: candData?.skills ?? "",
      // skills: candData? candData.skills.split(",") : "",
      datasource: candData?.dataSource ?? "",
      stream: candData?.stream ?? "",
      current_industry: candData?.current_industry ?? "",

      desired_salary: candData?.desired_salary ?? "",

      languages: candData?.languages ?? "",
      self_opportunity: candData?.self_opportunity ?? "",
      relocation: candData?.relocation ?? "",
      // experience: 0-1
      avl_skill: candData?.avl_skill ?? "",
      resume_ready: candData?.resume_ready ?? "",
      gender: candData?.gender ?? "",
      marital_status: candData?.marital_status ?? "",
      address: candData?.address ?? "",
      wa_optin: candData?.wa_optin ?? "",
      degree: candData?.degree ?? "",
      resume: candData?.resume ?? "",
      pincode: candData?.pincode ?? "",
      datasource: candData?.datasource ?? "",
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Enter email"),

      //   security_ques: Yup.string().required("Please select a question "),
      //   security_ans: Yup.string().required("Please enter your answer"),
      // password: Yup.string()
      //   .min(8, "Password must be 8 characters long")
      //   .matches(/[0-9]/, "Password requires a number")
      //   .matches(/[a-z]/, "Password requires a lowercase letter")
      //   .matches(/[A-Z]/, "Password requires an uppercase letter")
      //   .matches(/[^\w]/, "Password requires a special charator"),
      // confirm_password: Yup.string()
      //   .required("Please enter confirm password")
      //   .oneOf([Yup.ref("password"), null], "Password must match"),
      //   org_name: Yup.string().required("Please enter organization name"),
      //   org_address: Yup.string().required("Please enter address"),
      //   org_state: Yup.string().required("Please enter state"),
      //   org_district: Yup.string().required("Please enter district"),
      //   org_pincode: Yup.string()
      //     .required("Please enter pincode")
      //     .matches(/^[0-9]+$/, "Must be only digits")
      //     .length(6, "Please enter 6 digit number"),
      //   org_cin_num: Yup.string()
      //     .required("Please enter CIN number")
      //     .length(21, "CIN number should be 21 digits"),
      //   org_size: Yup.string().required("Please select size of the organization"),
      //   org_type: Yup.string().required("Please select indutry type"),
      //   org_sector: Yup.string().required("Please select sector type"),
      // org_logo: Yup.string().required(
      //   "Please upload files < 5mb and make sure format is either jpeg or png"
      // ),
      // org_website: Yup.string().required("Please enter org website"),
      //   form_link: Yup.string().required("Please enter form link"),
      //   partner_association: Yup.string().required("Please assign project owner"),
      //   assign_to_owner: Yup.string().required("Please assign center owner"),
    }),
    onSubmit: (values) => {
      console.log("formii")
      // delete values.confirm_password;
      // delete values.partner_association;
      let formData = new FormData();
      formData.append("registration_id", candData.registration_id),
        // formData.append("updatedby", jpId),
        // formData.append("updator_role", roleId);
        // formData.append("role_id", "JP");
        // formData.append("creator_role", roleId);
        // formData.append("createdby", jpId);
        // formData.append("wa_notify", check);
        formData.append("firstname", values.firstnm);
      formData.append("lastname", values.lastnm);
      formData.append("mobnum", values.mobnum);
      formData.append("email", values.email);
      formData.append("date_of_birth", values.date_of_birth);
      formData.append("highest_education", values.highest_education);
      formData.append("current_salary", values.current_salary);
      formData.append("district", values.district);
      formData.append("experience", values.experience);
      formData.append("job_role", values.job_role);
      formData.append("state", values.state);
      formData.append("taluka", values.taluka);
      formData.append("skills", values.skills);
      formData.append("datasource", values.datasource);
      formData.append("stream", values.stream);
      formData.append("current_industry", values.current_industry);
      formData.append("desired_salary", values.desired_salary);

      formData.append("languages", values.languages);
      formData.append("self_opportunity", values.self_opportunity);
      formData.append("relocation", values.relocation);
      formData.append("avl_skill", values.avl_skill);
      formData.append("resume_ready", values.resume_ready);
      formData.append("gender", values.gender);

      formData.append("marital_status", values.marital_status);
      formData.append("address", values.address);
      formData.append("wa_optin", values.wa_optin);
      formData.append("degree", values.degree);
      formData.append("resume", values.resume);
      formData.append("pincode", values.pincode);
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      console.log("formiii", formData)
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/dashboard/candidate/skilling`,
          //  {
          //   ...values,
          //   role_id: "JP",
          //   creator_role: roleId,
          //   // creator_role: "SA",
          //   createdby: registerID,
          //   wa_notification: check ? "true" : "false",
          // }
          formData
        )
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);

          // Setshow(true);
          Swal.fire({
            //position: "top",
            icon: "success",
            title: "Profile updated successfully",
            //text: "Profile updated successfully",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            // window.location.reload(true)
            HandleFetchCandDetails(candData.registration_id, candData?.course_id);
            setShowEditModal(false);
            setOpenModal(true);
          });
          // } else {
          //   Swal.fire({
          //     icon: "warning",
          //     title: "",
          //     title: `${res.data["Developer Message"]}`,
          //     showConfirmButton: false,
          //     timer: 3000,
          //   });

        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);
          Swal.fire({
            icon: "error",
            title: "",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            // props.Seteditadmin(false);
            // navigate("/jobprovider-list-page");
          });
        });
    },
  });

  const UpdateTimeline = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/modify/candidate`,
        {
          candidate: candData.registration_id,
          counsellor_id: counsellorId,
          job_id: candData.jobid,
          current_stage: candData.stage,
          jp_status: prevState.jp_status,
          next_step_status: prevState.next_step_status,
          selection_status: prevState.selection_status,
          interview_status: prevState.interview_status,
          call_status: prevState.call_status,
          joining_status: prevState.joining_status,
        }
      );
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Updated Successfully",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        setShowModal1(false);
        stageDataFunc();
        HandleFetchCandDetails();
        HandleFetchCrm();
        HandleTimeline();
        handleVerifyDetailstimeline();
      });

      const data = await res.data;
      console.log(data);
    } catch (err) {
      console.log(err);
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const ResetTimeline = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/resetstatus`,
        {
          candid: candData.registration_id,
          // counsellor_id: con_id,
          course_id: searchParams.get("course_id"),
        }
      );
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Reset Successfully",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      stageDataFunc();
      HandleFetchCrm1();
      HandleTimeline();
      HandleFetchCandDetails(candData?.registration_id, candData?.course_id);
      handleVerifyDetailstimeline();
      const data = await res.data;
      console.log(data);
    } catch (err) {
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        // text: res.data["User Message"],
        //text: "Counsellor Re-assinged Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleVerifyDetailstimeline = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          first_name: veridetails.first_name,
          last_name: veridetails.last_name,
          mobile: veridetails.mobile,
          email: veridetails.email,
          date_of_birth: veridetails.date_of_birth,
          highest_education: veridetails.highest_education,
          current_salary: veridetails.current_salary,
          job_role: veridetails.job_role,
          experience: veridetails.experience,
        },
        counsellor_id: con_id,
      })
      .then((res) => {
        setShowModal(false);
        HandleFetchCandDetails(candData?.registration_id, candData?.course_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const jobpostformone = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      designation: "",
      applicantct: "",
      salary: "",
      jobpostdt: "",
      jobvaliddt: "",
      state: "",
      district: "",
      industry: "",
      qualification: "",
      stream: "",
      specialization: "",
      workfromhome: "",
      //jobdesc: "",
      createdby: resDATA,
      creator_role: "SA",
      headerimg: "",
      Experiance: "",
      age: "",
      gender: "",
      whatsapp_jd: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select organization name"),
      job_title: Yup.string().required("Please enter job title"),
      jobpostdt: Yup.string().required("Please select job post date"),
      jobvaliddt: Yup.string().required("Please select job post valid till date"),
      //state: Yup.string().required("Please select state"),
      whatsapp_jd: Yup.string().required("Please fill above field"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const job = new FormData();
      job.append("org_name", values.org_name);
      job.append("org_id", orgNameID);
      job.append("job_title", values.job_title);
      job.append("job_type", values.job_type);
      job.append("designation", values.designation);
      job.append("applicantct", values.applicantct);
      job.append("salary", values.salary);
      job.append("jobpostdt", values.jobpostdt);
      job.append("jobvaliddt", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      job.append("state", !allstate ? values.state : "");
      job.append("district", !allstate ? values.district : "");
      job.append("qualification", values.qualification);
      job.append("stream", values.stream);
      job.append("specialization", values.specialization);
      job.append("workfromhome", values.workfromhome);
      job.append("jobdesc", jobDes);
      job.append("createdby", values.createdby);
      job.append("creator_role", values.creator_role);
      job.append("experience", values.Experiance);
      job.append("age", agenew);
      job.append("gender", values.gender);
      job.append("whatsapp_jd", values.whatsapp_jd);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
          job
        );
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "success",
          title: "Job posting has been created successfully",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          jobpostformone.handleReset();
          handleCloseShortlistModal3A();
        });
      } catch (err) {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  });

  const jobpostform = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      designation: "",
      applicantct: "",
      salary: "",
      jobpostdt: "",
      jobvaliddt: "",
      state: "",
      district: "",
      industry: "",
      qualification: "",
      stream: "",
      specialization: "",
      workfromhome: "",
      jobdesc: "",
      // createdby: resDATA,
      creator_role: "SA",
      headerimg: "",
      Experiance: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please Select Organization Name"),
      job_title: Yup.string().required(" Please enter Job Title"),
      // job_type: Yup.string().required(" Please enter Job Type"),
      // designation: Yup.string().required(" Please Select Designation"),
      // applicantct: Yup.string().required(" Please Select Applicanctct"),
      // salary: Yup.string().required(" Please Select Salary"),
      jobpostdt: Yup.string().required(" Please Select Job Post Date"),
      jobvaliddt: Yup.string().required(" Please Select Job Post till Date"),
      state: Yup.string().required(" Please Select State"),
      // district: Yup.string().required(" Please Select District"),
      //industry: Yup.string().required(" Please Select Industry"),
      // qualification: Yup.string().required(" Please Select Qualifaction"),
      // stream: Yup.string().required(" Please Select Stream"),
      // specialization: Yup.string().required(" Please Select Specialization"),
      // workfromhome: Yup.string().required(" Please Select "),
      // jobdesc: Yup.string().required(" Please Enter Job Description "),
      // Experiance: Yup.string().required(" Please Select Experiance"),
    }),
    onSubmit: (values) => {
      const job = new FormData();
      job.append("org_name", values.org_name);
      job.append("org_id", orgNameID);
      job.append("job_title", values.job_title);
      job.append("job_type", values.job_type);
      job.append("designation", values.designation);
      job.append("applicantct", values.applicantct);
      job.append("salary", values.salary);
      job.append("jobpostdt", values.jobpostdt);
      job.append("jobvaliddt", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      job.append("state", !allstate ? values.state : "");
      job.append("district", !allstate ? values.district : "");
      job.append("qualification", values.qualification);
      job.append("stream", values.stream);
      job.append("specialization", values.specialization);
      job.append("workfromhome", values.workfromhome);
      job.append("jobdesc", values.jobdesc);
      job.append("createdby", values.createdby);
      job.append("creator_role", values.creator_role);
      job.append("experience", values.Experiance);

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
          // `${ngrokLink}/superadmin/candidatesearch/jobpost`,
          job
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Job posting has been created successfully",
            }).then((result) => {
              setShowShortlistModal3(false);
              setShowShortlistModal1(false);
              setShowShortlistModal2(true);
            });
            // Swal.fire({
            //   icon: "success",
            //   text: "Job posting has been created successfully",
            //   showDenyButton: true,
            //   // showCancelButton: true,
            //   confirmButtonText: "Add New Post",
            //   denyButtonText: `Search Candidate`,
            // }).then((result) => {
            //   /* Read more about isConfirmed, isDenied below */
            //   if (result.isConfirmed) {
            //     // Swal.fire("Saved!", "", "success");
            //     jobpostform.resetForm();
            //     setOrgNameID("");
            //     setSelectedState();
            //     // location.reload();
            //     // jobpostform.setFieldValue("org_name", "");
            //     // window.reload();
            //     // navigate("/searchbyposting/createposting");
            //     // console.log("hello");
            //   } else if (result.isDenied) {
            //     // Swal.fire("Changes are not saved", "", "info");
            //     sessionStorage.setItem("job_id", res.data["JobID"]);
            //     console.log(res.data["JobID"]);
            //     sessionStorage.setItem("searchdata_org_name", values.org_name);
            //     sessionStorage.setItem("searchdata_org_id", orgNameID);
            //     // sessionStorage.setItem("searchdata_designation", values.designation);
            //     // sessionStorage.setItem("searchdata_salary", values.salary);
            //     sessionStorage.setItem("searchdata_state", values.state);
            //     sessionStorage.setItem("searchdata_district", values.district);
            //     // sessionStorage.setItem("searchdata_industry", values.industry);
            //     // sessionStorage.setItem("searchdata_qualification", values.qualification);
            //     // sessionStorage.setItem("searchdata_experience", values.Experiance);
            //     // sessionStorage.setItem("searchdata_stream", values.stream);
            //     // sessionStorage.setItem("searchdata_specialization", values.specialization);
            //     navigate("/searchbyposting/createposting/recommendedPostList");
            //   }
            // });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Oops...",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  });

  console.log(data, "abcd");

  const fetchOrgList = () => {
    const params = {
      // partner: "samarth",
      type: "dropdown",
      // type: "detail",
    };
    // const headers = {
    //   "access-control-allow-origin": "*",
    //   "Content-type": "application/json; charset=UTF-8",
    // };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // `${ngrokLink}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_List"]);
        setOrgList(res.data["Organisation_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOgrRole = () => {
    const params = {
      orgid: orgIdA,
      type: "dropdown",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?${paramString}`
        // `${ngrokLink}/superadmin/candidatesearch/jobpostdata?${paramString}`
      )
      .then((res) => {
        // setDistrict(res.data);
        setRoleList(res.data["JobPost_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVerifyDetailsNew = () => {
    if (
      veridetailsnew.first_name === "" ||
      veridetailsnew.last_name === "" ||
      veridetailsnew.mobile === "" ||
      veridetailsnew.date_of_birth === "" ||
      veridetailsnew.highest_education === "" ||
      veridetailsnew.state === "" ||
      veridetailsnew.district === "" ||
      veridetailsnew.job_role === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Fill all mandatory fields",
        //text: "Something went wrong",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
          verification_details: {
            registration_id: candData.registration_id,
            first_name: veridetailsnew.first_name,
            last_name: veridetailsnew.last_name,
            mobile: veridetailsnew.mobile,
            email: veridetailsnew.email,
            date_of_birth: veridetailsnew.date_of_birth,
            highest_education: veridetailsnew.highest_education,
            current_salary: veridetailsnew.current_salary,
            job_role: veridetailsnew.job_role,
            experience: veridetailsnew.experience,
            skills: veridetailsnew.skills,
            datasource: veridetailsnew.dataSource,
            state: veridetailsnew.state,
            district: veridetailsnew.district,
            taluka: veridetailsnew.taluka,
          },
          counsellor_id: canId,
        })
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);
          Swal.fire({
            icon: "success",
            //title: "",
            title: "Profile updated successfully!",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            setShowEditModal(false);
            HandleFetchCandDetails(candData?.registration_id);
          });
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);
          Swal.fire({
            icon: "error",
            title: "",
            text: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const GenerateData = async (text) => {
    try {
      let formData = new FormData();
      formData.append("candidate_name", text.candidate_name);
      formData.append("taluka", text.taluka);
      formData.append("district", text.district);
      formData.append("state", text.state);
      formData.append("skills", text.skills);
      formData.append("education", text.education);
      formData.append("exp", text.exp);
      formData.append("industry", text.industry);
      formData.append("job_role", text.job_role);
      formData.append("job_title", text.job_title);
      formData.append("phone", text.phone);

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
        formData
      );
      const data = await res.data;
      Swal.fire({
        icon: "success",
        text: "Resume generated successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: "Something went wrong!",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const fetchDataSource = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`)
      .then((res) => {
        console.log("datasource", res.data["datasource_list"]);
        setDataSource(res.data["datasource_list"]);
        // setJSFilterDataSource(res.data["datasource_list"]);
        // setDataSource(res.data["datasource_list"].map((ele, i) => ({ label: ele, value: ele })));
        // setJSFilterDataSource(
        //   res.data["datasource_list"].map((ele, i) => ({ label: ele, value: ele }))
        // );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchDataSource();
    fetchOrgList();
    // fetchOgrRole();
    fetcheducation();
    fetchstream();
    fetchdegree();
    fetchexp();
    fetchStateList();
    fetchSalary();
    fetchjobrole();
    fetchjobtype();
  }, []);

  useEffect(() => {
    fetchOgrRole();
  }, [orgIdA]);

  const selectBucket = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_role: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select Organization name"),
      job_role: Yup.string().required("Please select Job role"),
    }),
    onSubmit: (values) => {
      console.log("clicked handle submit");
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/transfershortlistCandidate`,
          {
            // org_name: values.org_name,
            // job_role: values.job_role,
            // shortlisted_id: selectedRowKeys,
            shortlistedid: shortlistObjA,
            jobid: jobIdA,
            orgid: orgIdA,
            // shortlisted_id: { "SMR-124536-JP1": "80", "SMR-124536-JP5": "40" },
            // jobid: "SMR-Job-JP",
            // orgid: "SMR-145256-JP",
          }
        )
        .then((res) => {
          console.log(res);
          Swal.fire({
            icon: "success",
            title: "",
            // text: res.data["User Message"],
            text: "Candidate Transferd Successfully",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            // navigate("/searchbyposting/createposting/recommendedPostList");
            handleShortlistedApi();
            setShowShortlistModal1A(false);
            setShowShortlistModal2A(false);
          });
          // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Oops...",
            // text: res.data["User Message"],
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  });

  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);

    // return "mis_data_" + dformat + ".txt";
  };

  useEffect(() => {
    step1 && handleShortlistedApi(newOrgId, newCourseId);
  }, [step1]);

  useEffect(() => {
    handleShortlistedApi(newOrgId, newCourseId);
    // setSearchSet(true);
    setSearchSet(false);
    setAllData(true);
    // setIsLoading(true);
    // localStorage_toggle && setIsLoading(localStorage_toggle);
  }, []);

  useState(() => {
    if (ViewPage === "All") {
      setStep1(true);
    }
  }, [props.ViewPage]);

  const CapitalizeFunc = (str) => {
    const arr = str.split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].splice(1);
    }
    const newStr = arr.join(" ");
    return newStr;
  };

  const ResumeUploadFunc = async (data) => {
    console.log("resume row:", data);
    let docData = new FormData();
    docData.append("jsid", data.key);
    docData.append("type", "resume");
    docData.append("resume", resumefile);
    // docData.append("updatedby", registerID);
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/register`,
        docData
      )
      .then((res) => {
        console.log("upload", res);
        Swal.fire({
          icon: "success",
          title: "",
          text: res.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        }).then(() => (setResumeModal(false), handleShortlistedApi(newOrgId, newCourseId)));
      })
      .catch((err) => {
        console.log("upload", err);
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const bulkUploadfunc = () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    // setLoading(true);
    // Swal.fire({
    //   //icon: "success",
    //   title: "Uploading",
    //   text: "File Upload is in progress",
    //   showConfirmButton: false,
    //   timer: 3000,
    // });
    let formData = new FormData();
    formData.append("jobseeker_file", uploadFile);
    // formData.append("cpid", cpId);
    formData.append("jobid", newCourseId);
    formData.append("orgid", newOrgId);

    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/checkcandidate`, formData)
      .then((res) => {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "File Uploaded successfully",
            showConfirmButton: true,
            confirmButtonText: "Genrate Report",
            // timer: 3000,
          }).then((result) => {
            // var el = document.createElement("a");
            // el.setAttribute("download", "");
            // el.href = res.data.link;
            // console.log(res.data.link);
            // document.body.appendChild(el);
            // el.remove();
            if (result.isConfirmed) {
              // Handle the download logic here
              // Create and trigger a download link
              const fileUrl = res.data.link; // Replace with your file URL
              const link = document.createElement("a");
              link.href = fileUrl;
              link.download = "upload_candidate.xlsx"; // Specify the desired filename
              link.target = "_blank"; // Open the download link in a new tab
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link); // Clean up the link element
            }
          });
          // setactionModal2(false);
          setShowShortlistModal1(false);
          handleShortlistedApi(newOrgId, newCourseId);
          // setResponseModal(true);
          // setUploadModal(false);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const bulkUploadStatusfunc = () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let formData = new FormData();
    formData.append("excel_data", uploadExcel);
    formData.append("job_id", newCourseId);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/bulk/upload/status`,
        formData
      )
      .then((res) => {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            //title: "Success",
            title: "File Uploaded successfully",
            showConfirmButton: false,
            //confirmButtonText: "Genrate Report",
            timer: 3000,
          });
          setShowShortlistModal5(false);
          handleShortlistedApi(newOrgId, newCourseId);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const open = Boolean(anchorEl);
  const localStorage_jobId = sessionStorage.getItem("course_id");
  const localStorage_orgId = sessionStorage.getItem("org_id");
  const localStorage_jobRole = sessionStorage.getItem("course_name");
  const localStorage_orgName = sessionStorage.getItem("org_name");
  const localStorage_toggle = sessionStorage.getItem("shortlisted_local");
  console.log(
    "localstorage",
    localStorage_jobId,
    localStorage_orgId,
    localStorage_toggle,
    localStorage_orgName,
    localStorage_jobRole,
    newOrgId,
    newCourseId,
    isLoading
  );
  // const [buutonToggle, setButtonToggle] = useState(false);
  // const [val,setVal] = useState('');
  // const [searchData,setSearchData] = useState(data);
  // const rowJobId = [];
  console.log("shortdata ", shortData);
  console.log("data ", data);
  console.log(actionModal1);
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setShortlistObjA(
      selectedRows.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.score === undefined ? 0 : 0 }),
        {}
      )
    );
    setTablerow(selectedRows);
    console.log("selected rows", selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
    // selectedRows.map((ele, i) => {
    //   console.log("element ", ele);
    //   // setRowJobId((item) => {
    //   //   [...item, ele.key];
    //   // });

    //   // rowJobId.concat([ele.jobid]);
    // });
    setRowJobId(selectedRows.map((item, i) => item.jobid));
    setRowMob(selectedRows.map((item, i) => item.phone));
    setRowName(selectedRows.map((item, i) => item.candidate_name));
    setCandid(selectedRows.map((item, i) => item.key));
    setJobdesignation(selectedRows.map((item, i) => item.job_role));
  };
  console.log(
    tablerow.filter((ele) => {
      return broadCast !== "" ? ele.sent_status === broadCast : ele;
    }),
    "tablerow"
  );
  console.log("row jobid1", rowJobId);
  console.log("row mob", rowMob);
  console.log("row name", rowName);
  console.log("row jp id", jobdesignation);
  console.log("row keys", selectedRowKeys);
  console.log("broadcastdby id", newOrgId);
  console.log("canid", candid);
  console.log("selected candidi tras:", shortlistObjA);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (step === "List to AI Bot Engaged") {
      searchFunc2();
    } else if (step === "List to Counsellor Engaged") {
      searchFunc3();
    } else if (step === "Unengaged") {
      searchFunc4();
    } else if (step === "All") {
      searchFunc();
    }
    // setSearchSet(false);
    setSearchSet(false);
    setAllData(false);
  }, [newName, step]);


  const HandleFetchCandDetailsA = async (can_id, counsellor_id) => {
    // setIsLoading(true);
    console.log("value:", can_id, counsellor_id, newOrgId, newCourseId),
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/register`,
          {
            params: {
              reg_id: can_id,
              counsellor_id: counsellor_id ? counsellor_id : superadminId,
              orgid: newOrgId ? newOrgId : localStorage_orgId,
              jobid: newCourseId ? newCourseId : localStorage_jobId,
            },
          }
        )
        .then((res) => {
          console.log(res.data.JobSeeker_List, "ppp");
          /*
          setCandData(res.data.JobSeeker_List);
          setVeridetails({
            first_name: res.data.JobSeeker_List.first_name,
            last_name: res.data.JobSeeker_List.last_name,
            email: res.data.JobSeeker_List.email,
            mobile: res.data?.JobSeeker_List?.mobile
              ? res.data?.JobSeeker_List?.mobile.length === 12
                ? res.data?.JobSeeker_List?.mobile?.substring(2)
                : res.data?.JobSeeker_List?.mobile
              : "",
            date_of_birth: res.data.JobSeeker_List.date_of_birth,
            highest_education: res.data.JobSeeker_List.highest_education,
            current_salary: res.data.JobSeeker_List.current_salary,
            experience: res.data.JobSeeker_List.experience,
            job_role: res.data.JobSeeker_List.job_role,
            dataSource: res.data.JobSeeker_List.datasource,
            state: res.data.JobSeeker_List.state,
            taluka: res.data.JobSeeker_List.taluka,
            district: res.data.JobSeeker_List.district,
            lastactive: moment(res.data.JobSeeker_List.last_activedate).format("DD-MM-YYYY"),
            modified: moment(res.data.JobSeeker_List.last_modified_dt).format("DD-MM-YYYY"),
          });
          setVeridetailsnew({
            first_name: res.data?.JobSeeker_List?.first_name,
            last_name: res.data?.JobSeeker_List?.last_name,
            email: res.data?.JobSeeker_List?.email,
            mobile: res.data?.JobSeeker_List?.mobile
              ? res.data?.JobSeeker_List?.mobile.length === 12
                ? res.data?.JobSeeker_List?.mobile?.substring(2)
                : res.data?.JobSeeker_List?.mobile
              : "",
            date_of_birth: res.data?.JobSeeker_List?.date_of_birth,
            highest_education: res.data.JobSeeker_List.highest_education,
            current_salary: res.data.JobSeeker_List.current_salary,
            skills: res.data.JobSeeker_List.skills,
            experience: res.data.JobSeeker_List.experience,
            job_role: res.data.JobSeeker_List.job_role,
            dataSource: res.data.JobSeeker_List.datasource,
            state: res.data.JobSeeker_List.state,
            taluka: res.data.JobSeeker_List.taluka,
            district: res.data.JobSeeker_List.district,
            lastactive: moment(res.data.JobSeeker_List.last_activedate).format("DD-MM-YYYY"),
            modified: moment(res.data.JobSeeker_List.last_modified_dt).format("DD-MM-YYYY"),
          });
          setOpenModal(true);
          // setVerifyDetail(res.data.JobSeeker_List.verification_details);
          // setIsLoading(false);
          */
        })
        .catch((err) => {
          console.log(err);
          // setIsLoading(false);
        });
  };

  useEffect(() => {
    HandleFetchCandDetailsA()
  }, [])

  const HandleFetchCandDetails = async (value) => {
    console.log("value props:", value);
    //setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_crm_details`, {
        params: {
          candid: value,
          // orgid: orgname,
          course_id: searchParams.get("course_id"),
          // counsellor_id: sessionStorage.getItem("cons_id"),
        },
      })
      .then((res) => {
        setCandData(res.data);
        setVeridetails({
          first_name: res.data.firstname,
          last_name: res.data.lastname,
          email: res.data.email,
          mobile: res.data.mobile,
          date_of_birth:
            res.data.date_of_birth !== null
              ? moment(res.data.date_of_birth).format("YYYY-MM-DD")
              : null,
          highest_education: res.data.highest_education,
          current_salary: res.data.current_salary,
          experience: res.data.experience,
          job_role: res.data.job_role,
          dataSource: res.data.datasource,
          state: res.data.state,
          skills: res.data.skills,
          taluka: res.data.taluka,
          district: res.data.district,
          lastactive: moment(res.data.last_activedate).format("DD-MM-YYYY"),
          modified: moment(res.data.last_modified_dt).format("DD-MM-YYYY"),
          notes:
            res.data.notes === "" || res.data.notes === "nan" || res.data.notes === null
              ? ""
              : res.data?.notes,

          registration_id: res.data.registration_id,

          stream: res.data.stream,
          current_industry: res.data.current_industry,

          desired_salary: res.data.desired_salary,

          languages: res.data.languages,
          self_opportunity: res.data.self_opportunity,

          avl_skill: res.data.avl_skill,

          gender: res.data.gender,
          marital_status: res.data.marital_status,

          mobnum: res.data.mobnum,
          address: res.data.address,
          wa_optin: res.data.wa_optin,
          resume: res.data.resume,
          pincode: res.data.pincode,
          datasource: res.data.dataSource,
          createddt: res.data.createddt,
          stage: res.data.stage,
        });
        setVerifyDetail(res.data.verification_details);
        setShowCallStatus(res.data.call_status === "Answered" ? true : false);
        setShowJobPost(res.data.job_status === "Actively looking for job" ? true : false);
      })
      .catch((err) => {
        console.log(err);
        //setIsLoading(false);
      });
  };
  const HandleFetchCrm1 = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_shortlist`, {
        params: {
          orgid: searchParams.get("org_id"),
          course_id: candData?.course_id,
          counsellor_id: candData?.counsellor_id,
        },
      })
      .then((res) => {
        console.log(res, "text3");
        setSearchData(res.data.map((ele) => (
          {
            ...ele,
            completion_status:
              ele.completion_status === 1
                ? "in_progress"
                : ele.completion_status === 2
                  ? "completed"
                  : "rejected",

          }
        )))
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    HandleFetchCrm1()
  }, [])

  const HandleFetchCrm = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/dashboard/applicant/crm/fetch`,
        {
          params: {
            orgid: orgname,
            jobid: jobname,
            counid: sessionStorage.getItem("cons_id"),
          },
        }
      )
      .then((res) => {
        setCrmData(
          res.data.counsellor_details.map((ele, i) => ({
            key: ele.registration_id,
            ...ele,
            assign_date:
              ele.assign_date !== "-" ? moment(ele.assign_date).format("DD-MM-YYYY") : " - ",
            name: ele.first_name + " " + ele.last_name,
            mobile: ele?.mobile,
            ratings: ele.ratings,
            score: ele.score,
            // company: ele.job_detail.company,
            // rating: i % 2 !== 0 ? "2.8" : "3.6",
          }))
        );
        /*
        setSearchData(
          res.data.counsellor_details.map((ele, i) => ({
            key: ele.registration_id,
            ...ele,
            assign_date: ele.assign_date !== "-" ? new Date(ele.assign_date) : " - ",
            name: ele.first_name + " " + ele.last_name,
            mobile: ele?.mobile,
            ratings: ele.ratings,
            score: ele.score,
            // company: ele.job_detail.company,
            // rating: i % 2 !== 0 ? "2.8" : "3.6",
          }))
          
        );
        */


        setPaginationStateMain({
          ...paginationStateMain,
          dataSize: res.data.counsellor_details.length,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleTimeline = () => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_crm`, {
        // params: {
        candid: candData?.registration_id,
        // course_id:jobId
        // counsellor_id: sessionStorage.getItem("register_id"),
        // orgid: orgId,
        // jobid: jobId,
        // },
      })
      .then((res) => {
        console.log(res.data);
        setTime(
          res.data.map((ele) => ({
            ...ele,
            // "type": "Call Status",
            // "status": "Answered",
            date: ele.timestamp,
            assignee: ele.action_by_name ? ele.action_by_name : "",
            // "org_name": "Sapio Analytics Pvt. Ltd.",
            job_title: ele.course_name,
            job_id: ele.course_id,
          }))
        );
        setIsLoading(false);
        // HandleFetchCandDetails(canId);
        // handleOpenModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    HandleTimeline();
    // stageDataFunc();
  }, [candData]);

  const searchFunc = () => {
    setSearchSet(false);
    let newData0 = data.filter((ele) => {
      return newName?.toLocaleLowerCase() === ""
        ? ele
        : ele.candidate_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
          String(newName) === ""
          ? ele
          : String(ele.phone).includes(String(newName));
    });

    let newData =
      broadCast !== "" ? newData0.filter((ele) => ele.sent_status === broadCast) : newData0;
    let newData1 =
      waStatus !== "" ? newData.filter((ele) => ele.interest_level === waStatus) : newData;
    let newData2 =
      stList !== "" ? newData1.filter((ele) => ele.candidate_status === stList) : newData1;
    let newData3 =
      counsellorName !== ""
        ? newData2.filter((ele) => ele.counsellor_name === counsellorName)
        : newData2;
    let newData4 =
      shortlistDate !== ""
        ? newData3.filter(
          (ele) => ele?.shortlist_date === moment(shortlistDate).format("DD-MM-YYYY")
        )
        : newData3;
    // let newData5 =
    //   broadcastdate !== ""
    //     ? newData5.filter(
    //         (ele) => ele?.broadcast_date === moment(broadcastdate).format("DD-MM-YYYY")
    //       )
    //     : newData5;
    setNewSearchData(newData4);
  };

  const resetfunc = () => {
    setBroadCast("");
    setWaStatus("");
    setStList("");
    setCounsellorName("");
    setSearchSet(false);
    setAllData(true);
    setShortlistDate([]);
    setBroadcastdate([]);
    setScoreft("");
    setCandidateRt("");
    setResponseStatus("");
    setSelectedDSF("");
    setStateFilter("");
    setDistrictFilter("");
    setIndustryfilter("");
    setRolefilter("");
    setEducationfilter("");
    setSkillfilter("");
    setSalaryfilter("");
    setExpfilter("");
    setAgefilter([0, 100]);
    setGenderfilter("");
    setModifiedDate([]);
    setLastDate([]);
    setAssignDate([]);
    setUseAge(false);
  };

  const searchFunc2 = () => {
    setSearchSet(false);
    const newData = shortData
      .filter(
        (ele) =>
          ele.broadcast_status === "1" ||
          ele.broadcast_status === "2" ||
          ele.broadcast_status === "3"
      )
      // .filter((ele) => ele.broadcasted === "1")
      .map((item, i) => ({
        ...item,
        key: item.registration_id,
        mobile: item?.mobile,
        last_activedate: moment(item.last_activedate).format("DD-MM-YYYY"),
        modifieddt: moment(item.modifieddt).format("DD-MM-YYYY"),
        job_title: item.job_title,
        // rating: i % 2 !== 0 ? "2.8" : "3.8",
        rating: item.ratings,
        shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
        wa_status: item.wa_status,
      }))
      .filter((ele) => {
        console.log("searchFunc2", ele);
        return newName?.toLocaleLowerCase() === ""
          ? ele
          : ele.first_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
            newName?.toLocaleLowerCase() === ""
            ? ele
            : ele.last_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
              newName?.toLocaleLowerCase() === ""
              ? ele
              : String(ele.mobile?.toLocaleLowerCase()).includes(String(newName.toLocaleLowerCase()));
      });
    console.log(newData);
    setNewSearchData1(newData);
  };

  const searchFunc3 = () => {
    setSearchSet(false);
    const newData = shortData
      .filter((ele) => ele.counsellor_name !== "")
      .map((item, i) => ({
        ...item,
        key: item.registration_id,
        mobile: item?.mobile,
        rating: item.ratings,
        shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
        // rating: i % 2 !== 0 ? "2.8" : "3.8",
      }))
      .filter((ele) => {
        return newName?.toLocaleLowerCase() === ""
          ? ele
          : ele.first_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
            newName?.toLocaleLowerCase() === ""
            ? ele
            : String(ele.mobile).includes(String(newName));
      });
    console.log(newData);
    setNewSearchData2(newData);
  };
  const searchFunc4 = () => {
    setSearchSet(false);
    const newData = shortData
      .filter((ele) => ele.broadcast_status === "0" || ele.counsellor_name === "")
      .map((ele) => ({
        ...ele,
        key: ele.registration_id,
        phone: ele?.mobile?.length === 12 ? ele.mobile?.substring(2) : ele.mobile,
        // phone: "9892038669",
        education: ele.highest_education,
        modified_on: moment(ele.modifieddt).format("DD-MM-YYYY"),
        skills: ele.skills === null ? ele.job_role : ele.skills,
        taluka: ele.taluka,
        state: ele.state,
        district: ele.district,
        last_active: moment(ele.last_activedate).format("DD-MM-YYYY"),
        candidate_name: `${ele.first_name} ${ele.last_name}`,
        // score: `0`,
        score: ele.score,
        shortlist_date: moment(ele.shortlist_date).format("DD-MM-YYYY"),
        // status: "45",
        industry: ele.industry,
        job_role: ele.job_role,
        broadcast_status: ele.broadcast_status,
        jobid: ele.jobid,
        status: ele.wa_status,
        response: ele.response,
        job_title: ele.job_title,
        datasource: ele.datasource,
        ratings: ele.ratings,
        salary_req: ele.desired_salary,
        exp: ele.experience,
        display_comment: ele.display_comment,
        feedback: ele.feedback,
        sent_status: ele.sent_status,
        completion_status: ele.completion_status,
        candidate_status: ele.candidate_status,
        counsellor_name: ele.counsellor_name,
        resume: ele.resume_cv,
        // return {
        //   key: ele.registration_id,
        //   phone: ele.mobile.substring(2),
        //   // phone: "9892038669",
        //   education: ele.highest_education,
        //   modified_on: moment(ele.modifieddt).format("DD-MM-YYYY"),
        //   skills: ele.skills === null ? ele.job_role : ele.skills,
        //   taluka: ele.taluka,
        //   state: ele.state,
        //   district: ele.district,
        //   last_active: moment(ele.last_activedate).format("DD-MM-YYYY"),
        //   candidate_name: `${ele.first_name} ${ele.last_name}`,
        //   // score: `80`,
        //   // score: ele.score,
        //   // status: "45",
        //   industry: ele.industry,
        //   job_role: ele.job_role,
        //   broadcast_status: ele.broadcast_status,
        //   jobid: ele.jobid,
        //   status: ele.wa_status,
        //   response: ele.response,
        //   job_title: ele.job_title,
        //   datasource: ele.datasource,
        //   ratings: ele.ratings,
        //   salary_req: ele.desired_salary,
        //   exp: ele.experience,
        //   display_comment: ele.display_comment,
        //   feedback: ele.feedback,
        //   sent_status: ele.sent_status,
        //   completion_status: ele.completion_status,
        //   candidate_status: ele.candidate_status,
        //   counsellor_name: ele.counsellor_name,
        //   resume: ele.resume_cv,
        //   // rating: i % 2 !== 0 ? "2.8" : "3.8",
        // };
      }))
      .filter((ele) => {
        // console.log("searchFunc", ele);
        return newName?.toLocaleLowerCase() === ""
          ? ele
          : ele.candidate_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
            newName?.toLocaleLowerCase() === ""
            ? ele
            : String(ele.phone?.toLocaleLowerCase()).includes(String(newName.toLocaleLowerCase()));
      });
    console.log(newData);
    setNewSearchData4(newData);
  };
  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };

  const head = {

    0: "Applicant Details",
    1: "Interview",
    2: "Interview",
    3: "Interview",
    4: "Post Selection",
    5: "Post Selection"
  }

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
      minWidth: "200px",
      maxWidth: "100%",
      height: "41px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const stylesDD2 = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
      width: "320px",
      height: "41px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: (record) => ({
    //   disabled: record.counsellor_name !== "",
    //   // Column configuration not to be checked
    //   counsellor_name: record.counsellor_name,
    // }),
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    setStored1(candData?.stage === 5
      ? candData?.stage_values
        ?.filter((ee) => ee.key === 4)
        ?.map((ele, i) => ele)?.filter((ee) => ee.name === 'Feedback')?.map((v) => v.value)[0] : "");
    setStored2(candData?.stage === 5
      ? candData?.stage_values
        ?.filter((ee) => ee.key === 4)
        ?.map((ele, i) => ele)?.filter((ee) => ee.name === 'Feedback')?.map((v) => v.value)[0] : "");
  }, [candData?.stage])

  const handleConsPostData = () => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_assign`, {
        orgid: newOrgId,
        // course_id: rowJobId?.length !== 0 ? rowJobId : rowData.jobid?.split(" "),
        course_id: newCourseId ? newCourseId : rowData.jobid?.split(" "),
        candidate_ids: candid?.length !== 0 ? candid : rowData.key?.split(" "),
        counsellor_id: cosId.value,
        shortlistedby: superadminId,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data["User Message"],
          showConfirmButton: false,
          //position: "top",
          timer: 3000,
        })
          .then(() => {
            // onSelectChange();
            setIsLoading(false);
          })
          .then(() => {
            navigate("/skilling-shortlisted");
          });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        setIsLoading(false);
      });
  };

  const handleRejectStatus = (rowdata) => {
    // setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/bulk/reject`, {
        candidate_ids: selectedRowKeys?.length !== 0 ? selectedRowKeys : rowdata.key?.split(" "),
        job_id: newCourseId,
        // orgid: orgId,
        // jobid: rowJobId?.length !== 0 ? rowJobId : rowData.jobid?.split(" "),
        // candid: candid?.length !== 0 ? candid : rowData.key?.split(" "),
        // counid: cosId.value,
        // assignee_id: superadminId,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Candidates Rejected",
          text: res.data["data"],
          showConfirmButton: false,
          //position: "top",
          timer: 3000,
        }).then(() => {
          // onSelectChange();
          // setIsLoading(false);
          handleShortlistedApi(newOrgId, newCourseId);
        });
        // .then(() => {
        //   navigate("/shortlisted");
        // });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: err.response.data["data"] ? err.response.data["data"] : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["error"],
          showConfirmButton: false,
          timer: 3000,
        });
        setIsLoading(false);
      });
  };

  const handleConsPost = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/dashboard/fetch/details`, {
        params: { createdby: superadminId },
      })
      .then((res) => {
        setConsList(res.data.counsellor_details);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConsPost1 = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/dashboard/fetch/details`, {
        params: { createdby: superadminId },
      })
      .then((res) => {
        setConsList1(
          res?.data?.counsellor_details?.map((v) => {
            return {
              label: v.label,
              value: v.label,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("date filters:", shortlistDate);
  console.log("date filters bd:", broadcastdate);
  console.log(
    "data for date filter:",
    data
      ?.filter((ele) => {
        return shortlistDate?.length === 0 || shortlistDate === undefined
          ? ele
          : ele.shortlist_date >= shortlistDate[0] && ele.shortlist_date <= shortlistDate[1];
      })
      .filter((ele) => {
        return broadcastdate?.length === 0 || broadcastdate === undefined
          ? ele
          : ele.broadcast_date >= broadcastdate[0] && ele.broadcast_date <= broadcastdate[1];
      })
      .filter((ele) => {
        return agefilter?.length === 0 || agefilter === undefined
          ? ele
          : ele.age >= agefilter[0] && ele.age <= agefilter[1];
      })
  );

  useEffect(() => {
    handleConsPost1();
  }, []);

  const stage1Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage1,
        candidate_id: candData?.registration_id,
        superadmin_id: superadminId,
        // counsellor_id: candData?.counsellorid,
        counsellor_id: counsellorId || counsellorId !== "" ? counsellorId : "",
        // orgid: candData?.orgid,
        // jobid: candData?.jobid,
        orgid: newOrgId,
        jobid: newCourseId,
      })
      .then((res) => {
        showAlert();
        HandleTimeline();
        HandleFetchCandDetails(candData?.registration_id, counsellorId);
      })
      .catch((err) => {
        console.log("stage1  err", err.data);
        errorAlert();
      });
  };

  const stage2Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage2,
        candidate_id: candData?.registration_id,
        superadmin_id: superadminId,
        // counsellor_id: candData?.counsellorid,
        counsellor_id: counsellorId || counsellorId !== "" ? counsellorId : "",
        orgid: newOrgId,
        jobid: newCourseId,
      })
      .then((res) => {
        showAlert();
        HandleTimeline();
        HandleFetchCandDetails(candData?.registration_id, counsellorId);
      })
      .catch((err) => {
        errorAlert();
      });
  };
  const stage3Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage3,
        candidate_id: candData?.registration_id,
        superadmin_id: superadminId,
        // counsellor_id: candData?.counsellorid,
        counsellor_id: counsellorId || counsellorId !== "" ? counsellorId : "",
        orgid: newOrgId,
        jobid: newCourseId,
      })
      .then((res) => {
        showAlert();
        HandleTimeline();
        HandleFetchCandDetails(candData?.registration_id, counsellorId);
      })
      .catch((err) => {
        console.log("stage3  err", err.data);
        errorAlert();
      });
  };
  const stage4Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage4,
        candidate_id: candData?.registration_id,
        superadmin_id: superadminId,
        // counsellor_id: candData?.counsellorid,
        counsellor_id: counsellorId || counsellorId !== "" ? counsellorId : "",
        orgid: newOrgId,
        jobid: newCourseId,
      })
      .then((res) => {
        showAlert();
        HandleTimeline();
        HandleFetchCandDetails(candData?.registration_id, counsellorId);
      })
      .catch((err) => {
        console.log("stage4  err", err.data);
        errorAlert();
      });
  };
  const stage5Func = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm`, {
        ...stage5,
        candidate_id: candData?.registration_id,
        superadmin_id: superadminId,
        // counsellor_id: candData?.counsellorid,
        counsellor_id: counsellorId || counsellorId !== "" ? counsellorId : "",
        orgid: newOrgId,
        jobid: newCourseId,
      })
      .then((res) => {
        showAlert();
        HandleTimeline();
        HandleFetchCandDetails(candData?.registration_id, counsellorId);
      })
      .catch((err) => {
        console.log("stage5  err", err.data);
        errorAlert();
      });
  };

  const stageDataFunc = () => {
    setIsLoadingCandData(true);
    const params = {
      candidate_id: candData?.registration_id,
      counsellor_id: counsellorId || counsellorId !== "" ? counsellorId : "",
      // candData?.counsellorid ||
      // candData?.counsellorid !== "" ||
      // candData?.counsellorid !== undefined
      //   ? candData?.counsellorid
      //   : superadminId,
      orgid: searchParams.get("org_id"),
      jobid: candData?.course_id,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/operate/crm?${paramString}`
        // {
        //   ...stage5,
        //   candidate_id: candData?.registration_id,
        // }
      )
      .then((res) => {
        // showAlert();
        // handleOpenModal();
        // setStagesData(res.data["candidate_crm_details"]);
        setIsLoadingCandData(false);
        setStage1({
          // counsellor_id: superadminId,
          ...stage1,
          first_call: res.data["candidate_crm_details"]?.first_call,
          call_status: res.data["candidate_crm_details"]?.call_status,
          job_status: res.data["candidate_crm_details"]?.job_status,
          industry: res.data["candidate_crm_details"]?.industry,
          ready_resume: res.data["candidate_crm_details"]?.ready_resume,
          next_step_status: res.data["candidate_crm_details"]?.next_step_status,
          suggested_job_bucket: res.data["candidate_crm_details"]?.suggested_bucket,
          display_comment: res.data["candidate_crm_details"]?.display_comment,
          is_disabled: res.data["candidate_crm_details"]?.is_disabled,
          // call_editable: res.data["candidate_crm_details"]?.call_editable,
          // job_editable: res.data["candidate_crm_details"]?.job_editable,
        });
        setStage2({
          // counsellor_id: superadminId,
          ...stage2,
          jp_status: res.data["candidate_crm_details"]?.jp_status,
        });
        setStage3({
          // counsellor_id: superadminId,
          ...stage3,
          interview_status: res.data["candidate_crm_details"]?.interview_status,
          interview_date: res.data["candidate_crm_details"]?.interview_date,
        });
        setStage4({
          ...stage4,
          selection_status: res.data["candidate_crm_details"]?.selection_status,
        });
        setStage5({
          ...stage5,
          joining_status: res.data["candidate_crm_details"]?.joining_status,
          retention_status: res.data["candidate_crm_details"]?.retention_status,
          feedback: res.data["candidate_crm_details"]?.feedback,
        });
        setPrevState({
          ...prevState,
          first_call: res.data["candidate_crm_details"].first_call,
          call_status: res.data["candidate_crm_details"].call_status,
          job_status: res.data["candidate_crm_details"].job_status,
          industry: res.data["candidate_crm_details"].industry,
          ready_resume: res.data["candidate_crm_details"].ready_resume,
          next_step_status: res.data["candidate_crm_details"].next_step_status,
          suggested_job_bucket: res.data["candidate_crm_details"].suggested_bucket,
          display_comment: res.data["candidate_crm_details"].display_comment,
          is_disabled: res.data["candidate_crm_details"].is_disabled,
          jp_status: res.data["candidate_crm_details"].jp_status,
          interview_status: res.data["candidate_crm_details"].interview_status,
          interview_date: res.data["candidate_crm_details"].interview_date,
          selection_status: res.data["candidate_crm_details"].selection_status,
          joining_status: res.data["candidate_crm_details"].joining_status,
          retention_status: res.data["candidate_crm_details"].retention_status,
          feedback: res.data["candidate_crm_details"].feedback,
        });
      })
      .catch((err) => {
        console.log("stage5  err", err);
        setIsLoadingCandData(false);
      });
  };

  console.log("details modal loading:", isLoadingCandData);
  console.log("details timeline loading:", timelineLoading);

  useEffect(() => {
    stageDataFunc();
  }, [candData?.stage]);

  const uploadResumeFile = () => {
    let formData = new FormData();
    formData.append("candidate_id", candData.registration_id);
    formData.append("counsellor_id", con_id);
    formData.append("resume", resumeFile);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/upload/resume`,
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          // setShow(true);
          setResumeFile("");
          setUploadResume(false);
          setResumeLink(res.resume_link);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: err.response.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  console.log(data, "data");

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  const viewList = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "List to AI Bot Engaged",
      value: "List to AI Bot Engaged",
    },
    {
      label: "List to Counsellor Engaged",
      value: "List to Counsellor Engaged",
    },
    { label: "Unengaged", value: "Unengaged" },
  ];
  const round = [
    { isDone: true },
    { isDone: true },
    { isDone: false },
    { isDone: false },
    { isDone: false },
  ];

  // counsellor columns
  const columns3 = [
    {
      title: "Shortlisted Date",
      dataIndex: "shortlist_date",
      fixed: "left",
      key: "shortlist_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.shortlist_date, "DD-MM-YYYY") - moment(b.shortlist_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
    },
    {
      title: "Candidate Names",
      width: 200,
      // dataIndex: "candidate_name",
      key: "candidate_name",
      fixed: "left",
      //   sorter: true,
      // render: (text, record) => <p>{`${text.first_name}` + " " + `${text.last_name}`}</p>,
      // sorter: { compare: (a, b) => a?.candidate_name.localeCompare(b?.candidate_name) },
      sorter: { compare: (a, b) => a?.candidate_name?.localeCompare(b?.candidate_name) },
      render: (text, record) => (
        // <p>{`${text.first_name}` + " " + `${text.last_name}`}</p>
        <p>{text.candidate_name}</p>
      ),
    },

    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      fixed: "left",
      sorter: { compare: (a, b) => a?.phone - b?.phone },
    },
    {
      title: "Broadcast Date",
      //dataIndex: "broadcast_date",C
      key: "broadcast_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.broadcast_date, "DD-MM-YYYY") - moment(b.broadcast_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
      render: (text) => <>{text.broadcast_date === "" ? "-" : text.broadcast_date}</>,
    },
    {
      title: "Data Source",
      //dataIndex: "datasource",
      key: "datasource",
      width: 200,
      sorter: { compare: (a, b) => a?.datasource?.localeCompare(b?.datasource) },
      render: (text) => (
        <>{text?.datasource === null || text?.datasource === "" ? "-" : text?.datasource}</>
      ),
    },
    {
      title: "Counsellor Name",
      dataIndex: "counsellor_name",
      key: "counsellor_name",
      sorter: { compare: (a, b) => a?.counsellor_name.localeCompare(b?.counsellor_name) },
      width: 200,
    },
    {
      title: "Hiring Stage",
      // dataIndex: "stage",
      width: 250,
      key: "stage",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* {round.map((ele, i) => ( */}
          <Tooltip title="Bot Successfully Engaged" placement="bottom">
            <div
              // key={i + 1}
              className={
                text.stage === 0 && text.completion_status === "completed"
                  ? "completed-class"
                  : text.stage > 0
                    ? "completed-class"
                    : text.stage === "" && text.completion_status === "in_progress"
                      ? "in_progress-class"
                      : text.stage === 0 && text.completion_status === "in_progress"
                        ? "in_progress-class"
                        : text.stage === "" && text.completion_status === "rejected"
                          ? "rejected-class"
                          : text.stage === 0 && text.completion_status === "rejected"
                            ? "completed-class"
                            : text.stage === -1 && text.completion_status === "in_progress"
                              ? "in_progress-class"
                              : "default"
              }
            // style={{ width: "8px", height: "8px", background: "blue", borderRadius: "50%" }}
            ></div>
          </Tooltip>
          {/* ))} */}
          <Tooltip title="Counsellor Engagement On" placement="bottom">
            <div
              // key={i + 1}
              className={
                text.stage === 0 && text.completion_status === "completed"
                  ? "in_progress-class"
                  : text.stage === 1 && text.completion_status === "completed"
                    ? "completed-class"
                    : text.stage === 0 && text.completion_status === "rejected"
                      ? "rejected-class"
                      : text.stage === 1 && text.completion_status === "rejected"
                        ? "completed-class"
                        : text.stage === 1 && text.completion_status === "in_progress"
                          ? "completed-class"
                          : text.stage > 1
                            ? "completed-class"
                            : text.stage === 0 && text.completion_status === "in_progress"
                              ? "in_progress-class"
                              : text.stage === 1 && text.completion_status === "rejected"
                                ? "rejected-class"
                                : "default"
              }
            // style={{ width: "8px", height: "8px", background: "blue", borderRadius: "50%" }}
            ></div>
          </Tooltip>
          <Tooltip title="Interview Process Initiated" placement="bottom">
            <div
              // key={i + 1}
              className={
                text.stage === 1 && text.completion_status === "completed"
                  ? "in_progress-class"
                  : text.stage === 2 && text.completion_status === "completed"
                    ? "completed-class"
                    : text.stage === 1 && text.completion_status === "rejected"
                      ? "rejected-class"
                      : text.stage === 2 && text.completion_status === "rejected"
                        ? "completed-class"
                        : text.stage === 2 && text.completion_status === "in_progress"
                          ? "completed-class"
                          : text.stage > 2
                            ? "completed-class"
                            : text.stage === 1 && text.completion_status === "in_progress"
                              ? "in_progress-class"
                              : text.stage === 2 && text.completion_status === "rejected"
                                ? "rejected-class"
                                : "default"
              }
            // style={{ width: "8px", height: "8px", background: "blue", borderRadius: "50%" }}
            ></div>
          </Tooltip>
          <Tooltip title="Interview Process On" placement="bottom">
            <div
              // key={i + 1}
              className={
                text.stage === 2 && text.completion_status === "completed"
                  ? "in_progress-class"
                  : text.stage === 3 && text.completion_status === "completed"
                    ? "completed-class"
                    : text.stage === 2 && text.completion_status === "rejected"
                      ? "rejected-class"
                      : text.stage === 3 && text.completion_status === "rejected"
                        ? "completed-class"
                        : text.stage === 3 && text.completion_status === "in_progress"
                          ? "completed-class"
                          : text.stage > 3
                            ? "completed-class"
                            : text.stage === 2 && text.completion_status === "in_progress"
                              ? "in_progress-class"
                              : text.stage === 3 && text.completion_status === "rejected"
                                ? "rejected-class"
                                : "default"
              }
            // style={{ width: "8px", height: "8px", background: "blue", borderRadius: "50%" }}
            ></div>
          </Tooltip>
          <Tooltip title="Final Selection Stage" placement="bottom">
            <div
              // key={i + 1}
              className={
                text.stage === 3 && text.completion_status === "completed"
                  ? "in_progress-class"
                  : text.stage === 4 && text.completion_status === "completed"
                    ? "completed-class"
                    : text.stage === 3 && text.completion_status === "rejected"
                      ? "rejected-class"
                      : text.stage === 4 && text.completion_status === "rejected"
                        ? "completed-class"
                        : text.stage === 4 && text.completion_status === "in_progress"
                          ? "completed-class"
                          : text.stage > 4
                            ? "completed-class"
                            : text.stage === 3 && text.completion_status === "in_progress"
                              ? "in_progress-class"
                              : text.stage === 4 && text.completion_status === "rejected"
                                ? "rejected-class"
                                : "default"
              }
            // style={{ width: "8px", height: "8px", background: "blue", borderRadius: "50%" }}
            ></div>
          </Tooltip>
          <Tooltip title="Post Selection Stage" placement="bottom">
            <div
              // key={i + 1}
              className={
                text.stage === 4 && text.completion_status === "completed"
                  ? "in_progress-class"
                  : text.stage === 5 && text.completion_status === "completed"
                    ? "completed-class"
                    : text.stage === 4 && text.completion_status === "rejected"
                      ? "rejected-class"
                      : text.stage === 5 && text.completion_status === "rejected"
                        ? "completed-class"
                        : text.stage === 5 && text.completion_status === "in_progress"
                          ? "completed-class"
                          : text.stage > 5
                            ? "completed-class"
                            : text.stage === 4 && text.completion_status === "in_progress"
                              ? "in_progress-class"
                              : text.stage === 5 && text.completion_status === "rejected"
                                ? "rejected-class"
                                : "default"
              }
            // style={{ width: "8px", height: "8px", background: "blue", borderRadius: "50%" }}
            ></div>
          </Tooltip>
        </div>
      ),
      // render: (text, record) => (
      //   <div style={{ display: "flex", justifyContent: "space-between" }}>
      //     {round.map(
      //       (ele, i) => (
      //         console.log("application stage", ele),
      //         (
      //           <div
      //             key={i + 1}
      //             className={
      //               shortData.stage === 0 && app_status === "completed"
      //                 ? "completed-class"
      //                 : shortData.stage === 0 && app_status === "inprocess"
      //                 ? "inprocess-class"
      //                 : shortData.stage === 0 && app_status === "rejected"
      //                 ? "rejected-class"
      //                 : "default"
      //             }
      //           ></div>
      //         )
      //       )
      //     )}
      //   </div>
      // ),
    },
    {
      title: "Hiring Status",
      // dataIndex: "status",
      key: "candidate_status",
      width: 300,
      sorter: { compare: (a, b) => a?.candidate_status.localeCompare(b?.candidate_status) },
      render: (text) => (
        <span
          style={{
            backgroundColor:
              text.completion_status === "completed"
                ? "#BDFDC8"
                : text.completion_status === "rejected"
                  ? "#EFD6FF"
                  : "#FFE1C3",
            color:
              text.completion_status === "completed"
                ? "#049C2F"
                : text.completion_status === "rejected"
                  ? "#62049C"
                  : "#EA7500",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "50px",
          }}
        >
          {text.candidate_status}
        </span>
      ),
    },
    // { title: "Location", dataIndex: "taluka", key: "2" },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 200,
      sorter: { compare: (a, b) => a?.state.localeCompare(b?.state) },
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 200,
      sorter: { compare: (a, b) => a?.district.localeCompare(b?.district) },
    },
    {
      title: "Taluka",
      dataIndex: "taluka",
      key: "taluka",
      width: 200,
      sorter: { compare: (a, b) => a?.taluka?.localeCompare(b?.taluka) },
    },
    {
      title: "Skills",
      // dataIndex: "skills",
      width: 150,
      key: "skills",
      sorter: {
        compare: (a, b) =>
          a.skills !== null
            ? a?.skills?.localeCompare(b?.skills)
            : a?.job_role?.localeCompare(b?.job_role),
      },
      render: (text) => (
        <span>{text.skills !== null ? text.skills : text.job_role}</span>
        // <span>{text.job_role}</span>
      ),
    },
    {
      title: "Self Opportunity",
      //dataIndex: "skills",
      key: "self_opportunity",
      width: 200,
      sorter: { compare: (a, b) => a?.self_opportunity?.localeCompare(b?.self_opportunity) },
      render: (text) => <span>{text.self_opportunity === "" ? "-" : text.self_opportunity}</span>,
      isExportable: true,
    },
    {
      title: "Available Skills",
      //dataIndex: "skills",
      key: "avl_skill",
      width: 200,
      sorter: { compare: (a, b) => a?.avl_skill?.localeCompare(b?.avl_skill) },
      render: (text) => <span>{text.avl_skill === "" ? "-" : text.avl_skill}</span>,
      isExportable: true,
    },
    {
      title: "Languages Known",
      //dataIndex: "skills",
      key: "languages",
      width: 200,
      sorter: { compare: (a, b) => a?.languages?.localeCompare(b?.languages) },
      render: (text) => <span>{text.languages === "" ? "-" : text.languages}</span>,
      isExportable: true,
    },
    {
      title: "Candidate Profiling Score",
      // dataIndex: "ratings",
      sorter: { compare: (a, b) => a.ratings - b.ratings },
      key: "ratings",
      width: 270,
      render: (text, record) => (
        // <SoftBox>
        //   <SoftBadge
        //     variant="contained"
        //     color={text >= 3.5 ? "success" : text < 3.5 && text >= 2 ? "warning" : "error"}
        //     size="xs"
        //     badgeContent={
        //       text >= 3.5
        //         ? "Excellent Match"
        //         : text < 3.5 && text >= 2
        //         ? "Medium Match"
        //         : "Poor Match"
        //     }
        //     container
        //   />
        // </SoftBox>
        <>
          {/* {console.log("ratings text:", text)}, */}
          {/* <span>{text.ratings}</span> */}
          <div
            className="dropdown"
            style={{
              width: "100%",
              height: "30px",
              // border: "1px solid ",
              borderRadius: "15px",
              backgroundColor:
                text.ratings >= 4
                  ? "#B7F4BB"
                  : text.ratings < 4 && text.ratings >= 2.5
                    ? "#FFD2A5"
                    : text.ratings === 0
                      ? "#abe9f7"
                      : "#FFD3D3",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                // padding: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                padding: "5px 0px 5px",
                color:
                  text.ratings >= 4
                    ? "#049C2F"
                    : text.ratings < 4 && text.ratings >= 2.5
                      ? "#EA7500"
                      : text.ratings === 0
                        ? "#08a1c4"
                        : "#FF0000",
              }}
            // className="dropdown-toggle"
            // data-bs-toggle="dropdown"
            >
              {/* {text === null ? "Placed" : "Not Placed"} */}
              {/* {text.ratings} */}
              {text.ratings !== 0 ? text.ratings : "Yet to be rated"}
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Counsellor Comments",
      // dataIndex: "comments",
      width: 200,
      key: "display_comment",
      render: (text) => (
        <span>{text.display_comment !== null ? text.display_comment : " - "}</span>
        // <span>{text.job_role}</span>
      ),
      sorter: { compare: (a, b) => a?.display_comment?.localeCompare(b?.display_comment) },
    },
    {
      title: "Candidate Feedback",
      // dataIndex: "feedback",
      width: 200,
      key: "feedback",
      render: (text) => (
        <span>{text.feedback !== null ? text.feedback : " - "}</span>
        // <span>{text.job_role}</span>
      ),
      sorter: { compare: (a, b) => a?.feedback?.localeCompare(b?.feedback) },
    },
  ];

  // WA columns
  const columns = [
    {
      title: "Shortlisted Date",
      dataIndex: "shortlist_date",
      fixed: "left",
      key: "shortlist_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.shortlist_date, "DD-MM-YYYY") - moment(b.shortlist_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
    },
    {
      title: "Candidate Names",
      width: 200,
      dataIndex: "candidate_name",
      key: "candidate_name",
      fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a?.candidate_name?.localeCompare(b?.candidate_name) },
      render: (text, record) => (
        // <p>{`${text.first_name}` + " " + `${text.last_name}`}</p>
        <p>{text}</p>
      ),
    },
    {
      title: "Phone Number",
      width: 150,
      dataIndex: "phone",
      key: "phone",
      sorter: { compare: (a, b) => a.phone - b.phone },
      fixed: "left",
      //   sorter: true,
    },
    {
      title: "Broadcast Date",
      //dataIndex: "broadcast_date",D
      key: "broadcast_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.broadcast_date, "DD-MM-YYYY") - moment(b.broadcast_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
      render: (text) => <>{text.broadcast_date === "" ? "-" : text.broadcast_date}</>,
    },
    {
      title: "Data Source",
      //dataIndex: "datasource",
      key: "datasource",
      width: 200,
      sorter: { compare: (a, b) => a?.datasource?.localeCompare(b?.datasource) },
      render: (text) => (
        <>{text?.datasource === null || text?.datasource === "" ? "-" : text?.datasource}</>
      ),
    },
    {
      title: "Industry",
      //dataIndex: "industry",
      key: "industry",
      width: 200,
      sorter: { compare: (a, b) => a?.industry?.localeCompare(b?.industry) },
      render: (text) => (
        <span>
          {text.industry === null || text.industry === "nan" || text.industry === ""
            ? "-"
            : text.industry}
        </span>
      ),
    },
    {
      title: "Roles",
      //dataIndex: "job_role",
      key: "2",
      width: 200,
      sorter: { compare: (a, b) => a?.job_role?.localeCompare(b?.job_role) },
      render: (text) => (
        <span>
          {text.job_role === null || text.job_role === "nan" || text.job_role === ""
            ? "-"
            : text.job_role}
        </span>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "10",
      width: 200,
      sorter: { compare: (a, b) => a?.job_title?.localeCompare(b?.job_title) },
    },

    // { title: "Qualification", dataIndex: "qualification", key: "1" },
    // { title: 'Modified On', dataIndex: 'modified_on', key: '2' },

    {
      title: "Relevancy Score",
      // dataIndex: "score",
      sorter: { compare: (a, b) => a.score - b.score },
      key: "6",
      width: 200,
      render: (text) => (
        <span
          style={{
            backgroundColor:
              text.score === 1
                ? "#B7F4BB"
                : text.score === 2
                  ? "#FFF2CC"
                  : // ? "#EFB7F4"
                  "#FFD3D3",
            color:
              text.score === 1
                ? "#049C2F"
                : text.score === 2
                  ? "#FBC400"
                  : // ? "#62049C"
                  "#FF0000",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "10px",
          }}
        >
          {text.score === 1 ? "Excellent Match" : text.score === 2 ? "Average Match" : "Less Match"}
        </span>
      ),
      // render: (text, record) => (
      //   <span>
      //     <SoftBadge
      //       variant="contained"
      //       color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
      //       size="xs"
      //       badgeContent={
      //         text >= 80
      //           ? "Excellent Match"
      //           : text < 80 && text >= 45
      //           ? "Medium Match"
      //           : "Poor Match"
      //       }
      //       container
      //     />
      //   </span>
      // ),
    },
    {
      title: "Last Active",
      dataIndex: "last_activedate",
      key: "5",
      sorter: {
        compare: (a, b) =>
          moment(a.last_activedate, "DD-MM-YYYY") - moment(b.last_activedate, "DD-MM-YYYY"),
      },
    },
    {
      title: "Candidate Profiling Score",
      // dataIndex: "rating",
      sorter: { compare: (a, b) => a.ratings - b.ratings },
      key: "ratings",
      width: 270,
      render: (text, record) => (
        // <SoftBox>
        //   <SoftBadge
        //     variant="contained"
        //     color={text >= 3.5 ? "success" : text < 3.5 && text >= 2 ? "warning" : "error"}
        //     size="xs"
        //     badgeContent={
        //       text >= 3.5
        //         ? "Excellent Match"
        //         : text < 3.5 && text >= 2
        //         ? "Medium Match"
        //         : "Poor Match"
        //     }
        //     container
        //   />
        // </SoftBox>
        <>
          {/* {console.log("ratings text:", text)}, */}
          {/* <span>{text.ratings}</span> */}
          <div
            className="dropdown"
            style={{
              width: "100%",
              height: "30px",
              // border: "1px solid ",
              borderRadius: "15px",
              backgroundColor:
                text.ratings >= 4
                  ? "#B7F4BB"
                  : text.ratings < 4 && text.ratings >= 2.5
                    ? "#FFD2A5"
                    : text.ratings === 0
                      ? "#abe9f7"
                      : "#FFD3D3",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                // padding: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                padding: "5px 0px 5px",
                color:
                  text.ratings >= 4
                    ? "#049C2F"
                    : text.ratings < 4 && text.ratings >= 2.5
                      ? "#EA7500"
                      : text.ratings === 0
                        ? "#08a1c4"
                        : "#FF0000",
              }}
            // className="dropdown-toggle"
            // data-bs-toggle="dropdown"
            >
              {/* {text === null ? "Placed" : "Not Placed"} */}
              {/* {text.ratings} */}
              {text.ratings !== 0 ? text.ratings : "Yet to be rated"}
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Response",
      // dataIndex: "status",
      sorter: { compare: (a, b) => a?.response?.localeCompare(b?.response) },
      key: "7",
      render: (text) => (
        console.log("ai bot:", text),
        (
          <span
            style={{
              backgroundColor:
                text.response === "interested"
                  ? "#BDFDC8"
                  : text.response === "not interested"
                    ? "#EFD6FF"
                    : "#FFE1C3",
              color:
                text.response === "interested"
                  ? "#049C2F"
                  : text.response === "not interested"
                    ? "#62049C"
                    : "#EA7500",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              // paddingTop: "3px",
              // paddingBottom: "3px",
              // paddingLeft: "15px",
              // paddingRight: "15px",
              borderRadius: "10px",
              width: "106px",
            }}
          >
            {text.response === "interested"
              ? "Interested"
              : text.response === "not interested"
                ? "Not Interested"
                : text.response === "" && text.wa_status === ""
                  ? "No Response"
                  : text.wa_status === "read"
                    ? "Read"
                    : text.wa_status === "delivered"
                      ? "Delivered"
                      : text.wa_status}
          </span>
        )
      ),
      // render: (text, record) => (
      //   <span>
      //     <SoftBadge
      //       variant="contained"
      //       color={text.response === "interested" ? "success" : "error"}
      //       size="xs"
      //       badgeContent={
      //         text.response === "interested"
      //           ? "Interested"
      //           : text.response === "not interested"
      //           ? "Not Interested"
      //           : "No Response"
      //       }
      //       container
      //     />
      //   </span>
      // ),
    },
    {
      title: "Taluka",
      //dataIndex: "taluka",
      key: "4",
      sorter: { compare: (a, b) => a?.taluka?.localeCompare(b?.taluka) },
      render: (text) => (
        <span>
          {text.taluka === null || text.taluka === "nan" || text.taluka === "" ? "-" : text.taluka}
        </span>
      ),
    },
    // { title: "Column 7", dataIndex: "address", key: "7" },
    // { title: "Column 8", dataIndex: "address", key: "8" },
    {
      title: "Resume",
      width: 150,
      key: "resume",
      render: (text) => (
        <span>
          <SoftBadge
            variant="contained"
            color={text.resume ? "success" : "info"}
            size="lg"
            badgeContent={
              // text >= 80
              //   ? "Excellent Match"
              //   : text < 80 && text >= 45
              //   ? "Medium Match"
              //   : "Poor Match"
              text.resume ? "View" : "Upload"
            }
            container
          />
        </span>
      ),
    },
    {
      title: "Action",
      key: "operation",
      // fixed: "right",
      width: 80,
      // render: () => <MdMoreHoriz />,
      render: (text) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "1") {
                  setApplyModal(true);
                  setRowData(text);
                } else {
                  setactionModal1(true);
                  setactionModal(false);
                  handleConsPost();
                  handleClose();
                }
              },
            }}
          >
            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
  // WA columns

  const [colHeaders, setColHeaders] = useState([
    {
      label: "Shortlisted Date",
      key: "shortlist_date",
      isExportable: true,
    },
    {
      label: "Candidate Name",
      key: "candidate_name",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "phone",
      isExportable: true,
    },
    {
      label: "Broadcast Date",
      key: "broadcast_date",
      isExportable: true,
    },
    {
      label: "Data Source",
      key: "datasource",
      isExportable: true,
    },
    // {
    //   label: "Email",
    //   key: "email",
    //   isExportable: true,
    // },
    {
      label: "Industry",
      key: "industry",
      isExportable: true,
    },
    {
      label: "Roles",
      key: "job_role",
      isExportable: true,
    },
    {
      label: "Education",
      key: "education",
      isExportable: true,
    },
    {
      label: "Modified On",
      key: "modified_on",
      isExportable: true,
    },
    {
      label: "Skills",
      key: "skills",
      isExportable: true,
    },
    {
      label: "Self Opportunity",
      key: "self_opportunity",
      isExportable: true,
    },
    {
      label: "Available Skills",
      key: "avl_skills",
      isExportable: true,
    },
    {
      label: "Languages Known",
      key: "languages",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Taluka",
      key: "taluka",
      isExportable: true,
    },
    {
      label: "Data Source",
      key: "datasource",
      isExportable: true,
    },
    {
      label: "Last Active",
      key: "last_active",
      isExportable: true,
    },
    {
      label: "Relevancy Score",
      key: "relevancy_match",
      isExportable: true,
    },
    {
      label: "Experience",
      key: "exp",
      isExportable: true,
    },
    {
      label: "Salary Requirement",
      key: "salary_req",
      isExportable: true,
    },
    {
      label: "Gender",
      key: "gender",
      isExportable: true,
    },
    {
      label: "Age",
      key: "age",
      isExportable: true,
    },
    {
      label: "Approach Status",
      key: "approach_status",
      isExportable: true,
    },
    {
      label: "Interest Level",
      key: "interest_level",
      isExportable: true,
    },
    {
      label: "Hiring Status",
      key: "candidate_status",
      isExportable: true,
    },
    {
      label: "Candidate Profiling Score",
      key: "ratings",
      isExportable: true,
    },
    {
      label: "Counsellor Assigned",
      key: "counsellor_name",
      isExportable: true,
    },
    {
      label: "Assigned Dates",
      key: "assign_date",
      isExportable: true,
    },
    {
      label: "Counsellor Comments",
      key: "display_comment",
      isExportable: true,
    },
    {
      label: "Candidate Feedback",
      key: "feedback",
      isExportable: true,
    },
    {
      label: "Resume",
      key: "resume",
      isExportable: true,
    },
  ]);

  const [colHeaders1, setColHeaders1] = useState([
    {
      label: "Shortlisted Date",
      key: "shortlist_date",
      isExportable: true,
    },
    {
      label: "Candidate Name",
      key: "candidate_name",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "phone",
      isExportable: true,
    },
    {
      label: "Broadcast Date",
      key: "broadcast_date",
      isExportable: true,
    },
    {
      label: "Data Source",
      key: "datasource",
      isExportable: true,
    },
    {
      label: "Industry",
      key: "industry",
      isExportable: true,
    },
    {
      label: "Roles",
      key: "job_role",
      isExportable: true,
    },
    {
      label: "Education",
      key: "education",
      isExportable: true,
    },
    {
      label: "Modified On",
      key: "modified_on",
      isExportable: true,
    },
    {
      label: "Skills",
      key: "skills",
      isExportable: true,
    },
    {
      label: "Self Opportunity",
      key: "self_opportunity",
      isExportable: true,
    },
    {
      label: "Available Skills",
      key: "avl_skills",
      isExportable: true,
    },
    {
      label: "Languages Known",
      key: "languages",
      isExportable: true,
    },
    {
      label: "Experience (yrs)",
      key: "exp",
      isExportable: true,
    },
    {
      label: "Salary Requirement",
      key: "salary_req",
      isExportable: true,
    },
    {
      label: "Gender",
      key: "gender",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Taluka",
      key: "taluka",
      isExportable: true,
    },
    {
      label: "Data Source",
      key: "datasource",
      isExportable: true,
    },
    {
      label: "Last Active",
      key: "last_active",
      isExportable: true,
    },
    {
      label: "Relevancy Score",
      key: "relevancy_match",
      isExportable: true,
    },
    {
      label: "Approach Status",
      key: "approach_status",
      isExportable: true,
    },
    {
      label: "Interest Level",
      key: "interest_level",
      isExportable: true,
    },
    {
      label: "Hiring Status",
      key: "candidate_status",
      isExportable: true,
    },
    {
      label: "Candidate Profiling Score",
      key: "ratings",
      isExportable: true,
    },
    {
      label: "Counsellor Assigned",
      key: "counsellor_name",
      isExportable: true,
    },
    {
      label: "Assigned Dates",
      key: "assign_date",
      isExportable: true,
    },
    {
      label: "Resume",
      key: "resume",
      isExportable: true,
    },
  ]);

  const [colHeaders2, setColHeaders2] = useState([
    {
      label: "Shortlisted Date",
      key: "shortlist_date",
      isExportable: true,
    },
    {
      label: "Candidate Name",
      key: "first_name",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "mobile",
      isExportable: true,
    },
    {
      label: "Broadcast Date",
      key: "broadcast_date",
      isExportable: true,
    },
    {
      label: "Data Source",
      key: "datasource",
      isExportable: true,
    },
    {
      label: "Counsellor Name",
      key: "counsellor_name",
      isExportable: true,
    },
    {
      label: "Hiring Stage",
      key: "stage",
      isExportable: true,
    },
    {
      label: "Hiring Status",
      key: "candidate_status",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Taluka",
      key: "taluka",
      isExportable: true,
    },
    {
      label: "Skills",
      key: "skills",
      isExportable: true,
    },
    {
      label: "Self Opportunity",
      key: "self_opportunity",
      isExportable: true,
    },
    {
      label: "Available Skills",
      key: "avl_skills",
      isExportable: true,
    },
    {
      label: "Languages Known",
      key: "languages",
      isExportable: true,
    },
    {
      label: "Candidate Profiling Score",
      key: "ratings",
      isExportable: true,
    },
    {
      label: "Counsellor Comments",
      key: "comments",
      isExportable: true,
    },
    {
      label: "Candidate Feedback",
      key: "feedback",
      isExportable: true,
    },
  ]);

  const [colHeaders3, setColHeaders3] = useState([
    {
      label: "Shortlisted Date",
      key: "shortlist_date",
      isExportable: true,
    },
    {
      label: "Candidate Name",
      key: "first_name",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "mobile",
      isExportable: true,
    },
    {
      label: "Broadcast Date",
      key: "broadcast_date",
      isExportable: true,
    },
    {
      label: "Data Source",
      key: "datasource",
      isExportable: true,
    },
    {
      label: "Industry",
      key: "industry",
      isExportable: true,
    },
    {
      label: "Roles",
      key: "job_role",
      isExportable: true,
    },
    {
      label: "Job Title",
      key: "job_title",
      isExportable: true,
    },
    {
      label: "Relevancy Score",
      key: "relevancy_match",
      isExportable: true,
    },
    {
      label: "Last Active",
      key: "last_activedate",
      isExportable: true,
    },
    {
      label: "Candidate Profiling Score",
      key: "ratings",
      isExportable: true,
    },
    {
      label: "Response",
      key: "response",
      isExportable: true,
    },
    {
      label: "Taluka",
      key: "taluka",
      isExportable: true,
    },
    {
      label: "Resume",
      key: "resume",
      isExportable: true,
    },
  ]);

  const columns111 = [
    {
      title: "Candidate Names",
      width: 200,
      dataIndex: "candidate_name",
      key: "candidate_name",
      fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a?.candidate_name?.localeCompare(b?.candidate_name) },
      render: (text) => {
        return (
          <span
            onClick={() => {
              setCanId(text.key);
              HandleFetchCandDetails(canId);
              setOpenModal(true);
            }}
          >
            {text}
          </span>
        );
      },
    },
  ];

  // All columns
  const columns2 = [
    {
      title: "Shortlisted Date",
      dataIndex: "shortlist_date",
      fixed: "left",
      key: "shortlist_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.shortlist_date, "DD-MM-YYYY") - moment(b.shortlist_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
    },
    {
      title: "Candidate Name",
      width: 200,
      //dataIndex: "candidate_name",
      key: "candidate_name",
      fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a?.candidate_name?.localeCompare(b?.candidate_name) },
      render: (text, record) => {
        return (
          <p
            onClick={() => {
              setCanId(record.key);
              setCounsellorId(record.counsellor_id);
              setJobname(record?.jobid);
              HandleFetchCandDetails(text.key, text.orgid, text.course_id);
              console.log("text", record);
              setOpenModal(true);
            }}
            style={{
              cursor: "pointer",
              marginBottom: "-2px",
              color: "#3B5092",
              textDecorationLine: "underline",
            }}
          >
            {text.candidate_name}
          </p>
        );
      },
    },
    {
      title: "Phone Number",
      width: 150,
      dataIndex: "phone",
      key: "phone",
      fixed: "left",
      sorter: { compare: (a, b) => a?.phone - b?.phone },
    },
    {
      title: "Broadcast Date",
      //dataIndex: "broadcast_date",A
      key: "broadcast_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.broadcast_date, "DD-MM-YYYY") - moment(b.broadcast_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
      /*
      sorter: {
        compare: (a, b) => {
          a = a.broadcast_date.toLowerCase();
          b = b.broadcast_date.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      */
      render: (text) => <>{text.broadcast_date === "" ? "-" : text.broadcast_date}</>,
    },
    {
      title: "Data Source",
      //dataIndex: "datasource",
      key: "datasource",
      width: 200,
      sorter: { compare: (a, b) => a?.datasource?.localeCompare(b?.datasource) },
      render: (text) => (
        <>{text?.datasource === null || text?.datasource === "" ? "-" : text?.datasource}</>
      ),
    },
    {
      title: "Industry",
      //dataIndex: "industry",
      key: "industry",
      width: 200,
      sorter: { compare: (a, b) => a?.industry?.localeCompare(b?.industry) },
      render: (text) => <span>{text.industry === "" ? "-" : text.industry}</span>,
      isExportable: true,
    },
    {
      title: "Roles",
      //dataIndex: "job_role",
      key: "job_role",
      width: 150,
      sorter: { compare: (a, b) => a?.job_role?.localeCompare(b?.job_role) },
      render: (text) => <span>{text.job_role === "" ? "-" : text.job_role}</span>,
      isExportable: true,
    },

    {
      title: "Education",
      //dataIndex: "education",
      key: "education",
      width: 150,
      sorter: { compare: (a, b) => a?.education?.localeCompare(b?.education) },
      render: (text) => <span>{text.education === "" ? "-" : text.education}</span>,
      isExportable: true,
    },
    {
      title: "Modified On",
      dataIndex: "modified_on",
      key: "modified_on",
      width: 150,
      sorter: {
        compare: (a, b) =>
          moment(a.modified_on, "DD-MM-YYYY") - moment(b.modified_on, "DD-MM-YYYY"),
      },
      isExportable: true,
    },
    {
      title: "Skills",
      //dataIndex: "skills",
      key: "skills",
      width: 150,
      sorter: { compare: (a, b) => a?.skills?.localeCompare(b?.skills) },
      render: (text) => <span>{text.skills === "" ? "-" : text.skills}</span>,
      isExportable: true,
    },
    {
      title: "Self Opportunity",
      //dataIndex: "skills",
      key: "self_opportunity",
      width: 200,
      sorter: { compare: (a, b) => a?.self_opportunity?.localeCompare(b?.self_opportunity) },
      render: (text) => <span>{text.self_opportunity === "" ? "-" : text.self_opportunity}</span>,
      isExportable: true,
    },
    {
      title: "Available Skills",
      //dataIndex: "skills",
      key: "avl_skill",
      width: 200,
      sorter: { compare: (a, b) => a?.avl_skill?.localeCompare(b?.avl_skill) },
      render: (text) => <span>{text.avl_skill === "" ? "-" : text.avl_skill}</span>,
      isExportable: true,
    },
    {
      title: "Languages Known",
      //dataIndex: "skills",
      key: "languages",
      width: 200,
      sorter: { compare: (a, b) => a?.languages?.localeCompare(b?.languages) },
      render: (text) => <span>{text.languages === "" ? "-" : text.languages}</span>,
      isExportable: true,
    },
    {
      title: "Experience (yrs)",
      dataIndex: "exp",
      key: "exp",
      width: 200,
      sorter: { compare: (a, b) => a?.exp?.localeCompare(b?.exp) },
      isExportable: true,
    },
    {
      title: "Current Salary",
      dataIndex: "salary_req",
      key: "salary_req",
      width: 180,
      sorter: { compare: (a, b) => a?.salary_req?.localeCompare(b?.salary_req) },
      isExportable: true,
    },
    {
      title: "Gender",
      //dataIndex: "gender",
      width: 180,
      key: "gender",
      sorter: { compare: (a, b) => a?.gender?.localeCompare(b?.gender) },
      render: (text) => (
        <span>{text?.gender ? text.gender[0].toUpperCase() + text.gender.slice(1) : ""}</span>
      ),
      isExportable: true,
    },
    {
      title: "Age",
      //dataIndex: "age",
      width: 180,
      key: "age",
      sorter: { compare: (a, b) => a?.age - b?.age },
      render: (text) => <span>{text.age === "" ? "-" : text.age}</span>,
      isExportable: true,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 150,
      sorter: { compare: (a, b) => a?.state?.localeCompare(b?.state) },
      isExportable: true,
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 150,
      sorter: { compare: (a, b) => a?.district?.localeCompare(b?.district) },
      isExportable: true,
    },
    {
      title: "Taluka",
      //dataIndex: "taluka",
      key: "taluka",
      width: 150,
      sorter: { compare: (a, b) => a?.taluka?.localeCompare(b?.taluka) },
      render: (text) => <span>{text.taluka === "" ? "-" : text.taluka}</span>,
      isExportable: true,
    },
    {
      title: "Last Active",
      dataIndex: "last_active",
      key: "last_active",
      width: 150,
      sorter: {
        compare: (a, b) =>
          moment(a.last_active, "DD-MM-YYYY") - moment(b.last_active, "DD-MM-YYYY"),
      },
      isExportable: true,
    },
    {
      title: "Relevancy Score",
      sorter: { compare: (a, b) => a.score - b.score },
      key: "score",
      width: 180,
      isExportable: true,
      render: (text) => (
        // <span
        //   style={{
        //     backgroundColor:
        //       text.score > 80
        //         ? "#B7F4BB"
        //         : text.score < 80 && text.score >= 45
        //         ? "#EFB7F4"
        //         : "#FFD3D3",
        //     color:
        //       text.score > 80
        //         ? "#049C2F"
        //         : text.score < 80 && text.score >= 45
        //         ? "#62049C"
        //         : "#FF0000",
        //     paddingTop: "3px",
        //     paddingBottom: "3px",
        //     paddingLeft: "15px",
        //     paddingRight: "15px",
        //     borderRadius: "10px",
        //   }}
        // >
        //   {text.score >= 80
        //     ? "Excellent Match"
        //     : text.score < 80 && text >= 45
        //     ? "Average Match"
        //     : "Less Match"}
        // </span>
        <span
          style={{
            backgroundColor:
              text.score === 1
                ? "#B7F4BB"
                : text.score === 2
                  ? "#FFF2CC"
                  : // ? "#EFB7F4"
                  "#FFD3D3",
            color:
              text.score === 1
                ? "#049C2F"
                : text.score === 2
                  ? "#FBC400"
                  : // ? "#62049C"
                  "#FF0000",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "10px",
          }}
        >
          {text.score === 1 ? "Excellent Match" : text.score === 2 ? "Average Match" : "Less Match"}
        </span>
      ),
    },
    {
      title: "Approach Status",
      // dataIndex: "sent_status",
      sorter: { compare: (a, b) => a?.sent_status?.localeCompare(b?.sent_status) },
      key: "sent_status",
      width: 200,
      render: (text, record) => (
        <span>
          <SoftBadge
            variant="contained"
            color={
              text.sent_status === "success"
                ? "success"
                : text.sent_status === "fail"
                  ? "error"
                  : "info"
            }
            size="lg"
            badgeContent={
              // text >= 80
              //   ? "Excellent Match"
              //   : text < 80 && text >= 45
              //   ? "Medium Match"
              //   : "Poor Match"
              // text.sent_status === "success"
              //   ? "Broadcast Sent"
              //   : text.sent_status === "fail"
              //   ? "Broadcast Failed"
              //   : "Broadcast Not Sent"

              text.sent_status === "success"
                ? "Approached"
                : text.sent_status === "fail"
                  ? "Failed"
                  : "Not Yet Approached"

              // text.approach_status
            }
            container
          />
        </span>
      ),
    },
    {
      title: "Interest Level",
      // dataIndex: "status",
      sorter: { compare: (a, b) => a?.interest_level.localeCompare(b?.interest_level) },
      key: "interest_level",
      width: 200,
      render: (text) => (
        <div
          style={{
            backgroundColor:
              text.broadcast_status === "0"
                ? "#E9E9E9"
                : text.broadcast_status === "1"
                  ? "#EFB7F4"
                  : text.broadcast_status === "2"
                    ? "#C0CFFF"
                    : text.broadcast_status === "3"
                      ? "#FFD3D3"
                      : "#E9E9E9",
            color:
              text.broadcast_status === "0"
                ? "#767676"
                : text.broadcast_status === "1"
                  ? "#62049C"
                  : text.broadcast_status === "2"
                    ? "#3B5092"
                    : text.broadcast_status === "3"
                      ? "#FF0000"
                      : "#767676",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            paddingRight: "10px",
            borderRadius: "10px",
            gap: "5px",
            // width: "106px",
          }}
        >
          <p style={{ marginBottom: "0px" }}>
            {text.broadcast_status === "0" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.22451 0.285366C7.6715 -0.0951218 8.3285 -0.0951222 8.77549 0.285366L9.39455 0.812325C9.66835 1.04539 10.0325 1.14296 10.3861 1.07802L11.1857 0.931186C11.7631 0.825167 12.3321 1.15367 12.5289 1.70668L12.8016 2.47257C12.9221 2.81131 13.1887 3.07786 13.5274 3.19845L14.2933 3.47109C14.8463 3.66795 15.1748 4.23693 15.0688 4.81428L14.922 5.61388C14.857 5.96753 14.9546 6.33165 15.1877 6.60545L15.7146 7.22451C16.0951 7.6715 16.0951 8.3285 15.7146 8.77549L15.1877 9.39455C14.9546 9.66835 14.857 10.0325 14.922 10.3861L15.0688 11.1857C15.1748 11.7631 14.8463 12.3321 14.2933 12.5289L13.5274 12.8016C13.1887 12.9221 12.9221 13.1887 12.8016 13.5274L12.5289 14.2933C12.3321 14.8463 11.7631 15.1748 11.1857 15.0688L10.3861 14.922C10.0325 14.857 9.66835 14.9546 9.39455 15.1877L8.77549 15.7146C8.3285 16.0951 7.6715 16.0951 7.22451 15.7146L6.60545 15.1877C6.33165 14.9546 5.96753 14.857 5.61388 14.922L4.81428 15.0688C4.23693 15.1748 3.66795 14.8463 3.47109 14.2933L3.19845 13.5274C3.07786 13.1887 2.81131 12.9221 2.47257 12.8016L1.70668 12.5289C1.15367 12.3321 0.825167 11.7631 0.931185 11.1857L1.07802 10.3861C1.14296 10.0325 1.0454 9.66835 0.812325 9.39455L0.285366 8.77549C-0.0951218 8.3285 -0.0951222 7.6715 0.285366 7.22451L0.812325 6.60545C1.04539 6.33165 1.14296 5.96753 1.07802 5.61388L0.931186 4.81428C0.825167 4.23693 1.15367 3.66795 1.70668 3.47109L2.47257 3.19845C2.81131 3.07786 3.07786 2.81131 3.19845 2.47257L3.47109 1.70668C3.66795 1.15367 4.23693 0.825167 4.81428 0.931185L5.61388 1.07802C5.96753 1.14296 6.33165 1.0454 6.60545 0.812325L7.22451 0.285366Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M7.70864 10.0036V7.62992H8.5982C9.20843 7.62992 9.65965 7.46827 9.95187 7.14498C10.2527 6.82169 10.4031 6.42608 10.4031 5.95816C10.4031 5.60934 10.33 5.31157 10.1839 5.06485C10.0378 4.80962 9.83155 4.61819 9.56511 4.49058C9.30727 4.35446 9.01075 4.2864 8.67555 4.2864C8.28878 4.2864 7.95359 4.36722 7.66996 4.52886C7.39493 4.682 7.18006 4.89469 7.02535 5.16694C6.87065 5.43068 6.79329 5.73696 6.79329 6.08577H5.60721C5.60721 5.58382 5.71895 5.11589 5.94241 4.682C6.17447 4.24811 6.51826 3.89504 6.97378 3.6228C7.42931 3.35055 7.99656 3.21443 8.67555 3.21443C9.27718 3.21443 9.79287 3.33779 10.2226 3.58451C10.6609 3.82273 10.9961 4.14602 11.2282 4.55439C11.4689 4.95425 11.5892 5.39665 11.5892 5.88159V6.03472C11.5892 6.51116 11.4732 6.9493 11.2411 7.34916C11.009 7.74902 10.6609 8.06806 10.1968 8.30628C9.7413 8.53598 9.17834 8.65084 8.50795 8.65084L8.97207 8.06381L8.94629 10.0036H7.70864ZM7.57971 12.7856V11.2287H9.07521V12.7856H7.57971Z"
                  fill="#767676"
                />
              </svg>
            ) : text.broadcast_status === "1" ? (
              ""
            ) : text.broadcast_status === "3" ? (
              ""
            ) : text.broadcast_status === "2" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M9.39455 0.812325L8.77549 0.285366C8.3285 -0.0951222 7.6715 -0.0951218 7.22451 0.285366L6.60545 0.812325C6.33165 1.0454 5.96753 1.14296 5.61388 1.07802L4.81428 0.931185C4.23693 0.825167 3.66795 1.15367 3.47109 1.70668L3.19845 2.47257C3.07786 2.81131 2.81131 3.07786 2.47257 3.19845L1.70668 3.47109C1.15367 3.66795 0.825167 4.23693 0.931186 4.81428L1.07802 5.61388C1.14296 5.96753 1.0454 6.33165 0.812325 6.60545L0.285366 7.22451C-0.0951222 7.6715 -0.0951218 8.3285 0.285366 8.77549L0.812325 9.39455C1.0454 9.66835 1.14296 10.0325 1.07802 10.3861L0.931185 11.1857C0.825167 11.7631 1.15367 12.3321 1.70668 12.5289L2.47257 12.8016C2.81131 12.9221 3.07786 13.1887 3.19845 13.5274L3.47109 14.2933C3.66795 14.8463 4.23693 15.1748 4.81428 15.0688L5.61388 14.922C5.96753 14.857 6.33165 14.9546 6.60545 15.1877L7.22451 15.7146C7.6715 16.0951 8.3285 16.0951 8.77549 15.7146L9.39455 15.1877C9.66835 14.9546 10.0325 14.857 10.3861 14.922L11.1857 15.0688C11.7631 15.1748 12.3321 14.8463 12.5289 14.2933L12.8016 13.5274C12.9221 13.1887 13.1887 12.9221 13.5274 12.8016L14.2933 12.5289C14.8463 12.3321 15.1748 11.7631 15.0688 11.1857L14.922 10.3861C14.857 10.0325 14.9546 9.66835 15.1877 9.39455L15.7146 8.77549C16.0951 8.3285 16.0951 7.6715 15.7146 7.22451L15.1877 6.60545C14.9546 6.33165 14.857 5.96753 14.922 5.61388L15.0688 4.81428C15.1748 4.23693 14.8463 3.66795 14.2933 3.47109L13.5274 3.19845C13.1887 3.07786 12.9221 2.81131 12.8016 2.47257L12.5289 1.70668C12.3321 1.15367 11.7631 0.825167 11.1857 0.931186L10.3861 1.07802C10.0325 1.14296 9.66835 1.0454 9.39455 0.812325Z"
                  fill="#849DED"
                />
                <path
                  d="M12.5354 5.22656L6.30208 11.4399L3.46875 8.61565"
                  stroke="#3B5092"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.22451 0.285366C7.6715 -0.0951218 8.3285 -0.0951222 8.77549 0.285366L9.39455 0.812325C9.66835 1.04539 10.0325 1.14296 10.3861 1.07802L11.1857 0.931186C11.7631 0.825167 12.3321 1.15367 12.5289 1.70668L12.8016 2.47257C12.9221 2.81131 13.1887 3.07786 13.5274 3.19845L14.2933 3.47109C14.8463 3.66795 15.1748 4.23693 15.0688 4.81428L14.922 5.61388C14.857 5.96753 14.9546 6.33165 15.1877 6.60545L15.7146 7.22451C16.0951 7.6715 16.0951 8.3285 15.7146 8.77549L15.1877 9.39455C14.9546 9.66835 14.857 10.0325 14.922 10.3861L15.0688 11.1857C15.1748 11.7631 14.8463 12.3321 14.2933 12.5289L13.5274 12.8016C13.1887 12.9221 12.9221 13.1887 12.8016 13.5274L12.5289 14.2933C12.3321 14.8463 11.7631 15.1748 11.1857 15.0688L10.3861 14.922C10.0325 14.857 9.66835 14.9546 9.39455 15.1877L8.77549 15.7146C8.3285 16.0951 7.6715 16.0951 7.22451 15.7146L6.60545 15.1877C6.33165 14.9546 5.96753 14.857 5.61388 14.922L4.81428 15.0688C4.23693 15.1748 3.66795 14.8463 3.47109 14.2933L3.19845 13.5274C3.07786 13.1887 2.81131 12.9221 2.47257 12.8016L1.70668 12.5289C1.15367 12.3321 0.825167 11.7631 0.931185 11.1857L1.07802 10.3861C1.14296 10.0325 1.0454 9.66835 0.812325 9.39455L0.285366 8.77549C-0.0951218 8.3285 -0.0951222 7.6715 0.285366 7.22451L0.812325 6.60545C1.04539 6.33165 1.14296 5.96753 1.07802 5.61388L0.931186 4.81428C0.825167 4.23693 1.15367 3.66795 1.70668 3.47109L2.47257 3.19845C2.81131 3.07786 3.07786 2.81131 3.19845 2.47257L3.47109 1.70668C3.66795 1.15367 4.23693 0.825167 4.81428 0.931185L5.61388 1.07802C5.96753 1.14296 6.33165 1.0454 6.60545 0.812325L7.22451 0.285366Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M7.70864 10.0036V7.62992H8.5982C9.20843 7.62992 9.65965 7.46827 9.95187 7.14498C10.2527 6.82169 10.4031 6.42608 10.4031 5.95816C10.4031 5.60934 10.33 5.31157 10.1839 5.06485C10.0378 4.80962 9.83155 4.61819 9.56511 4.49058C9.30727 4.35446 9.01075 4.2864 8.67555 4.2864C8.28878 4.2864 7.95359 4.36722 7.66996 4.52886C7.39493 4.682 7.18006 4.89469 7.02535 5.16694C6.87065 5.43068 6.79329 5.73696 6.79329 6.08577H5.60721C5.60721 5.58382 5.71895 5.11589 5.94241 4.682C6.17447 4.24811 6.51826 3.89504 6.97378 3.6228C7.42931 3.35055 7.99656 3.21443 8.67555 3.21443C9.27718 3.21443 9.79287 3.33779 10.2226 3.58451C10.6609 3.82273 10.9961 4.14602 11.2282 4.55439C11.4689 4.95425 11.5892 5.39665 11.5892 5.88159V6.03472C11.5892 6.51116 11.4732 6.9493 11.2411 7.34916C11.009 7.74902 10.6609 8.06806 10.1968 8.30628C9.7413 8.53598 9.17834 8.65084 8.50795 8.65084L8.97207 8.06381L8.94629 10.0036H7.70864ZM7.57971 12.7856V11.2287H9.07521V12.7856H7.57971Z"
                  fill="#767676"
                />
              </svg>
            )}
          </p>
          <p style={{ marginBottom: "0px" }}>
            {/* {text.broadcast_status === "0"
              ? "Yet to start"
              : text.broadcast_status === "1"
              ? "In progress"
              : text.broadcast_status === "2"
              ? "Intrested"
              : "Yet to start"} */}
            {text.interest_level}
          </p>
          {/* </div> */}
        </div>
      ),
      ellipsis: {
        showTitle: false,
      },
      // render: (text) => (
      //   <span>
      //     <SoftBadge
      //       variant="contained"
      //       color={text.status === "sent" ? "seconday" : "dark"}
      //       size="xs"
      //       badgeContent={text.status === "sent" ? "Sent" : "Yet to send"}
      //       container
      //     />
      //   </span>
      //   // <Tooltip placement="topLeft" title={text.status}>
      //   //   {text.status}
      //   // </Tooltip>
      // ),
    },
    {
      title: "Hiring Status",
      // dataIndex: "hiring_status",
      sorter: { compare: (a, b) => a?.candidate_status.localeCompare(b?.candidate_status) },
      key: "candidate_status",
      width: 300,
      render: (text) => (
        <span
          style={{
            backgroundColor:
              text.completion_status === "completed"
                ? "#BDFDC8"
                : text.completion_status === "rejected"
                  ? "#EFD6FF"
                  : "#FFE1C3",
            color:
              text.completion_status === "completed"
                ? "#049C2F"
                : text.completion_status === "rejected"
                  ? "#62049C"
                  : "#EA7500",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "50px",
          }}
        >
          {text.candidate_status}
        </span>
      ),
      isExportable: true,
    },
    // { title: "Column 8", dataIndex: "address", key: "8" },
    {
      title: "Candidate Profiling Score",
      // dataIndex: "ratings",
      sorter: { compare: (a, b) => a.ratings - b.ratings },
      key: "ratings",
      width: 230,
      render: (text, record) => (
        console.log("profile score: ", text),
        (
          // <SoftBox>
          //   <SoftBadge
          //     variant="contained"
          //     color={text >= 3.5 ? "success" : text < 3.5 && text >= 2 ? "warning" : "error"}
          //     size="xs"
          //     badgeContent={
          //       text >= 3.5
          //         ? "Excellent Match"
          //         : text < 3.5 && text >= 2
          //         ? "Medium Match"
          //         : "Poor Match"
          //     }
          //     container
          //   />
          // </SoftBox>
          <>
            {/* {console.log("ratings text:", text)}, */}
            {/* <span>{text.ratings}</span> */}
            <div
              className="dropdown"
              style={{
                width: "100%",
                height: "30px",
                // border: "1px solid ",
                borderRadius: "15px",
                backgroundColor:
                  text.ratings >= 4
                    ? "#B7F4BB"
                    : text.ratings < 4 && text.ratings >= 2.5
                      ? "#FFD2A5"
                      : text.ratings === 0
                        ? "#abe9f7"
                        : "#FFD3D3",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  // padding: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  padding: "5px 0px 5px",
                  color:
                    text.ratings >= 4
                      ? "#049C2F"
                      : text.ratings < 4 && text.ratings >= 2.5
                        ? "#EA7500"
                        : text.ratings === 0
                          ? "#08a1c4"
                          : "#FF0000",
                }}
              // className="dropdown-toggle"
              // data-bs-toggle="dropdown"
              >
                {/* {text === null ? "Placed" : "Not Placed"} */}
                {text.ratings !== 0 ? text.ratings : "Yet to be rated"}
              </p>
            </div>
          </>
        )
      ),
      isExportable: true,
    },
    {
      title: "Counsellor Assigned",
      width: 200,
      //dataIndex: "counsellor_name",
      //key: "counsellor_name",
      // fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a?.counsellor_name?.localeCompare(b?.counsellor_name) },
      render: (text) => <span>{text.counsellor_name === "" ? "-" : text.counsellor_name}</span>,
      isExportable: true,
    },
    {
      title: "Assigned Dates",
      width: 200,
      dataIndex: "assign_date",
      key: "assign_date",
      // fixed: "left",
      //   sorter: true,
      // render: (text, record) => <>{moment(text).format("DD-MM-YYYY")}</>,
      sorter: { compare: (a, b) => a?.assign_date?.localeCompare(b?.assign_date) },
      // isExportable: true,
    },
    {
      title: "Counsellor Comments",
      // dataIndex: "comments",
      width: 200,
      key: "display_comment",
      render: (text) => (
        <span>{text.display_comment === "" ? "-" : text.display_comment}</span>
        // <span>{text.job_role}</span>
      ),
      sorter: { compare: (a, b) => a?.display_comment?.localeCompare(b?.display_comment) },
      isExportable: true,
    },
    {
      title: "Candidate Feedback",
      // dataIndex: "feedback",
      width: 200,
      key: "feedback",
      render: (text) => (
        <span>{text.feedback === "" ? "-" : text.feedback}</span>
        // <span>{text.job_role}</span>
      ),
      isExportable: true,
      sorter: { compare: (a, b) => a?.feedback?.localeCompare(b?.feedback) },
    },
    {
      title: "Resume",
      width: 150,
      key: "resume",
      render: (text) =>
        text?.resume !== "" ? (
          (console.log("aaaa", text.resume_cv),
            (
              <a href={text.resume} target="_blank" rel="noreferrer">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <path
                  fill="currentColor"
                  d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25Z"
                />
                <path
                  fill="currentColor"
                  d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6Zm0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4Z"
                />
              </svg> */}
                <SoftBadge
                  variant="contained"
                  color="success"
                  size="lg"
                  badgeContent={
                    // text >= 80
                    //   ? "Excellent Match"
                    //   : text < 80 && text >= 45
                    //   ? "Medium Match"
                    //   : "Poor Match"
                    "View"
                  }
                  container
                />
              </a>
            ))
        ) : (
          <span onClick={() => (setResumeModal(true), setRowData(text))}>
            {/* <label htmlFor="resumeUpload">
              <SoftBadge
                variant="contained"
                color="info"
                size="lg"
                badgeContent={
                  // text >= 80
                  //   ? "Excellent Match"
                  //   : text < 80 && text >= 45
                  //   ? "Medium Match"
                  //   : "Poor Match"
                  "Upload"
                }
                container
              />
            </label>
            <input
              id="resumeUpload"
              style={{ display: "none" }}
              type="file"
              accept=".pdf, .docs, .png"
              // accept="image/png, image/jpeg"
              onChange={handleFileUpload}
            /> */}
            <SoftBadge
              variant="contained"
              color="info"
              size="lg"
              style={{ cursor: "pointer" }}
              badgeContent={
                // text >= 80
                //   ? "Excellent Match"
                //   : text < 80 && text >= 45
                //   ? "Medium Match"
                //   : "Poor Match"
                "Click to Upload"
              }
              container
            />
          </span>
        ),
      isExportable: true,
    },
    {
      title: "Action",
      // title: (
      //   // <SoftTypography fontWeight="large" fontSize="24px">
      //   //   ...
      //   // </SoftTypography>
      //   <MdMoreHoriz />
      // ),
      key: "operation",
      // fixed: "right",
      width: 100,
      // render: () => <MdMoreHoriz />,
      render: (text) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "1") {
                  setRowData(text);
                  setApplyModal(true);
                } else if (key === "2") {
                  setactionModal1(true);
                  handleConsPost();
                  setRowData(text);
                } else if (key === "4") {
                  // setRowData(text);
                  handleRejectStatus(text);
                } else {
                  setResumeModal(true);
                  setRowData(text);
                }
              },
            }}
          >
            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
  // All columns

  const columns4 = [
    {
      title: "Shortlisted Date",
      dataIndex: "shortlist_date",
      fixed: "left",
      key: "shortlist_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.shortlist_date, "DD-MM-YYYY") - moment(b.shortlist_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
    },
    {
      title: "Candidate Name",
      width: 200,
      dataIndex: "candidate_name",
      key: "candidate_name",
      fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a?.candidate_name?.localeCompare(b?.candidate_name) },
      render: (text, record) => {
        return (
          <p
            // onClick={() => {
            //   setCanId(record.key);
            //   setCounsellorId(record.counsellor_id);
            //   HandleFetchCandDetails(record.key, record.counsellor_id);
            //   console.log("text", record);
            //   // setOpenModal(true)
            // }}
            style={{
              cursor: "pointer",
              marginBottom: "-2px",
              color: "#3B5092",
              textDecorationLine: "underline",
            }}
          >
            {text}
          </p>
        );
      },
    },
    {
      title: "Phone Number",
      width: 150,
      dataIndex: "phone",
      key: "phone",
      fixed: "left",
      sorter: { compare: (a, b) => a?.phone - b?.phone },
    },
    {
      title: "Broadcast Date",
      //dataIndex: "broadcast_date",B
      key: "broadcast_date",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.broadcast_date, "DD-MM-YYYY") - moment(b.broadcast_date, "DD-MM-YYYY"),
        // multiple: 1,
      },
      render: (text) => <>{text.broadcast_date === "" ? "-" : text.broadcast_date}</>,
    },
    {
      title: "Data Source",
      //dataIndex: "datasource",
      key: "datasource",
      width: 200,
      sorter: { compare: (a, b) => a?.datasource?.localeCompare(b?.datasource) },
      render: (text) => (
        <>{text?.datasource === null || text?.datasource === "" ? "-" : text?.datasource}</>
      ),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      width: 200,
      sorter: { compare: (a, b) => a?.industry?.localeCompare(b?.industry) },
      render: (text) => <span>{text !== null ? text : " - "}</span>,
      isExportable: true,
    },
    {
      title: "Roles",
      dataIndex: "job_role",
      key: "job_role",
      width: 150,
      sorter: { compare: (a, b) => a?.job_role?.localeCompare(b?.job_role) },
      isExportable: true,
    },

    {
      title: "Education",
      dataIndex: "education",
      key: "education",
      width: 150,
      sorter: { compare: (a, b) => a?.education?.localeCompare(b?.education) },
      isExportable: true,
    },
    {
      title: "Modified On",
      dataIndex: "modified_on",
      key: "modified_on",
      width: 150,
      sorter: {
        compare: (a, b) =>
          moment(a.modified_on, "DD-MM-YYYY") - moment(b.modified_on, "DD-MM-YYYY"),
      },
      isExportable: true,
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      width: 150,
      sorter: { compare: (a, b) => a?.skills?.localeCompare(b?.skills) },
      isExportable: true,
    },
    {
      title: "Self Opportunity",
      //dataIndex: "skills",
      key: "self_opportunity",
      width: 200,
      sorter: { compare: (a, b) => a?.self_opportunity?.localeCompare(b?.self_opportunity) },
      render: (text) => <span>{text.self_opportunity === "" ? "-" : text.self_opportunity}</span>,
      isExportable: true,
    },
    {
      title: "Available Skills",
      //dataIndex: "skills",
      key: "avl_skill",
      width: 200,
      sorter: { compare: (a, b) => a?.avl_skill?.localeCompare(b?.avl_skill) },
      render: (text) => <span>{text.avl_skill === "" ? "-" : text.avl_skill}</span>,
      isExportable: true,
    },
    {
      title: "Languages Known",
      //dataIndex: "skills",
      key: "languages",
      width: 200,
      sorter: { compare: (a, b) => a?.languages?.localeCompare(b?.languages) },
      render: (text) => <span>{text.languages === "" ? "-" : text.languages}</span>,
      isExportable: true,
    },
    {
      title: "Experience (yrs)",
      dataIndex: "exp",
      key: "exp",
      width: 150,
      sorter: { compare: (a, b) => a?.exp?.localeCompare(b?.exp) },
      isExportable: true,
    },
    {
      title: "Salary Requirement",
      dataIndex: "salary_req",
      key: "salary_req",
      width: 180,
      sorter: { compare: (a, b) => a?.salary_req?.localeCompare(b?.salary_req) },
      isExportable: true,
    },
    {
      title: "Gender",
      //dataIndex: "gender",
      width: 180,
      key: "gender",
      sorter: { compare: (a, b) => a?.gender?.localeCompare(b?.gender) },
      render: (text) => (
        <span>{text?.gender ? text.gender[0].toUpperCase() + text.gender.slice(1) : ""}</span>
      ),
      isExportable: true,
    },
    {
      title: "Age",
      //dataIndex: "age",
      width: 180,
      key: "age",
      //sorter: { compare: (a, b) => a?.date_of_birth - b?.date_of_birth },
      // render: (text) => (
      //   <span>
      //     {text?.date_of_birth
      //       ? String(calAge(moment(text.date_of_birth).format("DD-MM-YYYY"))) === "NaN"
      //         ? "-"
      //         : calAge(moment(text.date_of_birth).format("DD-MM-YYYY"))
      //       : ""}
      //   </span>
      // ),
      sorter: { compare: (a, b) => a?.age - b?.age },
      render: (text) => <span>{text.age === "" ? "-" : text.age}</span>,
      isExportable: true,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: 150,
      sorter: { compare: (a, b) => a?.state?.localeCompare(b?.state) },
      isExportable: true,
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 150,
      sorter: { compare: (a, b) => a?.district?.localeCompare(b?.district) },
      isExportable: true,
    },
    {
      title: "Taluka",
      dataIndex: "taluka",
      key: "taluka",
      width: 150,
      sorter: { compare: (a, b) => a?.taluka?.localeCompare(b?.taluka) },
      isExportable: true,
    },
    {
      title: "Last Active",
      dataIndex: "last_active",
      key: "last_active",
      width: 150,
      sorter: {
        compare: (a, b) => moment(a.last_active, "DD-MM-YYYY") - moment(b.active, "DD-MM-YYYY"),
      },
      isExportable: true,
    },
    {
      title: "Relevancy Score",
      // dataIndex: "score",
      sorter: { compare: (a, b) => a.score - b.score },
      key: "score",
      width: 180,
      isExportable: true,
      // render: (text, record) => (
      //   <span>
      //     <SoftBadge
      //       variant="contained"
      //       color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
      //       size="xs"
      //       badgeContent={
      //         text >= 80
      //           ? "Excellent Match"
      //           : text < 80 && text >= 45
      //           ? "Medium Match"
      //           : "Poor Match"
      //       }
      //       container
      //     />
      //   </span>
      // ),
      render: (text) => (
        // <span
        //   style={{
        //     backgroundColor:
        //       text.score > 80
        //         ? "#B7F4BB"
        //         : text.score < 80 && text.score >= 45
        //         ? "#EFB7F4"
        //         : "#FFD3D3",
        //     color:
        //       text.score > 80
        //         ? "#049C2F"
        //         : text.score < 80 && text.score >= 45
        //         ? "#62049C"
        //         : "#FF0000",
        //     paddingTop: "3px",
        //     paddingBottom: "3px",
        //     paddingLeft: "15px",
        //     paddingRight: "15px",
        //     borderRadius: "10px",
        //   }}
        // >
        //   {text.score >= 80
        //     ? "Excellent Match"
        //     : text.score < 80 && text >= 45
        //     ? "Average Match"
        //     : "Less Match"}
        // </span>
        <span
          style={{
            backgroundColor:
              text.score === 1
                ? "#B7F4BB"
                : text.score === 2
                  ? "#FFF2CC"
                  : // ? "#EFB7F4"
                  "#FFD3D3",
            color:
              text.score === 1
                ? "#049C2F"
                : text.score === 2
                  ? "#FBC400"
                  : // ? "#62049C"
                  "#FF0000",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "10px",
          }}
        >
          {text.score === 1 ? "Excellent Match" : text.score === 2 ? "Average Match" : "Less Match"}
        </span>
      ),
    },
    {
      title: "Approach Status",
      // dataIndex: "sent_status",
      sorter: { compare: (a, b) => a?.sent_status.localeCompare(b?.sent_status) },
      key: "sent_status",
      width: 200,
      render: (text, record) => (
        <span>
          <SoftBadge
            variant="contained"
            color={
              text.sent_status === "success"
                ? "success"
                : text.sent_status === "fail"
                  ? "error"
                  : "info"
            }
            size="lg"
            badgeContent={
              // text >= 80
              //   ? "Excellent Match"
              //   : text < 80 && text >= 45
              //   ? "Medium Match"
              //   : "Poor Match"
              // text.sent_status === "success"
              //   ? "Broadcast Sent"
              //   : text.sent_status === "fail"
              //   ? "Broadcast Failed"
              //   : "Broadcast Not Sent"
              text.sent_status === "success"
                ? "Approached"
                : text.sent_status === "fail"
                  ? "Failed"
                  : "Not Yet Approached"
            }
            container
          />
        </span>
      ),
    },
    {
      title: "Interest Level",
      // dataIndex: "status",
      sorter: { compare: (a, b) => a?.interest_level.localeCompare(b?.interest_level) },
      key: "interest_level",
      width: 200,
      render: (text) => (
        <div
          style={{
            backgroundColor:
              text.broadcast_status === "0"
                ? "#E9E9E9"
                : text.broadcast_status === "1"
                  ? "#EFB7F4"
                  : text.broadcast_status === "2"
                    ? "#C0CFFF"
                    : text.broadcast_status === "3"
                      ? "#FFD3D3"
                      : "#E9E9E9",
            color:
              text.broadcast_status === "0"
                ? "#767676"
                : text.broadcast_status === "1"
                  ? "#62049C"
                  : text.broadcast_status === "2"
                    ? "#3B5092"
                    : text.broadcast_status === "3"
                      ? "#FF0000"
                      : "#767676",
            // text.broadcast_status === "sent"
            //   ? "#3B5092"
            //   : // : text.status === "rejected"
            //     // ? "#62049C"
            //     "#767676",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            paddingRight: "10px",
            borderRadius: "10px",
            gap: "5px",
            // width: "106px",
          }}
        >
          {/* {text.status} */}
          {console.log("status ", text.broadcast_status)}
          {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}> */}
          <p style={{ marginBottom: "0px" }}>
            {text.broadcast_status === "0" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.22451 0.285366C7.6715 -0.0951218 8.3285 -0.0951222 8.77549 0.285366L9.39455 0.812325C9.66835 1.04539 10.0325 1.14296 10.3861 1.07802L11.1857 0.931186C11.7631 0.825167 12.3321 1.15367 12.5289 1.70668L12.8016 2.47257C12.9221 2.81131 13.1887 3.07786 13.5274 3.19845L14.2933 3.47109C14.8463 3.66795 15.1748 4.23693 15.0688 4.81428L14.922 5.61388C14.857 5.96753 14.9546 6.33165 15.1877 6.60545L15.7146 7.22451C16.0951 7.6715 16.0951 8.3285 15.7146 8.77549L15.1877 9.39455C14.9546 9.66835 14.857 10.0325 14.922 10.3861L15.0688 11.1857C15.1748 11.7631 14.8463 12.3321 14.2933 12.5289L13.5274 12.8016C13.1887 12.9221 12.9221 13.1887 12.8016 13.5274L12.5289 14.2933C12.3321 14.8463 11.7631 15.1748 11.1857 15.0688L10.3861 14.922C10.0325 14.857 9.66835 14.9546 9.39455 15.1877L8.77549 15.7146C8.3285 16.0951 7.6715 16.0951 7.22451 15.7146L6.60545 15.1877C6.33165 14.9546 5.96753 14.857 5.61388 14.922L4.81428 15.0688C4.23693 15.1748 3.66795 14.8463 3.47109 14.2933L3.19845 13.5274C3.07786 13.1887 2.81131 12.9221 2.47257 12.8016L1.70668 12.5289C1.15367 12.3321 0.825167 11.7631 0.931185 11.1857L1.07802 10.3861C1.14296 10.0325 1.0454 9.66835 0.812325 9.39455L0.285366 8.77549C-0.0951218 8.3285 -0.0951222 7.6715 0.285366 7.22451L0.812325 6.60545C1.04539 6.33165 1.14296 5.96753 1.07802 5.61388L0.931186 4.81428C0.825167 4.23693 1.15367 3.66795 1.70668 3.47109L2.47257 3.19845C2.81131 3.07786 3.07786 2.81131 3.19845 2.47257L3.47109 1.70668C3.66795 1.15367 4.23693 0.825167 4.81428 0.931185L5.61388 1.07802C5.96753 1.14296 6.33165 1.0454 6.60545 0.812325L7.22451 0.285366Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M7.70864 10.0036V7.62992H8.5982C9.20843 7.62992 9.65965 7.46827 9.95187 7.14498C10.2527 6.82169 10.4031 6.42608 10.4031 5.95816C10.4031 5.60934 10.33 5.31157 10.1839 5.06485C10.0378 4.80962 9.83155 4.61819 9.56511 4.49058C9.30727 4.35446 9.01075 4.2864 8.67555 4.2864C8.28878 4.2864 7.95359 4.36722 7.66996 4.52886C7.39493 4.682 7.18006 4.89469 7.02535 5.16694C6.87065 5.43068 6.79329 5.73696 6.79329 6.08577H5.60721C5.60721 5.58382 5.71895 5.11589 5.94241 4.682C6.17447 4.24811 6.51826 3.89504 6.97378 3.6228C7.42931 3.35055 7.99656 3.21443 8.67555 3.21443C9.27718 3.21443 9.79287 3.33779 10.2226 3.58451C10.6609 3.82273 10.9961 4.14602 11.2282 4.55439C11.4689 4.95425 11.5892 5.39665 11.5892 5.88159V6.03472C11.5892 6.51116 11.4732 6.9493 11.2411 7.34916C11.009 7.74902 10.6609 8.06806 10.1968 8.30628C9.7413 8.53598 9.17834 8.65084 8.50795 8.65084L8.97207 8.06381L8.94629 10.0036H7.70864ZM7.57971 12.7856V11.2287H9.07521V12.7856H7.57971Z"
                  fill="#767676"
                />
              </svg>
            ) : text.broadcast_status === "1" ? (
              ""
            ) : text.broadcast_status === "3" ? (
              ""
            ) : text.broadcast_status === "2" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M9.39455 0.812325L8.77549 0.285366C8.3285 -0.0951222 7.6715 -0.0951218 7.22451 0.285366L6.60545 0.812325C6.33165 1.0454 5.96753 1.14296 5.61388 1.07802L4.81428 0.931185C4.23693 0.825167 3.66795 1.15367 3.47109 1.70668L3.19845 2.47257C3.07786 2.81131 2.81131 3.07786 2.47257 3.19845L1.70668 3.47109C1.15367 3.66795 0.825167 4.23693 0.931186 4.81428L1.07802 5.61388C1.14296 5.96753 1.0454 6.33165 0.812325 6.60545L0.285366 7.22451C-0.0951222 7.6715 -0.0951218 8.3285 0.285366 8.77549L0.812325 9.39455C1.0454 9.66835 1.14296 10.0325 1.07802 10.3861L0.931185 11.1857C0.825167 11.7631 1.15367 12.3321 1.70668 12.5289L2.47257 12.8016C2.81131 12.9221 3.07786 13.1887 3.19845 13.5274L3.47109 14.2933C3.66795 14.8463 4.23693 15.1748 4.81428 15.0688L5.61388 14.922C5.96753 14.857 6.33165 14.9546 6.60545 15.1877L7.22451 15.7146C7.6715 16.0951 8.3285 16.0951 8.77549 15.7146L9.39455 15.1877C9.66835 14.9546 10.0325 14.857 10.3861 14.922L11.1857 15.0688C11.7631 15.1748 12.3321 14.8463 12.5289 14.2933L12.8016 13.5274C12.9221 13.1887 13.1887 12.9221 13.5274 12.8016L14.2933 12.5289C14.8463 12.3321 15.1748 11.7631 15.0688 11.1857L14.922 10.3861C14.857 10.0325 14.9546 9.66835 15.1877 9.39455L15.7146 8.77549C16.0951 8.3285 16.0951 7.6715 15.7146 7.22451L15.1877 6.60545C14.9546 6.33165 14.857 5.96753 14.922 5.61388L15.0688 4.81428C15.1748 4.23693 14.8463 3.66795 14.2933 3.47109L13.5274 3.19845C13.1887 3.07786 12.9221 2.81131 12.8016 2.47257L12.5289 1.70668C12.3321 1.15367 11.7631 0.825167 11.1857 0.931186L10.3861 1.07802C10.0325 1.14296 9.66835 1.0454 9.39455 0.812325Z"
                  fill="#849DED"
                />
                <path
                  d="M12.5354 5.22656L6.30208 11.4399L3.46875 8.61565"
                  stroke="#3B5092"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.22451 0.285366C7.6715 -0.0951218 8.3285 -0.0951222 8.77549 0.285366L9.39455 0.812325C9.66835 1.04539 10.0325 1.14296 10.3861 1.07802L11.1857 0.931186C11.7631 0.825167 12.3321 1.15367 12.5289 1.70668L12.8016 2.47257C12.9221 2.81131 13.1887 3.07786 13.5274 3.19845L14.2933 3.47109C14.8463 3.66795 15.1748 4.23693 15.0688 4.81428L14.922 5.61388C14.857 5.96753 14.9546 6.33165 15.1877 6.60545L15.7146 7.22451C16.0951 7.6715 16.0951 8.3285 15.7146 8.77549L15.1877 9.39455C14.9546 9.66835 14.857 10.0325 14.922 10.3861L15.0688 11.1857C15.1748 11.7631 14.8463 12.3321 14.2933 12.5289L13.5274 12.8016C13.1887 12.9221 12.9221 13.1887 12.8016 13.5274L12.5289 14.2933C12.3321 14.8463 11.7631 15.1748 11.1857 15.0688L10.3861 14.922C10.0325 14.857 9.66835 14.9546 9.39455 15.1877L8.77549 15.7146C8.3285 16.0951 7.6715 16.0951 7.22451 15.7146L6.60545 15.1877C6.33165 14.9546 5.96753 14.857 5.61388 14.922L4.81428 15.0688C4.23693 15.1748 3.66795 14.8463 3.47109 14.2933L3.19845 13.5274C3.07786 13.1887 2.81131 12.9221 2.47257 12.8016L1.70668 12.5289C1.15367 12.3321 0.825167 11.7631 0.931185 11.1857L1.07802 10.3861C1.14296 10.0325 1.0454 9.66835 0.812325 9.39455L0.285366 8.77549C-0.0951218 8.3285 -0.0951222 7.6715 0.285366 7.22451L0.812325 6.60545C1.04539 6.33165 1.14296 5.96753 1.07802 5.61388L0.931186 4.81428C0.825167 4.23693 1.15367 3.66795 1.70668 3.47109L2.47257 3.19845C2.81131 3.07786 3.07786 2.81131 3.19845 2.47257L3.47109 1.70668C3.66795 1.15367 4.23693 0.825167 4.81428 0.931185L5.61388 1.07802C5.96753 1.14296 6.33165 1.0454 6.60545 0.812325L7.22451 0.285366Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M7.70864 10.0036V7.62992H8.5982C9.20843 7.62992 9.65965 7.46827 9.95187 7.14498C10.2527 6.82169 10.4031 6.42608 10.4031 5.95816C10.4031 5.60934 10.33 5.31157 10.1839 5.06485C10.0378 4.80962 9.83155 4.61819 9.56511 4.49058C9.30727 4.35446 9.01075 4.2864 8.67555 4.2864C8.28878 4.2864 7.95359 4.36722 7.66996 4.52886C7.39493 4.682 7.18006 4.89469 7.02535 5.16694C6.87065 5.43068 6.79329 5.73696 6.79329 6.08577H5.60721C5.60721 5.58382 5.71895 5.11589 5.94241 4.682C6.17447 4.24811 6.51826 3.89504 6.97378 3.6228C7.42931 3.35055 7.99656 3.21443 8.67555 3.21443C9.27718 3.21443 9.79287 3.33779 10.2226 3.58451C10.6609 3.82273 10.9961 4.14602 11.2282 4.55439C11.4689 4.95425 11.5892 5.39665 11.5892 5.88159V6.03472C11.5892 6.51116 11.4732 6.9493 11.2411 7.34916C11.009 7.74902 10.6609 8.06806 10.1968 8.30628C9.7413 8.53598 9.17834 8.65084 8.50795 8.65084L8.97207 8.06381L8.94629 10.0036H7.70864ZM7.57971 12.7856V11.2287H9.07521V12.7856H7.57971Z"
                  fill="#767676"
                />
              </svg>
            )}
          </p>
          <p style={{ marginBottom: "0px" }}>
            {/* {text.broadcast_status === "0"
              ? "Yet to start"
              : text.broadcast_status === "1"
              ? "In progress"
              : text.broadcast_status === "2"
              ? "Intrested"
              : "Yet to start"} */}
            {text.interest_level}
          </p>
          {/* </div> */}
        </div>
      ),
      ellipsis: {
        showTitle: false,
      },
      // render: (text) => (
      //   <span>
      //     <SoftBadge
      //       variant="contained"
      //       color={text.status === "sent" ? "seconday" : "dark"}
      //       size="xs"
      //       badgeContent={text.status === "sent" ? "Sent" : "Yet to send"}
      //       container
      //     />
      //   </span>
      //   // <Tooltip placement="topLeft" title={text.status}>
      //   //   {text.status}
      //   // </Tooltip>
      // ),
    },
    {
      title: "Hiring Status",
      // dataIndex: "hiring_status",
      sorter: { compare: (a, b) => a?.candidate_status.localeCompare(b?.candidate_status) },
      key: "candidate_status",
      width: 300,
      render: (text) => (
        <span
          style={{
            backgroundColor:
              text.completion_status === "completed"
                ? "#BDFDC8"
                : text.completion_status === "rejected"
                  ? "#EFD6FF"
                  : text.completion_status === ""
                    ? "#E9E9E9"
                    : "#FFE1C3",
            color:
              text.completion_status === "completed"
                ? "#049C2F"
                : text.completion_status === "rejected"
                  ? "#62049C"
                  : text.completion_status === ""
                    ? "#767676"
                    : "#EA7500",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "50px",
          }}
        >
          {text.candidate_status === "" ? "-" : text.candidate_status}
        </span>
      ),
      isExportable: true,
    },
    // { title: "Column 8", dataIndex: "address", key: "8" },
    {
      title: "Candidate Profiling Score",
      // dataIndex: "ratings",
      sorter: { compare: (a, b) => a.ratings - b.ratings },
      key: "ratings",
      width: 230,
      render: (text, record) => (
        console.log("profile score: ", text),
        (
          // <SoftBox>
          //   <SoftBadge
          //     variant="contained"
          //     color={text >= 3.5 ? "success" : text < 3.5 && text >= 2 ? "warning" : "error"}
          //     size="xs"
          //     badgeContent={
          //       text >= 3.5
          //         ? "Excellent Match"
          //         : text < 3.5 && text >= 2
          //         ? "Medium Match"
          //         : "Poor Match"
          //     }
          //     container
          //   />
          // </SoftBox>
          <>
            {/* {console.log("ratings text:", text)}, */}
            {/* <span>{text.ratings}</span> */}
            <div
              className="dropdown"
              style={{
                width: "100%",
                height: "30px",
                // border: "1px solid ",
                borderRadius: "15px",
                backgroundColor:
                  text.ratings >= 4
                    ? "#B7F4BB"
                    : text.ratings < 4 && text.ratings >= 2.5
                      ? "#FFD2A5"
                      : text.ratings === 0
                        ? "#abe9f7"
                        : "#FFD3D3",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  // padding: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  padding: "5px 0px 5px",
                  color:
                    text.ratings >= 4
                      ? "#049C2F"
                      : text.ratings < 4 && text.ratings >= 2.5
                        ? "#EA7500"
                        : text.ratings === 0
                          ? "#08a1c4"
                          : "#FF0000",
                }}
              // className="dropdown-toggle"
              // data-bs-toggle="dropdown"
              >
                {/* {text === null ? "Placed" : "Not Placed"} */}
                {text.ratings !== 0 ? text.ratings : "Yet to be rated"}
              </p>
            </div>
          </>
        )
      ),
      isExportable: true,
    },
    /*
     {
       title: "Counsellor Assigned",
       width: 200,
       dataIndex: "counsellor_name",
       key: "counsellor_name",
       // fixed: "left",
       //   sorter: true,
       sorter: { compare: (a, b) => a?.counsellor_name?.localeCompare(b?.counsellor_name) },
       isExportable: true,
     },
    */
    {
      title: "Assigned Dates",
      width: 200,
      dataIndex: "assign_date",
      key: "assign_date",
      // fixed: "left",
      //   sorter: true,
      // render: (text, record) => (
      //   <>{text.assign_date ? moment(text.assign_date).format("DD-MM-YYYY") : "-"}</>
      //   // <>{text.assign_date ? text.assign_date : "-"}</>
      // ),
      sorter: { compare: (a, b) => a?.assign_date?.localeCompare(b?.assign_date) },
      isExportable: true,
    },
    /*
    {
      title: "Counsellor Comments",
      // dataIndex: "comments",
      width: 200,
      key: "display_comment",
      render: (text) => (
        <span>{text.display_comment !== null ? text.display_comment : " - "}</span>
        // <span>{text.job_role}</span>
      ),
      isExportable: true,
    },
    {
      title: "Candidate Feedback",
      // dataIndex: "feedback",
      width: 200,
      key: "feedback",
      render: (text) => (
        <span>{text.feedback !== null ? text.feedback : " - "}</span>
        // <span>{text.job_role}</span>
      ),
      isExportable: true,
    },
    */
    {
      title: "Resume",
      width: 150,
      key: "resume",
      render: (text) =>
        text?.resume !== null ? (
          (console.log("a", text.resume),
            (
              <a href={text.resume} target="_blank" rel="noreferrer">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <path
                  fill="currentColor"
                  d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25Z"
                />
                <path
                  fill="currentColor"
                  d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6Zm0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4Z"
                />
              </svg> */}
                <SoftBadge
                  variant="contained"
                  color="success"
                  size="lg"
                  badgeContent={
                    // text >= 80
                    //   ? "Excellent Match"
                    //   : text < 80 && text >= 45
                    //   ? "Medium Match"
                    //   : "Poor Match"
                    "View"
                  }
                  container
                />
              </a>
            ))
        ) : (
          <span onClick={() => (setResumeModal(true), setRowData(text))}>
            {/* <label htmlFor="resumeUpload">
              <SoftBadge
                variant="contained"
                color="info"
                size="lg"
                badgeContent={
                  // text >= 80
                  //   ? "Excellent Match"
                  //   : text < 80 && text >= 45
                  //   ? "Medium Match"
                  //   : "Poor Match"
                  "Upload"
                }
                container
              />
            </label>
            <input
              id="resumeUpload"
              style={{ display: "none" }}
              type="file"
              accept=".pdf, .docs, .png"
              // accept="image/png, image/jpeg"
              onChange={handleFileUpload}
            /> */}
            <SoftBadge
              variant="contained"
              color="info"
              size="lg"
              style={{ cursor: "pointer" }}
              badgeContent={
                // text >= 80
                //   ? "Excellent Match"
                //   : text < 80 && text >= 45
                //   ? "Medium Match"
                //   : "Poor Match"
                "Click to Upload"
              }
              container
            />
          </span>
        ),
      isExportable: true,
    },
    {
      title: "Action",
      // title: (
      //   // <SoftTypography fontWeight="large" fontSize="24px">
      //   //   ...
      //   // </SoftTypography>
      //   <MdMoreHoriz />
      // ),
      key: "operation",
      // fixed: "right",
      width: 100,
      // render: () => <MdMoreHoriz />,
      render: (text) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "1") {
                  setApplyModal(true);
                  setRowData(text);
                } else if (key === "2") {
                  setactionModal1(true);
                  handleConsPost();
                  setRowData(text);
                } else if (key === "4") {
                  // setRowData(text);
                  handleRejectStatus(text);
                } else {
                  setResumeModal(true);
                  setRowData(text);
                }
              },
            }}
          >
            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
  console.log(cosId);
  const sendMessageFunc = () => {
    console.log("JOb designation", jobdesignation);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/whatsappverification`,
        // "https://ee6e-2401-4900-6293-a5e8-fd29-5a11-4be5-a138.ngrok-free.app/superadmin/candidatesearch/whatsappverification",
        {
          // jobid: rowJobId,
          // candid: selectedRowKeys,
          // mobnum: rowMob,
          // name: rowName,
          // broadcastedby: orgId,
          broadcast_job: rowJobId?.length !== 0 ? rowJobId : rowData?.jobid?.split(" "),
          broadcast_cand: selectedRowKeys?.length !== 0 ? selectedRowKeys : rowData?.key.split(" "),
          broadcast_mobnum: rowMob?.length !== 0 ? rowMob : rowData?.phone.split(" "),
          cand_name: rowName?.length !== 0 ? rowName : [rowData?.candidate_name],
          job_designation:
            jobdesignation?.length !== 0 ? jobdesignation : rowData?.job_role?.split(","),
          broadcastedby: newOrgId,
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          //title: "Success",
          // text: res.data["User Message"],
          title: "WhatsApp Message Queued",
          showConfirmButton: false,
          //position: "top",
          timer: 3000,
        }).then(() => {
          navigate("/skilling-shortlisted");
          handleShortlistedApi(newOrgId, newCourseId);
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  // const handleSearch = () => {
  //   const newData = admindata.filter((ele) => {
  //     return name?.toLocaleLowerCase() === ""
  //       ? ele
  //       : ele.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
  //         name.toLocaleLowerCase() === ""
  //       ? ele
  //       : String(ele.mobnum).toLocaleLowerCase().includes(name.toLocaleLowerCase());
  //   });
  //   // .filter((y) => y.department == (department1 == "" ? y.department : department1))
  //   // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
  //   // setSearchData(newData);
  //   setSearchData(newData);
  // };
  // useEffect(() => {
  //   handleSearch();
  // }, [name]);

  /*
  useEffect(() => {
    if (step === "All") {
      setStep1(true)
      setSearchSet(true)
    }
  })
  */

  console.log(
    "datasource",
    selectedDSF?.split(",").find((ele) => ele === "Channel Partner") !== ""
      ? data?.filter((ele) => {
        return selectedDSF?.split(",").find((ele) => ele === "Channel Partner") ===
          "Channel Partner"
          ? ele.datasource.startsWith("SMR-CP-")
          : ele;
      })
      : data
  );

  useEffect(() => {
    stage1.call_status === "Answered" ? setShowCallStatus(true) : setShowCallStatus(false);
    stage1.job_status === "Actively looking for job" ? setShowJobPost(true) : setShowJobPost(false);
  }, []);
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftBox display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <SoftBox onClick={buttonFunc} sx={{ cursor: "pointer", mr: "16px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                  >
                    <rect width="27" height="27" rx="13.5" fill="#D9D9D9" />
                    <path
                      d="M16.2008 18.9016L10.8008 13.5016L16.2008 8.10156"
                      stroke="black"
                      strokeWidth="1.8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SoftBox>

                <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="20px">
                  {`Shortlisted candidates for \"${jobrole || localStorage_jobRole}\" in \"${orgname || localStorage_orgName
                    }\"`}
                </SoftTypography>
              </Box>

              <SoftBox display="flex" flexDirection="row" gap="10px">
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  color="#fff"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="68px"
                  /*
                  onClick={() => {
                    setactionModal(true);
                  }}
                  */
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <SoftTypography fontWeight="small" fontSize="14px" lineHeight="18px" color="#fff">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="36"
                      viewBox="0 0 42 36"
                      fill="none"
                    >
                      <rect width="42" height="36" rx="2" fill="white" />
                      <path
                        d="M10 18C10 18 14 10 21 10C28 10 32 18 32 18C32 18 28 26 21 26C14 26 10 18 10 18Z"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 21C22.6569 21 24 19.6569 24 18C24 16.3431 22.6569 15 21 15C19.3431 15 18 16.3431 18 18C18 19.6569 19.3431 21 21 21Z"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg> */}
                    Action
                  </SoftTypography>
                </SoftBox>
                <Menu
                  sx={{ mt: 2 }}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* <MenuItem
                    onClick={() => {
                      setApplyModal(true);
                      handleClose();
                      // handleConsPost();
                    }}
                  >
                    Activate AI Bot
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      setactionModal1(true);
                      setactionModal(false);
                      handleConsPost();
                      handleClose();
                    }}
                  >
                    Assign Counsellor
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      // setactionModal1(true);
                      // setactionModal(false);
                      // handleConsPost();
                      handleClose();
                      selectedRowKeys.length !== 0
                        ? handleRejectStatus()
                        : Swal.fire({
                            icon: "warning",
                            title: "Oops...",
                            // text: res.data["User Message"],
                            text: "Please select the candidate ",
                            showConfirmButton: false,
                            timer: 3000,
                          });
                    }}
                  >
                    Reject Candidates
                  </MenuItem> */}
                  {/* <MenuItem onClick={handleClose}>SMS Verification</MenuItem> */}
                </Menu>
                {/* {step2 && ( */}
                <SoftBox
                  sx={{
                    height: "40px",
                    //backgroundColor: "#81BBFF",
                    //borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                // width="117px"
                //color="#fff"
                >
                  {/* <Card sx={{ overflow: "hidden", backgroundColor: "#81BBFF" }}> */}
                  <Select
                    options={viewList}
                    placeholder="View List"
                    styles={stylesDD}
                    //fontWeight="small"
                    //fontSize="14px"
                    //lineHeight="18px"
                    value={
                      step1
                        ? { label: "All", value: "All" }
                        : step2
                          ? { label: "List to AI Bot Engaged", value: "List to AI Bot Engaged" }
                          : step4
                            ? { label: "Unengaged", value: "Unengaged" }
                            : {
                              label: "List to Counsellor Engaged",
                              value: "List to Counsellor Engaged",
                            }
                    }
                    onChange={(e) => {
                      {
                        e.value === "List to AI Bot Engaged"
                          ? (setStep2(true),
                            setStep1(false),
                            setStep3(false),
                            setStep4(false),
                            setSelectedRowKeys([]),
                            setTablerow([]),
                            setItems([
                              {
                                key: "1",
                                label: (
                                  <a style={{ display: "flex" }} className="dropdown-item">
                                    Activate AI Bot
                                  </a>
                                ),
                              },
                              {
                                key: "2",
                                label: (
                                  <a className="dropdown-item" style={{ display: "flex" }}>
                                    Assign Counsellor
                                  </a>
                                ),
                              },
                            ]),
                            setStep("List to AI Bot Engaged"))
                          : // handleShortlistedApi()
                          e.value === "All"
                            ? (setStep2(false),
                              setStep1(true),
                              setStep3(false),
                              setStep4(false),
                              setItems([
                                {
                                  key: "1",
                                  label: (
                                    <a style={{ display: "flex" }} className="dropdown-item">
                                      Activate AI Bot
                                    </a>
                                  ),
                                },
                                {
                                  key: "2",
                                  label: (
                                    <a className="dropdown-item" style={{ display: "flex" }}>
                                      Assign Counsellor
                                    </a>
                                  ),
                                },
                                {
                                  key: "4",
                                  label: (
                                    <a className="dropdown-item" style={{ display: "flex" }}>
                                      Reject Candidate
                                    </a>
                                  ),
                                },
                                {
                                  key: "3",
                                  label: (
                                    <a className="dropdown-item" style={{ display: "flex" }}>
                                      Upload Resume
                                    </a>
                                  ),
                                },
                              ]),
                              setSelectedRowKeys([]),
                              setTablerow([]),
                              setStep("All"),
                              setAllData(true),
                              setAgefilter([0, 100]))
                            : // handleShortlistedApi()
                            e.value === "Unengaged"
                              ? (setStep2(false),
                                setStep1(false),
                                setStep3(false),
                                setStep4(true),
                                setSelectedRowKeys([]),
                                setTablerow([]),
                                setStep("Unengaged"),
                                setItems([
                                  {
                                    key: "1",
                                    label: (
                                      <a style={{ display: "flex" }} className="dropdown-item">
                                        Activate AI Bot
                                      </a>
                                    ),
                                  },
                                  {
                                    key: "2",
                                    label: (
                                      <a className="dropdown-item" style={{ display: "flex" }}>
                                        Assign Counsellor
                                      </a>
                                    ),
                                  },
                                  {
                                    key: "4",
                                    label: (
                                      <a className="dropdown-item" style={{ display: "flex" }}>
                                        Reject Candidate
                                      </a>
                                    ),
                                  },
                                  {
                                    key: "3",
                                    label: (
                                      <a className="dropdown-item" style={{ display: "flex" }}>
                                        Upload Resume
                                      </a>
                                    ),
                                  },
                                ]),
                                setAgefilter([0, 100]))
                              : // handleShortlistedApi()
                              (setStep2(false),
                                setStep1(false),
                                setStep3(true),
                                setStep4(false),
                                setSelectedRowKeys([]),
                                setTablerow([]),
                                setStep("List to Counsellor Engaged"));
                        // handleShortlistedApi()
                      }
                    }}
                  />
                  {/* </Card> */}
                </SoftBox>
                {/* )} */}

                {/*
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#FFF",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="40px"
                >
                  <SoftBox>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <rect width="38" height="38" rx="2" fill="white" />
                      <path
                        d="M28 22V26C28 26.5304 27.7893 27.0391 27.4142 27.4142C27.0391 27.7893 26.5304 28 26 28H12C11.4696 28 10.9609 27.7893 10.5858 27.4142C10.2107 27.0391 10 26.5304 10 26V22"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 17L19 22L24 17"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 22V10"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </SoftBox>
                */}
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#FFF",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="40px"
                  mr={2}
                  onClick={() => {
                    handleShortlistedApi(newOrgId, newCourseId);
                    resetfunc();
                  }}
                >
                  <SoftBox>
                    <img src={refreshBtn} />
                  </SoftBox>
                </SoftBox>
                {/* <SoftBox sx={{ height: "40px", backgroundColor: "#FFF", borderRadius: "2px", cursor:"pointer" }} 
                                      display="flex" justifyContent="center" alignItems="center"
                                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                      width="40px"
                                  >
                                    <SoftBox>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                                      <rect width="38" height="38" rx="5" fill="white"/>
                                      <path d="M26.3333 28.1673H11.6667C11.4236 28.1673 11.1904 28.0707 11.0185 27.8988C10.8466 27.7269 10.75 27.4938 10.75 27.2507V10.7507C10.75 10.5075 10.8466 10.2744 11.0185 10.1025C11.1904 9.93056 11.4236 9.83398 11.6667 9.83398H26.3333C26.5764 9.83398 26.8096 9.93056 26.9815 10.1025C27.1534 10.2744 27.25 10.5075 27.25 10.7507V27.2507C27.25 27.4938 27.1534 27.7269 26.9815 27.8988C26.8096 28.0707 26.5764 28.1673 26.3333 28.1673ZM25.4167 26.334V11.6673H12.5833V26.334H25.4167ZM15.3333 14.4173H22.6667V16.2507H15.3333V14.4173ZM15.3333 18.084H22.6667V19.9173H15.3333V18.084ZM15.3333 21.7507H22.6667V23.584H15.3333V21.7507Z" fill="#A4A4A4"/>
                                      </svg>
                                    </SoftBox>
                    </SoftBox >
                    <SoftBox  sx={{ height: "40px", backgroundColor: "#FFF", borderRadius: "2px", cursor:"pointer" }} 
                                      display="flex" justifyContent="center" alignItems="center"
                                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                      width="40px"
                                      
                                  >
                                      <SoftBox >
                                          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="37" viewBox="0 0 39 37" fill="none">
                                              <rect width="38.8889" height="37" rx="2" fill="white"/>
                                              <path d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z" stroke="#A4A4A4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                          </svg>
                                      </SoftBox>
                    </SoftBox > */}
              </SoftBox>
            </SoftBox>

            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                width: "100%",
                justifyContent: "space-between",
                mt: 4,
                // mb: 1,
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                gap="10px"
                justifyContent="space-between"
                width={{ lg: "500px", md: "500px", sm: "300px" }}
              >
                <SoftInput
                  isClearable
                  // width={{ lg: "1000px", md: "600px" }}
                  type="search"
                  id="search-bar"
                  className="text"
                  // onInput={(e) => {
                  //   setSearchQuery(e.target.value);
                  // }}
                  // height="40px"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  variant="outlined"
                  placeholder="Search by candidate name , phone number , counsellor comments"
                  size="small"
                />

                {/*
                <SoftBox
                  // pl={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    height: "34px",
                    backgroundColor: "#3B5092",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  p="auto"
                  width={{ lg: "97px", sm: "100%", xs: "50%" }}
                  onClick={() => searchFunc2()}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "medium",
                      lineHeight: "20px",
                    }}
                  >
                    Search
                  </SoftTypography>
                </SoftBox>
                */}
              </SoftBox>

              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                {step1 && (
                  <>
                    {/* <SoftBox
                      sx={{
                        height: "33px",
                        //backgroundColor: "#81BBFF",
                        //borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mx={2}
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      // width="117px"
                      //color="#fff"
                    >
                      {/* <Card sx={{ overflow: "hidden", backgroundColor: "#81BBFF" }}> 
                    <Select
                      options={statusList}
                      placeholder="Status List"
                      styles={stylesDD}
                      // isMulti
                      isClearable
                      height="33px"
                      value={
                        statusFilter !== "" ? { label: statusFilter, value: statusFilter } : null
                      }
                      onChange={(e) => {
                        console.log(e.value);
                        setStatusFilter(e.value ? e.value : "");
                      }}
                    />
                    {/* </Card> *
                    </SoftBox>
                    */}
                    {/* <SoftBox
                      pl={2}
                      sx={{
                        height: "33px",
                        backgroundColor: "#3B5092",
                        borderRadius: "2px",
                        cursor: selectedRowKeys.length ? "pointer" : "no-drop",
                      }}
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      p="7px 22px"
                      width={{ lg: "97px", sm: "100%", xs: "50%" }}
                      // onClick={() => setShow(true)}
                      onClick={() => selectedRowKeys.length && setShowShortlistModal1A(true)}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: "medium",
                          lineHeight: "20px",
                        }}
                      >
                        Transfer
                      </SoftTypography>
                    </SoftBox> */}

                    {/* <Tooltip key="2" title="Upload" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                          ml: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={
                          // () => setShowTemplate(true)
                          () => setBulkResume(true)
                          // setFileName(() => {
                          //   getFileName();
                          // })
                          // getFileName()
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                            <path
                              fill="#fff"
                              d="M8 9v2H5v9h14v-9h-3V9h3a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h3Zm4.884-6.531l3.359 3.358a1 1 0 1 1-1.415 1.415L13 5.413V15a1 1 0 1 1-2 0V5.413L9.172 7.242a1 1 0 1 1-1.415-1.415l3.36-3.358a1.25 1.25 0 0 1 1.767 0Z"
                            />
                          </g>
                        </svg>
                      </SoftBox>
                    </Tooltip> */}

                    <Tooltip key="3" title="Filter" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => {
                          setShowFilter(true);
                          //setAgefilter([18, 45]);
                        }}
                      >
                        <SoftBox>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="34"
                            viewBox="0 0 39 37"
                            fill="none"
                          >
                            <rect width="38.8889" height="37" rx="2" fill="white" />
                            <path
                              d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                              stroke="#A4A4A4"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </SoftBox>
                    </Tooltip>

                    <Tooltip
                      key="2"
                      title={tablerow.length === 0 ? "Download All" : "Download"}
                      placement="top"
                    >
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => (
                          setShow(!show),
                          // setFileName(() => {
                          //   getFileName();
                          // })
                          getFileName()
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="46"
                          height="34"
                          viewBox="0 0 46 34"
                          fill="none"
                        >
                          <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                          <path
                            d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22.9531 16.5098V23.0102"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </SoftBox>
                    </Tooltip>
                  </>
                )}

                {step2 && (
                  <>
                    {/* <Tooltip key="2" title="Upload" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                          ml: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={
                          // () => setShowTemplate(true)
                          () => setBulkResume(true)
                          // setFileName(() => {
                          //   getFileName();
                          // })
                          // getFileName()
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                            <path
                              fill="#fff"
                              d="M8 9v2H5v9h14v-9h-3V9h3a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h3Zm4.884-6.531l3.359 3.358a1 1 0 1 1-1.415 1.415L13 5.413V15a1 1 0 1 1-2 0V5.413L9.172 7.242a1 1 0 1 1-1.415-1.415l3.36-3.358a1.25 1.25 0 0 1 1.767 0Z"
                            />
                          </g>
                        </svg>
                      </SoftBox>
                    </Tooltip> */}

                    <Tooltip key="3" title="Filter" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => {
                          // showFilter ? setShowFilter(false) : setShowFilter(true);
                          setShowFilter(true);
                        }}
                      >
                        <SoftBox>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="34"
                            viewBox="0 0 39 37"
                            fill="none"
                          >
                            <rect width="38.8889" height="37" rx="2" fill="white" />
                            <path
                              d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                              stroke="#A4A4A4"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </SoftBox>
                    </Tooltip>

                    <Tooltip
                      key="2"
                      title={tablerow.length === 0 ? "Download All" : "Download"}
                      placement="top"
                    >
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => (
                          setShow3(!show3),
                          // setFileName(() => {
                          //   getFileName();
                          // })
                          getFileName()
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="46"
                          height="34"
                          viewBox="0 0 46 34"
                          fill="none"
                        >
                          <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                          <path
                            d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22.9531 16.5098V23.0102"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </SoftBox>
                    </Tooltip>
                  </>
                )}

                {step3 && (
                  <>
                    {/* <Tooltip key="2" title="Upload" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                          ml: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={
                          // () => setShowTemplate(true)
                          () => setBulkResume(true)
                          // setFileName(() => {
                          //   getFileName();
                          // })
                          // getFileName()
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                            <path
                              fill="#fff"
                              d="M8 9v2H5v9h14v-9h-3V9h3a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h3Zm4.884-6.531l3.359 3.358a1 1 0 1 1-1.415 1.415L13 5.413V15a1 1 0 1 1-2 0V5.413L9.172 7.242a1 1 0 1 1-1.415-1.415l3.36-3.358a1.25 1.25 0 0 1 1.767 0Z"
                            />
                          </g>
                        </svg>
                      </SoftBox>
                    </Tooltip> */}

                    <Tooltip key="3" title="Filter" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => {
                          // showFilter ? setShowFilter(false) : setShowFilter(true);
                          setShowFilter(true);
                        }}
                      >
                        <SoftBox>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="34"
                            viewBox="0 0 39 37"
                            fill="none"
                          >
                            <rect width="38.8889" height="37" rx="2" fill="white" />
                            <path
                              d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                              stroke="#A4A4A4"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </SoftBox>
                    </Tooltip>

                    <Tooltip
                      key="2"
                      title={tablerow.length === 0 ? "Download All" : "Download"}
                      placement="top"
                    >
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => (
                          setShow2(!show2),
                          // setFileName(() => {
                          //   getFileName();
                          // })
                          getFileName()
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="46"
                          height="34"
                          viewBox="0 0 46 34"
                          fill="none"
                        >
                          <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                          <path
                            d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22.9531 16.5098V23.0102"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </SoftBox>
                    </Tooltip>
                  </>
                )}

                {step4 && (
                  <>
                    {/* <SoftBox
                      pl={2}
                      sx={{
                        height: "33px",
                        backgroundColor: "#3B5092",
                        borderRadius: "2px",
                        cursor: selectedRowKeys.length ? "pointer" : "no-drop",
                      }}
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      p="7px 22px"
                      width={{ lg: "97px", sm: "100%", xs: "50%" }}
                      // onClick={() => setShow(true)}
                      onClick={() => selectedRowKeys.length && setShowShortlistModal1A(true)}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: "medium",
                          lineHeight: "20px",
                        }}
                      >
                        Transfer
                      </SoftTypography>
                    </SoftBox>

                    <Tooltip key="2" title="Upload" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                          ml: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={
                          // () => setShowTemplate(true)
                          () => setBulkResume(true)
                          // setFileName(() => {
                          //   getFileName();
                          // })
                          // getFileName()
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                            <path
                              fill="#fff"
                              d="M8 9v2H5v9h14v-9h-3V9h3a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h3Zm4.884-6.531l3.359 3.358a1 1 0 1 1-1.415 1.415L13 5.413V15a1 1 0 1 1-2 0V5.413L9.172 7.242a1 1 0 1 1-1.415-1.415l3.36-3.358a1.25 1.25 0 0 1 1.767 0Z"
                            />
                          </g>
                        </svg>
                      </SoftBox>
                    </Tooltip> */}

                    <Tooltip key="3" title="Filter" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => {
                          // showFilter ? setShowFilter(false) : setShowFilter(true);
                          setShowFilter(true);
                        }}
                      >
                        <SoftBox>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="34"
                            viewBox="0 0 39 37"
                            fill="none"
                          >
                            <rect width="38.8889" height="37" rx="2" fill="white" />
                            <path
                              d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                              stroke="#A4A4A4"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </SoftBox>
                    </Tooltip>

                    <Tooltip
                      key="2"
                      title={tablerow.length === 0 ? "Download All" : "Download"}
                      placement="top"
                    >
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                          mr: "16px",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => (
                          setShow1(!show1),
                          // setFileName(() => {
                          //   getFileName();
                          // })
                          getFileName()
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="46"
                          height="34"
                          viewBox="0 0 46 34"
                          fill="none"
                        >
                          <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                          <path
                            d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22.9531 16.5098V23.0102"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </SoftBox>
                    </Tooltip>
                  </>
                )}

                {/*
                <Tooltip key="1" title="Filter" placement="top">
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "2px",
                      cursor: "pointer",
                      pr: "8px",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => {
                      setNewName("");
                      setSearchSet(true);
                    }}
                  >
                    <SoftBox>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="39"
                        height="37"
                        viewBox="0 0 39 37"
                        fill="none"
                      >
                        <rect width="38.8889" height="37" rx="2" fill="white" />
                        <path
                          d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                          stroke="#A4A4A4"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </SoftBox>
                  </SoftBox>
                </Tooltip>
                */}
              </Box>
              {/* <SoftBox width={{ lg: "500px", md: "400px" }}>
                      <SoftInput
                        isClearable
                        // width={{ lg: "1000px", md: "600px" }}
                        // width="1000px"
                        id="search-bar"
                        className="text"
                        // onInput={(e) => {
                        //   setSearchQuery(e.target.value);
                        // }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        placeholder="Search by name and contact number"
                        size="small"
                      />
                    </SoftBox> */}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                width: "100%",
                justifyContent: "space-between",
                mt: 2,
                // mb: 1,
              }}
            >
              <SoftBox
                display="flex"
                flexDirection="row"
                // gap="10px"
                justifyContent="space-between"
                width={{ lg: "100%", md: "500px", sm: "300px" }}
              >
                {/*
                <SoftBox
                  sx={{
                    height: "40px",
                    //backgroundColor: "#81BBFF",
                    //borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                // width="117px"
                //color="#fff"
                >

                  <Select
                    options={broadcast_statusList}
                    placeholder="Approach Status List"
                    styles={stylesDD2}
                    //fontWeight="small"
                    //fontSize="14px"
                    //lineHeight="18px"
                    value={
                      filterStatus.approach_status !== ""
                        ? {
                          label: filterStatus.approach_status,
                          value: filterStatus.approach_status,
                        }
                        : null
                    }
                    onChange={(e) => {
                      setFilterStatus({ ...filterStatus, approach_status: e.value });
                      handleShortlistedApi();
                    }}
                  />

                </SoftBox>
                */}

                {/*
                <SoftBox
                  sx={{
                    height: "40px",
                    //backgroundColor: "#81BBFF",
                    //borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                // width="117px"
                //color="#fff"
                >

                  <Select
                    options={WA_statusList}
                    placeholder="Interest Level List"
                    styles={stylesDD2}
                    //fontWeight="small"
                    //fontSize="14px"
                    //lineHeight="18px"
                    value={
                      filterStatus.interest_level !== ""
                        ? {
                          label: filterStatus.interest_level,
                          value: filterStatus.interest_level,
                        }
                        : null
                    }
                    onChange={(e) => {
                      setFilterStatus({ ...filterStatus, interest_level: e.value });
                      handleShortlistedApi();
                    }}
                  />

                </SoftBox>
                */}

                {/*
                <SoftBox
                  sx={{
                    height: "40px",
                    //backgroundColor: "#81BBFF",
                    //borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                // width="117px"
                //color="#fff"
                >

                  <Select
                    options={statusList}
                    placeholder="Hiring Status List"
                    styles={stylesDD2}
                    //fontWeight="small"
                    //fontSize="14px"
                    //lineHeight="18px"
                    value={
                      filterStatus.hiring_status !== ""
                        ? {
                          label: filterStatus.hiring_status,
                          value: filterStatus.hiring_status,
                        }
                        : null
                    }
                    onChange={(e) => {
                      setFilterStatus({ ...filterStatus, hiring_status: e.value });
                      handleShortlistedApi();
                    }}
                  />

                </SoftBox>
                */}
              </SoftBox>
            </Box>

            {/* <Grid container> */}
            <SoftBox mt={2} width="100%" height="auto" display="flex">
              {step1 && (
                <SoftBox
                  // alignItems="flex-start"
                  // position="relative"
                  // borderRadius="10px"
                  width="100%"
                >
                  <Table
                    loading={
                      localStorage_toggle === true && isLoading ? localStorage_toggle : isLoading
                    }
                    // {...tableProps}
                    //  pagination={{position:["bottomCenter"]}}
                    rowSelection={rowSelection}
                    columns={columns2}
                    // dataSource={allData ? data : searchSet ? data1 : newSearchData}
                    dataSource={data
                      ?.filter((ele) => {
                        return newName?.toLocaleLowerCase() === ""
                          ? ele
                          : ele.candidate_name
                            ?.toLocaleLowerCase()
                            .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                            ? ele
                            : ele.display_comment
                              ? ele.display_comment
                                ?.toLocaleLowerCase()
                                .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              : String(ele.phone).includes(String(newName));
                      })
                      .filter((ele) => {
                        return selectedDSF !== ""
                          ? selectedDSF?.split(",").includes(ele.datasource)
                          : ele;
                        // return selectedDSF !== "" &&
                        //   selectedDSF?.split(",").find((ele) => ele === "Channel Partner") ===
                        //     "Channel Partner"
                        //   ? ele.datasource.startsWith("SMR-CP-")
                        //   : selectedDSF?.split(",").length > 1
                        //   ? selectedDSF?.split(",").includes(ele.datasource)
                        //   : ele;
                        // :( selectedDSF?.split(",").length>1 ? (ele.datasource.startsWith("SMR-CP-") &&
                        // selectedDSF?.split(",").includes(ele.datasource)))
                        // ele;
                      })
                      // .filter((ele) => {
                      //   return selectedDSF?.split(",").includes("Channel Partner")
                      //     ? ele.datasource.startsWith("SMR-CP-")
                      //     : ele;
                      // })
                      .filter((ele) => {
                        return broadCast !== "" ? ele.sent_status === broadCast : ele;
                      })
                      .filter((ele) => {
                        return waStatus.toLocaleLowerCase() !== ""
                          ? ele.interest_level === waStatus
                          : ele;
                      })
                      .filter((ele) => {
                        return stList !== "" ? ele.candidate_status === stList : ele;
                      })
                      .filter((ele) => {
                        return counsellorName !== "" ? ele.counsellor_name === counsellorName : ele;
                      })
                      .filter((ele) => {
                        return shortlistDate?.length === 0 || shortlistDate === undefined
                          ? ele
                          : ele.shortlist_date >= shortlistDate[0] &&
                          ele.shortlist_date <= shortlistDate[1];
                      })
                      .filter((ele) => {
                        return broadcastdate?.length === 0 || broadcastdate === undefined
                          ? ele
                          : ele.broadcast_date >= broadcastdate[0] &&
                          ele.broadcast_date <= broadcastdate[1];
                      })
                      .filter((ele) => {
                        return modifiedDate?.length === 0 || modifiedDate === undefined
                          ? ele
                          : ele.modified_on >= modifiedDate[0] &&
                          ele.modified_on <= modifiedDate[1];
                      })
                      .filter((ele) => {
                        return lastDate?.length === 0 || lastDate === undefined
                          ? ele
                          : ele.last_active >= lastDate[0] && ele.last_active <= lastDate[1];
                      })
                      .filter((ele) => {
                        return assignDate?.length === 0 || assignDate === undefined
                          ? ele
                          : ele.assign_date >= assignDate[0] && ele.assign_date <= assignDate[1];
                      })
                      .filter((ele) => {
                        return stateFilter !== ""
                          ? ele.state?.toLocaleLowerCase() === stateFilter?.toLocaleLowerCase()
                          : ele;
                      })
                      .filter((ele) => {
                        return industryfilter !== ""
                          ? industryfilter?.split(",").includes(ele.industry)
                          : ele;
                      })
                      .filter((ele) => {
                        return rolefilter !== ""
                          ? rolefilter?.split(",").includes(ele.job_role)
                          : ele;
                      })
                      .filter((ele) => {
                        return educationfilter !== ""
                          ? educationfilter?.split(",").includes(ele.education)
                          : ele;
                      })
                      .filter((ele) => {
                        return skillfilter !== ""
                          ? skillfilter?.split(",").includes(ele.skills)
                          : ele;
                      })
                      .filter((ele) => {
                        return selfOpp !== "" ? ele.self_opportunity === selfOpp : ele;
                      })
                      .filter((ele) => {
                        return expfilter !== "" ? ele.exp === expfilter : ele;
                      })
                      .filter((ele) => {
                        return salaryfilter !== ""
                          ? salaryfilter?.split(",").includes(ele.salary_req)
                          : ele;
                      })
                      .filter((ele) => {
                        return genderfilter !== ""
                          ? genderfilter?.split(",").includes(ele.gender)
                          : ele;
                      })
                      .filter((ele) => {
                        return scoreft !== ""
                          ? scoreft === "Excellent"
                            ? ele.score === 1
                            : scoreft === "Average"
                              ? ele.score === 2
                              : scoreft === "Less"
                                ? ele.score === 3
                                : ele
                          : ele;
                      })
                      .filter((ele) => {
                        return agefilter?.length === 0 || agefilter === undefined || !useAge
                          ? ele
                          : ele.age >= agefilter[0] && ele.age <= agefilter[1];
                      })
                      .filter((ele) => {
                        return districtFilter !== ""
                          ? districtFilter?.split(",").includes(ele.district)
                          : ele;
                      })}
                    // dataSource={candidateTable}

                    // dataSource={
                    //!searchSet
                    //? //statusFilter
                    //   ? data.filter(
                    //       (ele) =>
                    //         ele.sent_status !== filterStatus.approach_status ||
                    //         ele.broadcast_status !== filterStatus.broadcast_status ||
                    //         ele.completion_status !== filterStatus.completion_status
                    //     )
                    //   :
                    // data
                    //: newSearchData
                    //}
                    pagination={{
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100", "500"],
                      showTotal: (total, range) => (
                        <span>
                          Showing{" "}
                          <strong>
                            {range[0]} - {range[1]}
                          </strong>{" "}
                          resuls of {total}
                        </span>
                      ),
                    }}
                    scroll={{ x: 3500, y: 400 }}
                    style={{ zIndex: "0 !important" }}
                  />
                </SoftBox>
              )}

              {step2 && (
                <SoftBox width="100%">
                  <Table
                    // {...tableProps}
                    //  pagination={{position:["bottomCenter"]}}
                    loading={isLoading}
                    columns={columns}
                    dataSource={shortData
                      ?.filter(
                        (ele) =>
                          ele.broadcast_status === "1" ||
                          ele.broadcast_status === "2" ||
                          ele.broadcast_status === "3"
                      )
                      // .filter((ele) => ele.broadcasted === "1")
                      .map((item, i) => ({
                        ...item,
                        key: item.registration_id,
                        phone: item?.mobile,
                        candidate_name: `${item.first_name} ${item.last_name}`,
                        last_activedate: moment(item.last_activedate).format("DD-MM-YYYY"),
                        modifieddt: moment(item.modifieddt).format("DD-MM-YYYY"),
                        job_title: item.job_title,
                        // rating: i % 2 !== 0 ? "2.8" : "3.8",
                        rating: item.ratings,
                        display_comment: item.display_comment,
                        feedback: item.feedback,
                        resume: item.resume_cv,
                        shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                        wa_status: item.wa_status,
                      }))
                      .filter((ele) => {
                        return newName?.toLocaleLowerCase() === ""
                          ? ele
                          : ele.candidate_name
                            ?.toLocaleLowerCase()
                            .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                            ? ele
                            : ele.display_comment
                              ? ele.display_comment
                                ?.toLocaleLowerCase()
                                .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              : String(ele.phone).includes(String(newName));
                      })
                      .filter((ele) => {
                        return selectedDSF !== ""
                          ? selectedDSF?.split(",").includes(ele.datasource)
                          : ele;
                      })
                      .filter((ele) => {
                        return broadcastdate?.length === 0 || broadcastdate === undefined
                          ? ele
                          : ele.broadcast_date >= broadcastdate[0] &&
                          ele.broadcast_date <= broadcastdate[1];
                      })
                      .filter((ele) => {
                        return shortlistDate?.length === 0 || shortlistDate === undefined
                          ? ele
                          : ele.shortlist_date >= shortlistDate[0] &&
                          ele.shortlist_date <= shortlistDate[1];
                      })
                      .filter((ele) => {
                        return industryfilter !== ""
                          ? industryfilter?.split(",").includes(ele.industry)
                          : ele;
                      })
                      .filter((ele) => {
                        return rolefilter !== ""
                          ? rolefilter?.split(",").includes(ele.job_role)
                          : ele;
                      })
                      .filter((ele) => {
                        return lastDate?.length === 0 || lastDate === undefined
                          ? ele
                          : ele.last_activedate >= lastDate[0] &&
                          ele.last_activedate <= lastDate[1];
                      })
                      .filter((ele) => {
                        return candidateRt !== ""
                          ? candidateRt === "Excellent"
                            ? ele.ratings > 4
                            : candidateRt === "Average"
                              ? ele.ratings < 4 && ele.ratings >= 2.5
                              : candidateRt === "Less"
                                ? ele.ratings < 2.5 && ele.ratings > 0
                                : candidateRt === "Yet to be rated"
                                  ? ele.ratings === 0
                                  : ele
                          : ele;
                      })
                      .filter((ele) => {
                        return scoreft !== ""
                          ? scoreft === "Excellent"
                            ? ele.score === 1
                            : scoreft === "Average"
                              ? ele.score === 2
                              : scoreft === "Less"
                                ? ele.score === 3
                                : ele
                          : ele;
                      })
                      .filter((ele) => {
                        // console.log("ele reposne:", responseStatus);
                        // console.log("optios res:", ele.response, ele.wa_status);
                        return responseStatus !== ""
                          ? responseStatus === "Read"
                            ? ele.wa_status === "read"
                            : responseStatus === "Delivered"
                              ? ele.wa_status === "delivered"
                              : responseStatus === "Interested"
                                ? ele.response === "interested"
                                : responseStatus === "Not Interested"
                                  ? ele.response === "not interested"
                                  : ele
                          : ele;
                      })
                      .filter((ele) => {
                        return stateFilter !== ""
                          ? ele.state?.toLocaleLowerCase() === stateFilter?.toLocaleLowerCase()
                          : ele;
                      })
                      .filter((ele) => {
                        return districtFilter !== ""
                          ? districtFilter?.split(",").includes(ele.district)
                          : ele;
                      })}
                    rowSelection={rowSelection}
                    // dataSource={candidateTable}
                    scroll={{ x: 3000, y: 400 }}
                    style={{ zIndex: "0 !important" }}
                    pagination={{
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100", "500"],
                      showTotal: (total, range) => (
                        <span>
                          Showing{" "}
                          <strong>
                            {range[0]} - {range[1]}
                          </strong>{" "}
                          resuls of {total}
                        </span>
                      ),
                    }}
                  />
                </SoftBox>
              )}

              {step3 && (
                <SoftBox width="100%">
                  <Table
                    // {...tableProps}
                    //  pagination={{position:["bottomCenter"]}}
                    loading={shortData?.length === 0 ? true : false}
                    rowSelection={rowSelection}
                    columns={columns3}
                    dataSource={shortData
                      ?.filter((ele) => ele.counsellor_name !== "")
                      .map((item, i) => ({
                        ...item,
                        key: item.registration_id,
                        // mobile: item.mobile.substring(0),
                        phone: item?.mobile,
                        candidate_name: `${item.first_name} ${item.last_name}`,
                        rating: item.ratings,
                        display_comment: item.display_comment,
                        feedback: item.feedback,
                        shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                        // rating: i % 2 !== 0 ? "2.8" : "3.8",
                      }))
                      .filter((ele) => {
                        return newName?.toLocaleLowerCase() === ""
                          ? ele
                          : ele.candidate_name
                            ?.toLocaleLowerCase()
                            .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                            ? ele
                            : ele.display_comment
                              ? ele.display_comment
                                ?.toLocaleLowerCase()
                                .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              : String(ele.phone).includes(String(newName));
                      })
                      .filter((ele) => {
                        return waStatus !== "" ? ele.broadcast_status === waStatus : ele;
                      })
                      .filter((ele) => {
                        return shortlistDate?.length === 0 || shortlistDate === undefined
                          ? ele
                          : ele.shortlist_date >= shortlistDate[0] &&
                          ele.shortlist_date <= shortlistDate[1];
                      })
                      .filter((ele) => {
                        return broadcastdate?.length === 0 || broadcastdate === undefined
                          ? ele
                          : ele.broadcast_date >= broadcastdate[0] &&
                          ele.broadcast_date <= broadcastdate[1];
                      })
                      .filter((ele) => {
                        return stList !== "" ? ele.candidate_status === stList : ele;
                      })
                      .filter((ele) => {
                        return selectedDSF !== ""
                          ? selectedDSF?.split(",").includes(ele.datasource)
                          : ele;
                      })
                      .filter((ele) => {
                        return counsellorName !== "" ? ele.counsellor_name === counsellorName : ele;
                      })
                      .filter((ele) => {
                        return skillfilter !== ""
                          ? skillfilter?.split(",").includes(ele.skills)
                          : ele;
                      })
                      .filter((ele) => {
                        return selfOpp !== "" ? ele.self_opportunity === selfOpp : ele;
                      })
                      .filter((ele) => {
                        return candidateRt !== ""
                          ? candidateRt === "Excellent"
                            ? ele.ratings > 4
                            : candidateRt === "Average"
                              ? ele.ratings < 4 && ele.ratings >= 2.5
                              : candidateRt === "Less"
                                ? ele.ratings < 2.5 && ele.ratings > 0
                                : candidateRt === "Yet to be rated"
                                  ? ele.ratings === 0
                                  : ele
                          : ele;
                      })
                      .filter((ele) => {
                        return stateFilter !== ""
                          ? ele.state?.toLocaleLowerCase() === stateFilter?.toLocaleLowerCase()
                          : ele;
                      })
                      .filter((ele) => {
                        return districtFilter !== ""
                          ? districtFilter?.split(",").includes(ele.district)
                          : ele;
                      })}
                    // dataSource={candidateTable}
                    scroll={{ x: 3200, y: 400 }}
                    showSizeChanger={true}
                    style={{ zIndex: "0 !important" }}
                    pagination={{
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100", "500"],
                      showTotal: (total, range) => (
                        <span>
                          Showing{" "}
                          <strong>
                            {range[0]} - {range[1]}
                          </strong>{" "}
                          resuls of {total}
                        </span>
                      ),
                    }}
                  />
                </SoftBox>
              )}

              {step4 && (
                <SoftBox width="100%">
                  <Table
                    // {...tableProps}
                    //  pagination={{position:["bottomCenter"]}}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    columns={columns4}
                    dataSource={shortData
                      ?.filter(
                        (ele) =>
                          (ele.broadcast_status === "0" || ele.stage === -1) &&
                          ele.counsellor_name === ""
                      )
                      // .filter(
                      //   (ele) =>
                      //     ele.approach_status === "Not Yet Approached" ||
                      //     ele.approach_status === "Failed" ||
                      //     (ele.broadcast_status === "0" && ele.counsellor_name === "")
                      // )
                      // .filter((ele) =>
                      //   ele.candidate_status
                      //     ?.toLocaleLowerCase()
                      //     .includes("Bot yet to engage".toLocaleLowerCase())
                      // )
                      .map((ele) => ({
                        ...ele,
                        key: ele.registration_id,
                        phone: ele.mobile,
                        // phone: "9892038669",
                        education: ele.highest_education,
                        modified_on: moment(ele.modifieddt).format("DD-MM-YYYY"),
                        skills: ele.skills === null ? ele.job_role : ele.skills,
                        taluka: ele.taluka,
                        state: ele.state,
                        district: ele.district,
                        last_active: moment(ele.last_activedate).format("DD-MM-YYYY"),
                        candidate_name: `${ele.first_name} ${ele.last_name}`,
                        // score: `0`,
                        score: ele.relativity_score,
                        // status: "45",
                        industry: ele.industry,
                        job_role: ele.job_role,
                        broadcast_status: ele.broadcast_status,
                        jobid: ele.jobid,
                        status: ele.wa_status,
                        response: ele.response,
                        job_title: ele.job_title,
                        datasource: ele.datasource,
                        ratings: ele.ratings,
                        salary_req: ele.desired_salary,
                        exp: ele.experience,
                        display_comment: ele.display_comment,
                        feedback: ele.feedback,
                        sent_status: ele.sent_status,
                        completion_status: ele.completion_status,
                        candidate_status: ele.candidate_status,
                        counsellor_name: ele.counsellor_name,
                        shortlist_date: moment(ele.shortlist_date).format("DD-MM-YYYY"),
                        resume: ele.resume_cv,
                        interest_level:
                          ele.broadcast_status === "0"
                            ? "Yet to start"
                            : ele.broadcast_status === "1"
                              ? "In progress"
                              : ele.broadcast_status === "2"
                                ? "Interested"
                                : ele.broadcast_status === "3"
                                  ? "Not interested"
                                  : "Yet to start",
                        age:
                          ele?.date_of_birth === ""
                            ? ""
                            : String(calAge(moment(ele?.date_of_birth).format("DD-MM-YYYY"))) ===
                              "NaN"
                              ? ""
                              : calAge(moment(ele?.date_of_birth).format("DD-MM-YYYY")),
                        // assign_date: moment(ele.assign_date).format("DD-MM-YYYY"),
                        // assign_date: ele.assign_date,
                      }))
                      .filter((ele) => {
                        return newName?.toLocaleLowerCase() === ""
                          ? ele
                          : ele.candidate_name
                            ?.toLocaleLowerCase()
                            .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                            ? ele
                            : ele.display_comment
                              ? ele.display_comment
                                ?.toLocaleLowerCase()
                                .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              : String(ele.phone).includes(String(newName));
                      })
                      .filter((ele) => {
                        return selectedDSF !== ""
                          ? selectedDSF?.split(",").includes(ele.datasource)
                          : ele;
                      })
                      .filter((ele) => {
                        return scoreft !== ""
                          ? scoreft === "Excellent"
                            ? ele.score === 1
                            : scoreft === "Average"
                              ? ele.score === 2
                              : scoreft === "Less"
                                ? ele.score === 3
                                : ele
                          : ele;
                      })
                      .filter((ele) => {
                        return broadCast !== "" ? ele.sent_status === broadCast : ele;
                      })
                      .filter((ele) => {
                        return waStatus !== "" ? ele.interest_level === waStatus : ele;
                      })
                      .filter((ele) => {
                        return stList !== "" ? ele.candidate_status === stList : ele;
                      })
                      .filter((ele) => {
                        return counsellorName !== "" ? ele.counsellor_name === counsellorName : ele;
                      })
                      .filter((ele) => {
                        return shortlistDate?.length === 0 || shortlistDate === undefined
                          ? ele
                          : ele.shortlist_date >= shortlistDate[0] &&
                          ele.shortlist_date <= shortlistDate[1];
                      })
                      .filter((ele) => {
                        return broadcastdate?.length === 0 || broadcastdate === undefined
                          ? ele
                          : ele.broadcast_date >= broadcastdate[0] &&
                          ele.broadcast_date <= broadcastdate[1];
                      })
                      .filter((ele) => {
                        return modifiedDate?.length === 0 || modifiedDate === undefined
                          ? ele
                          : ele.modified_on >= modifiedDate[0] &&
                          ele.modified_on <= modifiedDate[1];
                      })
                      .filter((ele) => {
                        return lastDate?.length === 0 || lastDate === undefined
                          ? ele
                          : ele.last_active >= lastDate[0] && ele.last_active <= lastDate[1];
                      })
                      .filter((ele) => {
                        return assignDate?.length === 0 || assignDate === undefined
                          ? ele
                          : ele.assign_date >= assignDate[0] && ele.assign_date <= assignDate[1];
                      })
                      .filter((ele) => {
                        return industryfilter !== ""
                          ? industryfilter?.split(",").includes(ele.industry)
                          : ele;
                      })
                      .filter((ele) => {
                        return rolefilter !== ""
                          ? rolefilter?.split(",").includes(ele.job_role)
                          : ele;
                      })
                      .filter((ele) => {
                        return educationfilter !== ""
                          ? educationfilter?.split(",").includes(ele.education)
                          : ele;
                      })
                      .filter((ele) => {
                        return skillfilter !== ""
                          ? skillfilter?.split(",").includes(ele.skills)
                          : ele;
                      })
                      .filter((ele) => {
                        return selfOpp !== "" ? ele.self_opportunity === selfOpp : ele;
                      })
                      .filter((ele) => {
                        return expfilter !== "" ? ele.exp === expfilter : ele;
                      })
                      .filter((ele) => {
                        return salaryfilter !== ""
                          ? salaryfilter?.split(",").includes(ele.salary_req)
                          : ele;
                      })
                      .filter((ele) => {
                        return genderfilter !== ""
                          ? genderfilter?.split(",").includes(ele.gender)
                          : ele;
                      })
                      .filter((ele) => {
                        return agefilter?.length === 0 || agefilter === undefined || !useAge
                          ? ele
                          : ele.age >= agefilter[0] && ele.age <= agefilter[1];
                      })
                      .filter((ele) => {
                        return stateFilter !== ""
                          ? ele.state?.toLocaleLowerCase() === stateFilter?.toLocaleLowerCase()
                          : ele;
                      })
                      .filter((ele) => {
                        return districtFilter !== ""
                          ? districtFilter?.split(",").includes(ele.district)
                          : ele;
                      })}
                    // dataSource={candidateTable}
                    scroll={{ x: 3500, y: 400 }}
                    showSizeChanger={true}
                    style={{ zIndex: "0 !important" }}
                    pagination={{
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100", "500"],
                      showTotal: (total, range) => (
                        <span>
                          Showing{" "}
                          <strong>
                            {range[0]} - {range[1]}
                          </strong>{" "}
                          resuls of {total}
                        </span>
                      ),
                    }}
                  />
                </SoftBox>
              )}
            </SoftBox>
            {/* </Grid> */}
          </SoftBox>
        </SoftBox>

        <Index
          open={showFilter}
          setOpen={setShowFilter}
          broadCast={broadCast}
          setBroadCast={setBroadCast}
          waStatus={waStatus}
          setWaStatus={setWaStatus}
          stList={stList}
          setStList={setStList}
          searchFunc={searchFunc}
          resetfunc={resetfunc}
          cosList1={cosList1}
          setConsList1={setConsList1}
          counsellorName={counsellorName}
          setCounsellorName={setCounsellorName}
          selectedDSF={selectedDSF}
          setSelectedDSF={setSelectedDSF}
          shortlistDate={shortlistDate}
          setShortlistDate={setShortlistDate}
          broadcastdate={broadcastdate}
          setBroadcastdate={setBroadcastdate}
          scoreft={scoreft}
          setScoreft={setScoreft}
          step={step}
          setStep={setStep}
          candidateRt={candidateRt}
          setCandidateRt={setCandidateRt}
          responseStatus={responseStatus}
          setResponseStatus={setResponseStatus}
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
          districtFilter={districtFilter}
          setDistrictFilter={setDistrictFilter}
          industryfilter={industryfilter}
          setIndustryfilter={setIndustryfilter}
          rolefilter={rolefilter}
          setRolefilter={setRolefilter}
          educationfilter={educationfilter}
          setEducationfilter={setEducationfilter}
          skillfilter={skillfilter}
          setSkillfilter={setSkillfilter}
          salaryfilter={salaryfilter}
          setSalaryfilter={setSalaryfilter}
          expfilter={expfilter}
          setExpfilter={setExpfilter}
          agefilter={agefilter}
          setAgefilter={setAgefilter}
          genderfilter={genderfilter}
          setGenderfilter={setGenderfilter}
          modifiedDate={modifiedDate}
          setModifiedDate={setModifiedDate}
          lastDate={lastDate}
          setLastDate={setLastDate}
          assignDate={assignDate}
          setAssignDate={setAssignDate}
          useAge={useAge}
          setUseAge={setUseAge}
          selfOpp={selfOpp}
          setSelfOpp={setSelfOpp}
        />
      </DashboardLayout>



      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
          setShowEditModal(false);
          setPrevState({
            ...prevState,
            first_call: "",
            call_status: "",
            job_status: "",
            industry: "",
            ready_resume: "",
            next_step_status: "",
            suggested_job_bucket: "",
            display_comment: "",
            is_disabled: "",
            jp_status: "",
            interview_status: "",
            interview_date: "",
            selection_status: "",
            joining_status: "",
            retention_status: "",
            feedback: "",
          });
        }}
        onClose={() =>
          setPrevState({
            ...prevState,
            first_call: "",
            call_status: "",
            job_status: "",
            industry: "",
            ready_resume: "",
            next_step_status: "",
            suggested_job_bucket: "",
            display_comment: "",
            is_disabled: "",
            jp_status: "",
            interview_status: "",
            interview_date: "",
            selection_status: "",
            joining_status: "",
            retention_status: "",
            feedback: "",
          })
        }
        centered
        size="xl"
        closeButton
        contentClassName="modal-height"
      >
        <Modal.Body>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "end", width: "100%", mt: -1 }}>
              <SoftBox
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => (
                  setOpenModal(false),
                  setBucketOption(false),
                  handleShortlistedApi(newOrgId, newCourseId)
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.16992 14.8299L14.8299 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.8299 14.8299L9.16992 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SoftBox>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                p: 1,
                pt: 0,
                mt: 0,
                background: "#FFFFFF",
              }}
            >
              <Grid container spacing={2}>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box sx={{ width: "100%", m: 1 }}>
                        {isLoadingCandData ? (
                          <Box
                            sx={{
                              p: 2,
                              height: "300px",
                              width: "100%",
                              border: "1px solid #979A9A",
                              borderRadius: "8px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FadeLoader
                              color="#B3B6B7"
                              height={12}
                              width={5}
                              radius={2}
                              margin={2}
                            />
                          </Box>
                        ) : (
                          <SoftBox width="100%">
                            <SoftBox
                              width="100%"
                              sx={{
                                p: 1,
                                // height: "50%",
                                border: "1px solid #979A9A",
                                borderRadius: "8px",
                                // cursor: "pointer",
                              }}
                            >
                              <SoftBox
                                sx={{
                                  borderBottom: 1,
                                  borderColor: "grey.300",
                                  pb: 1,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexWrap: { xs: "wrap", md: "no-wrap" },
                                }}
                                alignItems="center"
                              //onClick={() => setInputId(100)}
                              >
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                // onClick={() => setInputId(100)}
                                >
                                  <SoftBox display="flex" gap="5px">
                                    <SoftTypography
                                      fontWeight="400"
                                      fontSize="15px"
                                      lineHeight="30px"
                                    >
                                      Name:
                                    </SoftTypography>
                                    <SoftBox
                                      display="flex"
                                      justifyContent="space-around"
                                      alignItems="center"
                                    >
                                      <SoftTypography fontSize="15px" sx={{ fontWeight: 500 }}>
                                        {candData?.firstname ? candData?.firstname : "-"}{" "}
                                        {candData?.lastname ? candData?.lastname : ""}
                                      </SoftTypography>
                                      &nbsp;&nbsp;
                                      {/* <SoftBadge>Walk-in</SoftBadge> */}
                                    </SoftBox>
                                  </SoftBox>
                                  {/* <SoftSelect /> */}
                                </SoftBox>

                                {candData?.resume_cv !== null ? (
                                  <SoftBox bgColor="#9747FF" px={1} sx={{ borderRadius: 1 }}>
                                    <a href={candData?.resume} target="_blank" rel="noreferrer">
                                      <ArticleIcon color="white" />
                                      <Button>
                                        <SoftTypography
                                          bgColor="#9747FF"
                                          color="white"
                                          fontSize="15px"
                                        >
                                          Resume
                                        </SoftTypography>
                                      </Button>
                                    </a>
                                  </SoftBox>
                                ) : (
                                  <SoftBox bgColor="#9747FF" px={1} sx={{ borderRadius: 1 }}>
                                    <ArticleIcon color="white" />
                                    <Button disabled>
                                      <SoftTypography
                                        bgColor="#dfc7ff"
                                        color="white"
                                        fontSize="15px"
                                      >
                                        Resume
                                      </SoftTypography>
                                    </Button>
                                  </SoftBox>
                                )}

                                <SoftBox
                                  // width="100%"
                                  display="flex"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                  pt={0}
                                  pb={0}
                                  pr={2}
                                >
                                  {showEditModal ? (
                                    <SoftBox height="12px" width="12px" />
                                  ) : (
                                    <SoftBox>
                                      <img
                                        src={Edit}
                                        height="20px"
                                        width="20px"
                                        style={{ marginTop: "7px", cursor: "pointer" }}
                                        onClick={() => {
                                          setShowEditModal(true);
                                          setOpenModal(false);
                                          // console.log("4444", veridetails)
                                        }}
                                      />

                                      <SoftTypography fontSize="10px">Edit</SoftTypography>
                                    </SoftBox>
                                  )}
                                </SoftBox>
                              </SoftBox>

                              <Box sx={{ pb: 1, width: "100%", height: "100%" }}>
                                <Grid container>
                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          <MailOutlineIcon />
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {candData?.email !== "" ? candData.email : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          <LocalPhoneIcon />
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {candData?.mobnum !== "" ? candData.mobnum : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          DOB:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.date_of_birth !== ""
                                            ? moment(veridetails.date_of_birth).format("DD-MM-YYYY")
                                            : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Qualification:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.highest_education !== ""
                                            ? veridetails?.highest_education
                                            : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          State:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.state !== "" ? veridetails.state : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          District:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.district !== ""
                                            ? veridetails.district
                                            : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Taluka:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.taluka !== "" ? veridetails?.taluka : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Skills:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {candData?.skills !== null
                                            ? candData?.skills
                                            : "Unavailable"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Experience:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.experience}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Data Source:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.dataSource !== ""
                                            ? veridetails?.dataSource
                                            : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Desired Role:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.job_role !== ""
                                            ? veridetails.job_role
                                            : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Current Salary:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.current_salary !== ""
                                            ? veridetails?.current_salary
                                            : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Company:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {candData?.industry !== "" ? candData.industry : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Bot Nurturing Done:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          No
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Modified on:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.modified !== ""
                                            ? veridetails.modified
                                            : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>

                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SoftBox sx={{ width: "145px" }}>
                                        <SoftTypography fontSize="15px" lineHeight="30px">
                                          Last Active:
                                        </SoftTypography>
                                      </SoftBox>

                                      <SoftBox>
                                        <SoftTypography
                                          sx={{ fontWeight: 500 }}
                                          fontSize="15px"
                                          lineHeight="30px"
                                        >
                                          {veridetails?.lastactive !== ""
                                            ? veridetails.lastactive
                                            : "-"}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </SoftBox>
                          </SoftBox>
                        )}
                      </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ height: "100%" }}>
                      <Box
                        sx={{
                          m: 1,
                          width: "100%",
                          backgroundColor: "#FFF",
                          border: "1px solid #979A9A",
                          borderRadius: "8px",
                          cursor: "pointer",
                          height: "100%",
                        }}
                      >
                        <Alltabs
                          time={time}
                          jobId={searchParams.get("org_id")}
                          orgId={candData?.course_id}
                          setTime={setTime}
                          candData={candData}
                          veridetails={veridetails}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          HandleFetchCandDetails={HandleFetchCandDetails}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      m: 1,
                      p: 2,
                      width: "100%",
                      backgroundColor: "#FFF",
                      border: "1px solid #979A9A",
                      borderRadius: "8px",
                      cursor: "pointer",
                      minHeight: "608px",
                    }}
                  >
                    {isLoadingCandData ? (
                      <SoftBox
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "50px",
                        }}
                      >
                        <FadeLoader
                          color="#B3B6B7"
                          height={12}
                          width={5}
                          radius={2}
                          margin={10}
                        // style={{ display: "flex", justifyContent: "center" }}
                        />
                      </SoftBox>
                    ) : (
                      <Box sx={{ height: "100%" }}>
                        <SoftBox display="flex" justifyContent="space-between">
                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            // backgroundColor="#"
                            // borderRadius="50px"
                            width="174px"
                            height="40px"
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "50px",
                              color: "#3B5092",
                              border: "1px solid #3B5092",
                            }}
                            onClick={() => ResetTimeline()}
                          >
                            <SoftTypography
                              fontWeight="400"
                              fontSize="13px"
                              lineHeight="19px"
                              color="#3B5092"
                            >
                              {"Reset Status"}
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="174px"
                            height="40px"
                            sx={{ backgroundColor: "#3B5092", borderRadius: "50px", color: "#fff" }}
                            onClick={() => {
                              setShowModal1(true);
                            }}
                          >
                            <SoftTypography
                              fontWeight="400"
                              fontSize="13px"
                              lineHeight="19px"
                              color="#fff"
                            >
                              {"Update Previous Status"}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <br />

                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="left"
                          sx={{
                            // height: "100%",
                            backgroundColor: "#FFF",
                            borderBottom: 1,
                            borderColor: "grey.300",
                            // borderRight: "1px solid black",
                            // borderRadius: "2px",
                            // padding: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <SoftTypography>
                            {candData.stage === -1 ? "Application Details" : head[`${candData.stage}`]}
                          </SoftTypography>
                        </SoftBox>
                        <br />
                        <Box sx={{ height: "435px", overflow: "auto",p:1 }}>
                          {candData.stage === 5
                            ?
                            candData.stage_values
                              ?.filter((ee) => ee.key === 4)
                              ?.map((ele, i) => (
                                <>
                                  <SoftBox>

                                    <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                      {ele.name}
                                    </SoftTypography>
                                    {ele.datatype === "datetime" ? (
                                      <SoftBox width="100%">
                                        <SoftInput
                                          type="date"
                                          value={ele.value}
                                          // minDate={`${new Date().toISOString().split("T")[0]}`}
                                          onChange={(e) => {
                                            handleChangeStatus(e.target.value, ele);
                                          }}
                                        />
                                      </SoftBox>
                                    ) : ele.datatype === "input" ? (
                                      <SoftBox width="100%" sx={{ display: "flex", }}>
                                        <SoftInput
                                          type="text"

                                          //defaultValue={ele.value}
                                          value={stored2}
                                          // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                                          // minDate={
                                          //   new Date().getFullYear() +
                                          //   "-" +
                                          //   parseInt(new Date().getMonth() + 1) +
                                          //   "-" +
                                          //   new Date().getDate()
                                          // }
                                          // minDate={`${new Date().toISOString().split("T")[0]}`}
                                          onChange={(e) => {
                                            setStored2(e.target.value);
                                            //handleFormChange111(e, i);
                                          }}
                                        />&nbsp;
                                        {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus1(ele)}>
                                    Save
                                  </button> */}
                                        <SoftBox sx={{ cursor: "pointer" }} onClick={()=>handleChangeStatus1({ ...ele, value: stored2 })}>
                                          <img src={saveimg} />
                                        </SoftBox>
                                      </SoftBox>
                                    ) : (
                                      <Select
                                        options={ele.options.map((item) => ({
                                          label: item,
                                          value: item,
                                        }))}
                                        // isDisabled={candData.job_editable ? true : false}
                                        // value={
                                        //   candData.job_status !== "" &&
                                        //   candData.job_status === "Actively looking for job"
                                        //     ? { label: candData.job_status, value: candData.job_status }
                                        //     : candData.job_status !== "Actively looking for job"
                                        //     ? { label: stage1.job_status, value: stage1.job_status }
                                        //     : null
                                        // }
                                        value={
                                          ele.value !== ""
                                            ? { label: ele.value, value: ele.value }
                                            : null
                                        }
                                        onChange={(e) =>
                                          // setStage1({ ...stage1, job_status: e.value }),
                                          // e.value === "Actively looking for job" && setShowJobPost(true),
                                          handleChangeStatus(e.value, ele)
                                        }
                                        styles={stylesDD}
                                      />
                                    )}
                                  </SoftBox>
                                </>
                              )
                              )
                            :
                            candData.stage === -1
                              ? candData.stage_values?.filter((ee) => ee.key === 0)
                                ?.map((ele, i) => (
                                  <>
                                    <SoftBox>
                                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                        {ele.name}
                                      </SoftTypography>
                                      {ele.datatype === "datetime" ? (
                                        <SoftBox width="100%">
                                          <SoftInput
                                            type="date"
                                            value={ele.value}
                                            // minDate={`${new Date().toISOString().split("T")[0]}`}
                                            onChange={(e) => {
                                              handleChangeStatus(e.target.value, ele);
                                            }}
                                          />
                                        </SoftBox>
                                      ) : ele.datatype === "input" ? (
                                        <SoftBox width="100%" sx={{ display: "flex", }}>
                                          <SoftInput
                                            type="text"

                                            value={ele.value}
                                            // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                                            // minDate={
                                            //   new Date().getFullYear() +
                                            //   "-" +
                                            //   parseInt(new Date().getMonth() + 1) +
                                            //   "-" +
                                            //   new Date().getDate()
                                            // }
                                            // minDate={`${new Date().toISOString().split("T")[0]}`}
                                            onChange={(e) => {
                                              setStored(e.target.value);
                                              handleFormChange111(e, i);
                                            }}
                                          />&nbsp;
                                          {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus1(ele)}>
                                      Save
                                    </button> */}
                                          <SoftBox sx={{ cursor: "pointer" }} onClick={()=>handleChangeStatus1(ele)}>
                                            <img src={saveimg} />
                                          </SoftBox>
                                        </SoftBox>
                                      ) : (
                                        <Select
                                          options={ele.options.map((item) => ({
                                            label: item,
                                            value: item,
                                          }))}
                                          // isDisabled={candData.job_editable ? true : false}
                                          // value={
                                          //   candData.job_status !== "" &&
                                          //   candData.job_status === "Actively looking for job"
                                          //     ? { label: candData.job_status, value: candData.job_status }
                                          //     : candData.job_status !== "Actively looking for job"
                                          //     ? { label: stage1.job_status, value: stage1.job_status }
                                          //     : null
                                          // }
                                          value={
                                            ele.value !== ""
                                              ? { label: ele.value, value: ele.value }
                                              : null
                                          }
                                          onChange={(e) => {
                                            // setStage1({ ...stage1, job_status: e.value }),
                                            // e.value === "Actively looking for job" && setShowJobPost(true),
                                            handleChangeStatus(e.value, ele);
                                            // HandleFetchCrm();
                                          }}
                                          styles={stylesDD}
                                        />
                                      )}
                                    </SoftBox>
                                  </>
                                ))
                              : candData.stage_values
                                ?.filter((ee) => ee.key === candData.stage)
                                ?.map((ele, i) => (
                                  <>
                                    <SoftBox>
                                      <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                                        {ele.name}
                                      </SoftTypography>
                                      {ele.datatype === "datetime" ? (
                                        <SoftBox width="100%">
                                          <SoftInput
                                            type="date"
                                            value={ele.value}
                                            // minDate={`${new Date().toISOString().split("T")[0]}`}
                                            onChange={(e) => {
                                              handleChangeStatus(e.target.value, ele);
                                            }}
                                          />
                                        </SoftBox>
                                      ) : ele.datatype === "input" ? (
                                        <SoftBox width="100%" sx={{ display: "flex", }}>
                                          <SoftInput
                                            type="text"
                                            value={ele.value}
                                            // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                                            // minDate={
                                            //   new Date().getFullYear() +
                                            //   "-" +
                                            //   parseInt(new Date().getMonth() + 1) +
                                            //   "-" +
                                            //   new Date().getDate()
                                            // }
                                            // minDate={`${new Date().toISOString().split("T")[0]}`}

                                            onChange={(e) => {
                                              setStored(e.target.value, ele);
                                              handleFormChange111(e, i)

                                            }}

                                          />
                                          &nbsp;
                                          {/* <button style={{ height: "40px", backgroundColor: "#3b5092", color: "white", borderRadius: "5px" }} onClick={() => handleChangeStatus(stored, ele)}>Save</button> */}
                                          <SoftBox sx={{ cursor: "pointer" }}  onClick={()=>handleChangeStatus(stored,ele)}>
                                            <img src={saveimg} />
                                          </SoftBox>
                                        </SoftBox>
                                      ) : (
                                        <Select
                                          options={ele.options.map((item) => ({
                                            label: item,
                                            value: item,
                                          }))}
                                          // isDisabled={candData.job_editable ? true : false}
                                          // value={
                                          //   candData.job_status !== "" &&
                                          //   candData.job_status === "Actively looking for job"
                                          //     ? { label: candData.job_status, value: candData.job_status }
                                          //     : candData.job_status !== "Actively looking for job"
                                          //     ? { label: stage1.job_status, value: stage1.job_status }
                                          //     : null
                                          // }
                                          value={
                                            ele.value !== ""
                                              ? { label: ele.value, value: ele.value }
                                              : null
                                          }


                                          onChange={(e) =>
                                            // setStage1({ ...stage1, job_status: e.value }),
                                            // e.value === "Actively looking for job" && setShowJobPost(true),
                                            handleChangeStatus(e.value, ele)
                                          }
                                          styles={stylesDD}
                                        />
                                      )}
                                    </SoftBox>
                                  </>
                                ))
                          }
                        </Box>
                        <br />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
          setOpenModal(true);
        }}
        size="lg"
        centered
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Update Details
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                setShowEditModal(false);
                setOpenModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2}
            component="form"
            role="form"
            onSubmit={updateDetails.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  First Name
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="firstnm"
                  name="firstnm"
                  onChange={updateDetails.handleChange}
                  value={updateDetails.values.firstnm}
                // value={veridetails?.first_name}
                // onChange={(e) => setVeridetails({ ...veridetails, first_name: e.target.value })}
                />
                {updateDetails.touched.firstnm && updateDetails.errors.firstnm ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {updateDetails.errors.firstnm}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Last Name
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="lastnm"
                  name="lastnm"
                  onChange={updateDetails.handleChange}
                  value={updateDetails.values.lastnm}
                // value={veridetails?.last_name}
                // onChange={(e) => setVeridetails({ ...veridetails, last_name: e.target.value })}
                />
                {updateDetails.touched.lastnm && updateDetails.errors.lastnm ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {updateDetails.errors.lastnm}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Email
                </SoftTypography>

                <SoftInput
                  type="email"
                  placeholder=""
                  id="email"
                  name="email"
                  onChange={updateDetails.handleChange}
                  value={updateDetails.values.email}
                // value={veridetails?.email}
                // onChange={(e) => setVeridetails({ ...veridetails, email: e.target.value })}
                />
                {updateDetails.touched.email && updateDetails.errors.email ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {updateDetails.errors.email}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Phone
                </SoftTypography>

                <SoftInput
                  type="tel"
                  // sx={{
                  //   inputProps: {
                  //     maxLength: "10 !important",
                  //   },
                  // }}
                  // maxLength={10}
                  inputProps={{ maxLength: 10 }}
                  placeholder=""
                  id="mobnum"
                  name="mobnum"
                  onChange={updateDetails.handleChange}
                  value={
                    updateDetails.values.mobnum.length === 12
                      ? updateDetails.values.mobnum.substring(2)
                      : updateDetails.values.mobnum
                  }
                // value={
                //   veridetails?.mobile?.length == 12
                //     ? veridetails?.mobile.substring(2)
                //     : veridetails?.mobile
                // }
                // value={editContact}
                // onChange={(e) => setVeridetails({ ...veridetails, mobile: e.target.value })}
                // onChange={(e)=>setEditContact(e.target.value)}
                />
                {updateDetails.touched.mobnum && updateDetails.errors.mobnum ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {updateDetails.errors.mobnum}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  D.O.B
                </SoftTypography>

                <SoftInput
                  type="date"
                  placeholder=""
                  id="date_of_birth"
                  name="date_of_birth"
                  onChange={updateDetails.handleChange}
                  value={updateDetails.values.date_of_birth}
                // value={
                //   veridetails?.date_of_birth !== null ? veridetails.date_of_birth : ""
                //   // : moment(veridetails.date_of_birth).format("DD-MM-YYYY")
                // }
                // onChange={(e) =>
                //   setVeridetails({ ...veridetails, date_of_birth: e.target.value })
                // }
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Qualification
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={education}
                  // value={{
                  //   label: veridetails?.highest_education,
                  //   value: veridetails?.highest_education,
                  // }}
                  // onChange={(e) =>
                  //   setVeridetails({ ...veridetails, highest_education: e ? e.value : "" })
                  // }
                  onChange={(e) =>
                    updateDetails.setFieldValue("highest_education", e.value)
                  }
                  value={{ label: updateDetails.values.highest_education }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Current Salary
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={salaryList}
                  // value={{ label: veridetails?.current_salary, value: veridetails?.current_salary }}
                  // onChange={(e) =>
                  //   setVeridetails({ ...veridetails, current_salary: e ? e.value : "" })
                  // }
                  onChange={(e) =>
                    updateDetails.setFieldValue("current_salary", e.value)
                  }
                  value={{ label: updateDetails.values.current_salary }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Desired Role
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={jobRole}
                  // value={{ label: veridetails?.job_role, value: veridetails?.job_role }}
                  // onChange={(e) => setVeridetails({ ...veridetails, : e ? e.value : "" })}
                  onChange={(e) =>
                    updateDetails.setFieldValue("job_role", e.value)
                  }
                  value={{ label: updateDetails.values.job_role }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Skills
                </SoftTypography>

                <Select
                  isClearable
                  isMulti
                  placeholder="Search...."
                  value={
                    updateDetails.values.skills === "" ||
                      updateDetails.values.skills === null ||
                      updateDetails.values.skills === "nan"
                      ? multiSkill
                      : updateDetails.values.skills?.split(",").map(
                        (ele) =>
                          (ele !== "null" || ele !== null || ele !== "nan") && {
                            label: ele,
                            value: ele,
                          }
                      )
                  }
                  options={skillsOption}
                  // onChange={(e) => (e ? setSalary(e.value) : setSalary(""))}
                  onChange={(e) => {
                    if (e) {
                      setMultiSkill(e.map((item) => item));
                    } else {
                      setMultiSkill([]);
                    }
                    // setVeridetails({
                    //   ...veridetails,
                    //   skills: e
                    //     .map((ele) => ele)
                    //     .map((item) => item.value)
                    //     .join(),
                    // });
                    // updateDetails.values.skills.map((e)=>e).map((item)=> item.value).join()
                    console.log("canii", updateDetails.values.skills)
                    updateDetails.setFieldValue("skills", e.map((el) => el.value).join())
                  }}
                  //  value={{ label: updateDetails.values.skills }}
                  // onChange={(e) =>
                  //   console.log("ee", e)
                  // updateDetails.setFieldValue("skills", e.value)
                  //  }
                  styles={stylesDD}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Experience
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={exp}
                  // value={{ label: veridetails?.experience, value: veridetails?.experience }}
                  // onChange={(e) => setVeridetails({ ...veridetails, experience: e ? e.value : "" })}
                  onChange={(e) =>
                    updateDetails.setFieldValue("experience", e.value)
                  }
                  value={{ label: updateDetails.values.experience }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Data Source
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={JSFilterDisplay}
                  // value={{ label: veridetails?.dataSource, value: veridetails?.dataSource }}
                  // onChange={(e) => setVeridetails({ ...veridetails, dataSource: e ? e.value : "" })}
                  onChange={(e) =>
                    updateDetails.setFieldValue("datasource", e.value)
                  }
                  value={{ label: updateDetails.values.datasource }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  State
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  // options={state}
                  options={state}
                  // value={{ label: veridetails?.state, value: veridetails?.state }}
                  value={{ label: updateDetails.values.state }}
                  onChange={(e) => {
                    // setVeridetails({ ...veridetails, state: e ? e.value : "" });
                    setSelectedState(e ? e.value : "");
                    updateDetails.setFieldValue("state", e.value)
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  District
                </SoftTypography>

                <Select
                  styles={stylesDD}
                  options={district}
                  // value={{ label: veridetails?.district, value: veridetails?.district }}
                  // onChange={(e) => setVeridetails({ ...veridetails, district: e ? e.value : "" })}
                  onChange={(e) =>
                    updateDetails.setFieldValue("district", e.value)
                  }
                  value={{ label: updateDetails.values.district }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftTypography fontWeight="medium" fontSize="15px">
                  Taluka
                </SoftTypography>

                <SoftInput
                  type="text"
                  placeholder=""
                  id="taluka"
                  name="taluka"
                  onChange={(e) =>
                    updateDetails.setFieldValue("taluka", e.value)
                  }
                  value={updateDetails.values.taluka}
                // value={veridetails?.taluka}
                // onChange={(e) => setVeridetails({ ...veridetails, taluka: e.target.value })}
                />
              </Grid>
            </Grid>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 1 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   handleVerifyDetailsNew();
                // }}
                onClick={updateDetails.handleSubmit}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Submit
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowEditModal(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Cancel
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
      >
        <Modal.Body>
          <SoftBox sx={{ m: 0 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p className="HeaderCSV">Select columns to export</p>

              <button className="CloseBtn" onClick={() => setShow(false)}>
                <span>
                  <CloseIcon fontSize="small" />
                </span>
              </button>
            </Box>

            <Box sx={{ height: "470px", width: "100%", overflow: "auto", pt: 1 }}>
              {colHeaders.map((ele, index) => (
                <>
                  <ul key={index} className="UlCSV">
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="login-btn4" onClick={() => setShow(false)}>
                <span className="login-btn4-Text">Cancel</span>
              </button>

              <button className="login-btn3">
                {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                <CSVLink
                  data={
                    // step1
                    tablerow.length === 0
                      ? data
                        ?.filter((ele) => {
                          return newName?.toLocaleLowerCase() === ""
                            ? ele
                            : ele.candidate_name
                              ?.toLocaleLowerCase()
                              .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              ? ele
                              : ele.display_comment
                                ? ele.display_comment
                                  ?.toLocaleLowerCase()
                                  .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                                : String(ele.phone).includes(String(newName));
                        })
                        .filter((ele) => {
                          return selectedDSF !== ""
                            ? selectedDSF?.split(",").includes(ele.datasource)
                            : ele;
                          // return selectedDSF !== "" &&
                          //   selectedDSF?.split(",").find((ele) => ele === "Channel Partner") ===
                          //     "Channel Partner"
                          //   ? ele.datasource.startsWith("SMR-CP-")
                          //   : selectedDSF?.split(",").length > 1
                          //   ? selectedDSF?.split(",").includes(ele.datasource)
                          //   : ele;
                          // :( selectedDSF?.split(",").length>1 ? (ele.datasource.startsWith("SMR-CP-") &&
                          // selectedDSF?.split(",").includes(ele.datasource)))
                          // ele;
                        })
                        // .filter((ele) => {
                        //   return selectedDSF?.split(",").includes("Channel Partner")
                        //     ? ele.datasource.startsWith("SMR-CP-")
                        //     : ele;
                        // })
                        .filter((ele) => {
                          return broadCast !== "" ? ele.sent_status === broadCast : ele;
                        })
                        .filter((ele) => {
                          return waStatus.toLocaleLowerCase() !== ""
                            ? ele.interest_level === waStatus
                            : ele;
                        })
                        .filter((ele) => {
                          return stList !== "" ? ele.candidate_status === stList : ele;
                        })
                        .filter((ele) => {
                          return counsellorName !== ""
                            ? ele.counsellor_name === counsellorName
                            : ele;
                        })
                        .filter((ele) => {
                          return shortlistDate?.length === 0 || shortlistDate === undefined
                            ? ele
                            : ele.shortlist_date >= shortlistDate[0] &&
                            ele.shortlist_date <= shortlistDate[1];
                        })
                        .filter((ele) => {
                          return broadcastdate?.length === 0 || broadcastdate === undefined
                            ? ele
                            : ele.broadcast_date >= broadcastdate[0] &&
                            ele.broadcast_date <= broadcastdate[1];
                        })
                        .filter((ele) => {
                          return modifiedDate?.length === 0 || modifiedDate === undefined
                            ? ele
                            : ele.modified_on >= modifiedDate[0] &&
                            ele.modified_on <= modifiedDate[1];
                        })
                        .filter((ele) => {
                          return lastDate?.length === 0 || lastDate === undefined
                            ? ele
                            : ele.last_active >= lastDate[0] && ele.last_active <= lastDate[1];
                        })
                        .filter((ele) => {
                          return assignDate?.length === 0 || assignDate === undefined
                            ? ele
                            : ele.assign_date >= assignDate[0] &&
                            ele.assign_date <= assignDate[1];
                        })
                        .filter((ele) => {
                          return industryfilter !== ""
                            ? industryfilter?.split(",").includes(ele.industry)
                            : ele;
                        })
                        .filter((ele) => {
                          return rolefilter !== ""
                            ? rolefilter?.split(",").includes(ele.job_role)
                            : ele;
                        })
                        .filter((ele) => {
                          return educationfilter !== ""
                            ? educationfilter?.split(",").includes(ele.education)
                            : ele;
                        })
                        .filter((ele) => {
                          return skillfilter !== ""
                            ? skillfilter?.split(",").includes(ele.skills)
                            : ele;
                        })
                        .filter((ele) => {
                          return expfilter !== "" ? ele.exp === expfilter : ele;
                        })
                        .filter((ele) => {
                          return salaryfilter !== ""
                            ? salaryfilter?.split(",").includes(ele.salary_req)
                            : ele;
                        })
                        .filter((ele) => {
                          return genderfilter !== ""
                            ? genderfilter?.split(",").includes(ele.gender)
                            : ele;
                        })
                        .filter((ele) => {
                          return agefilter?.length === 0 || agefilter === undefined
                            ? ele
                            : ele.age >= agefilter[0] && ele.age <= agefilter[1];
                        })
                        .filter((ele) => {
                          return scoreft !== ""
                            ? scoreft === "Excellent"
                              ? ele.score === 1
                              : scoreft === "Average"
                                ? ele.score === 2
                                : scoreft === "Less"
                                  ? ele.score === 3
                                  : ele
                            : ele;
                        })
                        .filter((ele) => {
                          return stateFilter !== ""
                            ? ele.state?.toLocaleLowerCase() === stateFilter?.toLocaleLowerCase()
                            : ele;
                        })
                        .filter((ele) => {
                          return districtFilter !== ""
                            ? districtFilter?.split(",").includes(ele.district)
                            : ele;
                        })
                      : tablerow
                  }
                  // filename={"my-file.csv"}
                  filename={fileName}
                  headers={colHeaders.filter((ele) => ele.isExportable === true)}
                >
                  <span className="login-btn3-Text">Export</span>
                </CSVLink>
              </button>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={() => {
          setShow1(false);
        }}
        centered
      >
        <Modal.Body>
          <SoftBox sx={{ m: 0 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p className="HeaderCSV">Select columns to export</p>

              <button className="CloseBtn" onClick={() => setShow1(false)}>
                <span>
                  <CloseIcon fontSize="small" />
                </span>
              </button>
            </Box>

            <Box sx={{ height: "470px", width: "100%", overflow: "auto", pt: 1 }}>
              {colHeaders1.map((ele, index) => (
                <>
                  <ul key={index} className="UlCSV">
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders1, setColHeaders1)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="login-btn4" onClick={() => setShow1(false)}>
                <span className="login-btn4-Text">Cancel</span>
              </button>

              <button className="login-btn3">
                {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                <CSVLink
                  data={
                    tablerow.length === 0
                      ? shortData
                        ?.filter(
                          (ele) => ele.broadcast_status === "0" && ele.counsellor_name === ""
                        )
                        .map((ele) => ({
                          ...ele,
                          key: ele.registration_id,
                          phone:
                            ele?.mobile?.length === 12 ? ele.mobile?.substring(2) : ele.mobile,
                          // phone: "9892038669",
                          education: ele.highest_education,
                          modified_on: moment(ele.modifieddt).format("DD-MM-YYYY"),
                          skills: ele.skills === null ? ele.job_role : ele.skills,
                          taluka: ele.taluka,
                          state: ele.state,
                          district: ele.district,
                          last_active: moment(ele.last_activedate).format("DD-MM-YYYY"),
                          candidate_name: `${ele.first_name} ${ele.last_name}`,
                          // score: `0`,
                          score: ele.relativity_score,
                          // status: "45",
                          industry: ele.industry,
                          job_role: ele.job_role,
                          broadcast_status: ele.broadcast_status,
                          jobid: ele.jobid,
                          status: ele.wa_status,
                          response: ele.response,
                          job_title: ele.job_title,
                          datasource: ele.datasource,
                          ratings: ele.ratings,
                          salary_req: ele.desired_salary,
                          exp: ele.experience,
                          display_comment: ele.display_comment,
                          feedback: ele.feedback,
                          sent_status: ele.sent_status,
                          completion_status: ele.completion_status,
                          candidate_status: ele.candidate_status,
                          counsellor_name: ele.counsellor_name,
                          shortlist_date: moment(ele.shortlist_date).format("DD-MM-YYYY"),
                          resume: ele.resume_cv,
                          interest_level:
                            ele.broadcast_status === "0"
                              ? "Yet to start"
                              : ele.broadcast_status === "1"
                                ? "In progress"
                                : ele.broadcast_status === "2"
                                  ? "Interested"
                                  : ele.broadcast_status === "3"
                                    ? "Not interested"
                                    : "Yet to start",
                          // assign_date: moment(ele.assign_date).format("DD-MM-YYYY"),
                          // assign_date: ele.assign_date,
                        }))
                        .filter((ele) => {
                          return newName?.toLocaleLowerCase() === ""
                            ? ele
                            : ele.candidate_name
                              ?.toLocaleLowerCase()
                              .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              ? ele
                              : String(ele.phone).includes(String(newName));
                        })
                        .filter((ele) => {
                          return scoreft !== ""
                            ? scoreft === "Excellent"
                              ? ele.score === 1
                              : scoreft === "Average"
                                ? ele.score === 2
                                : scoreft === "Less"
                                  ? ele.score === 3
                                  : ele
                            : ele;
                        })
                        .filter((ele) => {
                          return broadCast !== "" ? ele.sent_status === broadCast : ele;
                        })
                        .filter((ele) => {
                          return waStatus !== "" ? ele.broadcast_status === waStatus : ele;
                        })
                        .filter((ele) => {
                          return stList !== "" ? ele.candidate_status === stList : ele;
                        })
                        .filter((ele) => {
                          return counsellorName !== ""
                            ? ele.counsellor_name === counsellorName
                            : ele;
                        })
                        .filter((ele) => {
                          return shortlistDate !== ""
                            ? ele.shortlist_date === moment(shortlistDate).format("DD-MM-YYYY")
                            : ele;
                        })
                        .filter((ele) => {
                          return shortlistDate?.length === 0 || shortlistDate === undefined
                            ? ele
                            : ele.shortlist_date >= shortlistDate[0] &&
                            ele.shortlist_date <= shortlistDate[1];
                        })
                        .filter((ele) => {
                          return broadcastdate?.length === 0 || broadcastdate === undefined
                            ? ele
                            : ele.broadcast_date >= broadcastdate[0] &&
                            ele.broadcast_date <= broadcastdate[1];
                        })
                        .filter((ele) => {
                          return modifiedDate?.length === 0 || modifiedDate === undefined
                            ? ele
                            : ele.modified_on >= modifiedDate[0] &&
                            ele.modified_on <= modifiedDate[1];
                        })
                        .filter((ele) => {
                          return lastDate?.length === 0 || lastDate === undefined
                            ? ele
                            : ele.last_active >= lastDate[0] && ele.last_active <= lastDate[1];
                        })
                        .filter((ele) => {
                          return assignDate?.length === 0 || assignDate === undefined
                            ? ele
                            : ele.assign_date >= assignDate[0] &&
                            ele.assign_date <= assignDate[1];
                        })
                        .filter((ele) => {
                          return industryfilter !== ""
                            ? industryfilter?.split(",").includes(ele.industry)
                            : ele;
                        })
                        .filter((ele) => {
                          return rolefilter !== ""
                            ? rolefilter?.split(",").includes(ele.job_role)
                            : ele;
                        })
                        .filter((ele) => {
                          return educationfilter !== ""
                            ? educationfilter?.split(",").includes(ele.education)
                            : ele;
                        })
                        .filter((ele) => {
                          return skillfilter !== ""
                            ? skillfilter?.split(",").includes(ele.skills)
                            : ele;
                        })
                        .filter((ele) => {
                          return expfilter !== "" ? ele.exp === expfilter : ele;
                        })
                        .filter((ele) => {
                          return salaryfilter !== ""
                            ? salaryfilter?.split(",").includes(ele.salary_req)
                            : ele;
                        })
                        .filter((ele) => {
                          return genderfilter !== ""
                            ? genderfilter?.split(",").includes(ele.gender)
                            : ele;
                        })
                        .filter((ele) => {
                          return agefilter?.length === 0 || agefilter === undefined
                            ? ele
                            : ele.age >= agefilter[0] && ele.age <= agefilter[1];
                        })
                      : tablerow
                        ?.filter(
                          (ele) => ele.broadcast_status === "0" && ele.counsellor_name === ""
                        )
                        .map((ele) => ({
                          ...ele,
                          key: ele.registration_id,
                          phone:
                            ele?.mobile?.length === 12 ? ele.mobile?.substring(2) : ele.mobile,
                          // phone: "9892038669",
                          education: ele.highest_education,
                          modified_on: moment(ele.modifieddt).format("DD-MM-YYYY"),
                          skills: ele.skills === null ? ele.job_role : ele.skills,
                          taluka: ele.taluka,
                          state: ele.state,
                          district: ele.district,
                          last_active: moment(ele.last_activedate).format("DD-MM-YYYY"),
                          candidate_name: `${ele.first_name} ${ele.last_name}`,
                          // score: `0`,
                          score: ele.relativity_score,
                          // status: "45",
                          industry: ele.industry,
                          job_role: ele.job_role,
                          broadcast_status: ele.broadcast_status,
                          jobid: ele.jobid,
                          status: ele.wa_status,
                          response: ele.response,
                          job_title: ele.job_title,
                          datasource: ele.datasource,
                          ratings: ele.ratings,
                          salary_req: ele.desired_salary,
                          exp: ele.experience,
                          display_comment: ele.display_comment,
                          feedback: ele.feedback,
                          sent_status: ele.sent_status,
                          completion_status: ele.completion_status,
                          candidate_status: ele.candidate_status,
                          counsellor_name: ele.counsellor_name,
                          shortlist_date: moment(ele.shortlist_date).format("DD-MM-YYYY"),
                          resume: ele.resume_cv,
                          interest_level:
                            ele.broadcast_status === "0"
                              ? "Yet to start"
                              : ele.broadcast_status === "1"
                                ? "In progress"
                                : ele.broadcast_status === "2"
                                  ? "Interested"
                                  : ele.broadcast_status === "3"
                                    ? "Not interested"
                                    : "Yet to start",
                          // assign_date: moment(ele.assign_date).format("DD-MM-YYYY"),
                          // assign_date: ele.assign_date,
                        }))
                        .filter((ele) => {
                          return newName?.toLocaleLowerCase() === ""
                            ? ele
                            : ele.candidate_name
                              ?.toLocaleLowerCase()
                              .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              ? ele
                              : String(ele.phone).includes(String(newName));
                        })
                        .filter((ele) => {
                          return scoreft !== ""
                            ? scoreft === "Excellent"
                              ? ele.score === 1
                              : scoreft === "Average"
                                ? ele.score === 2
                                : scoreft === "Less"
                                  ? ele.score === 3
                                  : ele
                            : ele;
                        })
                        .filter((ele) => {
                          return broadCast !== "" ? ele.sent_status === broadCast : ele;
                        })
                        .filter((ele) => {
                          return waStatus !== "" ? ele.broadcast_status === waStatus : ele;
                        })
                        .filter((ele) => {
                          return stList !== "" ? ele.candidate_status === stList : ele;
                        })
                        .filter((ele) => {
                          return counsellorName !== ""
                            ? ele.counsellor_name === counsellorName
                            : ele;
                        })
                        .filter((ele) => {
                          return shortlistDate?.length === 0 || shortlistDate === undefined
                            ? ele
                            : ele.shortlist_date >= shortlistDate[0] &&
                            ele.shortlist_date <= shortlistDate[1];
                        })
                        .filter((ele) => {
                          return broadcastdate?.length === 0 || broadcastdate === undefined
                            ? ele
                            : ele.broadcast_date >= broadcastdate[0] &&
                            ele.broadcast_date <= broadcastdate[1];
                        })
                        .filter((ele) => {
                          return modifiedDate?.length === 0 || modifiedDate === undefined
                            ? ele
                            : ele.modified_on >= modifiedDate[0] &&
                            ele.modified_on <= modifiedDate[1];
                        })
                        .filter((ele) => {
                          return lastDate?.length === 0 || lastDate === undefined
                            ? ele
                            : ele.last_active >= lastDate[0] && ele.last_active <= lastDate[1];
                        })
                        .filter((ele) => {
                          return assignDate?.length === 0 || assignDate === undefined
                            ? ele
                            : ele.assign_date >= assignDate[0] &&
                            ele.assign_date <= assignDate[1];
                        })
                        .filter((ele) => {
                          return industryfilter !== ""
                            ? industryfilter?.split(",").includes(ele.industry)
                            : ele;
                        })
                        .filter((ele) => {
                          return rolefilter !== ""
                            ? rolefilter?.split(",").includes(ele.job_role)
                            : ele;
                        })
                        .filter((ele) => {
                          return educationfilter !== ""
                            ? educationfilter?.split(",").includes(ele.education)
                            : ele;
                        })
                        .filter((ele) => {
                          return skillfilter !== ""
                            ? skillfilter?.split(",").includes(ele.skills)
                            : ele;
                        })
                        .filter((ele) => {
                          return expfilter !== "" ? ele.exp === expfilter : ele;
                        })
                        .filter((ele) => {
                          return salaryfilter !== ""
                            ? salaryfilter?.split(",").includes(ele.salary_req)
                            : ele;
                        })
                        .filter((ele) => {
                          return genderfilter !== ""
                            ? genderfilter?.split(",").includes(ele.gender)
                            : ele;
                        })
                        .filter((ele) => {
                          return agefilter?.length === 0 || agefilter === undefined
                            ? ele
                            : ele.age >= agefilter[0] && ele.age <= agefilter[1];
                        })
                  }
                  // filename={"my-file.csv"}
                  filename={fileName}
                  headers={colHeaders1.filter((ele) => ele.isExportable === true)}
                >
                  <span className="login-btn3-Text">Export</span>
                </CSVLink>
              </button>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        onHide={() => {
          setShow2(false);
        }}
        centered
      >
        <Modal.Body>
          <SoftBox sx={{ m: 0 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p className="HeaderCSV">Select columns to export</p>

              <button className="CloseBtn" onClick={() => setShow2(false)}>
                <span>
                  <CloseIcon fontSize="small" />
                </span>
              </button>
            </Box>

            {colHeaders2.map((ele, index) => (
              <>
                <ul key={index} className="UlCSV">
                  <input
                    type="checkbox"
                    checked={ele.isExportable}
                    onChange={(event) =>
                      handleFormChange12(event, index, colHeaders2, setColHeaders2)
                    }
                  />
                  <span className="ListCSV">&nbsp;{ele.label}</span>
                </ul>
              </>
            ))}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="login-btn4" onClick={() => setShow2(false)}>
                <span className="login-btn4-Text">Cancel</span>
              </button>

              <button className="login-btn3">
                {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                <CSVLink
                  data={
                    tablerow.length === 0
                      ? shortData
                        ?.filter((ele) => ele.counsellor_name !== "")
                        .map((item, i) => ({
                          ...item,
                          // mobile: item.mobile.substring(0),
                          // rating: item.ratings,
                          // display_comment: item.display_comment,
                          // feedback: item.feedback,
                          // shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                          phone: item?.mobile,
                          candidate_name: `${item.first_name} ${item.last_name}`,
                          rating: item.ratings,
                          display_comment: item.display_comment,
                          feedback: item.feedback,
                          shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                          // rating: i % 2 !== 0 ? "2.8" : "3.8",
                        }))
                        .filter((ele) => {
                          return newName?.toLocaleLowerCase() === ""
                            ? ele
                            : ele.candidate_name
                              ?.toLocaleLowerCase()
                              .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              ? ele
                              : String(ele.phone).includes(String(newName));
                        })
                        .filter((ele) => {
                          return waStatus !== "" ? ele.broadcast_status === waStatus : ele;
                        })
                        .filter((ele) => {
                          return stList !== "" ? ele.candidate_status === stList : ele;
                        })
                        .filter((ele) => {
                          return counsellorName !== ""
                            ? ele.counsellor_name === counsellorName
                            : ele;
                        })
                        .filter((ele) => {
                          return shortlistDate?.length === 0 || shortlistDate === undefined
                            ? ele
                            : ele.shortlist_date >= shortlistDate[0] &&
                            ele.shortlist_date <= shortlistDate[1];
                        })
                        .filter((ele) => {
                          return broadcastdate?.length === 0 || broadcastdate === undefined
                            ? ele
                            : ele.broadcast_date >= broadcastdate[0] &&
                            ele.broadcast_date <= broadcastdate[1];
                        })
                        .filter((ele) => {
                          return skillfilter !== ""
                            ? skillfilter?.split(",").includes(ele.skills)
                            : ele;
                        })
                        .filter((ele) => {
                          return candidateRt !== ""
                            ? candidateRt === "Excellent"
                              ? ele.ratings > 4
                              : candidateRt === "Average"
                                ? ele.ratings < 4 && ele.ratings >= 2.5
                                : candidateRt === "Less"
                                  ? ele.ratings < 2.5 && ele.ratings > 0
                                  : candidateRt === "Yet to be rated"
                                    ? ele.ratings === 0
                                    : ele
                            : ele;
                        })
                      : tablerow
                        ?.filter((ele) => ele.counsellor_name !== "")
                        .map((item, i) => ({
                          ...item,
                          // mobile: item.mobile.substring(0),
                          // rating: item.ratings,
                          // display_comment: item.display_comment,
                          // feedback: item.feedback,
                          // shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                          phone: item?.mobile,
                          candidate_name: `${item.first_name} ${item.last_name}`,
                          rating: item.ratings,
                          display_comment: item.display_comment,
                          feedback: item.feedback,
                          // shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                          // rating: i % 2 !== 0 ? "2.8" : "3.8",
                        }))
                        .filter((ele) => {
                          return newName?.toLocaleLowerCase() === ""
                            ? ele
                            : ele.candidate_name
                              ?.toLocaleLowerCase()
                              .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              ? ele
                              : String(ele.phone).includes(String(newName));
                        })
                        .filter((ele) => {
                          return waStatus !== "" ? ele.broadcast_status === waStatus : ele;
                        })
                        .filter((ele) => {
                          return stList !== "" ? ele.candidate_status === stList : ele;
                        })
                        .filter((ele) => {
                          return counsellorName !== ""
                            ? ele.counsellor_name === counsellorName
                            : ele;
                        })
                        .filter((ele) => {
                          return shortlistDate?.length === 0 || shortlistDate === undefined
                            ? ele
                            : ele.shortlist_date >= shortlistDate[0] &&
                            ele.shortlist_date <= shortlistDate[1];
                        })
                        .filter((ele) => {
                          return broadcastdate?.length === 0 || broadcastdate === undefined
                            ? ele
                            : ele.broadcast_date >= broadcastdate[0] &&
                            ele.broadcast_date <= broadcastdate[1];
                        })
                        .filter((ele) => {
                          return skillfilter !== ""
                            ? skillfilter?.split(",").includes(ele.skills)
                            : ele;
                        })
                        .filter((ele) => {
                          return candidateRt !== ""
                            ? candidateRt === "Excellent"
                              ? ele.ratings > 4
                              : candidateRt === "Average"
                                ? ele.ratings < 4 && ele.ratings >= 2.5
                                : candidateRt === "Less"
                                  ? ele.ratings < 2.5 && ele.ratings > 0
                                  : candidateRt === "Yet to be rated"
                                    ? ele.ratings === 0
                                    : ele
                            : ele;
                        })
                  }
                  // filename={"my-file.csv"}
                  filename={fileName}
                  headers={colHeaders2.filter((ele) => ele.isExportable === true)}
                >
                  <span className="login-btn3-Text">Export</span>
                </CSVLink>
              </button>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal
        show={show3}
        onHide={() => {
          setShow3(false);
        }}
        centered
      >
        <Modal.Body>
          <SoftBox sx={{ m: 0 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p className="HeaderCSV">Select columns to export</p>

              <button className="CloseBtn" onClick={() => setShow3(false)}>
                <span>
                  <CloseIcon fontSize="small" />
                </span>
              </button>
            </Box>

            {colHeaders3.map((ele, index) => (
              <>
                <ul key={index} className="UlCSV">
                  <input
                    type="checkbox"
                    checked={ele.isExportable}
                    onChange={(event) =>
                      handleFormChange12(event, index, colHeaders3, setColHeaders3)
                    }
                  />
                  <span className="ListCSV">&nbsp;{ele.label}</span>
                </ul>
              </>
            ))}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <button className="login-btn4" onClick={() => setShow3(false)}>
                <span className="login-btn4-Text">Cancel</span>
              </button>

              <button className="login-btn3">
                {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                <CSVLink
                  data={
                    tablerow.length === 0
                      ? shortData
                        // .filter((ele) => ele.counsellor_name !== "")
                        ?.filter(
                          (ele) =>
                            ele.broadcast_status === "1" ||
                            ele.broadcast_status === "2" ||
                            ele.broadcast_status === "3"
                        )
                        .map((item, i) => ({
                          ...item,
                          // mobile: item.mobile.substring(0),
                          // rating: item.ratings,
                          // display_comment: item.display_comment,
                          // feedback: item.feedback,
                          // shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                          key: item.registration_id,
                          phone: item?.mobile,
                          candidate_name: `${item.first_name} ${item.last_name}`,
                          last_activedate: moment(item.last_activedate).format("DD-MM-YYYY"),
                          modifieddt: moment(item.modifieddt).format("DD-MM-YYYY"),
                          job_title: item.job_title,
                          // rating: i % 2 !== 0 ? "2.8" : "3.8",
                          rating: item.ratings,
                          display_comment: item.display_comment,
                          feedback: item.feedback,
                          resume: item.resume_cv,
                          shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                          wa_status: item.wa_status,
                          // rating: i % 2 !== 0 ? "2.8" : "3.8",
                        }))
                        .filter((ele) => {
                          return newName?.toLocaleLowerCase() === ""
                            ? ele
                            : ele.candidate_name
                              ?.toLocaleLowerCase()
                              .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              ? ele
                              : String(ele.phone).includes(String(newName));
                        })
                        // .filter((ele) => {
                        //   return waStatus !== "" ? ele.broadcast_status === waStatus : ele;
                        // })
                        // .filter((ele) => {
                        //   return stList !== "" ? ele.candidate_status === stList : ele;
                        // })
                        // .filter((ele) => {
                        //   return counsellorName !== "" ? ele.counsellor_name === counsellorName : ele;
                        // })
                        .filter((ele) => {
                          return candidateRt !== ""
                            ? candidateRt === "Excellent"
                              ? ele.ratings > 4
                              : candidateRt === "Average"
                                ? ele.ratings < 4 && ele.ratings >= 2.5
                                : candidateRt === "Less"
                                  ? ele.ratings < 2.5 && ele.ratings > 0
                                  : candidateRt === "Yet to be rated"
                                    ? ele.ratings === 0
                                    : ele
                            : ele;
                        })
                        .filter((ele) => {
                          return scoreft !== ""
                            ? scoreft === "Excellent"
                              ? ele.score === 1
                              : scoreft === "Average"
                                ? ele.score === 2
                                : scoreft === "Less"
                                  ? ele.score === 3
                                  : ele
                            : ele;
                        })
                        .filter((ele) => {
                          // console.log("ele reposne:", responseStatus);
                          // console.log("optios res:", ele.response, ele.wa_status);
                          return responseStatus !== ""
                            ? responseStatus === "Read"
                              ? ele.wa_status === "read"
                              : responseStatus === "Delivered"
                                ? ele.wa_status === "delivered"
                                : responseStatus === "Interested"
                                  ? ele.response === "interested"
                                  : responseStatus === "Not Interested"
                                    ? ele.response === "not interested"
                                    : ele
                            : ele;
                        })
                        .filter((ele) => {
                          return shortlistDate?.length === 0 || shortlistDate === undefined
                            ? ele
                            : ele.shortlist_date >= shortlistDate[0] &&
                            ele.shortlist_date <= shortlistDate[1];
                        })
                        .filter((ele) => {
                          return broadcastdate?.length === 0 || broadcastdate === undefined
                            ? ele
                            : ele.broadcast_date >= broadcastdate[0] &&
                            ele.broadcast_date <= broadcastdate[1];
                        })
                        .filter((ele) => {
                          return lastDate?.length === 0 || lastDate === undefined
                            ? ele
                            : ele.last_activedate >= lastDate[0] &&
                            ele.last_activedate <= lastDate[1];
                        })
                        .filter((ele) => {
                          return industryfilter !== ""
                            ? industryfilter?.split(",").includes(ele.industry)
                            : ele;
                        })
                        .filter((ele) => {
                          return rolefilter !== ""
                            ? rolefilter?.split(",").includes(ele.job_role)
                            : ele;
                        })
                        .filter((ele) => {
                          // console.log("ele reposne:", responseStatus);
                          // console.log("optios res:", ele.response, ele.wa_status);
                          return responseStatus !== ""
                            ? responseStatus === "Read"
                              ? ele.wa_status === "read"
                              : responseStatus === "Delivered"
                                ? ele.wa_status === "delivered"
                                : responseStatus === "Interested"
                                  ? ele.response === "interested"
                                  : responseStatus === "Not Interested"
                                    ? ele.response === "not interested"
                                    : ele
                            : ele;
                        })
                      : tablerow
                        ?.filter(
                          (ele) =>
                            ele.broadcast_status === "1" ||
                            ele.broadcast_status === "2" ||
                            ele.broadcast_status === "3"
                        )
                        .map((item, i) => ({
                          ...item,
                          // mobile: item.mobile.substring(0),
                          // rating: item.ratings,
                          // display_comment: item.display_comment,
                          // feedback: item.feedback,
                          // shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                          key: item.registration_id,
                          phone: item?.mobile,
                          candidate_name: `${item.first_name} ${item.last_name}`,
                          last_activedate: moment(item.last_activedate).format("DD-MM-YYYY"),
                          modifieddt: moment(item.modifieddt).format("DD-MM-YYYY"),
                          job_title: item.job_title,
                          // rating: i % 2 !== 0 ? "2.8" : "3.8",
                          rating: item.ratings,
                          display_comment: item.display_comment,
                          feedback: item.feedback,
                          resume: item.resume_cv,
                          // shortlist_date: moment(item.shortlist_date).format("DD-MM-YYYY"),
                          wa_status: item.wa_status,
                          // rating: i % 2 !== 0 ? "2.8" : "3.8",
                        }))
                        .filter((ele) => {
                          return newName?.toLocaleLowerCase() === ""
                            ? ele
                            : ele.candidate_name
                              ?.toLocaleLowerCase()
                              .includes(newName.toLocaleLowerCase()) || String(newName) === ""
                              ? ele
                              : String(ele.phone).includes(String(newName));
                        })
                        // .filter((ele) => {
                        //   return waStatus !== "" ? ele.broadcast_status === waStatus : ele;
                        // })
                        // .filter((ele) => {
                        //   return stList !== "" ? ele.candidate_status === stList : ele;
                        // })
                        // .filter((ele) => {
                        //   return counsellorName !== "" ? ele.counsellor_name === counsellorName : ele;
                        // })
                        .filter((ele) => {
                          return shortlistDate?.length === 0 || shortlistDate === undefined
                            ? ele
                            : ele.shortlist_date >= shortlistDate[0] &&
                            ele.shortlist_date <= shortlistDate[1];
                        })
                        .filter((ele) => {
                          return broadcastdate?.length === 0 || broadcastdate === undefined
                            ? ele
                            : ele.broadcast_date >= broadcastdate[0] &&
                            ele.broadcast_date <= broadcastdate[1];
                        })
                        .filter((ele) => {
                          return lastDate?.length === 0 || lastDate === undefined
                            ? ele
                            : ele.last_activedate >= lastDate[0] &&
                            ele.last_activedate <= lastDate[1];
                        })
                        .filter((ele) => {
                          return industryfilter !== ""
                            ? industryfilter?.split(",").includes(ele.industry)
                            : ele;
                        })
                        .filter((ele) => {
                          return rolefilter !== ""
                            ? rolefilter?.split(",").includes(ele.job_role)
                            : ele;
                        })
                        .filter((ele) => {
                          return candidateRt !== ""
                            ? candidateRt === "Excellent"
                              ? ele.ratings > 4
                              : candidateRt === "Average"
                                ? ele.ratings < 4 && ele.ratings >= 2.5
                                : candidateRt === "Less"
                                  ? ele.ratings < 2.5 && ele.ratings > 0
                                  : candidateRt === "Yet to be rated"
                                    ? ele.ratings === 0
                                    : ele
                            : ele;
                        })
                        .filter((ele) => {
                          return scoreft !== ""
                            ? scoreft === "Excellent"
                              ? ele.score === 1
                              : scoreft === "Average"
                                ? ele.score === 2
                                : scoreft === "Less"
                                  ? ele.score === 3
                                  : ele
                            : ele;
                        })
                        .filter((ele) => {
                          // console.log("ele reposne:", responseStatus);
                          // console.log("optios res:", ele.response, ele.wa_status);
                          return responseStatus !== ""
                            ? responseStatus === "Read"
                              ? ele.wa_status === "read"
                              : responseStatus === "Delivered"
                                ? ele.wa_status === "delivered"
                                : responseStatus === "Interested"
                                  ? ele.response === "interested"
                                  : responseStatus === "Not Interested"
                                    ? ele.response === "not interested"
                                    : ele
                            : ele;
                        })
                        .filter((ele) => {
                          // console.log("ele reposne:", responseStatus);
                          // console.log("optios res:", ele.response, ele.wa_status);
                          return responseStatus !== ""
                            ? responseStatus === "Read"
                              ? ele.wa_status === "read"
                              : responseStatus === "Delivered"
                                ? ele.wa_status === "delivered"
                                : responseStatus === "Interested"
                                  ? ele.response === "interested"
                                  : responseStatus === "Not Interested"
                                    ? ele.response === "not interested"
                                    : ele
                            : ele;
                        })
                  }
                  // filename={"my-file.csv"}
                  filename={fileName}
                  headers={colHeaders3.filter((ele) => ele.isExportable === true)}
                >
                  <span className="login-btn3-Text">Export</span>
                </CSVLink>
              </button>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      {/* Modal 1 */}
      <Modal
        show={actionModal1}
        onHide={() => {
          setactionModal1(false);
          // showAlert();
        }}
        size="sm"
        centered
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Assign Counsellor
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => setactionModal1(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox display="flex" width="100%" mt={2}>
            <SoftTypography fontWeight="medium" fontSize="16px">
              Select Counsellor
            </SoftTypography>
          </SoftBox>

          <SoftBox mt={0}>
            <SoftBox>
              <SoftBox mt={0} width="100%" height="auto">
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox mt={0} width="100%" height="auto">
                    <SoftBox width="100%" height="auto">
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                          <Select
                            placeholder="Select Counsellor"
                            options={cosList}
                            styles={stylesDD}
                            value={cosId}
                            onChange={(e) =>
                              // jobpostform.setFieldValue("org_name", e.label),
                              setCosId(e ? e : "")
                            }
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 1 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // setApplyModal(true);
                  setactionModal1(false);
                  handleConsPostData();
                  // setStep1(false);
                  // setStep2(true);
                  // Swal.fire()
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Apply
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>
      {/* Modal 1 */}
      <Modal
        show={actionModal}
        onHide={() => {
          setactionModal(false);
          // showAlert();
        }}
        size="sm"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <SoftBox display="flex" flexDirection="column">
            <SoftBox
              // pl={2}
              sx={{
                // height: "45px",
                // backgroundColor: "#3B5092",
                // borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              // width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => {
                setApplyModal(true);
                setactionModal(false);
                // setStep1(false);
                // setStep2(true);
              }}
            >
              <SoftButton my="14px" display="flex" justifyContent="center">
                <SoftTypography>Whatsapp Verification</SoftTypography>
              </SoftButton>
            </SoftBox>
            <SoftBox
              // pl={2}
              sx={{
                // height: "45px",
                // backgroundColor: "#3B5092",
                // borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              // width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => {
                setactionModal1(true);
                setactionModal(false);
                handleConsPost();
                // setStep1(false);
                // setStep2(true);
              }}
            >
              <SoftButton my="14px" display="flex" justifyContent="center">
                <SoftTypography>Counsellors</SoftTypography>
              </SoftButton>
            </SoftBox>

            <SoftButton my="14px" display="flex" justifyContent="center">
              <SoftTypography>SMS Verification</SoftTypography>
            </SoftButton>
          </SoftBox>
        </Modal.Body>
        {/* <Modal.Footer className="apply-modal">
          <SoftBox
            // pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => {
              setApplyModal(true);
              setactionModal(false);
              // setStep1(false);
              // setStep2(true);
            }}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Apply
            </SoftTypography>
          </SoftBox>
        </Modal.Footer> */}
      </Modal>

      {/* Modal 2 */}
      <Modal
        show={applyModal}
        onHide={() => {
          setApplyModal(false);
          // showAlert();
        }}
        centered
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Candidate Connect Message
            </SoftTypography>

            <SoftBox sx={{ "&:hover": { cursor: "pointer" } }} onClick={() => setApplyModal(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox display="flex" flexDirection="column" mt={2}>
            <SoftBox display="flex" flexDirection="column" justifyContent="center">
              <SoftTypography fontWeight="small" fontSize="14px" lineHeight="19px">
                Dear Candidate,
              </SoftTypography>
              <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                Greetings from SAMARTH
              </SoftTypography>
              <SoftTypography fontWeight="small" fontSize="14px" lineHeight="19px">
                Based on our evaluation of your skills, we have been assigned by your local public
                administrator to help you with your job search. We have identified job requirements
                that may suit your requirements. Are you actively looking for new job opportunities?
              </SoftTypography>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 1 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setApplyModal(false);
                  // setStep1(false);
                  // setStep2(true);
                  sendMessageFunc();
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Send
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal
        show={resumeModal}
        onHide={() => {
          setResumeModal(false);
        }}
        centered
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <SoftBox sx={{ m: 0 }}>
          {console.log("rowdata for resume:", rowData)}
          <Modal.Body>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <SoftTypography fontWeight="medium" fontSize="20px">
                Upload Resume
              </SoftTypography>

              <SoftBox
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => setResumeModal(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.16992 14.8299L14.8299 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.8299 14.8299L9.16992 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SoftBox>
            </SoftBox>

            <SoftBox mt={2}>
              <SoftBox>
                <SoftBox mt={0} width="100%" height="auto">
                  <Card sx={{ overflow: "visible" }}>
                    <SoftBox mt={0} width="100%" height="auto">
                      <SoftBox width="100%" height="auto">
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={12}>
                            <SoftBox>
                              <Box sx={{ width: "100%" }}>
                                <input
                                  className="form-control"
                                  type="file"
                                  accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                  id="datasource"
                                  name="datasource"
                                  onChange={(e) => {
                                    // const file = e.target.files[0],
                                    console.log("resumefile ", e.target.files[0]),
                                      setResumeFile(e ? e.target.files[0] : "");
                                  }}
                                />
                              </Box>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ ml: "auto", mr: "auto", mt: 1 }}
            >
              <Box sx={{ p: 1 }}>
                <SoftBox
                  sx={{
                    height: "45px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    ResumeUploadFunc(rowData);
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Save
                  </SoftTypography>
                </SoftBox>
              </Box>

              <Box sx={{ p: 1 }}>
                <SoftBox
                  sx={{
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #3B5092",
                    borderRadius: "5px",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setResumeModal(false)}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#3B5092",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Cancel
                  </SoftTypography>
                </SoftBox>
              </Box>
            </SoftBox>
          </Modal.Body>
        </SoftBox>
      </Modal>
      {/* modal for document edit */}

      {/* Template modal for checking if candidate is shortlisted */}
      <Modal
        show={showTemplate}
        onHide={() => {
          setShowTemplate(false);
        }}
        centered
      >
        <Modal.Body>
          <SoftBox sx={{ m: 0 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p className="HeaderCSV">Upload Candidate for Shortlisting</p>

              <button className="CloseBtn" onClick={() => setShowTemplate(false)}>
                <span>
                  <CloseIcon fontSize="small" />
                </span>
              </button>
            </Box>
            {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <button className="login-btn4" onClick={() => setShowTemplate(false)}>
                <span className="login-btn4-Text">Downlaod</span>
              </button>

              <button
                className="login-btn3"
                onClick={() => (setShowShortlistModal1(true), setShowTemplate(false))}
              >
                
                <span className="login-btn3-Text">Upload</span>
              </button>
            </Box> */}
            <SoftBox display="flex" flexDirection="row" gap="20px">
              <SoftBox
                // pl={2}
                margin="auto"
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width="150px"
                p="10px 20px"
                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  // navigate('/search/newsearch/recommend-search/allshortlist');
                  //activate modal2
                  // setShowModal2(true);
                  setShowTemplate(false);
                }}
              >
                <Link to="/candidate_check_template.xlsx" target="_blank" download>
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "medium",
                      lineHeight: "15px",
                    }}
                  >
                    Download Template
                  </SoftTypography>
                </Link>
              </SoftBox>
              <SoftBox
                // pl={2}
                margin="auto"
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width="150px"
                p="10px 20px"
                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  // navigate('/search/newsearch/recommend-search/allshortlist');
                  //activate modal3
                  // setShowModal3(true);
                  setBulkResume(true), setShowTemplate(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "medium",
                    lineHeight: "15px",
                  }}
                >
                  Upload
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Modal.Body>
      </Modal>
      {/* Template modal for checking if candidate is shortlisted */}

      {/* Upload modal for bulk upload of resume or mobile number of candidate to check */}
      <Modal
        show={bulkResume}
        onHide={() => {
          setBulkResume(false);
        }}
        centered
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Upload Candidate for Shortlisting
            </SoftTypography>

            <SoftBox sx={{ "&:hover": { cursor: "pointer" } }} onClick={() => setBulkResume(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 2 }}
          >
            {/*
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "190px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setUploadResume(true);
                  setBulkResume(false);
                  setShowShortlistModal1(true);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "normal", lineHeight: "19px" }}
                >
                  Upload Bulk Resume
                </SoftTypography>
              </SoftBox>
            </Box>
            */}

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "190px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // navigate('/search/newsearch/recommend-search/allshortlist');
                  //activate modal2
                  // setShowModal2(true);
                  // setBulkResume(false);
                  setUploadResume(false);
                  setBulkResume(false);
                  setShowShortlistModal5(true);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "normal", lineHeight: "19px" }}
                >
                  Upload Bulk Status
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "190px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // navigate('/search/newsearch/recommend-search/allshortlist');
                  //activate modal3
                  // setShowModal3(true);
                  setShowShortlistModal1(true), setBulkResume(false), setUploadResume(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "normal",
                    lineHeight: "19px",
                  }}
                >
                  Upload Candidate Number
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      {/* Shortlist modal */}
      <Modal
        show={showshortlistModal1}
        onHide={() => (setShowShortlistModal1(false), setBulkResume(true))}
        centered
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              {uploadResume ? "Upload Resumes of Candidates" : `Upload Candidate for Shortlisting`}
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => (setShowShortlistModal1(false), setBulkResume(true))}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                  {uploadResume ? `Upload Bulk Resume:` : `Upload File:`}
                </SoftTypography>

                <SoftBox width="100%">
                  <input
                    className="form-control"
                    type="file"
                    accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                    // accept="image/png, image/jpeg"
                    // onChange={handleFileUpload}
                    onChange={(e) => {
                      // const file = e.target.files[0],
                      console.log("resumefile ", e.target.files[0]),
                        setUploadFile(e.target.files[0]);
                    }}
                  />
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={12}>
                {uploadResume ? (
                  <SoftBox width="100%">
                    <SoftBox display="flex" flexDirection="column">
                      <SoftBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      // gap="10px"
                      // onClick={() => {
                      //   setCreate(!create);
                      // }}
                      >
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Data Source
                        </SoftTypography>
                        <SoftBox
                          display="flex"
                          flexDirection="row"
                          gap="10px"
                          onClick={() => {
                            setCreateDS(!createDS);
                            setSelectedDS("");
                          }}
                        >
                          <Tooltip
                            key="6"
                            title={!createDS ? `Add new data source` : `Select From Existing `}
                            placement="bottom"
                          >
                            <SoftBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15px"
                                height="15px"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <rect width="10" height="10" rx="5" fill="#3B5092" />
                                <path
                                  d="M5 2V8M2 5H8"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </SoftBox>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                      {createDS ? (
                        <SoftInput
                          type="text"
                          placeholder="Data Source"
                          // name="job_title"
                          // id="job_title"
                          onChange={(e) => (
                            setSelectedDS(e.target.value), console.log("sel:", selectedDS)
                          )}
                          value={selectedDS ? selectedDS : ""}
                        />
                      ) : (
                        <Select
                          options={dataSource}
                          styles={stylesDD}
                          onChange={(e) => setSelectedDS(e ? e.value : "")}
                        />
                      )}
                    </SoftBox>
                  </SoftBox>
                ) : null}
              </Grid>
            </Grid>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 1 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Link to="/candidate_check_template.xlsx" target="_blank" download>
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Download Template
                  </SoftTypography>
                </Link>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => bulkUploadfunc()}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Upload
                </SoftTypography>
              </SoftBox>
            </Box>

            {/*
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #3B5092",
                  borderRadius: "5px",
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => (setShowShortlistModal1(false), setBulkResume(true))}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "15px",
                    fontWeight: "medium",
                    lineHeight: "19px",
                  }}
                >
                  Cancel
                </SoftTypography>
              </SoftBox>
            </Box>
            */}
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal1}
        onHide={() => {
          setShowModal1(false);
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb={2}
          >
            <SoftTypography fontWeight="medium" fontSize="20px">
              Update Previous Status
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                setShowModal1(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>
          <>
            <Grid container spacing={2}>
              {/*Stage 1*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Counsellor Engagement on
                  </SoftTypography>
                </SoftBox>
                <br />
                {candData.stage_values
                  ?.filter((ee) => ee.key === 0)
                  ?.map((ele,i) => (
                    <>
                      <SoftBox>

                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex", }}>
                            <SoftInput
                              type="text"
                              value={ele.value}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored(e.target.value, ele);
                                handleFormChange111(e, i)

                              }}

                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleChangeStatus(stored, ele)}>
                              <img src={saveimg} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={
                              ele.value !== ""
                                ? { label: ele.value, value: ele.value }
                                : null
                            }
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  )
                  )
                }


              </Grid>

              {/*Stage 2*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Interview Process Initiated
                  </SoftTypography>
                </SoftBox>
                <br />

                {candData.stage_values
                  ?.filter((ee) => ee.key === 1)
                  ?.map((ele,i) => (
                    <>
                      <SoftBox>

                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex", }}>
                            <SoftInput
                              type="text"
                              value={ele.value}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored(e.target.value, ele);
                                handleFormChange111(e, i)

                              }}

                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleChangeStatus(stored, ele)}>
                              <img src={saveimg} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={
                              ele.value !== ""
                                ? { label: ele.value, value: ele.value }
                                : null
                            }
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  )
                  )
                }
              </Grid>

              {/*Stage 3*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Interview Process On
                  </SoftTypography>
                </SoftBox>
                <br />

                {candData.stage_values
                  ?.filter((ee) => ee.key === 2)
                  ?.map((ele) => (
                    <>
                      <SoftBox>

                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex", }}>
                            <SoftInput
                              type="text"
                              value={ele.value}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored(e.target.value, ele);
                                handleFormChange111(e, i)

                              }}

                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleChangeStatus(stored, ele)}>
                              <img src={saveimg} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={
                              ele.value !== ""
                                ? { label: ele.value, value: ele.value }
                                : null
                            }
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  )
                  )
                }
              </Grid>

              {/*Stage 4*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Final Selection Stage
                  </SoftTypography>
                </SoftBox>
                <br />

                {candData.stage_values
                  ?.filter((ee) => ee.key === 3)
                  ?.map((ele) => (
                    <>
                      <SoftBox>

                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex", }}>
                            <SoftInput
                              type="text"
                              value={ele.value}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored(e.target.value, ele);
                                handleFormChange111(e, i)

                              }}

                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleChangeStatus(stored, ele)}>
                              <img src={saveimg} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={
                              ele.value !== ""
                                ? { label: ele.value, value: ele.value }
                                : null
                            }
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  )
                  )
                }
              </Grid>

              {/*Stage 5*/}
              <Grid item lg={2.4} md={4} sm={6} xs={12}>
                <SoftBox width="100%" textAlign="center" border="1px solid grey" borderRadius="8px">
                  <SoftTypography fontSize="15px" lineHeight="30px">
                    Post Selection Stage
                  </SoftTypography>
                </SoftBox>
                <br />

                {candData.stage_values
                  ?.filter((ee) => ee.key === 4)
                  ?.map((ele,i) => (
                    <>
                      <SoftBox>

                        <SoftTypography fontWeight="500" fontSize="15px" lineHeight="30px">
                          {ele.name}
                        </SoftTypography>
                        {ele.datatype === "datetime" ? (
                          <SoftBox width="100%">
                            <SoftInput
                              type="date"
                              value={ele.value}
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                handleChangeStatus(e.target.value, ele);
                              }}
                            />
                          </SoftBox>
                        ) : ele.datatype === "input" ? (
                          <SoftBox width="100%" sx={{ display: "flex", }}>
                            <SoftInput
                              type="text"
                              value={stored1}
                              // value={moment(candData?.first_call).format("DD-MM-YYYY")}
                              // minDate={
                              //   new Date().getFullYear() +
                              //   "-" +
                              //   parseInt(new Date().getMonth() + 1) +
                              //   "-" +
                              //   new Date().getDate()
                              // }
                              // minDate={`${new Date().toISOString().split("T")[0]}`}
                              onChange={(e) => {
                                setStored1(e.target.value, ele);
                                handleFormChange111(e, i)
                              }}

                            />
                            &nbsp;
                            {/* <button style={{height:"40px", backgroundColor:"#3b5092", color:"white",borderRadius:"5px"}} onClick={()=>handleChangeStatus(stored,ele)}>Save</button> */}
                            <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleChangeStatus(stored1, ele)}>
                              <img src={saveimg} />
                            </SoftBox>
                          </SoftBox>
                        ) : (
                          <Select
                            options={ele.options.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            // isDisabled={candData.job_editable ? true : false}
                            // value={
                            //   candData.job_status !== "" &&
                            //   candData.job_status === "Actively looking for job"
                            //     ? { label: candData.job_status, value: candData.job_status }
                            //     : candData.job_status !== "Actively looking for job"
                            //     ? { label: stage1.job_status, value: stage1.job_status }
                            //     : null
                            // }
                            value={
                              ele.value !== ""
                                ? { label: ele.value, value: ele.value }
                                : null
                            }
                            onChange={(e) =>
                              // setStage1({ ...stage1, job_status: e.value }),
                              // e.value === "Actively looking for job" && setShowJobPost(true),
                              handleChangeStatus(e.value, ele)
                            }
                            styles={stylesDD}
                          />
                        )}
                      </SoftBox>
                    </>
                  )
                  )
                }
              </Grid>
            </Grid>

            {/* <SoftBox display="flex" justifyContent="center">
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="174px"
                height="40px"
                sx={{
                  backgroundColor: "#FFA142",
                  borderRadius: "50px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  console.log(prevState);
                  UpdateTimeline(prevState);
                }}
              >
                <SoftTypography fontWeight="400" fontSize="15px" lineHeight="19px" color="#fff">
                  Submit
                </SoftTypography>
              </SoftBox>
            </SoftBox> */}
          </>
        </Modal.Body>
      </Modal>

      <Modal
        show={showshortlistModal5}
        onHide={() => (setShowShortlistModal5(false), setBulkResume(true), setUploadExcel(""))}
        centered
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Upload Bulk Status
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => (
                setShowShortlistModal5(false), setBulkResume(true), setUploadExcel("")
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                  Upload File
                </SoftTypography>

                <SoftBox width="100%">
                  <input
                    className="form-control"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                      setUploadExcel(e.target.files[0]);
                    }}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 1 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Link to="/Status_Update.xlsx" target="_blank" download>
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Download Template
                  </SoftTypography>
                </Link>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => bulkUploadStatusfunc()}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Upload
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal show={showshortlistModal1A} onHide={handleCloseShortlistModal1A} centered>
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Select from below options
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                handleCloseShortlistModal1A();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 2 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // navigate('/search/newsearch/recommend-search/allshortlist');
                  //activate modal2
                  // setShowModal2(true);
                  // setShowModal1(false);
                  setShowShortlistModal2A(true);
                  setShowShortlistModal1A(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Existing Bucket
                </SoftTypography>
              </SoftBox>
            </Box>

            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // navigate('/search/newsearch/recommend-search/allshortlist');
                  //activate modal3
                  setShowShortlistModal3A(true);
                  setShowShortlistModal1A(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Create Bucket
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal show={showShortlistModal2A} onHide={handleCloseShortlistModal2A} centered>
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Select a Bucket
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                handleCloseShortlistModal2A();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox mt={2}>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <SoftBox>
                    <Box sx={{ width: "100%" }}>
                      <Select
                        styles={stylesDD}
                        options={orgList}
                        placeholder="Select from following compaines"
                        onChange={(e) => (
                          selectBucket.setFieldValue("org_name", e.label), setOrgIdA(e.value)
                        )}
                      />
                    </Box>

                    <SoftTypography fontWeight="small" fontSize="12px" padding="4px">
                      {selectBucket.touched.org_name && selectBucket.errors.org_name ? (
                        <span style={{ color: "red" }}>{selectBucket.errors.org_name}</span>
                      ) : null}
                    </SoftTypography>
                  </SoftBox>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <SoftBox>
                    <Box sx={{ width: "100%" }}>
                      <Select
                        styles={stylesDD}
                        options={roleList}
                        placeholder="Select from following job types"
                        onChange={(e) => {
                          selectBucket.setFieldValue("job_role", e.label);
                          setJobIdA(e.jobid);
                        }}
                      />

                      <SoftTypography fontWeight="small" fontSize="12px" padding="4px">
                        {selectBucket.touched.job_role && selectBucket.errors.job_role ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {selectBucket.errors.job_role}
                          </span>
                        ) : null}
                      </SoftTypography>
                    </Box>
                  </SoftBox>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <SoftBox display="flex" justifyContent="center">
                    <SoftTypography
                      fontWeight="small"
                      fontSize="14px"
                      sx={{ "&:hover": { cursor: "pointer" } }}
                      onClick={() => {
                        setShowShortlistModal3A(true);
                        setShowShortlistModal2A(false);
                        // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                        navigate("/searchbyposting/createposting");
                      }}
                    >
                      Could not find suitable bucket? Create Bucket
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: "auto", mr: "auto", mt: 1 }}
          >
            <Box sx={{ p: 1 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={
                  selectBucket.handleSubmit
                  // setShowModal1(false);
                  // setShowModal2(false);

                  // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                }
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Select Bucket
                </SoftTypography>
              </SoftBox>
            </Box>
          </SoftBox>
        </Modal.Body>
      </Modal>

      <Modal show={showShortlistModal3A} onHide={handleCloseShortlistModal3A} size="xl" centered>
        <Modal.Body>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <SoftTypography fontWeight="medium" fontSize="20px">
              Create a bucket
            </SoftTypography>

            <SoftBox
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                handleCloseShortlistModal3A();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#3A3A3A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SoftBox>
          </SoftBox>

          <Box sx={{ p: 1, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mb={1.5}
                  >
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                        Organization Name
                      </SoftTypography>
                      <SoftBox
                        sx={{
                          color: "red",
                          position: "inherit",
                          paddingLeft: "2px",
                          fontSize: "15px",
                        }}
                      >
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      gap="10px"
                      onClick={() => {
                        navigate("/jobprovider-registration");
                      }}
                    >
                      <Tooltip key="1" title="Add a new organisation" placement="bottom">
                        <SoftBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15px"
                            height="15px"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                            <path
                              d="M5 2V8M2 5H8"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </Tooltip>
                      {/* <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="13px"
                                  lineHeight="17px"
                                >
                                  Add a new organisation
                                </SoftTypography> */}
                    </SoftBox>
                  </SoftBox>
                  {/* <SoftInput type="text" placeholder="" /> */}
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      placeholder="Select organization name"
                      options={orgList}
                      // value={orgList.filter((ele) => ele.value === orgNameID)}
                      onChange={(e) => (
                        jobpostformone.setFieldValue("org_name", e ? e.label : ""),
                        setOrgNameID(e ? e.value : "")
                      )}
                    />
                  </SoftBox>
                  {jobpostformone.touched.org_name && jobpostformone.errors.org_name ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.org_name}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftBox
                    width="100%"
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Job Title
                      </SoftTypography>
                      <SoftBox
                        sx={{
                          color: "red",
                          position: "inherit",
                          paddingLeft: "2px",
                          fontSize: "15px",
                        }}
                      >
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      gap="10px"
                      onClick={() => {
                        setCreate(!create);
                      }}
                    >
                      <Tooltip
                        key="2"
                        title={!create ? `Create New Job Title` : `Select From Existing `}
                        placement="bottom"
                      >
                        <SoftBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15px"
                            height="15px"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                            <path
                              d="M5 2V8M2 5H8"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </Tooltip>
                      {/* <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="10px"
                                  lineHeight="17px"
                                >
                                  {!create ? `Create New Job Title` : `Select From Existing `}
                                </SoftTypography> */}
                    </SoftBox>
                  </SoftBox>
                  {create ? (
                    <SoftInput
                      type="text"
                      placeholder="Job title"
                      name="job_title"
                      id="job_title"
                      onChange={jobpostformone.handleChange}
                      value={jobpostformone.values.job_title}
                    />
                  ) : (
                    <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                      <Select
                        styles={stylesDD}
                        isClearable
                        placeholder="Please enter job title"
                        options={jobTitle}
                        onChange={
                          (e) => jobpostformone.setFieldValue("job_title", e ? e.label : "")
                          // setOrgNameID(e.value)
                        }
                      />
                    </SoftBox>
                  )}
                  {/* <Card >
                                                        <SoftSelect   options={reporting} />
                                                    </Card> */}
                  {jobpostformone.touched.job_title && jobpostformone.errors.job_title ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.job_title}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    Job Type
                  </SoftTypography>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      placeholder="Select job type"
                      options={jobType}
                      onChange={(e) => {
                        jobpostformone.setFieldValue("job_type", e ? e.label : "");
                      }}
                    />
                  </SoftBox>
                  {jobpostformone.touched.job_type && jobpostformone.errors.job_type ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.job_type}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Valid Till
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    type="datetime-local"
                    placeholder=""
                    name="jobvaliddt"
                    id="jobvaliddt"
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.jobvaliddt}
                  />
                  {jobpostformone.touched.jobvaliddt && jobpostformone.errors.jobvaliddt ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.jobvaliddt}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    No.of Professional
                  </SoftTypography>
                  <SoftInput
                    type="number"
                    placeholder=""
                    name="applicantct"
                    id="applicantct"
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.applicantct}
                  />
                  {jobpostformone.touched.applicantct && jobpostformone.errors.applicantct ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.applicantct}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    Role
                  </SoftTypography>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      placeholder="Select role"
                      options={jobRole}
                      onChange={(e) => {
                        jobpostformone.setFieldValue("designation", e ? e.label : "");
                      }}
                    />
                  </SoftBox>
                  {jobpostformone.touched.designation && jobpostformone.errors.designation ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.designation}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Date of Posting
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    type="date"
                    placeholder=""
                    name="jobpostdt"
                    id="jobpostdt"
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.jobpostdt}
                  />
                  {jobpostformone.touched.jobpostdt && jobpostformone.errors.jobpostdt ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.jobpostdt}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    Salary Range
                  </SoftTypography>
                  {/* <SoftBox display="flex" flexDirection="column">
                              {
                              
                              <Range
                                step={1}
                                defaultValue={150000}
                                min={100000}
                                max={300000}
                                onChange={(value) => {
                                  console.log("new value", value);
                                  setValue(value);
                                  jobpostform.setFieldValue("salary", value);
                                }}
                              />
                              <SoftTypography fontWeight="medium" fontSize="15px" lineHeight="15px">
                                Salary : {value}
                              </SoftTypography>
                             
                              {jobpostform.touched.salary && jobpostform.errors.salary ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {jobpostform.errors.salary}{" "}
                                </span>
                              ) : null}
                            </SoftBox> */}
                  {/* <SoftBox display="flex" flexDirection="row" gap="20px"> */}
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      options={salaryList}
                      placeholder="Select salary range"
                      onChange={(e) => {
                        jobpostformone.setFieldValue("salary", e ? e.label : "");
                      }}
                    />
                  </SoftBox>
                  {jobpostformone.touched.salary && jobpostformone.errors.salary ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.salary}{" "}
                    </span>
                  ) : null}
                  {/* <Card><SoftSelect options={reporting} /></Card>  */}
                  {/* <input type="range" width="100%" min={25000} max={90000}/> */}
                  {/* </SoftBox> */}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftBox
                    width="100%"
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Gender
                      </SoftTypography>
                      {/* <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox> */}
                    </SoftBox>
                  </SoftBox>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      placeholder="Select gender"
                      options={genderOption}
                      // value={
                      //   jobpostform.values.gender !== null
                      //     ? {
                      //         label: jobpostform.values.gender,
                      //         value: jobpostform.values.gender,
                      //       }
                      //     : jobpostform.setFieldValue("gender", "")
                      // }
                      onChange={(e) => {
                        // jobpostform.values.gender !== null
                        //   ? jobpostform.setFieldValue("gender", e.label)
                        //   : jobpostform.setFieldValue("gender", "");
                        jobpostformone.setFieldValue("gender", e ? e.label : "");
                      }}
                    />
                  </SoftBox>
                  {jobpostformone.touched.gender && jobpostformone.errors.gender ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.gender}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    {/* Age: {`${age[0]} - ${age[1]}`} */}
                    Age: {agenew}
                  </SoftTypography>
                  <SoftBox>
                    {/* <PrettoSlider
                                // defaultValue={26}
                                aria-label="pretto slider"
                                valueLabelDisplay="auto"
                                min={18}
                                max={65}
                                value={age}
                                onChange={handleAgeChange}
                                // name="age"
                                // id="age"
                                // onChange={jobpostform.handleChange}
                              /> */}
                    <ThemeProvider theme={TermSlider}>
                      <Slider
                        name="termValue"
                        // getAriaLabel={() => "Minimum distance shift"}
                        // disableSwap
                        valueLabelDisplay="auto"
                        aria-label="Default"
                        min={18}
                        max={65}
                        value={agenew}
                        defaultValue={26}
                        onChange={handleAgeChangenew}
                      />
                    </ThemeProvider>
                  </SoftBox>

                  {jobpostformone.touched.age && jobpostformone.errors.age ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.age}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SoftBox width="100%" pl={2} display="flex">
                  <Form.Check
                    type="checkbox"
                    id="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        Setallstate(true);
                      } else {
                        Setallstate(false);
                      }
                    }}
                  />
                  <SoftTypography
                    fontWeight="medium"
                    fontSize="16px"
                    lineHeight="20px"
                    ml={2}
                    mt={1}
                  >
                    Pan India
                  </SoftTypography>
                </SoftBox>
              </Grid>

              {!allstate && (
                <>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          State
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <Select
                          styles={stylesDD}
                          isClearable
                          options={state}
                          onChange={(currentTarget) => (
                            // setSelectedState(currentTarget.value),
                            // !allstate
                            //   ? jobpostform.setFieldValue("state", currentTarget.value)
                            //   : ""
                            setSelectedState(currentTarget ? currentTarget.value : ""),
                            !allstate
                              ? jobpostformone.setFieldValue(
                                "state",
                                currentTarget ? currentTarget.value : ""
                              )
                              : ""
                          )}
                        />
                      </SoftBox>
                      {jobpostformone.touched.state && jobpostformone.errors.state ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.state}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SoftBox width="100%">
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        District
                      </SoftTypography>
                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <Select
                          styles={stylesDD}
                          isClearable
                          isMulti
                          options={district}
                          onChange={(e) => (
                            // !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                            console.log("multi district:", e.map((ele) => ele.value).join(",")),
                            !allstate
                              ? jobpostformone.setFieldValue(
                                "district",
                                e.map((ele) => ele.value).join(",")
                              )
                              : ""
                          )}
                        />
                      </SoftBox>
                      {jobpostformone.touched.district && jobpostformone.errors.district ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.district}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    Highest Education
                  </SoftTypography>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      isMulti
                      options={education}
                      // onChange={(e) =>
                      //   jobpostform.setFieldValue("qualification", e ? e.value : "")
                      // }
                      onChange={(e) => (
                        // !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                        console.log("multi education:", e.map((ele) => ele.value).join(",")),
                        jobpostformone.setFieldValue(
                          "qualification",
                          e.map((ele) => ele.value).join(",")
                        )
                      )}
                    />
                  </SoftBox>
                  {jobpostformone.touched.qualification && jobpostformone.errors.qualification ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.qualification}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    Stream
                  </SoftTypography>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      options={streams}
                      onChange={(e) => jobpostformone.setFieldValue("stream", e ? e.value : "")}
                    />
                  </SoftBox>
                  {jobpostformone.touched.stream && jobpostformone.errors.stream ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.stream}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    Specialization
                  </SoftTypography>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      options={degree}
                      onChange={(e) =>
                        jobpostformone.setFieldValue("specialization", e ? e.value : "")
                      }
                    />
                  </SoftBox>
                  {jobpostformone.touched.qualification && jobpostformone.errors.qualification ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.qualification}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    Experience
                  </SoftTypography>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      options={exp}
                      onChange={(e) => jobpostformone.setFieldValue("Experiance", e ? e.value : "")}
                    />
                  </SoftBox>

                  {jobpostformone.touched.Experiance && jobpostformone.errors.Experiance ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.Experiance}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SoftBox
                  width="100%"
                  pl={2}
                  display="flex"
                  flexDirection="row"
                  // alignItems="center"
                  gap="30px"
                // justifyContent=""
                >
                  <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                    Work from home availability
                  </SoftTypography>
                  {/* <Card>
                              <SoftSelect
                                options={wfm}
                                onChange={(e) => jobpostform.setFieldValue("workfromhome", e.value)}
                              />
                            </Card> */}
                  <SoftBox display="flex" flexDirection="row" alignItems="center" gap="20px">
                    <SoftBox display="flex" flexDirection="row" gap="5px">
                      <input
                        type="radio"
                        value="Yes"
                        name="keyword"
                        // defaultChecked
                        // checked={jobpostform.workfromhome === "Yes"}
                        style={{ cursor: "pointer" }}
                        onChange={(e) => (
                          console.log("valuesss ", e.target.value),
                          jobpostformone.setFieldValue("workfromhome", e.target.value),
                          // )
                          console.log("value radio ", jobpostformone.workfromhome)
                        )}
                      // onChange={jobpostform.handleChange}
                      />
                      {/* <input
                                  type="checkbox"
                                  name="checkbox"
                                  value
                                  checked={check}
                                  onChange={() => setCheck(!check)}
                                  placeholder=""
                                /> */}
                      <SoftTypography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "small",
                          fontSize: "15px",
                          lineHeight: "19px",
                        }}
                      >
                        Yes
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="row" gap="5px">
                      <input
                        type="radio"
                        value="No"
                        name="keyword"
                        style={{ cursor: "pointer" }}
                        // checked={jobpostform.workfromhome === "No"}
                        onChange={(e) => (
                          console.log("valuesss ", e.target.value),
                          jobpostformone.setFieldValue("workfromhome", e.target.value),
                          console.log("value radio ", jobpostformone.workfromhome)
                        )}
                      />
                      <SoftTypography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "small",
                          fontSize: "15px",
                          lineHeight: "19px",
                        }}
                      >
                        No
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      gap="5px"
                      sx={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value="Hybrid"
                        name="keyword"
                        // style={{ cursor: "pointer" }}
                        // checked={jobpostform.workfromhome === "Hybrid"}
                        onChange={(e) => (
                          console.log("valuesss ", e.target.value),
                          jobpostformone.setFieldValue("workfromhome", e.target.value),
                          console.log("value radio ", jobpostformone.workfromhome)
                        )}
                      />
                      <SoftTypography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "small",
                          fontSize: "15px",
                          lineHeight: "19px",
                          // cursor: "pointer",
                        }}
                      >
                        Hybrid
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <SoftBox pl={2}>
                  {jobpostformone.touched.workfromhome && jobpostformone.errors.workfromhome ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.workfromhome}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SoftBox width="100%">
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      WhatsApp Job Description
                    </SoftTypography>

                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>

                  <SoftInput
                    type="text"
                    placeholder=""
                    multiline
                    rows={5}
                    id="whatsapp_jd"
                    name="whatsapp_jd"
                    inputProps={{
                      maxLength: "200",
                    }}
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.whatsapp_jd}
                  />
                  <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                    {jobpostformone.values.whatsapp_jd?.length}/{addressChar}
                  </SoftTypography>

                  {jobpostformone.touched.whatsapp_jd && jobpostformone.errors.whatsapp_jd ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.whatsapp_jd}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                  Job Description
                </SoftTypography>

                <Box sx={{ background: "#FFFFFF" }}>
                  <ReactQuill
                    value={jobDes || ""}
                    defaultValue=""
                    modules={modules}
                    formats={formats}
                    onChange={handleTexteditorChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ p: 1, m: 1, pt: 0 }}>
              <SoftBox
                sx={{
                  height: "45px",
                  backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  width: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={jobpostformone.handleSubmit}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
                >
                  Create Bucket
                </SoftTypography>
              </SoftBox>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
};
NewViewCandidates.propTypes = {
  orgname: PropTypes.string.isRequired,
  jobrole: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  buttonFunc: PropTypes.func.isRequired,
  // orgId: PropTypes.string.isRequired,
  // jobId: PropTypes.string.isRequired,
  shortData: PropTypes.array.isRequired,
  setShortData: PropTypes.array.isRequired,
  handleShortlistedApi: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  ViewPage: PropTypes.string.isRequired,
};
export default NewViewCandidates;
