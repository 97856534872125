import Grid from "@mui/material/Unstable_Grid2";
import { Card, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import SoftInput from "components/SoftInput";
import axios from "axios";
import { useDispatch } from 'react-redux'

import Swal from "sweetalert2";
import "../style.css";
import SoftSelect from "components/SoftSelect";
import img from "../../assets/images/job-fair (1) 1.png";
import adminLogo from "../../assets/images/admin-logo.png";
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual'


const Register = () => {
  //   const totalIncome = useMemo(() => {
  //     return incomeChart.datasets[0].data.reduceRight((sum, curr) => {
  //       console.log(sum, curr);
  //       return sum + curr;
  //     }, 0);
  //   }, [incomeChart]);

  /* sx={{ width: "100%" }}*/
  const translate = useTranslate()
  const dispatch = useDispatch()


  const [showFile, setShowFile] = useState(false);
  const [actionModal, setactionModal] = useState(false);
  const [actionModal2, setactionModal2] = useState(false);
  const [instructionModal, setInstructionModal] = useState(false);
  const [CSVFile, setCSVFile] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [selectedDS, setSelectedDS] = useState("");
  const navigate = useNavigate();
  const bulkUploadfunc = () => {
    let formData = new FormData();
    formData.append("jobseeker_file", CSVFile);
    formData.append("datasource", selectedDS);
    formData.append("partner_association", "samarth");
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/bulk/upload`,
        formData
      )
      .then((res) => {
        console.log("res data", res);
        const uniqueErr = [...new Set(res.data["error_data"].map((ele) => ele.error))];
        if (res.status === 200) {
          Swal.fire({
            icon: res.data["error_data"].length !== 0 ? "warning" : "success",
            title: res.data["error_data"].length !== 0 ? "Please check your data" : "Success",
            text:
              res.data["error_data"].length !== 0
                ? uniqueErr.map((ele) => `${ele} ${" "}`)
                : "File Uploaded successfully",
            showConfirmButton: false,
            timer: 3000,
          });
          setactionModal2(false);
        }
      })
      .catch((err) => {
        console.log("error ", err);
        Swal.fire({
          icon: "error",
          title: "...Oops",
          text: err.response.data["error"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const handleResume = (File) => {
    const validExt = ["jpeg", "png"];
    const fileExt = File.name.split(".")[1];
    return validExt.includes(fileExt);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file);
    if (file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };
  const fetchDataSource = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`)
      .then((res) => {
        console.log("datasource", res.data["datasource_list"]);
        // setDataSource(res.data["datasource_list"]);
        setDataSource(res.data["datasource_list"].map((ele, i) => ({ label: ele, value: ele })));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    fetchDataSource();
    // dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);



  console.log("object", dataSource);
  console.log("seleced ds", selectedDS);
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={2}>
          <SoftTypography fontSize="24px" mb={2} fontWeight="medium">
          {translate('register')}       
             </SoftTypography>
        </SoftBox>
        <SoftBox mt={10} mb={8}>
          <SoftBox>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8} container spacing={3}>
                <Grid item xs={12} md={6}>
                  {/* <Basic /> */}
                  {/* <Template/> */}
                  <Card>
                    <Link to="/admin-registration-page">
                      <SoftBox p={1} textAlign="center" bgColor="#3B5092" color="#fff">
                        <SoftBox width="100%" pt={1}>
                          <img src={adminLogo} height={"40px"} alt="" />
                        </SoftBox>
                        <SoftBox>
                          <SoftTypography variant="div" color="white" fontSize="14px">
                            {/* {title} */}
                            {translate('admin1')}       
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Link>
                  </Card>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  {/* <Success /> */}
                  <Card>
                    <Link to="/jobprovider-registration">
                      <SoftBox p={1} textAlign="center" bgColor="#3B5092" color="#fff">
                        <SoftBox width="100%" pt={1}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="45"
                            height="40"
                            viewBox="0 0 13 18"
                            fill="none"
                          >
                            <path
                              d="M4.181 12.5717L6.16674 17.1137C6.22461 17.2436 6.35457 17.3302 6.49903 17.3302C6.64349 17.3302 6.77345 17.2436 6.83117 17.1135L8.81691 12.5716C11.3226 11.6185 12.9979 9.19578 12.9979 6.49895C12.9979 2.91369 10.0842 0 6.49895 0C2.91369 0 0 2.91369 0 6.49895C0 9.19597 1.67537 11.6188 4.18099 12.572L4.181 12.5717ZM6.49904 1.80516C9.08786 1.80516 11.1926 3.91012 11.1926 6.49871C11.1926 7.93923 10.5247 9.31132 9.38728 10.1959C9.34028 10.2357 9.28618 10.2754 9.23556 10.3115C9.18857 10.3476 9.14172 10.3801 9.08747 10.4162C8.32586 10.9215 7.42692 11.1923 6.49896 11.1923C5.56388 11.1923 4.66479 10.9215 3.90305 10.4088C3.87419 10.3943 3.81647 10.3511 3.75859 10.3113C3.70797 10.2752 3.6575 10.2391 3.61051 10.1994C2.47686 9.3113 1.80535 7.94284 1.80535 6.49868C1.80535 3.90986 3.91015 1.80513 6.4989 1.80513L6.49904 1.80516Z"
                              fill="white"
                            />
                            <path
                              d="M4.17563 9.72336L4.25512 9.77746L4.266 9.78471L4.30574 9.80995C4.94464 10.2432 5.70274 10.4708 6.49712 10.4708C7.28427 10.4708 8.03876 10.2433 8.68506 9.81358C8.7248 9.78835 8.75729 9.76659 8.79341 9.74135C8.8475 9.69798 8.88725 9.67275 8.91973 9.64389C9.07145 9.52481 9.21213 9.39833 9.34224 9.26113C9.24478 8.67255 8.97036 8.1346 8.54074 7.70498C8.47214 7.63637 8.39991 7.5714 8.32768 7.51367C7.86908 8.00117 7.2193 8.30442 6.49714 8.30442C5.77862 8.30442 5.12869 8.00114 4.66661 7.51727C4.13953 7.95049 3.76766 8.56793 3.64844 9.26835C3.77476 9.3983 3.90835 9.52116 4.0528 9.63298C4.10705 9.67635 4.14314 9.69798 4.17563 9.72336Z"
                              fill="white"
                            />
                            <path
                              d="M8.30594 5.77782C8.30594 6.77482 7.49764 7.58313 6.50063 7.58313C5.50362 7.58313 4.69531 6.77482 4.69531 5.77782C4.69531 4.78081 5.50362 3.97266 6.50063 3.97266C7.49764 3.97266 8.30594 4.78081 8.30594 5.77782Z"
                              fill="white"
                            />
                          </svg>
                        </SoftBox>
                        <SoftBox>
                          <SoftTypography variant="div" color="white" fontSize="14px">
                            {/* {title} */}
                            {translate('jobProvider')}       
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Link>
                  </Card>
                </Grid>
              
                <Grid item xs={12} md={6} style={{ margin: "auto" }}>
                  {/* <Basic /> */}
                  {/* <Template/> */}
                  <Card>
                    <Link to="/counselor-registration-page">
                      <SoftBox p={1} textAlign="center" bgColor="#3B5092" color="#fff">
                        <SoftBox width="100%" pt={1}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="45"
                            height="40"
                            viewBox="0 0 53 71"
                            fill="none"
                          >
                            <path
                              d="M52.0668 68.6333C52.0673 69.2609 51.818 69.8631 51.3742 70.3074C50.9299 70.7512 50.3277 
                                                            71.0005 49.7001 71H2.36668C1.7391 71.0005 1.13685 70.7512 0.692569 70.3074C0.248825 69.8631 -0.000526594 69.2609 8.35015e-07 68.6333C8.35015e-07 61.7287 2.74277 55.1068 7.62514 50.2247C12.5075 45.3423 19.1288 42.5995 26.0338 42.5995C32.9388 42.5995 39.5603 45.3423 44.4424 50.2247C49.3248 55.107 52.0676 61.7283 52.0676 68.6333H52.0668ZM26.0334 9.46655C22.2673 9.46655 18.6555 10.9626 15.9925 13.6257C13.3295 16.2888 11.8334 19.9005 11.8334 23.6666C11.8334 27.4327 13.3294 31.0445 15.9925 33.7075C18.6556 36.3705 22.2673 37.8666 26.0334 37.8666C29.7995 37.8666 33.4113 36.3706 36.0743 33.7075C38.7373 31.0444 40.2334 27.4327 40.2334 23.6666C40.2292 19.9015 38.732 16.2924 36.0696 13.6304C33.4075 10.9678 29.7984 9.47074 26.0334 9.46655ZM4.73337 30.7666C5.98801 30.765 7.19146 30.2658 8.07835 29.3782C8.96585 28.4913 9.46509 27.2878 9.46671 26.0332V21.2999C9.46671 19.6089 8.56441 18.0462 7.10004 17.2006C5.63568 16.3553 
                                                            3.83106 16.3553 2.3667 17.2006C0.902338 18.0463 2.76615e-05 19.609 2.76615e-05 21.2999V26.0332C0.00161252 27.2879 0.500829 28.4913 1.38838 29.3782C2.27536 30.2657 3.47876 30.765 4.73337 30.7666ZM26.0334 4.73321C32.4985 4.73955 38.5128 8.04709 41.9821 13.5031C43.3386 12.5845 44.9124 12.0361 46.5463 11.913C43.7814 7.06607 39.3893 3.35553 34.1493 1.43785C28.9088 -0.479285 23.159 -0.479285 17.918 1.43785C12.6781 3.35553 8.28594 7.06607 5.521 11.913C7.15495 12.0361 8.72871 12.5844 10.0853 13.5031C13.5539 8.04709 19.5683 4.73956 26.0339 4.73321H26.0334ZM47.3334 16.5666C46.0788 16.5681 44.8753 17.0674 43.9885 17.9549C43.1009 18.8419 42.6017 20.0453 42.6001 21.2999V26.0332C42.6027 27.6381 43.4194 29.1321 44.7692 30.0006C44.4078 31.5611 43.5288 32.9542 42.2757 33.9527C41.0226 34.9511 39.4689 35.4963 37.8666 35.4999C37.0209 35.4999 36.2396 35.9511 35.8169 36.6833C35.3943 37.4155 35.3943 38.3177 35.8169 39.0499C36.2396 
                                                            39.7821 37.0209 40.2333 37.8666 40.2333C40.685 40.2253 43.4077 39.2111 45.5441 37.3727C47.6805 35.5344 49.0894 32.9927 49.5173 30.2072C50.2827 29.8105 50.9251 29.212 51.3746 28.4761C51.8242 27.7402 52.0635 26.8954 52.0667 26.0334V21.3C52.0651 20.0454 51.5659 18.8419 50.6783 17.955C49.7913 17.0675 48.5879 16.5683 47.3333 16.5667L47.3334 16.5666Z"
                              fill="white"
                            />
                          </svg>
                        </SoftBox>
                        <SoftBox>
                          <SoftTypography variant="div" color="white" fontSize="14px">
                            {/* {title} */}
                            {translate('counsellor')}       
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} style={{ margin: "auto" }}>
                  {/* <Basic /> */}
                  {/* <Template/> */}
                  <Card>
                    <Link to="/channel-partner-registration-page">
                      <SoftBox p={1} textAlign="center" bgColor="#3B5092" color="#fff">
                        <SoftBox width="100%" pt={1}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="45"
                            height="40"
                            viewBox="0 0 76 69"
                            fill="none"
                          >
                            <path
                              d="M34.5125 65.0027L39.4904 60.0248C40.1079 59.4073 40.4417 58.6043 40.4417 57.74C40.4417 56.8757 40.1085 56.0603 39.4904 55.4553C38.8728 54.8377 38.0699 54.5039 37.2056 54.5039C36.3657 54.5039 35.5628 54.8252 34.9578 55.4177L28.3745 62.001C28.362 62.0135 28.3501 62.0255 28.325 62.038L28.2756 62.0874C27.658 62.705 27.3242 63.5079 27.3242 64.3722C27.3242 65.2365 27.6574 66.052 28.2756 66.657C29.4982 67.8796 31.6351 67.8796 32.8582 66.657L34.5125 65.0027Z"
                              fill="white"
                            />
                            <path
                              d="M33.2108 53.7432C33.2233 53.7307 33.2352 53.7188 33.2602 53.7063L35.6565 51.31C36.2741 50.6924 36.6079 49.8895 36.6079 49.0252C36.6079 48.1609 36.2746 47.3454 35.6565 46.7404C35.039 46.1229 34.236 45.7891 33.3717 45.7891C32.5074 45.7891 31.692 46.1223 31.087 46.7404L22.1193 55.7081C21.5018 56.3256 21.168 57.1285 21.168 57.9928C21.168 58.8571 21.5012 59.6726 22.1193 60.2776C23.33 61.4883 25.4294 61.5002 26.6526 60.3146L33.2108 53.7432Z"
                              fill="white"
                            />
                            <path
                              d="M14.9352 46.8975L21.5559 40.2768C22.1734 39.6592 22.5072 38.8563 22.5072 37.992C22.5072 37.1277 22.174 36.3122 21.5559 35.7072C20.9383 35.0897 20.1354 34.7559 19.2711 34.7559C18.4068 34.7559 17.5913 35.0891 16.9863 35.7072L10.3537 42.3279C9.73614 42.9455 9.40234 43.7484 9.40234 44.6127C9.40234 45.477 9.73558 46.2925 10.3537 46.8975C11.6252 48.1576 13.6755 48.1576 14.9352 46.8975Z"
                              fill="white"
                            />
                            <path
                              d="M29.6766 44.724C30.2942 44.1065 30.628 43.3036 30.628 42.4393C30.628 41.575 30.2947 40.7595 29.6766 40.1545C29.0465 39.5244 28.2191 39.2031 27.3918 39.2031C26.5645 39.2031 25.7365 39.5244 25.107 40.1545L15.6209 49.6407C14.3608 50.9008 14.3608 52.9632 15.6209 54.2233C16.881 55.4834 18.9434 55.4834 20.2035 54.2233L29.6766 44.724Z"
                              fill="white"
                            />
                            <path
                              d="M44.3378 62.8217L42.0649 60.5488C41.8181 60.9691 41.5463 61.3643 41.1881 61.7225L37.0625 65.8481L39.187 67.9726C40.5455 69.3436 42.9668 69.3436 44.3378 67.9726C45.0293 67.2811 45.4126 66.3668 45.4126 65.4035C45.4126 64.4402 45.0418 63.5384 44.3629 62.8469L44.3378 62.8217Z"
                              fill="white"
                            />
                            <path
                              d="M5.79417 25.1512L20.6398 9.41466L21.4183 8.58727L16.1441 3.60938L0 20.7289L5.2622 25.7062L5.79417 25.1512Z"
                              fill="white"
                            />
                            <path
                              d="M44.7238 22.0607L43.4142 23.3823C39.9062 26.8903 34.2123 26.8903 30.7043 23.3823C28.4684 21.1464 28.4684 17.5149 30.7043 15.2796L33.4588 12.5251C29.9263 10.9443 25.9737 10.5115 22.1325 11.3269L7.7917 26.519C7.14912 30.8299 8.08797 35.2517 10.3608 38.9201L15.2892 33.9917C17.4876 31.7933 21.0701 31.7933 23.2684 33.9917C24.2317 34.955 24.7384 36.1783 24.8617 37.4253C26.9737 36.363 29.6173 36.6843 31.3835 38.4505C32.4458 39.5128 33.0389 40.9333 33.0389 42.4401C33.0389 42.7734 33.0019 43.1071 32.9399 43.4284C34.5207 43.305 36.1516 43.8236 37.3617 45.0343C38.424 46.0965 39.017 47.517 39.017 49.0239C39.017 50.16 38.6838 51.2472 38.0538 52.1736C39.2024 52.3465 40.3141 52.8532 41.2035 53.7425C42.2658 54.8047 42.8589 56.2253 42.8589 57.7321C42.8589 57.7941 42.8463 57.8555 42.8463 57.9175L46.058 61.1291C46.0705 61.1416 46.0705 61.1416 46.0824 61.1536L48.7135 63.7847C50.0971 65.1682 52.4934 65.1682 53.8763 63.7847C55.2968 62.3642 55.2968 60.0669 53.8888 58.6463L44.0569 48.8144C43.5878 48.3453 43.5878 47.5918 44.0569 47.1221C44.5261 46.653 45.2795 46.653 45.7492 47.1221L55.9018 57.2747C57.2603 58.6332 59.7061 58.6332 61.0401 57.2747C62.4606 55.8417 62.4606 53.5319 61.0401 52.112L54.4938 45.5657C54.0247 45.0965 54.0247 44.3431 54.4938 43.8734C54.963 43.4042 55.7164 43.4042 56.1861 43.8734L60.7564 48.4186C62.1274 49.7771 64.5482 49.7771 65.9073 48.4186C66.5987 47.7271 66.982 46.8128 66.982 45.8495C66.982 44.8737 66.5993 43.96 65.9073 43.2804L62.3497 39.3773L44.7238 22.0607Z"
                              fill="white"
                            />
                            <path
                              d="M54.3789 4.97724L70.0776 21.6032V21.6157L70.5342 22.0974L75.7964 17.1195L59.6523 0L54.3789 4.97724Z"
                              fill="white"
                            />
                            <path
                              d="M52.7832 6.79081C47.1876 5.97536 41.5183 7.85305 37.5416 11.8301L32.3908 16.9685C31.0937 18.2656 31.0937 20.3775 32.3908 21.6746C34.9599 24.2437 39.1349 24.2437 41.7042 21.6746L43.8656 19.5131C44.1005 19.2782 44.4093 19.1674 44.718 19.1674C45.0268 19.1674 45.3231 19.2782 45.5579 19.5131L63.184 36.8422C66.729 33.1119 68.4826 28.097 68.0505 22.9587L52.7832 6.79081Z"
                              fill="white"
                            />
                          </svg>
                        </SoftBox>
                        <SoftBox>
                          <SoftTypography variant="div" color="white" fontSize="14px">
                            {/* {title} */}
                            {translate('channelpartner')}       
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Link>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6} style={{ margin: "auto" }}>
                  {/* <Basic /> */}
                  {/* <Template/> */}
                  <Card>
                    <Link to="/job-fair-registration-page">
                      <SoftBox p={1} textAlign="center" bgColor="#3B5092" color="#fff">
                        <SoftBox width="100%" pt={1}>
                          <img src={img} height={"45px"} alt="" />
                        </SoftBox>
                        <SoftBox>
                          <SoftTypography variant="div" color="white" fontSize="14px">
                            {/* {title} */}
                            {translate('jobfair')}       
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Link>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>

      {/* Modal for Job seekers */}
      <Modal
        show={actionModal}
        onHide={() => {
          setactionModal(false);
          // showAlert();
        }}
        size="sm"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <SoftBox display="flex" flexDirection="column">
            <SoftBox
              // pl={2}
              sx={{
                // height: "45px",
                // backgroundColor: "#3B5092",
                // borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              // width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => {
                // setApplyModal(true);
                setactionModal(false);
                // setStep1(false);
                // setStep2(true);
                // navigate(
                //   "http://job-center-uat.s3-website.ap-south-1.amazonaws.com/?centerid=JC1&centerhead=ADM10&centeremployee=ADM15"
                // );
              }}
            >
              <Link to="http://job-center-uat.s3-website.ap-south-1.amazonaws.com/?centerid=JC1&centerhead=ADM10&centeremployee=ADM15">
                <SoftButton my="14px" display="flex" justifyContent="center">
                  <SoftTypography>Platform Registration</SoftTypography>
                </SoftButton>
              </Link>
            </SoftBox>

            <SoftBox
              // pl={2}
              sx={{
                // height: "45px",
                // backgroundColor: "#3B5092",
                // borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              // width={{ lg: "225px", sm: "100%", xs: "50%" }}
              onClick={() => {
                setactionModal2(true);
                setactionModal(false);
                setShowFile(false);
                // handleConsPost();
                // setStep1(false);
                // setStep2(true);
              }}
            >
              <SoftButton my="14px" display="flex" justifyContent="center">
                <SoftTypography>Bulk Upload</SoftTypography>
              </SoftButton>
            </SoftBox>
            <SoftBox
              sx={{
                // height: "45px",
                // backgroundColor: "#3B5092",
                // borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link to="/jobseekar-registration">
                <SoftButton my="14px" display="flex" justifyContent="center">
                  <SoftTypography>Single Entry</SoftTypography>
                </SoftButton>
              </Link>
            </SoftBox>
          </SoftBox>
        </Modal.Body>
      </Modal>
      {/* Modal for Job seekers */}
      {/* Modat 1 */}
      <Modal
        show={actionModal2}
        onHide={() => {
          setactionModal2(false);
          // showAlert();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select from below options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SoftBox display="flex" flexDirection="row" gap="20px">
            {!showFile && (
              <>
                <SoftBox
                  // pl={2}
                  margin="auto"
                  sx={{
                    height: "35px",
                    backgroundColor: "#3B5092",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width="150px"
                  p="10px 20px"
                  // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    // navigate('/search/newsearch/recommend-search/allshortlist');
                    //activate modal2
                    // setShowModal2(true);
                    setactionModal(false);
                    setInstructionModal(true);
                  }}
                >
                  <Link to="/js_template.xlsx" target="_blank" download>
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "medium",
                        lineHeight: "15px",
                      }}
                    >
                      Download Template
                    </SoftTypography>
                  </Link>
                </SoftBox>
                <SoftBox
                  // pl={2}
                  margin="auto"
                  sx={{
                    height: "35px",
                    backgroundColor: "#3B5092",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width="150px"
                  p="10px 20px"
                  // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    // navigate('/search/newsearch/recommend-search/allshortlist');
                    //activate modal3
                    // setShowModal3(true);
                    setactionModal(false);
                    setShowFile(true);
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "medium",
                      lineHeight: "15px",
                    }}
                  >
                    Bulk Upload
                  </SoftTypography>
                </SoftBox>
              </>
            )}
            {showFile && (
              //   <SoftBox margin="auto" width="150px" p="10px 20px" display="flex" flexDirection="row">
              <SoftBox display="flex" flexDirection="column" gap="20px" width="100%">
                <SoftInput
                  type="file"
                  //   accept="file/csv"
                  onChange={(e) => {
                    // const file = e.target.files[0],
                    console.log("resumefile ", e.target.files[0]), setCSVFile(e.target.files[0]);
                  }}
                />
                <SoftSelect options={dataSource} onChange={(e) => setSelectedDS(e.value)} />
                <SoftBox display="flex" flexDirection="row" gap="10px">
                  <SoftBox
                    margin="auto"
                    sx={{
                      height: "35px",
                      backgroundColor: "#3B5092",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    width="150px"
                    p="10px 20px"
                    onClick={bulkUploadfunc}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "medium",
                        lineHeight: "15px",
                      }}
                    >
                      Upload
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    margin="auto"
                    sx={{
                      height: "35px",
                      backgroundColor: "#3B5092",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    width="150px"
                    p="10px 20px"
                    onClick={() => setShowFile(false)}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "medium",
                        lineHeight: "15px",
                      }}
                    >
                      Back
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                {/* </SoftBox> */}
              </SoftBox>
            )}
          </SoftBox>
        </Modal.Body>
      </Modal>
      {/* Modat 1 */}
      {/* instruction modal */}
      <Modal
        show={instructionModal}
        onHide={() => {
          setInstructionModal(false);
          // showAlert();
        }}
        size="xl"
        centered
        // sx={{ marginTop: "50px" }}
        // mt="78px"
      >
        <Modal.Header closeButton>
          <Modal.Title>Instruction to follow while uploading the data in csv file</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SoftBox display="flex" flexDirection="column" gap="20px">
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Column Name</SoftTypography>
              <SoftTypography width="15%">Sample Entry</SoftTypography>
              <SoftTypography width="35%">Guide</SoftTypography>
              <SoftTypography width="35%">Link</SoftTypography>
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Mobile No</SoftTypography>
              <SoftTypography width="15%">9859859855</SoftTypography>
              <SoftTypography width="35%">
                Please set the column cell for mobile number as Text format. Click on the link to
                know how to do this setting{" "}
              </SoftTypography>
              <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-numbers-as-text-583160db-936b-4e52-bdff-6f1863518ba4">
                  https://support.microsoft.com/en-us/office/format-numbers-as-text-583160db-936b-4e52-bdff-6f1863518ba4
                </a>
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Date of birth</SoftTypography>
              <SoftTypography width="15%">1994-11-05</SoftTypography>
              <SoftTypography width="35%">
                Please set the column cell for date of birth in YYYY-MM-DD format . Click on the
                link to know how to do this setting{" "}
              </SoftTypography>
              <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">First Name</SoftTypography>
              <SoftTypography width="15%">Ramesh</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Last Name</SoftTypography>
              <SoftTypography width="15%">Pawar</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Email</SoftTypography>
              <SoftTypography width="15%">ramesh@gmail.com</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Gender</SoftTypography>
              <SoftTypography width="15%">Male</SoftTypography>
              <SoftTypography width="35%">
                Please enter from options (Male,Female,All)
              </SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">State</SoftTypography>
              <SoftTypography width="15%">Maharashtra</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">District</SoftTypography>
              <SoftTypography width="15%">Mumbai</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Taluka</SoftTypography>
              <SoftTypography width="15%">Andheri</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Current Salary</SoftTypography>
              <SoftTypography width="15%">10000-15000</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Desired Salary</SoftTypography>
              <SoftTypography width="15%">15000-20000</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Experience</SoftTypography>
              <SoftTypography width="15%">0-1</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Job Role</SoftTypography>
              <SoftTypography width="15%">Waiter</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Industry</SoftTypography>
              <SoftTypography width="15%">Hospitality and Management</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Language</SoftTypography>
              <SoftTypography width="15%">Hindi</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Data source</SoftTypography>
              <SoftTypography width="15%">Naukri</SoftTypography>
              <SoftTypography width="35%">Enter form you are uploading the data</SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Degree</SoftTypography>
              <SoftTypography width="15%">Bachelors of Ed</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Highest Education</SoftTypography>
              <SoftTypography width="15%">HSC</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Stream</SoftTypography>
              <SoftTypography width="15%">Commerce</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Part Time</SoftTypography>
              <SoftTypography width="15%">No</SoftTypography>
              <SoftTypography width="35%">Enter Yes or No</SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Skills</SoftTypography>
              <SoftTypography width="15%">Communications</SoftTypography>
              <SoftTypography width="35%"></SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="15px">
              <SoftTypography width="15%">Resume Link</SoftTypography>
              <SoftTypography width="15%"></SoftTypography>
              <SoftTypography width="35%">Enter link of resume</SoftTypography>
              {/* <SoftTypography width="35%">
                <a href="https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e">
                  https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
                </a>
              </SoftTypography> */}
            </SoftBox>
          </SoftBox>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
