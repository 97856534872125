/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "routes";
import jfadminroute from "./SidenavRoutes/jfadminroute";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ct.png";

// routes components
import Login from "pages/Login/Login";
import AdminLogin from "pages/Login/AdminLogin";
import CPLogin from "pages/Login/ChannelPartnerLogin";
import CenterHeadLogin from "pages/Login/CenterHeadLogin";
import CenterEmployeeLogin from "pages/Login/CenterEmployeeLogin";
// import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
import SignUpBasic from "layouts/authentication/sign-up/basic";
// import SignUpCover from "layouts/authentication/sign-up/cover";
import AdminRegistration from "pages/Admin/registrationPage";
import Channelprtnerregister from "pages/Channelpartner/channelprtnerregister";
import JobFairRegistration from "pages/Jobfair/jobfairRegistration";
import Centerreg from "pages/Center/centerreg";
import CenterHeadRegistration from "pages/Centerhead/centerHeadRegistration";
import CenterHeadRegistration2 from "pages/Centerhead/centerHeadRegistration2";
import CenterEmployeeRegistration1 from "pages/Centeremployee/centerEmployeeRegistration1";
import CenterEmployeeRegistration2 from "pages/Centeremployee/centerEmployeeRegistration2";
import SuperAdminRegistraion from "pages/Suparadmin/superAdminRegisteration";
import Jobseekarreg from "pages/Jobseekar/jobseekarreg";
import Jobproviderreg from "pages/Jobprovider/jobproviderreg";
import CounsellourRegistration from "pages/Counsellour/counselloourRegistration";
import Forgotpassword1 from "pages/Forgotpassword/forgotpassword1";
import Roletable from "pages/roletable/roletable";
import Centerhead from "pages/Centerhead/centerhead";
import Centerheadpage from "Centerhead/layout/centerheadpage";
import Centerheadregister from "Centerhead/layout/centerheadregister";
import Centerheaddashboard from "Centerhead/layout/centerheaddashboard";
import Centerheadmaster from "Centerhead/layout/centerheadmaster";
import Centeremployeemaster from "Centerhead/layout/centeremployeemaster";
import Centerheadreg from "Centerhead/registerform/centerheadreg";
import Centeremployeereg from "Centerhead/registerform/centeremployeereg";
import Centerjobprovider from "Centerhead/registerform/centerjobprovider";
import Centerjobseeker from "Centerhead/registerform/centerjobseeker";
import Centerjobseekers from "Centerhead/layout/centerjobseekersmaster";
import Centerjobprovidermaster from "Centerhead/layout/centerjobprovidermaster";
import Channelpartnerpage from "Channelpartner/layout/channelpartnerpage";
import Channlpartendashboard from "Channelpartner/layout/channlpartendashboard";
import Channelpartnerregisterlist from "Channelpartner/layout/channelpartnerregisterlist";
import AdminDashboard from "Admin/layout/adminDashboard";
import AdminRegister from "Admin/layout/adminRegister";
import AdminOverview from "Admin/layout/adminOverview";
import AdminReg from "Admin/registrationForm/adminRegistration";
import AdmincenterReg from "Admin/registrationForm/admincenterReg";
import AdminchannelPartnerRegis from "Admin/registrationForm/adminchannelPartnerRegis";
import AdmincenterEmpReg from "Admin/registrationForm/admincenterEmpReg";
import AdmincenterheadReg from "Admin/registrationForm/admincenterheadReg";
import Admincenterheadreg2 from "Admin/registrationForm/admincenterheadreg2";
import Admincenter from "Admin/layout/admincenter";
import Admincenterhead from "Admin/layout/admincenterhead";
import Admincp from "Admin/layout/admincp";
import Admincenteremployee from "Admin/layout/admincenteremployee";
import AdminjobproviderReg from "Admin/registrationForm/adminjobproviderReg";
import Adminjobprovider from "Admin/layout/adminjobprovider";
import Adminjobseekars from "Admin/layout/adminjobseekars";
import Adminjobfair from "Admin/layout/adminjobfair";
import AdminjobfairReg from "Admin/registrationForm/adminjobfairReg";
import CenterEmployeePage from "Centeremployee/layout/centeremployeepage";
import CenterEmployeeRegisterList from "Centeremployee/layout/centeremployeeregisterlist";
import CenterEmployeeDashboard from "Centeremployee/layout/centeremployeedashboard";
import RecommendSearch from "pages/CandidateSearch/recommendSearch";
import RecommendShortlist from "pages/CandidateSearch/recommendShortlist";
import ViewCandidates from "pages/CandidateSearch/viewCandidates";
// import JobPosting from "pages/CandidateSearch/JobPostings/jobPosting";
import Recommandsearchlist from "pages/CandidateSearch/JobPostings/recommendedJobPostSearchList";
import JobProviderpage from "Jobprovider/layout/jobproviderPage";
import JobProviderDashboard from "Jobprovider/layout/jobproviderDashboard";
import JobProviderLogin from "pages/Login/JobProviderLogin";
import NewSearch from "pages/CandidateSearch/newSearch";
import JobPosting from "pages/CandidateSearch/JobPostings/jobPosting";
import CreatePosting from "pages/CandidateSearch/JobPostings/createPosting";
import PrivateRoute from "utils/PrivateRoute";
import EditPosting from "pages/CandidateSearch/JobPostings/editPosting";
import NewRecommendShortlist from "pages/CandidateSearch/SkillingShortlist/newRecommendShortlist";
import JobFairs from "pages/NewJobFair/jobfairs";
import JobFairDetails from "pages/NewJobFair/JobFairDetails";
import ProfilePage from "pages/NewJobFair/Profile";
import Overview from "pages/NewJobFair/Overview";
import JobFairAdminLogin from "pages/Login/JobFairAdminLogin";

// import CounselorPage from "Counselor/layout/counselorsPage";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, i) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          // <Route exact element={<PrivateRoute />} key={i}>
          <Route exact path={route.route} element={route.component} key={route.key} />
          // </Route>
        );
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );
  console.log("hostname", window.location.host.split(".")[0]); //window.location.host.split(".")[0]
  return (
    // direction === "rtl" ? (
    //   <CacheProvider value={rtlCache} >
    //     <ThemeProvider theme={themeRTL}>
    //       {/* <CssBaseline /> */}
    //       {layout === "dashboard" && (
    //         <>
    //           <Sidenav
    //             color={sidenavColor}
    //             brand={brand}
    //             brandName="Soft UI Dashboard PRO"
    //             routes={routes}
    //             onMouseEnter={handleOnMouseEnter}
    //             onMouseLeave={handleOnMouseLeave}
    //           />
    //           {/* <Configurator /> */}
    //           {configsButton}
    //         </>
    //       )}
    //       {/* {layout === "vr" && <Configurator />} */}
    //       <Routes>
    //         {getRoutes(routes)}
    //         {/* <Route path="*" element={<Navigate to="/agent/business" />}/> */}
    //         {/* routes for signIn and registration */}
    //         <Route path="/login" element={<Login/>} />
    //         <Route path="/forgotpassword1" element={<Forgotpassword1/>} />
    //         <Route path="/authentication/sign-in/basic" element={<SignInBasic/>} />
    //         <Route path="/authentication/sign-in/cover" element={<SignInCover/>} />
    //         <Route path="/authentication/sign-up/basic" element={<SignUpBasic/>} />
    //         <Route path="/authentication/sign-up/cover" element={<SignUpCover/>} />
    //         <Route path="/channel-partner-register" element={<Channelprtnerregister/>}/>
    //         <Route path="/job-center-register" element={<Centerreg/>}/>
    //         {/* routing for registration pages */}
    //         <Route path="/super-admin-registration-page" element={<SuperAdminRegistraion/>} />
    //         <Route path="/admin-registration-page" element={<AdminRegistration/>} />
    //         <Route path="/center-head-registration" element={<CenterHeadRegistration/>}/>
    //         <Route path="/center-head-registration-page2" element={<CenterHeadRegistration2/>}/>
    //         <Route path="/center-employee-registration" element={<CenterEmployeeRegistration1/>}/>
    //         <Route path="/center-employee-registration-page2" element={<CenterEmployeeRegistration2/>}/>
    //         <Route path="/job-fair-events" element={<JobFairRegistration/>}/>
    //         <Route path="/jobseekar-registration" element={<Jobseekarreg/>}/>
    //         <Route path="/jobprovider-registration" element={<Jobproviderreg/>}/>

    //       </Routes>
    //     </ThemeProvider>
    //   </CacheProvider>
    // ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {sessionStorage.getItem("admin_type") !== "JF-ADM" ? (
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Soft UI Dashboard PRO"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          ) : (
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Soft UI Dashboard PRO"
              routes={jfadminroute}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          {/* <Configurator /> */}
          {/* {configsButton} */}
          {/* {configsButton} */}
        </>
      )}

      {/* {layout === "vr" && <Configurator />} */}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/login" />} />
        {/* Login pages */}

        <Route
          path="/login"
          element={
            window.location.host.split(".")[0] !== "jobfairadmin" ? (
              <Login />
            ) : (
              <JobFairAdminLogin />
            )
          }
        />
        {/* <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/channelpartner/login" element={<CPLogin />} />
        <Route path="/centerhead/login" element={<CenterHeadLogin />} />
        <Route path="/centeremployee/login" element={<CenterEmployeeLogin />} />
        <Route path="/jobprovider/login" element={<JobProviderLogin />} /> */}
        <Route path="/skilling-shortlisted" element={<NewRecommendShortlist />} />

        {/* Login pages */}
        <Route path="/analysistable" element={<Roletable />} />
        <Route path="/forgotpassword1" element={<Forgotpassword1 />} />
        {/* <Route path="/authentication/sign-in/basic" element={<SignInBasic/>} /> */}
        {/* <Route path="/authentication/sign-in/cover" element={<SignInCover/>} /> */}
        <Route path="/signup" element={<SignUpBasic />} />
        {/* <Route path="/authentication/sign-up/cover" element={<SignUpCover/>} /> */}
        {/* routing for registration pages */}
        <Route exact element={<PrivateRoute />}>
          <Route exact path="/super-admin-registration-page" element={<SuperAdminRegistraion />} />
        </Route>
        {/* <Route exact element={<PrivateRoute />}> */}
        <Route path="/admin-registration-page" element={<AdminRegistration />} />
        {/* </Route> */}
        <Route exact element={<PrivateRoute />}>
          <Route path="/channel-partner-registration-page" element={<Channelprtnerregister />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/job-fair-registration-page" element={<JobFairRegistration />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/job-center-register" element={<Centerreg />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/center-head-registration" element={<CenterHeadRegistration />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/center-head-registration-page2" element={<CenterHeadRegistration2 />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/center-employee-registration" element={<CenterEmployeeRegistration1 />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route
            path="/center-employee-registration-page2"
            element={<CenterEmployeeRegistration2 />}
          />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/job-fair-events" element={<JobFairRegistration />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/jobseekar-registration" element={<Jobseekarreg />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/jobprovider-registration" element={<Jobproviderreg />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/counselor-registration-page" element={<CounsellourRegistration />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/admin-jobfairs" element={<JobFairs />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/admin-jobfairs/jobfairdetails" element={<JobFairDetails />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/admin-profile" element={<ProfilePage />} />
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route path="/admin-jobfair-dashboard" element={<Overview />} />
        </Route>
        {/* Admin Routes */}
        {/* <Route path="/admin" element={<AdminDashboard/>} >
          <Route path="" element={<AdminOverview/>} />
          <Route path="register" element={<AdminRegister/>} />
          <Route path="admin-register" element={<AdminReg/>} />
          <Route path="job-center-register" element={<AdmincenterReg/>} />
          <Route path="channel-partner-register" element={<AdminchannelPartnerRegis/>} />
          <Route path="chenter-employee-register" element={<AdmincenterEmpReg/>} />
          <Route path="chenter-head-register" element={<AdmincenterheadReg/>} />
          <Route path="chenter-head-register2" element={<Admincenterheadreg2/>} />
          <Route path="jobprovider-registration" element={<AdminjobproviderReg/>} />
          <Route path="jobfair-registration" element={<AdminjobfairReg/>} />
          <Route path="centers-list-page" element={<Admincenter/>} />
          <Route path="centers-head-page" element={<Admincenterhead/>} />
          <Route path="channel-partners-list-page" element={<Admincp/>} />
          <Route path="super-admin-centeremployee" element={<Admincenteremployee/>} />
          <Route path="jobprovider-list-page" element={<Adminjobprovider/>} />
          <Route path="jobseeker-list-page" element={<Adminjobseekars/>} />
          <Route path="job-fair-events-list-pages" element={<Adminjobfair/>} />
         
        </Route> */}
        {/* Admin Routes */}

        {/* centerhead route */}
        <Route path="/centerhead" element={<Centerheadpage />}>
          <Route path="center-head-register" element={<Centerheadregister />} />
          <Route path="" element={<Centerheaddashboard />} />
          <Route path="center-head-master" element={<Centerheadmaster />} />
          <Route path="center-employee-master" element={<Centeremployeemaster />} />
          <Route path="register" element={<Centerheadreg />} />
          <Route path="employeeregister" element={<Centeremployeereg />} />
          <Route path="centerjobprovider" element={<Centerjobprovider />} />
          <Route path="centerjobseekars" element={<Centerjobseeker />} />
          <Route path="center-jobseekars-master" element={<Centerjobseekers />} />
          <Route path="center-jobprovider-master" element={<Centerjobprovidermaster />} />
        </Route>

        {/* channelpartner route */}
        <Route path="/channelpartner" element={<Channelpartnerpage />}>
          <Route path="register" element={<Channelpartnerregisterlist />} />
          <Route path="" element={<Channlpartendashboard />} />
        </Route>
        {/* channelpartner route */}

        {/* Center Employee route */}
        <Route path="/centeremployee" element={<CenterEmployeePage />}>
          <Route path="register" element={<CenterEmployeeRegisterList />} />
          <Route path="" element={<CenterEmployeeDashboard />} />
        </Route>
        {/* Center Employee route */}

        {/* Job provider Routes */}
        <Route path="/jobprovider" element={<JobProviderpage />}>
          <Route path="" element={<JobProviderDashboard />} />
          {/* <Route path="register" element={<JobProviderRegisterList />} /> */}
          <Route path="search/newsearch" element={<NewSearch />} />
          <Route path="search/previouslysearch" element={<NewSearch />} />
          <Route path="search/savedsearches" element={<NewSearch />} />
          <Route path="searchbyposting/createposting" element={<CreatePosting />} />
          <Route path="searchbyposting/allposting" element={<JobPosting />} />
          <Route path="shortlisted" element={<RecommendShortlist />} />
        </Route>
        {/* Job provider Routes */}

        {/* Candidate search */}
        <Route path="/search/newsearch/recommend-search" element={<RecommendSearch />} />
        {/* <Route path="/search/newsearch/recommend-search/allshortlist" element={<RecommendShortlist/>} /> */}
        <Route
          path="/search/newsearch/recommend-search/allshortlist/viewCandidates"
          element={<ViewCandidates />}
        />

        <Route
          path="/searchbyposting/createposting/recommendedPostList"
          element={<Recommandsearchlist />}
        />
        {/* <Route path="/searchbyposting/allposting/editposting" element={<EditPosting />} /> */}
        {/* Candidate search */}

        {/* Counsellors Routes */}
        {/* <Route path="/counsellor" element={<CounselorPage />}></Route> */}
        {/* Counsellors Routes */}
      </Routes>
    </ThemeProvider>

    // );
  );
}
