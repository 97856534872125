import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Select from "react-select";
import PropTypes from "prop-types";
import "./SideDrawer.css";
import Backdrop from "./BackDrop";
import axios from "axios";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { statusList, WA_statusList, broadcast_statusList, responseList } from "./crmStatusConst";
import SoftInput from "components/SoftInput";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const SideDrawer = (props) => {
  const {
    broadCast,
    setBroadCast,
    waStatus,
    setWaStatus,
    stList,
    setStList,
    selectedDSF,
    setSelectedDSF,
    searchFunc,
    resetfunc,
    cosList1,
    setConsList1,
    counsellorName,
    setCounsellorName,
    shortlistDate,
    setShortlistDate,
    broadcastdate,
    setBroadcastdate,
    scoreft,
    setScoreft,
    step,
    setStep,
    candidateRt,
    setCandidateRt,
    setResponseStatus,
    responseStatus,
    stateFilter,
    setStateFilter,
    districtFilter,
    setDistrictFilter,
  } = props;
  const { setOpen } = props;
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }

  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [multiDS, setMultiDS] = useState([]);
  const [multiDistrict, setMultiDistrict] = useState([]);
  const [JSFilterdataSource, setJSFilterDataSource] = useState([]);
  const [JSFilterDisplay, setJSFilterDisplay] = useState([]);
  const statusScore = [
    { value: "Excellent", label: "Excellent" },
    { value: "Average", label: "Average" },
    { value: "Less", label: "Less" },
  ];

  const profillingScore = [
    { value: "Excellent", label: "Excellent" },
    { value: "Average", label: "Average" },
    { value: "Less", label: "Less" },
    { value: "Yet to be rated", label: "Yet to be rated" },
  ];

  function onCalendarChange1(dates) {
    setShortlistDate(
      dates?.map((v) => moment(new Date(v)).format("DD-MM-YYYY")),
      "onCalendarChange"
    );
  }

  function onCalendarChange2(dates) {
    setBroadcastdate(
      dates?.map((v) => moment(new Date(v)).format("DD-MM-YYYY")),
      "onCalendarChange"
    );
  }

  function onCalendarChange3(dates) {
    setShortlistDate(
      dates?.map((v) => moment(new Date(v)).format("DD-MM-YYYY")),
      "onCalendarChange"
    );
  }

  function onCalendarChange4(dates) {
    setBroadcastdate(
      dates?.map((v) => moment(new Date(v)).format("DD-MM-YYYY")),
      "onCalendarChange"
    );
  }

  function onCalendarChange10(dates) {
    setBroadcastdate(
      dates?.map((v) => moment(new Date(v)).format("DD-MM-YYYY")),
      "onCalendarChange"
    );
  }

  function onCalendarChange11(dates) {
    setBroadcastdate(
      dates?.map((v) => moment(new Date(v)).format("DD-MM-YYYY")),
      "onCalendarChange"
    );
  }

  useEffect(() => {
    if (selectedDSF === "") {
      setMultiDS([]);
    }
  }, [selectedDSF]);

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const fetchDataSource = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`)
      .then((res) => {
        setJSFilterDataSource(
          res.data["datasource_list"]
            .filter((item) => item?.toString() !== "")
            .filter((item) => item?.toString() !== "null")
            // .filter((item) => !item.toString().startsWith("SMR-CP"))
            .concat(["Channel Partner"])
            .map((ele, i) => ({ label: ele, value: ele }))
        );
        setJSFilterDisplay(
          res.data["datasource_list"]
            .filter((item) => item?.toString() !== "")
            .filter((item) => item?.toString() !== "null")
            .filter((item) => !item.toString().startsWith("SMR-CP"))
            .concat(["Channel Partner"])
            .map((ele, i) => ({ label: ele.label, value: ele.value }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        console.log("State", res.data);
        // var StateList = res.data[0].sort().map(function (item) {
        //   return { label: item, value: item };
        // });
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchDataSource();
    fetchStateList();
  }, []);

  useEffect(() => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);
  console.log(JSFilterDisplay , 'filter');
  // console.log(
  //   JSFilterdataSource.filter((ite) => ite.value.startsWith("SMR-CP-")).map((er) => er.value)
  // );

  return (
    <div className={drawerClasses}>
      <SoftBox
        sx={{
          height: "90%",
          backgroundColor: "#FFF",
          // borderBottom: "1px solid black",
          // borderRadius: "2px",
          cursor: "pointer",
          //   overFlowY: "scroll",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
        width="100%"
      >
        <SoftBox
          width="100%"
          sx={{
            height: "100%",
            backgroundColor: "#FFF",
            // borderBottom: "1px solid black",
            // borderRight: "1px solid black",
            // borderRadius: "2px",
            cursor: "pointer",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <SoftBox>Welcome filter</SoftBox> */}
          <SoftBox
            display="flex"
            justifyContent="space-between"
            // justifyConten="flex-end"
            alignItems="left"
            sx={{
              // height: "100%",
              backgroundColor: "#FFF",
              //   borderBottom: "1px solid black",
              // borderRight: "1px solid black",
              // borderRadius: "2px",
              padding: "0px 20px",
              cursor: "pointer",
            }}
          >
            <SoftTypography>Quick Filter</SoftTypography>
            <SoftBox onClick={() => props.setOpen(false)}>
              {" "}
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path
                  d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 
                  13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                ></path>
              </svg>{" "} */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style={{ height: "20px" }}
              >
                <path
                  d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 
                              13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                ></path>
              </svg>
            </SoftBox>
          </SoftBox>

          <SoftBox display="flex" flexDirection="column" sx={{ overflowY: "scroll" }}>
            <SoftBox display="flex" flexDirection="column" gap="20px">
              <SoftBox padding="8px">
                {step === "All" && (
                  <>
                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Approach Status
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select approach status"
                        options={broadcast_statusList}
                        //value={broadCast}
                        /*
                    value={
                      broadCast !== ""
                        ? { label: broadCast, value: broadCast }
                        : null
                    }
                    */
                        onChange={(e) => {
                          setBroadCast(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Interest Level
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select interest level"
                        options={WA_statusList}
                        //value={waStatus}
                        value={waStatus !== "" ? { label: waStatus, value: waStatus } : null}
                        onChange={(e) => {
                          setWaStatus(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Hiring Status
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select hiring status"
                        options={statusList}
                        value={stList !== "" ? { label: stList, value: stList } : null}
                        onChange={(e) => {
                          setStList(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Select Counsellor
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select counsellor name"
                        options={cosList1}
                        value={
                          counsellorName !== ""
                            ? { label: counsellorName, value: counsellorName }
                            : null
                        }
                        onChange={(e) => {
                          setCounsellorName(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Data Source
                      </SoftTypography>
                      <Select
                        isClearable
                        isMulti
                        // placeholder="Search...."
                        value={multiDS}
                        placeholder="Select data source"
                        options={JSFilterDisplay}
                        // onChange={(e) => setSelectedDSF(e.value)}
                        onChange={(e) => {
                          if (e) {
                            setMultiDS(e.map((item) => item));
                          } else {
                            setMultiDS([]);
                          }
                          setSelectedDSF(
                            e
                              .map((ele) => ele)
                              .map((itm) => itm.value)
                              .includes("Channel Partner")
                              ? JSFilterdataSource.filter((ite) => ite.value.startsWith("SMR-CP-"))
                                  .map((er) => er.value)
                                  .concat(e.map((el) => el).map((item) => item.value))
                                  .join()
                              : e
                                  .map((ell) => ell)
                                  .map((it) => it.value)
                                  .join()
                          );
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        State
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select state "
                        options={state}
                        //value={broadCast}
                        value={
                          stateFilter !== "" ? { label: stateFilter, value: stateFilter } : null
                        }
                        onChange={(e) => {
                          setStateFilter(e ? e.value : "");
                          // e ? setStateFilter(e.value) : setStateFilter("");
                          setSelectedState(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        District
                      </SoftTypography>
                      <Select
                        isClearable
                        isMulti
                        // placeholder="Search...."
                        value={multiDistrict}
                        placeholder="Select district "
                        options={district}
                        // onChange={(e) => setSelectedDSF(e.value)}
                        onChange={(e) => {
                          if (e) {
                            setMultiDistrict(e.map((item) => item));
                          } else {
                            setMultiDistrict([]);
                          }
                          setDistrictFilter(
                            e
                              .map((ele) => ele)
                              .map((item) => item.value)
                              .join()
                          );
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Shortlisted Date
                      </SoftTypography>

                      <RangePicker style={{ width: "100%" }} onCalendarChange={onCalendarChange3} />

                      {/*
                  <SoftInput
                    type="date"
                    value={shortlistDate}
                    onChange={(e) => setShortlistDate(e.target.value)}
                  />
                  */}
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Broadcast Date
                      </SoftTypography>

                      <RangePicker style={{ width: "100%" }} onCalendarChange={onCalendarChange4} />
                      {/*
                  <SoftInput
                    type="date"
                    value={broadcastdate}
                    onChange={(e) => setBroadcastdate(e.target.value)}
                  />
                  */}
                    </SoftBox>
                  </>
                )}

                {step === "List to AI Bot Engaged" && (
                  <>
                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Candidate Profiling Score
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select score"
                        options={profillingScore}
                        value={
                          candidateRt !== "" ? { label: candidateRt, value: candidateRt } : null
                        }
                        onChange={(e) => {
                          setCandidateRt(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Relevancy Score
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select score"
                        options={statusScore}
                        value={scoreft !== "" ? { label: scoreft, value: scoreft } : null}
                        onChange={(e) => {
                          setScoreft(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>
                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Response Status
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select response"
                        options={responseList}
                        value={
                          responseStatus !== ""
                            ? { label: responseStatus, value: responseStatus }
                            : null
                        }
                        onChange={(e) => setResponseStatus(() => (e ? e.value : ""))}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Data Source
                      </SoftTypography>
                      <Select
                        isClearable
                        isMulti
                        // placeholder="Search...."
                        value={multiDS}
                        placeholder="Select data source"
                        options={JSFilterDisplay}
                        // onChange={(e) => setSelectedDSF(e.value)}
                        onChange={(e) => {
                          if (e) {
                            setMultiDS(e.map((item) => item));
                          } else {
                            setMultiDS([]);
                          }
                          // setSelectedDSF(
                          //   e
                          //     .map((ele) => ele)
                          //     .map((item) => item.value)
                          //     .join()
                          // );
                          setSelectedDSF(
                            e
                              .map((ele) => ele)
                              .map((itm) => itm.value)
                              .includes("Channel Partner")
                              ? JSFilterdataSource.filter((ite) => ite.value.startsWith("SMR-CP-"))
                                  .map((er) => er.value)
                                  .concat(e.map((el) => el).map((item) => item.value))
                                  .join()
                              : e
                                  .map((ell) => ell)
                                  .map((it) => it.value)
                                  .join()
                          );
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        State
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select state "
                        options={state}
                        //value={broadCast}
                        value={
                          stateFilter !== "" ? { label: stateFilter, value: stateFilter } : null
                        }
                        onChange={(e) => {
                          setStateFilter(e ? e.value : "");
                          // e ? setStateFilter(e.value) : setStateFilter("");
                          setSelectedState(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        District
                      </SoftTypography>
                      <Select
                        isClearable
                        isMulti
                        // placeholder="Search...."
                        value={multiDistrict}
                        placeholder="Select district "
                        options={district}
                        // onChange={(e) => setSelectedDSF(e.value)}
                        onChange={(e) => {
                          if (e) {
                            setMultiDistrict(e.map((item) => item));
                          } else {
                            setMultiDistrict([]);
                          }
                          setDistrictFilter(
                            e
                              .map((ele) => ele)
                              .map((item) => item.value)
                              .join()
                          );
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Broadcast Date
                      </SoftTypography>
                      <RangePicker
                        style={{ width: "100%" }}
                        onCalendarChange={onCalendarChange10}
                      />
                    </SoftBox>
                  </>
                )}

                {step === "List to Counsellor Engaged" && (
                  <>
                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Candidate Profiling Score
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select score"
                        options={profillingScore}
                        value={
                          candidateRt !== "" ? { label: candidateRt, value: candidateRt } : null
                        }
                        onChange={(e) => {
                          setCandidateRt(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Hiring Status
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select hiring status"
                        options={statusList}
                        value={stList !== "" ? { label: stList, value: stList } : null}
                        onChange={(e) => {
                          setStList(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Select Counsellor
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select counsellor name"
                        options={cosList1}
                        value={
                          counsellorName !== ""
                            ? { label: counsellorName, value: counsellorName }
                            : null
                        }
                        onChange={(e) => {
                          setCounsellorName(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Data Source
                      </SoftTypography>
                      <Select
                        isClearable
                        isMulti
                        // placeholder="Search...."
                        value={multiDS}
                        placeholder="Select data source"
                        options={JSFilterDisplay}
                        // onChange={(e) => setSelectedDSF(e.value)}
                        onChange={(e) => {
                          if (e) {
                            setMultiDS(e.map((item) => item));
                          } else {
                            setMultiDS([]);
                          }
                          // setSelectedDSF(
                          //   e
                          //     .map((ele) => ele)
                          //     .map((item) => item.value)
                          //     .join()
                          // );
                          setSelectedDSF(
                            e
                              .map((ele) => ele)
                              .map((itm) => itm.value)
                              .includes("Channel Partner")
                              ? JSFilterdataSource.filter((ite) => ite.value.startsWith("SMR-CP-"))
                                  .map((er) => er.value)
                                  .concat(e.map((el) => el).map((item) => item.value))
                                  .join()
                              : e
                                  .map((ell) => ell)
                                  .map((it) => it.value)
                                  .join()
                          );
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        State
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select state "
                        options={state}
                        //value={broadCast}
                        value={
                          stateFilter !== "" ? { label: stateFilter, value: stateFilter } : null
                        }
                        onChange={(e) => {
                          setStateFilter(e ? e.value : "");
                          // e ? setStateFilter(e.value) : setStateFilter("");
                          setSelectedState(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        District
                      </SoftTypography>
                      <Select
                        isClearable
                        isMulti
                        // placeholder="Search...."
                        value={multiDistrict}
                        placeholder="Select district "
                        options={district}
                        // onChange={(e) => setSelectedDSF(e.value)}
                        onChange={(e) => {
                          if (e) {
                            setMultiDistrict(e.map((item) => item));
                          } else {
                            setMultiDistrict([]);
                          }
                          setDistrictFilter(
                            e
                              .map((ele) => ele)
                              .map((item) => item.value)
                              .join()
                          );
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Broadcast Date
                      </SoftTypography>
                      <RangePicker
                        style={{ width: "100%" }}
                        onCalendarChange={onCalendarChange11}
                      />
                    </SoftBox>
                  </>
                )}

                {step === "Unengaged" && (
                  <>
                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Approach Status
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select approach status"
                        options={broadcast_statusList}
                        //value={broadCast}
                        /*
                    value={
                      broadCast !== ""
                        ? { label: broadCast, value: broadCast }
                        : null
                    }
                    */
                        onChange={(e) => {
                          setBroadCast(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Interest Level
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select interest level"
                        options={WA_statusList}
                        //value={waStatus}
                        value={waStatus !== "" ? { label: waStatus, value: waStatus } : null}
                        onChange={(e) => {
                          setWaStatus(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>
                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Relevancy Score
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select score"
                        options={statusScore}
                        value={scoreft !== "" ? { label: scoreft, value: scoreft } : null}
                        onChange={(e) => {
                          setScoreft(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>
                    {/*
                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Hiring Status
                  </SoftTypography>
                  <Select
                    isClearable
                    placeholder="Select hiring status"
                    options={statusList}
                    value={stList !== "" ? { label: stList, value: stList } : null}
                    onChange={(e) => {
                      setStList(e ? e.value : "");
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>
                */}

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Data Source
                      </SoftTypography>
                      <Select
                        isClearable
                        isMulti
                        // placeholder="Search...."
                        value={multiDS}
                        placeholder="Select data source"
                        options={JSFilterDisplay}
                        // onChange={(e) => setSelectedDSF(e.value)}
                        onChange={(e) => {
                          if (e) {
                            setMultiDS(e.map((item) => item));
                          } else {
                            setMultiDS([]);
                          }
                          // setSelectedDSF(
                          //   e
                          //     .map((ele) => ele)
                          //     .map((item) => item.value)
                          //     .join()
                          // );
                          setSelectedDSF(
                            e
                              .map((ele) => ele)
                              .map((itm) => itm.value)
                              .includes("Channel Partner")
                              ? JSFilterdataSource.filter((ite) => ite.value.startsWith("SMR-CP-"))
                                  .map((er) => er.value)
                                  .concat(e.map((el) => el).map((item) => item.value))
                                  .join()
                              : e
                                  .map((ell) => ell)
                                  .map((it) => it.value)
                                  .join()
                          );
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        State
                      </SoftTypography>
                      <Select
                        isClearable
                        placeholder="Select state "
                        options={state}
                        //value={broadCast}
                        value={
                          stateFilter !== "" ? { label: stateFilter, value: stateFilter } : null
                        }
                        onChange={(e) => {
                          setStateFilter(e ? e.value : "");
                          // e ? setStateFilter(e.value) : setStateFilter("");
                          setSelectedState(e ? e.value : "");
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        District
                      </SoftTypography>
                      <Select
                        isClearable
                        isMulti
                        // placeholder="Search...."
                        value={multiDistrict}
                        placeholder="Select district "
                        options={district}
                        // onChange={(e) => setSelectedDSF(e.value)}
                        onChange={(e) => {
                          if (e) {
                            setMultiDistrict(e.map((item) => item));
                          } else {
                            setMultiDistrict([]);
                          }
                          setDistrictFilter(
                            e
                              .map((ele) => ele)
                              .map((item) => item.value)
                              .join()
                          );
                        }}
                        styles={stylesDD}
                      />
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Shortlisted Dates
                      </SoftTypography>

                      <RangePicker style={{ width: "100%" }} onCalendarChange={onCalendarChange1} />
                      {/*
                  <SoftInput
                    type="date"
                    value={shortlistDate}
                    onChange={(e) => setShortlistDate(e.target.value)}
                  />
                  */}
                    </SoftBox>

                    <SoftBox margin="8px">
                      <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                        Broadcast Date
                      </SoftTypography>
                      <RangePicker style={{ width: "100%" }} onCalendarChange={onCalendarChange2} />
                      {/*
                  <SoftInput
                    type="date"
                    value={broadcastdate}
                    onChange={(e) => setBroadcastdate(e.target.value)}
                  />
                */}
                    </SoftBox>
                  </>
                )}
              </SoftBox>

              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="center"
                gap="35px"
                px={3}
                py="24px 0px"
              >
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "100px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  // p="10px 25px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={{ lg: "150px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    setOpen(false);
                    resetfunc();
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "small",
                      lineHeight: "20px",
                    }}
                  >
                    Reset
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "100px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  // p="10px 25px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={{ lg: "150px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    setOpen(false);
                    searchFunc();
                    console.log(broadCast, searchFunc, "bbb");
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "small",
                      lineHeight: "20px",
                    }}
                  >
                    Apply Filter
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </div>
  );
};

SideDrawer.propTypes = {
  show: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  broadCast: PropTypes.string,
  setBroadCast: PropTypes.string,
  waStatus: PropTypes.string,
  setWaStatus: PropTypes.string,
  stList: PropTypes.string,
  setStList: PropTypes.string,
  selectedDSF: PropTypes.string,
  setSelectedDSF: PropTypes.string,
  searchFunc: PropTypes.func,
  resetfunc: PropTypes.func,
  cosList1: PropTypes.string,
  setConsList1: PropTypes.string,
  counsellorName: PropTypes.string,
  setCounsellorName: PropTypes.string,
  shortlistDate: PropTypes.string,
  setShortlistDate: PropTypes.string,
  broadcastdate: PropTypes.string,
  setBroadcastdate: PropTypes.string,
  scoreft: PropTypes.string,
  setScoreft: PropTypes.string,
  step: PropTypes.string,
  setStep: PropTypes.string,
  candidateRt: PropTypes.string,
  setCandidateRt: PropTypes.string,
  responseStatus: PropTypes.string,
  setResponseStatus: PropTypes.string,
  stateFilter: PropTypes.string,
  setStateFilter: PropTypes.string,
  districtFilter: PropTypes.string,
  setDistrictFilter: PropTypes.string,
};

export default SideDrawer;
