// import { Card, Stack } from "@mui/material";
import "../style.css";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataTable from "examples/Tables/DataTable";
// import adminTableData from "./admintable";
import SoftSelect from "../../components/SoftSelect";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import React from "react";
import { Icon } from "@iconify/react";
import Select from "react-select";
import download from "assets/icons/download_icon.png";
import downArrow from "assets/icons/down_arrow.png";
import Mapdata from "pages/Mapdata/Mapdata";
import { Table } from "ant-table-extensions";
// importing data for table temporirly from superadmin table.js
import { data } from "../Suparadmin/suparadmintable";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import { MdMoreVert } from "react-icons/md";
import AdminEdit from "pages/EditModals/Admin/adminEdit";
import PasswordResetAdmin from "pages/EditModals/Admin/AdminPasswordReset";
import Button from "@mui/material/Button";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal, ModalBody } from "react-bootstrap";
// import { updatePercentiles } from "../../utils/mapGeoCode";
// import {
//   setNavbarCity,
//   setNavbarState
// } from "../../redux/Navbar-Selection/navbarSelection.actions"

// import {Map} from "layouts/pages/map/Map";
// import {
//   dataLayer2,
//   dataLayer3,
//   // skillMatchLayer,
// } from "../../utils/mapGeoCode";
// import { useSelector } from "react-redux";
// import {checkGeoJson} from "../../utils/Common"

const Admin = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [adminNo, setAdminNo] = useState("");
  const [selectAdmin, setSelectAdmin] = useState(false);
  const handleSelectAdmin = () => {
    setSelectAdmin(!selectAdmin);
  };
  const ListOfAdmins = [
    { label: " all Admin", value: "all" },
    { label: "Admin 1", value: "1" },
    { label: "Admin 2", value: "2" },
    { label: "Admin 3", value: "3" },
    { label: "Admin 4", value: "4" },
    { label: "Admin 5", value: "5" },
  ];
  const [admindata, Setadmindata] = useState([]);
  const [resetPassword, setResetPassword] = useState(false);
  const [editadmin, Seteditadmin] = useState(false);
  const [editdata, Seteditdata] = useState({});

  //   setColHeaders(colName.map((ele,i)=>(
  //     [...colHeaders,{label:ele.title,key:ele.dataIndex, isExportable:true}]
  //   )))
  // }
  // columnData();

  // setColHeaders(columnData);
  // const data =admindata?.map((item ,id)=>{return {
  //   key: item.registration_id,
  //   // organization: item.org_name ,
  //   // location: item.org_address,
  //   name: `${item.firstnm+" "+item.lastnm}`,
  //   firstnm: item.firstnm,
  //   lastnm: item.lastnm,
  //   sequrity_ques: item.sequrity_ques,
  //   sequrity_ans: item.sequrity_ans,
  //   email: item.email,
  //   mobnum: item.mobnum,
  //   date_of_creation: moment(item.createddt).utc().format('DD-MM-YYYY') ,
  // }});

  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectCol, setSelectCol] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [val, setVal] = useState("");
  const [searchData, setSearchData] = useState(data);
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [colHeadersFilterd, setColHeadersFilterd] = useState([]);
  const [colHeaders, setColHeaders] = useState([
    {
      label: "Organization",
      key: "organization",
      isExportable: true,
    },
    {
      label: "Location",
      key: "location",
      isExportable: true,
    },
    {
      label: "Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "phone_number",
      isExportable: true,
    },
    {
      label: "Date of Creation",
      key: "date_of_creation",
      isExportable: true,
    },
  ]);
  const colName = [
    {
      title: "Organization",
      dataIndex: "organization",
      sorter: {
        compare: (a, b) => a.organization - b.organization,
        multiple: 1,
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: {
        compare: (a, b) => a.location - b.location,
        multiple: 2,
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 4,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      sorter: {
        compare: (a, b) => a.phone_number - b.phone_number,
        multiple: 5,
      },
    },
    {
      title: "Date Of Creation",
      dataIndex: "date_of_creation",
      sorter: {
        compare: (a, b) => a.date_of_creation - b.date_of_creation,
        multiple: 6,
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      width: "5%",
      render: (admindata) => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </Button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="mdi:lead-pencil" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#EditUser"
                    onClick={() => {
                      Seteditadmin(true);
                      Seteditdata(admindata);
                    }}
                  >
                    Edit Profile
                  </a>
                </div>
              </li>
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="mdi:password-reset" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#EditUser"
                    onClick={() => {
                      setResetPassword(true);
                      Seteditdata(admindata);
                    }}
                  >
                    Reset Password
                  </a>
                </div>
              </li>
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <Icon icon="fluent-mdl2:deactivate-orders" />
                  </div>
                  <a
                    className="dropdown-item"
                    href="#Deleteuser"
                    // onClick={() => {
                    //   if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
                    //     DeleteClientUser(e.id)
                    //   } else {
                    //     toast.error("Admin Cannot Delete")
                    //   }

                    // }}
                    onClick={() => {
                      deActiveFun(admindata);
                    }}
                  >
                    Deactivate
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];
  // const colHeaders2 = [
  //   {
  //     label : "Organization",
  //     key : "organization",
  //     isExportable: true,
  //   },
  //   {
  //     label : "Location",
  //     key : "location",
  //     isExportable: true,
  //   },
  //   {
  //     label : "Name",
  //     key : "name",
  //     isExportable: true,
  //   },
  //   {
  //     label : "Email",
  //     key : "email",
  //     isExportable: true,
  //   },
  //   {
  //     label : "Phone Number",
  //     key : "phone_number",
  //     isExportable: true,
  //   },
  //   {
  //     label : "Date of Creation",
  //     key : "date_of_crea,tion",
  //     isExportable: true,
  //   },

  // ]
  // const handlleCol=()=>{

  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(data, newSelectedRowKeys);
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", selectedCSVData);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };

  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        deactivate_role: roleId,
        "partner-association": "samarth",
        deactivatedby: registerID,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "",
          text: res.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "",
          text: e.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);

  const fetchadminList = () => {
    axios
      .get(`${process.env.REACT_APP_DASHBOARD_URL}/superadmin/adminlist`)
      .then((res) => {
        console.log(res.data["SuperAdmin_List"]);
        Setadmindata(res.data["SuperAdmin_List"]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchadminList();
    fetchStateList();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  useEffect(() => {
    // handlleCol();
    console.log("colheades", colHeaders);
  }, []);

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          {/* <Card> */}
          {/* <Map
            data={mapData}
            location={selectedState}
            type={selectedCity && !jsonError ? "city" : "state"}
            dataLayer={dataLayer2}
            dataLayer2={dataLayer3}
            jsonError={jsonError}
            toolTip={ToolTipRender}
            onClick={onClick}
            mapError={mapError}
          /> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox
              lineHeight={1}
              mb={3}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="10px"
            >
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
                Admin {adminNo}
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSelectAdmin}
              >
                <SoftBox component="img" src={downArrow} width="10px" height="10px" />
              </SoftTypography>
              {selectAdmin && (
                // <SoftSelect placeholder="Select Admin" />
                // <select className="form-select form-select-lg mb-3" placeholder="Select Admin">
                //   {ListOfAdmins.map((item,id)=>{
                //     return <option value={item.value} key={id}>{item.label}</option>
                //   })}
                // </select>
                <Select
                  placeholder="Select Admin"
                  options={ListOfAdmins}
                  value={adminNo !== "" ? { label: adminNo, value: adminNo } : null}
                  onChange={(e) => {
                    setAdminNo(e.value);
                    setSelectAdmin(false);
                  }}
                />
              )}
            </SoftBox>
          </SoftBox>
          <SoftBox>
            <Mapdata
              h1="Total Admin registered"
              h2="Total Job Center Created"
              h3="Total Channel Partner Add"
              h4="Total Center Created"
              v1="50"
              v2="20"
              v3="4"
              v4="20"
            />

            <SoftBox display="flex" alignItems="flex-start" mb="29px" width="100%" flexWrap="wrap">
              <Grid container spacing={4}>
                <Grid item lg={4} md={4} xs={12}>
                  <SoftBox
                    width="350px"
                    // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                    mb={1}
                    sx={{
                      border: "0px",
                      backgroundColor: "#fff",
                      marginRight: "13px",
                      borderRadius: "5px",
                    }}
                  >
                    <SoftSelect
                      placeholder="State"
                      options={state}
                      // value={search}
                      onChange={(currentTarget) => {
                        setSelectedState(currentTarget.value);
                      }}
                    />
                    {/* <Select  placeholder="State" /> */}
                  </SoftBox>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <SoftBox
                    width="350px"
                    // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                    mb={1}
                    sx={{
                      border: "0px",
                      backgroundColor: "#fff",
                      marginRight: "13px",
                      borderRadius: "5px",
                    }}
                  >
                    <SoftSelect
                      placeholder="District"
                      options={district}
                      onChange={(currentTarget) => {
                        setSelectedDistrict(currentTarget.value);
                      }}
                    />
                  </SoftBox>
                </Grid>
                {/* <SoftBox width={{ lg: "24%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{
                border: "0px",
                backgroundColor: "#fff",
                marginRight: "13px",
                borderRadius: "5px",
              }}>
              <SoftSelect
                placeholder="Taluka"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              />
            </SoftBox> */}
                <Grid item lg={4} md={4} xs={12}>
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-center"
                    justifyContent="center"
                    gap="24px"
                    // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                    width="350px"
                    mb={1}
                    sx={{
                      height: "40px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    padding={{ lg: "10px 5%", sm: "12px 80px", xs: "10px 70px" }}
                    onClick={() => navigate("/admin-registration-page")}
                  >
                    <SoftTypography
                      flex="none"
                      flexGrow="0"
                      order="0"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      +
                    </SoftTypography>
                    <SoftTypography
                      flex="none"
                      flexGrow="0"
                      order="1"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Admin
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>

            <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="100%">
              {/* <DataTable
                table={adminTableData}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
              /> */}
              {selectedRowKeys.length > 1 && (
                // <Button variant="contained" color="success" >
                //   Deactivate
                // </Button>
                <SoftBox
                  width="120px"
                  sx={{
                    position: "absolute",
                    // marginLeft:"850px",
                    marginLeft: "850px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    // backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    border: "1px solid red",
                    cursor: "pointer",
                  }}
                  // onclick to deactivate all
                >
                  <SoftTypography
                    sx={{ color: "red", fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                  >
                    Deactivate All
                  </SoftTypography>
                </SoftBox>
              )}

              <SoftBox
                width="120px"
                sx={{
                  position: "absolute",
                  // marginLeft:"1000px",
                  marginLeft: "1000px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "32px",
                  // backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  border: "1px solid ",
                  cursor: "pointer",
                }}
                // onclick to deactivate all
              >
                <SoftTypography
                  sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                  onClick={() => (
                    setShow(!show),
                    // setFileName(() => {
                    //   getFileName();
                    // })
                    getFileName()
                  )}
                >
                  Export CSV
                </SoftTypography>
                {/* <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink> */}
              </SoftBox>

              <Table
                {...tableProps}
                //  pagination={{position:["bottomCenter"]}}
                rowSelection={rowSelection}
                columns={colName}
                dataSource={data}
                // exportable
                // exportableProps={{ showColumnPicker: true }}
                // searchableProps={{width:"500px"}}
                searchable
              />
              <AdminEdit editadmin={editadmin} Seteditadmin={Seteditadmin} editdata={editdata} />

              {/* reset password  */}
              <PasswordResetAdmin
                editadmin={resetPassword}
                Seteditadmin={setResetPassword}
                editdata={editdata}
              />
            </SoftBox>
          </SoftBox>
          {/* </Card> */}
        </SoftBox>

        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <SoftBox>
              {colHeaders.map((ele, index) => (
                <>
                  <ul key={index}>
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <label> {ele.label}</label>
                  </ul>
                </>
              ))}
            </SoftBox>
          </Modal.Body>
          <Modal.Footer>
            <SoftBox
              pl={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "45px",
                backgroundColor: "#fff",
                border: "1px solid",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
            >
              {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
              <CSVLink
                data={selectedCSVData}
                filename={fileName}
                headers={colHeaders.filter((ele) => ele.isExportable === true)}
              >
                export
              </CSVLink>
            </SoftBox>
          </Modal.Footer>
        </Modal>
        {/* modal for export */}
      </DashboardLayout>
    </>
  );
};

export default Admin;
