import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import BootstrapTable from "react-bootstrap-table-next";
import Table from 'react-bootstrap/Table'


import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";


import { ADMIN } from "utils/constant";
import { spacing } from '@mui/system';

import pageRoutes from "page.routes";
import Card from "@mui/material/Card";
import axios from "axios";


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: "white",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  fontSize: "12px",
}));

const Roletable = () => {
    const transparent = false;
    const light = false;
    // const [userRole, setUserRole] = useState(AGENT);
    const [city ,SetCity] =useState([]);
    const [role ,Setrole] =useState([]);
  
    const changeUser = (e, type) => {
      // double clicking on same option deselects and sets type as null
      if (type !== null) {
        setUserRole(type);
      }
    };

    useEffect(()=>{
      axios.get(`https://7e55uus5l5.execute-api.ap-south-1.amazonaws.com/dev/rolecount`).then((res) => { 
            
        SetCity(Object.keys(res.data))  
        Setrole(Object.values(res.data))            
      
      })
        .catch((err) => {
          console.log("Login failed");
          alert("Error message", err);
        })
    },[])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

 
    const products = [
      { id: "Account", name: " mumbai",  },
      { id: "Helper", name: "aNDHERI ",  },
      { id: "Anaylst", name: "Vasai ", },
      { id: "Soft Developer", name: "Mumbai ",  },
      { id: "Marketing", name: " Mambai", }
    
    ];

    
    const columns = [
      {
        dataField: "id",
        text: "Job Family Distribution /District",
        sort: true
      },
      
      {
        dataField: "name",
        text: "Mumbai",
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {setShow(true)  },
        }
      },
      {
        dataField: "name",
        text: "Central Mumbai",
        sort: true
      },
      {
        dataField: "name",
        text: "Andheri",
        sort: true
      },
    ];

  return (
    <>
<PageLayout>
        <DefaultNavbar
          routes={pageRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
          light
        />        
     
      </PageLayout>
      <h1> Table</h1>
      <div className="container">
        <Button className="pb-2">Refresh</Button>
      {/* <BootstrapTable
        bootstrap4
        keyField="id"
        data={products}
        columns={columns}
      /> */}
</div>

<Table stripped bordered hover size="sm">
  <thead>
    <tr>
      <th width="170">Job Family Distribution /District</th>
      {city?.map((item ,id) => <th width="170" key={id}>{item}</th>)}   
 
    </tr>
  </thead>
  <tbody>
  {/* {Object.keys(role[0])?.map((item ,id)=>    <tr key={id}>
      <td >{item}</td>
      <td></td>
   
 
    </tr> )}   */}
 
  </tbody>
</Table>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>test</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Roletable