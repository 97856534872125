import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  Grid,
  Card,
  Typography,
  Box,
  Switch,
  Skeleton,
  Tooltip as TooltipMui,
  Menu,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import moment from "moment";
import nodata from "../../assets/images/browser.png";

// import Mapchart from "./MapChart1";
import axios from "axios";
import { useState } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { SelectChangeEvent } from "@mui/material/Select";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
// import A1 from "../../assets/images/Group 1000003388.png";
// import A2 from "../../assets/images/kpi2.png";
// import A3 from "../../assets/images/kpi3.png";
// import A4 from "../../assets/images/kpi4.png";
// import A5 from "../../assets/images/kpi5.png";
// import A6 from "../../assets/images/kpi6.png";
import A1 from "../../assets/new_jf/conducted.png";
import A2 from "../../assets/new_jf/ongoing.png";
import A3 from "../../assets/new_jf/completed.png";
import A4 from "../../assets/new_jf/upcoming.png";
import A5 from "../../assets/new_jf/companies.png";
import A6 from "../../assets/new_jf/cand_registered.png";
import cand_sum from "../../assets/new_jf/cand_sum.png";
import place_sum from "../../assets/new_jf/place_sum.png";
import req_stat from "../../assets/new_jf/req_stat.png";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  ComposedChart,
} from "recharts";
import { ResponsivePie } from "@nivo/pie";
import SquareIcon from "@mui/icons-material/Square";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import {
  SBox1,
  SBox2,
  SBox3,
  SBox4,
  SBox5,
  SGHeading,
  SGSubHeading,
  SGTitles,
  SGToggles,
  SGraphSubHeading,
  SGridBox,
  SHeading,
  SKPICount,
  SKPIHeading,
  SKPISubHeading1,
  SKPISubHeading2,
  SUnderlineBox,
  SVerticalBox,
} from "./Style";
import DatePicker from "rsuite/DatePicker";
import isAfter from "date-fns/isAfter";
// import "./style.css";
import "rsuite/dist/rsuite.min.css";
import "../../components/SoftWeekPicker/index.css";
const Overview = () => {
  const componentRef = useRef();
  const [toggle1, setToggle1] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orgList, setOrgList] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [orgNameID, setOrgNameID] = useState("");
  const [jobLabel1, setJobLabel1] = useState("");
  const [orgLabel2, setOrgLabel2] = useState("");
  const [orgLabel1, setOrgLabel1] = useState("");
  const [jobList, setJobList] = useState([]);
  const [jobName, setJobName] = useState("");
  const [value, setValue] = useState(0);
  const [check, setCheck] = useState(false);
  const [clear, setClear] = useState(false);
  const [roleType, setRoleType] = useState("qualification");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [data, setData] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [selectYear, setSelectYear] = useState("2023");
  const [stateSelected, setStateSelected] = useState([]);
  const [statesSelected, setStatesSelected] = useState([]);
  const [show, setShow] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [datesArr, setDatesArr] = useState([]);
  const current = new Date();
  // let currentStart = moment(current).subtract(1, "weeks").startOf("isoWeek").toDate(); // prev week start date
  let currentStart = moment(current).startOf("isoWeek").toDate(); // curr week start date
  currentStart = currentStart.toLocaleDateString("en-CA");
  // currentStart="2023-10-02"
  // let currentEnd = moment(current).subtract(1, "weeks").endOf("isoWeek").toDate(); // prev week end date
  let currentEnd = moment(current).endOf("isoWeek").toDate(); // curr week end date
  currentEnd = currentEnd.toLocaleDateString("en-CA");
  //  currentEnd="2023-10-08"

  const [week, setWeek] = useState({
    date: moment(new Date()).startOf("isoWeek").toDate(), // prev week monday
    currentStart: currentStart,
    currentEnd: currentEnd,
    dateFrom: moment(new Date()).startOf("isoWeek").toDate(),
    dateTo: moment(new Date()).endOf("isoWeek").toDate(),
    WeekNumber: "",
  });

  console.log(stateSelected?.length, statesSelected?.length, check, "kite");

  const [attWeek, setAttWeek] = useState({
    target_start_date: "2023-08-01",
    target_end_date: "2023-08-31",
    current_start_date: currentStart,
    current_end_date: currentEnd,
  });

  const getDates = (start, end) => {
    let dates = [];
    let current = new Date(start);
    while (current <= end) {
      dates.push(moment(current).format("YYYY-MM-DD"));
      current.setDate(current.getDate() + 1);
    }
    setDatesArr(dates);
  };

  const onChange = (date) => {
    //console.log(date)
    const WeekNumber = moment(date).isoWeek();
    let dateFrom = moment(date).startOf("isoWeek").toDate();
    dateFrom = dateFrom.toLocaleDateString("en-CA");
    let dateTo = moment(date).endOf("isoWeek").toDate();
    console.log("dateTo", dateTo, currentStart);
    dateTo = dateTo.toLocaleDateString("en-CA");
    console.log("dateTo", dateTo);
    setWeek({
      date: date,
      currentStart: currentStart,
      currentEnd: currentEnd,
      dateFrom:
        dateFrom !== "Invalid Date" ? dateFrom : moment(new Date()).startOf("isoWeek").toDate(),
      dateTo: dateTo !== "Invalid Date" ? dateTo : moment(new Date()).endOf("isoWeek").toDate(),
      WeekNumber: WeekNumber,
    });
    getDates(new Date(dateFrom), new Date(dateTo));
  };
  const handleDownloadImage = async () => {
    const element = componentRef.current;
    const padding = 40;
    // Capture the entire element, not just the visible part
    const canvas = await html2canvas(element, {
      scrollX: 0,
      scrollY: -window.scrollY,
      useCORS: true, // Use this if you have images and want to avoid cross-origin issues
    });

    const imgData = canvas.toDataURL("image/jpeg");

    // Get the actual dimensions of the element
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    // Create a new PDF document with the dimensions of the element
    const pdf = new jsPDF({
      orientation: imgWidth > imgHeight ? "landscape" : "portrait",
      unit: "pt",
      format: [imgWidth + padding * 2, imgHeight + padding * 2],
    });

    // Add the image to the PDF
    pdf.addImage(imgData, "JPEG", padding, padding, imgWidth, imgHeight);

    // Save the PDF with a timestamped filename
    pdf.save(`Report_Dashboard_${moment(new Date()).format("DD-MM-YYYY")}.pdf`);
  };
  const YearFunc = () => {
    let arr = [];
    for (let startOf = 2024; startOf >= 2000; startOf--) {
      arr.unshift({ label: startOf.toString(), value: startOf.toString() });
    }
    setYearList(arr);
  };

  useEffect(() => {
    getAttritionDates(week);
  }, [week]);

  useEffect(() => {
    getDates(
      week?.dateFrom === ""
        ? new Date(moment(new Date()).startOf("isoWeek").toDate())
        : new Date(week?.dateFrom),
      week?.dateTo === ""
        ? new Date(moment(new Date()).endOf("isoWeek").toDate())
        : new Date(week?.dateTo)
    );
  }, [week]);

  console.log("week obj", week);

  const handleFormChange12 = (event, index, state, setState) => {
    setCheck(false);
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    setState(data);
    console.log("handleform", data);
    setStatesSelected(stateSelected.filter((ele) => ele.isExportable === true));
  };

  const getAttritionDates = (week) => {
    setAttWeek({
      target_start_date: week.dateFrom,
      target_end_date: week.dateTo,
      current_start_date: week.currentStart,
      current_end_date: week.currentEnd,
    });
  };

  const distributionDD = [
    { label: "Age", value: "age" },
    { label: "Gender", value: "gender" },
    { label: "Highest Qualification", value: "qualification" },
    { label: "Job Role", value: "role" },
  ];

  const [data6, setData6] = useState([]);

  const [data4, setData4] = useState([]);

  const [data3, setData3] = useState([]);

  const [data1, setData1] = useState([]);
  const [placed, setPlaced] = useState([]);
  const [data1A, setData1A] = useState([]);
  const [data10, setData10] = useState([]);

  const [KPIObj, setKPIObj] = useState({
    super_admin_count: "",
    admin_count: "",
    channel_partner_count: "",
    // job_center_count: "",
    // center_head_count: "",
    // center_employee_count: "",
    job_provider_count: "",
    job_seeker_count: "",
    job_fair_count: "",
    counsellor_count: "",
  });
  const [kpiObj2, setKpiObj2] = useState({
    total_placed: "",
    total_posts: "",
    total_assigned_cps: "",
    recent_admins: "",
    recent_counsellor: "",
    upcoming_jf: "",
  });

  const [kpiArr, setKpiArr] = useState([]);

  const handleChange = (event, newValue) => {
    console.log("changes:", event);
    console.log("changes2:", newValue);
    setValue(newValue);
    setRoleType(
      newValue === 0
        ? "qualification"
        : newValue === 1
        ? "gender"
        : newValue === 2
        ? "age"
        : "qualification"
    );
  };

  const fetchOrgList = () => {
    const params = {
      partner: "samarth",
      type: "dropdown",
      created_by: sessionStorage.getItem("register_id")
        ? sessionStorage.getItem("register_id")
        : "",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/company-list-dd`, {
        params: {
          // jf_id: "SMR_JF_Ma_Pu_Sh_0862",
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        // console.log("org list", res.data["Organisation_List"]);
        setOrgList([{ label: "All", value: "" }].concat(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchOrgList();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`, {
        params: {
          orgid: orgName,
          type: "dropdown",
          admin_id: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log(res.data.org_title);
        setJobList([{ label: "All", value: "" }].concat(res.data["JobPost_List"]));
      });
  }, [orgName]);

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: "12.5px",
      width: "166px",
    }),
    option: (base) => ({
      ...base,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const option1 = [{ label: "All", value: "All" }];

  const option2 = [{ label: "Mar-Apr", value: "Mar-Apr" }];

  //Pyramid
  const Series = {
    series: [
      {
        // name: data6.map((v, i) => v.msg),
        // name: "Steps",
        data: data6.map((v) => v.Candidates),
      },
    ],
  };

  //Pyramid
  const option3 = {
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        fontFamily: "Inter",
        fontSize: "10px",
      },
      tooltip: {
        enabled: true,

        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          {
            console.log("hover:", dataPointIndex);
          }
          return (
            "<div style='margin:4px'>" +
            "<ul>" +
            "<li>" +
            "<div >" +
            `Count : ${w.globals.series[0][dataPointIndex]}` +
            "</div>" +
            "</li>" +
            // `${
            //   // w.globals.seriesNames[0][dataPointIndex] !== "" &&
            //   "<li>" +
            //   "<div>" +
            //   // series[seriesIndex][dataPointIndex] +

            //   // dataPointIndex !== 0 &&
            //   `${w.globals.seriesNames[0][dataPointIndex]}` +
            //   "</div>" +
            //   "</li>"
            // }` +
            "</ul>" +
            "</div>"
          );
          // :
          // "<div style={{margin:'8px',display:'flex',alignItems:'center'}}>" +
          //     "<ul>" +
          //     `${
          //       "<li>" +
          //       "<div>" +
          //       `${w.globals.seriesNames[0][dataPointIndex]}` +
          //       "</div>" +
          //       "</li>"
          //     }` +
          //     "</ul>" +
          //     "</div>";
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          barHeight: "80%",
          isFunnel: true,
        },
      },
      colors: ["#88ABAE", "#F94144", "#2D9CDB", "#F8961E", "#C69774", "#90BE6D", "#F3722C"],
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return val;
        },
        dropShadow: {
          enabled: false,
        },
      },
      xaxis: {
        categories: data6.map((v) => v.name),
        percentage: data6.map((v) => v.percent),
      },
      legend: {
        show: false,
      },
    },
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    console.log("labels x:", x);
    console.log("labels cx:", cx);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontFamily: "Inter", fontSize: "12px" }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const FetchGraph1 = async () => {
    setLoading4(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/state/users
        `,
        {
          state: sessionStorage.getItem("state") ? sessionStorage.getItem("state") : "",
          admin_id: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        }
      );
      setLoading4(false);
      const data = await res.data;
      setStateSelected(
        data?.data?.map((v) => {
          return {
            label: v.district,
            isExportable: true,
            name: v.district,
            count: v.count,
          };
        })
      );
      setStatesSelected(
        data?.data?.map((v) => {
          return {
            label: v.district,
            isExportable: true,
            name: v.district,
            count: v.count,
          };
        })
      );
    } catch (err) {
      console.log(err);
      setLoading4(false);
    }
  };

  // useEffect(() => {
  //   FetchGraph1();
  // }, []);

  // useEffect(() => {
  //   //setStatesSelected(stateSelected)
  //   check && FetchGraph1();
  // }, [check]);

  const FetchGraph2 = async () => {
    setLoading3(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/requirement-statistics`,
        {
          params: {
            created_by: sessionStorage.getItem("register_id")
              ? sessionStorage.getItem("register_id")
              : "",
            jp_id: orgNameID ? orgNameID : "",
          },
        }
      );
      setLoading3(false);
      const data = await res.data;
      setData4([
        {
          id: "Total Requirement",
          value: data.total_requirements,
          role: "Total Requirement",
          name: "Total Requirement",
          color: "#2D9CDB",
        },
        {
          id: "Total Matched",
          value: data.candidate_matched,
          role: "Total Matched",
          name: "Total Matched",
          color: "#90BE6D",
        },
      ]);
      // console.log(data4, "dd67");

      // if (roleType !== "role") {
      //   setData4(
      //     data?.data?.map((v, i) => {
      //       return {
      //         name: v.name
      //           ? v.name
      //               ?.split(" ")
      //               ?.map((v) => v?.charAt(0)?.toUpperCase() + v?.slice(1))
      //               ?.join(" ")
      //           : "",
      //         role: v.name
      //           ? v.name
      //               ?.split(" ")
      //               ?.map((v) => v?.charAt(0)?.toUpperCase() + v?.slice(1))
      //               ?.join(" ")
      //           : "",
      //         value: v.count,
      //         color: v.color,
      //         // percent: v.percent.toFixed(2),
      //       };
      //     })
      //   );
      //   setData3([]);
      // } else {
      //   setData3(
      //     data?.data?.map((v) => {
      //       return {
      //         name: v.name,
      //         "Total Candidates": v.count,
      //       };
      //     })
      //   );
      //   setData4([]);
      // }
    } catch (err) {
      setLoading3(false);
      console.log(err);
    }
  };

  const FetchGraph3 = async () => {
    setLoading1(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/candidate-summary`,
        {
          params: {
            // created_by: "SMR-SA-764877",
            created_by: sessionStorage.getItem("register_id")
              ? sessionStorage.getItem("register_id")
              : "",
          },
        }
      );
      setLoading1(false);

      const data = await res.data;
      console.log(data, "dd12");
      // setData1([
      //   {
      //     // name: v.name,
      //     "Total Candidates": data.total_candidates,
      //     "Total Candidate in pre-placement": data.shortlisted,
      //     "Total Candidate Attended": data.total_attended,
      //   },
      // ]);
      setData1([
        {
          name: "Total Candidates",
          count: data.total_candidates ? data.total_candidates : 0,
          fill: "#E67E23",
        },
        {
          name: "Total Candidate in pre-placement",
          count: data.total_pre_placement ? data.total_pre_placement : 0,
          fill: "#2D9CDB",
        },
        {
          name: "Total Candidate Attended",
          count: data.total_attended ? data.total_attended : 0,
          fill: "#F9C74F",
        },
      ]);
      // console.log(data1, "dd12");
      // if (orgNameID === "All") {
      //   const overalldata = data?.data?.map((v) => {
      //     return {
      //       name: v.name,
      //       "Total Requirements": v.requested,
      //       "Candidates Shortlisted": v.shortlisted,
      //       "Candidates Shared": v.shared,
      //     };
      //   });

      //   setData1A([
      //     {
      //       name: "Total Requirements",
      //       count: overalldata?.length > 0 ? overalldata[0]["Total Requirements"] : 0,
      //       fill: "#90BE6D",
      //     },
      //     {
      //       name: "Candidates Shared",
      //       count: overalldata?.length > 0 ? overalldata[0]["Candidates Shared"] : 0,
      //       fill: "#F9C74F",
      //     },
      //     {
      //       name: "Candidates Shortlisted",
      //       count: overalldata?.length > 0 ? overalldata[0]["Candidates Shortlisted"] : 0,
      //       fill: "#F94144",
      //     },
      //   ]);
      // } else {
      //   setData1(
      //     data?.data?.map((v) => {
      //       return {
      //         name: v.name,
      //         "Total Requirements": v.requested,
      //         "Candidates Shortlisted": v.shortlisted,
      //         "Candidates Shared": v.shared,
      //       };
      //     })
      //   );
      // }
    } catch (err) {
      setLoading1(false);
      console.log(err);
    }
  };

  const FetchGraph4 = async () => {
    setLoading2(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/pipeline`, {
        params: {
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      });

      const data = await res.data;
      setLoading2(false);
      setData6(
        data.map((el) => {
          return {
            Candidates: el.count,
            name: el.name,
            percent: el.percentage,
          };
        })
      );
    } catch (err) {
      console.log(err);
      setLoading2(false);
    }
  };

  const fetchPlacedData = async () => {
    setLoading1(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/placement-summary`,
        {
          params: {
            created_by: sessionStorage.getItem("register_id")
              ? sessionStorage.getItem("register_id")
              : "",
            jp_id: orgNameID ? orgNameID : "",
          },
        }
      );
      setLoading1(false);

      const data = await res.data;
      // console.log(data1, data, "dd12");

      setPlaced([
        {
          name: "Candidates Under Evaluation",
          count: data.shortlisted_candidate ? data.shortlisted_candidate : 0,
          fill: "#2D9CDB",
        },
        {
          name: "Candidates Selected",
          count: data.selecteed_candidates ? data.selecteed_candidates : 0,
          fill: "#E67E23",
        },
        {
          name: "Candidates Joined",
          count: data.joined_candidates ? data.joined_candidates : 0,
          fill: "#DA3636",
        },
      ]);
      // console.log(data1, "dd12");
    } catch (err) {
      setLoading1(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchPlacedData();
  }, [orgLabel2]);
  useEffect(() => {
    FetchGraph2();
  }, [orgLabel1]);

  useEffect(() => {
    FetchGraph3();
  }, []);

  useEffect(() => {
    FetchGraph4();
  }, [orgName, jobName]);

  const FetchGraph5 = async () => {
    setLoading6(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/date/users`,
        {
          start_date: datesArr?.length === 7 ? datesArr[0] : currentStart,
          end_date: datesArr?.length === 7 ? datesArr[6] : currentEnd,
          admin_id: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        }
      );
      setLoading6(false);
      const data = await res.data;
      setData10(
        data?.data?.map((v, i) => {
          return {
            name: v.date,
            "Channel Partner": v["Channel Partners"],
            "Job Provider": v["Job Providers"],
          };
        })
      );
    } catch (err) {
      console.log(err);
      setLoading6(false);
    }
  };

  const FetchGraph6 = async () => {
    setLoading1(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/candidate-summarys`,

        {
          year: selectYear,

          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        }
      )
      .then(
        (res) => (
          setLoading1(false),
          setData(
            res.data.data?.map((item) => ({
              ...item,
              name:
                item?.name === "June" || item?.name === "July" || item?.name === "September"
                  ? item?.name?.substring(0, 4)
                  : item?.name.substring(0, 3),
            }))
          )
        )
      )
      .catch((err) => {
        console.log("datasource chart:", err.response);
        setLoading1(false);
      });
  };

  console.log(data, "datasource");

  // useEffect(() => {
  //   FetchGraph5();
  // }, [datesArr]);

  // useEffect(() => {
  //   FetchGraph6();
  // }, [selectYear]);

  const OverallKPIFunc = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jfkpi`, {
        params: {
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then(
        (res) => (
          setLoading(false),
          // setKPIObj({
          //   super_admin_count: res.data["counter_data"].super_admin_count,
          //   admin_count: res.data["counter_data"].admin_count,
          //   channel_partner_count: res.data["counter_data"].channel_partner_count,

          //   counsellor_count: res.data["counter_data"].counsellor_count,
          //   job_fair_count: res.data["counter_data"].job_fair_count,
          //   job_provider_count: res.data["counter_data"].job_provider_count,
          //   job_seeker_count: res.data["counter_data"].job_seeker_count,
          // }),
          setKpiArr([
            {
              heading: "Job Fair Conducted",
              subheading: "Total Placed :",
              count1: res.data.total_conducted ? res.data.total_conducted : 0,
              // count2: res.data["counter_data"].placed_count
              //   ? res.data["counter_data"].placed_count
              //   : 0,
              img: A1,
            },
            {
              heading: "Ongoing Job fairs",
              subheading: "Total Job Post :",
              count1: res.data.ongoing_today ? res.data.ongoing_today : 0,
              // count2: res.data["counter_data"].job_post_count
              //   ? res.data["counter_data"].job_post_count
              //   : 0,
              img: A2,
            },
            {
              heading: "Completed Job Fairs",
              subheading: "Total Assigned :",
              count1: res.data.completed ? res.data.completed : 0,
              // count2: res.data["counter_data"].cp_count_with_job_assignment
              //   ? res.data["counter_data"].cp_count_with_job_assignment
              //   : 0,
              img: A3,
            },
            {
              heading: "Upcoming Job Fairs",
              subheading: "Recently Added :",
              count1: res.data.planned ? res.data.planned : 0,
              // count2: res.data["counter_data"].new_adm_count
              //   ? res.data["counter_data"].new_adm_count
              //   : 0,
              img: A4,
            },
            {
              heading: "Companies Registered",
              subheading: "Upcoming Job Fair :",
              count1: res.data.company_registered ? res.data.company_registered : 0,
              // count2: res.data["counter_data"].upcoming_jf_count
              //   ? res.data["counter_data"].upcoming_jf_count
              //   : 0,
              img: A5,
            },
            {
              heading: "Candidates Registered",
              subheading: "Recently Added :",
              count1: res.data.candidate_registered ? res.data.candidate_registered : 0,
              // count2: res.data["counter_data"].recently_added_counsellors
              //   ? res.data["counter_data"].recently_added_counsellors
              //   : 0,
              img: A6,
            },
          ])
        )
      )
      .catch((err) => {
        setLoading(false);
        setKpiArr([
          {
            heading: "Job Fair Conducted",
            subheading: "Total Placed :",
            count1: 0,
            count2: 0,
            img: A1,
          },
          {
            heading: "Ongoing Job fairs",
            subheading: "Total Job Post :",
            count1: 0,
            count2: 0,
            img: A2,
          },
          {
            heading: "Completed Job Fairs",
            subheading: "Total Assigned :",
            count1: 0,
            count2: 0,
            img: A3,
          },
          {
            heading: "Upcoming Job Fairs",
            subheading: "Recently Added :",
            count1: 0,
            count2: 0,
            img: A4,
          },
          {
            heading: "Companies Registered",
            subheading: "Upcoming Job Fair :",
            count1: 0,
            count2: 0,
            img: A5,
          },
          {
            heading: "Candidates Registered",
            subheading: "Recently Added :",
            count1: 0,
            count2: 0,
            img: A6,
          },
        ]);
      });
  };
  useEffect(() => {
    OverallKPIFunc();
    YearFunc();
  }, []);

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <SHeading>Dashboard</SHeading>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
              backgroundColor: "#FF5C00",
              borderRadius: "5px",
              cursor: "pointer",
              margin: { xs: "8px 0px", lg: "8px" },
              color: "#fff",
              fontSize: "15px",
              fontWeight: "medium",
              lineHeight: "19px",
            }}
            width={{ lg: hover1 ? "220px" : "40px", sm: "100%", xs: "100%" }}
            onClick={handleDownloadImage}
            onMouseOverCapture={() => setHover1(true)}
            onMouseLeave={() => setHover1(false)}
          >
            {/* Generate Report */}
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 11.082V18.082M14 18.082L11.6667 15.7487M14 18.082L16.3333 15.7487M9.8 22.1654C6.32061 22.1654 3.5 19.3705 3.5 15.9229C3.5 13.0655 5.6 10.4258 8.75 9.91537C9.73809 7.56613 12.0766 5.83203 14.8041 5.83203C18.2981 5.83203 21.1537 8.54162 21.35 11.957C23.2042 12.7677 24.5 14.7574 24.5 16.9117C24.5 19.8132 22.1495 22.1654 19.25 22.1654L9.8 22.1654Z"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {hover1 && <span style={{ marginLeft: "10px" }}>Export Report</span>}
          </Box>
        </Box>
        <div ref={componentRef}>
          <Grid container spacing={2} sx={{ mt: 0 }}>
            {loading ? (
              <>
                {Array.from(Array(6).keys()).map((v, i) => {
                  return (
                    <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                      <SBox1>
                        <SBox2>
                          <SVerticalBox>&nbsp;</SVerticalBox>

                          <SBox3>
                            <Box>
                              <Skeleton
                                variant="rounded"
                                animation="wave"
                                sx={{ height: "16px", width: "120px", m: 0.5, mb: 1.8 }}
                              />

                              <Skeleton
                                variant="rounded"
                                animation="wave"
                                sx={{ height: "24px", width: "90px", m: 0.5, mb: 1.4 }}
                              />

                              <SBox4>
                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  sx={{
                                    height: "30px",
                                    width: "140px",
                                    background: "rgba(112, 186, 191, 0.17)",
                                    borderRadius: "7px",
                                  }}
                                />
                              </SBox4>
                            </Box>

                            <Box>
                              <Skeleton
                                variant="rounded"
                                animation="wave"
                                sx={{ height: "64.909px", width: "68px" }}
                              />
                            </Box>
                          </SBox3>
                        </SBox2>
                      </SBox1>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <>
                {kpiArr.map((v, i) => {
                  return (
                    <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                      <SBox1>
                        <SBox2>
                          <SVerticalBox>&nbsp;</SVerticalBox>

                          <SBox3>
                            <Box>
                              <SKPIHeading>{v.heading}</SKPIHeading>

                              <SKPICount>{v.count1}</SKPICount>

                              {/* <Box
                              sx={{
                                p: 0.65,
                                m: 0.5,
                                background: "rgba(112, 186, 191, 0.17)",
                                borderRadius: "7px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <SKPISubHeading1>{v.subheading}</SKPISubHeading1>

                              <SKPISubHeading2>{v.count2}</SKPISubHeading2>
                            </Box> */}
                            </Box>

                            <Box
                              sx={{
                                height: "42px",
                                width: "42px",
                                backgroundColor: "#FFF5ED",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img src={v.img} alt="" height={"30px"} width={"30px"} />
                            </Box>
                          </SBox3>
                        </SBox2>
                      </SBox1>
                    </Grid>
                  );
                })}
              </>
            )}

            <Grid item lg={6} sm={12} md={12} xs={12}>
              <SGridBox>
                <SBox5
                  sx={{
                    p: 3,
                    pl: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SGHeading>
                    {/* All Data Source <span style={{ color: "#ACACAC" }}>vs</span>
                  <br />
                  Candidate Shared */}
                    <img src={cand_sum} alt="" height="20px" width="20px" />
                    <span style={{ marginLeft: "5px" }}>Candidate Summary</span>
                  </SGHeading>
                </SBox5>

                {loading1 ? (
                  <Box>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{
                        height: "400px",
                        width: "100%",
                        background: "rgba(112, 186, 191, 0.17)",
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    {/* {data1
                    ?.map((v) => {
                      return Object.keys(v)
                        ?.filter((v) => v !== "name")
                        ?.reduce((ele, key) => {
                          ele[key] = v[key];
                          return ele;
                        }, {});
                    })
                    ?.map((v) => Object.values(v))
                    ?.flat(1)
                    ?.every((v) => v === 0 || v === "") ||
                  data?.length === 0 ||
                  data === undefined ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "400px",
                        }}
                      >
                        <img src={nodata} alt="" height={"94px"} width={"94px"} />
                      </Box>
                    </>
                  ) : ( */}
                    <>
                      <ResponsiveContainer width="100%" height={360}>
                        <BarChart
                          width={400}
                          height={200}
                          data={data1}
                          margin={{
                            top: 10,
                            right: 15,
                            left: 15,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" fontSize={"9px"} />
                          <YAxis fontSize={"9px"} />
                          <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />

                          <Bar
                            dataKey="count"
                            fill="#8884d8"
                            barSize={50}
                            label={{ position: "center" }}
                          />
                          {/* <Bar dataKey="count" fill="#8884d8" barSize={25} /> */}
                          {/* <Bar dataKey="Candidates Shortlisted" fill="#F94144" barSize={25} /> */}
                          {/* <Bar dataKey="Total Candidate" fill="#E67E23" barSize={25} />
                        <Bar
                          dataKey="Total Candidate in pre-placement"
                          fill="#2D9CDB"
                          barSize={25}
                        />
                        <Bar dataKey="Total Candidate Attended" fill="#F9C74F" barSize={25} /> */}
                        </BarChart>
                      </ResponsiveContainer>

                      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#E67E23" }} />
                          Total Candidate Registered
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#2D9CDB" }} />
                          Total Candidate in pre-placement
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#F9C74F" }} />
                          Total Candidate Attended
                        </SGraphSubHeading>
                      </Box>
                    </>
                    {/* )} */}
                  </>
                )}
              </SGridBox>
            </Grid>

            <Grid item lg={6} sm={12} md={12} xs={12}>
              <SGridBox>
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SGHeading sx={{ alignItems: "center" }}>
                    <img src={place_sum} alt="" height="20px" width="20px" />
                    <span style={{ marginLeft: "5px" }}>Pipeline</span>
                  </SGHeading>
                </Box>

                {loading2 ? (
                  <Box>
                    {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{
                        height: "400px",
                        width: "100%",
                        background: "rgba(112, 186, 191, 0.17)",
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    {/* {data6
                    ?.map((v) => {
                      return Object.keys(v)
                        ?.filter((v) => !["name", "msg"].includes(v))
                        ?.reduce((ele, key) => {
                          ele[key] = v[key];
                          return ele;
                        }, {});
                    })
                    ?.map((v) => Object.values(v))
                    ?.flat(1)
                    ?.every((v) => v === 0 || v === "") ||
                  data6?.length === 0 ||
                  data6 === undefined ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "400px",
                        }}
                      >
                        <img src={nodata} alt="" height={"94px"} width={"94px"} />
                      </Box>
                    </>
                  ) : ( */}
                    <Box sx={{ width: "100%" }}>
                      <Grid container>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                          <Box sx={{ mt: 5 }}>
                            {data6.map((v, i) => {
                              return (
                                <Box key={i} sx={{ height: "60px", textAlign: "right" }}>
                                  <SGSubHeading>{v.name}</SGSubHeading>
                                </Box>
                              );
                            })}
                          </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={4}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              ml: 2,
                              mr: 2,
                            }}
                          >
                            <ReactApexChart
                              options={option3.options}
                              series={Series.series}
                              type="bar"
                              height={385}
                              width={200}
                            />
                          </Box>
                        </Grid>

                        <Grid item lg={2} md={2} sm={2} xs={2}>
                          <Box sx={{ mt: 5, ml: 6 }}>
                            {option3.options.xaxis.percentage.map((v, i) => {
                              return (
                                <Box key={i} sx={{ height: "60px", textAlign: "left" }}>
                                  <SGSubHeading>
                                    {v?.toFixed(2)?.split(".")[1] === "00"
                                      ? v?.toFixed(2)?.split(".")[0]
                                      : v?.toFixed(2)}
                                    %
                                  </SGSubHeading>
                                </Box>
                              );
                            })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* )} */}
                  </>
                )}
              </SGridBox>
            </Grid>

            <Grid item lg={6} sm={12} md={12} xs={12}>
              <Box
                sx={{
                  // width: "100%",
                  // m: 2,
                  // mt: 0,
                  // mb: 0,
                  // p: 1,
                  // background: "#FFFFFF",
                  // height: "500px",
                  // boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)",
                  // borderRadius: "8px",
                  // width: "100%",
                  // margin: "16px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  padding: "8px",
                  background: "#FFFCF566",
                  minHeight: "500px",
                  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "8px",
                }}
              >
                <SBox5>
                  <SGHeading>
                    <img src={req_stat} alt="" height="20px" width="20px" />
                    <span style={{ marginLeft: "5px" }}>Requirement Statistics</span>
                  </SGHeading>

                  <Select
                    isClearable
                    styles={stylesDD}
                    options={orgList}
                    placeholder="Company Name"
                    // value={orgLabel2 !== "" ? { label: orgLabel2, value: orgLabel2 } : null}
                    defaultValue={[{ label: "All", value: "" }]}
                    onChange={(e) => {
                      setOrgLabel1(e ? e.label : "");
                      setOrgNameID(e ? e.value : "");
                    }}
                  />
                </SBox5>

                {loading3 ? (
                  <Box>
                    {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{
                        height: "400px",
                        width: "100%",
                        background: "rgba(112, 186, 191, 0.17)",
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    {/* {data1
                        ?.map((v) => {
                          return Object.keys(v)
                            ?.filter((v) => v !== "name")
                            ?.reduce((ele, key) => {
                              ele[key] = v[key];
                              return ele;
                            }, {});
                        })
                        ?.map((v) => Object.values(v))
                        ?.flat(1)
                        ?.every((v) => v === 0 || v === "") ||
                      data1?.length === 0 ||
                      data1 === undefined ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "400px",
                          }}
                        >
                          <img src={nodata} alt="" height={"94px"} width={"94px"} />
                        </Box>
                      ) : ( */}
                    <>
                      {/* <ResponsiveContainer
                      width="100%"
                      // height={roleType === "role" ? 400 : "70%"}
                      height="70%"
                    >
                      <PieChart width={300} height={300}>
                        <Pie
                          data={data4}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={100}
                          innerRadius={60}
                          fill="#8884d8"
                          dataKey="value"
                          // label
                          // onMouseEnter={onPieEnter}
                        >
                          {data4?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} label />
                          ))}
                        </Pie>
                        <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />
                      </PieChart>
                    </ResponsiveContainer> */}
                      <div
                        style={{
                          height: "320px",
                          marginLeft: "5px",
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                        }}
                      >
                        <ResponsivePie
                          data={data4}
                          margin={{
                            top: 80,
                            right: 80,
                            bottom: 10,
                            left: 80,
                          }}
                          innerRadius={0.5}
                          motionConfig="gentle"
                          transitionMode="middleAngle"
                          padAngle={1}
                          cornerRadius={1}
                          activeOuterRadiusOffset={8}
                          borderWidth={1}
                          borderColor={{
                            from: "color",
                            modifiers: [["darker", 0.2]],
                          }}
                          // colors={{ scheme: "set1" }}
                          colors={{ scheme: "accent" }}
                          enableArcLinkLabels={false}
                          //   arcLinkLabel="id"
                          // arcLinkLabel={(e) => {
                          //   console.log("e", e);
                          //   return e.data.percent;
                          //   // return e.id + " (" + e.data.percent + ")";
                          // }}
                          arcLinkLabelsSkipAngle={10}
                          arcLinkLabelsTextColor="#333333"
                          arcLinkLabelsThickness={2}
                          // arcLinkLabelsDiagonalLength={9}
                          // arcLinkLabelsStraightLength={15}
                          arcLinkLabelsColor={{ from: "color" }}
                          enableArcLabels={true}
                          // arcLabel={(e) => e.data.percent}
                          // arcLabelsSkipAngle={10}
                          arcLabelsTextColor={{
                            from: "color",
                            modifiers: [["darker", 2]],
                          }}
                          legends={[
                            {
                              anchor: "bottom",
                              direction: "column",
                              justify: false,
                              // translateX: -250,
                              // translateY: 10,
                              translateX: -250,
                              translateY: 10,
                              itemsSpacing: 5,
                              itemWidth: 60,
                              itemHeight: 20,
                              itemTextColor: "#000",
                              itemDirection: "left-to-right",
                              itemOpacity: 1,
                              symbolSize: 18,
                              symbolShape: "square",
                              effects: [
                                {
                                  on: "hover",
                                  style: {
                                    itemTextColor: "#000",
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                      </div>
                    </>
                    {/* )} */}
                  </>
                )}
              </Box>
            </Grid>

            <Grid item lg={6} sm={12} md={12} xs={12}>
              <SGridBox>
                <SBox5>
                  <SGHeading>
                    <img src={place_sum} alt="" height="20px" width="20px" />
                    <span style={{ marginLeft: "5px" }}>Placement Summary</span>
                  </SGHeading>

                  <Select
                    isClearable
                    styles={stylesDD}
                    options={orgList}
                    placeholder="Company Name"
                    // value={orgLabel2 !== "" ? { label: orgLabel2, value: orgLabel2 } : null}
                    defaultValue={[{ label: "All", value: "" }]}
                    onChange={(e) => {
                      setOrgLabel2(e ? e.label : "");
                      setOrgNameID(e ? e.value : "");
                    }}
                  />

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 5px rgba(0,0,0,0.10))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: "100%",
                          height: "100%",
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <Box
                      sx={{
                        maxHeight: "400px",
                        minHeight: "100%",
                        overflow: "auto",
                        paddingTop: "8px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "5px",
                          textDecoration: "underline",
                          color: "#3B5092",
                          cursor: "pointer",
                        }}
                        className="ListCSV"
                        // onClick={(e) => setClear(true)}
                        onClick={(e) => (
                          console.log("use to deselect all"),
                          setClear(true),
                          setCheck(false),
                          setStateSelected(
                            stateSelected?.map((v, i) => {
                              return {
                                label: v.label,
                                isExportable: false,
                                name: v.name,
                                count: v.count,
                                // "Total Candidates": 500 + (i + 1) * 2,
                              };
                            })
                          )
                        )}
                      >
                        Clear All
                      </span>
                      <ul className="UlCSV" style={{ paddingTop: "6px", padddingBottom: "6px" }}>
                        <input
                          type="checkbox"
                          checked={check}
                          disabled={
                            statesSelected?.length === stateSelected?.length && !clear
                              ? true
                              : false
                          }
                          onChange={(e) => (setCheck(!check), setClear(false))}
                        />
                        <span className="ListCSV">&nbsp;All</span>
                      </ul>
                      {/* </Box> */}
                      {stateSelected.map((ele, index) => (
                        <ul
                          key={index}
                          className="UlCSV"
                          style={{ paddingTop: "6px", padddingBottom: "6px" }}
                        >
                          <input
                            type="checkbox"
                            checked={ele.isExportable}
                            onChange={(event) =>
                              handleFormChange12(event, index, stateSelected, setStateSelected)
                            }
                          />
                          <span className="ListCSV">&nbsp;{ele.label}</span>
                        </ul>
                      ))}
                    </Box>
                  </Menu>
                </SBox5>

                {loading4 ? (
                  <Box>
                    {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{
                        height: "400px",
                        width: "100%",
                        background: "rgba(112, 186, 191, 0.17)",
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    {/* {statesSelected
                    ?.map((v) => {
                      return Object.keys(v)
                        ?.filter((v) => v === "count")
                        ?.reduce((ele, key) => {
                          ele[key] = v[key];
                          return ele;
                        }, {});
                    })
                    ?.map((v) => Object.values(v))
                    ?.flat(1)
                    ?.every((v) => v === 0 || v === "") ||
                  statesSelected?.length === 0 ||
                  statesSelected === undefined ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "400px",
                      }}
                    >
                      <img src={nodata} alt="" height={"94px"} width={"94px"} />
                    </Box>
                  ) : ( */}
                    <>
                      <ResponsiveContainer width="100%" height={360}>
                        <BarChart
                          width={500}
                          height={200}
                          data={placed}
                          margin={{
                            top: 10,
                            right: 15,
                            left: 15,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" fontSize={"9px"} />
                          <YAxis fontSize={"9px"} />
                          <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />

                          <Bar
                            dataKey="count"
                            fill="#8884d8"
                            barSize={35}
                            label={{ position: "center" }}
                          />
                          {/* <Bar dataKey="count" fill="#8884d8" barSize={25} /> */}
                          {/* <Bar dataKey="Candidates Shortlisted" fill="#F94144" barSize={25} /> */}
                          {/* <Bar dataKey="Total Candidate" fill="#E67E23" barSize={25} />
                        <Bar
                          dataKey="Total Candidate in pre-placement"
                          fill="#2D9CDB"
                          barSize={25}
                        />
                        <Bar dataKey="Total Candidate Attended" fill="#F9C74F" barSize={25} /> */}
                        </BarChart>
                      </ResponsiveContainer>

                      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#2D9CDB" }} />
                          Candidates Under Evaluation
                        </SGraphSubHeading>
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#E67E23" }} />
                          Candidates Selected
                        </SGraphSubHeading>
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#DA3636" }} />
                          Candidates Joined
                        </SGraphSubHeading>
                      </Box>
                    </>
                    {/* )} */}
                  </>
                )}
              </SGridBox>
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Overview;
