import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
// import ProgressBar from "utils/progressBar";

const CenterHeadRegistration = () => {
  const navigate = useNavigate();
  // const [showPasswordBox,setShowPasswordBox] = useState(true);
  const barStyle = {
    bgcolor: "#3B5092",
    completed: 50,
  };
  const [autopass, Setautopass] = useState(true);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });
  const [questions, setQuestions] = useState([]);

  // const fatchAutopass = () => {
  //   axios.get(`${process.env.REACT_APP_BACKEND_URL}/generic/genpass`).then(res => { setCopyPassword({ ...copyPassword, value: res.data?.Password }) })
  // }
  // useEffect(() => {
  //   if (autopass) {
  //     fatchAutopass();
  //   }

  // }, [autopass])
  // useEffect(()=>{
  //   axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/securityques`,
  //   {
  //     "partner_association":"samarth",
  // })
  //   .then((res)=>{
  //     console.log("Questions",res.data);
  //     setQuestions(res.data);
  //   })
  //   .catch((err)=>{
  //     console.log(err);
  //   })
  // },[]);

  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data.Password });
    });
  };

  // autopass
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    //   const resDATA = sessionStorage.getItem("register_id");
    //   const creatorRole = sessionStorage.getItem("creator_role");
    //   console.log(resDATA);
    //   console.log(creatorRole);
    //  if(resDATA){
    //     setRegisterID(()=> resDATA);
    //  }
    //  if(creatorRole){
    //   setRoleId(creatorRole);
    //  }
    //  console.log("createdby :",registerID);
    //  console.log("creator_role :",roleId);
  }, [autopass]);

  // securityques
  useEffect(() => {
    securityQuestionFunc();
  }, []);

  const reporting = [
    {
      label: "Super Admin 1",
      value: "Super Admin 1",
    },
    {
      label: "Super Admin 2",
      value: "Super Admin 2",
    },
    {
      label: "Super Admin 3",
      value: "Super Admin 3",
    },
    {
      label: "Super Admin 4",
      value: "Super Admin 4",
    },
    {
      label: "Super Admin 5",
      value: "Super Admin 5",
    },
  ];

  const centerheadform = useFormik({
    enableReinitialize: true,
    initialValues: {
      createdby: "SA1",
      creator_role: "SA",
      role_id: "CH",
      email: "",
      mobnum: "",
      firstnm: "",
      lastnm: "",
      password: copyPassword.value ?? "",
      confirm_password: copyPassword.value ?? "",
      security_ques: "",
      security_ans: "",
      aadhar: "",
      pannum: "",
    },
    validationSchema: Yup.object().shape({
      // email: Yup.string().email("Please enter valid email").required("Please enter email"),
      // mobnum: Yup.string()
      //   .required("Please enter mobile number")
      //   .length(10, "Mobile number should be 10 Digit")
      //   .matches(/^[0-9]+$/, "Must be only digits"),
      // aadhar: Yup.string()
      //   .required("Please enter aadhar number")
      //   .length(12, "Aadhar number should be 12 Digit"),
      // pannum: Yup.string()
      //   .required("Please enter pan cand number")
      //   .length(10, "Pancard number should be 10 Alpanumeric"),
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required("Please enter last name"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
      security_ques: Yup.string().required("Please select question"),
      security_ans: Yup.string().required("Please enter answer"),
    }),
    onSubmit: (values) => {
      let finalvalue = values;
      localStorage.setItem("reg1", JSON.stringify(finalvalue));
      navigate("/center-head-registration-page2");
    },
  });

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              Center Head Registration
            </SoftTypography>
            <Grid container>
              <SoftBox mt={4} width="100%" height="auto">
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox mt={4} width="100%" height="auto">
                    <SoftBox pl={2} pr={4} pt={2} pb={3}>
                      <SoftTypography fontWeight="medium" fontSize="16px" mb={4.5}>
                        Contact Details
                      </SoftTypography>
                      {/* <SoftBox>
                        <ProgressBar bgcolor={barStyle.bgcolor} completed={barStyle.completed}/>
                      </SoftBox> */}
                      <SoftBox component="form" role="form" onSubmit={centerheadform.handleSubmit}>
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Email
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="email"
                                  placeholder=""
                                  id="email"
                                  name="email"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.email}
                                />
                                {centerheadform.touched.email && centerheadform.errors.email ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.email}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Mobile Number
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="mobnum"
                                  name="mobnum"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.mobnum}
                                />
                                {centerheadform.touched.mobnum && centerheadform.errors.mobnum ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.mobnum}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    First Name
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="firstnm"
                                  name="firstnm"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.firstnm}
                                />
                                {centerheadform.touched.firstnm && centerheadform.errors.firstnm ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.firstnm}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Last Name
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="lastnm"
                                  name="lastnm"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.lastnm}
                                />
                                {centerheadform.touched.lastnm && centerheadform.errors.lastnm ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.lastnm}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Aadhaar Card Number
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="number"
                                  placeholder=""
                                  id="aadhar"
                                  name="aadhar"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.aadhar}
                                />
                                {centerheadform.touched.aadhar && centerheadform.errors.aadhar ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.aadhar}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    PAN Card Number
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="pannum"
                                  name="pannum"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.pannum}
                                />
                                {centerheadform.touched.pannum && centerheadform.errors.pannum ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.pannum}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                              <SoftBox
                                // pl={2}
                                display="flex"
                                flexDirection="row"
                                width="100%"
                                height="auto"
                                sx={{
                                  border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                  bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                }}
                              >
                                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                  <input
                                    name="password"
                                    type="radio"
                                    defaultChecked="true"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        Setautopass(true);
                                      } else {
                                        Setautopass(false);
                                      }
                                    }}
                                  />
                                </SoftBox>

                                <SoftBox display="flex" flexDirection="column" my="5px">
                                  <SoftTypography fontWeight="medium" fontSize="18px">
                                    Auto Generate Password
                                  </SoftTypography>
                                  <SoftTypography
                                    fontWeight="small"
                                    fontSize="15px"
                                    lineHeight="16px"
                                  >
                                    Password will be automatically generated after the registration
                                    is done.
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                              <SoftBox
                                // pl={2}
                                display="flex"
                                flexDirection="row"
                                width="100%"
                                height="75px"
                                sx={{
                                  border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                  bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                }}
                              >
                                <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                  <input
                                    name="password"
                                    type="radio"
                                    onChange={() => {
                                      centerheadform.setFieldValue("password", "");
                                      centerheadform.setFieldValue("confirm_password", "");
                                      Setautopass(false);
                                    }}
                                  />
                                </SoftBox>
                                <SoftBox display="flex" flexDirection="column" my="5px">
                                  <SoftTypography fontWeight="medium" fontSize="18px">
                                    Custom Password
                                  </SoftTypography>
                                  <SoftTypography
                                    fontWeight="small"
                                    fontSize="15px"
                                    lineHeight="16px"
                                  >
                                    You can create a custom password for the user
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Password
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="password"
                                  placeholder=""
                                  disabled={autopass}
                                  id="password"
                                  name="password"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.password}
                                />
                                {centerheadform.touched.password &&
                                centerheadform.errors.password ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.password}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Confirm Password
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="password"
                                  placeholder=""
                                  disabled={autopass}
                                  id="confirm_password"
                                  name="confirm_password"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.confirm_password}
                                />
                                {centerheadform.touched.confirm_password &&
                                centerheadform.errors.confirm_password ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.confirm_password}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Security Question
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftSelect
                                  placeholder="select questions"
                                  options={questions}
                                  id="security_ques"
                                  name="security_ques"
                                  onChange={(e) =>
                                    centerheadform.setFieldValue("security_ques", e.label)
                                  }
                                  value={{ label: centerheadform.values.security_ques }}
                                />
                                {centerheadform.touched.security_ques &&
                                centerheadform.errors.security_ques ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.security_ques}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Answer
                                  </SoftTypography>
                                  <SoftBox sx={{ color: "red", position: "inherit" }}>
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="security_ans"
                                  name="security_ans"
                                  onChange={centerheadform.handleChange}
                                  value={centerheadform.values.security_ans}
                                />
                                {centerheadform.touched.security_ans &&
                                centerheadform.errors.security_ans ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerheadform.errors.security_ans}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={centerheadform.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Next
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => navigate("/super-admin-register")}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default CenterHeadRegistration;
