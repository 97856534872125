import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SoftInput from "components/SoftInput";
import SoftBox from "components/SoftBox";
import axios from "axios";
import Swal from "sweetalert2";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { Box } from "@mui/material";
import htmlToDraft from "html-to-draftjs";
import { ContentState } from "draft-js";
import "./styles.css";

const Notes = (props) => {
  const { candData, time, setTime } = props;
  console.log("time:", time);
  console.log("settime:", setTime);
  console.log("candata profile :", candData);
  const [candidateNotes, setCandidatesNotes] = useState("");
  console.log("notesss:", candidateNotes);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [notesdes, setNotesDes] = useState("");

  const html = candData?.notes;

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  useEffect(() => {
    setEditorState(htmlToDraftBlocks(candData?.notes ? candData.notes : "<p></p>"));
  }, [props?.candData]);

  useEffect(() => {
    setNotesDes(candData?.notes);
  }, [props?.candData]);

  //const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const addNotes = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/notes`, {
        notes: convertedContent,
        candidate_id: candData.registration_id,
      })
      .then((res) => {
        console.log("notes:", res.data);
        Swal.fire({
          icon: "success",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          title: "Success",
          text: "Notes Added Successfully",
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch((err) => {
        console.log("err", err.response);
        Swal.fire({
          icon: "error",
          // title: res.data["Developer Message"] ? res.data["Developer Message"] : "",
          // text: res.data["User Message"],
          title: "Oops...",
          text: "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <Box textAlign={'left'}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', m: 1, mt:0 }}>
        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#3B5092",
            color: "#fff",
            width: "70px",
            borderRadius: "8px",
            fontSize: "15px",
            marginRight:"8px"
          }}
          onClick={addNotes}
        >
          Save
        </SoftBox>
      </Box>

      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />

    </Box>
  );
};

export default Notes;

Notes.propTypes = {
  candData: PropTypes.object,
  //   veridetails: PropTypes.object,
  time: PropTypes.array,
  setTime: PropTypes.array,
};
