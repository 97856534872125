import { MdMoreHoriz } from "react-icons/md";
import SoftBadge from "components/SoftBadge";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import React, { useState } from "react";

// const [applyModal, setApplyModal] = useState(false);

const columns = [
  {
    title: "Candidate Name",
    width: 150,
    dataIndex: "candidate_name",
    key: "candidate_name",
    fixed: "left",
    //   sorter: true,
    sorter: { compare: (a, b) => a.candidate_name - b.candidate_name },
  },
  { title: "Roles", dataIndex: "role", key: "2" },
  {
    title: "Phone",
    //   width: 100,
    dataIndex: "phone",
    key: "phone",
    //   fixed: 'left',
    //   sorter: true,
  },
  // { title: "Qualification", dataIndex: "qualification", key: "1" },
  // { title: 'Modified On', dataIndex: 'modified_on', key: '2' },

  {
    title: "Score",
    dataIndex: "score",
    sorter: { compare: (a, b) => a.score - b.score },
    key: "6",
    render: (text, record) => (
      <span>
        <SoftBadge
          variant="contained"
          color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
          size="xs"
          badgeContent={
            text >= 80 ? "Excellent Match" : text < 80 && text >= 45 ? "Medium Match" : "Poor Match"
          }
          container
        />
      </span>
    ),
  },
  { title: "Last Active", dataIndex: "last_active", key: "5" },
  {
    title: "Status",
    dataIndex: "status",
    sorter: { compare: (a, b) => a.status - b.status },
    key: "7",
    render: (text, record) => (
      <span>
        <SoftBadge
          variant="contained"
          color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
          size="xs"
          badgeContent={
            text >= 80 ? "Excellent Match" : text < 80 && text >= 45 ? "Medium Match" : "Poor Match"
          }
          container
        />
      </span>
    ),
  },
  { title: "Location", dataIndex: "location", key: "4" },
  // { title: "Column 7", dataIndex: "address", key: "7" },
  // { title: "Column 8", dataIndex: "address", key: "8" },

  {
    title: "action",
    key: "operation",
    fixed: "right",
    width: 30,
    render: () => <MdMoreHoriz />,
  },
];
const columns2 = [
  {
    title: "Candidate Name",
    width: 150,
    dataIndex: "candidate_name",
    key: "candidate_name",
    fixed: "left",
    //   sorter: true,
    sorter: { compare: (a, b) => a.candidate_name - b.candidate_name },
  },
  // { title: "Roles", dataIndex: "role", key: "2" },
  {
    title: "Phone",
    //   width: 100,
    dataIndex: "phone",
    key: "phone",
    //   fixed: 'left',
    //   sorter: true,
  },
  { title: "Education", dataIndex: "education", key: "1" },
  { title: "Modified On", dataIndex: "modified_on", key: "2" },
  { title: "Skills", dataIndex: "skills", key: "3" },
  { title: "Location", dataIndex: "location", key: "4" },
  { title: "Last Active", dataIndex: "last_active", key: "5" },
  {
    title: "Score",
    dataIndex: "score",
    sorter: { compare: (a, b) => a.score - b.score },
    key: "6",
    render: (text, record) => (
      <span>
        <SoftBadge
          variant="contained"
          color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
          size="xs"
          badgeContent={
            text >= 80 ? "Excellent Match" : text < 80 && text >= 45 ? "Medium Match" : "Poor Match"
          }
          container
        />
      </span>
    ),
  },

  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   sorter: { compare: (a, b) => a.status - b.status },
  //   key: "7",
  //   render: (text, record) => (
  //     <span>
  //       <SoftBadge
  //         variant="contained"
  //         color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
  //         size="xs"
  //         badgeContent={
  //           text >= 80 ? "Excellent Match" : text < 80 && text >= 45 ? "Medium Match" : "Poor Match"
  //         }
  //         container
  //       />
  //     </span>
  //   ),
  // },

  // { title: "Column 7", dataIndex: "address", key: "7" },
  // { title: "Column 8", dataIndex: "address", key: "8" },

  {
    title: "action",
    key: "operation",
    fixed: "right",
    width: 30,
    // render: () => <MdMoreHoriz />,
    render: () => (
      <div className="edit_icons">
        <div className="dropdown">
          <Button
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Icon icon="ion:ellipsis-horizontal" color="black" />
          </Button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a
                className="dropdown-item"
                href="#EditUser"
                onClick={() => {
                  console.log("whatsapp verification modal");
                  // setApplyModal(true);
                }}
              >
                Whatsapp Verification
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#Deleteuser"
                onClick={() => {
                  console.log("counsellors modal");
                  // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                }}
              >
                Counsellors
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#Deleteuser"
                // onClick={() => { deActiveFun(admindata); }}
              >
                SMS Verification
              </a>
            </li>
          </ul>
        </div>
      </div>
    ),
  },
];
const data = [];
for (let i = 0; i < 20; i++) {
  data.push({
    key: i + 1,
    candidate_name: `John Brown ${i + 1}`,
    jobid: `SMR-JOB-147852`,
    // phone: "9855664444",
    phone: "9892038669",
    education: "Ambernath School",
    modified_on: "19-02-2022",
    role: "Carpenter,welding,Painter",
    location: "Kurla",
    last_active: "23-02-2022",
    skills: "Cartpenter,welding",
    score: `80`,
    status: "45",
  });
}

export { columns, columns2, data };
