import React from "react";
// import MainPage from "./Components/MainPage.js";
import SideDrawer from "./SideDrawer";
import Backdrop from "./BackDrop.js";
import PropTypes from "prop-types";

const Index = (props) => {
  const {
    open,
    setOpen,
    orgId,
    jobId,
    admindata,
    searchData,
    setSearchData,
    Setadmindata,
    desgination,
    salary,
    selectedFilterState,
    selectedFilterDistrict,
    qualification,
    experience,
    stream,
    specialization,
    selectedDSF,
    setDesignation,
    setSalary,
    setSelectedFilterState,
    setSelectedFilterDistrict,
    setQualification,
    setExperirnce,
    setStream,
    setSpecialization,
    setSelectedDSF,
    fetchFilteredCandidates,
    paginationState,
    setPaginationState,
    filterOffsetFunc,
    setAge,
    age,
    gendertype,
    setGenderType,
    scoreBased,
    setScoreBased,
    selectedCPs,
    setSelectedCPs,
    resetFunc,
    setFunc,
  } = props;
  const backdropClickHandler = () => {
    setOpen(false);
  };
  let backdrop;
  if (open) {
    backdrop = <Backdrop close={backdropClickHandler} />;
  }
  return (
    <div>
      <SideDrawer
        show={open}
        setOpen={setOpen}
        orgId={orgId}
        jobId={jobId}
        admindata={admindata}
        Setadmindata={Setadmindata}
        searchData={searchData}
        setSearchData={setSearchData}
        desgination={desgination}
        salary={salary}
        selectedFilterState={selectedFilterState}
        selectedFilterDistrict={selectedFilterDistrict}
        qualification={qualification}
        experience={experience}
        stream={stream}
        specialization={specialization}
        selectedDSF={selectedDSF}
        setDesignation={setDesignation}
        setSalary={setSalary}
        setSelectedFilterState={setSelectedFilterState}
        setSelectedFilterDistrict={setSelectedFilterDistrict}
        setQualification={setQualification}
        setExperirnce={setExperirnce}
        setStream={setStream}
        setSpecialization={setSpecialization}
        setSelectedDSF={setSelectedDSF}
        fetchFilteredCandidates={fetchFilteredCandidates}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        filterOffsetFunc={filterOffsetFunc}
        gendertype={gendertype}
        setGenderType={setGenderType}
        age={age}
        setAge={setAge}
        setScoreBased={setScoreBased}
        scoreBased={scoreBased}
        selectedCPs={selectedCPs}
        setSelectedCPs={setSelectedCPs}
        resetFunc={resetFunc}
        setFunc={setFunc}
      />
      {backdrop}
    </div>
  );
};
Index.propTypes = {
  open: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  orgId: PropTypes.string,
  jobId: PropTypes.string,
  admindata: PropTypes.array,
  Setadmindata: PropTypes.array,
  searchData: PropTypes.array,
  setSearchData: PropTypes.array,
  desgination: PropTypes.string,
  salary: PropTypes.string,
  selectedFilterState: PropTypes.string,
  selectedFilterDistrict: PropTypes.string,
  selectedCPs: PropTypes.string,
  qualification: PropTypes.string,
  experience: PropTypes.string,
  stream: PropTypes.string,
  specialization: PropTypes.string,
  selectedDSF: PropTypes.string,
  setDesignation: PropTypes.string,
  setSalary: PropTypes.string,
  setSelectedFilterState: PropTypes.string,
  setSelectedFilterDistrict: PropTypes.string,
  setSelectedCPs: PropTypes.string,
  setQualification: PropTypes.string,
  setExperirnce: PropTypes.string,
  setStream: PropTypes.string,
  setSpecialization: PropTypes.string,
  setSelectedDSF: PropTypes.string,
  fetchFilteredCandidates: PropTypes.func,
  setPaginationState: PropTypes.object,
  paginationState: PropTypes.object,
  filterOffsetFunc: PropTypes.func,
  setAge: PropTypes.string,
  age: PropTypes.number,
  gendertype: PropTypes.string,
  setGenderType: PropTypes.string,
  setScoreBased: PropTypes.boolean,
  scoreBased: PropTypes.boolean,
  resetFunc:PropTypes.func,
  setFunc:PropTypes.func,
};
export default Index;
