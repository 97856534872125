import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";

const copy = require("clipboard-copy");

const PasswordResetAdmin = (props) => {
  console.log("editdata", props.editdata);
  const [autopass, Setautopass] = useState(true);
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [token, setToken] = useState("");
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });
  const [show, Setshow] = useState(false);
  const [showpassword, Setshowpassword] = useState(false);
  const [displayPassword, setDisplayPassword] = useState("");

  const showAlert = () =>
    Swal.fire({
      position: "top",
      icon: "success",
      text: "Password successfully updated",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      Setshow(false);
      Setshowpassword(false);
      navigate("/super-admins-list-page");
    });
  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [`Auto Generate Password : ${showpassword ? displayPassword : copyPassword.value}`],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };

  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data?.Password });
    });
  };
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    // const resDATA = sessionStorage.getItem("register_id");
    // const creatorRole = sessionStorage.getItem("creator_role");
    // if (resDATA) {
    //     setRegisterID(() => resDATA);
    // }
    // if(creatorRole){
    //     setRoleId(creatorRole);
    // }
  }, [autopass]);
  useEffect(() => {
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    const token = sessionStorage.getItem("token");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    if (token) {
      setToken(token);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);

  const navigate = useNavigate();
  const adminform = useFormik({
    enableReinitialize: true,
    initialValues: {
      //   org_name: props.editdata?.org_name ?? "",
      //   org_address: props.editdata?.org_address ?? "",
      //   org_state: props.editdata?.org_state ?? "",
      //   org_city: props.editdata?.org_city ?? "",
      //   org_pincode: props.editdata?.org_pincode ?? "",
      //   org_website: props.editdata?.org_website ?? "",
      //   org_cin_num: props.editdata?.org_cin_num ?? "",
      //   email: props.editdata?.email ?? "",
      //   mobnum: props.editdata?.mobnum ?? "",
      //   firstnm: props.editdata?.firstnm ?? "",
      //   lastnm: props.editdata?.lastnm ?? "",
      //   aadhar: props.editdata?.aadhar ?? "",
      password: copyPassword.value ?? "",
      confirmpassword: copyPassword.value ?? "",
      //   security_ques: "",
      //   security_ans:"",
      // answer: ""
    },
    validationSchema: Yup.object().shape({
      //   org_name: Yup.string().required("Please enter organization name"),
      //   org_address: Yup.string().required("Please enter address name"),
      //   org_state: Yup.string().required("Please enter state name"),
      //   org_city: Yup.string().required("Please enter city name"),
      //   org_pincode: Yup.string().required("Please enter pincode").length(6, "Pincode should be 6 digit").matches(/^[0-9]+$/, "Must be only digits"),
      //   org_website: Yup.string()
      //     .matches(
      //       /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //       'Please enter correct url!'
      //     )
      //     .required('Please enter website'),
      //   org_cin_num: Yup.string().required("Please enter company CIN number").length(21,"CIN number should be 21 digits"),
      //   email: Yup.string().email("Please enter valid email").required("Please enter email"),
      //   mobnum: Yup.string().required("Please enter mobile number").length(10, "Mobile number should be 10 Digit").matches(/^[0-9]+$/, "Must be only digits"),
      //   firstnm: Yup.string().required("Please enter first name"),
      //   lastnm: Yup.string().required("Please enter last name"),
      //   aadhar: Yup.string().required("Please enter aadhar card number").length(12, "Aadhar card number should be 12 Digit"),
      //   security_ques: Yup.string().required("Please select question"),
      //   security_ans: Yup.string().required("Please enter answer"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirmpassword: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
    }),
    onSubmit: (value) => {
      console.log(value);
      delete value.confirmpassword;
      setDisplayPassword(value.password);
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/quickresetpassword`,
          {
            // "reg_id":props.editdata.key,
            // "role_id": "SA",
            // "partner-association":"samarth",
            // "password": values.password
            // modifier_role: "ADM",
            // updater_role: roleId,
            // partner_association: "samarth",
            updatedby: registerID,
            // updatedby: "SMR-SA-1",
            modifier_id: props.editdata.key,
            password: value.password,
          }
          //{
          // headers: {
          //     Authorization:  `Bearer ${token}` ,
          // },
          //   { ...value, role_id: "ADM",
          //   creator_role: roleId,
          //   // creator_role: "SA"
          //    createdby: registerID }
        )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            // Setshow(true);
            Swal.fire({
              //position: "top",
              icon: "success",
              title: "Updated",
              text: "Profile updated successfully",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              props.Seteditadmin(false);
              Setshow(true);
              //   fetchadminList();
              navigate("/admins-list-page");
            });
          } else {
            Swal.fire({
              icon: "info",
              title: "",
              title: `${res.data["Developer Message"]}`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            ttitle: err?.response?.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Something went wrong",
            text: err?.response?.data["User Message"]
              ? err.response.data["User Message"]
              : "Something went wrong",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            // props.Seteditadmin(false);
            navigate("/admins-list-page");
          });
        });
    },
  });

  return (
    <>
      <Modal
        show={props?.editadmin}
        onHide={() => {
          props?.Seteditadmin(false);
        }}
        size="lg"
        centered
        style={{ marginTop: "52px" }}
      >
        <Modal.Header closeButton>Reset Password</Modal.Header>

        <Modal.Body>
          <SoftBox mt={0}>
            <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
              {/* <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                                Admin Registration
                            </SoftTypography> */}
              <SoftBox component="form" role="form" onSubmit={adminform.handleSubmit}>
                <Grid container>
                  <SoftBox width="100%" height="auto" mt={2} pr={2}>
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox width="100%" height="auto">
                        <SoftBox>
                          <SoftTypography fontWeight="medium" fontSize="16px" mb={4.5}>
                            Registration Details
                          </SoftTypography>
                          <SoftBox component="form" role="form">
                            <SoftBox mb={3}>
                              <Grid container spacing={8}>
                                <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                                  <SoftBox
                                    display="flex"
                                    flexDirection="row"
                                    width="100%"
                                    height="auto"
                                    sx={{
                                      border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                      bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                    }}
                                  >
                                    <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                      <input
                                        name="password"
                                        type="radio"
                                        defaultChecked="true"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            Setautopass(true);
                                          } else {
                                            Setautopass(false);
                                          }
                                        }}
                                      />
                                    </SoftBox>

                                    <SoftBox display="flex" flexDirection="column" my="5px">
                                      <SoftTypography fontWeight="medium" fontSize="18px">
                                        Auto Generate Password
                                      </SoftTypography>
                                      <SoftTypography
                                        fontWeight="small"
                                        fontSize="15px"
                                        lineHeight="16px"
                                      >
                                        Password will be automatically generated after the
                                        registration is done.
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                </Grid>
                                <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                                  <SoftBox
                                    // pl={2}
                                    display="flex"
                                    flexDirection="row"
                                    width="100%"
                                    height="75px"
                                    sx={{
                                      border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                      bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                    }}
                                  >
                                    <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                      <input
                                        name="password"
                                        type="radio"
                                        onChange={() => {
                                          adminform.setFieldValue("password", "");
                                          adminform.setFieldValue("confirmpassword", "");
                                          Setautopass(false);
                                        }}
                                      />
                                    </SoftBox>
                                    <SoftBox display="flex" flexDirection="column" my="5px">
                                      <SoftTypography fontWeight="medium" fontSize="18px">
                                        Custom Password
                                      </SoftTypography>
                                      <SoftTypography
                                        fontWeight="small"
                                        fontSize="15px"
                                        lineHeight="16px"
                                      >
                                        You can create a custom password for the user
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                </Grid>
                              </Grid>
                            </SoftBox>
                            <SoftBox mb={3}>
                              <Grid container spacing={8}>
                                <Grid item xs={12} lg={6} container spacing={3}>
                                  <SoftBox width="100%" pl={2}>
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Password
                                    </SoftTypography>
                                    <SoftInput
                                      type="password"
                                      placeholder=""
                                      disabled={autopass}
                                      id="password"
                                      name="password"
                                      onChange={adminform.handleChange}
                                      value={adminform.values.password}
                                    />
                                    {adminform.touched.password && adminform.errors.password ? (
                                      <span style={{ color: "red", fontSize: "12px" }}>
                                        {adminform.errors.password}
                                      </span>
                                    ) : null}
                                  </SoftBox>
                                </Grid>
                                <Grid item xs={12} lg={6} container spacing={3}>
                                  <SoftBox width="100%" pl={2}>
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Confirm Password
                                    </SoftTypography>
                                    <SoftInput
                                      type="password"
                                      placeholder=""
                                      disabled={autopass}
                                      id="confirmpassword"
                                      name="confirmpassword"
                                      onChange={adminform.handleChange}
                                      value={adminform.values.confirmpassword}
                                    />
                                    {adminform.touched.confirmpassword &&
                                    adminform.errors.confirmpassword ? (
                                      <span style={{ color: "red", fontSize: "12px" }}>
                                        {adminform.errors.confirmpassword}
                                      </span>
                                    ) : null}
                                  </SoftBox>
                                </Grid>
                              </Grid>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer>
          <Grid container my={2} ml={0}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6} sm={6} container spacing={1}>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "45px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={adminform.handleSubmit}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Submit
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12} lg={6} sm={6} container spacing={1}>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "45px",
                    backgroundColor: "#c4c4c4",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    props.Seteditadmin(false);
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#3B5092",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Cancel
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={() => {
          Setshow(false);
          //showAlert();
        }}
        centered
      >
        <Modal.Header closeButton>Retrieve Password</Modal.Header>

        <Modal.Body>
          <SoftBox display="flex">
            {/* Password: {showpassword ? copyPassword.value : "**************"} */}
            Password: {showpassword ? displayPassword : "**************"}
            <SoftBox
              ml={25}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                Setshowpassword(true);
                copy(`${copyPassword.value}`);
              }}
            >
              <Icon icon="mdi:content-copy" color="#3b5092" width="20" />
            </SoftBox>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => downloadfile()}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Download
            </SoftTypography>
          </SoftBox>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => {
              Setshow(false);
              showAlert();
            }}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Cancel
            </SoftTypography>
          </SoftBox>
        </Modal.Footer>
      </Modal>
    </>
  );
};

PasswordResetAdmin.propTypes = {
  editadmin: PropTypes.bool.isRequired,
  Seteditadmin: PropTypes.func.isRequired,
  editdata: PropTypes.object.isRequired,
  // h4: PropTypes.string.isRequired,
  // v1: PropTypes.string.isRequired,
  // v2: PropTypes.string.isRequired,
  // v3: PropTypes.string.isRequired,
  // v4: PropTypes.string.isRequired,
};

export default PasswordResetAdmin;
