import { MdMoreHoriz } from 'react-icons/md';
import Button from '@mui/material/Button';
import { Icon } from "@iconify/react";

const columns = [
    {
      title: 'Candidate Name',
    //   width: 100,
      dataIndex: 'candidate_name',
      key: 'candidate_name',
      fixed: 'left',
    //   sorter: true,
    sorter:{ compare: (a,b)=> a.candidate_name-b.candidate_name,},
    },
    {
      title: 'Phone',
    //   width: 100,
      dataIndex: 'phone',
      key: 'phone',
    //   fixed: 'left',
    //   sorter: true,
    },
    { title: 'Qualification', dataIndex: 'qualification', key: '1' },
    // { title: 'Modified On', dataIndex: 'modified_on', key: '2' },
    { title: 'Role', dataIndex: 'role', key: '3' },
    { title: 'Location', dataIndex: 'location', key: '4' },
    { title: 'Last Active', dataIndex: 'last_active', key: '5' },
    { title: 'Score',
        dataIndex: 'score', 
        sorter:{ compare: (a,b)=> a.score-b.score,},
        key: '6',
        
        
    },
    // { title: 'Column 7', dataIndex: 'address', key: '7' },
    // { title: 'Column 8', dataIndex: 'address', key: '8' },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 10,
      // render: () => <MdMoreHoriz/>,
      render: (admindata) => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button             
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-horizontal" color="black" />
            </Button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <div style={{display:"flex", flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                  {/* <div style={{marginLeft:"10px"}}><Icon icon="mdi:lead-pencil" /></div> */}
                  <a
                    className="dropdown-item"
                    href="#EditUser"
                    // onClick={() =>{ Seteditadmin(true); Seteditdata(admindata)}}
                  >
                    Shortlist
                  </a>
                </div>
              </li>
              <li>
                <div style={{display:"flex", flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                  {/* <div style={{marginLeft:"10px"}}><Icon icon="mdi:password-reset" /></div> */}
                  <a
                    className="dropdown-item"
                    href="#EditUser"
                    // onClick={() =>{ setResetPassword(true); Seteditdata(admindata)}}
                  >
                    View
                  </a>
                </div>
                
              </li>
              
            </ul>
          </div>
        </div>
      ),
    },
];
  
// const data = [
    // {
    //   key: '1',
    //   candidate_name: 'John Brown',
    //     phone:"9855664444",
    //     education: "Ambernath School",
    //     modified_on: "19-02-2022",
    //     skills: "Carpenter,welding,Painter",
    //     location: 'Kurla',
    //     last_active:"23-02-2022",
    //     score: "100",
    // },
    // {
    //   key: '2',
    //   candidate_name: 'Adam King',
    //     phone:"9855664444",
    //     education: "Ambernath School",
    //     modified_on: "19-02-2022",
    //     skills: "Carpenter,welding,Painter",
    //     location: 'Kurla',
    //     last_active:"23-02-2022",
    //     score: "10",
    // },
// ];
const data =[];
for(let i=0;i<20;i++){
    data.push({
        key: i+1,
        candidate_name: `John Brown ${i+1}`,
        phone:"9855664444",
        qualification: "Ambernath School",
        // modified_on: "19-02-2022",
        role: "Carpenter,welding,Painter",
        location: 'Kurla',
        last_active:"23-02-2022",
        score: `80`,
    })
}
// for(let i=0;i<10;i++){
//   data.push({
//       key: i+1,
//       candidate_name: `John Brown ${i+1}`,
//       phone:"9855664444",
//       qualification: "Ambernath School",
//       // modified_on: "19-02-2022",
//       role: "Carpenter,welding,Painter",
//       location: 'Kurla',
//       last_active:"23-02-2022",
//       score: `45`,
//   })
// }

export {columns,data}