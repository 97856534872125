import { useMemo } from "react";

import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";

/* eslint-disable react/prop-types */

// Define a default UI for filtering
export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Use preFilteredRows to calculate the options
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return ["All", ...options.values()];
  }, [id, preFilteredRows]);

  // UI for Multi-Select box
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftSelect
        onChange={({ value }) => {
          setFilter(value || undefined);
        }}
        defaultValue={{ value: "", label: "All" }}
        options={options.map((opt) =>
          opt === "All" ? { value: "", label: opt } : { value: opt, label: opt }
        )}
        size="small"
      />
    </SoftBox>
  );
}
