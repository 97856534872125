import { Card, Stack, Tooltip } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import SoftSelect from "../../components/SoftSelect";
import SoftInput from "components/SoftInput";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { width } from "@mui/system";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FadeLoader } from "react-spinners";

const copy = require("clipboard-copy");
const JobFairRegistration = () => {
  const ref = useRef();
  const [resumefile, setResumeFile] = useState("");
  const [autopass, Setautopass] = useState(true);
  const [check, setCheck] = useState(false);
  const [crmOptinYes, setCrmOptinYes] = useState(false);
  const [crmOptinYes1, setCrmOptinYes1] = useState(false);
  const [crmOptinNo, setCrmOptinNo] = useState(false);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });
  const [showScreen, setShowScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [questions, setQuestions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [relId, setRelId] = useState("");
  const [jplink, setJplink] = useState("");
  const [jslink, setJslink] = useState();
  const [jpId, setJpId] = useState("");
  // const [formLink,setFormLink] = useState("");
  const [show, setShow] = useState(false);
  const [showpassword, Setshowpassword] = useState(false);
  const [displayPassword, setDisplayPassword] = useState("");
  const [addressChar, setAddressChar] = useState(250);
  const [create, setCreate] = useState(false);
  const [create1, setCreate1] = useState(false);
  const [create2, setCreate2] = useState(false);
  const [jpLinkOption, setJPLinkOption] = useState([]);
  const [jsLinkOption, setJSLinkOption] = useState([]);
  const [jfList, setJFList] = useState([]);
  const [selectedJF, setSelectedJF] = useState("");
  const RoleOptions = [
    { label: "Requirement Based", value: "Requirement Based" },
    { label: "End to End", value: "End to End" },
  ];
  const DatabaseOptions = [
    { label: "All", value: "All" },
    { label: "Self Database", value: "Self Database" },
    { label: "Samarth’s Database", value: "Samarth’s Database" },
  ];
  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [`Auto Generate Password : ${showpassword ? displayPassword : copyPassword.value}`],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };
  const handleResume = (File) => {
    const validExt = ["jpeg", "png"];
    const fileExt = File.name.split(".")[1];
    return validExt.includes(fileExt);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file);
    if (file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  const sizeOptions = [
    {
      label: "Tiny Unit",
      value: "Tiny Unit",
    },
    {
      label: "Small Unit",
      value: "Small Unit",
    },
    {
      label: "Medium Unit",
      value: "Medium Unit",
    },
    {
      label: "Large Unit",
      value: "Large Unit",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const industryOptions1 = [
    { label: "Textile and Clothing", value: "Textile and Clothing" },
    { label: "Building and Construction", value: "Building and Construction" },
    { label: "Automobile and Transportation", value: "Automobile and Transportation" },
    { label: "Organized and Unorganized Retail", value: "Organized and Unorganized Retail" },
    { label: "Gems and Jewellery", value: "Gems and Jewellery" },
    { label: "IT & Computers", value: "IT & Computers" },
    { label: "Furniture and Furnishing", value: "Furniture and Furnishing" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Leather and Leather Goods", value: "Leather and Leather Goods" },
    { label: "Tourism and Hospitality", value: "Tourism and Hospitality" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Mining", value: "Mining" },
    { label: "Households", value: "Households" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
    { label: "Others", value: "Others" },
  ];

  const sectorOption = [
    {
      label: "Public",
      value: "Public",
    },
    {
      label: "Private",
      value: "Private",
    },
    {
      label: "PSU",
      value: "PSU",
    },
  ];

  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data?.Password });
    });
  };
  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchIndustryFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setIndustryOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLinkJP = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/links`, {
        params: { toggle: "jp" },
      })
      .then((res) => {
        console.log("links jp", res.data);
        // var StateList = res.data[0].sort().map(function (item) {
        //   return { label: item, value: item };
        // });
        setJPLinkOption(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchLinkJS = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/links`, {
        params: { toggle: "js" },
      })
      .then((res) => {
        console.log("State", res.data);
        // var StateList = res.data[0].sort().map(function (item) {
        //   return { label: item, value: item };
        // });
        setJSLinkOption(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchJFList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobfair_list`,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      })
      .then((res) => {
        console.log("State", res.data);
        // var StateList = res.data[0].sort().map(function (item) {
        //   return { label: item, value: item };
        // });
        setJFList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, [autopass]);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
    securityQuestionFunc();
    fetchIndustryFunc();
    fetchLinkJP();
    fetchLinkJS();
    fetchJFList();
  }, []);

  useEffect(() => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);

  const showAlert = () =>
    Swal.fire({
      //position: "top",
      icon: "success",
      title: "Registration Successful",
      //text: "Job Provider registered successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      navigate("/jobprovider-list-page");
    });

  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({
    job_fair: "",
    org_name: "",
    org_state: "",
    org_district: "",
    venue: "",
    locality: "",
    org_date: "",
    org_time: "",
    lastnm: "",
    mobnum: "",
    email: "",
    jslink: "",
    jplink: "",
    rel_id: "",
    banner_img: "",
  });
  const [pageOne, setPageOne] = useState({
    job_fair: "",
    org_name: "",
    org_state: "",
    org_district: "",
    venue: "",
    locality: "",
    org_date: "",
    org_time: "",
    lastnm: "",
    mobnum: "",
    email: "",
    rel_id: "",
    banner_img: "",
  });

  const registrationChannelPartner = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: formDetails.org_name ? formDetails.org_name : "",
      org_state: formDetails.org_state ? formDetails.org_state : "",
      org_district: formDetails.org_district ? formDetails.org_district : "",
      venue: formDetails.venue ? formDetails.venue : "",
      locality: formDetails.locality ? formDetails.locality : "",
      org_date: formDetails.org_date ? formDetails.org_date : "",
      org_time: formDetails.org_time ? formDetails.org_time : "",
      email: formDetails.email ? formDetails.email : "",
      mobnum: formDetails.mobnum ? formDetails.mobnum : "",
      lastnm: formDetails.lastnm ? formDetails.lastnm : "",
      rel_id: formDetails.rel_id ? formDetails.rel_id : null,
      banner: formDetails.banner_img ? formDetails.banner_img : "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please enter job fair name"),
      org_state: Yup.string().required("Please select state"),
      org_district: Yup.string().required("Please select district"),
      locality: Yup.string().required("Please enter locality"),
      venue: Yup.string().required("Please enter venue details"),
      org_date: Yup.string().required("Please select date"),
      org_time: Yup.string().required("Please select time"),

      lastnm: Yup.string().required("Please enter name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
    }),
    onSubmit: async (values) => {
      setPageOne({
        org_name: values.org_name,
        org_state: values.org_state,
        org_district: values.org_district,
        venue: values.venue,
        locality: values.locality,
        org_date: values.org_date,
        org_time: values.org_time,
        lastnm: values.lastnm,
        mobnum: values.mobnum,
        email: values.email,
        rel_id: relId !== "" ? relId : '',
        banner_img: values.banner,
      });

      setJplink("");
      setJslink("");
      setShowScreen(true);
    },
  });

  const registrationJobFair = useFormik({
    enableReinitialize: true,
    initialValues: {
      js_links: jslink ? jslink : "",
      jp_links: jplink ? jplink : "",
    },
    validationSchema: Yup.object().shape({
      js_links: Yup.string().required("Please enter job seeker link"),
      jp_links: Yup.string().required("Please enter job provider link"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const waitingPopup = Swal.fire({
          title: "Please wait...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        setIsLoading(true);
        let formData = new FormData();
        formData.append("name", pageOne.org_name);
        formData.append("venue", pageOne.venue);
        formData.append("locality", pageOne.locality);
        formData.append("state", pageOne.org_state);
        formData.append("district", pageOne.org_district);
        formData.append("date", pageOne.org_date);
        formData.append("time", pageOne.org_time);
        formData.append("spoc_name", pageOne.lastnm);
        formData.append("spoc_mobile", pageOne.mobnum);
        formData.append("spoc_email", pageOne.email);
        formData.append("banner", pageOne.banner_img);
        formData.append("spoc_details", "");
        formData.append("js_link", values.js_links ? values.js_links : jslink);
        formData.append("jp_link", values.jp_links ? values.jp_links : jplink);
        formData.append("is_active", false);
        formData.append(
          "created_by",
          sessionStorage.getItem("register_id") ? sessionStorage.getItem("register_id") : ""
        );
        formData.append("rel_id", pageOne.rel_id);
        //setLoadingModal(true);
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobfair`,formData
          // {
          //   name: pageOne.org_name,
          //   venue: pageOne.venue,
          //   locality: pageOne.locality,
          //   state: pageOne.org_state,
          //   district: pageOne.org_district,
          //   date: pageOne.org_date,
          //   time: pageOne.org_time,
          //   spoc_name: pageOne.lastnm,
          //   spoc_mobile: pageOne.mobnum,
          //   spoc_email: pageOne.email,
          //   spoc_details: "",
          //   js_link: values.js_links ? values.js_links : jslink,
          //   jp_link: values.jp_links ? values.jp_links : jplink,
          //   is_active: false,
          //   created_by: sessionStorage.getItem("register_id")
          //     ? sessionStorage.getItem("register_id")
          //     : "",
          //   rel_id: pageOne.rel_id,
          //   // rel_id: relId,
          // }
        );
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "success",
          title: "Registered successfully",
          //text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        }).then(
          () => (
            setIsLoading(false),
            setLoadingModal(false),
            setCreate(false),
            setCreate1(false),
            setCreate2(false)
          )
        );
        setShowScreen(false);
        resetForm();
        setJpId("");
        setJslink("");
        setJplink("");
        setRelId("");
        setSelectedJF("");
        setPageOne({
          org_name: "",
          org_state: "",
          org_district: "",
          venue: "",
          locality: "",
          org_date: "",
          org_time: "",
          lastnm: "",
          mobnum: "",
          email: "",
          rel_id: "",
          banner_img: "",
                });
        setFormDetails({
          org_name: "",
          org_state: "",
          org_district: "",
          venue: "",
          locality: "",
          org_date: "",
          org_time: "",
          lastnm: "",
          mobnum: "",
          email: "",
          jslink: "",
          jplink: "",
          rel_id: "",
          banner_img: "",
          
        });
      } catch (err) {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  });

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          {showScreen ? (
            <>
              <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
                <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                  Job Fair Registration
                </SoftTypography>
                <Grid container minWidth="70%">
                  <SoftBox mt={4} width="100%" height="auto">
                    <Card minWidth="100%" sx={{ overflow: "visible" }}>
                      <SoftBox pl={4} pr={14} pt={2} pb={3}>
                        <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                          Job Fair Details
                        </SoftTypography>
                        <SoftBox component="form" role="form">
                          <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox
                                    width="100%"
                                    mb={1}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <SoftBox display="flex" flexDirection="row">
                                      <SoftTypography fontWeight="medium" fontSize="16px">
                                        Job Provider Link
                                      </SoftTypography>
                                      <SoftBox
                                        sx={{
                                          color: "red",
                                          position: "inherit",
                                          paddingLeft: "2px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <sup>*</sup>
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      flexDirection="row"
                                      gap="10px"
                                      onClick={() => {
                                        setCreate(!create);
                                      }}
                                    >
                                      <Tooltip
                                        key="2"
                                        title={!create ? `Add new link` : `Select From Existing `}
                                        placement="bottom"
                                      >
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15px"
                                            height="15px"
                                            viewBox="0 0 10 10"
                                            fill="none"
                                          >
                                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                                            <path
                                              d="M5 2V8M2 5H8"
                                              stroke="white"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </SoftBox>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                  {create ? (
                                    <SoftInput
                                      type="text"
                                      placeholder=""
                                      id="jp_links"
                                      name="jp_links"
                                      onChange={(e) => {
                                        registrationJobFair.handleChange;
                                        setJplink(e.target.value);
                                      }}
                                      value={registrationJobFair.values.jp_links}
                                    />
                                  ) : (
                                    <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                      <SoftSelect
                                        // styles={stylesDD}
                                        // isClearable
                                        options={jpLinkOption}
                                        onChange={(e) => {
                                          registrationJobFair.setFieldValue("jp_links", e.label);
                                          setJplink(e.value);
                                          // setSelectedState(e.label);
                                          // setFormDetails({
                                          //   ...formDetails,
                                          //   org_state: e ? e.label : "",
                                          // });
                                        }}
                                        value={{
                                          label: registrationJobFair.values.jp_links,
                                        }}
                                      />
                                    </SoftBox>
                                  )}

                                  <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                    {" "}
                                  </SoftTypography>

                                  {registrationJobFair.touched.jp_links &&
                                  registrationJobFair.errors.jp_links ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationJobFair.errors.jp_links}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox
                                    width="100%"
                                    mb={1}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <SoftBox display="flex" flexDirection="row">
                                      <SoftTypography fontWeight="medium" fontSize="16px">
                                        Job Seeker Link
                                      </SoftTypography>
                                      <SoftBox
                                        sx={{
                                          color: "red",
                                          position: "inherit",
                                          paddingLeft: "2px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <sup>*</sup>
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      flexDirection="row"
                                      gap="10px"
                                      onClick={() => {
                                        setCreate1(!create1);
                                      }}
                                    >
                                      <Tooltip
                                        key="2"
                                        title={!create ? `Add new link` : `Select From Existing `}
                                        placement="bottom"
                                      >
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15px"
                                            height="15px"
                                            viewBox="0 0 10 10"
                                            fill="none"
                                          >
                                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                                            <path
                                              d="M5 2V8M2 5H8"
                                              stroke="white"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </SoftBox>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                  {create1 ? (
                                    <SoftInput
                                      type="text"
                                      placeholder=""
                                      id="js_links"
                                      name="js_links"
                                      onChange={(e) => {
                                        registrationJobFair.handleChange;
                                        setJslink(e.target.value);
                                      }}
                                      value={registrationJobFair.values.js_links}
                                    />
                                  ) : (
                                    <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                      <SoftSelect
                                        // styles={stylesDD}
                                        // isClearable
                                        options={jsLinkOption}
                                        onChange={(e) => {
                                          registrationJobFair.setFieldValue("js_links", e.label);
                                          setJslink(e.value);
                                          // setSelectedState(e.label);
                                          // setFormDetails({
                                          //   ...formDetails,
                                          //   org_state: e ? e.label : "",
                                          // });
                                        }}
                                        value={{
                                          label: registrationJobFair.values.js_links,
                                        }}
                                      />
                                    </SoftBox>
                                  )}

                                  {registrationJobFair.touched.js_links &&
                                  registrationJobFair.errors.js_links ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {registrationJobFair.errors.js_links}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
                <Grid container mt={4} ml={2}>
                  <Grid container spacing={8}>
                    <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                      <SoftBox
                        pl={2}
                        sx={{
                          height: "45px",
                          backgroundColor: "#3B5092",
                          borderRadius: "5px",
                          cursor: isLoading ? "no-drop" : "pointer",
                          opacity: isLoading ? 0.5 : 1,
                        }}
                        p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                        width={{ lg: "225px", sm: "100%", xs: "50%" }}
                        onClick={!isLoading && registrationJobFair.handleSubmit}
                        //onClick={registrationChannelPartner.handleSubmit}
                      >
                        <SoftTypography
                          display="flex"
                          justifyContent="center"
                          sx={{
                            color: "#fff",
                            fontSize: "15px",
                            fontWeight: "medium",
                            lineHeight: "19px",
                          }}
                        >
                          Submit
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                      <SoftBox
                        pl={2}
                        sx={{
                          height: "45px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                        width={{ lg: "225px", sm: "100%", xs: "50%" }}
                        onClick={() => {
                          // navigate("/admin-register");
                          setShowScreen(false);
                        }}
                      >
                        <SoftTypography
                          display="flex"
                          justifyContent="center"
                          sx={{
                            color: "#3B5092",
                            fontSize: "15px",
                            fontWeight: "medium",
                            lineHeight: "19px",
                          }}
                        >
                          Cancel
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </SoftBox>
            </>
          ) : (
            <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
              <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                Job Fair Registration
              </SoftTypography>
              <Grid container minWidth="70%">
                <SoftBox mt={4} width="100%" height="auto">
                  <Card minWidth="100%" sx={{ overflow: "visible" }}>
                    <SoftBox pl={4} pr={14} pt={2} pb={3}>
                      <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                        Job Fair Details
                      </SoftTypography>
                      <SoftBox component="form" role="form">
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox
                                  width="100%"
                                  mb={1}
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                >
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Job Fair Name
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup> *</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="row"
                                    gap="10px"
                                    onClick={() => {
                                      setCreate2(!create2);
                                    }}
                                  >
                                    <Tooltip
                                      key="3"
                                      title={
                                        !create2 ? `Create New Job Fair` : `Select From Existing `
                                      }
                                      placement="bottom"
                                    >
                                      <SoftBox
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15px"
                                          height="15px"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                        >
                                          <rect width="10" height="10" rx="5" fill="#3B5092" />
                                          <path
                                            d="M5 2V8M2 5H8"
                                            stroke="white"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </SoftBox>
                                    </Tooltip>
                                  </SoftBox>
                                </SoftBox>

                                {create2 ? (
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="org_name"
                                    name="org_name"
                                    onChange={(e) => {
                                      registrationChannelPartner.handleChange;
                                      setFormDetails({
                                        ...formDetails,
                                        org_name: e.target.value,
                                      });
                                    }}
                                    value={registrationChannelPartner.values.org_name}
                                  />
                                ) : (
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      // styles={stylesDD}
                                      // isClearable
                                      options={jfList}
                                      onChange={(e) => {
                                        registrationChannelPartner.setFieldValue("rel_id", e.value);
                                        registrationChannelPartner.setFieldValue(
                                          "org_name",
                                          e.label
                                        );
                                        setRelId(e.value);
                                        setSelectedJF(e.label);
                                        setFormDetails({
                                          ...formDetails,
                                          rel_id: e ? e.value : null,
                                          org_name: e ? e.label : "",
                                        });
                                      }}
                                      value={{
                                        label: selectedJF,
                                      }}
                                    />
                                  </SoftBox>
                                )}
                                <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                  {" "}
                                </SoftTypography>

                                {registrationChannelPartner.touched.org_name &&
                                registrationChannelPartner.errors.org_name ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.org_name}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>

                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    State
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftSelect
                                  options={state}
                                  placeholder="Select State"
                                  id="org_state"
                                  name="org_state"
                                  onChange={(e) => {
                                    registrationChannelPartner.setFieldValue("org_state", e.label);
                                    setSelectedState(e.label);
                                    setFormDetails({
                                      ...formDetails,
                                      org_state: e ? e.label : "",
                                    });
                                  }}
                                  value={{ label: registrationChannelPartner.values.org_state }}
                                />

                                <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                  {" "}
                                </SoftTypography>

                                {registrationChannelPartner.touched.org_state &&
                                registrationChannelPartner.errors.org_state ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.org_state}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    District
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftSelect
                                  options={district}
                                  placeholder=""
                                  id="org_district"
                                  name="org_district"
                                  onChange={(e) => {
                                    registrationChannelPartner.setFieldValue(
                                      "org_district",
                                      e.label
                                    );
                                    setFormDetails({
                                      ...formDetails,
                                      org_district: e ? e.label : "",
                                    });
                                  }}
                                  value={{ label: registrationChannelPartner.values.org_district }}
                                />

                                <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                  {" "}
                                </SoftTypography>

                                {registrationChannelPartner.touched.org_district &&
                                registrationChannelPartner.errors.org_district ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.org_district}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>

                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Locality
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="locality"
                                  name="locality"
                                  onChange={(e) => {
                                    registrationChannelPartner.handleChange;
                                    setFormDetails({
                                      ...formDetails,
                                      locality: e.target.value,
                                    });
                                  }}
                                  value={registrationChannelPartner.values.locality}
                                />
                                {registrationChannelPartner.touched.locality &&
                                registrationChannelPartner.errors.locality ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.locality}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>

                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Venue
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="venue"
                                  name="venue"
                                  onChange={(e) => {
                                    registrationChannelPartner.handleChange;
                                    setFormDetails({
                                      ...formDetails,
                                      venue: e.target.value,
                                    });
                                  }}
                                  value={registrationChannelPartner.values.venue}
                                />
                                {registrationChannelPartner.touched.venue &&
                                registrationChannelPartner.errors.venue ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.venue}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>

                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Date
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="date"
                                  placeholder=""
                                  id="org_date"
                                  name="org_date"
                                  onChange={(e) => {
                                    registrationChannelPartner.handleChange;
                                    setFormDetails({
                                      ...formDetails,
                                      org_date: e.target.value,
                                    });
                                  }}
                                  value={registrationChannelPartner.values.org_date}
                                />

                                <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                  {" "}
                                </SoftTypography>

                                {registrationChannelPartner.touched.org_date &&
                                registrationChannelPartner.errors.org_date ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.org_date}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>

                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Time
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="time"
                                  placeholder=""
                                  id="org_time"
                                  name="org_time"
                                  onChange={(e) => {
                                    registrationChannelPartner.handleChange;
                                    setFormDetails({
                                      ...formDetails,
                                      org_time: e.target.value,
                                    });
                                  }}
                                  value={registrationChannelPartner.values.org_time}
                                />

                                <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                  {" "}
                                </SoftTypography>

                                {registrationChannelPartner.touched.org_time &&
                                registrationChannelPartner.errors.org_time ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.org_time}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Upload Banner
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  ></SoftBox>
                                </SoftBox>
                                <input
                                  className="form-control"
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                  id="banner"
                                  name="banner"
                                  onChange={(e) => {
                                    // registrationChannelPartner.setFieldValue(
                                    //   "banner",
                                    //   e.target.files[0]
                                    // );
                                    setFormDetails({
                                      ...formDetails,
                                      banner_img: e.target.files[0],
                                    });
                                  }}
                                />
                                <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                  {" "}
                                </SoftTypography>
                                {registrationChannelPartner.touched.banner &&
                                registrationChannelPartner.errors.banner ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.banner}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox pl={4} pr={14} pt={0} pb={0}>
                      <SoftTypography fontWeight="medium" fontSize="20px" mb={4}>
                        SPOC Details
                      </SoftTypography>
                      <SoftBox
                        component="form"
                        role="form"
                        onSubmit={registrationChannelPartner.handleSubmit}
                      >
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Name
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="lastnm"
                                  name="lastnm"
                                  onChange={(e) => {
                                    registrationChannelPartner.handleChange;
                                    setFormDetails({
                                      ...formDetails,
                                      lastnm: e.target.value,
                                    });
                                  }}
                                  value={registrationChannelPartner.values.lastnm}
                                />
                                {registrationChannelPartner.touched.lastnm &&
                                registrationChannelPartner.errors.lastnm ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.lastnm}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>

                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Mobile Number
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="tel"
                                  placeholder=""
                                  inputProps={{
                                    maxLength: "10",
                                  }}
                                  id="mobnum"
                                  name="mobnum"
                                  onChange={(e) => {
                                    registrationChannelPartner.handleChange;
                                    setFormDetails({
                                      ...formDetails,
                                      mobnum: e.target.value,
                                    });
                                  }}
                                  value={registrationChannelPartner.values.mobnum}
                                />
                                {registrationChannelPartner.touched.mobnum &&
                                registrationChannelPartner.errors.mobnum ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.mobnum}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          // display="flex"
                          // flexDirection="row"
                          // justifyContent="space-between"
                          mb={3}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Email
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <sup>*</sup>
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="email"
                                  placeholder=""
                                  id="email"
                                  name="email"
                                  onChange={(e) => {
                                    registrationChannelPartner.handleChange;
                                    setFormDetails({
                                      ...formDetails,
                                      email: e.target.value,
                                    });
                                  }}
                                  value={registrationChannelPartner.values.email}
                                />
                                {registrationChannelPartner.touched.email &&
                                registrationChannelPartner.errors.email ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.email}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
              </Grid>
              <Grid container mt={4} ml={2}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                    <SoftBox
                      pl={2}
                      sx={{
                        height: "45px",
                        backgroundColor: "#3B5092",
                        borderRadius: "5px",
                        cursor: isLoading ? "no-drop" : "pointer",
                        opacity: isLoading ? 0.5 : 1,
                      }}
                      p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                      width={{ lg: "225px", sm: "100%", xs: "50%" }}
                      onClick={registrationChannelPartner.handleSubmit}
                      //onClick={registrationChannelPartner.handleSubmit}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        Next
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                    <SoftBox
                      pl={2}
                      sx={{
                        height: "45px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                      width={{ lg: "225px", sm: "100%", xs: "50%" }}
                      onClick={() => {
                        navigate("/admin-register");
                        setFormDetails({
                          org_name: "",
                          org_state: "",
                          org_district: "",
                          venue: "",
                          locality: "",
                          org_date: "",
                          org_time: "",
                          lastnm: "",
                          mobnum: "",
                          email: "",
                        });
                      }}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#3B5092",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
                        Cancel
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </SoftBox>
          )}
        </SoftBox>
      </DashboardLayout>
      {/* Modal After Submit */}

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          showAlert();
        }}
        centered
      >
        <Modal.Header closeButton>Retrieve Password</Modal.Header>

        <Modal.Body>
          <SoftBox display="flex">
            {/* Password: {showpassword ? copyPassword.value : "**************"} */}
            Password: {showpassword ? displayPassword : "**************"}
            <SoftBox
              ml={25}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                Setshowpassword(true);
                copy(`${copyPassword.value}`);
              }}
            >
              <Icon icon="mdi:content-copy" color="#3b5092" width="20" />
            </SoftBox>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => downloadfile()}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Download
            </SoftTypography>
          </SoftBox>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => {
              setShow(false);
              showAlert();
            }}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Cancel
            </SoftTypography>
          </SoftBox>
        </Modal.Footer>
      </Modal>
      {/* Modal After Submit */}

      {/* loading modal */}
      <Modal
        show={loadingModal}
        onHide={() => {
          setLoadingModal(false);
        }}
        centered
        size="md"
        closeButton
        contentClassName="modal-height"
      >
        <Modal.Body>
          <SoftBox display="flex" justifyContent="center" alignItems="center">
            <SoftBox display="flex" flexDirection="column" gap={3}>
              <SoftTypography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Please wait as we are registering your details
              </SoftTypography>
              <SoftTypography
                sx={{
                  // fontSize: "14px",
                  // fontWeight: 600,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Uploading..... */}
                <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Modal.Body>
      </Modal>
      {/* loading modal */}
    </>
  );
};

export default JobFairRegistration;
