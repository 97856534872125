/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import { createStore, combineReducers } from "redux";
import translation from "./utils/translation";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
const defaultLocale = "en";
const reducers = combineReducers(Object.assign({}, { Intl }));
const store1 = createStore(reducers, { Intl: { locale: "en" } });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store1}>
    <IntlProvider translations={translation} locale="en">
      <BrowserRouter>
        <SoftUIControllerProvider>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </SoftUIControllerProvider>
      </BrowserRouter>
    </IntlProvider>
  </Provider>
);
