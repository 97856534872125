import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Card } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import { height } from "@mui/system";
import SoftSelect from "components/SoftSelect";
// import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { borderColor } from "@mui/system";
import Range from "@atlaskit/range";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
// import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Select, { StylesConfig } from "react-select";
import styles from "components/SoftSelect/styles";
import Tooltip from "@mui/material/Tooltip";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import "../JobPostings/style.css";
import { Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editPost.css";

const CreateCourse = () => {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [allstate, Setallstate] = useState(false);
  const [value, setValue] = useState(150000);
  const [education, Seteducation] = useState();
  const [stream, Setstream] = useState();
  const [degree, Setdergee] = useState();
  const [addressChar, setAddressChar] = useState(200);
  const [exp, Setexp] = useState();
  const [jobrole, Setjobrole] = useState();
  const [orgList, setOrgList] = useState([]);
  const [create, setCreate] = useState(false);
  const [jobTitle, setJobtitle] = useState([]);
  const [orgNameID, setOrgNameID] = useState("");
  const [newddEducation, setNewDDEducation] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [jobDes, setJobDes] = useState("");
  const [age, setAge] = useState([18, 45]);
  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(64);
  const [any, setAny] = useState(false);
  const resDATA = sessionStorage.getItem("register_id");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [whatsappjd, setWhatsappjd] = useState("");

  const jobType = [
    { label: "One Month", value: "One Month" },
    { label: "Four Months", value: "Four Months" },
    { label: "Six Months", value: "Six Months" },
  ];

  const salaryList = [
    { label: "On site", value: "On site" },
    { label: "Online", value: "Online" },
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleTexteditorChange = (value) => {
    setJobDes(value);
  };

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  // dd style
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // dd style

  const ngrokLink = "https://72e2-2401-4900-6288-532f-29d1-73fb-262e-d817.ngrok-free.app";
  const reporting = [
    {
      label: "Relience digital",
      value: "Relience digital",
    },
    {
      label: "Tata Counsultant serviecs",
      value: "Tata Counsultant serviecs",
    },
    {
      label: "Uber",
      value: "Uber",
    },
    {
      label: "Swiggy",
      value: "Swiggy",
    },
  ];

  const wfm = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
    {
      label: "Hybrid",
      value: "Hybrid",
    },
  ];

  const jtype = [
    {
      label: "Full",
      value: "Full",
    },
    {
      label: "Part",
      value: "Part",
    },
    {
      label: "Intership",
      value: "Intership",
    },
  ];
  const genderOption = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "All",
      value: "All",
    },
  ];

  const [ageOption, setAgeOption] = useState([
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 28 },
    { label: 29, value: 29 },
    { label: 30, value: 30 },
    { label: 31, value: 31 },
    { label: 32, value: 32 },
    { label: 33, value: 33 },
    { label: 34, value: 34 },
    { label: 35, value: 35 },
    { label: 36, value: 36 },
    { label: 37, value: 37 },
    { label: 38, value: 38 },
    { label: 39, value: 39 },
    { label: 40, value: 40 },
    { label: 41, value: 41 },
    { label: 42, value: 42 },
    { label: 43, value: 43 },
    { label: 44, value: 44 },
    { label: 45, value: 45 },
    { label: 46, value: 46 },
    { label: 47, value: 47 },
    { label: 48, value: 48 },
    { label: 49, value: 49 },
    { label: 50, value: 50 },
    { label: 51, value: 51 },
    { label: 52, value: 52 },
    { label: 53, value: 53 },
    { label: 54, value: 54 },
    { label: 55, value: 55 },
    { label: 56, value: 56 },
    { label: 57, value: 57 },
    { label: 58, value: 58 },
    { label: 59, value: 59 },
    { label: 60, value: 60 },
    { label: 61, value: 61 },
    { label: 62, value: 62 },
    { label: 63, value: 63 },
    { label: 64, value: 64 },
  ]);

  const [maxAgeOption, setMaxAgeOption] = useState([]);

  const setOptions = (value) =>{
    const array = []
    for (let i = value; i <= 65; i++) {
      array.push({label:i, value:i});
    }
    setMaxAgeOption(array)
  }


  const jobpostformone = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      jobpostdt: "",
      jobvaliddt: "",
      start_date: "",
      fee: "",
      application: "",
      qualification: "",
      salary: "",
      state: "",
      district: "",
      address: "",
      taluka: "",
      createdby: resDATA,
      creator_role: "SA",
      spoc_name: "",
      spoc_email: "",
      spoc_phone: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select organization name"),
      job_title: Yup.string().required("Please enter course name"),
      job_type: Yup.string().required("Please enter number of days"),
      //jobpostdt: Yup.string().required("Please select course post date"),
      jobvaliddt: Yup.string().required("Please select course valid till date"),
      start_date: Yup.string().required("Please select course start date"),
      qualification: Yup.string().required("Please select highest education"),
      salary: Yup.string().required("Please select mode of course"),
      state: Yup.string().required("Please select state"),
      district: Yup.string().required("Please select district"),
      taluka: Yup.string().required("Please enter taluka"),
      address: Yup.string().required("Please enter address"),
      fee: Yup.string().required("Please enter fee"),
      spoc_name: Yup.string().required("Please enter name"),
      spoc_email: Yup.string().required("Please enter email"),
      spoc_phone: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      application: Yup.mixed()
        // .required("Upload brochure")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        let formData = new FormData();
        formData.append("orgid", orgNameID);
        formData.append("name", values.job_title);
        formData.append("duration", values.job_type);
        formData.append("date_posting", moment(new Date()).format("YYYY-MM-DD"));
        formData.append("valid_till", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
        formData.append("highest_qualification", values.qualification);
        formData.append("mode_of_course", values.salary);
        formData.append("state", values.state);
        formData.append("district", values.district);
        formData.append("address", values.address);
        formData.append("taluka", values.taluka);
        formData.append("description", jobDes);
        // formData.append("age_lower_range", age ? age[0] : [18]);
        // formData.append("age_upper_range", age ? age[1] : [45]);
        formData.append("age_lower_range", minAge);
        formData.append("age_upper_range", maxAge);
        formData.append("createdby", values.createdby);
        formData.append("brochure", values.application);
        formData.append("start_date", values.start_date);
        formData.append("fees", values.fee);
        formData.append("spoc_name", values.spoc_name);
        formData.append("spoc_email", values.spoc_email);
        formData.append("spoc_mobile", values.spoc_phone);
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_register`,
          formData
        );
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "success",
          text: "Course has been created successfully",
          showDenyButton: true,
          confirmButtonText: "Add More",
          denyButtonText: `Search Candidate`,
        }).then((result) => {
          if (result.isConfirmed) {
            jobpostformone.resetForm();
            window.location.reload();
            setJobDes("");
            setOrgNameID("");
            setSelectedState("");
          } else if (result.isDenied) {
            sessionStorage.setItem("job_id", res.data["JobID"]);
            console.log(res.data["JobID"]);
            sessionStorage.setItem("searchdata_org_name", values.org_name);
            sessionStorage.setItem("searchdata_org_id", orgNameID);
            sessionStorage.setItem("searchdata_state", values.state);
            sessionStorage.setItem("searchdata_district", values.district);
            jobpostformone.resetForm();
            setJobDes("");
            setOrgNameID("");
            setSelectedState("");
          } else {
            navigate("/searchbyposting/allcourse");
          }
        });
      } catch (err) {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  });

  const jobpostform = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      designation: "",
      applicantct: "",
      salary: "",
      jobpostdt: "",
      jobvaliddt: "",
      state: "",
      district: "",
      industry: "",
      qualification: "",
      stream: "",
      specialization: "",
      workfromhome: "",
      //jobdesc: "",
      createdby: resDATA,
      creator_role: "SA",
      headerimg: "",
      Experiance: "",
      age: "",
      gender: "",
      whatsapp_jd: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select organization name"),
      job_title: Yup.string().required("Please enter job title"),
      // job_type: Yup.string().required(" Please enter Job Type"),
      // designation: Yup.string().required(" Please Select Designation"),
      // applicantct: Yup.string().required(" Please Select Applicanctct"),
      // salary: Yup.string().required(" Please Select Salary"),
      jobpostdt: Yup.string().required("Please select job post date"),
      jobvaliddt: Yup.string().required("Please select job post valid till date"),
      state: Yup.string().required("Please select state"),
      whatsapp_jd: Yup.string().required("Please fill above field"),
      // district: Yup.string().required(" Please Select District"),
      //industry: Yup.string().required(" Please Select Industry"),
      // qualification: Yup.string().required(" Please Select Qualifaction"),
      // stream: Yup.string().required(" Please Select Stream"),
      // specialization: Yup.string().required(" Please Select Specialization"),
      // workfromhome: Yup.string().required(" Please Select "),
      // jobdesc: Yup.string().required(" Please Enter Job Description "),
      // Experiance: Yup.string().required(" Please Select Experiance"),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      const job = new FormData();
      job.append("org_name", values.org_name);
      job.append("org_id", orgNameID);
      job.append("job_title", values.job_title);
      job.append("job_type", values.job_type);
      job.append("designation", values.designation);
      job.append("applicantct", values.applicantct);
      job.append("salary", values.salary);
      job.append("jobpostdt", values.jobpostdt);
      job.append("jobvaliddt", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      job.append("state", !allstate ? values.state : "");
      job.append("district", !allstate ? values.district : "");
      job.append("qualification", values.qualification);
      job.append("stream", values.stream);
      job.append("specialization", values.specialization);
      job.append("workfromhome", values.workfromhome);
      job.append("jobdesc", jobDes);
      job.append("createdby", values.createdby);
      job.append("creator_role", values.creator_role);
      job.append("experience", values.Experiance);
      //job.append("age", `${age[0]} - ${age[1]}`);
      job.append("age", age);
      job.append("gender", values.gender);
      job.append("whatsapp_jd", values.whatsapp_jd);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
          // `${ngrokLink}/superadmin/candidatesearch/jobpost`,
          job
        )
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            //setJobDes("")
            Swal.fire({
              icon: "success",
              text: "Job posting has been created successfully",
              showDenyButton: true,
              // showCancelButton: true,
              confirmButtonText: "Add More",
              denyButtonText: `Search Candidate`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Swal.fire("Saved!", "", "success");

                jobpostform.resetForm();
                setOrgNameID("");
                setSelectedState();
                // location.reload();
                // jobpostform.setFieldValue("org_name", "");
                // window.reload();
                // navigate("/searchbyposting/createposting");
                // console.log("hello");
              } else if (result.isDenied) {
                // Swal.fire("Changes are not saved", "", "info");
                sessionStorage.setItem("job_id", res.data["JobID"]);
                console.log(res.data["JobID"]);
                sessionStorage.setItem("searchdata_org_name", values.org_name);
                sessionStorage.setItem("searchdata_org_id", orgNameID);
                // sessionStorage.setItem("searchdata_designation", values.designation);
                // sessionStorage.setItem("searchdata_salary", values.salary);
                sessionStorage.setItem("searchdata_state", values.state);
                sessionStorage.setItem("searchdata_district", values.district);
                // sessionStorage.setItem("searchdata_industry", values.industry);
                // sessionStorage.setItem("searchdata_qualification", values.qualification);
                // sessionStorage.setItem("searchdata_experience", values.Experiance);
                // sessionStorage.setItem("searchdata_stream", values.stream);
                // sessionStorage.setItem("searchdata_specialization", values.specialization);
                navigate("/searchbyposting/createposting/recommendedPostList");
              }
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Oops...",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  });

  const TermSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#52af77",
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });

  const handleAgeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < 10) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 65 - 10);
        console.log("age slider:", [clamped, clamped + 10]);
        setAge([clamped, clamped + 10]);
      } else {
        const clamped = Math.max(newValue[1], 10);
        setAge(clamped >= 28 ? [clamped - 10, clamped] : [18, 28]);
      }
    } else {
      setAge(newValue);
    }
  };

  useEffect(() => {
    orgNameID !== "" &&
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_names`, {
          params: {
            orgid: orgNameID,
          },
        })
        .then((res) => {
          setJobtitle(res?.data);
        });
  }, [orgNameID]);

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
        setNewDDEducation(res.data.map((ele) => ele.value));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        Setstream(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOrgList = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/skill_institute`,{
          params:{
            created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
          }
        }
        // headers
      )
      .then((res) => {
        setOrgList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchStateList();
    fetchjobrole();
    fetchexp();
    fetchdegree();
    fetchstream();
    fetcheducation();
    fetchOrgList();
  }, []);

  useEffect(() => {
    fetchDistricts();
    // setDistrict([]);
  }, [selectedState]);

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox>
          <SoftTypography
            fontStyle="normal"
            fontWeight="medium"
            fontSize="24px"
            lineHeight="30px"
            pb={3}
          >
            Create Course
          </SoftTypography>

          <SoftBox sx={{ maxWidth: "900px", p: 2, background: "#FFFFFF", borderRadius: "12px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mb={1.5}
                  >
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                        Skilling Institute
                      </SoftTypography>
                      <SoftBox
                        sx={{
                          color: "red",
                          position: "inherit",
                          paddingLeft: "2px",
                          fontSize: "15px",
                        }}
                      >
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      gap="10px"
                      onClick={() => {
                        sessionStorage.setItem("skilling", "Skilling Institute");
                        navigate("/jobprovider-registration");
                      }}
                    >
                      <Tooltip key="1" title="Add a new skilling institute" placement="bottom">
                        <SoftBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15px"
                            height="15px"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                            <path
                              d="M5 2V8M2 5H8"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </Tooltip>
                      {/* <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="13px"
                                  lineHeight="17px"
                                >
                                  Add a new organisation
                                </SoftTypography> */}
                    </SoftBox>
                  </SoftBox>
                  {/* <SoftInput type="text" placeholder="" /> */}
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      placeholder="Select skilling institute"
                      options={orgList}
                      // value={orgList.filter((ele) => ele.value === orgNameID)}
                      onChange={(e) => (
                        jobpostformone.setFieldValue("org_name", e ? e.label : ""),
                        setOrgNameID(e ? e.value : "")
                      )}
                    />
                  </SoftBox>
                  {jobpostformone.touched.org_name && jobpostformone.errors.org_name ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.org_name}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox
                    width="100%"
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <SoftBox display="flex" flexDirection="row">
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Course Name
                      </SoftTypography>
                      <SoftBox
                        sx={{
                          color: "red",
                          position: "inherit",
                          paddingLeft: "2px",
                          fontSize: "15px",
                        }}
                      >
                        <sup>*</sup>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      gap="10px"
                      onClick={() => {
                        setCreate(!create);
                      }}
                    >
                      <Tooltip
                        key="2"
                        title={!create ? `Create New Job Title` : `Select From Existing `}
                        placement="bottom"
                      >
                        <SoftBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15px"
                            height="15px"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                            <path
                              d="M5 2V8M2 5H8"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                  {create ? (
                    <SoftInput
                      type="text"
                      placeholder="Job title"
                      name="job_title"
                      id="job_title"
                      onChange={jobpostformone.handleChange}
                      value={jobpostformone.values.job_title}
                    />
                  ) : (
                    <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                      <Select
                        styles={stylesDD}
                        isClearable
                        placeholder="Please enter course name"
                        options={jobTitle}
                        onChange={
                          (e) => jobpostformone.setFieldValue("job_title", e ? e.label : "")
                          // setOrgNameID(e.value)
                        }
                      />
                    </SoftBox>
                  )}

                  {jobpostformone.touched.job_title && jobpostformone.errors.job_title ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.job_title}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Course Duration
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <SoftInput
                      type="number"
                      placeholder="Enter number of days"
                      name="job_type"
                      id="job_type"
                      // min={0}
                      onChange={jobpostformone.handleChange}
                      value={jobpostformone.values.job_type}
                    />
                  </SoftBox>
                  {jobpostformone.touched.job_type && jobpostformone.errors.job_type ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.job_type}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Valid Till
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    type="datetime-local"
                    placeholder=""
                    name="jobvaliddt"
                    id="jobvaliddt"
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.jobvaliddt}
                  />
                  {jobpostformone.touched.jobvaliddt && jobpostformone.errors.jobvaliddt ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.jobvaliddt}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              {/* <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Date of Posting
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    type="date"
                    placeholder=""
                    name="jobpostdt"
                    id="jobpostdt"
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.jobpostdt}
                  />
                  {jobpostformone.touched.jobpostdt && jobpostformone.errors.jobpostdt ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.jobpostdt}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid> */}

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Course Start Date
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    type="date"
                    placeholder=""
                    name="start_date"
                    id="start_date"
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.start_date}
                  />
                  {jobpostformone.touched.start_date && jobpostformone.errors.start_date ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.start_date}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Course Fee
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    {/* <span>&#x20B9;</span> */}
                    <SoftInput
                      type="number"
                      placeholder="&#x20B9; Enter course fee"
                      name="fee"
                      id="fee"
                      onChange={jobpostformone.handleChange}
                      value={jobpostformone.values.fee}
                    />
                  </SoftBox>

                  {jobpostformone.touched.fee && jobpostformone.errors.fee ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.fee}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Highest Qualification
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      isMulti
                      options={education}
                      // onChange={(e) =>
                      //   jobpostform.setFieldValue("qualification", e ? e.value : "")
                      // }
                      onChange={(e) => (
                        // !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                        console.log("multi education:", e.map((ele) => ele.value).join(",")),
                        jobpostformone.setFieldValue(
                          "qualification",
                          e.map((ele) => ele.value).join(",")
                        )
                      )}
                    />
                  </SoftBox>
                  {jobpostformone.touched.qualification && jobpostformone.errors.qualification ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.qualification}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Mode of Course
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      options={salaryList}
                      placeholder="Select mode of course"
                      onChange={(e) => {
                        jobpostformone.setFieldValue("salary", e ? e.label : "");
                      }}
                    />
                  </SoftBox>
                  {jobpostformone.touched.salary && jobpostformone.errors.salary ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.salary}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      State
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      options={state}
                      onChange={(currentTarget) => (
                        // setSelectedState(currentTarget.value),
                        // !allstate
                        //   ? jobpostform.setFieldValue("state", currentTarget.value)
                        //   : ""
                        setSelectedState(currentTarget ? currentTarget.value : ""),
                        !allstate
                          ? jobpostformone.setFieldValue(
                              "state",
                              currentTarget ? currentTarget.value : ""
                            )
                          : ""
                      )}
                    />
                  </SoftBox>
                  {jobpostformone.touched.state && jobpostformone.errors.state ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.state}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      District
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <Select
                      styles={stylesDD}
                      isClearable
                      isMulti
                      options={district}
                      onChange={(e) => (
                        // !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                        console.log("multi district:", e.map((ele) => ele.value).join(",")),
                        !allstate
                          ? jobpostformone.setFieldValue(
                              "district",
                              e.map((ele) => ele.value).join(",")
                            )
                          : ""
                      )}
                    />
                  </SoftBox>
                  {jobpostformone.touched.district && jobpostformone.errors.district ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.district}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Taluka
                    </SoftTypography>
                    {/* <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox> */}
                  </SoftBox>

                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <SoftInput
                      type="text"
                      placeholder="Enter taluka"
                      name="taluka"
                      id="taluka"
                      onChange={jobpostformone.handleChange}
                      value={jobpostformone.values.taluka}
                    />
                  </SoftBox>

                  {/* {jobpostformone.touched.taluka && jobpostformone.errors.taluka ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.taluka}{" "}
                    </span>
                  ) : null} */}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Address
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                    <SoftInput
                      type="text"
                      placeholder="Enter address"
                      name="address"
                      id="address"
                      onChange={jobpostformone.handleChange}
                      value={jobpostformone.values.address}
                    />
                  </SoftBox>

                  {jobpostformone.touched.address && jobpostformone.errors.address ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.address}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Upload Brochure
                    </SoftTypography>
                    {/* <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox> */}
                  </SoftBox>

                  <input
                    className="form-control"
                    type="file"
                    accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                    id="application"
                    name="application"
                    onChange={(e) => {
                      jobpostformone.setFieldValue("application", e.target.files[0]);
                    }}
                  />

                  {jobpostformone.touched.application && jobpostformone.errors.application ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.application}{" "}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Age
                      {/* : {`${age[0]} - ${age[1]}`} */}
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        background: "#fff",
                        borderRadius: "0.5rem",
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Select
                        placeholder="Min Age"
                        isDisabled={any}
                        styles={stylesDD}
                        isClearable
                        options={ageOption}
                        onChange={
                          (currentTarget) =>{
                            // setSelectedState(currentTarget.value),
                            // !allstate
                            //   ? jobpostform.setFieldValue("state", currentTarget.value)
                            //   : ""
                            setMinAge(currentTarget ? currentTarget.value : "");
                            currentTarget && setOptions(currentTarget.value);
                            !currentTarget && setMaxAgeOption([])
                          // !allstate
                          //   ? jobpostformone.setFieldValue(
                          //       "state",
                          //       currentTarget ? currentTarget.value : ""
                          //     )
                          //   : ""
                        }}
                      />
                      <Select
                        placeholder="Max Age"
                        isDisabled={any}
                        styles={stylesDD}
                        isClearable
                        options={maxAgeOption}
                        onChange={
                          (currentTarget) =>
                            // setSelectedState(currentTarget.value),
                            // !allstate
                            //   ? jobpostform.setFieldValue("state", currentTarget.value)
                            //   : ""
                            setMaxAge(currentTarget ? currentTarget.value : "")
                          // !allstate
                          //   ? jobpostformone.setFieldValue(
                          //       "state",
                          //       currentTarget ? currentTarget.value : ""
                          //     )
                          //   : ""
                        }
                      />
                      <SoftBox
                        sx={{
                          border: !any ? "1px solid #e4e4e4" : "1px solid #87c4ff",
                          borderRadius: "8px",
                          fontSize: "16px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100px",
                          cursor: "pointer",
                          backgroundColor: !any ? "none" : "#87c4ff",
                          color: !any ? "none" : "#fff",
                        }}
                        onClick={() => (setMinAge(18), setMaxAge(64), setAny(!any))}
                      >
                        Any
                      </SoftBox>
                    </SoftBox>
                    {/* <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox> */}
                  </SoftBox>

                  {/* <ThemeProvider theme={TermSlider}>
                    <Slider
                      getAriaLabel={() => "Minimum distance shift"}
                      valueLabelDisplay="auto"
                      min={18}
                      max={65}
                      value={age}
                      disableSwap
                      onChange={handleAgeChange}
                    />
                  </ThemeProvider> */}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={12}>
                <SoftBox width="100%" height="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                      Course Description
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>

                  <Box sx={{ background: "#FFFFFF" }}>
                    <ReactQuill
                      // theme="snow"
                      // className="text-box"
                      value={jobDes || ""}
                      defaultValue=""
                      modules={modules}
                      formats={formats}
                      onChange={handleTexteditorChange}
                    />
                  </Box>
                </SoftBox>
              </Grid>
            </Grid>

            <SoftTypography fontWeight="medium" fontSize="20px" mb={1} mt={3}>
              SPOC Details
            </SoftTypography>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px">
                      Name
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="Enter name"
                    id="spoc_name"
                    name="spoc_name"
                    inputProps={{
                      maxLength: "90",
                    }}
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.spoc_name}
                  />
                  {jobpostformone.touched.spoc_name && jobpostformone.errors.spoc_name ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.spoc_name}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%">
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px">
                      Mobile Number
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    type="tel"
                    placeholder="Enter mobile number"
                    inputProps={{
                      maxLength: "10",
                    }}
                    id="spoc_phone"
                    name="spoc_phone"
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.spoc_phone}
                  />
                  {jobpostformone.touched.spoc_phone && jobpostformone.errors.spoc_phone ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.spoc_phone}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>

              <Grid item xs={12} lg={6}>
                <SoftBox width="100%" mb={2}>
                  <SoftBox display="flex" flexDirection="row">
                    <SoftTypography fontWeight="medium" fontSize="16px">
                      Email
                    </SoftTypography>
                    <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    type="email"
                    placeholder="Enter email"
                    id="spoc_email"
                    name="spoc_email"
                    onChange={jobpostformone.handleChange}
                    value={jobpostformone.values.spoc_email}
                  />
                  {jobpostformone.touched.spoc_email && jobpostformone.errors.spoc_email ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.spoc_email}
                    </span>
                  ) : null}
                </SoftBox>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} lg={12}></Grid> */}
          </SoftBox>
          <Box sx={{ display: "flex", justifyContent: "flex-start", marginTop: "8px" }}>
            <SoftBox
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "45px",
                backgroundColor: "#3B5092",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              width={{ lg: "225px", sm: "100px", xs: "80px" }}
              onClick={jobpostformone.handleSubmit}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: "medium",
                  lineHeight: "19px",
                }}
              >
                Submit
              </SoftTypography>
            </SoftBox>

            <SoftBox
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "45px",
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              width={{ lg: "225px", sm: "100px", xs: "80px" }}
              onClick={() => navigate("/searchbyposting/allcourse")}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{
                  color: "#3B5092",
                  fontSize: "15px",
                  fontWeight: "medium",
                  lineHeight: "19px",
                }}
              >
                Cancel
              </SoftTypography>
            </SoftBox>
          </Box>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default CreateCourse;
