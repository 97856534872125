const translation = {
  en: {
    locale: 'en-US',
    messages: {
      hello: 'Hello! How are you?',
      login:'Login',
      email:'Email Address',
      password:'Password',
      forgotpass:'Forgot Password',
      register: 'Register',
      admin1:'Admin',
      jobProvider:'Job Provider',
      counsellor:'counsellor',
      channelpartner:'Channel Partner',
      jobfair:'Job Fair',
      jobposting:'Job Postings',
      trendingjob:'Trending Job',
      allposting:'All Postings',
      totalposting:'Total Postings',
      totalcandidates:'Total Candidates',
      totalplacedcandidates:'Total Placed Candidates',
      Totalmatch:'Total Candidates Matched',
      candidates:'Candidates',
      totalregister:'Total Registered',
      totalconnect:'Total Connected',
      totalverify:'Total Verified',
      totalmatch:'Total Matched',
      totalplaced:'Total Placed',
      totalinpipeline:'Total in Hiring Pipeline',
      reset:'Reset',
      applyfilter:'Apply Filter',
      loadcandidate:'Select the option to load candidates',
      selectpref:'Select Preference for Candidates',
      thiswillhelp:'This Will help you find the best suitable candidates according to your requirement.',
      search:'Search',
      clear:'Clear',
      shortlist:'Shortlist',
      totalJP:'Total Providers Registered',
      totalJobs:'Total Jobs Created',
      totalseekers:'Total Jobs Seekers Added',
      totalseekershortlisted:'Total Jobs Seekers Shortlisted',
      seekerhired:'Total Jobs Seekers Hired',
      seekerpipeline:'Total Jobs Seekers in Pipeline',
      requested:'Requested',
      onboarded:'Onboarded',
      skilling:'Skilling',
      allcourses:'All Courses',
      totalskiling:'Total Skilling Partners',
      totalcourses:'Total Courses',
      matchcandidates:'Match Candidates',
      candidatesplaced:'Candidates Placed',
      jobfair:'Job Fair',
      alljobfair:'All Job Fair',
      companylist:'Company List',
      candidatelist:'Candidate List',
      totaljf:'Total Job Fair Conducted',
      ongoing:'Ongoing Job Fairs',
      completedjf:'Completed Job Fairs',
      upcomingjf:'Upcoming Job Fairs',
      companireg:'Company Registered',
      candidatereg:'Candidate Registered',
      onhold:'On Hold',
      rejected:'Rejected',
      shortlisted:'shortlisted',
      all:'All',
      allcandidate:'All Candidates',
      totalcompany:'Total Companies',
      attendcompny:'Attended Companies',
      admin:'Admin', 
      totaladmin:'Total Admin Registered',
      totaljobcreate:'Total Job Created',
      totalcandidatesplaced:'Total Candidates Hired',
      holdcandidate:'Total Candidates On Hold',
      counsellor:'Counsellor',
      totalcounsellor:'Total Counsellor Registered',
      totalcall:'Total Calls',
      selectedcand:'Total Candidates Selected',
      cp:'Channel Partner',
      tcp:'Total Channel Partner Registered',
      tajp:'Total Job Post Accepted',
      djp:'Total Job Post Decline',
      tjpr:'Total Job Post Requested',
      industry:'Industry',
      action:'Action',
      allmatch:'All Matched Candidates',
      submit:'Submit',
      cancel:'Cancel',
      view:'View',
      notification:'Notifications',
      clearall:'Clear All',
      viewmore:'View More',
    }
  },
  hi: {
    locale: 'hi',
    messages: {
      hello: 'hindi' ,
      login:'लॉग इन',
      email:'ई-मेल',
      password:'पासवर्ड',
      forgotpass:'पासवर्ड भूल गए',
      register: 'पंजीकरण',
      admin1:'ऍडमिन',
      jobProvider:'नौकरी प्रदाता',
      counsellor:'काउंसलर',
      channelpartner:'चैनल पार्टनर',
      jobfair:'नौकरी मेला',
      jobposting:'नौकरी की पोस्टिंग',
      trendingjob:'ट्रेंडिंग जॉब',
      allposting:'सभी पोस्टिंग',
      totalposting:'कुल पोस्टिंग',
      totalcandidates:'कुल उम्मीदवार',
      totalplacedcandidates:'कुल पदस्थापित उम्मीदवार',
      Totalmatch:'कुल उम्मीदवारों का मिलान',
      candidates:'उम्मीदवार',
      totalregister:'कुल पंजीकृत',
      totalconnect:'कुल जुड़ाव',
      totalverify:'कुल सत्यापित',
      totalmatch:'कुल मिलान',
      totalplaced:'कुल पदस्थापित उम्मीदवार',
      totalinpipeline:'हायरिंग पाइपलाइन में कुल',
      reset:'रीसेट',
      applyfilter:'फिल्टर करें',
      loadcandidate:'उम्मीदवारों को लोड करने के विकल्प का चयन करें',
      selectpref:'उम्मीदवारों के लिए प्राथमिकता चुनें',
      thiswillhelp:'इससे आपको अपनी आवश्यकता के अनुसार सर्वोत्तम उपयुक्त उम्मीदवार ढूंढने में मदद मिलेगी।',
      shortlist:'शॉर्टलिस्ट',
      search:'खोज करें',
      clear:'क्लिअर',
      totalJP:'पंजीकृत कुल प्रदाता',
      totalJobs:'कुल नौकरियाँ सृजित',
      totalseekers:'कुल नौकरी चाहने वाले',
      totalseekershortlisted:'कुल नौकरी चाहने वाले शॉर्टलिस्ट',
      seekerhired:'कुल नौकरी चाहने वाले नियुक्त',
      seekerpipeline:'नौकरी चाहने वालों की कुल संख्या पाइपलाइन में',
      requested:'प्रार्थित',
      onboarded:'ऑनबोर्डइड',
      skilling:'स्किलिंग',
      allcourses:'सभी कोर्सेस ',
      totalskiling:'कुल स्किलिंग पार्टनर',
      totalcourses:'कुल कोर्सेस',
      matchcandidates:'मिलान किये गये उम्मीदवार',
      candidatesplaced:'अभ्यर्थियों को नौकरी मिल गयी',
      jobfair:'नौकरी मेला',
      alljobfair:'सर्व रोजगार मेला',
      companylist:'कंपनी सूची',
      candidatelist:'उम्मीदवार सूची',
      totaljf:'कुल रोजगार मेला आयोजित',
      ongoing:'चालू रोजगार मेला',
      completedjf:'रोजगार मेले सम्पन्न',
      upcomingjf:'आगामी रोजगार मेले',
      companireg:'कंपनियाँ पंजीकृत',
      candidatereg:'उम्मीदवार पंजीकृत',
      onhold:'होल्ड पर',
      rejected:'रद्द',
      shortlisted:'शॉर्टलिस्टेड',
      all:'समस्त',
      allcandidate:'सभी उम्मीदवार',
       totalcompany:'कुल कंपनी',
      attendcompny:'कंपनियों में भाग लिया',
      admin:'व्यवस्थापक', 
      totaladmin:'कुल व्यवस्थापक पंजीकृत',
      totaljobcreate:'कुल रोजगार सृजित',
      totalcandidatesplaced:'कुल नौकरी मिल गयी',
      holdcandidate:'कुल उम्मीदवार होल्ड पर',
      counsellor:'काउंसलर',
      totalcounsellor:'कुल पंजीकृत काउंसलर',
      totalcall:'कुल कॉल',
      selectedcand:'कुल चयनित उम्मीदवार',
      cp:'चैनल पार्टनर',
      tcp:'कुल चैनल पार्टनर पंजीकृत',
      tajp:'कुल स्वीकृत नौकरी पोस्ट',
      djp:'कुल जॉब पोस्ट अस्वीकार',
      tjpr:'कुल अनुरोधित नौकरी पोस्ट',
      industry:'व्यवसाय',
      action:'क्रिया',
       allmatch:'सभी मिलान वाले उम्मीदवार',
       view:'देखना',
       submit:'पूरा करें',
       cancel:'रद्द करें',
       notification:'सूचनाएं',
       clearall:'क्लिअर करें ',
       viewmore:'और देखें',














      


      













      













    }
  }
}

export default translation