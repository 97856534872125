import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
// import Select from "react-select";
import { Icon } from "@iconify/react";
import "../style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import MapCoOrdinates from "pages/MapCoordinates/MapCoOrdinates";
import { SiGooglemaps } from "react-icons/si";

const Centerreg = () => {
  const [registerID, setRegisterID] = useState("SA1");
  const [selectedState, setSelectedState] = useState("");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [formLink, setFormLink] = useState("");
  const [roleId, setRoleId] = useState("SA");
  const [showMap, setShowMap] = useState(false);
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: "",
  });
  const [location, setLocation] = useState({ latitude: "", longitude: "" });

  function handleLocationChange(newLocation) {
    setLocation(newLocation);
  }
  const [latitude, setLatitude] = useState("25.00");
  const [longitude, setLongitude] = useState("72.00");
  // const [coordinates,setCoordinates] = useState({})

  const handleParentCallback = () => {
    // setLongitude()
    // setCoordinates({
    //     ladtitude: obj.lat,
    //     longitude: obj.longi
    // })
    // setCoordinates({...coordinates,latitude:obj.latitude,longitude:obj.longitude});
  };
  const navigate = useNavigate();
  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, [registerID, roleId]);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
    // securityQuestionFunc();
  }, []);

  useEffect(() => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/generic/centername`, {
        partner_association: "samarth",
        center_district: "Mumbai",
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/generic/centerlocation`, {
        partner_association: "samarth",
        // "center_district":"Mumbai"
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  useEffect(() => {
    // axios.post(`${process.env.REACT_APP_BACKEND_URL}/generic/formlink`,{
    //     "partner_association": "samarth",
    //     "access_role": roleId,
    //     // "access_role": "SA",
    //     "role_id": registerID
    // })
    // .then((res)=>{
    //     console.log("fomLink",res.data["Form Link"]);
    //     setFormLink(res.data["Form Link"]);
    //     // setFormLink("www.google.com");
    //     console.log(formLink);
    // }).catch((err)=>{
    //     console.log(err.data);
    // })
    const formLinkFunc = () => {
      axios
        .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/formlink`, {
          // "partner_association": "samarth",
          // "access_role": roleId,
          // // "access_role": "SA",
          // "role_id": registerID
          partner_association: "samarth",
          form_type: "job fair",
          access_role: roleId,
          role_id: registerID,
        })
        .then((res) => {
          console.log("fomLink", res.data["Form Link"]);
          setFormLink(res.data["Form Link"]);
        })
        .catch((err) => {
          console.log(err.data);
        });
    };
    formLinkFunc();
  }, []);

  useEffect(() => {
    setLatitude(location.latitude);
    setLongitude(location.longitude);
  }, [location]);

  const centerform = useFormik({
    initialValues: {
      createdby: "SA1",
      creator_role: "SA",
      center_name: "",
      center_address: "",
      center_state: "",
      center_taluka: "",
      center_district: "",
      center_pincode: "",
      center_location: `${latitude + " " + longitude}`,
      form_link: formLink,
    },
    validationSchema: Yup.object().shape({
      center_name: Yup.string().required("Please enter center name"),
      center_address: Yup.string().required("Please enter address"),
      center_state: Yup.string().required("Please enter state name"),
      center_district: Yup.string().required("Please enter district name"),
      center_taluka: Yup.string().required("Please enter taluka name"),
      center_pincode: Yup.string()
        .required("Please enter pincode")
        .matches(/^[0-9]+$/, "Must be only digits"),
      center_location: Yup.string().required("Please enter location name"),
      // form_link: Yup.string().required("Please enter form link "),
    }),
    onSubmit: (values) => {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL_REGISTER}/jobcenter/register`, {
          ...values,
          role_id: "JC",
          // creator_role:"SA",
          creator_role: roleId,
          createdby: registerID,
          assign_to_owner: "samarth",
        })
        .then((res) => {
          console.log("profile Post api called");
          if (res.status === 201) {
            Swal.fire({
              icon: "success",
              title: res.data["Status"],
              text: res.data["Developer Message"],
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              navigate("/centers-list-page");
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // alert('Error message',err);
          Swal.fire({
            icon: "error",
            title: "",
            // text: "User registered successfully",
            text: err["message"],
            showConfirmButton: false,
            timer: 3000,
          });
          // .then(() => {
          //   navigate("/superadmin/dashborad");
          // });
        });
    },
  });

  const showAlert = () =>
    Swal.fire({
      //position: "top",
      icon: "success",
      title: "Registration Successful",
      text: "Job Center registered successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      window.location = "/centers-list-page";
    });
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              New Job Center Registration
            </SoftTypography>

            <Grid container>
              <SoftBox mt={2} width="100% !important" height="auto">
                <Card sx={{ overflow: "visible", width: { lg: "927px", md: "100%" } }}>
                  <SoftBox mt={4} width="100% " height="auto">
                    <SoftBox pl={2} pr={4} pt={2} pb={3} mb={4}>
                      {/* <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                        Organization Details
                      </SoftTypography> */}
                      <SoftBox
                        p={1}
                        mb={1}
                        textAlign="baseline"
                        fontWeight="medium"
                        fontSize="16px"
                        // mb={4.5}
                        display="flex"
                        flexDirection="row"
                      >
                        <SoftTypography variant="h5" fontWeight="medium">
                          Organization Details
                        </SoftTypography>
                        <SoftBox display="flex" flexDirection="row" mt={1} ml={1}>
                          <SoftBox
                            sx={{ color: "red", position: "inherit", top: "0", fontSize: "14px" }}
                          >
                            <sup>*</sup>
                          </SoftBox>
                          <SoftTypography fontSize="12px" fontStyle="italic">
                            all fields are mandatory
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox component="form" role="form">
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="15px">
                                  Name of the Center
                                </SoftTypography>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="center_name"
                                  name="center_name"
                                  onChange={centerform.handleChange}
                                  value={centerform.values.center_name}
                                />
                                {centerform.touched.center_name && centerform.errors.center_name ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerform.errors.center_name}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="15px">
                                  Address
                                </SoftTypography>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="center_address"
                                  name="center_address"
                                  onChange={centerform.handleChange}
                                  value={centerform.values.center_address}
                                />
                                {centerform.touched.center_address &&
                                centerform.errors.center_address ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerform.errors.center_address}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="15px">
                                  State
                                </SoftTypography>
                                <SoftSelect
                                  placeholder=""
                                  id="center_state"
                                  name="center_state"
                                  // onChange={centerform.handleChange}
                                  // value={centerform.values.center_state}
                                  options={state}
                                  onChange={(e) => {
                                    centerform.setFieldValue("center_state", e.label);
                                    setSelectedState(e.label);
                                  }}
                                  value={{ label: centerform.values.center_state }}
                                />
                                {centerform.touched.center_state &&
                                centerform.errors.center_state ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerform.errors.center_state}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="15px">
                                  District
                                </SoftTypography>
                                <SoftSelect
                                  placeholder=""
                                  id="center_district"
                                  name="center_district"
                                  options={district}
                                  onChange={(e) => {
                                    centerform.setFieldValue("center_district", e.label);
                                  }}
                                  value={{ label: centerform.values.center_district }}
                                />
                                {centerform.touched.center_district &&
                                centerform.errors.center_district ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerform.errors.center_district}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          {" "}
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="15px">
                                  Taluka
                                </SoftTypography>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="center_taluka"
                                  name="center_taluka"
                                  onChange={centerform.handleChange}
                                  value={centerform.values.center_taluka}
                                />
                                {centerform.touched.center_taluka &&
                                centerform.errors.center_taluka ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerform.errors.center_taluka}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="15px">
                                  PINCODE
                                </SoftTypography>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="center_pincode"
                                  name="center_pincode"
                                  onChange={centerform.handleChange}
                                  value={centerform.values.center_pincode}
                                />
                                {centerform.touched.center_pincode &&
                                centerform.errors.center_pincode ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerform.errors.center_pincode}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                            {/* <Grid item xs={12} lg={6} container spacing={3} >
                              <SoftBox width="100%" pl={2} >
                                <SoftTypography fontWeight="medium" fontSize="15px">
                                  center_location me
                                </SoftTypography>
                                <SoftInput type="text" placeholder=""  id="center_location"
                                  name="center_location"
                                  onChange={centerform.handleChange}
                                  value={centerform.values.center_location} />
                                {centerform.touched.center_location && centerform.errors.center_location ?
                                  (<span style={{ color: "red", fontSize: "12px" }}>{centerform.errors.center_location}</span>) : null
                                }
                              </SoftBox>
                            </Grid> */}
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          {" "}
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row" gap="30px">
                                  <SoftTypography fontWeight="medium" fontSize="15px">
                                    Center Location
                                  </SoftTypography>
                                  <SoftBox
                                    variant="button"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    // data-toggle="modal" data-target="#exampleModal"
                                    onClick={() => {
                                      setShowMap(!showMap);
                                      console.log("clicked", showMap);
                                    }}
                                  >
                                    {/* Click */}
                                    <SiGooglemaps />
                                  </SoftBox>
                                </SoftBox>
                                <SoftInput
                                  type="text"
                                  placeholder=""
                                  id="center_location"
                                  name="center_location"
                                  onChange={centerform.handleChange}
                                  value={centerform.values.center_location}
                                />
                                {centerform.touched.center_location &&
                                centerform.errors.center_location ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerform.errors.center_location}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mb={3}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={12} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftTypography fontWeight="medium" fontSize="15px">
                                  Form Link
                                </SoftTypography>

                                <SoftBox
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  className="wrapper"
                                >
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    pr={5}
                                    id="form_link"
                                    name="form_link"
                                    onChange={centerform.handleChange}
                                    value={centerform.values.form_link}
                                  />
                                  <Icon icon="mdi:lead-pencil" inline={true} className="btn-edit" />
                                </SoftBox>

                                {centerform.touched.form_link && centerform.errors.form_link ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {centerform.errors.form_link}
                                  </span>
                                ) : null}
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={centerform.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Submit
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{ height: "45px", backgroundColor: "#FFFFFF", borderRadius: "5px" }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => navigate("/super-admin-register")}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
        {/* Map Modal */}
        {/* <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                ...
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>         */}
        <Modal
          show={showMap}
          onHide={() => {
            setShowMap(false);
          }}
          centered
          size="lg"
          tabIndex="-2"
        >
          <Modal.Header closeButton>Select Coordinates</Modal.Header>

          <Modal.Body>
            {/* <MapCoOrdinates /> */}
            {/* <MapCoOrdinates parentState={coordinates} parentCallback={handleParentCallback}/> */}
            <MapCoOrdinates location={location} handleLocationChange={handleLocationChange} />
            {
              console.log({ ...location })
              // setLatitude(location.latitude),
              // setLongitude(location.longitude),
            }
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        {/* Map Model */}
      </DashboardLayout>
    </>
  );
};
export default Centerreg;
