import { Card, Stack, Tooltip, useMediaQuery } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";
// import centertable from "./centertable";
import SoftSelect from "../../components/SoftSelect";
import SoftBadge from "components/SoftBadge";
import { Link, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import download from "assets/icons/download_icon.png";
import Mapdata from "pages/Mapdata/Mapdata";
import SoftInput from "components/SoftInput";
// import { Table } from "ant-table-extensions";
// importing data for table temporirly from superadmin table.js
// import { data } from "../Suparadmin/suparadmintable";
import { Table, Dropdown } from "antd";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import { MdMoreVert } from "react-icons/md";
import AdminEdit from "pages/EditModals/Centers/centerEdit";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment/moment";
import { Modal } from "react-bootstrap";
import ReactSwitch from "react-switch";
import Switch from "@mui/material/Switch";
import Select from "react-select";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../style.css";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import refreshBtn from "../../assets/images/refresh_btn.png";
import { ThemeProvider } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { createMuiTheme } from "@material-ui/core/styles";
import helpimg from "../../assets/images/info.png";
import { DatePicker } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import JobseekerSkillEdit from "./JobseekerSkillEdit";
const { RangePicker } = DatePicker;
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual'
import { useDispatch } from 'react-redux'

const JobseekerSkill = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [jobTitle, setJobtitle] = useState([]);
  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(64);
  const [any, setAny] = useState(false);
  const [jobDes, setJobDes] = useState("");
  const [orgNameID, setOrgNameID] = useState("");
  const [allstate, Setallstate] = useState(false);
  const [filtershow, setFiltershow] = useState(true);
  const [registerID, setRegisterID] = useState(sessionStorage.getItem("register_id"));
  const [roleId, setRoleId] = useState("SA");
  const [tablerow, setTablerow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const matchsm = useMediaQuery("(max-width:768.5px)");
  const [modalA1, setModalA1] = useState(false);
  const [modalA2, setModalA2] = useState(false);
  const [dataLength, setDataLength] = useState("");
  const [edit, setEdit] = useState(false)
  const [editProfile, setEditProfile] = useState([]);
  // const [editdata, setEditdata] = useState({});

  const [categoryOptions, setCategoryOptions] = useState([
    { label: "Channel Partner", value: "CP" },
    { label: "Job Provider", value: "JP" },
    { label: "Job Fair", value: "JF" },
    { label: "Admin", value: "ADM" },
    { label: "ALL", value: "ALL" },
  ]);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [pageSize, setPageSize] = useState(10);
  const [dataID, setDataID] = useState("");
  const [create, setCreate] = useState(false);
  const [selectedState, setSelectedState] = useState("");

  //Filter fields states
  const [filteroff, setFilteroff] = useState(false);
  const [pincodefilter, setPincodefilter] = useState("");
  const [talukafilter, setTalukafilter] = useState("");
  const [selectedFilterDistrict, setSelectedFilterDistrict] = useState("");
  const [multiDistrict, setMultiDistrict] = useState([]);
  const [district, setDistrict] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [selectedFilterState, setSelectedFilterState] = useState("");
  const [languagesList, setLanguagesList] = useState([]);
  const [language, setLanguage] = useState("");
  const [multiLanguage, setMultiLanguage] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([
    { label: "Married", value: "Married" },
    { label: "Unmarried", value: "Unmarried" },
    { label: "Divorced", value: "Divorced" },
  ]);
  const [married, setMarried] = useState("");
  const [multiMarried, setMultimarried] = useState([]);
  const [age, setAge] = useState([18, 45]);
  const [useAge, setUseAge] = useState(false);
  const [multigender, setMultigender] = useState([]);
  const [gendertype, setGenderType] = useState("");
  const [applySkills, setApplySkills] = useState([]);
  const [applySkill, setApplySkill] = useState("");
  const [multiapplySkills, setMultiApplySkills] = useState([]);
  const [skills, setSkills] = useState("");
  const [multiSkill, setMultiSkill] = useState([]);
  const [skillsOption, SetSkillsOption] = useState([]);
  const [selfOpportunity, setSelfOpportunity] = useState("");
  const [selfOpportunityList, setSelfOpportunityList] = useState([
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]);
  const [desgination, setDesignation] = useState("");
  const [jobrole, Setjobrole] = useState([]);
  const [multiRole, setMultiRole] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [multiIndustry, setMultiIndustry] = useState([]);
  const [industry, setIndustry] = useState("");
  const [salaryList, setSalaryList] = useState([]);
  const [salary, setSalary] = useState("");
  const [multiSalary, setMultiSalary] = useState([]);
  const [stream, setStream] = useState("");
  const [multiStream, setMultiStream] = useState([]);
  const [streamdata, SetstreamData] = useState([]);
  const [qualification, setQualification] = useState("");
  const [multiEducation, setMultiEducation] = useState([]);
  const [education, Seteducation] = useState([]);
  const [registerDate, setRegisterDate] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [checked, setChecked] = useState(false);
  const [excludeKeyword, setExcludeKeyword] = useState(false);

  const [admindata, Setadmindata] = useState([]);
  const [editadmin, Seteditadmin] = useState(false);
  const [editdata, Seteditdata] = useState({});

  const handleChangeKeyword = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);

  /* shortliste modal states*/
  const [showShortlistOption, setShowShortlistOption] = useState(false);
  const [showshortlistModal1, setShowShortlistModal1] = useState(false);
  const [showShortlistModal2, setShowShortlistModal2] = useState(false);
  const [showShortlistModal3, setShowShortlistModal3] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [jobId, setJobId] = useState("");
  const [orgId1, setOrgId1] = useState("");
  const [jobId1, setJobId1] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [shortlistObj, setShortlistObj] = useState({});
  const [showText, setShowText] = useState("");
  const [candIds, setCandIds] = useState([]);
  const handleCloseShortlistModal1 = () => {
    setShowShortlistModal1(false);
    setShowShortlistOption(false);
  };
  const handleCloseShortlistModal2 = () => {
    setShowShortlistModal2(false);
    setShowShortlistOption(false);
    setShowShortlistModal1(false);
    setShowShortlistOption(false);
    setRoleList([]);
    setOrgId("");
    setOrgId1("");
    // setShowShortlistModal1(true);
  };
  const handleCloseShortlistModal3 = () => {
    setShowShortlistModal3(false);
    setShowShortlistOption(false);
    setJobDes("");
    setOrgNameID("");
    setSelectedState("");
  };

  const resDATA = sessionStorage.getItem("register_id");

  const Modes = [
    { label: "On site", value: "On site" },
    { label: "Online", value: "Online" },
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleTexteditorChange = (value) => {
    setJobDes(value);
  };

  function onCalendarChange(dates) {
    setRegisterDate(
      dates?.map((v) => moment(new Date(v)).format("YYYY-MM-DD")),
      "onCalendarChange"
    );
  }

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const AllShortListFunc = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name1: "",
      job_role1: "",
    },
    validationSchema: Yup.object().shape({
      org_name1: Yup.string().required("Please select Organization name"),
      job_role1: Yup.string().required("Please select Job role"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_shortlist`, {
          objid: dataID,
          course_id: [jobId1],
          shortlistedby:
            sessionStorage.getItem("register_id") === ""
              ? ""
              : sessionStorage.getItem("register_id"),
        })
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);
          if (res.status === 504 || res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Candidate Shortlisted Successfully",
              html:
                "Total Count: " +
                `${res?.data?.total}` +
                "<br/>" +
                "Existing Count: " +
                `${res?.data?.skipped}`,
              showConfirmButton: true,
            }).then(() => {
              setLoadingModal(false);
              setModalA2(false);
              setDataLength("");
              AllShortListFunc.resetForm();
              setOrgId1("");
              setJobId1("");
            });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close();
          }, 0);
          setShowText("");
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Something went wrong!",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            setLoadingModal(false);
            setModalA2(false);
          });
        });
    },
  });

  const DownloadAll = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/downloadSkillingJS`,
        {
          objid: dataID,
          email: sessionStorage.getItem("email") === "" ? "" : sessionStorage.getItem("email"),
        }
      );
      const data = await res.data;
      Swal.fire({
        text: "File will be mailed shortly to registered email Id (ETA 2 Minutes)",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };


  const Download = async () => {
    setShow(!show);
    getFileName();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/downloadSkillingJS`,
        {
          candids: candIds?.length !== 0 ? candIds : showText.key?.split(" "),
          email: sessionStorage.getItem("email") === "" ? "" : sessionStorage.getItem("email"),
        }
      );
      const data = await res.data;
      Swal.fire({
        text: "File will be mailed shortly to registered email Id (ETA 2 Minutes)",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const AllShortListFunc9 = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_shortlist`,
        {
          objid: dataID,
          course_id: [jobId1],
          shortlistedby:
            sessionStorage.getItem("register_id") === ""
              ? ""
              : sessionStorage.getItem("register_id"),
        }
      );
      setTimeout(() => {
        waitingPopup.close();
      }, 0);

      if (res.status === 504 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Candidate Shortlisted Successfully",
          html:
            "Total Count: " +
            `${res?.data?.total}` +
            "<br/>" +
            "Existing Count: " +
            `${res?.data?.skipped}`,
          showConfirmButton: true,
        }).then(() => {
          setLoadingModal(false);
          setModalA2(false);
          setDataLength("");
          setOrgId1("");
          setJobId1("");
        });
      }
    } catch (err) {
      setTimeout(() => {
        waitingPopup.close();
      }, 0);
      setShowText("");
      Swal.fire({
        icon: "error",
        title: err.response.data["Developer Message"]
          ? err.response.data["Developer Message"]
          : "Something went wrong!",
        text: err.response.data["User Message"],
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        setLoadingModal(false);
        setModalA2(false);
      });
    }
  };

  //Filter DDs
  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
        setFilterState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedFilterState || selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
        setFilterDistrict(res.data);
        // setSelectedFilterDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchlang = async () => {
    try {
      const res = await axios.get(
        "https://samarth.prod.api.sapioglobal.com/jobseeker/account/language"
      );
      const data = await res.data;
      setLanguagesList(
        data?.map((v) => {
          return {
            value: v,
            label: v,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSkills = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/skills`)
      .then((res) => {
        SetSkillsOption(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        SetstreamData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
        setNewDDEducation(res.data.map((ele) => ele.value));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchIndustry = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry`)
      .then((res) => {
        setIndustryList(res?.data)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchStateList();
    fetchlang();
    fetchSkills();
    fetchjobrole();
    fetchSalary();
    fetchstream();
    fetcheducation();
    fetchIndustry();
    //fetchadminList();
    // fetchCounsellorsKPIs();
    fetchOrgList();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedFilterState, selectedState]);

  const ageOption = [
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 28 },
    { label: 29, value: 29 },
    { label: 30, value: 30 },
    { label: 31, value: 31 },
    { label: 32, value: 32 },
    { label: 33, value: 33 },
    { label: 34, value: 34 },
    { label: 35, value: 35 },
    { label: 36, value: 36 },
    { label: 37, value: 37 },
    { label: 38, value: 38 },
    { label: 39, value: 39 },
    { label: 40, value: 40 },
    { label: 41, value: 41 },
    { label: 42, value: 42 },
    { label: 43, value: 43 },
    { label: 44, value: 44 },
    { label: 45, value: 45 },
    { label: 46, value: 46 },
    { label: 47, value: 47 },
    { label: 48, value: 48 },
    { label: 49, value: 49 },
    { label: 50, value: 50 },
    { label: 51, value: 51 },
    { label: 52, value: 52 },
    { label: 53, value: 53 },
    { label: 54, value: 54 },
    { label: 55, value: 55 },
    { label: 56, value: 56 },
    { label: 57, value: 57 },
    { label: 58, value: 58 },
    { label: 59, value: 59 },
    { label: 60, value: 60 },
    { label: 61, value: 61 },
    { label: 62, value: 62 },
    { label: 63, value: 63 },
    { label: 64, value: 64 },
  ];

  const [maxAgeOption, setMaxAgeOption] = useState([]);

  const setOptions = (value) =>{
    const array = []
    for (let i = value; i <= 65; i++) {
      array.push({label:i, value:i});
    }
    setMaxAgeOption(array)
  }

  const jobpostformone = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      jobpostdt: "",
      jobvaliddt: "",
      start_date: "",
      fee: "",
      application: "",
      qualification: "",
      salary: "",
      state: "",
      district: "",
      address: "",
      taluka: "",
      createdby: resDATA,
      creator_role: "SA",
      spoc_name: "",
      spoc_email: "",
      spoc_phone: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select organization name"),
      job_title: Yup.string().required("Please enter course name"),
      job_type: Yup.string().required("Please enter number of days"),
      //jobpostdt: Yup.string().required("Please select course post date"),
      jobvaliddt: Yup.string().required("Please select course valid till date"),
      start_date: Yup.string().required("Please select course start date"),
      qualification: Yup.string().required("Please select highest education"),
      salary: Yup.string().required("Please select mode of course"),
      state: Yup.string().required("Please select state"),
      district: Yup.string().required("Please select district"),
      taluka: Yup.string().required("Please enter taluka"),
      address: Yup.string().required("Please enter address"),
      fee: Yup.string().required("Please enter fee"),
      spoc_name: Yup.string().required("Please enter name"),
      spoc_email: Yup.string().required("Please enter email"),
      spoc_phone: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      application: Yup.mixed()
        .required("Upload brochure")
        .test(
          "FILE_FORMAT",
          "Invalid format",
          (value) =>
            !value ||
            (value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
              ].includes(value?.type))
        )
        .test(
          "FILE_SIZE",
          "Please upload file < 5mb",
          (value) => !value || (value && value?.size / 1024 < 5120)
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        let formData = new FormData();
        formData.append("orgid", orgNameID);
        formData.append("name", values.job_title);
        formData.append("duration", values.job_type);
        formData.append("date_posting", moment(new Date()).format("YYYY-MM-DD"));
        formData.append("valid_till", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
        formData.append("highest_qualification", values.qualification);
        formData.append("mode_of_course", values.salary);
        formData.append("state", values.state);
        formData.append("district", values.district);
        formData.append("address", values.address);
        formData.append("taluka", values.taluka);
        formData.append("description", jobDes);
        // formData.append("age_lower_range", age ? age[0] : [18]);
        // formData.append("age_upper_range", age ? age[1] : [45]);
        formData.append("age_lower_range", minAge);
        formData.append("age_upper_range", maxAge);
        formData.append("createdby", values.createdby);
        formData.append("brochure", values.application);
        formData.append("start_date", values.start_date);
        formData.append("fees", values.fee);
        formData.append("spoc_name", values.spoc_name);
        formData.append("spoc_email", values.spoc_email);
        formData.append("spoc_mobile", values.spoc_phone);
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_register`,
          formData
        );
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "success",
          title: "Course has been created successfully",
          showConfirmButton: false,
          timer: 3000,
        }).then((result) => {
          handleCloseShortlistModal3();
          jobpostformone.resetForm();
          setJobDes("");
          setOrgNameID("");
          setSelectedState("");
        });
      } catch (err) {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "error",
          title: err?.response?.data
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  });

  const selectBucket = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_role: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select Organization name"),
      job_role: Yup.string().required("Please select Job role"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      const waitingPopup = Swal.fire({
        title: "Please wait as we are uploading the file",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      //setLoadingModal(true);
      axios
        .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_shortlist`, {
          // shortlistedid: showText === "" ? shortlistObj : { [showText.key]: showText.score },
          // candidate_ids: candIds?.length !== 0 ? candIds : showText.key?.split(" "),
          // course_id: jobId,
          // orgid: orgId,
          // shortlistedby: sessionStorage.getItem("register_id"),
          candidate_ids: candIds?.length !== 0 ? candIds : /*showText.key?.split(" ")*/[showText],
          course_id: [jobId],
          // orgid: orgId,
          shortlistedby: sessionStorage.getItem("register_id"),
        })
        .then((res) => {
          handleCloseShortlistModal2();
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          Swal.fire({
            icon: "success",
            // text: res.data["User Message"],
            title: "Candidate Shortlisted Successfully",
            html:
              "Total Count: " +
              `${res?.data?.total}` +
              "<br/>" +
              "Existing Count: " +
              `${res?.data?.skipped}`,
            showConfirmButton: true,
          }).then(() => {
            // navigate("/searchbyposting/createposting/recommendedPostList");
            setShowShortlistModal1(false);
            setShowShortlistModal2(false);
            setShowText("");
            setLoadingModal(false);
            selectBucket.resetForm();
            setJobId("");
            setOrgId("");
          });
          // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          setShowText("");
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Oops...",
            // text: res.data["User Message"],
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => setLoadingModal(false));
        });
    },
  });
  /* shortliste modal states*/
  const handleChange = (val) => {
    setChecked(val);
  };

  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };

  const filterOffsetFunc = () => {
    setPaginationState({ ...paginationState, page: 1 });
  };

  const handleChange1 = (event) => {
    const value = Math.max(
      0,
      Math.min(
        paginationState.dataSize < 3000 ? paginationState.dataSize : 3000,
        Number(event.target.value)
      )
    );
    setDataLength(value);
  };

  console.log(pageSize, "hhh");

  const genderOption = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const TermSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#52af77",
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });

  const handleAgeChange = (event, newValue, activeThumb) => {
    // setAge(newValue === "" ? 26 : newValue);
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < 10) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 65 - 10);
        console.log("age slider:", [clamped, clamped + 10]);
        setAge([clamped, clamped + 10]);
      } else {
        const clamped = Math.max(newValue[1], 10);
        console.log("age slider:", [clamped - 10, clamped]);
        setAge(clamped >= 28 ? [clamped - 10, clamped] : [18, 28]);
      }
    } else {
      console.log("age slider:", newValue);
      setAge(newValue);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <a style={{ display: "flex" }} className="dropdown-item" href="#EditUser">
          <div>
            <Icon icon="mdi:lead-pencil" />
          </div>
          &nbsp; Edit Profile
        </a>
      ),
    },
 
    {
      key: "4",
      label: (
        <a style={{ display: "flex" }} className="dropdown-item" href="#shortlist">
          <div>
            <Icon icon="gridicons:multiple-users" />
          </div>
          &nbsp; Shorlist
        </a>
      ),
    },
  ];

  const resetFunc = () => {
    setPincodefilter("");
    setTalukafilter("");
    setSelectedFilterDistrict("");
    setMultiDistrict([]);
    setSelectedFilterState("");
    setLanguage("");
    setMultiLanguage("");
    setMarried("");
    setMultimarried("");
    setAge([18, 45]);
    setUseAge(false);
    setMultigender([]);
    setGenderType("");
    setApplySkill("");
    setMultiApplySkills([]);
    setSkills("");
    setMultiSkill([]);
    setSelfOpportunity("");
    setDesignation("");
    setMultiRole([]);
    setMultiIndustry([]);
    setIndustry("");
    setSalary("");
    setMultiSalary([]);
    setStream("");
    setMultiStream([]);
    setQualification("");
    setMultiEducation([]);
    setKeywordSearch("");
    setChecked(false);
    setExcludeKeyword(false);
    setFilteroff(true);
    setRegisterDate([]);
  };

  const setFunc = () => {
    setFilteroff(false);
  };

  useEffect(() => {
    filteroff && fetchFilteredCandidates();
  }, [filteroff]);

  const colName = [
    {
      title: "Date",
      //dataIndex: "taluka",
      key: "createddt",
      width: 150,
      fixed: "left",
      sorter: {
        compare: (a, b) => moment(a.createddt, "DD-MM-YYYY") - moment(b.createddt, "DD-MM-YYYY"),
        multiple: 1,
      },
      render: (text) => <>{text.createddt === "" ? "-" : text.createddt}</>,
    },
    {
      title: "Registration ID",
      dataIndex: "key",
      width: 200,
      fixed: !matchsm ? "left" : "none",
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
        multiple: 1,
      },
    },
    {
      title: "Candidate Name",
      dataIndex: "candidate",
      width: 200,
      fixed: !matchsm ? "left" : "none",
      sorter: {
        compare: (a, b) => a?.candidate?.localeCompare(b?.candidate),
        multiple: 1,
      },
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      width: 150,
      sorter: {
        compare: (a, b) => a.mobile - b.mobile,
        multiple: 2,
      },
    },
    {
      title: "Email",
      //dataIndex: "taluka",
      key: "email",
      width: 250,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 7,
      },
      render: (text) => <>{text.email === "" ? "-" : text.email}</>,
    },
    {
      title: "Education",
      dataIndex: "highest_education",
      width: 200,
      sorter: {
        compare: (a, b) => a?.highest_education?.localeCompare(b?.highest_education),
        multiple: 3,
      },
    },
    {
      title: "Stream",
      key: "stream",
      width: 200,
      sorter: {
        compare: (a, b) => a?.stream?.localeCompare(b?.stream),
        multiple: 3,
      },
      render: (text) => <>{text.stream === "" ? "-" : text.stream}</>,
    },
    {
      title: "Experience",
      key: "experience",
      width: 150,
      sorter: {
        compare: (a, b) => a.experience - b.experience,
        multiple: 2,
      },
      render: (text) => <>{text.experience === "" ? "-" : text.experience}</>,
    },
    {
      title: "Current Salary",
      // dataIndex: "current_income",
      key: "current_salary",
      width: 150,
      sorter: {
        compare: (a, b) => a.current_salary - b.current_salary,
        multiple: 2,
      },
      render: (text) => <>{text.current_salary === "" ? "-" : text.current_salary}</>,
    },
    {
      title: "Current Industry",
      // dataIndex: "current_industry",
      key: "current_industry",
      width: 300,
      sorter: {
        compare: (a, b) => a?.current_industry?.localeCompare(b?.current_industry),
        multiple: 3,
      },
      render: (text) => <>{text.current_industry === "" ? "-" : text.current_industry}</>,
    },
    {
      title: "Role",
      //dataIndex: "job_role",
      width: 200,
      sorter: {
        compare: (a, b) => a?.job_role?.localeCompare(b?.job_role),
        multiple: 4,
      },
      render: (text) => <>{text?.job_role === "" ? "-" : text?.job_role}</>,
    },
    {
      title: "Self Opportunity",
      //dataIndex: "job_role",
      width: 200,
      sorter: {
        compare: (a, b) => a?.self_opportunity?.localeCompare(b?.self_opportunity),
        multiple: 4,
      },
      render: (text) => <>{text?.self_opportunity === "" ? "-" : text?.self_opportunity}</>,
    },
    {
      title: "Skills",
      //dataIndex: "skills",
      width: 200,
      sorter: {
        compare: (a, b) => a?.skills?.localeCompare(b?.skills),
        multiple: 5,
      },
      render: (text) => <>{text?.skills === "" ? "-" : text?.skills}</>,
    },
    {
      title: "Available Skill",
      //dataIndex: "skills",
      width: 200,
      sorter: {
        compare: (a, b) => a?.avl_skill?.localeCompare(b?.avl_skill),
        multiple: 5,
      },
      render: (text) => <>{text?.avl_skill === "" ? "-" : text?.avl_skill}</>,
    },
    {
      title: "Gender",
      //dataIndex: "state",
      key: "gender",
      width: 200,
      sorter: {
        compare: (a, b) => a?.gender?.localeCompare(b?.gender),
        multiple: 6,
      },
      render: (text) => <>{text.gender === "" ? "-" : text.gender}</>,
    },
    {
      title: "Date of Birth",
      key: "date_of_birth",
      width: 200,
      sorter: {
        compare: (a, b) =>
          moment(a.date_of_birth, "DD-MM-YYYY") - moment(b.date_of_birth, "DD-MM-YYYY"),
        multiple: 1,
      },
      render: (text) => <>{text.date_of_birth === "" ? "-" : text.date_of_birth}</>,
    },
    {
      title: "Marital Status",
      key: "marital_status",
      width: 200,
      sorter: {
        compare: (a, b) => a?.marital_status?.localeCompare(b?.marital_status),
        multiple: 6,
      },
      render: (text) => <>{text.marital_status === "" ? "-" : text.marital_status}</>,
    },
    {
      title: "Languages Known",
      // dataIndex: "languages",
      key: "languages",
      width: 150,
      sorter: {
        compare: (a, b) => a?.languages?.localeCompare(b?.languages),
        multiple: 6,
      },
      render: (text) => <>{text.languages === "" ? "-" : text.languages}</>,
    },
    {
      title: "State",
      dataIndex: "state",
      width: 200,
      sorter: {
        compare: (a, b) => a?.state?.localeCompare(b?.state),
        multiple: 6,
      },
    },
    {
      title: "District",
      dataIndex: "district",
      width: 150,
      sorter: {
        compare: (a, b) => a?.district?.localeCompare(b?.district),
        multiple: 7,
      },
    },
    {
      title: "Taluka",
      //dataIndex: "taluka",
      key: "taluka",
      width: 150,
      sorter: {
        compare: (a, b) => a?.taluka?.localeCompare(b?.taluka),
        multiple: 7,
      },
      render: (text) => <>{text.taluka === "" ? "-" : text.taluka}</>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 150,
    },
    {
      title: "Pincode",
      key: "pincode",
      width: 150,
      sorter: {
        compare: (a, b) => a?.pincode - b?.pincode,
        multiple: 7,
      },
      render: (text) => <>{text.pincode === "" ? "-" : text.pincode}</>,
    },
    // {
    //   title: "WA_optin",
    //   key: "wa_optin",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => a?.wa_optin?.localeCompare(b?.wa_optin),
    //     multiple: 2,
    //   },
    //   render: (text) => <>{text.wa_optin === "" ? "-" : text.wa_optin}</>,
    // },
    {
      title: "Datasource",
      //dataIndex: "datasource",
      key: "datasource",
      width: 150,
      sorter: {
        compare: (a, b) => a?.datasource?.localeCompare(b?.datasource),
        multiple: 7,
      },
      render: (text) => <>{text.datasource === "" ? "-" : text.datasource}</>,
    },
    {
      title: "Resume",
      width: 150,
      key: "resume_cv",
      render: (text) =>
        text?.resume_cv === undefined || text?.resume_cv === null || text?.resume_cv === "" ? (
          <span>
            <SoftBadge
              variant="contained"
              color="red"
              size="lg"
              badgeContent={"Unavailable"}
              container
            />
          </span>
        ) : (
          (console.log("a", text.resume_cv),
            (
              <a href={text.resume_cv} target="_blank" rel="noreferrer">
                <SoftBadge
                  variant="contained"
                  color="success"
                  size="lg"
                  badgeContent={"View"}
                  container
                />
              </a>
            ))
        ),
      // sorter: {
      //   compare: (a, b) => a?.resume_cv?.localeCompare(b?.resume_cv),
      // },
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      // fixed: "right",
      width: 100,
      render: (data) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "1") {
                  // editProfile(true);
                  // setEditData(data);
                  Seteditadmin(true);
                  Seteditdata(data);
                }
                // else if (key === "2") {
                //   deActiveFun(admindata);
                // } else if (key === "3") {
                //   ActiveFun(admindata);
                // }
                // else {
                //   setShowShortlistModal1(true);
                //   setShowText(admindata);
                // }
                else if (key === "4") {
                  setShowShortlistModal1(true);
                  setShowText(data?.key);
                }
              },
            }}
          >
            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];

  const [colHeaders, setColHeaders] = useState([
    {
      label: "Date",
      key: "createddt",
      isExportable: true,
    },
    {
      label: "Registration ID",
      key: "key",
      isExportable: true,
    },
    {
      label: "Candidate Name",
      key: "candidate",
      isExportable: true,
    },
    {
      label: "Phone",
      key: "mobile",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Education",
      key: "highest_education",
      isExportable: true,
    },
    {
      label: "Gender",
      key: "gender",
      isExportable: true,
    },
    {
      label: "Stream",
      key: "stream",
      isExportable: true,
    },
    {
      label: "Self Opportunity",
      key: "self_opportunity",
      isExportable: true,
    },
    {
      label: "Experience",
      key: "experience",
      isExportable: true,
    },
    {
      label: "Current Income",
      key: "current_salary",
      isExportable: true,
    },
    {
      label: "Current Industry",
      key: "current_industry",
      isExportable: true,
    },
    {
      label: "Role",
      key: "job_role",
      isExportable: true,
    },
    {
      label: "Available Skills",
      key: "avl_skill",
      isExportable: true,
    },
    {
      label: "Skills",
      key: "skills",
      isExportable: true,
    },
    {
      label: "Date of Birth",
      key: "date_of_birth",
      isExportable: true,
    },
    {
      label: "Marital Status",
      key: "marital_status",
      isExportable: true,
    },
    {
      label: "Languages Known",
      key: "languages",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Taluka",
      key: "taluka",
      isExportable: true,
    },
    {
      label: "Address",
      key: "address",
      isExportable: true,
    },
    {
      label: "Pincode",
      key: "pincode",
      isExportable: true,
    },
    // {
    //     label: "WA_optin",
    //     key: "wa_optin",
    //     isExportable: true,
    // },
    {
      label: "Datasource",
      key: "datasource",
      isExportable: true,
    },
    {
      label: "Resume",
      key: "resume_cv",
      isExportable: true,
    },
  ]);
  // const data =admindata?.map((item ,id)=>{return {
  //   key: item.registration_id,
  //   // organization: item.org_name ,
  //   // location: item.org_address,
  //   name: `${item.firstnm+" "+item.lastnm}`,
  //   firstnm: item.firstnm,
  //   lastnm: item.lastnm,
  //   sequrity_ques: item.sequrity_ques,
  //   sequrity_ans: item.sequrity_ans,
  //   email: item.email,
  //   mobnum: item.mobnum,
  //   date_of_creation: moment(item.createddt).utc().format('DD-MM-YYYY') ,
  // }});

  const [state, setState] = useState([]);
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectCol, setSelectCol] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [val, setVal] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [name, setName] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [show, setShow] = useState(false);
  const [KPIObj, setKPIObj] = useState({
    // job_seeekr_on_hold: "",
    // job_seeker_in_pipeline: "",
    // job_seekers_hired: "",
    // total_jobseekers: "",

    total_jobseekers: "0",
    job_seekers_hired: "0",
    job_seeker_in_pipeline: "0",
    job_seeker_on_hold: "0",
    job_seeker_shortlisted: "0",
    job_seeker_uploaded: "0",
    job_seeker_verified: "0",
    job_seeker_connected: "0",
  });
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(admindata, newSelectedRowKeys);
    setTablerow(selectedRows);
    setShortlistObj(
      selectedRows.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.key]: item.score === undefined ? 0 : item.score }),
        {}
      )
    );
    setCandIds(selectedRows.map((item, i) => item.key));
    console.log(selectedRows, "selectedRows");
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", selectedCSVData);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
    // header: ()=>{
    //   <>
    //     <Input.Search placeholder="search" />
    //   </>
    // }
  };
  const ActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        // deactivate_role: roleId,
        // "partner-association": "samarth",
        active: "true",
        deactivatedby: registerID,
      })
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: res.data["Developer Message"],
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        //fetchadminList();
        // fetchOrgList();
      })
      .catch((e) => {
        console.log("err", e);
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        // deactivate_role: roleId,
        // "partner-association": "samarth",
        active: "false",
        deactivatedby: registerID,
      })
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: res.data["Developer Message"],
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        //fetchadminList();
        // fetchOrgList();
      })
      .catch((e) => {
        console.log("err", e);
        Swal.fire({
          icon: "error",
          //title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          // text: e.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }

    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);

  const handleClick = () => {
    if (tablerow.length !== 0) {
      setShow(!show);
      getFileName();
    } else {
      handleDownload();
    }
  };

  const handleDownload = async () => {
    setDownloadingModal(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/downloadallcandidatedata`,
        {
          objid: sessionStorage.getItem("dow_id"),
          email: sessionStorage.getItem("email"),
        }
      );
      console.log(res.data, "xxx1");
      if (res.data) {
        setDownloadingModal(false);
      }
      // var blob = new Blob([res.data], { type: ".csv" });
      // fileSaver.saveAs(blob, "allCandidates.csv");
      Swal.fire({
        text: "File will be mailed shortly to registered email Id (ETA 2 Minutes)",
      });
    } catch (err) {
      console.log(err);
    }
  };

  console.log(paginationState, "xxx");

  const fetchFilteredCandidates = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/candidatefilter`,
        {
          keyword: keywordSearch,
          registration_date: filteroff
            ? []
            : !registerDate[0] || !registerDate[1]
              ? []
              : [registerDate[0], registerDate[1]],
          education: qualification,
          stream: stream,
          experience: "",
          current_salary: salary,
          current_industry: industry,
          role: desgination,
          self_opportunity: selfOpportunity,
          skill: skills,
          gender: gendertype,
          marital_status: married,
          languages: language,
          state: selectedFilterState,
          district: selectedFilterDistrict,
          taluka: talukafilter,
          pincode: pincodefilter,
          age: [age[0], age[1]],
          avl_skill: applySkill,
          limit: pageSize,
          offset: paginationState.page - 1,
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      );

      const data = await res.data;
      console.log(data, "kkk");
      setPaginationState({ ...paginationState, dataSize: data?.count });
      setDataID(data?.download_id);
      setFilteroff(false);
      setSearchData(
        data?.jobseeker?.map((ele, id) => ({
          key: ele.registration_id,
          first_name: ele.firstname,
          last_name: ele.lastname,
          candidate: `${ele.firstname} ${ele.lastname}`,
          date_of_birth: moment(ele.date_of_birth).format("DD-MM-YYYY"),
          mobile: ele?.mobnum?.length === 12 ? ele?.mobnum?.substring(2) : ele.mobnum,
          email: ele.email,
          gender: ele.gender,
          state: ele.state,
          district: ele.district,
          taluka: ele.taluka,
          highest_education: ele.highest_education,
          degree: ele.degree,
          stream: ele.stream,
          experience: ele.experience,
          current_salary: ele.current_salary,
          desired_salary: ele.desired_salary,
          job_role: ele.job_role === "" || ele.job_role === null ? "" : ele.job_role,
          industry: ele.industry,
          marital_status: ele.marital_status,
          skills: ele.skills === "" || ele.skills === null ? "" : ele.skills,
          avl_skill: ele.avl_skill === "" || ele.avl_skill === null ? "" : ele.avl_skill,
          part_time: ele.part_time,
          address: ele.address,
          pincode: ele.pincode,
          specially_abled: ele.specially_abled,
          current_industry: ele.current_industry,
          job_priority: ele.job_priority,
          resume_cv: ele.resume,
          self_opportunity: ele.self_opportunity,
          last_activedate: moment(ele.last_activedate).format("DD-MM-YYYY"),
          last_activetime: moment(ele.last_activetime).format("DD-MM-YYYY"),
          data_status_type: ele.data_status_type,
          wa_optin: ele.wa_optin,
          datasource: ele.datasource === "" || ele.datasource === null ? "" : ele.datasource,
          languages: ele.languages,
          industry: ele.industry,
          createddt: moment(ele.createddt).format("DD-MM-YYYY"),
          modifieddt: moment(ele.modifieddt).format("DD-MM-YYYY"),
          ///////// stages data////////
          feedback: ele.feedback,
          sent_status: ele.sent_status,
          broadcast_status: ele.broadcast_status,
          ratings: ele.ratings,
          stage: ele.stage,
          completion_status: ele.completion_status,
          candidate_status: ele.candidate_status,
          display_comment: ele.display_comment,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchadminList = () => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/candidatefilter`, {
        limit: pageSize,
        offset: paginationState.page - 1,
        keyword: name,
        created_by: sessionStorage.getItem("register_id")
        ? sessionStorage.getItem("register_id")
        : "",
      })
      .then((res) => {
        console.log(res.data["jobseeker"], "counsellor_details");
        // setIsLoad(false);
        setSearchData(
          res?.data["jobseeker"]?.map((ele, id) => ({
            key: ele.registration_id,
            first_name: ele.firstname,
            last_name: ele.lastname,
            candidate: `${ele.firstname} ${ele.lastname}`,
            date_of_birth: moment(ele.date_of_birth).format("DD-MM-YYYY"),
            mobile: ele?.mobnum?.length === 12 ? ele?.mobnum?.substring(2) : ele.mobnum,
            email: ele.email,
            gender: ele.gender,
            state: ele.state,
            district: ele.district,
            taluka: ele.taluka,
            highest_education: ele.highest_education,
            degree: ele.degree,
            stream: ele.stream,
            experience: ele.experience,
            current_salary: ele.current_salary,
            desired_salary: ele.desired_salary,
            job_role: ele.job_role === "" || ele.job_role === null ? "" : ele.job_role,
            industry: ele.industry,
            marital_status: ele.marital_status,
            skills: ele.skills === "" || ele.skills === null ? "" : ele.skills,
            avl_skill: ele.avl_skill === "" || ele.avl_skill === null ? "" : ele.avl_skill,
            part_time: ele.part_time,
            address: ele.address,
            pincode: ele.pincode,
            specially_abled: ele.specially_abled,
            current_industry: ele.current_industry,
            job_priority: ele.job_priority,
            resume_cv: ele.resume,
            self_opportunity: ele.self_opportunity,
            last_activedate: moment(ele.last_activedate).format("DD-MM-YYYY"),
            last_activetime: moment(ele.last_activetime).format("DD-MM-YYYY"),
            data_status_type: ele.data_status_type,
            wa_optin: ele.wa_optin,
            datasource: ele.datasource === "" || ele.datasource === null ? "" : ele.datasource,
            languages: ele.languages,
            industry: ele.industry,
            createddt: moment(ele.createddt).format("DD-MM-YYYY"),
            modifieddt: moment(ele.modifieddt).format("DD-MM-YYYY"),
            ///////// stages data////////
            feedback: ele.feedback,
            sent_status: ele.sent_status,
            broadcast_status: ele.broadcast_status,
            ratings: ele.ratings,
            stage: ele.stage,
            completion_status: ele.completion_status,
            candidate_status: ele.candidate_status,
            display_comment: ele.display_comment,
          }))
        );
        setDataID(res.data?.download_id);
        setPaginationState({ ...paginationState, dataSize: res.data["count"] });
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        // setIsLoad(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    name !== "" && fetchadminList();
  }, [paginationState.page, pageSize, name]);

  useEffect(() => {
    !filtershow && name === "" && fetchFilteredCandidates();
  }, [paginationState.page, pageSize]);

  const fetchCounsellorsKPIs = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/fetch/kpi`, {
        params: {
          createdby: registerID,
        },
      })
      .then((res) => {
        setKPIObj({
          // job_seeekr_on_hold: res.data["job_seeekr_on_hold"],
          // job_seeker_in_pipeline: res.data["job_seeker_in_pipeline"],
          // job_seekers_hired: res.data["job_seekers_hired"],
          // total_jobseekers: res.data["total_jobseekers"],
          total_jobseekers: res.data["total_jobseekers"],
          job_seekers_hired: res.data["job_seekers_hired"],
          job_seeker_in_pipeline: res.data["job_seeker_in_pipeline"],
          job_seeker_on_hold: res.data["job_seeker_on_hold"],
          job_seeker_shortlisted: res.data["job_seeker_shortlisted"],
          job_seeker_uploaded: res.data["job_seeker_uploaded"],
          job_seeker_verified: res.data["job_seeker_verified"],
          job_seeker_connected: res.data["job_seeker_connected"],
        });
      })
      .catch((err) => {
        console.log("error kpi", err);
      });
  };

  const fetchOrgList = () => {
    // const headers = {
    //   "access-control-allow-origin": "*",
    //   "Content-type": "application/json; charset=UTF-8",
    // };
    // const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/skill_institute`,{
          params:{
            created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
          }
        }
        // headers
      )
      .then((res) => {
        console.log("org list", res.data);
        setOrgList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOgrRole = () => {
    const params = {
      orgid: orgId || orgId1 || orgNameID,
      // type: "dropdown",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_names?${paramString}`)
      .then((res) => {
        // setDistrict(res.data);
        setRoleList(res.data);
        setJobtitle(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /*
  const handleSearch = () => {
    const newData = admindata.filter((ele) => {
      return name?.toLocaleLowerCase() === ""
        ? ele
        : ele.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
          name.toLocaleLowerCase() === ""
        ? ele
        : String(ele.mobnum).toLocaleLowerCase().includes(name.toLocaleLowerCase());
    });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    setSearchData(newData);
  };
  useEffect(() => {
    handleSearch();
  }, [name]);
  */

  const fetchCourse = () => {
    const params = {
      orgid: orgNameID,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_names?${paramString}`)
      .then((res) => {
        setJobtitle(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchCourse();
  }, [orgNameID]);

  useEffect(() => {
    fetchOgrRole();
  }, [orgId, orgId1, orgNameID]);

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={2}>
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              {translate('skilling')}       
              </SoftTypography>
              {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                Brief summary  Channel Partner
              </SoftTypography> */}
            </SoftBox>

            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#FFF",
                borderRadius: "2px",
                cursor: "pointer",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              width="40px"
              onClick={() => {
                fetchadminList();
                // fetchCounsellorsKPIs();
                // window.location.reload()
              }}
            >
              <SoftBox>
                <img src={refreshBtn} />
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalregister')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_jobseekers === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_jobseekers
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalconnect')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_connected === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_connected
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalverify')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_verified === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_verified
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalmatch')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_shortlisted === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_shortlisted
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalplaced')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seekers_hired === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seekers_hired
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalinpipeline')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_in_pipeline === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_in_pipeline
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* <Mapdata
            h1="Total Channel Partner Add"
            h2="Total Job Center Created"
            h3="Total JobSeekers Add"
            h4="Total Provider Registered"
            v1="50"
            v2="20"
            v3="4"
            v4="20"
          /> */}
          {/* <SoftBox display="flex" alignItems="flex-start" mb="29px" width="100%" flexWrap="wrap">
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  width="350px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                  }}
                >
                  <SoftSelect
                    placeholder="State"
                    options={state}
                    // value={search}
                    onChange={(currentTarget) => {
                      setSelectedState(currentTarget.value);
                    }}
                  />
                  {/* <Select  placeholder="State" /> *
                </SoftBox>
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  width="350px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  mb={1}
                  sx={{
                    border: "0px",
                    backgroundColor: "#fff",
                    marginRight: "13px",
                    borderRadius: "5px",
                  }}
                >
                  <SoftSelect
                    placeholder="District"
                    options={district}
                    onChange={(currentTarget) => {
                      setSelectedDistrict(currentTarget.value);
                    }}
                  />
                </SoftBox>
              </Grid>
              {/* <SoftBox width={{ lg: "24%", sm: "48%", xs: "95%" }}
              mb={1}
              sx={{
                border: "0px",
                backgroundColor: "#fff",
                marginRight: "13px",
                borderRadius: "5px",
              }}>
              <SoftSelect
                placeholder="Taluka"
              // value={search}
              // onChange={({ currentTarget }) => {
              //   setSearch(search);
              //   onSearchChange(currentTarget.value);
              // }}
              />
            </SoftBox> *
              <Grid item lg={4} md={4} xs={12}>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-center"
                  justifyContent="center"
                  gap="24px"
                  // width={{ lg: "24%", sm: "48%", xs: "95%" }}
                  width="350px"
                  mb={1}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  padding={{ lg: "10px 5%", sm: "12px 80px", xs: "10px 70px" }}
                  onClick={() => navigate("/counselor-registration-page")}
                >
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="0"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    +
                  </SoftTypography>
                  <SoftTypography
                    flex="none"
                    flexGrow="0"
                    order="1"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Counsellor
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox> */}

          {!filtershow ? (
            <SoftBox alignItems="flex-start" position="relative">
              {/* <DataTable            
              table={centertable}
              entriesPerPage={{
                defaultValue: 10,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              canSearch
            /> */}
              <SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  mb={1}
                >
                  <SoftBox width={{ lg: "500px", md: "400px" }}>
                    <SoftInput
                      isClearable
                      // width={{ lg: "1000px", md: "600px" }}
                      // width="1000px"
                      id="search-bar"
                      className="text"
                      // onInput={(e) => {
                      //   setSearchQuery(e.target.value);
                      // }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      variant="outlined"
                      placeholder="Search by name and phone number"
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    gap="10px"
                    justifyContent="space-between"
                  >
                    {/* {selectedRowKeys.length > 1 && (
              // <Button variant="contained" color="success" >
              //   Deactivate
              // </Button>
              <SoftBox
                // width={{ lg: "120px", md: "80px", xs: "60px" }}
                width="120px"
                ml={{ lg: "920px", md: "450px", xs: "180px" }}
                sx={{
                  position: "absolute",
                  // marginLeft: "850px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "32px",
                  // backgroundColor: "#3B5092",
                  borderRadius: "5px",
                  border: "1px solid red",
                  cursor: "pointer",
                }}
                // onclick to deactivate all
              >
                <SoftTypography
                  sx={{
                    color: "red",
                    fontWeight: "medium",
                    fontSize: "15px",
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    padding: "auto",
                  }}
                  // fontSize={{ lg: "15px", md: "12px", xs: "7px" }}
                >
                  Deactivate All
                </SoftTypography>
              </SoftBox>
            )} */}
                    <SoftBox
                      pl={2}
                      sx={{
                        height: "33px",
                        backgroundColor: "#3B5092",
                        borderRadius: "2px",
                        cursor: selectedRowKeys.length !== 0 ? "no-drop" : "pointer",
                        opacity: selectedRowKeys.length !== 0 ? 0.4 : 1,
                      }}
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      p="7px 22px"
                      width={{ lg: "200px", sm: "100%", xs: "50%" }}
                      // onClick={() => setShow(true)}
                      onClick={() => (
                        selectedRowKeys.length === 0 && setModalA1(true),
                        setShowShortlistOption(true)
                      )}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: "medium",
                          lineHeight: "20px",
                        }}
                      >
                        Bulk Shortlist
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox
                      pl={2}
                      sx={{
                        height: "33px",
                        backgroundColor: "#3B5092",
                        borderRadius: "2px",
                        cursor: selectedRowKeys.length ? "pointer" : "no-drop",
                      }}
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      p="7px 22px"
                      width={{ lg: "97px", sm: "100%", xs: "50%" }}
                      // onClick={() => setShow(true)}
                      onClick={() =>
                        selectedRowKeys.length &&
                        (setShowShortlistModal1(true), setShowShortlistOption(false))
                      }
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: "medium",
                          lineHeight: "20px",
                        }}
                      >
{            translate('shortlist')        }                      </SoftTypography>
                    </SoftBox>
                    <Tooltip
                      key="1"
                      title={tablerow.length === 0 ? "Download All" : "Download"}
                      placement="top"
                    >
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#000000",
                          borderRadius: "2px",
                          cursor: "pointer",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => (selectedRowKeys?.length === 0 ? DownloadAll() : Download())}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="46"
                          height="34"
                          viewBox="0 0 46 34"
                          fill="none"
                        >
                          <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                          <path
                            d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22.9531 16.5098V23.0102"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </SoftBox>
                    </Tooltip>

                    <Tooltip key="3" title="Filter" placement="top">
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "2px",
                          cursor: "pointer",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        onClick={() => {
                          // showFilter ? setShowFilter(false) : setShowFilter(true);
                          //setShowFilter(true);
                          setFiltershow(true);
                        }}
                      >
                        <SoftBox>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="34"
                            viewBox="0 0 39 37"
                            fill="none"
                          >
                            <rect width="38.8889" height="37" rx="2" fill="white" />
                            <path
                              d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                              stroke="#A4A4A4"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </SoftBox>
                    </Tooltip>

                    {/*
                <SoftBox
                  width="120px"
                  // ml={{ lg: "1070px", md: "600px", xs: "300px" }}
                  sx={{
                    // position: "absolute",
                    // marginLeft:"1000px",
                    // marginLeft: "1000px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    // backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    border: "1px solid ",
                    cursor: "pointer",
                  }}
                // onclick to deactivate all
                >
                  <SoftTypography
                    sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                    onClick={() => (
                      setShow(!show),
                      // setFileName(() => {
                      //   getFileName();
                      // })
                      getFileName()
                    )}
                  >
                    Export CSV
                  </SoftTypography>
                  <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink>
                </SoftBox>
                */}
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              <Table
                loading={isLoading}
                rowSelection={rowSelection}
                columns={colName}
                // dataSource={searchData.filter((ele) =>
                //   name?.toLocaleLowerCase() === ""
                //     ? ele
                //     : ele.candidate?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
                //       name?.toLocaleLowerCase() === ""
                //     ? ele
                //     : String(ele.mobile?.toLocaleLowerCase()).includes(
                //         String(name.toLocaleLowerCase())
                //       )
                // )}
                dataSource={searchData}
                scroll={{ x: 1800, y: 400 }}
                pagination={{
                  pageSizeOptions: ["10", "20", "50", "100", "500"],
                  total: paginationState.dataSize,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  current: paginationState.page,
                  onChange: (page, pageSize) =>
                    setPaginationState({ ...paginationState, page: page }),
                  showTotal: (total, range) => (
                    // <span>
                    //   Showing <strong>{total}</strong> results of {paginationState.dataSize}
                    // </span>
                    <span>
                      Showing{" "}
                      <strong>
                        {range[0]} - {range[1]}
                      </strong>{" "}
                      results of {total}
                    </span>
                  ),
                }}
              />
            </SoftBox>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  background: "#FFFFFF",
                  mt: 3,
                  p: 3,
                  borderRadius: "10px",
                }}
              >
                <SoftTypography fontSize="20px" fontWeight="600" lineHeight="19px">
                {translate('loadcandidate')}       
                </SoftTypography>

                <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 2 }}>
                  <img src={helpimg} alt="" />

                  <SoftTypography fontSize="14px" fontWeight="small" lineHeight="19px">
                    &nbsp;{translate('thiswillhelp')} 
                  </SoftTypography>
                </Box>

                <Box
                  sx={{
                    p: 1,
                    background: "rgba(27, 89, 248, 0.05)",
                    border: "1px solid #C4C4C4",
                    borderRadius: "10px",
                  }}
                >
                  <SoftTypography
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="19px"
                    margin="16px"
                    marginLeft="10px"
                  >
                              {translate('selectpref')}       
                  </SoftTypography>

                  <Grid container spacing={2} sx={{ maxWidth: "900px" }}>
                    <Grid item lg={12} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <SoftTypography
                            fontSize="15px"
                            fontWeight="small"
                            lineHeight="19px"
                            display="flex"
                            alignItems="center"
                          >
                            Keyword
                          </SoftTypography>
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <SoftBox
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <input
                                type="checkbox"
                                name="checkbox"
                                checked={excludeKeyword}
                                onChange={() => setExcludeKeyword(!excludeKeyword)}
                                placeholder=""
                              />
                              <SoftTypography
                                sx={{
                                  color: "black",
                                  fontSize: "15px",
                                  fontWeight: "small",
                                  lineHeight: "19px",
                                  margin: "0px 5px",
                                }}
                              >
                                Exclude Keyword Search
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <Switch
                                  checked={checked}
                                  onChange={handleChangeKeyword}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              </Box>

                              <SoftTypography
                                sx={{
                                  color: "black",
                                  fontSize: "15px",
                                  fontWeight: "small",
                                  lineHeight: "19px",
                                  margin: "0px 5px",
                                }}
                              >
                                Boolean
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Box>

                        <SoftInput
                          type="text"
                          placeholder="Search by skills"
                          value={keywordSearch}
                          onChange={(e) => setKeywordSearch(e.target.value)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <br />

                <Box
                  sx={{
                    p: 1,
                    background: "rgba(27, 89, 248, 0.05)",
                    border: "1px solid #C4C4C4",
                    borderRadius: "10px",
                  }}
                >
                  <Grid container spacing={2} sx={{ maxWidth: "900px" }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Registration Date
                        </SoftTypography>

                        <RangePicker
                          style={{ width: "100%" }}
                          onCalendarChange={onCalendarChange}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Education
                        </SoftTypography>

                        <Select
                          isMulti
                          options={education}
                          value={
                            qualification !== ""
                              ? qualification?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiEducation
                          }
                          placeholder="Select Education"
                          onChange={(e) => {
                            if (e) {
                              setMultiEducation(e.map((item) => item));
                            } else {
                              setMultiEducation([]);
                            }
                            setQualification(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Stream
                        </SoftTypography>

                        <Select
                          // placeholder="Please enter Job Title"

                          // value={stream !== "" ? { label: stream, value: stream } : null}
                          // onChange={(e) => setStream(e.value)}
                          options={streamdata}
                          isMulti
                          value={
                            stream !== ""
                              ? stream?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiStream
                          }
                          placeholder="Select Streams"
                          onChange={(e) => {
                            if (e) {
                              setMultiStream(e.map((item) => item));
                            } else {
                              setMultiStream([]);
                            }
                            setStream(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Current Salary
                        </SoftTypography>

                        <Select
                          isClearable
                          isMulti
                          placeholder="Select Salary"
                          value={
                            salary !== ""
                              ? salary?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiSalary
                          }
                          options={salaryList}
                          // onChange={(e) => (e ? setSalary(e.value) : setSalary(""))}
                          onChange={(e) => {
                            if (e) {
                              setMultiSalary(e.map((item) => item));
                            } else {
                              setMultiSalary([]);
                            }
                            setSalary(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Current Industry
                        </SoftTypography>

                        <Select
                          isClearable
                          isMulti
                          placeholder="Select Industries"
                          value={
                            industry !== ""
                              ? industry?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiIndustry
                          }
                          options={industryList}
                          // onChange={(e) => (e ? setSalary(e.value) : setSalary(""))}
                          onChange={(e) => {
                            if (e) {
                              setMultiIndustry(e.map((item) => item));
                            } else {
                              setMultiIndustry([]);
                            }
                            setIndustry(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Role
                        </SoftTypography>

                        <Select
                          placeholder="Select Roles"
                          isMulti
                          options={jobrole}
                          value={
                            desgination !== ""
                              ? desgination?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiRole
                          }
                          onChange={(e) => {
                            if (e) {
                              setMultiRole(e.map((item) => item));
                            } else {
                              setMultiRole([]);
                            }
                            setDesignation(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Self Opportunity
                        </SoftTypography>

                        <Select
                          placeholder="Select Yes/No"
                          isClearable
                          value={
                            selfOpportunity !== ""
                              ? { label: selfOpportunity, value: selfOpportunity }
                              : null
                          }
                          // options={stateData}
                          options={selfOpportunityList}
                          onChange={(e) =>
                            e ? setSelfOpportunity(e.value) : setSelfOpportunity("")
                          }
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Skill
                        </SoftTypography>

                        <Select
                          placeholder="Select Skills"
                          isMulti
                          options={skillsOption}
                          value={
                            skills !== ""
                              ? skills?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiSkill
                          }
                          onChange={(e) => {
                            if (e) {
                              setMultiSkill(e.map((item) => item));
                            } else {
                              setMultiSkill([]);
                            }
                            setSkills(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Applied Skill
                        </SoftTypography>

                        <Select
                          placeholder="Select Skills"
                          isMulti
                          options={applySkills}
                          value={
                            applySkill !== ""
                              ? applySkill?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiapplySkills
                          }
                          onChange={(e) => {
                            console.log("multi desig:", e);
                            if (e) {
                              setMultiApplySkills(e.map((item) => item));
                            } else {
                              setMultiApplySkills([]);
                            }
                            setApplySkill(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Gender
                        </SoftTypography>

                        <Select
                          isClearable
                          isMulti
                          placeholder="Select Gender"
                          options={genderOption}
                          value={
                            gendertype !== ""
                              ? gendertype?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multigender
                          }
                          onChange={(e) => {
                            if (e) {
                              setMultigender(e.map((item) => item));
                            } else {
                              setMultigender([]);
                            }
                            setGenderType(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Marital Status
                        </SoftTypography>

                        <Select
                          isClearable
                          isMulti
                          placeholder="Select Marital Status"
                          options={maritalStatus}
                          value={
                            married !== ""
                              ? married?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiMarried
                          }
                          onChange={(e) => {
                            if (e) {
                              setMultimarried(e.map((item) => item));
                            } else {
                              setMultimarried([]);
                            }
                            setMarried(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Languages Known
                        </SoftTypography>

                        <Select
                          isClearable
                          isMulti
                          placeholder="Select Languages"
                          options={languagesList}
                          value={
                            language !== ""
                              ? language?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiLanguage
                          }
                          onChange={(e) => {
                            if (e) {
                              setMultiLanguage(e.map((item) => item));
                            } else {
                              setMultiLanguage([]);
                            }
                            setLanguage(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          State
                        </SoftTypography>

                        <Select
                          placeholder="Select State"
                          isClearable
                          value={
                            selectedFilterState !== ""
                              ? { label: selectedFilterState, value: selectedFilterState }
                              : null
                          }
                          // options={stateData}
                          options={filterState}
                          onChange={(e) =>
                            e ? setSelectedFilterState(e.value) : setSelectedFilterState("")
                          }
                          styles={stylesDD}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          District
                        </SoftTypography>

                        <Select
                          isMulti
                          placeholder="Select Districts"
                          options={district}
                          styles={stylesDD}
                          value={
                            selectedFilterDistrict !== "" && selectedFilterDistrict !== null
                              ? selectedFilterDistrict?.split(",").map(
                                (ele) =>
                                  ele !== "null" && {
                                    label: ele,
                                    value: ele,
                                  }
                              )
                              : multiDistrict
                          }
                          onChange={(e) => {
                            if (e) {
                              setMultiDistrict(e.map((item) => item));
                            } else {
                              setMultiDistrict([]);
                            }
                            setSelectedFilterDistrict(
                              e
                                .map((ele) => ele)
                                .map((item) => item.value)
                                .join()
                            );
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Taluka
                        </SoftTypography>

                        <SoftInput
                          type="text"
                          placeholder="Enter Taluka"
                          value={talukafilter}
                          onChange={(e) => setTalukafilter(e.target.value)}
                        />
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftTypography
                          fontSize="15px"
                          fontWeight="small"
                          lineHeight="19px"
                          pb="4px"
                        >
                          Pincode
                        </SoftTypography>

                        <SoftInput
                          type="tel"
                          placeholder="Enter Pincode"
                          inputProps={{
                            maxLength: "6",
                          }}
                          value={pincodefilter}
                          onChange={(e) => setPincodefilter(e.target.value)}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <SoftBox
                          sx={{
                            // margin: useAge ? "8px" : "32px 8px 0px 8px",
                            // margin:"8px",
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={useAge}
                            onChange={() => setUseAge(!useAge)}
                            placeholder=""
                          />
                          <SoftTypography fontWeight="medium" fontSize="15px" lineHeight="19px">
                            Select if you want to apply age filter
                          </SoftTypography>
                        </SoftBox>
                        {useAge && (
                          <Box sx={{ p: 1 }}>
                            <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                              Age: {`${age[0]} - ${age[1]}`}
                            </SoftTypography>

                            <ThemeProvider theme={TermSlider}>
                              <Slider
                                // name="termValue"
                                getAriaLabel={() => "Minimum distance shift"}
                                valueLabelDisplay="auto"
                                // aria-label="Default"
                                // defaultValue={[26 - 36]}
                                min={18}
                                max={65}
                                value={age}
                                disableSwap
                                onChange={handleAgeChange}
                              />
                            </ThemeProvider>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <br />

                <SoftBox display="flex" flexDirection="row" justifyContent="flex-start" gap="25px">
                  <Box sx={{ pl: 0.5 }}>
                    <SoftBox
                      sx={{
                        height: "45px",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #3B5092",
                        borderRadius: "5px",
                        width: "150px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => (setFiltershow(false), resetFunc())}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#3B5092",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
              {translate('reset')}       
                      </SoftTypography>
                    </SoftBox>
                  </Box>

                  <Box sx={{ pl: 0.5 }}>
                    <SoftBox
                      sx={{
                        height: "45px",
                        backgroundColor: "#3B5092",
                        borderRadius: "5px",
                        width: "150px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFiltershow(false);
                        fetchFilteredCandidates();
                        setFunc();
                        filterOffsetFunc();
                      }}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "medium",
                          lineHeight: "19px",
                        }}
                      >
              {translate('applyfilter')}       
                      </SoftTypography>
                    </SoftBox>
                  </Box>
                </SoftBox>
              </Box>
            </>
          )}

          {/* </Card> */}
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {colHeaders.map((ele, index) => (
                <>
                  <ul className="UlCSV" key={index}>
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={tablerow.length === 0 ? searchData : tablerow}
                    //data={selectedCSVData}
                    filename={fileName}
                    headers={colHeaders.filter((ele) => ele.isExportable === true)}
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* modal for export */}

        {/* shortlist Modat 1 */}
        <Modal show={showshortlistModal1} onHide={handleCloseShortlistModal1} centered>
          <Modal.Body>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <SoftTypography fontWeight="medium" fontSize="20px">
                Select from below options
              </SoftTypography>

              <SoftBox
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  handleCloseShortlistModal1();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.16992 14.8299L14.8299 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.8299 14.8299L9.16992 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ ml: "auto", mr: "auto", mt: 2 }}
            >
              <Box sx={{ p: 1 }}>
                <SoftBox
                  sx={{
                    height: "35px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    width: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowShortlistModal2(true);
                    setShowShortlistModal1(false);
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "normal",
                      lineHeight: "19px",
                    }}
                  >
                    Existing Bucket
                  </SoftTypography>
                </SoftBox>
              </Box>

              <Box sx={{ p: 1 }}>
                <SoftBox
                  sx={{
                    height: "35px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    width: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowShortlistModal3(true);
                    setShowShortlistModal1(false);
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "normal",
                      lineHeight: "19px",
                    }}
                  >
                    Create Bucket
                  </SoftTypography>
                </SoftBox>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>

        {/* Shortlist Modal 2 */}

        {/* Select Bucket Modal */}
        {/* // "0.5": */}

        <Modal
          show={showShortlistModal2}
          onHide={() => {
            handleCloseShortlistModal2();
            selectBucket.handleReset();
            setJobId("");
            setOrgId("");
          }}
          // onClose={handleCloseShortlistModal2}
          centered
        >
          <Modal.Body>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <SoftTypography fontWeight="medium" fontSize="20px">
                Select a Bucket
              </SoftTypography>

              <SoftBox
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  handleCloseShortlistModal2();
                  setShowShortlistModal2(false);
                  selectBucket.handleReset();
                  setJobId("");
                  setOrgId("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.16992 14.8299L14.8299 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.8299 14.8299L9.16992 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SoftBox>
            </SoftBox>

            <SoftBox mt={2}>
              <SoftBox>
                <SoftBox mt={0} width="100%" height="auto">
                  <Card sx={{ overflow: "visible" }}>
                    <SoftBox mt={0} width="100%" height="auto">
                      <SoftBox width="100%" height="auto">
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={12}>
                            <SoftBox>
                              <Box sx={{ width: "100%" }}>
                                <Select
                                  options={orgList}
                                  styles={stylesDD}
                                  placeholder="Select from following companies"
                                  // onChange={(e) => (
                                  //   selectBucket.setFieldValue("org_name", e.label), setOrgId(e.value)
                                  // )}
                                  onChange={(e) =>
                                    !showShortlistOption
                                      ? (selectBucket.setFieldValue("org_name", e.label),
                                        setOrgId(e.value))
                                      : setOrgId1(e.value)
                                  }
                                />
                                <SoftTypography fontWeight="small" fontSize="12px" padding="4px">
                                  {selectBucket.touched.org_name && selectBucket.errors.org_name ? (
                                    <span style={{ color: "red" }}>
                                      {selectBucket.errors.org_name}
                                    </span>
                                  ) : null}
                                </SoftTypography>
                              </Box>
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={12}>
                            <SoftBox>
                              <Box sx={{ width: "100%" }}>
                                <Select
                                  options={roleList}
                                  styles={stylesDD}
                                  placeholder="Select from following job types"
                                  onChange={(e) => {
                                    !showShortlistOption
                                      ? (selectBucket.setFieldValue("job_role", e.label),
                                        setJobId(e.value))
                                      : setJobId1(e.value);
                                  }}
                                />
                                <SoftTypography fontWeight="small" fontSize="12px" padding="4px">
                                  {selectBucket.touched.job_role && selectBucket.errors.job_role ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {selectBucket.errors.job_role}
                                    </span>
                                  ) : null}
                                </SoftTypography>
                              </Box>
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={12}>
                            {showShortlistOption && (
                              <SoftBox
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  // alignItems: "center",
                                  // marginTop: "30px",
                                  gap: "10px",
                                }}
                              >
                                <SoftTypography
                                  sx={{
                                    color: "black",
                                    fontSize: "15px",
                                    fontWeight: "small",
                                    lineHeight: "19px",
                                  }}
                                >
                                  Data count to upload from <b>{paginationState.dataSize}</b> total
                                  data:
                                </SoftTypography>
                                <SoftBox
                                  display="flex"
                                  flexDirection="row"
                                  // justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <SoftBox width="100%">
                                    <SoftInput
                                      type="number"
                                      // maxValue={cpAssignPayload.candidates}
                                      value={dataLength}
                                      // onChange={(e) => setAssignCount(e.target.value)}
                                      onChange={handleChange}
                                    />
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            )}
                          </Grid>

                          <Grid item xs={12} lg={12}>
                            <SoftBox display="flex" justifyContent="center">
                              <SoftTypography
                                fontWeight="small"
                                fontSize="14px"
                                sx={{ "&:hover": { cursor: "pointer" } }}
                                onClick={() => {
                                  setShowShortlistModal3(true);
                                  setShowShortlistModal2(false);
                                  navigate("/searchbyposting/createcourse");
                                }}
                              >
                                Could not find suitable bucket? Create Bucket
                              </SoftTypography>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ ml: "auto", mr: "auto", mt: 1 }}
            >
              {showShortlistOption ? (
                <Box sx={{ p: 1 }}>
                  <SoftBox
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      width: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={AllShortListFunc.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Bulk Shortlist
                    </SoftTypography>
                  </SoftBox>
                </Box>
              ) : (
                <Box sx={{ p: 1 }}>
                  <SoftBox
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      width: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={selectBucket.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Select Bucket
                    </SoftTypography>
                  </SoftBox>
                </Box>
              )}
            </SoftBox>
          </Modal.Body>
        </Modal>

        {/* Select Bucket Modal */}
        <Modal
          show={modalA1}
          onHide={() => {
            setModalA1(false);
            // showAlert();
          }}
          centered
        >
          <Modal.Body>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <SoftTypography fontWeight="medium" fontSize="20px">
                Select from below options
              </SoftTypography>

              <SoftBox
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  setModalA1(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.16992 14.8299L14.8299 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.8299 14.8299L9.16992 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ ml: "auto", mr: "auto", mt: 2 }}
            >
              <Box sx={{ p: 1 }}>
                <SoftBox
                  sx={{
                    height: "35px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    width: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalA2(true);
                    setModalA1(false);
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "normal",
                      lineHeight: "19px",
                    }}
                  >
                    Existing Bucket
                  </SoftTypography>
                </SoftBox>
              </Box>

              <Box sx={{ p: 1 }}>
                <SoftBox
                  sx={{
                    height: "35px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    width: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalA1(false);
                    setShowShortlistModal3(true);
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "normal",
                      lineHeight: "19px",
                    }}
                  >
                    Create Bucket
                  </SoftTypography>
                </SoftBox>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>

        {/* Bulk upload A2*/}
        <Modal
          show={modalA2}
          onHide={() => {
            setModalA2(false);
            AllShortListFunc.handleReset();
            setOrgId1("");
            setJobId1("");
          }}
          centered
        >
          <Modal.Body>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <SoftTypography fontWeight="medium" fontSize="20px">
                Select a Bucket
              </SoftTypography>

              <SoftBox
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  setModalA2(false);
                  AllShortListFunc.handleReset();
                  setOrgId1("");
                  setJobId1("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.16992 14.8299L14.8299 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.8299 14.8299L9.16992 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SoftBox>
            </SoftBox>

            <SoftBox mt={2}>
              <SoftBox>
                <SoftBox mt={0} width="100%" height="auto">
                  <Card sx={{ overflow: "visible" }}>
                    <SoftBox mt={0} width="100%" height="auto">
                      <SoftBox width="100%" height="auto">
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={12}>
                            <SoftBox>
                              <Box sx={{ width: "100%" }}>
                                <Select
                                  options={orgList}
                                  styles={stylesDD}
                                  placeholder="Select from following companies"
                                  // onChange={(e) => (
                                  //   selectBucket.setFieldValue("org_name", e.label), setOrgId(e.value)
                                  // )}
                                  onChange={(e) => (
                                    AllShortListFunc.setFieldValue("org_name1", e.value),
                                    setOrgId1(e ? e.value : "")
                                  )}
                                />
                                <SoftTypography fontWeight="small" fontSize="12px" padding="4px">
                                  {AllShortListFunc.touched.org_name1 &&
                                    AllShortListFunc.errors.org_name1 ? (
                                    <span style={{ color: "red" }}>
                                      {AllShortListFunc.errors.org_name1}
                                    </span>
                                  ) : null}
                                </SoftTypography>
                              </Box>
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={12}>
                            <SoftBox>
                              <Box sx={{ width: "100%" }}>
                                <Select
                                  options={roleList}
                                  styles={stylesDD}
                                  placeholder="Select from following job types"
                                  onChange={(e) => (
                                    AllShortListFunc.setFieldValue("job_role1", e.value),
                                    setJobId1(e ? e.value : "")
                                  )}
                                />
                                <SoftTypography fontWeight="small" fontSize="12px" padding="4px">
                                  {AllShortListFunc.touched.job_role1 &&
                                    AllShortListFunc.errors.job_role1 ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {AllShortListFunc.errors.job_role1}
                                    </span>
                                  ) : null}
                                </SoftTypography>
                              </Box>
                            </SoftBox>
                          </Grid>

                          {/*
                          <Grid item xs={12} lg={12}>
                            <SoftBox>
                              <SoftTypography
                                sx={{
                                  color: "black",
                                  fontSize: "15px",
                                  fontWeight: "small",
                                  lineHeight: "19px",
                                  mb: 1,
                                }}
                              >
                                Data count to upload from <b>{paginationState.dataSize}</b> total
                                data:
                              </SoftTypography>

                              <Box sx={{ width: "100%" }}>
                                <SoftInput
                                  type="number"
                                  value={dataLength}
                                  onChange={handleChange1}
                                />
                              </Box>
                            </SoftBox>
                          </Grid>
                          */}

                          <Grid item xs={12} lg={12}>
                            <SoftBox display="flex" justifyContent="center">
                              <SoftTypography
                                fontWeight="small"
                                fontSize="14px"
                                sx={{ "&:hover": { cursor: "pointer" } }}
                                onClick={() => {
                                  setModalA2(false);
                                  navigate("/searchbyposting/createcourse");
                                }}
                              >
                                Could not find suitable bucket? Create Bucket
                              </SoftTypography>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ ml: "auto", mr: "auto", mt: 1 }}
            >
              <Box sx={{ p: 1 }}>
                <SoftBox
                  sx={{
                    height: "45px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    width: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={AllShortListFunc.handleSubmit}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Bulk Shortlist
                  </SoftTypography>
                </SoftBox>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>

        <Modal
          show={showShortlistModal3}
          onHide={handleCloseShortlistModal3}
          size="xl"
          centered
          style={{ marginBottom: "75px" }}
        >
          <Modal.Body>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <SoftTypography fontWeight="medium" fontSize="20px">
                Create a bucket
              </SoftTypography>

              <SoftBox
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  handleCloseShortlistModal3();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.16992 14.8299L14.8299 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.8299 14.8299L9.16992 9.16992"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#3A3A3A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <SoftBox sx={{ p: 1, mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        mb={1.5}
                      >
                        <SoftBox display="flex" flexDirection="row">
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Skilling Institute
                          </SoftTypography>
                          <SoftBox
                            sx={{
                              color: "red",
                              position: "inherit",
                              paddingLeft: "2px",
                              fontSize: "15px",
                            }}
                          >
                            <sup>*</sup>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          display="flex"
                          flexDirection="row"
                          gap="10px"
                          onClick={() => {
                            sessionStorage.setItem("skilling", "Skilling Institute");
                            navigate("/jobprovider-registration");
                          }}
                        >
                          <Tooltip key="1" title="Add a new skilling institute" placement="bottom">
                            <SoftBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15px"
                                height="15px"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <rect width="10" height="10" rx="5" fill="#3B5092" />
                                <path
                                  d="M5 2V8M2 5H8"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </SoftBox>
                          </Tooltip>
                          {/* <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="13px"
                                  lineHeight="17px"
                                >
                                  Add a new organisation
                                </SoftTypography> */}
                        </SoftBox>
                      </SoftBox>
                      {/* <SoftInput type="text" placeholder="" /> */}
                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <Select
                          styles={stylesDD}
                          isClearable
                          placeholder="Select skilling institute"
                          options={orgList}
                          // value={orgList.filter((ele) => ele.value === orgNameID)}
                          onChange={(e) => (
                            jobpostformone.setFieldValue("org_name", e ? e.label : ""),
                            setOrgNameID(e ? e.value : "")
                          )}
                        />
                      </SoftBox>
                      {jobpostformone.touched.org_name && jobpostformone.errors.org_name ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.org_name}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox
                        width="100%"
                        mb={1}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <SoftBox display="flex" flexDirection="row">
                          <SoftTypography
                            fontWeight="medium"
                            fontSize="16px"
                            lineHeight="20px"
                            mb={1}
                          >
                            Course Name
                          </SoftTypography>
                          <SoftBox
                            sx={{
                              color: "red",
                              position: "inherit",
                              paddingLeft: "2px",
                              fontSize: "15px",
                            }}
                          >
                            <sup>*</sup>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          display="flex"
                          flexDirection="row"
                          gap="10px"
                          onClick={() => {
                            setCreate(!create);
                          }}
                        >
                          <Tooltip
                            key="2"
                            title={!create ? `Create New Job Title` : `Select From Existing `}
                            placement="bottom"
                          >
                            <SoftBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15px"
                                height="15px"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <rect width="10" height="10" rx="5" fill="#3B5092" />
                                <path
                                  d="M5 2V8M2 5H8"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </SoftBox>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                      {create ? (
                        <SoftInput
                          type="text"
                          placeholder="Job title"
                          name="job_title"
                          id="job_title"
                          onChange={jobpostformone.handleChange}
                          value={jobpostformone.values.job_title}
                        />
                      ) : (
                        <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                          <Select
                            styles={stylesDD}
                            isClearable
                            placeholder="Please enter course name"
                            options={jobTitle}
                            onChange={
                              (e) => jobpostformone.setFieldValue("job_title", e ? e.label : "")
                              // setOrgNameID(e.value)
                            }
                          />
                        </SoftBox>
                      )}

                      {jobpostformone.touched.job_title && jobpostformone.errors.job_title ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.job_title}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Course Duration
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <SoftInput
                          type="number"
                          placeholder="Enter number of days"
                          name="job_type"
                          id="job_type"
                          // min={0}
                          onChange={jobpostformone.handleChange}
                          value={jobpostformone.values.job_type}
                        />
                      </SoftBox>
                      {jobpostformone.touched.job_type && jobpostformone.errors.job_type ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.job_type}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Valid Till
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftInput
                        type="datetime-local"
                        placeholder=""
                        name="jobvaliddt"
                        id="jobvaliddt"
                        onChange={jobpostformone.handleChange}
                        value={jobpostformone.values.jobvaliddt}
                      />
                      {jobpostformone.touched.jobvaliddt && jobpostformone.errors.jobvaliddt ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.jobvaliddt}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Course Start Date
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftInput
                        type="date"
                        placeholder=""
                        name="start_date"
                        id="start_date"
                        onChange={jobpostformone.handleChange}
                        value={jobpostformone.values.start_date}
                      />
                      {jobpostformone.touched.start_date && jobpostformone.errors.start_date ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.start_date}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Course Fee
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        {/* <span>&#x20B9;</span> */}
                        <SoftInput
                          type="number"
                          placeholder="&#x20B9; Enter course fee"
                          name="fee"
                          id="fee"
                          onChange={jobpostformone.handleChange}
                          value={jobpostformone.values.fee}
                        />
                      </SoftBox>

                      {jobpostformone.touched.fee && jobpostformone.errors.fee ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.fee}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Highest Qualification
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <Select
                          styles={stylesDD}
                          isClearable
                          isMulti
                          options={education}
                          // onChange={(e) =>
                          //   jobpostform.setFieldValue("qualification", e ? e.value : "")
                          // }
                          onChange={(e) => (
                            // !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                            console.log("multi education:", e.map((ele) => ele.value).join(",")),
                            jobpostformone.setFieldValue(
                              "qualification",
                              e.map((ele) => ele.value).join(",")
                            )
                          )}
                        />
                      </SoftBox>
                      {jobpostformone.touched.qualification &&
                        jobpostformone.errors.qualification ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.qualification}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Mode of Course
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <Select
                          styles={stylesDD}
                          isClearable
                          options={Modes}
                          placeholder="Select mode of course"
                          onChange={(e) => {
                            jobpostformone.setFieldValue("salary", e ? e.label : "");
                          }}
                        />
                      </SoftBox>
                      {jobpostformone.touched.salary && jobpostformone.errors.salary ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.salary}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          State
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <Select
                          styles={stylesDD}
                          isClearable
                          options={state}
                          onChange={(currentTarget) => (
                            // setSelectedState(currentTarget.value),
                            // !allstate
                            //   ? jobpostform.setFieldValue("state", currentTarget.value)
                            //   : ""
                            setSelectedState(currentTarget ? currentTarget.value : ""),
                            !allstate
                              ? jobpostformone.setFieldValue(
                                "state",
                                currentTarget ? currentTarget.value : ""
                              )
                              : ""
                          )}
                        />
                      </SoftBox>
                      {jobpostformone.touched.state && jobpostformone.errors.state ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.state}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          District
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <Select
                          styles={stylesDD}
                          isClearable
                          isMulti
                          options={district}
                          onChange={(e) => (
                            // !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                            console.log("multi district:", e.map((ele) => ele.value).join(",")),
                            !allstate
                              ? jobpostformone.setFieldValue(
                                "district",
                                e.map((ele) => ele.value).join(",")
                              )
                              : ""
                          )}
                        />
                      </SoftBox>
                      {jobpostformone.touched.district && jobpostformone.errors.district ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.district}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Taluka
                        </SoftTypography>
                        {/* <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox> */}
                      </SoftBox>

                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <SoftInput
                          type="text"
                          placeholder="Enter taluka"
                          name="taluka"
                          id="taluka"
                          onChange={jobpostformone.handleChange}
                          value={jobpostformone.values.taluka}
                        />
                      </SoftBox>

                      {/* {jobpostformone.touched.taluka && jobpostformone.errors.taluka ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {jobpostformone.errors.taluka}{" "}
                    </span>
                  ) : null} */}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Address
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                        <SoftInput
                          type="text"
                          placeholder="Enter address"
                          name="address"
                          id="address"
                          onChange={jobpostformone.handleChange}
                          value={jobpostformone.values.address}
                        />
                      </SoftBox>

                      {jobpostformone.touched.address && jobpostformone.errors.address ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.address}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Upload Brochure
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>

                      <input
                        className="form-control"
                        type="file"
                        accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                        id="application"
                        name="application"
                        onChange={(e) => {
                          jobpostformone.setFieldValue("application", e.target.files[0]);
                        }}
                      />

                      {jobpostformone.touched.application && jobpostformone.errors.application ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.application}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="column">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Age
                          {/* : {`${age[0]} - ${age[1]}`} */}
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            background: "#fff",
                            borderRadius: "0.5rem",
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <Select
                            placeholder="Min Age"
                            isDisabled={any}
                            styles={stylesDD}
                            isClearable
                            options={ageOption}
                            onChange={
                              (currentTarget) =>{
                                // setSelectedState(currentTarget.value),
                                // !allstate
                                //   ? jobpostform.setFieldValue("state", currentTarget.value)
                                //   : ""
                                setMinAge(currentTarget ? currentTarget.value : "");
                                currentTarget && setOptions(currentTarget.value);
                                !currentTarget && setMaxAgeOption([]);
                              // !allstate
                              //   ? jobpostformone.setFieldValue(
                              //       "state",
                              //       currentTarget ? currentTarget.value : ""
                              //     )
                              //   : ""
                            }}
                          />
                          <Select
                            placeholder="Max Age"
                            isDisabled={any}
                            styles={stylesDD}
                            isClearable
                            options={maxAgeOption}
                            onChange={
                              (currentTarget) =>
                                // setSelectedState(currentTarget.value),
                                // !allstate
                                //   ? jobpostform.setFieldValue("state", currentTarget.value)
                                //   : ""
                                setMaxAge(currentTarget ? currentTarget.value : "")
                              // !allstate
                              //   ? jobpostformone.setFieldValue(
                              //       "state",
                              //       currentTarget ? currentTarget.value : ""
                              //     )
                              //   : ""
                            }
                          />
                          <SoftBox
                            sx={{
                              border: !any ? "1px solid #e4e4e4" : "1px solid #87c4ff",
                              borderRadius: "8px",
                              fontSize: "16px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100px",
                              cursor: "pointer",
                              backgroundColor: !any ? "none" : "#87c4ff",
                              color: !any ? "none" : "#fff",
                            }}
                            onClick={() => (setMinAge(18), setMaxAge(64), setAny(!any))}
                          >
                            Any
                          </SoftBox>
                        </SoftBox>
                        {/* <SoftBox
                      sx={{
                        color: "red",
                        position: "inherit",
                        paddingLeft: "2px",
                        fontSize: "15px",
                      }}
                    >
                      <sup>*</sup>
                    </SoftBox> */}
                      </SoftBox>

                      {/* <ThemeProvider theme={TermSlider}>
                    <Slider
                      getAriaLabel={() => "Minimum distance shift"}
                      valueLabelDisplay="auto"
                      min={18}
                      max={65}
                      value={age}
                      disableSwap
                      onChange={handleAgeChange}
                    />
                  </ThemeProvider> */}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <SoftBox width="100%" height="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Course Description
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>

                      <Box sx={{ background: "#FFFFFF" }}>
                        <ReactQuill
                          // theme="snow"
                          // className="text-box"
                          value={jobDes || ""}
                          defaultValue=""
                          modules={modules}
                          formats={formats}
                          onChange={handleTexteditorChange}
                        />
                      </Box>
                    </SoftBox>
                  </Grid>
                </Grid>

                <SoftTypography fontWeight="medium" fontSize="20px" mb={1} mt={3}>
                  SPOC Details
                </SoftTypography>

                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography fontWeight="medium" fontSize="16px">
                          Name
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        placeholder="Enter name"
                        id="spoc_name"
                        name="spoc_name"
                        inputProps={{
                          maxLength: "90",
                        }}
                        onChange={jobpostformone.handleChange}
                        value={jobpostformone.values.spoc_name}
                      />
                      {jobpostformone.touched.spoc_name && jobpostformone.errors.spoc_name ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.spoc_name}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%">
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography fontWeight="medium" fontSize="16px">
                          Mobile Number
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftInput
                        type="tel"
                        placeholder="Enter mobile number"
                        inputProps={{
                          maxLength: "10",
                        }}
                        id="spoc_phone"
                        name="spoc_phone"
                        onChange={jobpostformone.handleChange}
                        value={jobpostformone.values.spoc_phone}
                      />
                      {jobpostformone.touched.spoc_phone && jobpostformone.errors.spoc_phone ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.spoc_phone}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <SoftBox width="100%" mb={2}>
                      <SoftBox display="flex" flexDirection="row">
                        <SoftTypography fontWeight="medium" fontSize="16px">
                          Email
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftInput
                        type="email"
                        placeholder="Enter email"
                        id="spoc_email"
                        name="spoc_email"
                        onChange={jobpostformone.handleChange}
                        value={jobpostformone.values.spoc_email}
                      />
                      {jobpostformone.touched.spoc_email && jobpostformone.errors.spoc_email ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostformone.errors.spoc_email}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} lg={12}></Grid> */}
              </SoftBox>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ p: 1, m: 1, pt: 0 }}>
                  <SoftBox
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      width: "120px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={jobpostformone.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Create Bucket
                    </SoftTypography>
                  </SoftBox>
                </Box>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>

        {/* loading modal */}
        <Modal
          show={loadingModal}
          onHide={() => {
            setLoadingModal(false);
          }}
          centered
          size="sm"
          closeButton
          contentClassName="modal-height"
        >
          <Modal.Body>
            <SoftBox display="flex" justifyContent="center" alignItems="center">
              <SoftBox display="flex" flexDirection="column" gap={3}>
                {/* <SoftBox display="flex" justifyContent="center">
                  <img src={uploadGif} width="150px" height="150px" />
                </SoftBox> */}
                <SoftTypography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Uploading.....
                </SoftTypography>
                <SoftTypography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Please wait as we are uploading the file
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* loading modal */}

        <JobseekerSkillEdit
          editadmin={editadmin}
          Seteditadmin={Seteditadmin}
          editdata={editdata}
        />
      </DashboardLayout>
    </>
  );
};

export default JobseekerSkill;
