import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import Form from "react-bootstrap/Form";
import moment from "moment/moment";
import "./editPost.css";
// import "../../EditModals/editStyle.css";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import { stateToHTML } from "draft-js-export-html";
import { Box } from "@mui/material";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const EditPosting = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [allstate, Setallstate] = useState(false);
  const [value, setValue] = useState(150000);
  const [education, Seteducation] = useState();
  const [stream, Setstream] = useState();
  const [degree, Setdergee] = useState();
  const [exp, Setexp] = useState();
  const [jobrole, Setjobrole] = useState();
  const [orgList, setOrgList] = useState([]);
  const [create, setCreate] = useState(false);
  const [jobTitle, setJobtitle] = useState([]);
  const [orgNameID, setOrgNameID] = useState("");
  const [salaryList, setSalaryList] = useState([]);
  const [jobType, SetjobType] = useState([]);
  const [newddEducation, setNewDDEducation] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [whatsappjd, setWhatsappjd] = useState("");
  const [addressChar, setAddressChar] = useState(200);
  const [roleId, setRoleId] = useState("SA");
  const [jobDes, setJobDes] = useState("");
  const reg_id = sessionStorage.getItem("register_id");
  const [age, setAge] = useState(26);



  useEffect(() => {
    setJobDes(props.editdata?.jobdesc || "")
  }, [props.editdata])


  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
      ['clean']
    ],
  }
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleTexteditorChange = (value) => {
    setJobDes(value)
  }

  const handleCheckboxChange = (event) => {
    Setallstate(event.target.checked);
  };

  const jobpostform = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: props.editdata?.org_name === "" || props.editdata?.org_name === null || props.editdata?.org_name === "nan" ? "" : props.editdata?.org_name,
      job_title: props.editdata?.Jobname === "" || props.editdata?.Jobname === "nan" || props.editdata?.Jobname === null ? "" : props.editdata.Jobname,
      job_type: props.editdata?.Jobtype === "" || props.editdata?.Jobtype === "nan" || props.editdata?.Jobtype === null ? "" : props.editdata.Jobtype,
      designation: props.editdata?.designation === "" || props.editdata?.designation === "nan" || props.editdata?.designation === null ? "" : props.editdata?.designation,
      applicantct: props.editdata?.reqirement === "" || props.editdata?.reqirement === "nan" || props.editdata?.reqirement === null ? "" : props.editdata?.reqirement,
      salary: props.editdata?.salary === "" || props.editdata?.salary === "nan" || props.editdata?.salary === null ? "" : props.editdata?.salary,
      jobpostdt: props.editdata?.date ? moment(props.editdata?.date).format("YYYY-MM-DD") : "",
      jobvaliddt: props.editdata?.jobvaliddt === null || props.editdata?.jobvaliddt === "nan" || props.editdata?.jobvaliddt === "" ? "" : moment(props.editdata?.jobvaliddt).format("YYYY-MM-DD HH:mm"),
      state: props.editdata?.state === "" || props.editdata?.state === "nan" || props.editdata?.state === null ? "" : props.editdata?.state,
      district: props.editdata?.district === "" || props.editdata?.district === "nan" || props.editdata?.district === null ? "" : props.editdata?.district,
      //industry: props.editdata?.industry ?? "",
      qualification: props.editdata?.qualification === "" || props.editdata?.qualification === "nan" || props.editdata?.qualification === null ? "" : props.editdata?.qualification,
      stream: props.editdata?.stream === "" || props.editdata?.stream === "nan" || props.editdata?.stream === null ? "" : props.editdata?.stream,
      specialization: props.editdata?.specialization === "" || props.editdata?.specialization === "nan" || props.editdata?.specialization === null ? "" : props.editdata?.specialization,
      workfromhome: "",
      headerimg: props.editdata?.headerimg === "" || props.editdata?.headerimg === "nan" || props.editdata?.headerimg === null ? "" : props.editdata?.headerimg,
      Experiance: props.editdata?.experience === "" || props.editdata?.experience === "nan" || props.editdata?.experience === null ? "" : props.editdata?.experience,
      age: props.editdata?.age === "" || props.editdata?.age === "nan" || props.editdata?.age === null ? "" : props.editdata?.age,
      industry: props.editdata?.industry === "" || props.editdata?.industry === "nan" || props.editdata?.industry === null ? "" : props.editdata?.industry,
      whatsapp_jd: props.editdata?.whatsapp_jd === "" || props.editdata?.whatsapp_jd === "nan" || props.editdata?.whatsapp_jd === null ? "" : props.editdata?.whatsapp_jd,
    },
    validationSchema: allstate === true ?
      Yup.object().shape({
        org_name: Yup.string().required("Please select organization Name"),
        job_title: Yup.string().required(" Please enter job title"),
        jobpostdt: Yup.string().required(" Please select job post date"),
        jobvaliddt: Yup.string().required(" Please Select Job Post valid till date"),
        age: Yup.string().required("Please enter age"),
        whatsapp_jd: Yup.string().required(" Please fill above field"),
      }) :
      Yup.object().shape({
        org_name: Yup.string().required("Please select organization Name"),
        job_title: Yup.string().required(" Please enter job title"),
        jobpostdt: Yup.string().required(" Please select job post date"),
        jobvaliddt: Yup.string().required(" Please Select Job Post valid till date"),
        state: Yup.string().required(" Please select state"),
        age: Yup.string().required(" Please enter age"),
        whatsapp_jd: Yup.string().required(" Please fill above field"),
      }),
    onSubmit: (values) => {
      const job = new FormData();
      job.append("updatedby", reg_id);
      job.append("jobid", props.editdata.key);
      job.append("org_name", values.org_name);
      job.append("org_id", props.editdata.orgid);
      job.append("job_title", values.job_title);
      job.append("job_type", values.job_type);
      job.append("designation", values.designation);
      job.append("applicantct", values.applicantct);
      job.append("salary", values.salary);
      job.append("jobpostdt", values.jobpostdt);
      job.append("jobvaliddt", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      job.append("state", !allstate ? values.state : "");
      job.append("district", !allstate ? values.district : "");
      job.append("qualification", values.qualification);
      job.append("stream", values.stream);
      job.append("specialization", values.specialization);
      job.append("workfromhome", values.workfromhome);
      job.append("jobdesc", jobDes);
      //job.append("createdby", values.createdby);
      job.append("creator_role", props.editdata?.creator_role ? props.editdata.creator_role : "SA");
      job.append("experience", values.Experiance);
      job.append("age", values.age);
      job.append("industry", values.industry);
      job.append("whatsapp_jd", values.whatsapp_jd);

      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
          // `${ngrokLink}/superadmin/candidatesearch/jobpost`,
          job
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Job posting has been updated successfully",
              showConfirmButton: false,
              timer: 3000,
              //   showDenyButton: true,
              // showCancelButton: true,
              //   confirmButtonText: "Add More",
              //   denyButtonText: `Search Candidate`,
            }).then(() => {
              props.Seteditadmin(false);
              props.handleOrgFunc();
              props.handleRoleFunc();
              setJobDes("")
              setAge(26);
              Setallstate(false)
              setWhatsappjd("");
            });
            // .then((result) => {
            //   /* Read more about isConfirmed, isDenied below */

            //   if (result.isConfirmed) {
            //     // Swal.fire("Saved!", "", "success");
            //     jobpostform.resetForm();
            //     setOrgNameID("");
            //     setSelectedState();
            //     // location.reload();
            //     // jobpostform.setFieldValue("org_name", "");
            //     // window.reload();
            //     // navigate("/searchbyposting/createposting");
            //     // console.log("hello");
            //   } else if (result.isDenied) {
            //     // Swal.fire("Changes are not saved", "", "info");
            //     sessionStorage.setItem("job_id", res.data["JobID"]);
            //     console.log(res.data["JobID"]);
            //     sessionStorage.setItem("searchdata_org_name", values.org_name);
            //     sessionStorage.setItem("searchdata_org_id", orgNameID);
            //     // sessionStorage.setItem("searchdata_designation", values.designation);
            //     // sessionStorage.setItem("searchdata_salary", values.salary);
            //     sessionStorage.setItem("searchdata_state", values.state);
            //     sessionStorage.setItem("searchdata_district", values.district);
            //     // sessionStorage.setItem("searchdata_industry", values.industry);
            //     // sessionStorage.setItem("searchdata_qualification", values.qualification);
            //     // sessionStorage.setItem("searchdata_experience", values.Experiance);
            //     // sessionStorage.setItem("searchdata_stream", values.stream);
            //     // sessionStorage.setItem("searchdata_specialization", values.specialization);
            //     navigate("/searchbyposting/createposting/recommendedPostList");
            //   }
            // });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Something went wrong",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
          Setallstate(false)
          setWhatsappjd("");
          setJobDes("")
        });
    },
  });

  useEffect(() => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobtitle`,
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`, {
        params: {
          // org_name: orgNameID,
          orgid: orgNameID,
          type: "dropdown",
        },
      })
      .then((res) => {
        console.log(res.data.org_title);
        setJobtitle(res.data["JobPost_List"]);
        // setJobtitle(
        //   res.data.org_title.map((ele) => ({
        //     label: ele.job_title,
        //     value: ele.job_title,
        //   }))
        // );
      });
  }, [orgNameID]);

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
        setNewDDEducation(res.data.map((ele) => ele.value));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        Setstream(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchjobtype = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/jobtype`)
      .then((res) => {
        SetjobType(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchDistricts = () => {
    const params = {
      state: selectedState === "" ? props.editdata?.state : selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOrgList = () => {
    const params = {
      partner: "samarth",
      type: "dropdown",
      created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
      // type: "detail",
    };
    const headers = {
      "access-control-allow-origin": "*",
      "Content-type": "application/json; charset=UTF-8",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_List"]);
        setOrgList(res.data["Organisation_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };

  const fetchIndustryFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry`)
      .then((res) => {
        console.log("industry", res.data);
        setIndustryOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchStateList();
    fetchjobrole();
    fetchexp();
    fetchdegree();
    fetchstream();
    fetcheducation();
    fetchOrgList();
    fetchSalary();
    fetchjobtype();
    fetchIndustryFunc();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState, props.editdata?.state]);

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };
  const TermSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#52af77",
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });
  const handleAgeChange = (event, newValue, activeThumb) => {
    setAge(newValue === "" ? 26 : newValue);
    jobpostform.setFieldValue("age", newValue === "" ? 26 : newValue)
  };

  return (
    <>
      <Modal
        show={props?.editadmin}
        onHide={() => {
          props?.Seteditadmin(false);
          Setallstate(false)
        }}
        onClose={() => Setallstate(false)}
        size="xl"
        centered
        style={{ marginTop: "52px" }}
      >
        <Modal.Header closeButton>Update Job Post Details</Modal.Header>
        <Modal.Body>
          <SoftBox mt={0}>
            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              p={0}
            >
              <SoftBox
                component="form"
                role="form"
                width="100%"
                onSubmit={jobpostform.handleSubmit}
              >
                <Grid container>
                  <SoftBox mt={2} width="100%" height="auto">
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox width="100%" height="auto" mb={3}>
                        <SoftBox component="form" role="form" width="750px">
                          <SoftBox
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    mb={1}
                                  >
                                    <SoftBox display="flex" flexDirection="row">
                                      <SoftTypography
                                        fontWeight="medium"
                                        fontSize="16px"
                                        lineHeight="20px"
                                      >
                                        Organization Name
                                      </SoftTypography>
                                      <SoftBox
                                        sx={{
                                          color: "red",
                                          position: "inherit",
                                          paddingLeft: "2px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <sup>*</sup>
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      flexDirection="row"
                                      gap="10px"
                                      onClick={() => {
                                        navigate("/jobprovider-registration");
                                      }}
                                    >
                                      <Tooltip
                                        key="1"
                                        title="Add a new organisation"
                                        placement="bottom"
                                      >
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15px"
                                            height="15px"
                                            viewBox="0 0 10 10"
                                            fill="none"
                                          >
                                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                                            <path
                                              d="M5 2V8M2 5H8"
                                              stroke="white"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </SoftBox>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      placeholder="Select organization name"
                                      options={orgList}
                                      value={{ label: jobpostform.values.org_name }}
                                      onChange={(e) => (
                                        jobpostform.setFieldValue("org_name", e.label),
                                        setOrgNameID(e.value)
                                      )}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.org_name && jobpostform.errors.org_name ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.org_name}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox
                                    width="100%"
                                    mb={1}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <SoftBox display="flex" flexDirection="row">
                                      <SoftTypography
                                        fontWeight="medium"
                                        fontSize="16px"
                                        lineHeight="20px"
                                        mb={1}
                                      >
                                        Job Title
                                      </SoftTypography>
                                      <SoftBox
                                        sx={{
                                          color: "red",
                                          position: "inherit",
                                          paddingLeft: "2px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <sup>*</sup>
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      flexDirection="row"
                                      gap="10px"
                                      onClick={() => {
                                        setCreate(!create);
                                      }}
                                    >
                                      <Tooltip
                                        key="2"
                                        title={
                                          !create ? `Create New Job Title` : `Select From Existing `
                                        }
                                        placement="bottom"
                                      >
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15px"
                                            height="15px"
                                            viewBox="0 0 10 10"
                                            fill="none"
                                          >
                                            <rect width="10" height="10" rx="5" fill="#3B5092" />
                                            <path
                                              d="M5 2V8M2 5H8"
                                              stroke="white"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </SoftBox>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                  {create ? (
                                    <SoftInput
                                      type="text"
                                      placeholder="Job title"
                                      name="job_title"
                                      id="job_title"
                                      onChange={jobpostform.handleChange}
                                      value={jobpostform.values.job_title}
                                    />
                                  ) : (
                                    <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                      <SoftSelect
                                        placeholder="Please enter job title"
                                        options={jobTitle}
                                        onChange={
                                          (e) => jobpostform.setFieldValue("job_title", e.label)
                                          // setOrgNameID(e.value)
                                        }
                                        value={{ label: jobpostform.values.job_title }}
                                      />
                                    </SoftBox>
                                  )}
                                  {jobpostform.touched.job_title && jobpostform.errors.job_title ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.job_title}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Job Type
                                  </SoftTypography>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      placeholder="Select job type"
                                      options={jobType}
                                      onChange={(e) => {
                                        jobpostform.setFieldValue("job_type", e.label);
                                      }}
                                      value={{ label: jobpostform.values.job_type }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.job_type && jobpostform.errors.job_type ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.job_type}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Date of Posting
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="date"
                                    placeholder=""
                                    name="jobpostdt"
                                    id="jobpostdt"
                                    onChange={jobpostform.handleChange}
                                    value={jobpostform.values.jobpostdt}
                                  />
                                  {jobpostform.touched.jobpostdt && jobpostform.errors.jobpostdt ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.jobpostdt}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    No.of Professional
                                  </SoftTypography>
                                  <SoftInput
                                    type="number"
                                    placeholder=""
                                    name="applicantct"
                                    id="applicantct"
                                    onChange={jobpostform.handleChange}
                                    value={jobpostform.values.applicantct}
                                  />
                                  {jobpostform.touched.applicantct &&
                                    jobpostform.errors.applicantct ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.applicantct}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Role
                                  </SoftTypography>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      placeholder="Select role"
                                      options={jobrole}
                                      onChange={(e) => {
                                        jobpostform.setFieldValue("designation", e.label);
                                      }}
                                      value={{ label: jobpostform.values.designation }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.designation &&
                                    jobpostform.errors.designation ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.designation}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>

                          <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Age: {age}
                                    </SoftTypography>

                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                    {/* <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                    >
                                      : {`${age[0]} - ${age[1]}`}
                                    </SoftTypography> */}
                                  </SoftBox>
                                  {/*
                                  <SoftInput
                                    type="number"
                                    placeholder=""
                                    name="age"
                                    id="age"
                                    onChange={jobpostform.handleChange}
                                    value={jobpostform.values.age}
                                  />
                                 */}
                                  <ThemeProvider theme={TermSlider}>
                                    <Slider
                                      name="termValue"
                                      // getAriaLabel={() => "Minimum distance shift"}
                                      // disableSwap
                                      valueLabelDisplay="auto"
                                      aria-label="Default"
                                      min={18}
                                      max={65}
                                      value={age}
                                      defaultValue={26}
                                      onChange={handleAgeChange}
                                    />
                                  </ThemeProvider>
                                  {jobpostform.touched.age && jobpostform.errors.age ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.age}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              {/*
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Industry
                                  </SoftTypography>
                                  <SoftSelect
                                      placeholder="Select role"
                                      options={industryOptions}
                                      onChange={(e) => {
                                        jobpostform.setFieldValue("industry", e.label);
                                      }}
                                      value={{ label: jobpostform.values.industry }}
                                    />
                                  {jobpostform.touched.industry && jobpostform.errors.industry ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.industry}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              */}

                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      Valid Till
                                    </SoftTypography>
                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="datetime-local"
                                    placeholder=""
                                    name="jobvaliddt"
                                    id="jobvaliddt"
                                    onChange={jobpostform.handleChange}
                                    value={jobpostform.values.jobvaliddt}
                                  />
                                  {jobpostform.touched.jobvaliddt &&
                                    jobpostform.errors.jobvaliddt ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.jobvaliddt}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>

                          <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Expected Salary
                                  </SoftTypography>
                                  {/* <SoftBox display="flex" flexDirection="column">
                              {
                              
                              <Range
                                step={1}
                                defaultValue={150000}
                                min={100000}
                                max={300000}
                                onChange={(value) => {
                                  console.log("new value", value);
                                  setValue(value);
                                  jobpostform.setFieldValue("salary", value);
                                }}
                              />
                              <SoftTypography fontWeight="medium" fontSize="15px" lineHeight="15px">
                                Salary : {value}
                              </SoftTypography>
                             
                              {jobpostform.touched.salary && jobpostform.errors.salary ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {jobpostform.errors.salary}{" "}
                                </span>
                              ) : null}
                            </SoftBox> */}
                                  {/* <SoftBox display="flex" flexDirection="row" gap="20px"> */}
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={salaryList}
                                      placeholder="Select salary range"
                                      onChange={(e) => {
                                        jobpostform.setFieldValue("salary", e.label);
                                      }}
                                      value={{ label: jobpostform.values.salary }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.salary && jobpostform.errors.salary ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.salary}{" "}
                                    </span>
                                  ) : null}
                                  {/* <Card><SoftSelect options={reporting} /></Card>  */}
                                  {/* <input type="range" width="100%" min={25000} max={90000}/> */}
                                  {/* </SoftBox> */}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={1}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                  <Form.Check
                                    style={{ padding: '8px' }}
                                    type="checkbox"
                                    checked={allstate}
                                    onChange={handleCheckboxChange}
                                  />
                                  <span style={{ fontSize: '16px', color: '#000000', fontWeight: 500 }}>Pan India</span>
                                </label>

                              </Grid>
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox>
                        {/* <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                          Contact Details
                        </SoftTypography> */}
                        <SoftBox component="form" role="form" width="750px">
                          {!allstate && (
                            <SoftBox
                              // display="flex"
                              // flexDirection="row"
                              // justifyContent="space-between"
                              mb={3}
                            >
                              <Grid container spacing={8}>
                                <Grid item xs={12} lg={6} container spacing={3}>
                                  <SoftBox width="100%" pl={2}>
                                    <SoftBox display="flex" flexDirection="row">
                                      <SoftTypography
                                        fontWeight="medium"
                                        fontSize="16px"
                                        lineHeight="20px"
                                        mb={1}
                                      >
                                        State
                                      </SoftTypography>
                                      <SoftBox
                                        sx={{
                                          color: "red",
                                          position: "inherit",
                                          paddingLeft: "2px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <sup>*</sup>
                                      </SoftBox>
                                    </SoftBox>
                                    <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                      <SoftSelect
                                        options={state}
                                        onChange={(currentTarget) => (
                                          setSelectedState(currentTarget.value),
                                          !allstate
                                            ? jobpostform.setFieldValue(
                                              "state",
                                              currentTarget.value
                                            )
                                            : ""
                                        )}
                                        value={{ label: jobpostform.values.state }}
                                      />
                                    </SoftBox>
                                    {jobpostform.touched.state && jobpostform.errors.state ? (
                                      <span style={{ color: "red", fontSize: "12px" }}>
                                        {jobpostform.errors.state}{" "}
                                      </span>
                                    ) : null}
                                  </SoftBox>
                                </Grid>
                                <Grid item xs={12} lg={6} container spacing={3}>
                                  <SoftBox width="100%" pl={2}>
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      District
                                    </SoftTypography>
                                    <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                      <SoftSelect
                                        options={district}
                                        onChange={(e) =>
                                          !allstate
                                            ? jobpostform.setFieldValue("district", e.value)
                                            : ""
                                        }
                                        value={{ label: jobpostform.values.district }}
                                      />
                                    </SoftBox>
                                    {jobpostform.touched.district && jobpostform.errors.district ? (
                                      <span style={{ color: "red", fontSize: "12px" }}>
                                        {jobpostform.errors.district}{" "}
                                      </span>
                                    ) : null}
                                  </SoftBox>
                                </Grid>
                              </Grid>
                            </SoftBox>
                          )}
                          <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={3}
                          >
                            {" "}
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Highest Education
                                  </SoftTypography>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={education}
                                      onChange={(e) =>
                                        jobpostform.setFieldValue("qualification", e.value)
                                      }
                                      value={{ label: jobpostform.values.qualification }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.qualification &&
                                    jobpostform.errors.qualification ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.qualification}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Stream
                                  </SoftTypography>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={stream}
                                      onChange={(e) => jobpostform.setFieldValue("stream", e.value)}
                                      value={{ label: jobpostform.values.stream }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.stream && jobpostform.errors.stream ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.stream}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={3}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Specialization
                                  </SoftTypography>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={degree}
                                      onChange={(e) =>
                                        jobpostform.setFieldValue("specialization", e.value)
                                      }
                                      value={{ label: jobpostform.values.specialization }}
                                    />
                                  </SoftBox>
                                  {jobpostform.touched.qualification &&
                                    jobpostform.errors.qualification ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.qualification}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Experience
                                  </SoftTypography>
                                  <SoftBox sx={{ background: "#fff", borderRadius: "0.5rem" }}>
                                    <SoftSelect
                                      options={exp}
                                      onChange={(e) =>
                                        jobpostform.setFieldValue("Experiance", e.value)
                                      }
                                      value={{ label: jobpostform.values.Experiance }}
                                    />
                                  </SoftBox>

                                  {jobpostform.touched.Experiance &&
                                    jobpostform.errors.Experiance ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.Experiance}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>

                          <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={1}
                          >
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox
                                  width="100%"
                                  pl={2}
                                  display="flex"
                                  flexDirection="row"
                                  // alignItems="center"
                                  gap="30px"
                                // justifyContent=""
                                >
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Work from home availability
                                  </SoftTypography>
                                  <SoftBox
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap="20px"
                                  >
                                    <SoftBox display="flex" flexDirection="row" gap="5px">
                                      <input
                                        type="radio"
                                        value="Yes"
                                        name="keyword"
                                        style={{ cursor: "pointer" }}
                                        defaultChecked={props?.editdata?.workfromhome === "Yes" ? true : false}
                                        onChange={(e) =>
                                          jobpostform.setFieldValue("workfromhome", e.target.value)
                                        }
                                      />

                                      <SoftTypography
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          fontWeight: "small",
                                          fontSize: "15px",
                                          lineHeight: "19px",
                                        }}
                                      >
                                        Yes
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display="flex" flexDirection="row" gap="5px">
                                      <input
                                        type="radio"
                                        value="No"
                                        name="keyword"
                                        style={{ cursor: "pointer" }}
                                        defaultChecked={props?.editdata?.workfromhome === "No" ? true : false}
                                        onChange={(e) => (
                                          jobpostform.setFieldValue("workfromhome", e.target.value)
                                        )}
                                      />

                                      <SoftTypography
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          fontWeight: "small",
                                          fontSize: "15px",
                                          lineHeight: "19px",
                                        }}
                                      >
                                        No
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox
                                      display="flex"
                                      flexDirection="row"
                                      gap="5px"
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <input
                                        type="radio"
                                        value="Hybrid"
                                        name="keyword"
                                        defaultChecked={props?.editdata?.workfromhome === "Hybrid" ? true : false}
                                        onChange={(e) => (
                                          jobpostform.setFieldValue("workfromhome", e.target.value)
                                        )}
                                      />
                                      <SoftTypography
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          fontWeight: "small",
                                          fontSize: "15px",
                                          lineHeight: "19px",
                                          // cursor: "pointer",
                                        }}
                                      >
                                        Hybrid
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                                <SoftBox pl={2}>
                                  {jobpostform.touched.workfromhome &&
                                    jobpostform.errors.workfromhome ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.workfromhome}{" "}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={4}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={12} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex">
                                    <SoftTypography
                                      fontWeight="medium"
                                      fontSize="16px"
                                      lineHeight="20px"
                                      mb={1}
                                    >
                                      WhatsApp Job Description
                                    </SoftTypography>

                                    <SoftBox
                                      sx={{
                                        color: "red",
                                        position: "inherit",
                                        paddingLeft: "2px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    multiline
                                    rows={5}
                                    id="whatsapp_jd"
                                    name="whatsapp_jd"
                                    inputProps={{
                                      maxLength: "200",
                                    }}
                                    onChange={jobpostform.handleChange}
                                    value={jobpostform.values.whatsapp_jd}
                                  />
                                  <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                    {jobpostform.values.whatsapp_jd?.length}/{addressChar}
                                  </SoftTypography>

                                  {jobpostform.touched.whatsapp_jd &&
                                    jobpostform.errors.whatsapp_jd ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {jobpostform.errors.whatsapp_jd}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} lg={12}>
                                <SoftBox width="100%" height="100%" pl={0}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="16px"
                                    lineHeight="20px"
                                    mb={1}
                                  >
                                    Job Description
                                  </SoftTypography>
                                  {/* <Card> */}
                                  {/* <textareajobpostform
                                rows={5}
                                style={{ borderColor: "#fff", padding: "10px", fontSize: "14px" }}
                                name="jobdesc"
                                id="jobdesc"
                                onChange={jobpostform.handleChange}
                                value={jobpostform.values.jobdesc}
                              ></textarea> */}
                                  <ReactQuill
                                    value={jobDes || ''}
                                    defaultValue=''
                                    modules={modules}
                                    formats={formats}
                                    onChange={handleTexteditorChange}
                                  />
                                  <br />

                                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Box p={1}>
                                      <SoftBox
                                        pl={2}
                                        sx={{
                                          height: "45px",
                                          backgroundColor: "#3B5092",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                        }}
                                        p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                        width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                        onClick={jobpostform.handleSubmit}
                                      >
                                        <SoftTypography
                                          display="flex"
                                          justifyContent="center"
                                          sx={{
                                            color: "#fff",
                                            fontSize: "15px",
                                            fontWeight: "medium",
                                            lineHeight: "19px",
                                          }}
                                        >
                                          Submit
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>

                                    <Box p={1}>
                                      <SoftBox
                                        pl={2}
                                        sx={{
                                          height: "45px",
                                          backgroundColor: "#fff",
                                          borderRadius: "5px",
                                          border: "1px solid #3B5092",
                                          cursor: "pointer",
                                        }}
                                        p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                        width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                        onClick={() => {
                                          props.Seteditadmin(false);
                                          jobpostform.resetForm();
                                        }}
                                      >
                                        <SoftTypography
                                          display="flex"
                                          justifyContent="center"
                                          sx={{
                                            color: "#3B5092",
                                            fontSize: "15px",
                                            fontWeight: "medium",
                                            lineHeight: "19px",
                                          }}
                                        >
                                          Cancel
                                        </SoftTypography>
                                      </SoftBox>
                                    </Box>
                                  </Box>

                                  {/*
                                  <SoftInput
                                    type="text"
                                    placeholder="Type here..."
                                    multiline
                                    rows={5}
                                    //onChange={jobpostform.handleChange}
                                    //value={jobpostform.values.jobdesc}
                                    onChange={(e) => setJobDes(e.target.value)}
                                    value={jobDes}
                                  />
                                  *}
                                  {/* </Card> 
                            {jobpostform.touched.jobdesc && jobpostform.errors.jobdesc ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {jobpostform.errors.jobdesc}{" "}
                              </span>
                            ) : null}
                            */}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Modal.Body>
      </Modal>
    </>
  );
};
EditPosting.propTypes = {
  editadmin: PropTypes.bool.isRequired,
  Seteditadmin: PropTypes.func.isRequired,
  editdata: PropTypes.object.isRequired,
  handleOrgFunc: PropTypes.func.isRequired,
  handleRoleFunc: PropTypes.func.isRequired,
  //   fetchadminList: PropTypes.func.isRequired,
};
export default EditPosting;
