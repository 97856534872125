import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import { Grid, Card, Typography, Box, Switch, Skeleton , Tooltip as TooltipMui, Menu } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import moment from "moment";
import nodata from "../../assets/images/browser.png";


import Mapchart from './MapChart1'
import axios from "axios";
import { useState } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { SelectChangeEvent } from "@mui/material/Select";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import A1 from '../../assets/images/Group 1000003388.png';
import A2 from '../../assets/images/kpi2.png';
import A3 from '../../assets/images/kpi3.png';
import A4 from '../../assets/images/kpi4.png';
import A5 from '../../assets/images/kpi5.png';
import A6 from '../../assets/images/kpi6.png';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Sector,ComposedChart } from 'recharts';
import SquareIcon from '@mui/icons-material/Square';
import Select from "react-select";
import ReactApexChart from 'react-apexcharts'
import { SBox1, SBox2, SBox3, SBox4, SBox5, SGHeading, SGSubHeading, SGTitles, SGToggles, SGraphSubHeading, SGridBox, SHeading, SKPICount, SKPIHeading, SKPISubHeading1, SKPISubHeading2, SUnderlineBox, SVerticalBox } from "./Style";
import DatePicker from "rsuite/DatePicker";
import isAfter from "date-fns/isAfter";
import "./style.css";
import "rsuite/dist/rsuite.min.css";
import "../../components/SoftWeekPicker/index.css";
const SuperAdminOverview = () => {
  const [toggle1, setToggle1] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orgList, setOrgList] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [orgNameID, setOrgNameID] = useState("All");
  const [jobLabel1, setJobLabel1] = useState("");
  const [orgLabel2, setOrgLabel2] = useState("");
  const [jobList, setJobList] = useState([]);
  const [jobName, setJobName] = useState("");
  const [value, setValue] = useState(0);
  const [check, setCheck] = useState(false);
  const [clear, setClear] = useState(false);
  const [roleType, setRoleType] = useState("qualification");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [data, setData] = useState([
   
  ]);
  const [yearList, setYearList] = useState([]);
  const [selectYear, setSelectYear] = useState("2023");
  const [stateSelected, setStateSelected] = useState([]);
  const [statesSelected, setStatesSelected] = useState([]);
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [datesArr, setDatesArr] = useState([]);
  const current = new Date();
  // let currentStart = moment(current).subtract(1, "weeks").startOf("isoWeek").toDate(); // prev week start date
  let currentStart = moment(current).startOf("isoWeek").toDate(); // curr week start date
  currentStart = currentStart.toLocaleDateString("en-CA");
  // currentStart="2023-10-02"
  // let currentEnd = moment(current).subtract(1, "weeks").endOf("isoWeek").toDate(); // prev week end date
  let currentEnd = moment(current).endOf("isoWeek").toDate(); // curr week end date
  currentEnd = currentEnd.toLocaleDateString("en-CA");
  //  currentEnd="2023-10-08"

  const [week, setWeek] = useState({

    date: moment(new Date()).startOf("isoWeek").toDate(), // prev week monday
    currentStart: currentStart,
    currentEnd: currentEnd,
    dateFrom: moment(new Date()).startOf("isoWeek").toDate(),
    dateTo: moment(new Date()).endOf("isoWeek").toDate(),
    WeekNumber: "",
  });

  console.log(stateSelected?.length, statesSelected?.length, check, "kite");

  const [attWeek, setAttWeek] = useState({
    target_start_date: "2023-08-01",
    target_end_date: "2023-08-31",
    current_start_date: currentStart,
    current_end_date: currentEnd,
  });

  const getDates = (start, end) => {
    let dates = [];
    let current = new Date(start);
    while (current <= end) {
      dates.push(moment(current).format("YYYY-MM-DD"));
      current.setDate(current.getDate() + 1);
    }
    setDatesArr(dates);
  };

  const onChange = (date) => {
    //console.log(date)
    const WeekNumber = moment(date).isoWeek();
    let dateFrom = moment(date).startOf("isoWeek").toDate();
    dateFrom = dateFrom.toLocaleDateString("en-CA");
    let dateTo = moment(date).endOf("isoWeek").toDate();
    console.log("dateTo", dateTo, currentStart);
    dateTo = dateTo.toLocaleDateString("en-CA");
    console.log("dateTo", dateTo);
    setWeek({
      date: date,
      currentStart: currentStart,
      currentEnd: currentEnd,
      dateFrom:
        dateFrom !== "Invalid Date" ? dateFrom : moment(new Date()).startOf("isoWeek").toDate(),
      dateTo: dateTo !== "Invalid Date" ? dateTo : moment(new Date()).endOf("isoWeek").toDate(),
      WeekNumber: WeekNumber,
    });
    getDates(new Date(dateFrom), new Date(dateTo));
  };

  const YearFunc = () => {
    let arr = [];
    for (let startOf = 2024; startOf >= 2000; startOf--) {
      arr.unshift({ label: startOf.toString(), value: startOf.toString() });
    }
    setYearList(arr);
  };

  useEffect(() => {
    getAttritionDates(week);
  }, [week]);

  useEffect(() => {
    getDates(
      week?.dateFrom === ""
        ? new Date(moment(new Date()).startOf("isoWeek").toDate())
        : new Date(week?.dateFrom),
      week?.dateTo === ""
        ? new Date(moment(new Date()).endOf("isoWeek").toDate())
        : new Date(week?.dateTo)
    );
  }, [week]);

  console.log("week obj", week);


  const handleFormChange12 = (event, index, state, setState) => {
    setCheck(false);
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    setState(data);
    console.log("handleform", data);
    setStatesSelected(stateSelected.filter((ele) => ele.isExportable === true));
  };


  const getAttritionDates = (week) => {
    setAttWeek({
      target_start_date: week.dateFrom,
      target_end_date: week.dateTo,
      current_start_date: week.currentStart,
      current_end_date: week.currentEnd,
    });
  };

  const distributionDD = [
    { label: "Age", value: "age" },
    { label: "Gender", value: "gender" },
    { label: "Highest Qualification", value: "qualification" },
    { label: "Job Role", value: "role" },
  ];

  const [data6, setData6] = useState([
   
  ]);



  const [data4, setData4] = useState([
   
  ]);

  const [data3, setData3] = useState([
    
  ]);

  const [data1, setData1] = useState([]);
  const [data1A, setData1A] = useState([]);
  const [data10, setData10] = useState([]);

  const [KPIObj, setKPIObj] = useState({
    super_admin_count: "",
    admin_count: "",
    channel_partner_count: "",
    // job_center_count: "",
    // center_head_count: "",
    // center_employee_count: "",
    job_provider_count: "",
    job_seeker_count: "",
    job_fair_count: "",
    counsellor_count: "",
  });
  const [kpiObj2, setKpiObj2] = useState({
    total_placed: "",
    total_posts: "",
    total_assigned_cps: "",
    recent_admins: "",
    recent_counsellor: "",
    upcoming_jf: "",
  });

  const [kpiArr, setKpiArr] = useState([]);

  

  const handleChange = (event, newValue) => {
    console.log("changes:", event);
    console.log("changes2:", newValue);
    setValue(newValue);
    setRoleType(
      newValue === 0
        ? "qualification"
        : newValue === 1
        ? "gender"
        : newValue === 2
        ? "age"
        : "qualification"
    );
  };

  const fetchOrgList = () => {
    const params = {
      partner: "samarth",
      type: "dropdown",
      created_by: sessionStorage.getItem("register_id")
      ? sessionStorage.getItem("register_id")
      : "",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_List"]);
        setOrgList([{ label: "All", value: "All" }].concat(res.data["Organisation_List"]));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchOrgList();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`, {
        params: {
          orgid: orgName,
          type: "dropdown",
          admin_id: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        },
      })
      .then((res) => {
        console.log(res.data.org_title);
        setJobList([{ label: "All", value: "" }].concat(res.data["JobPost_List"]));
      });
  }, [orgName]);

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: "12.5px",
      width: "166px",
    }),
    option: (base) => ({
      ...base,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const option1 = [{ label: "All", value: "All" }];

  const option2 = [{ label: "Mar-Apr", value: "Mar-Apr" }];

  //Pyramid
  const Series = {
    series: [
      {
        name: data6.map((v, i) => v.msg),
        // name: "Steps",
        data: data6.map((v) => v.Candidates),
      },
    ],
  };

  //Pyramid
  const option3 = {
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        fontFamily: "Inter",
        fontSize: "10px",
      },
      tooltip: {
        enabled: true,
      
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          {
            console.log("hover:", dataPointIndex);
          }
          return dataPointIndex !== 0
            ? "<div style='margin:4px'>" +
                "<ul>" +
                "<li>" +
                "<div >" +
                `Count : ${w.globals.series[0][dataPointIndex]}` +
                "</div>" +
                "</li>" +
                `${
                  // w.globals.seriesNames[0][dataPointIndex] !== "" &&
                  "<li>" +
                  "<div>" +
                  // series[seriesIndex][dataPointIndex] +

                  // dataPointIndex !== 0 &&
                  `${w.globals.seriesNames[0][dataPointIndex]}` +
                  "</div>" +
                  "</li>"
                }` +
                "</ul>" +
                "</div>"
            : "<div style={{margin:'8px',display:'flex',alignItems:'center'}}>" +
                "<ul>" +
                // "<li>" +
                // "<div >" +
                // `Count : ${w.globals.series[0][dataPointIndex]}` +
                // "</div>" +
                // "</li>" +
                `${
                  // w.globals.seriesNames[0][dataPointIndex] !== "" &&
                  "<li>" +
                  "<div>" +
                  // series[seriesIndex][dataPointIndex] +

                  // dataPointIndex !== 0 &&
                  `${w.globals.seriesNames[0][dataPointIndex]}` +
                  "</div>" +
                  "</li>"
                }` +
                "</ul>" +
                "</div>";
        },
       
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          barHeight: "80%",
          isFunnel: true,
        },
      },
      colors: ["#88ABAE", "#F94144", "#2D9CDB", "#F8961E", "#C69774", "#90BE6D", "#F3722C"],
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return val;
        },
        dropShadow: {
          enabled: false,
        },
      },
      xaxis: {
        categories: data6.map((v) => v.name),
        percentage: data6.map((v) => v.percent),
      },
      legend: {
        show: false,
      },
    },
  };


  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    console.log("labels x:", x);
    console.log("labels cx:", cx);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontFamily: "Inter", fontSize: "12px" }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const FetchGraph1 = async () => {
    setLoading4(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/state/users
        `,
        {
          state: sessionStorage.getItem("state")
          ? sessionStorage.getItem("state")
          : "",
          admin_id: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      );
      setLoading4(false);
      const data = await res.data;
      setStateSelected(
        data?.data?.map((v) => {
          return {
            label: v.district,
            isExportable: true,
            name: v.district,
            count: v.count,
          };
        })
      );
      setStatesSelected(
        data?.data?.map((v) => {
          return {
            label: v.district,
            isExportable: true,
            name: v.district,
            count: v.count,
          };
        })
      );
    } catch (err) {
      console.log(err);
      setLoading4(false);
    }
  };

  useEffect(() => {
    FetchGraph1();
  }, []);

  useEffect(() => {
    //setStatesSelected(stateSelected)
    check && FetchGraph1();
  }, [check]);

  const FetchGraph2 = async () => {
    setLoading5(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/mis/type`,
        {
          type: roleType ? roleType : "qualification",
          admin_id: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      );
      setLoading5(false);
      const data = await res.data;

      if (roleType !== "role") {
        setData4(
          data?.data?.map((v, i) => {
            return {
              name: v.name
                ? v.name
                    ?.split(" ")
                    ?.map((v) => v?.charAt(0)?.toUpperCase() + v?.slice(1))
                    ?.join(" ")
                : "",
              role: v.name
                ? v.name
                    ?.split(" ")
                    ?.map((v) => v?.charAt(0)?.toUpperCase() + v?.slice(1))
                    ?.join(" ")
                : "",
              value: v.count,
              color: v.color,
              // percent: v.percent.toFixed(2),
            };
          })
        );
        setData3([]);
      } else {
        setData3(
          data?.data?.map((v) => {
            return {
              name: v.name,
              "Total Candidates": v.count,
            };
          })
        );
        setData4([]);
      }
    } catch (err) {
      setLoading5(false);
      console.log(err);
    }
  };

  const FetchGraph3 = async () => {
    setLoading3(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/mis/job/shared`,
        {
          org_id: orgNameID ? (orgNameID === "All" ? "" : orgNameID) : "",
          admin_id: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      );
      setLoading3(false);

      const data = await res.data;
      if (orgNameID === "All") {
        const overalldata = data?.data?.map((v) => {
          return {
            name: v.name,
            "Total Requirements": v.requested,
            "Candidates Shortlisted": v.shortlisted,
            "Candidates Shared": v.shared,
          };
        });

        setData1A([
          {
            name: "Total Requirements",
            count: overalldata?.length > 0 ? overalldata[0]["Total Requirements"] : 0,
            fill: "#90BE6D",
          },
          {
            name: "Candidates Shared",
            count: overalldata?.length > 0 ? overalldata[0]["Candidates Shared"] : 0,
            fill: "#F9C74F",
          },
          {
            name: "Candidates Shortlisted",
            count: overalldata?.length > 0 ? overalldata[0]["Candidates Shortlisted"] : 0,
            fill: "#F94144",
          },
        ]);
      } else {
        setData1(
          data?.data?.map((v) => {
            return {
              name: v.name,
              "Total Requirements": v.requested,
              "Candidates Shortlisted": v.shortlisted,
              "Candidates Shared": v.shared,
            };
          })
        );
      }
    } catch (err) {
      setLoading3(false);
      console.log(err);
    }
  };

  const FetchGraph4 = async () => {
    setLoading2(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/mis/details/all`,
        {
          org_id: orgName ? orgName : "",
          jobid: jobName ? jobName : "",
          admin_id: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      );

      const data = await res.data;
      setLoading2(false);
      setData6(data?.data);
    } catch (err) {
      console.log(err);
      setLoading2(false);
    }
  };

  useEffect(() => {
    FetchGraph2();
  }, [roleType]);

  useEffect(() => {
    FetchGraph3();
  }, [orgNameID]);

  useEffect(() => {
    FetchGraph4();
  }, [orgName, jobName]);

  const FetchGraph5 = async () => {
    setLoading6(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/date/users`,
        
        {
          start_date: datesArr?.length === 7 ? datesArr[0] : currentStart,
          end_date: datesArr?.length === 7 ? datesArr[6] : currentEnd,
          admin_id: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      );
      setLoading6(false);
      const data = await res.data;
      setData10(
        data?.data?.map((v, i) => {
          return {
            name: v.date,
            "Channel Partner": v["Channel Partners"],
            "Job Provider": v["Job Providers"],
          };
        })
      );
    } catch (err) {
      console.log(err);
      setLoading6(false);
    }
  };

  const FetchGraph6 = async () => {
    setLoading1(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/month/data`,
     
        { year: selectYear ,
     
                admin_id: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",}
      )
      .then(
        (res) => (
          setLoading1(false),
          setData(
            res.data.data?.map((item) => ({
              ...item,
              name:
                item?.name === "June" || item?.name === "July" || item?.name === "September"
                  ? item?.name?.substring(0, 4)
                  : item?.name.substring(0, 3),
            }))
          )
        )
      )
      .catch((err) => {
        console.log("datasource chart:", err.response);
        setLoading1(false);
      });
  };

  console.log(data ,'datasource')

  useEffect(() => {
    FetchGraph5();
  }, [datesArr]);

  useEffect(() => {
    FetchGraph6();
  }, [selectYear]);

  const OverallKPIFunc = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/dashboard/overall/kpi`,
      {params:{
        admin_id: sessionStorage.getItem("register_id")
        ? sessionStorage.getItem("register_id")
        : "",}
      })  
      .then(
        (res) => (
          setLoading(false),
          setKPIObj({
            super_admin_count: res.data["counter_data"].super_admin_count,
            admin_count: res.data["counter_data"].admin_count,
            channel_partner_count: res.data["counter_data"].channel_partner_count,
          
            counsellor_count: res.data["counter_data"].counsellor_count,
            job_fair_count: res.data["counter_data"].job_fair_count,
            job_provider_count: res.data["counter_data"].job_provider_count,
            job_seeker_count: res.data["counter_data"].job_seeker_count,
          }),
          setKpiArr([
            {
              heading: "Total Candidates",
              subheading: "Total Placed :",
              count1: res.data["counter_data"].job_seeker_count
                ? res.data["counter_data"].job_seeker_count
                : 0,
              count2: res.data["counter_data"].placed_count
                ? res.data["counter_data"].placed_count
                : 0,
              img: A1,
            },
            {
              heading: "Total Job Provider",
              subheading: "Total Job Post :",
              count1: res.data["counter_data"].job_provider_count
                ? res.data["counter_data"].job_provider_count
                : 0,
              count2: res.data["counter_data"].job_post_count
                ? res.data["counter_data"].job_post_count
                : 0,
              img: A2,
            },
            {
              heading: "Total Channel Partner",
              subheading: "Total Assigned :",
              count1: res.data["counter_data"].channel_partner_count
                ? res.data["counter_data"].channel_partner_count
                : 0,
              count2: res.data["counter_data"].cp_count_with_job_assignment
                ? res.data["counter_data"].cp_count_with_job_assignment
                : 0,
              img: A3,
            },
            {
              heading: "Total Admin Registered",
              subheading: "Recently Added :",
              count1: res.data["counter_data"].admin_count
                ? res.data["counter_data"].admin_count
                : 0,
              count2: res.data["counter_data"].new_adm_count
                ? res.data["counter_data"].new_adm_count
                : 0,
              img: A4,
            },
            {
              heading: "Total Job Fair",
              subheading: "Upcoming Job Fair :",
              count1: res.data["counter_data"].job_fair_count
                ? res.data["counter_data"].job_fair_count
                : 0,
              count2: res.data["counter_data"].upcoming_jf_count
                ? res.data["counter_data"].upcoming_jf_count
                : 0,
              img: A5,
            },
            {
              heading: "Total Counsellor",
              subheading: "Recently Added :",
              count1: res.data["counter_data"].counsellor_count
                ? res.data["counter_data"].counsellor_count
                : 0,
              count2: res.data["counter_data"].recently_added_counsellors
                ? res.data["counter_data"].recently_added_counsellors
                : 0,
              img: A6,
            },
          ])
        )
      )
      .catch((err) => {
        setLoading(false);
        setKpiArr([
          {
            heading: "Total Candidates",
            subheading: "Total Placed :",
            count1: 0,
            count2: 0,
            img: A1,
          },
          {
            heading: "Total Job Provider",
            subheading: "Total Job Post :",
            count1: 0,
            count2: 0,
            img: A2,
          },
          {
            heading: "Total Channel Partner",
            subheading: "Total Assigned :",
            count1: 0,
            count2: 0,
            img: A3,
          },
          {
            heading: "Total Admin Registered",
            subheading: "Recently Added :",
            count1: 0,
            count2: 0,
            img: A4,
          },
          {
            heading: "Total Job Fair",
            subheading: "Upcoming Job Fair :",
            count1: 0,
            count2: 0,
            img: A5,
          },
          {
            heading: "Total Counsellor",
            subheading: "Recently Added :",
            count1: 0,
            count2: 0,
            img: A6,
          },
        ]);
      });
  };
  useEffect(() => {
    OverallKPIFunc();
    YearFunc();
  }, []);

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SHeading>Dashboard</SHeading>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          {loading ? (
            <>
              {Array.from(Array(6).keys()).map((v, i) => {
                return (
                  <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                    <SBox1>
                      <SBox2>
                        <SVerticalBox>&nbsp;</SVerticalBox>

                        <SBox3>
                          <Box>
                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              sx={{ height: "16px", width: "120px", m: 0.5, mb: 1.8 }}
                            />

                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              sx={{ height: "24px", width: "90px", m: 0.5, mb: 1.4 }}
                            />

                            <SBox4>
                              <Skeleton
                                variant="rounded"
                                animation="wave"
                                sx={{
                                  height: "30px",
                                  width: "140px",
                                  background: "rgba(112, 186, 191, 0.17)",
                                  borderRadius: "7px",
                                }}
                              />
                            </SBox4>
                          </Box>

                          <Box>
                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              sx={{ height: "64.909px", width: "68px" }}
                            />
                          </Box>
                        </SBox3>
                      </SBox2>
                    </SBox1>
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              {kpiArr.map((v, i) => {
                return (
                  <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                    <SBox1>
                      <SBox2>
                        <SVerticalBox>&nbsp;</SVerticalBox>

                        <SBox3>
                          <Box>
                            <SKPIHeading>{v.heading}</SKPIHeading>

                            <SKPICount>{v.count1}</SKPICount>

                            <Box
                              sx={{
                                p: 0.65,
                                m: 0.5,
                                background: "rgba(112, 186, 191, 0.17)",
                                borderRadius: "7px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <SKPISubHeading1>{v.subheading}</SKPISubHeading1>

                              <SKPISubHeading2>{v.count2}</SKPISubHeading2>
                            </Box>
                          </Box>

                          <Box>
                            <img src={v.img} alt="" height={"64.909px"} width={"68px"} />
                          </Box>
                        </SBox3>
                      </SBox2>
                    </SBox1>
                  </Grid>
                );
              })}
            </>
          )}

          <Grid item lg={6} sm={12} md={12} xs={12}>
            <SGridBox>
              <SBox5
                sx={{
                  p: 3,
                  pl: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <SGHeading>
                  {/* All Data Source <span style={{ color: "#ACACAC" }}>vs</span>
                  <br />
                  Candidate Shared */}
                  Datasource Distribution
                </SGHeading>

                <Select
                  styles={stylesDD}
                  options={yearList}
                  // placeholder="Select Year"
                  // value={{ label: selectYear }}
                  defaultValue={[{ label: selectYear, value: selectYear }]}
                  onChange={(e) => {
                    setSelectYear(e ? e.value : "");
                  }}
                />
                {/* <DatePicker format={"yyyy"} /> */}
              </SBox5>

              {loading1 ? (
                <Box
                // sx={{
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "center",
                //   width: "100%",
                //   height: "400px",
                // }}
                >
                  {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{ height: "400px", width: "100%", background: "rgba(112, 186, 191, 0.17)" }}
                  />
                </Box>
              ) : (
                <>
                  {data
                    ?.map((v) => {
                      return Object.keys(v)
                        ?.filter((v) => v !== "name")
                        ?.reduce((ele, key) => {
                          ele[key] = v[key];
                          return ele;
                        }, {});
                    })
                    ?.map((v) => Object.values(v))
                    ?.flat(1)
                    ?.every((v) => v === 0 || v === "") ||
                  data?.length === 0 ||
                  data === undefined ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "400px",
                        }}
                      >
                        <img src={nodata} alt="" height={"94px"} width={"94px"} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <ResponsiveContainer width="100%" height={360}>
                        <BarChart
                          width={400}
                          height={200}
                          data={data}
                          margin={{
                            top: 10,
                            right: 15,
                            left: 15,
                            bottom: 10,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" fontSize={"9px"} />
                          <YAxis fontSize={"9px"} />
                          <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />
                          <Bar dataKey="Job Fair" stackId="a" fill="#F9C74F" barSize={25} />
                          <Bar dataKey="State Admins" stackId="a" fill="#00cdda" barSize={25} />
                          <Bar dataKey="Channel Partner" stackId="a" fill="#F94144" barSize={25} />
                          <Bar dataKey="Forms" stackId="a" fill="#90BE6D" barSize={25} />
                          <Bar dataKey="Job Provider" stackId="a" fill="#2D9CDB" barSize={25} />
                          <Bar dataKey="Others" stackId="a" fill="#E67E22" barSize={25} />
                          <Bar dataKey="Bots" stackId="a" fill="#8E44AD" barSize={25} />
                        </BarChart>
                      </ResponsiveContainer>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#F9C74F" }} />
                          Job Fair
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#00cdda" }} />
                          State Admins
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#F94144" }} />
                          Channel Partner
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#90BE6D" }} />
                          Forms
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#2D9CDB" }} />
                          Job Provider
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#E67E22" }} />
                          Others
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#8E44AD" }} />
                          Bots
                        </SGraphSubHeading>
                      </Box>
                    </>
                  )}
                </>
              )}
            </SGridBox>
          </Grid>

          <Grid item lg={6} sm={12} md={12} xs={12}>
            <SGridBox>
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <SGHeading>Placement Pipeline</SGHeading>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ ml: 1, mr: 1 }}>
                    <Select
                      isClearable
                      styles={stylesDD}
                      options={orgList}
                      placeholder="Orgnization"
                      defaultValue={[{ label: "All", value: "All" }]}
                      onChange={(e) => {
                        setOrgName(e ? e.value : "");
                      }}
                    />
                  </Box>

                  <Box sx={{ ml: 1, mr: 1 }}>
                    <Select
                      isClearable
                      styles={stylesDD}
                      options={jobList}
                      placeholder="Job Role"
                      value={jobLabel1 !== "" ? { label: jobLabel1, value: jobLabel1 } : null}
                      onChange={(e) => {
                        setJobLabel1(e ? e.label : "");
                        setJobName(e ? e.jobid : "");
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              {loading2 ? (
                <Box
              
                >
                  {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{ height: "400px", width: "100%", background: "rgba(112, 186, 191, 0.17)" }}
                  />
                </Box>
              ) : (
                <>
                  {data6
                    ?.map((v) => {
                      return Object.keys(v)
                        ?.filter((v) => !["name", "msg"].includes(v))
                        ?.reduce((ele, key) => {
                          ele[key] = v[key];
                          return ele;
                        }, {});
                    })
                    ?.map((v) => Object.values(v))
                    ?.flat(1)
                    ?.every((v) => v === 0 || v === "") ||
                  data6?.length === 0 ||
                  data6 === undefined ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "400px",
                        }}
                      >
                        <img src={nodata} alt="" height={"94px"} width={"94px"} />
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <Grid container>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                          <Box sx={{ mt: 5 }}>
                            {data6.map((v, i) => {
                              return (
                                <Box key={i} sx={{ height: "50px", textAlign: "right" }}>
                                  <SGSubHeading>{v.name}</SGSubHeading>
                                </Box>
                              );
                            })}
                          </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={4}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              ml: 2,
                              mr: 2,
                            }}
                          >
                            <ReactApexChart
                              options={option3.options}
                              series={Series.series}
                              type="bar"
                              height={385}
                              width={200}
                            />
                          </Box>
                        </Grid>

                        <Grid item lg={2} md={2} sm={2} xs={2}>
                          <Box sx={{ mt: 5, ml: 6 }}>
                            {option3.options.xaxis.percentage.map((v, i) => {
                              return (
                                <Box key={i} sx={{ height: "50px", textAlign: "left" }}>
                                  <SGSubHeading>
                                    {v?.toFixed(2)?.split(".")[1] === "00"
                                      ? v?.toFixed(2)?.split(".")[0]
                                      : v?.toFixed(2)}
                                    %
                                  </SGSubHeading>
                                </Box>
                              );
                            })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </>
              )}
            </SGridBox>
          </Grid>

          <Grid item lg={6} sm={12} md={12} xs={12}>
            <SGridBox>
              <SBox5>
                <SGHeading>Company Wise Postings</SGHeading>

                <Select
                  isClearable
                  styles={stylesDD}
                  options={orgList}
                  placeholder="Orgnization Name"
                  // value={orgLabel2 !== "" ? { label: orgLabel2, value: orgLabel2 } : null}
                  defaultValue={[{ label: "All", value: "All" }]}
                  onChange={(e) => {
                    setOrgLabel2(e ? e.label : "");
                    setOrgNameID(e ? e.value : "");
                  }}
                />
              </SBox5>

              {loading3 ? (
                <Box
             
                >
                  {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{ height: "400px", width: "100%", background: "rgba(112, 186, 191, 0.17)" }}
                  />
                </Box>
              ) : (
                <>
                  {orgNameID !== "All" ? (
                    <>
                      {data1
                        ?.map((v) => {
                          return Object.keys(v)
                            ?.filter((v) => v !== "name")
                            ?.reduce((ele, key) => {
                              ele[key] = v[key];
                              return ele;
                            }, {});
                        })
                        ?.map((v) => Object.values(v))
                        ?.flat(1)
                        ?.every((v) => v === 0 || v === "") ||
                      data1?.length === 0 ||
                      data1 === undefined ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "400px",
                          }}
                        >
                          <img src={nodata} alt="" height={"94px"} width={"94px"} />
                        </Box>
                      ) : (
                        <>
                          <ResponsiveContainer width="100%" height={360}>
                            <BarChart
                              width={400}
                              height={200}
                              data={data1}
                              margin={{
                                top: 10,
                                right: 15,
                                left: 15,
                                bottom: 0,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" fontSize={"9px"} />
                              <YAxis fontSize={"9px"} />
                              <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />
                              {/* <Bar dataKey="Candidates Shortlisted" fill="#F94144" barSize={25} /> */}
                              <Bar dataKey="Total Requirements" fill="#90BE6D" barSize={25} />
                              <Bar dataKey="Candidates Shared" fill="#F9C74F" barSize={25} />
                              <Bar dataKey="Candidates Shortlisted" fill="#F94144" barSize={25} />
                            </BarChart>
                          </ResponsiveContainer>

                          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <SGraphSubHeading>
                              <SquareIcon fontSize="medium" sx={{ color: "#90BE6D" }} />
                              Total Requirements
                            </SGraphSubHeading>

                            <SGraphSubHeading>
                              <SquareIcon fontSize="medium" sx={{ color: "#F9C74F" }} />
                              {`Candidates Shared (JP, CP & SA)`}
                            </SGraphSubHeading>

                            <SGraphSubHeading>
                              <SquareIcon fontSize="medium" sx={{ color: "#F94144" }} />
                              Candidates Shortlisted
                            </SGraphSubHeading>
                          </Box>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {data1A
                        ?.map((v) => {
                          return Object.keys(v)
                            ?.filter((v) => !["name", "fill"].includes(v))
                            ?.reduce((ele, key) => {
                              ele[key] = v[key];
                              return ele;
                            }, {});
                        })
                        ?.map((v) => Object.values(v))
                        ?.flat(1)
                        ?.every((v) => v === 0 || v === "") ||
                      data1A?.length === 0 ||
                      data1A === undefined ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "400px",
                          }}
                        >
                          <img src={nodata} alt="" height={"94px"} width={"94px"} />
                        </Box>
                      ) : (
                        <>
                          <ResponsiveContainer width="100%" height={360}>
                            <BarChart
                              width={400}
                              height={200}
                              data={data1A}
                              margin={{
                                top: 10,
                                right: 15,
                                left: 15,
                                bottom: 0,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" fontSize={"9px"} />
                              <YAxis fontSize={"9px"} />
                              <Tooltip itemStyle={{ fontFamily: "Montserrat", fontSize: "14px" }} />
                              <Bar dataKey="count" stackId="a" fill="#000" barSize={70} />
                            </BarChart>
                          </ResponsiveContainer>

                          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <SGraphSubHeading>
                              <SquareIcon fontSize="medium" sx={{ color: "#90BE6D" }} />
                              Total Requirements
                            </SGraphSubHeading>

                            <SGraphSubHeading>
                              <SquareIcon fontSize="medium" sx={{ color: "#F9C74F" }} />
                              {`Candidates Shared (JP, CP & SA)`}
                            </SGraphSubHeading>

                            <SGraphSubHeading>
                              <SquareIcon fontSize="medium" sx={{ color: "#F94144" }} />
                              Candidates Shortlisted
                            </SGraphSubHeading>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </SGridBox>
          </Grid>

          <Grid item lg={6} sm={12} md={12} xs={12}>
            <SGridBox>
              <SBox5>
                <SGHeading>Statewise Distribution</SGHeading>

                <TooltipMui key="1" title="Filter" placement="top">
                  <Box
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    //onClick={() => setShow(true)}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="34"
                      viewBox="0 0 39 37"
                      fill="none"
                    >
                      <rect width="38.8889" height="37" rx="2" fill="white" />
                      <path
                        d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                </TooltipMui>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 5px rgba(0,0,0,0.10))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: "100%",
                        height: "100%",
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Box
                    sx={{
                      maxHeight: "400px",
                      minHeight: "100%",
                      overflow: "auto",
                      paddingTop: "8px",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "5px",
                        textDecoration: "underline",
                        color: "#3B5092",
                        cursor: "pointer",
                      }}
                      className="ListCSV"
                      // onClick={(e) => setClear(true)}
                      onClick={(e) => (
                        console.log("use to deselect all"),
                        setClear(true),
                        setCheck(false),
                        setStateSelected(
                          stateSelected?.map((v, i) => {
                            return {
                              label: v.label,
                              isExportable: false,
                              name: v.name,
                              count: v.count,
                              // "Total Candidates": 500 + (i + 1) * 2,
                            };
                          })
                        )
                      )}
                    >
                      Clear All
                    </span>
                    <ul className="UlCSV" style={{ paddingTop: "6px", padddingBottom: "6px" }}>
                      <input
                        type="checkbox"
                        checked={check}
                        disabled={
                          statesSelected?.length === stateSelected?.length && !clear ? true : false
                        }
                        onChange={(e) => (setCheck(!check), setClear(false))}
                      />
                      <span className="ListCSV">&nbsp;All</span>
                    </ul>
                    {/* </Box> */}
                    {stateSelected.map((ele, index) => (
                      <ul
                        key={index}
                        className="UlCSV"
                        style={{ paddingTop: "6px", padddingBottom: "6px" }}
                      >
                        <input
                          type="checkbox"
                          checked={ele.isExportable}
                          onChange={(event) =>
                            handleFormChange12(event, index, stateSelected, setStateSelected)
                          }
                        />
                        <span className="ListCSV">&nbsp;{ele.label}</span>
                      </ul>
                    ))}
                  </Box>
                </Menu>
              </SBox5>

              {loading4 ? (
                <Box
           
                >
                  {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{ height: "400px", width: "100%", background: "rgba(112, 186, 191, 0.17)" }}
                  />
                </Box>
              ) : (
                <>
                  {statesSelected
                    ?.map((v) => {
                      return Object.keys(v)
                        ?.filter((v) => v === "count")
                        ?.reduce((ele, key) => {
                          ele[key] = v[key];
                          return ele;
                        }, {});
                    })
                    ?.map((v) => Object.values(v))
                    ?.flat(1)
                    ?.every((v) => v === 0 || v === "") ||
                  statesSelected?.length === 0 ||
                  statesSelected === undefined ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "400px",
                      }}
                    >
                      <img src={nodata} alt="" height={"94px"} width={"94px"} />
                    </Box>
                  ) : (
                    <>
                      <ResponsiveContainer width="100%" height={400}>
                        <ComposedChart
                          layout="vertical"
                          width={400}
                          height={200}
                          data={statesSelected}
                          margin={{
                            top: 10,
                            right: 15,
                            left: 15,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis type="number" fontSize={"9px"} />
                          <YAxis dataKey="name" type="category" fontSize={"9px"} />
                          <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />
                          <Bar dataKey="count" fill="#8884d8" barSize={25} />
                        </ComposedChart>
                      </ResponsiveContainer>

                      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#8884d8" }} />
                          Total Candidates
                        </SGraphSubHeading>
                      </Box>
                    </>
                  )}
                </>
              )}
            </SGridBox>
          </Grid>

          <Grid item lg={6} sm={12} md={12} xs={12}>
            <Box
              sx={{
                width: "100%",
                m: 2,
                mt: 0,
                mb: 0,
                p: 1,
                background: "#FFFFFF",
                height: "500px",
                boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)",
                borderRadius: "8px",
              }}
            >
              <SBox5>
                <SGHeading>General Distributions</SGHeading>

                <Select
                  styles={stylesDD}
                  options={distributionDD}
                  defaultValue={[{ label: "Highest Qualification", value: "qualification" }]}
                  placeholder="Job Role"
                  onChange={(e) => {
                    setRoleType(e ? e.value : "");
                  }}
                />
              </SBox5>

              {loading5 ? (
                <Box
                
                >
                  {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{ height: "350px", width: "100%", background: "rgba(112, 186, 191, 0.17)" }}
                  />
                </Box>
              ) : (
                <>
                  {roleType === "role" ? (
                    <>
                      {data3
                        ?.map((v) => {
                          return Object.keys(v)
                            ?.filter((v) => v !== "name")
                            ?.reduce((ele, key) => {
                              ele[key] = v[key];
                              return ele;
                            }, {});
                        })
                        ?.map((v) => Object.values(v))
                        ?.flat(1)
                        ?.every((v) => v === 0 || v === "") ||
                      data3?.length === 0 ||
                      data3 === undefined ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "400px",
                            }}
                          >
                            <img src={nodata} alt="" height={"94px"} width={"94px"} />
                          </Box>
                        </>
                      ) : (
                        <ResponsiveContainer
                          width="100%"
                          height={roleType === "role" ? 400 : "100%"}
                        >
                          <ComposedChart
                            layout="vertical"
                            width={400}
                            height={200}
                            data={data3}
                            margin={{
                              top: 10,
                              right: 15,
                              left: 15,
                              bottom: 0,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" fontSize={"9px"} />
                            <YAxis dataKey="name" type="category" fontSize={"9px"} />
                            <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />
                            <Bar dataKey="Total Candidates" fill="#F94144" barSize={25} />
                          </ComposedChart>
                        </ResponsiveContainer>
                      )}
                    </>
                  ) : (
                    <>
                      {data4
                        ?.map((v) => {
                          return Object.keys(v)
                            ?.filter((v) => v === "value")
                            ?.reduce((ele, key) => {
                              ele[key] = v[key];
                              return ele;
                            }, {});
                        })
                        ?.map((v) => Object.values(v))
                        ?.flat(1)
                        ?.every((v) => v === 0 || v === "") ||
                      data4?.length === 0 ||
                      data4 === undefined ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "400px",
                            }}
                          >
                            <img src={nodata} alt="" height={"94px"} width={"94px"} />
                          </Box>
                        </>
                      ) : (
                        <ResponsiveContainer
                          width="100%"
                          height={roleType === "role" ? 400 : "70%"}
                        >
                          <PieChart width={300} height={300}>
                            <Pie
                              data={data4}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              label={renderCustomizedLabel}
                              outerRadius={100}
                              fill="#8884d8"
                              dataKey="value"
                              // label
                              // onMouseEnter={onPieEnter}
                            >
                              {data4?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} label />
                              ))}
                            </Pie>
                            <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />
                          </PieChart>
                        </ResponsiveContainer>
                      )}
                    </>
                  )}
                </>
              )}

              {roleType !== "role" ? (
                loading5 ? (
                  <Box mt={1}>
                    {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{ height: "60px", width: "100%" }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={
                      roleType === "role"
                        ? {
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }
                        : {
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "end",
                            // mb: -8,
                          }
                    }
                  >
                    {data4.map((v, i) => {
                      return roleType === "age" ? (
                        // <SGTitles key={i}>
                        //   <SquareIcon fontSize="medium" sx={{ color: v.color }} />
                        //   {`${v.name} years`}
                        // </SGTitles>
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: v.color }} />
                          {`${v.name} years`}
                        </SGraphSubHeading>
                      ) : (
                      
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: v.color }} />
                          {v.name}
                        </SGraphSubHeading>
                      );
                    })}
                  </Box>
                )
              ) : null}

              {roleType === "role" ? (
                loading5 ? (
                  <Box mt={1}>
                    {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{ height: "44px", width: "100%" }}
                    />
                  </Box>
                ) : (
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <SGraphSubHeading>
                      <SquareIcon fontSize="medium" sx={{ color: "#F94144" }} />
                      Total Candidates
                    </SGraphSubHeading>
                  </Box>
                )
              ) : null}
            </Box>
          </Grid>

          <Grid item lg={6} sm={12} md={12} xs={12}>
            <SGridBox>
              <SBox5>
                <SGHeading>
                  Daily Trends: Channel Partner <span style={{ color: "#ACACAC" }}>vs</span> Job
                  Provider
                </SGHeading>

                <Box>
                  <DatePicker
                    placeholder="Select Week"
                    isoWeek
                    showWeekNumbers
                    value={week.date}
                    onChange={onChange}
                    // maxDate={currentEnd}
                    defaultValue={week.date}
                    shouldDisableDate={(date) => (
                      console.log(
                        "dis",
                        week.date, // prev monday
                        date // prev monday
                        // moment(date).subtract(1, "weeks").endOf("isoWeek")
                      ),
                      isAfter(
                        // moment(date).startOf("isoWeek"),
                        date,
                        moment(current).endOf("isoWeek").toDate()
                        // moment(current).subtract(1, "weeks").endOf("isoWeek").toDate()
                        // week.date
                      )
                    )}
                  />
                </Box>

                {/* <Box></Box> */}
              </SBox5>

              {loading6 ? (
                <Box
            
                >
                  {/* <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} /> */}
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{ height: "400px", width: "100%", background: "rgba(112, 186, 191, 0.17)" }}
                  />
                </Box>
              ) : (
                <>
                  {data10
                    ?.map((v) => {
                      return Object.keys(v)
                        ?.filter((v) => v !== "name")
                        ?.reduce((ele, key) => {
                          ele[key] = v[key];
                          return ele;
                        }, {});
                    })
                    ?.map((v) => Object.values(v))
                    ?.flat(1)
                    ?.every((v) => v === 0 || v === "") ||
                  data10?.length === 0 ||
                  data10 === undefined ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "400px",
                        }}
                      >
                        <img src={nodata} alt="" height={"94px"} width={"94px"} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <ResponsiveContainer width="100%" height={360}>
                        <BarChart
                          width={400}
                          height={200}
                          data={data10}
                          margin={{
                            top: 10,
                            right: 15,
                            left: 15,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" fontSize={"9px"} />
                          <YAxis fontSize={"9px"} />
                          <Tooltip itemStyle={{ fontFamily: "Roboto", fontSize: "14px" }} />
                          <Bar dataKey="Channel Partner" fill="#F94144" barSize={25} />
                          <Bar dataKey="Job Provider" fill="#90BE6D" barSize={25} />
                        </BarChart>
                      </ResponsiveContainer>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#F94144" }} />
                          Channel Partner
                        </SGraphSubHeading>

                        <SGraphSubHeading>
                          <SquareIcon fontSize="medium" sx={{ color: "#90BE6D" }} />
                          Job Provider
                        </SGraphSubHeading>
                      </Box>
                    </>
                  )}
                </>
              )}
            </SGridBox>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default SuperAdminOverview;
