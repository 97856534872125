import React, { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { Card, Stack, Tooltip, useMediaQuery, Skeleton, Typography } from "@mui/material";
import FadeLoader from "react-spinners/FadeLoader";
import { Table, Dropdown, Pagination, Menu as Menu1 } from "antd";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";
import axios from "axios";
import moment, { months } from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import { Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import SoftSelect from "components/SoftSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import img1 from "../../assets/images/sms.png";
import img2 from "../../assets/images/call.png";
import Index from "./index";
import SoftBadge from "components/SoftBadge";
import refreshBtn from "../../assets/images/refresh_btn.png";
import defaultimg from "../../assets/images/jobfairsvgs/profile.png";
import { withTranslate, IntlActions, useTranslate } from "react-redux-multilingual";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";

import {
  BoxGrid2,
  BoxHeadingContainer1,
  TypographyTabHeading,
  BoxInfoContainer1,
  BoxHeading1,
  TypographyList,
  TypographyHeading1,
  SBox1,
  SBox2,
  SBox3,
  SKPICount,
  SKPIHeading,
  SVerticalBox,
} from "./Style";
import "./jobfair.css";

// icons and images
import uploadGif from "../../assets/images/jobfairsvgs/login-gif2.gif";
// import refreshBtn from "../../assets/images/jobfairsvgs/refresh_btn.png";
import candidates1 from "../../assets/images/jobfairsvgs/orange1.png";
import candidates2 from "../../assets/images/jobfairsvgs/orange3.png";
import candidates3 from "../../assets/images/jobfairsvgs/placed.png";
import jobfairSVG from "../../assets/images/jobfairsvgs/jobfair.svg";
import candidatesSVG from "../../assets/images/jobfairsvgs/candidates.svg";
import calenderSVG from "../../assets/images/jobfairsvgs/calendar.svg";
import clockSVG from "../../assets/images/jobfairsvgs/clock.svg";
import locationSVG from "../../assets/images/jobfairsvgs/location.svg";
import registeredSVG from "../../assets/images/jobfairsvgs/registered.svg";
import shortlistedSVG from "../../assets/images/jobfairsvgs/shortlistedjf.svg";
import onholdSVG from "../../assets/images/jobfairsvgs/onhold.svg";
import rejectedSVG from "../../assets/images/jobfairsvgs/rejected.svg";
import backBtnSVG from "../../assets/images/jobfairsvgs/backbtn.svg";

const JobFairs = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [download1, setDownload1] = useState(false);
  const [columns, setColumns] = useState("");
  const [fileName, setFileName] = useState("");
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [step, setStep] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [table, setTable] = useState(0);
  const [candiatetable, setCandidatetable] = useState("");
  const [candiatetable1, setCandidatetable1] = useState("");
  const [KPIObj1, setKPIObj1] = useState({
    total_jf: "0",
    ongoing: "0",
    completed: "0",
    upcoming: "0",
    cand_reg: "0",
    comp_reg: "0",
  });
  const [KPIObj2, setKPIObj2] = useState({
    total_comp: "0",
    onboarded: "0",
    comp_attended: "0",
    shortlisted: "0",
  });
  const [KPIObj3, setKPIObj3] = useState({
    total_cand: "0",
    cand_active: "0",
    onHold: "0",
    shortlisted: "0",
  });
  const matches = useMediaQuery("(min-width:1237px)");
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [pageSize, setPageSize] = useState(10);
  const [jfId, setJFId] = useState("");
  const [jfList, setJFList] = useState([]);
  const [jpList, setJPList] = useState([]);
  const [candList, setCandList] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchJPData, setSearchJPData] = useState([]);
  const [searchCandData, setSearchCandData] = useState([]);
  const [jpReqData, setJpReqData] = useState([]);
  const [newJPId, setNewJPId] = useState("");
  const [companyModal, setCompanyModal] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [jdModal, setJDModal] = useState(false);
  const [tableModal1, setTableModal1] = useState(false);
  const [tableModal2, setTableModal2] = useState(false);
  const [tableModal1Data, setTableModal1Data] = useState([]);
  const [jd, setJD] = useState("");
  const [editadmin1, Seteditadmin1] = useState(false);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [jobid, setJobid] = useState("");
  const [statefilter, setStatefilter] = useState("");
  const [districtfilter, setDistrictfilter] = useState("");
  const [datefilter, setDatefilter] = useState("");
  const [jffilter, setJffilter] = useState("");
  const [educationfilter, setEducationfilter] = useState("");
  const [rolefilter, setRolefilter] = useState("");
  const [degreefilter, setDegreefilter] = useState("");
  const [boardingfilter, setBoardingfilter] = useState("");
  const [tablerow, setTablerow] = useState([]);
  const [pageOne, setPageOne] = useState({
    org_name: "",
    org_state: "",
    org_district: "",
    venue: "",
    locality: "",
    org_date: "",
    org_time: "",
    lastnm: "",
    mobnum: "",
    email: "",
    id: "",
    jplink: "",
    jslink: "",
    banner_img: "",
  });
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  // pagination data
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);
  const [totalSize, setTotalSize] = useState(limit);
  const [data4, setData4] = useState([]);

  const [data5, setData5] = useState([]);
  const [searchData5, setSearchData5] = useState([]);

  const [data6, setData6] = useState([]);

  const [data7, setData7] = useState([]);

  const [jobfairdata, setJobfairdata] = useState([
    {
      id: 1,
      date: "23-03-2023",
      job_fair_name: "Job Fair (Name ) Mumbai",
      job_role: "job_role",
      no_of_candidates: 10,
      job_description:
        "We require a degree in Computer Science, proficiency in Python, Node.js, or Java, and knowledge of databases. Strong problem-solving skills and effective communication are essential. If you're ready to contribute to cutting-edge projects, apply today.",
    },
    {
      id: 2,
      date: "23-03-2023",
      job_fair_name: "Job Fair (Name ) Mumbai",
      job_role: "job_role",
      no_of_candidates: 10,
      job_description:
        "We require a degree in Computer Science, proficiency in Python, Node.js, or Java, and knowledge of databases. Strong problem-solving skills and effective communication are essential. If you're ready to contribute to cutting-edge projects, apply today.",
    },
  ]);

  const [kpiArr, setKpiArr] = useState([
    {
      heading: "Job Fairs Conducted",
      count1: 0,
      img: candidates1,
    },
    {
      heading: "Ongoing Job Fairs",
      count1: 0,
      img: candidates2,
    },
    {
      heading: "Completed Job Fairs",
      count1: 0,
      img: candidates3,
    },
    {
      heading: "Upcoming Job Fairs",
      count1: 0,
      img: candidates2,
    },
    {
      heading: "Companies Registered",
      count1: 0,
      img: candidates3,
    },
    {
      heading: "Candidates Registered",
      count1: 0,
      img: candidates1,
    },
  ]);

  const onShowSizeChange = (current, pageSize) => {
    // console.log(current, pageSize);
    setLimit(pageSize);
  };

  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };

  const items = [
    {
      key: "1",
      label: (
        <a style={{ display: "flex" }} className="dropdown-item">
          <div>
            <Icon icon="mdi:lead-pencil" />
          </div>
          &nbsp; Edit
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a className="dropdown-item" style={{ display: "flex" }}>
          <div>
            <Icon icon="gridicons:multiple-users" color="black" />
          </div>
          &nbsp; Registered Candidates
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a className="dropdown-item" style={{ display: "flex" }}>
          <div>
            <Icon icon="bi:buildings-fill" />
          </div>
          &nbsp; Registered Companies
        </a>
      ),
    },
  ];

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        console.log("State", res.data);
        // var StateList = res.data[0].sort().map(function (item) {
        //   return { label: item, value: item };
        // });
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchStateList();
    dispatch(IntlActions.setLocale(sessionStorage.getItem("lang")));
  }, []);

  useEffect(() => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(jfList, newSelectedRowKeys);
    setTablerow(selectedRows);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", selectedCSVData);

  const resetfunc = () => {
    setDistrictfilter("");
    setStatefilter("");
    setDatefilter("");
    setJffilter("");
    setEducationfilter("");
    setRolefilter("");
    setDegreefilter("");
    setBoardingfilter("");
  };
  const toJFDetails = (record) => {
    // navigate("/admin-jobfairs/jobfairDetail", { state: record });
    navigate("/admin-jobfairs/jobfairdetails", { state: record });
  };
  const colName = [
    // {
    //   title: "Job Fair ID",
    //   dataIndex: "key",
    //   fixed: "left",
    //   width: 200,
    //   // width: !matchsm ? 200 : 100,
    //   fixed: !matchsm ? "left" : "none",
    //   // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
    //   sorter: {
    //     compare: (a, b) => a?.key?.localeCompare(b?.key),
    //     multiple: 1,
    //   },
    // },
    {
      title: "Job Fair Name",
      // dataIndex: "name",
      fixed: "left",
      // width: 200,
      // width: !matchsm ? 200 : 100,
      // fixed: !matchsm ? "left" : "none",
      render: (text, record) => (
        <p
          style={{ fontWeight: 500, color: "black", cursor: "pointer" }}
          // onClick={() => navigate("/jobproviderjf/jobfair/jobfairDetail", { state: record })}
          onClick={() => {
            toJFDetails(record);
          }}
        >
          {/* {`${text?.firstnm[0].toUpperCase() + text?.firstnm.slice(1)}` +
            " " +
            `${text?.lastnm[0].toUpperCase() + text?.lastnm.slice(1)}`} */}
          {text.jfName !== "" ? text.jfName : " - "}
        </p>
      ),
      sorter: {
        compare: (a, b) => a?.jfName?.localeCompare(b?.jfName),
        multiple: 1,
      },
    },
    {
      title: "Job Fair Date",
      dataIndex: "date",
      fixed: "left",
      // width: 150,
      // width: !matchsm ? 200 : 100,
      // fixed: !matchsm ? "left" : "none",
      sorter: {
        compare: (a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    // {
    //   title: "Job Fair Time",
    //   dataIndex: "time",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => moment(a.time, "DD-MM-YYYY") - moment(b.time, "DD-MM-YYYY"),
    //     multiple: 4,
    //   },
    // },
    // {
    //   title: "SPOC Name",
    //   // dataIndex: "name",
    //   // fixed: "left",
    //   width: 200,
    //   render: (text, record) => (
    //     <>
    //       {/* {`${text?.firstnm[0].toUpperCase() + text?.firstnm.slice(1)}` +
    //         " " +
    //         `${text?.lastnm[0].toUpperCase() + text?.lastnm.slice(1)}`} */}
    //       {text.name !== "" ? text.name : " - "}
    //     </>
    //   ),
    //   sorter: {
    //     compare: (a, b) => a?.name?.localeCompare(b?.name),
    //     multiple: 1,
    //   },
    // },
    // {
    //   title: "SPOC Email",
    //   dataIndex: "email",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => a?.email?.localeCompare(b?.email),
    //     multiple: 2,
    //   },
    // },
    // {
    //   title: "SPOC Mobile",
    //   dataIndex: "mobnum",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => a.mobnum - b.mobnum,
    //     multiple: 3,
    //   },
    // },

    // {
    //   title: "Created Date",
    //   dataIndex: "date_of_creation",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) =>
    //       moment(a.date_of_creation, "DD-MM-YYYY") - moment(b.date_of_creation, "DD-MM-YYYY"),
    //     multiple: 4,
    //   },
    // },
    // {
    //   title: "Modified Date",
    //   dataIndex: "modify_date",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) =>
    //       moment(a.modify_date, "DD-MM-YYYY") - moment(b.modify_date, "DD-MM-YYYY"),
    //     multiple: 4,
    //   },
    // },
    // {
    //   title: "State",
    //   dataIndex: "state",
    //   width: 200,
    //   sorter: {
    //     compare: (a, b) => a?.state?.localeCompare(b?.state),
    //     multiple: 2,
    //   },
    // },
    // {
    //   title: "District",
    //   dataIndex: "district",
    //   width: 200,
    //   sorter: {
    //     compare: (a, b) => a?.district?.localeCompare(b?.district),
    //     multiple: 2,
    //   },
    // },
    // {
    //   title: "Locality",
    //   dataIndex: "locality",
    //   width: 200,
    //   sorter: {
    //     compare: (a, b) => a?.locality?.localeCompare(b?.locality),
    //     multiple: 2,
    //   },
    // },
    {
      title: "Venue",
      dataIndex: "venue",
      // width: 200,
      sorter: {
        compare: (a, b) => a?.venue?.localeCompare(b?.venue),
        multiple: 2,
      },
    },
    // {
    //   title: "Company Registered",
    //   dataIndex: "comp_reg",
    //   width: 200,
    //   sorter: {
    //     compare: (a, b) => a?.comp_reg?.localeCompare(b?.comp_reg),
    //     multiple: 2,
    //   },
    //   render: (text) => (
    //     <span
    //       style={{
    //         textDecoration: "underline",
    //         display: "flex",
    //         justifyContent: "center",
    //         color: "#1B59F8",
    //         fontSize: "16px",
    //         fontWeight: 600,
    //         cursor: "pointer",
    //       }}
    //     >
    //       {text}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Company Attended",
    //   dataIndex: "comp_attended",
    //   width: 200,
    //   sorter: {
    //     compare: (a, b) => a?.comp_attended?.localeCompare(b?.comp_attended),
    //     multiple: 2,
    //   },
    //   render: (text) => (
    //     <span
    //       style={{
    //         textDecoration: "underline",
    //         display: "flex",
    //         justifyContent: "center",
    //         color: "#1B59F8",
    //         fontSize: "16px",
    //         fontWeight: 600,
    //         cursor: "pointer",
    //       }}
    //     >
    //       {text}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Candidate Registered",
    //   // dataIndex: "cand_reg",
    //   width: 200,
    //   sorter: {
    //     // compare: (a, b) => a?.cand_reg?.localeCompare(b?.cand_reg),
    //     compare: (a, b) => a.cand_reg - b.cand_reg,
    //     multiple: 2,
    //   },
    //   render: (text) => (
    //     <span
    //       style={{
    //         textDecoration: text.accepted_status !== "Declined" ? "underline" : "none",
    //         display: "flex",
    //         justifyContent: "center",
    //         color: text.accepted_status !== "Declined" ? "#1B59F8" : "black",
    //         fontSize: "16px",
    //         fontWeight: 600,
    //         cursor: text.accepted_status !== "Declined" ? "pointer" : "no-drop",
    //       }}
    //       onClick={() =>
    //         text.accepted_status !== "Declined" &&
    //         (setCandAddModal(true), setJFId(text.key), setRowData(text))
    //       }
    //     >
    //       {text.cand_reg}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Candidate Attended",
    //   dataIndex: "cand_attended",
    //   width: 200,
    //   sorter: {
    //     // compare: (a, b) => a?.cand_attended?.localeCompare(b?.cand_attended),
    //     compare: (a, b) => a.cand_attended - b.cand_attended,
    //     multiple: 2,
    //   },
    //   render: (text) => (
    //     <span
    //       style={{
    //         textDecoration: "underline",
    //         display: "flex",
    //         justifyContent: "center",
    //         // color: "#000",
    //         fontSize: "16px",
    //         fontWeight: 600,
    //         cursor: "pointer",
    //       }}
    //       // onClick={() => (setCandAddModal(true), setJFId(text.key))}
    //     >
    //       {text}
    //     </span>
    //   ),
    // },
    {
      title: "Job Fair Status",
      // dataIndex: "jfStatus",
      // width: 200,
      sorter: {
        compare: (a, b) => a?.jfStatus?.localeCompare(b?.jfStatus),
        multiple: 2,
      },
      render: (text) => (
        console.log("jf status:", text.jfStatus),
        (
          <span
            style={{
              backgroundColor:
                text.jfStatus === "Inactive"
                  ? "#E51837"
                  : text.jfStatus === "Upcoming"
                  ? "#1FBA77"
                  : text.jfStatus === "Ongoing"
                  ? "#73B0F9"
                  : "#FF5C00",
              color: "#fff",
              // text.jfStatus === "Inactive"
              //   ? "#049C2F"
              //   : text.jfStatus === "Upcoming"
              //   ? "#62049C"
              //   : text.jfStatus === "Ongoing"
              //   ? "#EA7500"
              //   : "",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              // paddingTop: "3px",
              // paddingBottom: "3px",
              // paddingLeft: "15px",
              // paddingRight: "15px",
              borderRadius: "10px",
              width: "150px",
              cursor:
                text.accepted_status === "Not Applied" &&
                (text.jfStatus === "Upcoming" || text.jfStatus === "Ongoing")
                  ? "pointer"
                  : "no-drop",
            }}
            onClick={() =>
              (text.jfStatus === "Upcoming" || text.jfStatus === "Ongoing") &&
              // text.accepted_status === "Accepted"
              text.accepted_status === "Not Applied"
                ? (setRequestModal(true), fetchjobrole(text.key), setJFId(text.key))
                : setRequestModal(false)
            }
          >
            {/* {text.jfStatus === "Inactive"
                ? "Inactive"
                : text.jfStatus === "Upcoming"
                ? "Upcoming"
                : text.jfStatus === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
            {text?.jfStatus}
          </span>
        )
      ),
    },
    {
      title: "Approval Status",
      // width: 200,
      sorter: {
        compare: (a, b) => a?.accepted_status.localeCompare(b?.accepted_status),
      },
      render: (text) => (
        <span>
          <SoftBadge
            variant="contained"
            color={
              text.accepted_status === "Accepted"
                ? "success"
                : text.accepted_status === "Declined"
                ? "error"
                : text.accepted_status === "Not Applied"
                ? "warning"
                : "info"
            }
            size="lg"
            badgeContent={
              // text >= 80
              //   ? "Excellent Match"
              //   : text < 80 && text >= 45
              //   ? "Medium Match"
              //   : "Poor Match"
              // text.accepted_status === "success"
              //   ? "Broadcast Sent"
              //   : text.accepted_status === "fail"
              //   ? "Broadcast Failed"
              //   : "Broadcast Not Sent"
              text.accepted_status === "Accepted"
                ? "Accepted"
                : text.accepted_status === "Declined"
                ? "Declined"
                : text.accepted_status === "Not Applied"
                ? "Not Applied"
                : "Pending"
            }
            container
            // sx={{ cursor: text.accepted_status === "Accepted" ? "pointer" : "none" }}
            // onClick={() => (setCandAddModal(true), setJFId(text.key))}
          />
        </span>
      ),
    },
    // {
    //   title: "Status",
    //   // dataIndex: "status",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => a.status - b.status,
    //     multiple: 7,
    //   },
    //   render: (text, record) => (
    //     console.log(text.status),
    //     (
    //       <div
    //         className="dropdown"
    //         style={{
    //           width: "100%",
    //           height: "30px",
    //           // border: "1px solid ",
    //           borderRadius: "15px",
    //           backgroundColor: text.status === 1 ? "#BDFDC8" : "#EFD6FF",
    //           cursor: "pointer",
    //         }}
    //       >
    //         <p
    //           style={{
    //             // padding: "auto",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             fontSize: "12px",
    //             padding: "5px 0px 5px",
    //           }}
    //           className="dropdown-toggle"
    //           data-bs-toggle="dropdown"
    //         >
    //           {text.status === 1 ? "Active" : "Deactivate"}
    //         </p>
    //         <div className="dropdown-menu">
    //           <a
    //             className="dropdown-item"
    //             href="#"
    //             // onClick={() => (text.status ? deActiveFun(text) : ActiveFun(text))}
    //           >
    //             {/* <i
    //             className={
    //               text.job_status
    //                 ? "fa fa-dot-circle-o text-danger"
    //                 : "fa fa-dot-circle-o text-success"
    //             }
    //           /> */}
    //             {text.status === 1 ? "Deactivate" : "Activate"}
    //           </a>
    //         </div>
    //       </div>
    //     )
    //   ),
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: false,
    //   width: 100,
    //   render: (admindata) => (
    //     <div className="edit_icons">
    //       <div className="dropdown">
    //         <Button
    //           type="button"
    //           id="dropdownMenuButton1"
    //           data-bs-toggle="dropdown"
    //           aria-expanded="false"
    //         >
    //           <Icon icon="ion:ellipsis-vertical" color="black" />
    //         </Button>
    //         <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    //           <li>
    //             <div
    //             /*
    //               style={{
    //                 display: "flex",
    //                 flexDirection: "row",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //               }}
    //               */
    //             >
    //               <a
    //                 className="dropdown-item"
    //                 href="#EditUser"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   console.log(admindata, "text");
    //                   Seteditadmin1(true);
    //                   setPageOne({
    //                     org_name: admindata?.jfName ? admindata?.jfName : "",
    //                     org_state: admindata?.state,
    //                     org_district: admindata?.district,
    //                     venue: admindata?.venue,
    //                     locality: admindata?.locality,
    //                     org_date: admindata?.date,
    //                     org_time: admindata?.time,
    //                     lastnm: admindata?.name,
    //                     mobnum: admindata?.mobnum,
    //                     email: admindata?.email,
    //                     id: admindata?.key,
    //                     jplink: admindata?.jpLink,
    //                     jslink: admindata?.jsLink,
    //                   });
    //                 }}
    //               >
    //                 <div>
    //                   <Icon icon="mdi:lead-pencil" />
    //                 </div>
    //                 &nbsp; Edit
    //               </a>
    //             </div>
    //           </li>
    //           <li>
    //             <div>
    //               <a
    //                 className="dropdown-item"
    //                 href="#candidates"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   // setResetPassword(true);
    //                   // Seteditdata(admindata);
    //                   fetchCandidateList(admindata.key);
    //                   setTable(2);
    //                 }}
    //               >
    //                 <div>
    //                   {/* <Icon icon="mdi:password-reset" /> */}
    //                   <Icon icon="gridicons:multiple-users" color="black" />
    //                 </div>
    //                 &nbsp; Registered Candidates
    //               </a>
    //             </div>
    //           </li>
    //           <li>
    //             <div>
    //               <a
    //                 className="dropdown-item"
    //                 href="#companies"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   // setResetPassword(true);
    //                   // Seteditdata(admindata);
    //                   fetchJPList(admindata.key);
    //                   setTable(1);
    //                 }}
    //               >
    //                 <div>
    //                   {/* <Icon icon="mdi:password-reset" /> */}
    //                   <Icon icon="bi:buildings-fill" hFlip={true} />
    //                 </div>
    //                 &nbsp; Registered Companies
    //               </a>
    //             </div>
    //           </li>
    //           {/*
    //           <li>
    //             <div
    //               style={{
    //                 display: "flex",
    //                 flexDirection: "row",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //               }}
    //             >
    //               <div style={{ marginLeft: "10px" }}>
    //                 <Icon icon="fluent-mdl2:deactivate-orders" />
    //               </div>
    //               <a
    //                 className="dropdown-item"
    //                 href="#Deleteuser"
    //                 // onClick={() => {
    //                 //   if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
    //                 //     DeleteClientUser(e.id)
    //                 //   } else {
    //                 //     toast.error("Admin Cannot Delete")
    //                 //   }

    //                 // }}
    //                 onClick={() => {
    //                   deActiveFun(admindata);
    //                 }}
    //               >
    //                 Deactivate
    //               </a>
    //             </div>
    //           </li>
    //           */}
    //         </ul>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const [colHeaders, setColHeaders] = useState([
    {
      label: "Job Fair ID",
      key: "key",
      isExportable: true,
    },
    {
      label: "Job Fair Name",
      key: "jfName",
      isExportable: true,
    },
    {
      label: "Job Fair Date",
      key: "date",
      isExportable: true,
    },
    {
      label: "Company Registration Link",
      key: "jpLink",
      isExportable: true,
    },
    {
      label: "Job Seeker Registration Link",
      key: "jsLink",
      isExportable: true,
    },
    {
      label: "Job Fair Time",
      key: "time",
      isExportable: true,
    },
    {
      label: "SPOC Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "SPOC Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "SPOC Mobile",
      key: "mobnum",
      isExportable: true,
    },
    {
      label: "Created Date",
      key: "date_of_creation",
      isExportable: true,
    },
    {
      label: "Modified Date",
      key: "modify_date",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Locality",
      key: "locality",
      isExportable: true,
    },
    {
      label: "Venue",
      key: "venue",
      isExportable: true,
    },
    {
      label: "Company Registered",
      key: "comp_reg",
      isExportable: true,
    },
    {
      label: "Candidate Attended",
      key: "cand_attended",
      isExportable: true,
    },

    {
      label: "Job Fair Status",
      key: "jfStatus",
      isExportable: true,
    },
    {
      label: "Total Shortlisted",
      key: "shortlisted",
      isExportable: true,
    },
    {
      label: "Total On Hold",
      key: "on_hold",
      isExportable: true,
    },
    {
      label: "Total Rejected",
      key: "rejected",
      isExportable: true,
    },
    {
      label: "Status",
      key: "status",
      isExportable: true,
    },
  ]);

  const [colHeaders1, setColHeaders1] = useState([
    {
      label: "Company Name",
      key: "company_name",
      isExportable: true,
    },
    {
      label: "Total Required Candidates",
      key: "required_candidates",
      isExportable: true,
    },
    {
      label: "Candidates Interacted",
      key: "cand_interacted",
      isExportable: true,
    },
    {
      label: "Candidates Shortlisted",
      key: "cand_shortlisted",
      isExportable: true,
    },
    {
      label: "Job Fairs Attended",
      key: "job_fairs_attended",
      isExportable: true,
    },
    // {
    //   label: "Onboarding Status",
    //   key: "onboarding_status",
    //   isExportable: true,
    // },
  ]);

  const [colHeaders2, setColHeaders2] = useState([
    {
      label: "Candidate Name",
      key: "candidate_name",
      isExportable: true,
    },
    {
      label: "Mobile Number",
      key: "mobile_number",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    // {
    //   label: "Date",
    //   key: "date",
    //   isExportable: true,
    // },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "City",
      key: "city",
      isExportable: true,
    },
    {
      label: "Job fair attended",
      key: "job_fair_attended",
      isExportable: true,
    },
    {
      label: "Company Visited",
      key: "company_visited",
      isExportable: true,
    },
    {
      label: "Company Name",
      key: "company_name",
      isExportable: true,
    },
    {
      label: "Job Title",
      key: "job_title",
      isExportable: true,
    },
    {
      label: "Status",
      key: "applicant_status",
      isExportable: true,
    },
  ]);

  const [colHeaders3, setColHeaders3] = useState([
    {
      label: "Candidate ID",
      key: "key",
      isExportable: true,
    },
    {
      label: "Candidate Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Mobile",
      key: "mobnum",
      isExportable: true,
    },
    {
      label: "Created Date",
      key: "date_of_creation",
      isExportable: true,
    },
    {
      label: "Modified Date",
      key: "modify_date",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },

    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Address",
      key: "address",
      isExportable: true,
    },
    {
      label: "Education",
      key: "education",
      isExportable: true,
    },
    {
      label: "Role",
      key: "role",
      isExportable: true,
    },
    {
      label: "specialization",
      key: "specialization",
      isExportable: true,
    },
    {
      label: "Skills",
      key: "skills",
      isExportable: true,
    },
    {
      label: "Company Visited",
      key: "company_visited",
      isExportable: true,
    },
    {
      label: "Status",
      key: "status",
      isExportable: true,
    },
  ]);

  const [colHeaders4, setColHeaders4] = useState([
    {
      label: "Company ID",
      key: "key",
      isExportable: true,
    },
    {
      label: "Company Name",
      key: "company_name",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Mobile",
      key: "mobnum",
      isExportable: true,
    },
    {
      label: "Created Date",
      key: "date_of_creation",
      isExportable: true,
    },
    {
      label: "Modified Date",
      key: "modify_date",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },

    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Address",
      key: "address",
      isExportable: true,
    },
    {
      label: "Job Fair Attended",
      key: "is_attended",
      isExportable: true,
    },
    {
      label: "Candidate Interacted",
      key: "cand_count",
      isExportable: true,
    },
  ]);

  const [colHeaders11, setColHeaders11] = useState([
    {
      label: "Company Name",
      key: "org_name",
      isExportable: true,
    },
    {
      label: "Job Role",
      key: "job_role",
      isExportable: true,
    },
    {
      label: "Date",
      key: "date",
      isExportable: true,
    },
    {
      label: "Status",
      key: "status",
      isExportable: true,
    },
  ]);
  const [colHeaders12, setColHeaders12] = useState([
    {
      label: "Candidate Name",
      key: "cand_name",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "mobnum",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Company Role",
      key: "company_role",
      isExportable: true,
    },
    {
      label: "Date",
      key: "date",
      isExportable: true,
    },
    {
      label: "Status",
      key: "status",
      isExportable: true,
    },
  ]);

  const colName1 = [
    // {title:"Organization",
    //   dataIndex:"organization",
    //   sorter: {
    //     compare: (a, b) => a.organization - b.organization,
    //     multiple: 1,
    //   },
    // },
    //   {title:"Location",
    //   dataIndex:"location",
    //       sorter: {
    //       compare: (a, b) => a.location - b.location,
    //       multiple: 2,
    //     },
    // },
    {
      title: "Company ID",
      dataIndex: "key",
      fixed: "left",
      width: 200,
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
        multiple: 1,
      },
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      fixed: "left",
      width: 200,
      // render: (text, record) => (
      //   <>
      //     {/* {`${text?.firstnm[0].toUpperCase() + text?.firstnm.slice(1)}` +
      //       " " +
      //       `${text?.lastnm[0].toUpperCase() + text?.lastnm.slice(1)}`} */}
      //     {/* {text.jfName !== "" ? text.jfName : " - "} */}
      //   </>
      // ),
      sorter: {
        compare: (a, b) => a?.company_name?.localeCompare(b?.company_name),
        multiple: 1,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 2,
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobnum",
      width: 150,
      sorter: {
        compare: (a, b) => a.mobnum - b.mobnum,
        multiple: 3,
      },
    },
    {
      title: "Created Date",
      dataIndex: "date_of_creation",
      width: 150,
      sorter: {
        compare: (a, b) =>
          moment(a.date_of_creation, "DD-MM-YYYY") - moment(b.date_of_creation, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    {
      title: "Modified Date",
      dataIndex: "modify_date",
      width: 150,
      sorter: {
        compare: (a, b) =>
          moment(a.modify_date, "DD-MM-YYYY") - moment(b.modify_date, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    {
      title: "State",
      dataIndex: "state",
      width: 200,
      sorter: {
        compare: (a, b) => a?.state?.localeCompare(b?.state),
        multiple: 2,
      },
    },
    {
      title: "District",
      dataIndex: "district",
      width: 200,
      sorter: {
        compare: (a, b) => a?.district?.localeCompare(b?.district),
        multiple: 2,
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      width: 200,
      sorter: {
        compare: (a, b) => a?.address?.localeCompare(b?.address),
        multiple: 2,
      },
    },
    {
      title: "Job Fair Attended",
      // dataIndex: "is_attended",
      width: 200,
      sorter: {
        compare: (a, b) => a?.is_attended?.localeCompare(b?.is_attended),
        multiple: 2,
      },
      render: (text) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <SoftBadge
            variant="contained"
            color={text.is_attended ? "success" : "error"}
            size="md"
            badgeContent={text.is_attended ? "Yes" : "No"}
            container
          />
        </span>
      ),
    },
    {
      title: "Candidate Interacted",
      dataIndex: "cand_count",
      width: 200,
      sorter: {
        compare: (a, b) => a?.cand_count - b?.cand_count,
        multiple: 2,
      },
      render: (text) => (
        <span
          style={{
            textDecoration: "underline",
            display: "flex",
            justifyContent: "center",
            color: "#1B59F8",
            // fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={() => (setTableModal1(true), fetchCandidateInteractedList(text.key, jfId))}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Action",
      // dataIndex: "jfStatus",
      width: 200,
      // sorter: {
      //   compare: (a, b) => a?.jfStatus?.localeCompare(b?.jfStatus),
      //   multiple: 2,
      // },
      render: (text) => (
        console.log("jf status:", text),
        (
          // <span
          //   style={{
          //     backgroundColor:
          //       text.jfStatus === "Inactive"
          //         ? "#E51837"
          //         : text.jfStatus === "Upcoming"
          //         ? "#1FBA77"
          //         : text.jfStatus === "Ongoing"
          //         ? "#73B0F9"
          //         : "#FF5C00",
          //     color: "#fff",
          //     // text.jfStatus === "Inactive"
          //     //   ? "#049C2F"
          //     //   : text.jfStatus === "Upcoming"
          //     //   ? "#62049C"
          //     //   : text.jfStatus === "Ongoing"
          //     //   ? "#EA7500"
          //     //   : "",
          //     display: "flex",
          //     flexDirection: "row",
          //     justifyContent: "center",
          //     alignContent: "center",
          //     paddingTop: "5px",
          //     paddingBottom: "5px",
          //     // paddingTop: "3px",
          //     // paddingBottom: "3px",
          //     // paddingLeft: "15px",
          //     // paddingRight: "15px",
          //     borderRadius: "10px",
          //     width: "150px",
          //   }}
          // >
          //   {/* {text.jfStatus === "Inactive"
          //       ? "Inactive"
          //       : text.jfStatus === "Upcoming"
          //       ? "Upcoming"
          //       : text.jfStatus === "Ongoing"
          //       ? "Ongoing"
          //       : "Completed"} */}
          //   {text?.jfStatus}
          // </span>
          <>
            {text.accepted_statuses === "Pending" ? (
              <div style={{ display: "flex", justifyContent: "center", gap: "25px" }}>
                <div style={{ cursor: "pointer" }} onClick={() => DeclineJPFunc(text)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M12.9902 21.0105L21.0086 12.9922"
                      stroke="#FF0000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.0086 21.0105L12.9902 12.9922"
                      stroke="#FF0000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.7497 31.1693H21.2497C28.333 31.1693 31.1663 28.3359 31.1663 21.2526V12.7526C31.1663 5.66927 28.333 2.83594 21.2497 2.83594H12.7497C5.66634 2.83594 2.83301 5.66927 2.83301 12.7526V21.2526C2.83301 28.3359 5.66634 31.1693 12.7497 31.1693Z"
                      stroke="#FF0000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => AcceptJPFunc(text)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M12.7497 31.1693H21.2497C28.333 31.1693 31.1663 28.3359 31.1663 21.2526V12.7526C31.1663 5.66927 28.333 2.83594 21.2497 2.83594H12.7497C5.66634 2.83594 2.83301 5.66927 2.83301 12.7526V21.2526C2.83301 28.3359 5.66634 31.1693 12.7497 31.1693Z"
                      stroke="#1FAF38"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.9795 17.0014L14.9887 21.0105L23.0212 12.9922"
                      stroke="#1FAF38"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            ) : text.accepted_statuses === "Declined" ? (
              <span
                style={{
                  backgroundColor: "#E51837",
                  color: "#fff",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRadius: "10px",
                  width: "150px",
                }}
              >
                {/* {text.accepted_statuses === "Inactive"
                ? "Inactive"
                : text.accepted_statuses === "Upcoming"
                ? "Upcoming"
                : text.accepted_statuses === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
                {text?.accepted_statuses}
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: "#1FBA77",
                  color: "#fff",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRadius: "10px",
                  width: "150px",
                }}
              >
                {/* {text.accepted_statuses === "Inactive"
                ? "Inactive"
                : text.accepted_statuses === "Upcoming"
                ? "Upcoming"
                : text.accepted_statuses === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
                {text?.accepted_statuses}
              </span>
            )}
          </>
        )
      ),
    },
    // {
    //   title: "Status",
    //   // dataIndex: "status",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => a.status - b.status,
    //     multiple: 7,
    //   },
    //   render: (text, record) => (
    //     console.log(text.status),
    //     (
    //       <div
    //         className="dropdown"
    //         style={{
    //           width: "100%",
    //           height: "30px",
    //           // border: "1px solid ",
    //           borderRadius: "15px",
    //           backgroundColor: text.status === 1 ? "#BDFDC8" : "#EFD6FF",
    //           cursor: "pointer",
    //         }}
    //       >
    //         <p
    //           style={{
    //             // padding: "auto",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             fontSize: "12px",
    //             padding: "5px 0px 5px",
    //           }}
    //           className="dropdown-toggle"
    //           data-bs-toggle="dropdown"
    //         >
    //           {text.status === 1 ? "Active" : "Deactivate"}
    //         </p>
    //         <div className="dropdown-menu">
    //           <a
    //             className="dropdown-item"
    //             href="#"
    //             // onClick={() => (text.status ? deActiveFun(text) : ActiveFun(text))}
    //           >
    //             {/* <i
    //             className={
    //               text.job_status
    //                 ? "fa fa-dot-circle-o text-danger"
    //                 : "fa fa-dot-circle-o text-success"
    //             }
    //           /> */}
    //             {text.status === 1 ? "Deactivate" : "Activate"}
    //           </a>
    //         </div>
    //       </div>
    //     )
    //   ),
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: false,
    //   width: 100,
    //   render: (admindata) => (
    //     <div className="edit_icons">
    //       <div className="dropdown">
    //         <Button
    //           type="button"
    //           id="dropdownMenuButton1"
    //           data-bs-toggle="dropdown"
    //           aria-expanded="false"
    //         >
    //           <Icon icon="ion:ellipsis-vertical" color="black" />
    //         </Button>
    //         <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    //           <li>
    //             <div
    //             /*
    //               style={{
    //                 display: "flex",
    //                 flexDirection: "row",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //               }}
    //               */
    //             >
    //               <a
    //                 className="dropdown-item"
    //                 href="#EditUser"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   Seteditadmin1(true);
    //                 }}
    //               >
    //                 <div>
    //                   <Icon icon="mdi:lead-pencil" />
    //                 </div>
    //                 &nbsp; Edit
    //               </a>
    //             </div>
    //           </li>
    //           <li>
    //             <div>
    //               <a
    //                 className="dropdown-item"
    //                 href="#candidates"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   // setResetPassword(true);
    //                   // Seteditdata(admindata);
    //                   setTable(2);
    //                 }}
    //               >
    //                 <div>
    //                   {/* <Icon icon="mdi:password-reset" /> */}
    //                   <Icon icon="gridicons:multiple-users" color="black" />
    //                 </div>
    //                 &nbsp; Registered Candidates
    //               </a>
    //             </div>
    //           </li>
    //           <li>
    //             <div>
    //               <a
    //                 className="dropdown-item"
    //                 href="#companies"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   // setResetPassword(true);
    //                   // Seteditdata(admindata);
    //                   fetchJPList(admindata.key);
    //                   setTable(1);
    //                 }}
    //               >
    //                 <div>
    //                   {/* <Icon icon="mdi:password-reset" /> */}
    //                   <Icon icon="bi:buildings-fill" hFlip={true} />
    //                 </div>
    //                 &nbsp; Registered Companies
    //               </a>
    //             </div>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const colName2 = [
    {
      title: "Candidate ID",
      dataIndex: "key",
      fixed: "left",
      width: 200,
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
        multiple: 1,
      },
    },
    {
      title: "Candidate Name",
      dataIndex: "name",
      fixed: "left",
      width: 200,
      // render: (text, record) => (
      //   <>
      //     {/* {`${text?.firstnm[0].toUpperCase() + text?.firstnm.slice(1)}` +
      //       " " +
      //       `${text?.lastnm[0].toUpperCase() + text?.lastnm.slice(1)}`} */}
      //     {/* {text.jfName !== "" ? text.jfName : " - "} */}
      //   </>
      // ),
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
        multiple: 1,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 2,
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobnum",
      width: 150,
      sorter: {
        compare: (a, b) => a?.mobnum - b?.mobnum,
        multiple: 3,
      },
    },
    {
      title: "Created Date",
      dataIndex: "date_of_creation",
      width: 150,
      sorter: {
        compare: (a, b) =>
          moment(a.date_of_creation, "DD-MM-YYYY") - moment(b.date_of_creation, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    {
      title: "Modified Date",
      dataIndex: "modify_date",
      width: 150,
      sorter: {
        compare: (a, b) =>
          moment(a.modify_date, "DD-MM-YYYY") - moment(b.modify_date, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    {
      title: "State",
      dataIndex: "state",
      width: 200,
      sorter: {
        compare: (a, b) => a?.state?.localeCompare(b?.state),
        multiple: 2,
      },
    },
    {
      title: "District",
      dataIndex: "district",
      width: 200,
      sorter: {
        compare: (a, b) => a?.district?.localeCompare(b?.district),
        multiple: 2,
      },
    },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   width: 200,
    //   sorter: {
    //     compare: (a, b) => a?.address?.localeCompare(b?.address),
    //     multiple: 2,
    //   },
    // },
    {
      title: "Education",
      dataIndex: "education",
      // fixed: "left",
      width: 200,
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.education?.localeCompare(b?.education),
        multiple: 1,
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      // fixed: "left",
      width: 200,
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.role?.localeCompare(b?.role),
        multiple: 1,
      },
    },
    {
      title: "specialization",
      dataIndex: "specialization",
      // fixed: "left",
      width: 200,
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.specialization?.localeCompare(b?.specialization),
        multiple: 1,
      },
    },
    {
      title: "Skills",
      dataIndex: "skills",
      // fixed: "left",
      width: 200,
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.skills?.localeCompare(b?.skills),
        multiple: 1,
      },
    },
    {
      title: "Job Fair Attended",
      // dataIndex: "is_attended",
      width: 200,
      /*
      sorter: {
        compare: (a, b) => a?.is_attended?.localeCompare(b?.is_attended),
        multiple: 2,
      },
      */
      render: (text) => (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <SoftBadge
            variant="contained"
            color={text.is_attended ? "success" : "error"}
            size="md"
            badgeContent={text.is_attended ? "Yes" : "No"}
            container
          />
        </span>
      ),
    },
    {
      title: "Company Visited",
      // dataIndex: "company_visited",
      width: 200,
      sorter: {
        compare: (a, b) => a?.company_visited - b?.company_visited,
        multiple: 2,
      },
      render: (text) => (
        <span
          style={{
            textDecoration: "underline",
            display: "flex",
            justifyContent: "center",
            color: "#1B59F8",
            // fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={() => (setTableModal2(true), fetchCompanyInteractedList(text.key, text.jf_id))}
        >
          {text.company_visited}
        </span>
      ),
    },
    {
      title: "Status",
      // dataIndex: "jfStatus",
      width: 200,
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status),
        multiple: 2,
      },
      render: (text) => (
        console.log("jf status:", text.status),
        (
          <span
            style={{
              backgroundColor:
                text.status === "Rejected"
                  ? "#FF0000"
                  : text.status === "On Hold"
                  ? "#FFAE5E"
                  : text.status === "Shortlisted"
                  ? "#00981A"
                  : "#FFAE5E",
              color: "#fff",
              // text.status === "Inactive"
              //   ? "#049C2F"
              //   : text.status === "Upcoming"
              //   ? "#62049C"
              //   : text.status === "Ongoing"
              //   ? "#EA7500"
              //   : "",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              // paddingTop: "3px",
              // paddingBottom: "3px",
              // paddingLeft: "15px",
              // paddingRight: "15px",
              borderRadius: "10px",
              width: "150px",
            }}
          >
            {/* {text.status === "Inactive"
                ? "Inactive"
                : text.status === "Upcoming"
                ? "Upcoming"
                : text.status === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
            {text?.status !== "-" ? text?.status : "On Hold"}
          </span>
        )
      ),
    },
    // {
    //   title: "Status",
    //   // dataIndex: "status",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => a.status - b.status,
    //     multiple: 7,
    //   },
    //   render: (text, record) => (
    //     console.log(text.status),
    //     (
    //       <div
    //         className="dropdown"
    //         style={{
    //           width: "100%",
    //           height: "30px",
    //           // border: "1px solid ",
    //           borderRadius: "15px",
    //           backgroundColor: text.status === 1 ? "#BDFDC8" : "#EFD6FF",
    //           cursor: "pointer",
    //         }}
    //       >
    //         <p
    //           style={{
    //             // padding: "auto",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             fontSize: "12px",
    //             padding: "5px 0px 5px",
    //           }}
    //           className="dropdown-toggle"
    //           data-bs-toggle="dropdown"
    //         >
    //           {text.status === 1 ? "Active" : "Deactivate"}
    //         </p>
    //         <div className="dropdown-menu">
    //           <a
    //             className="dropdown-item"
    //             href="#"
    //             // onClick={() => (text.status ? deActiveFun(text) : ActiveFun(text))}
    //           >
    //             {/* <i
    //             className={
    //               text.job_status
    //                 ? "fa fa-dot-circle-o text-danger"
    //                 : "fa fa-dot-circle-o text-success"
    //             }
    //           /> */}
    //             {text.status === 1 ? "Deactivate" : "Activate"}
    //           </a>
    //         </div>
    //       </div>
    //     )
    //   ),
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: false,
    //   width: 100,
    //   render: (admindata) => (
    //     <div className="edit_icons">
    //       <div className="dropdown">
    //         <Button
    //           type="button"
    //           id="dropdownMenuButton1"
    //           data-bs-toggle="dropdown"
    //           aria-expanded="false"
    //         >
    //           <Icon icon="ion:ellipsis-vertical" color="black" />
    //         </Button>
    //         <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    //           <li>
    //             <div
    //             /*
    //               style={{
    //                 display: "flex",
    //                 flexDirection: "row",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //               }}
    //               */
    //             >
    //               <a
    //                 className="dropdown-item"
    //                 href="#EditUser"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   Seteditadmin(true);
    //                   Seteditdata(admindata);
    //                 }}
    //               >
    //                 <div>
    //                   <Icon icon="mdi:lead-pencil" />
    //                 </div>
    //                 &nbsp; Edit
    //               </a>
    //             </div>
    //           </li>
    //           <li>
    //             <div>
    //               <a
    //                 className="dropdown-item"
    //                 href="#candidates"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   // setResetPassword(true);
    //                   // Seteditdata(admindata);
    //                   setTable(2);
    //                 }}
    //               >
    //                 <div>
    //                   {/* <Icon icon="mdi:password-reset" /> */}
    //                   <Icon icon="gridicons:multiple-users" color="black" />
    //                 </div>
    //                 &nbsp; Registered Candidates
    //               </a>
    //             </div>
    //           </li>
    //           <li>
    //             <div>
    //               <a
    //                 className="dropdown-item"
    //                 href="#companies"
    //                 style={{ display: "flex" }}
    //                 onClick={() => {
    //                   // setResetPassword(true);
    //                   // Seteditdata(admindata);
    //                   fetchJPList(admindata.key);
    //                   setTable(1);
    //                 }}
    //               >
    //                 <div>
    //                   {/* <Icon icon="mdi:password-reset" /> */}
    //                   <Icon icon="bi:buildings-fill" hFlip={true} />
    //                 </div>
    //                 &nbsp; Registered Companies
    //               </a>
    //             </div>
    //           </li>
    //           {/*
    //           <li>
    //             <div
    //               style={{
    //                 display: "flex",
    //                 flexDirection: "row",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //               }}
    //             >
    //               <div style={{ marginLeft: "10px" }}>
    //                 <Icon icon="fluent-mdl2:deactivate-orders" />
    //               </div>
    //               <a
    //                 className="dropdown-item"
    //                 href="#Deleteuser"
    //                 // onClick={() => {
    //                 //   if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
    //                 //     DeleteClientUser(e.id)
    //                 //   } else {
    //                 //     toast.error("Admin Cannot Delete")
    //                 //   }

    //                 // }}
    //                 onClick={() => {
    //                   deActiveFun(admindata);
    //                 }}
    //               >
    //                 Deactivate
    //               </a>
    //             </div>
    //           </li>
    //           */}
    //         </ul>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const tableCol1 = [
    {
      title: "Candidate Name",
      dataIndex: "cand_name",
      width: 150,
      fixed: "left",
      sorter: {
        compare: (a, b) => a?.cand_name?.localeCompare(b?.cand_name),
        multiple: 1,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      width: 150,
      sorter: {
        compare: (a, b) => a?.mobnum - b?.mobnum,
        multiple: 2,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 3,
      },
    },
    {
      title: "Company Role",
      dataIndex: "company_role",
      width: 150,
      sorter: {
        compare: (a, b) => a?.company_role?.localeCompare(b?.company_role),
        multiple: 4,
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 150,
      sorter: {
        compare: (a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    {
      title: "Status",
      // dataIndex: "jfStatus",
      width: 200,
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status),
        multiple: 2,
      },
      render: (text) => (
        console.log("jf status:", text.status),
        (
          <span
            style={{
              backgroundColor:
                text.status === "Rejected"
                  ? "#FF00008F"
                  : text.status === "Shortlisted"
                  ? "#00981AB0"
                  : text.status === "On Hold"
                  ? "#FFAE5E"
                  : "#FF5C00",
              color: "#fff",
              // text.status === "Inactive"
              //   ? "#049C2F"
              //   : text.status === "Upcoming"
              //   ? "#62049C"
              //   : text.status === "Ongoing"
              //   ? "#EA7500"
              //   : "",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              // paddingTop: "3px",
              // paddingBottom: "3px",
              // paddingLeft: "15px",
              // paddingRight: "15px",
              borderRadius: "10px",
              width: "150px",
            }}
          >
            {/* {text.status === "Inactive"
                ? "Inactive"
                : text.status === "Upcoming"
                ? "Upcoming"
                : text.status === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
            {text?.status}
          </span>
        )
      ),
    },
  ];

  const tableCol2 = [
    {
      title: "Company Name",
      dataIndex: "cand_name",
      width: 150,
      fixed: "left",
      sorter: {
        compare: (a, b) => a?.cand_name?.localeCompare(b?.cand_name),
        multiple: 1,
      },
    },
    // {
    //   title: "Phone Number",
    //   dataIndex: "mobnum",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => a?.mobnum - b?.mobnum,
    //     multiple: 2,
    //   },
    // },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   width: 150,
    //   sorter: {
    //     compare: (a, b) => a?.email?.localeCompare(b?.email),
    //     multiple: 3,
    //   },
    // },
    {
      title: "Company Role",
      dataIndex: "company_role",
      width: 150,
      sorter: {
        compare: (a, b) => a?.company_role?.localeCompare(b?.company_role),
        multiple: 4,
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 150,
      sorter: {
        compare: (a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    {
      title: "Status",
      // dataIndex: "jfStatus",
      width: 200,
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status),
        multiple: 2,
      },
      render: (text) => (
        console.log("jf status:", text.status),
        (
          <span
            style={{
              backgroundColor:
                text.status === "Rejected"
                  ? "#E51837"
                  : text.status === "Shortlisted"
                  ? "#1FBA77"
                  : text.status === "On Hold"
                  ? "#73B0F9"
                  : "#FF5C00",
              color: "#fff",
              // text.status === "Inactive"
              //   ? "#049C2F"
              //   : text.status === "Upcoming"
              //   ? "#62049C"
              //   : text.status === "Ongoing"
              //   ? "#EA7500"
              //   : "",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              // paddingTop: "3px",
              // paddingBottom: "3px",
              // paddingLeft: "15px",
              // paddingRight: "15px",
              borderRadius: "10px",
              width: "150px",
            }}
          >
            {/* {text.status === "Inactive"
                ? "Inactive"
                : text.status === "Upcoming"
                ? "Upcoming"
                : text.status === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
            {text?.status}
          </span>
        )
      ),
    },
  ];

  const colName4 = [
    {
      title: "Company Name",
      //dataIndex: "company_name",
      width: 270,
      render: (text, record) => (
        <p
          onClick={() => {
            setText(text);
            setCompanyModal(true);
            console.log(text, "444");
            fetchCompanyAttendedList(text.key);
          }}
          style={{
            cursor: "pointer",
            marginBottom: "-2px",
            color: "#3B5092",
            textDecorationLine: "underline",
          }}
        >
          {`${text.company_name}`}
        </p>
      ),
      sorter: {
        compare: (a, b) => a?.company_name?.localeCompare(b?.company_name),
        multiple: 1,
      },
    },
    // {
    //   title: "Job Role",
    //   dataIndex: "job_role",
    //   width: 70,
    //   sorter: {
    //     // compare: (a, b) => a.org_name - b.org_name,
    //     compare: (a, b) => a?.job_role?.localeCompare(b?.job_role),
    //     multiple: 5,
    //   },
    // },
    {
      title: "Total Required Candidates",
      dataIndex: "required_candidates",
      //fixed: "left",
      // width: 80,
      sorter: {
        // compare: (a, b) => a.name - b.name,
        compare: (a, b) => a?.required_candidates - b?.required_candidates,
        multiple: 1,
      },
    },
    {
      title: "Candidates Interacted",
      dataIndex: "cand_interacted",
      // width: 100,
      sorter: {
        // compare: (a, b) => a.org_size - b.org_size,
        compare: (a, b) => a?.cand_interacted - b?.cand_interacted,
        multiple: 6,
      },
    },
    {
      title: "Candidates Shortlisted",
      dataIndex: "cand_shortlisted",
      // width: 100,
      sorter: {
        // compare: (a, b) => a.org_size - b.org_size,
        compare: (a, b) => a?.cand_shortlisted - b?.cand_shortlisted,
        multiple: 6,
      },
    },
    {
      title: "Job Fairs Attended",
      dataIndex: "job_fairs_attended",
      width: 150,
      sorter: {
        // compare: (a, b) => a.org_state - b.org_state,
        compare: (a, b) => a?.job_fairs_attended - b?.job_fairs_attended,
        multiple: 3,
      },
    },
    // {
    //   title: "Onboarding Status",
    //   // dataIndex: "onboarding_status",
    //   width: 100,
    //   sorter: {
    //     // compare: (a, b) => a.org_address - b.org_address,
    //     compare: (a, b) => a?.onboarding_status?.localeCompare(b?.onboarding_status),
    //     multiple: 2,
    //   },
    //   render: (text) => (
    //     console.log("onboard status:", text.onboarding_status),
    //     (
    //       <span
    //         style={{
    //           backgroundColor: text.onboarding_status === "Accepted" ? "#53CA96" : "#FFA978",
    //           color: "#fff",
    //           // text.status === "Inactive"
    //           //   ? "#049C2F"
    //           //   : text.status === "Upcoming"
    //           //   ? "#62049C"
    //           //   : text.status === "Ongoing"
    //           //   ? "#EA7500"
    //           //   : "",
    //           display: "flex",
    //           flexDirection: "row",
    //           justifyContent: "center",
    //           alignContent: "center",
    //           paddingTop: "5px",
    //           paddingBottom: "5px",
    //           // paddingTop: "3px",
    //           // paddingBottom: "3px",
    //           // paddingLeft: "15px",
    //           // paddingRight: "15px",
    //           borderRadius: "10px",
    //           width: "150px",
    //         }}
    //       >
    //         {/* {text.status === "Inactive"
    //             ? "Inactive"
    //             : text.status === "Upcoming"
    //             ? "Upcoming"
    //             : text.status === "Ongoing"
    //             ? "Ongoing"
    //             : "Completed"} */}
    //         {text.onboarding_status === "Accepted" ? "Onboarded" : "Not Onboarded"}
    //       </span>
    //     )
    //   ),
    // },

    // {
    //   title: "Status",
    //   key: "6",
    //   width: 50,
    //   sorter: {
    //     compare: (a, b) => a.status - b.status,
    //     multiple: 7,
    //   },
    //   render: (text, record) => (
    //     <div
    //       // className="dropdown action-label text-center toggle-btn"
    //       className="dropdown"
    //       style={{
    //         width: "100%",
    //         height: "30px",
    //         // border: "1px solid ",
    //         borderRadius: "15px",
    //         backgroundColor: text.status ? "#BDFDC8" : "#EFD6FF",
    //         cursor: "pointer",
    //       }}
    //     >
    //       <p
    //         style={{
    //           // padding: "auto",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           fontSize: "12px",
    //           padding: "5px 0px 5px",
    //         }}
    //         className="dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //       >
    //         {text.status ? "Active" : "Deactivate"}
    //       </p>
    //       <div className="dropdown-menu">
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           //onClick={() => (text.job_status ? deActiveFun(text) : ActiveFun(text))}
    //         >
    //           {text.status ? "Deactivate" : "Active"}
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const [candDetails, setCandDetails] = useState({});
  const [canid, setCanId] = useState("");
  const fetchCandDetails = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/candidate-profile`, {
        params: {
          registration_id: id,
        },
      })
      .then((res) => {
        setCandDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setCandDetails({});
      });
  };
  // useEffect(()=>{
  //   fetchCandDetails();
  // },[])
  const colName5 = [
    {
      title: "Candidate Name",
      //dataIndex: "company_name",
      fixed: "left",
      width: 100,
      render: (text, record) => (
        <p
          onClick={() => {
            setText(text);
            setStudentModal(true);
            console.log(text, "4445");
            fetchCandDetails(text.key);
            fetchCandidateAttendedList(text.key);
          }}
          style={{
            cursor: "pointer",
            marginBottom: "-2px",
            color: "#3B5092",
            textDecorationLine: "underline",
          }}
        >
          {`${text.candidate_name}`}
        </p>
      ),
      sorter: {
        compare: (a, b) => a?.candidate_name?.localeCompare(b?.candidate_name),
        multiple: 1,
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      width: 100,
      sorter: {
        compare: (a, b) => a?.mobile_number - b?.mobile_number,
      },
    },
    {
      title: "Email",
      //dataIndex: "email",
      width: 100,
      sorter: {
        // compare: (a, b) => a.org_size - b.org_size,
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 6,
      },
      render: (text) => (
        <>{text.email === "" || text.email === "nan" || text.email === null ? "-" : text.email}</>
      ),
    },
    // {
    //   title: "Date",
    //   //dataIndex: "date",
    //   //fixed: "left",
    //   width: 100,
    //   sorter: {
    //     compare: (a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
    //     multiple: 4,
    //   },
    //   render: (text) => <>{text.date === "" ? "-" : text.date}</>,
    // },
    // {
    //   title: "Shortlisted for",
    //   dataIndex: "shortlisted_for",
    //   width: 70,
    //   sorter: {
    //     // compare: (a, b) => a.org_address - b.org_address,
    //     compare: (a, b) => a?.shortlisted_for?.localeCompare(b?.shortlisted_for),
    //     multiple: 2,
    //   },
    // },
    {
      title: "State",
      dataIndex: "state",
      width: 100,
      sorter: {
        // compare: (a, b) => a.org_state - b.org_state,
        compare: (a, b) => a?.state?.localeCompare(b?.state),
        multiple: 3,
      },
    },
    {
      title: "District",
      dataIndex: "district",
      width: 100,
      sorter: {
        // compare: (a, b) => a.org_state - b.org_state,
        compare: (a, b) => a?.district?.localeCompare(b?.district),
        multiple: 3,
      },
    },
    {
      title: "City",
      dataIndex: "city",
      width: 100,
      sorter: {
        // compare: (a, b) => a.org_state - b.org_state,
        compare: (a, b) => a?.city?.localeCompare(b?.city),
        multiple: 3,
      },
    },
    {
      title: "Job Fair attended",
      dataIndex: "job_fair_attended",
      width: 100,
      sorter: {
        compare: (a, b) => a?.job_fair_attended - b?.job_fair_attended,
        multiple: 3,
      },
    },
    {
      title: "Company Visited",
      dataIndex: "company_visited",
      width: 100,
      sorter: {
        compare: (a, b) => a?.company_visited - b?.company_visited,
        multiple: 3,
      },
    },
    {
      title: "Resume",
      // dataIndex: "operation",
      // key: "operation",
      width: 100,
      render: (text, record) => (
        <Link to={text.resume} target="_blank">
          <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
            <Icon icon="ph:eye" width="24" height="24" style={{ color: "black" }} />
          </div>
        </Link>
      ),
    },
    {
      title: "Status",
      // dataIndex: "jfStatus",
      width: 100,
      sorter: {
        compare: (a, b) => a?.applicant_status?.localeCompare(b?.applicant_status),
        multiple: 2,
      },
      render: (text) => (
        console.log("jf applicant_status:", text.applicant_status),
        (
          <span
            style={{
              backgroundColor:
                text.applicant_status === "Rejected" || text.applicant_status === "rejected"
                  ? "#FF0000"
                  : text.applicant_status === "On Hold"
                  ? "#FFAE5E"
                  : text.applicant_status === "Shortlisted" ||
                    text.applicant_status === "Selected" ||
                    text.applicant_status === "Joined"
                  ? "#00981A"
                  : "#FFAE5E",
              color: "#fff",
              // text.applicant_status === "Inactive"
              //   ? "#049C2F"
              //   : text.applicant_status === "Upcoming"
              //   ? "#62049C"
              //   : text.applicant_status === "Ongoing"
              //   ? "#EA7500"
              //   : "",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              // paddingTop: "3px",
              // paddingBottom: "3px",
              // paddingLeft: "15px",
              // paddingRight: "15px",
              borderRadius: "10px",
              width: "150px",
            }}
          >
            {/* {text.applicant_status === "Inactive"
                ? "Inactive"
                : text.applicant_status === "Upcoming"
                ? "Upcoming"
                : text.applicant_status === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
            {text?.applicant_status !== "-"
              ? text?.applicant_status
              : text?.applicant_status === "rejected"
              ? "Rejected"
              : "On Hold"}
          </span>
        )
      ),
    },
    // {
    //   title: "Status",
    //   key: "6",
    //   width: 50,
    //   sorter: {
    //     compare: (a, b) => a.status - b.status,
    //     multiple: 7,
    //   },
    //   render: (text, record) => (
    //     <div
    //       // className="dropdown action-label text-center toggle-btn"
    //       className="dropdown"
    //       style={{
    //         width: "100%",
    //         height: "30px",
    //         // border: "1px solid ",
    //         borderRadius: "15px",
    //         backgroundColor: text.status ? "#BDFDC8" : "#EFD6FF",
    //         cursor: "pointer",
    //       }}
    //     >
    //       <p
    //         style={{
    //           // padding: "auto",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           fontSize: "12px",
    //           padding: "5px 0px 5px",
    //         }}
    //         className="dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //       >
    //         {text.status ? "Active" : "Deactivate"}
    //       </p>
    //       <div className="dropdown-menu">
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           //onClick={() => (text.job_status ? deActiveFun(text) : ActiveFun(text))}
    //         >
    //           {text.status ? "Deactivate" : "Active"}
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const colName7 = [
    {
      title: "Company Name",
      dataIndex: "org_name",
      width: 70,
      sorter: {
        compare: (a, b) => a?.org_name?.localeCompare(b?.org_name),
        multiple: 1,
      },
    },
    {
      title: "Job Role",
      dataIndex: "job_role",
      width: 70,
      sorter: {
        // compare: (a, b) => a.org_name - b.org_name,
        compare: (a, b) => a?.job_role?.localeCompare(b?.job_role),
        multiple: 5,
      },
    },
    // {
    //   title: "Industry Sector",
    //   dataIndex: "industry_sector",
    //   width: 80,
    //   sorter: {
    //     // compare: (a, b) => a.org_size - b.org_size,
    //     compare: (a, b) => a?.industry_sector?.localeCompare(b?.industry_sector),
    //     multiple: 6,
    //   },
    // },
    // {
    //   title: "Date",
    //   dataIndex: "date",

    //   width: 50,

    // },
    {
      title: "Status",
      //dataIndex: "status",
      width: 70,
      sorter: {
        // compare: (a, b) => a.org_address - b.org_address,
        compare: (a, b) => a?.status?.localeCompare(b?.status),
        multiple: 2,
      },
      render: (text) => (
        <span
          style={{
            backgroundColor:
              text.status === "Rejected"
                ? "rgba(255, 0, 0, 0.56)"
                : text.status === "On Hold" || text.status === "Applied"
                ? "#FFAE5E"
                : text.status === "Shortlisted" ||
                  text.status === "Selected" ||
                  text.status === "Joined"
                ? "rgba(0, 152, 26, 0.69)"
                : "#FF5C00",
            color: "#FFFFFF",
            // text.jfStatus === "Inactive"
            //   ? "#049C2F"
            //   : text.jfStatus === "Upcoming"
            //   ? "#62049C"
            //   : text.jfStatus === "Ongoing"
            //   ? "#EA7500"
            //   : "",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            // paddingTop: "3px",
            // paddingBottom: "3px",
            // paddingLeft: "15px",
            // paddingRight: "15px",
            borderRadius: "10px",
            width: "150px",
          }}
        >
          {/* {text.jfStatus === "Inactive"
                ? "Inactive"
                : text.jfStatus === "Upcoming"
                ? "Upcoming"
                : text.jfStatus === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
          {text?.status}
        </span>
      ),
    },
  ];

  const colName6 = [
    {
      title: "Candidate Name",
      dataIndex: "candidate_name",
      width: 100,
      sorter: {
        compare: (a, b) => a?.candidate_name?.localeCompare(b?.candidate_name),
        multiple: 1,
      },
    },
    {
      title: "Job Role",
      dataIndex: "job_role",
      width: 70,
      sorter: {
        // compare: (a, b) => a.org_name - b.org_name,
        compare: (a, b) => a?.job_role?.localeCompare(b?.job_role),
        multiple: 5,
      },
    },
    // {
    //   title: "Industry Sector",
    //   dataIndex: "industry_sector",
    //   width: 80,
    //   sorter: {
    //     // compare: (a, b) => a.org_size - b.org_size,
    //     compare: (a, b) => a?.industry_sector?.localeCompare(b?.industry_sector),
    //     multiple: 6,
    //   },
    // },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   fixed: "left",
    //   width: 100,

    // },
    {
      title: "Status",
      //dataIndex: "status",
      width: 70,
      sorter: {
        // compare: (a, b) => a.org_address - b.org_address,
        compare: (a, b) => a?.status?.localeCompare(b?.status),
        multiple: 2,
      },
      render: (text) => (
        <span
          style={{
            backgroundColor:
              text.status === "Rejected"
                ? "rgba(255, 0, 0, 0.56)"
                : text.status === "On Hold" || text.status === "Applied"
                ? "#FFAE5E"
                : text.status === "Shortlisted" ||
                  text.status === "Selected" ||
                  text.status === "Joined"
                ? "rgba(0, 152, 26, 0.69)"
                : "#FF5C00",
            color: "#FFFFFF",
            // text.jfStatus === "Inactive"
            //   ? "#049C2F"
            //   : text.jfStatus === "Upcoming"
            //   ? "#62049C"
            //   : text.jfStatus === "Ongoing"
            //   ? "#EA7500"
            //   : "",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            // paddingTop: "3px",
            // paddingBottom: "3px",
            // paddingLeft: "15px",
            // paddingRight: "15px",
            borderRadius: "10px",
            width: "150px",
          }}
        >
          {/* {text.jfStatus === "Inactive"
                ? "Inactive"
                : text.jfStatus === "Upcoming"
                ? "Upcoming"
                : text.jfStatus === "Ongoing"
                ? "Ongoing"
                : "Completed"} */}
          {text?.status}
        </span>
      ),
    },
  ];

  const registrationJP = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: pageOne.org_name ? pageOne.org_name : "",
      org_state: pageOne.org_state ? pageOne.org_state : "",
      org_district: pageOne.org_district ? pageOne.org_district : "",
      venue: pageOne.venue ? pageOne.venue : "",
      locality: pageOne.locality ? pageOne.locality : "",
      org_date: pageOne.org_date ? pageOne.org_date : "",
      org_time: pageOne.org_time ? pageOne.org_time : "",
      email: pageOne.email ? pageOne.email : "",
      mobnum: pageOne.mobnum ? pageOne.mobnum : "",
      lastnm: pageOne.lastnm ? pageOne.lastnm : "",
      banner: pageOne.banner_img ? pageOne.banner_img : "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please enter job fair name"),
      org_state: Yup.string().required("Please select state"),
      org_district: Yup.string().required("Please select district"),
      venue: Yup.string().required("Please enter venue details"),
      locality: Yup.string().required("Please enter locality"),
      org_date: Yup.string().required("Please select date"),
      org_time: Yup.string().required("Please select time"),
      lastnm: Yup.string().required("Please enter name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
    }),
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("name", values.org_name);
      formData.append("venue", values.venue);
      formData.append("locality", values.locality);
      formData.append("state", values.org_state);
      formData.append("district", values.org_district);
      formData.append("date", values.org_date);
      formData.append("time", values.org_time);
      formData.append("spoc_name", pageOne.lastnm);
      formData.append("spoc_mobile", pageOne.mobnum);
      formData.append("spoc_email", pageOne.email);
      formData.append("banner", pageOne.banner_img === undefined ? "" : pageOne.banner_img);
      formData.append("spoc_details", "");
      formData.append("js_link", pageOne.jslink ? pageOne.jslink : jslink);
      formData.append("jp_link", pageOne.jslink ? pageOne.jslink : jplink);
      formData.append("is_active", false);
      formData.append(
        "created_by",
        sessionStorage.getItem("register_id") ? sessionStorage.getItem("register_id") : ""
      );
      formData.append("rel_id", pageOne.rel_id);
      formData.append("jf_id", pageOne.id);

      await axios
        .patch(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobfair`,
          formData
          //  {
          //   name: values.org_name,
          //   venue: values.venue,
          //   locality: values.locality,
          //   state: values.org_state,
          //   district: values.org_district,
          //   date: values.org_date,
          //   time: values.org_time,
          //   spoc_name: values.lastnm,
          //   spoc_mobile: pageOne.mobnum,
          //   spoc_email: pageOne.email,
          //   spoc_details: "",
          //   js_link: pageOne.jslink,
          //   jp_link: pageOne.jplink,
          //   is_active: false,
          //   created_by: sessionStorage.getItem("register_id")
          //     ? sessionStorage.getItem("register_id")
          //     : "",
          //   jf_id: pageOne.id,
          //   banner_img: values.banner,

          // }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Updated successfully",
            //text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          });
          setPageOne({
            org_name: "",
            org_state: "",
            org_district: "",
            venue: "",
            locality: "",
            org_date: "",
            org_time: "",
            lastnm: "",
            mobnum: "",
            email: "",
            id: "",
            jplink: "",
            jslink: "",
            banner_img: "",
          });
          Seteditadmin1(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err?.response?.data
              ? err.response.data["Developer Message"]
              : "Something went wrong",
            text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  });

  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const onTableRowExpand = async (expanded, record) => {
    // setCanId(record.District);
    // setVidhan(record.venue_name);
    const keys = [];
    if (expanded) {
      keys.push(record.key); // I have set my record.id as row key. Check the documentation for more details.
    }

    await setExpandedRowKeys(keys);
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };

  const fetchJFList = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobfair`, {
        params: {
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobfair`)
      .then((res) => {
        // console.log("data list:", res.data);
        setJFList(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              // organization: item.org_name ,
              // location: item.org_address,
              jfName: item.name,
              name: item.spoc_name,
              email: item.spoc_email,
              mobnum: item.spoc_mobile.substring(0),
              date_of_creation: moment(item.creation_date).utc().format("DD-MM-YYYY"),
              modify_date: moment(item.modify_date).utc().format("DD-MM-YYYY"),
              status: item.is_active,
              jfStatus: item.status,
              date: moment(item.date).format("DD-MM-YYYY"),
              time: moment(item.time, "HH:mm:ss").format("hh:mm A"),
              state: item.state,
              district: item.district,
              venue: item.venue,
              locality: item.locality,
              jpLink: item.jp_link,
              jsLink: item.js_link,
              jp_link_qr: item.jp_link_qr,
              js_link_qr: item.js_link_qr,
              spoc_details: item.spoc_details,
              cand_reg: item.candidate_registered ? Number(item.candidate_registered) : 0,
              cand_attended: item.candidate_attended ? Number(item.candidate_attended) : 0,
              comp_reg: item.company_registered ? Number(item.company_registered) : 0,
              comp_attended: item.company_attended ? Number(item.company_attended) : 0,
              total_shortlist: item.total_shortlisted ? item.total_shortlisted : " - ",
              total_onHold: item.total_onhold ? item.total_onhold : " - ",
              total_rejected: item.total_rejected ? item.total_rejected : " - ",
              // new stages count
              cand_reg: item.candidate_registered ? item.candidate_registered : " - ",
              cand_attended: item.candidate_attended ? item.candidate_attended : " - ",
              shortlisted: item.candidate_selected ? item.candidate_selected : " - ",
              onhold: item.count_underverification ? item.count_underverification : " - ",
              rejected: item.count_reject ? item.count_reject : " - ",
              selected: item.count_shortlisted ? item.count_shortlisted : " - ",
            };
          })
        );
        setSearchData(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              // organization: item.org_name ,
              // location: item.org_address,
              jfName: item.name,
              name: item.spoc_name,
              email: item.spoc_email,
              mobnum: item.spoc_mobile.substring(0),
              date_of_creation: moment(item.creation_date).utc().format("DD-MM-YYYY"),
              modify_date: moment(item.modify_date).utc().format("DD-MM-YYYY"),
              status: item.is_active,
              jfStatus: item.status,
              date: moment(item.date).format("DD-MM-YYYY"),
              time: moment(item.time, "HH:mm:ss").format("hh:mm A"),
              state: item.state,
              district: item.district,
              venue: item.venue,
              locality: item.locality,
              jpLink: item.jp_link,
              jsLink: item.js_link,
              jp_link_qr: item.jp_link_qr,
              js_link_qr: item.js_link_qr,
              spoc_details: item.spoc_details,
              cand_reg: item.candidate_registered ? Number(item.candidate_registered) : 0,
              cand_attended: item.candidate_attended ? Number(item.candidate_attended) : 0,
              comp_reg: item.company_registered ? Number(item.company_registered) : 0,
              comp_attended: item.company_attended ? Number(item.company_attended) : 0,
              total_shortlist: item.total_shortlisted ? item.total_shortlisted : " 0 ",
              total_onHold: item.total_onhold ? item.total_onhold : " 0 ",
              total_rejected: item.total_rejected ? item.total_rejected : " 0 ",
              // new stages count
              cand_reg: item.candidate_registered ? item.candidate_registered : " - ",
              cand_attended: item.candidate_attended ? item.candidate_attended : " - ",
              shortlisted: item.candidate_selected ? item.candidate_selected : " - ",
              onhold: item.count_underverification ? item.count_underverification : " - ",
              rejected: item.count_reject ? item.count_reject : " - ",
              selected: item.count_shortlisted ? item.count_shortlisted : " - ",
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchJPList = (jfId) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/job_provider_form`, {
        params: {
          jf_id: jfId,
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        // console.log("data list:", res);

        setSearchJPData(
          res.data.map((item, id) => {
            return {
              key: item.registration_id,
              company_name: item.org_name,
              mobnum: item?.mobnum
                ? item?.mobnum?.length === 12
                  ? item.mobnum?.substr(2)
                  : item.mobnum
                : "",
              email: item.email,
              state: item.org_state,
              district: item.org_district,
              address: item.org_address,
              cand_count: item.candidate_interacted,
              cand_shortlisted: item.candidate_shortlisted,
              jobfair_attended: item.jf_attended,
              modify_date: moment(item.modifieddt).format("DD-MM-YYYY"),
              date_of_creation: moment(item.createddt).format("DD-MM-YYYY"),
              accepted_statuses: item.accepted_statuses,
              jpStatus: item.is_active,
              is_attended: item.attended,
            };
          })
        );
        setJPList(
          res.data.map((item, id) => {
            return {
              key: item.registration_id,
              company_name: item.org_name,
              mobnum: item.mobnum,
              email: item.email,
              state: item.org_state,
              district: item.org_district,
              address: item.org_address,
              cand_count: item.candidate_interacted,
              cand_shortlisted: item.candidate_shortlisted,
              jobfair_attended: item.jf_attended,
              modify_date: moment(item.modifieddt).format("DD-MM-YYYY"),
              date_of_creation: moment(item.createddt).format("DD-MM-YYYY"),
              accepted_statuses: item.accepted_statuses,
              jpStatus: item.is_active,
              is_attended: item.attended,
            };
          })
        );

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchJPRequirements = (jpId) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/job_provider_form`, {
        params: {
          jp_id: jpId,
          toggle: "jpost",
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        // const data = [];
        // for (let i = 0; i < 10; i++) {
        //   data.push({
        //     key: i + 1,
        //     company_name: `Sapio ${i + 1}`,
        //     mobnum: "9669669669",
        //     email: "sapio@gmail.com",
        //     state: "Maharashtra",
        //     district: "Mumbai",
        //     address: "Andheri",
        //     cand_count: "10",
        //     modify_date: "13-09-2023",
        //     date_of_creation: "13-09-2023",
        //   });
        // }
        setJpReqData(
          res.data?.map((item, id) => {
            return {
              // key: item.registration_id,
              // company_name: item.org_name,
              // mobnum: item.mobnum,
              // email: item.email,
              // state: item.org_state,
              // district: item.org_district,
              // address: item.org_address,
              // cand_count: item.session_count,
              // modify_date: moment(item.modifieddt).format("DD-MM-YYYY"),
              // date_of_creation: moment(item.createddt).format("DD-MM-YYYY"),
              key: item.jpo_id,
              job_role: item.job_role,
              required_count: item.count,
              interacted_count: item.count,
              job_desc: item.job_desc,
              jobpost_id: item.jpo_id,
              jf_id: item.jf_id,
              jp_id: item.jpr_id,
            };
          })
        );

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchCandidateList = (jfId) => {
    // const data = [];
    // for (let i = 0; i < 10; i++) {
    //   data.push({
    //     key: i + 1,
    //     name: `Gopal ${i + 1}`,
    //     mobnum: "9669669669",
    //     email: "gopal@gmail.com",
    //     state: "Maharashtra",
    //     district: "Mumbai",
    //     // address: "Andheri",
    //     // cand_count: "10",
    //     modify_date: "13-09-2023",
    //     date_of_creation: "13-09-2023",
    //     education: "HSC",
    //     skills: "MERN, Java",
    //     role: "Software Developer",
    //     experience: "1-3",
    //     specialization: "B.Tech",
    //     stream: "Engineering",
    //     datasource: "job fair",
    //     company_visited: "10",
    //     status: i % 2 === 0 ? "Placed" : i % 3 === 0 ? "Rejected" : "Shortlisted",
    //   });
    // }
    setIsLoading(true);
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/candidate_interacted`, {
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/job_seeker_registration`, {
        params: {
          // jpr_id: jpId,
          jf_id: jfId,
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        setCandList(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              name: item.first_name + " " + item.last_name,
              mobnum: item?.mobile
                ? item?.mobile?.length === 12
                  ? item.mobile?.substr(2)
                  : item.mobile
                : "-",
              email: item.email ? item.email : " - ",
              state: item.state ? item.state : " - ",
              district: item.district ? item.district : " - ",
              modify_date: item.modifieddt ? moment(item.modifieddt).format("DD-MM-YYYY") : " - ",
              date_of_creation: item.createddt
                ? moment(item.createddt).format("DD-MM-YYYY")
                : " - ",
              education: item.highest_education ? item.highest_education : " - ",
              skills: item.skills ? item.skills : " - ",
              role: item.job_role ? item.job_role : " - ",
              experience: item.experience ? item.experience : " - ",
              specialization: item.degree ? item.degree : " - ",
              stream: item.stream ? item.stream : " - ",
              datasource: item.datasource ? item.datasource : " - ",
              company_visited: item.company_visited ? item.company_visited : " 0 ",
              // status: i % 2 === 0 ? "Placed" : i % 3 === 0 ? "Rejected" : "Shortlisted",
              // job_role: item.job_role ? item.job_role : " - ",
              industry_sector: item.mobile ? item.mobile : " - ",
              // date: item.creation_datetime
              //   ? moment(item.creation_datetime).format("DD-MM-YYYY")
              //   : " - ",
              // status: item.applicant_status ? item.applicant_status : " - ",
              status: item.status,
              resume: item.resume_cv,
              is_attended: item.attended,
              jobfair_attended: item.jobfair_attended ? item.jobfair_attended : " - ",
              jf_id: item.jf_id,
            };
          })
        );
        setSearchCandData(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              name: item.first_name + " " + item.last_name,
              mobnum: item?.mobile
                ? item?.mobile?.length === 12
                  ? item.mobile?.substr(2)
                  : item.mobile
                : "-",
              email: item.email ? item.email : " - ",
              state: item.state ? item.state : " - ",
              district: item.district ? item.district : " - ",
              modify_date: item.modifieddt ? moment(item.modifieddt).format("DD-MM-YYYY") : " - ",
              date_of_creation: item.createddt
                ? moment(item.createddt).format("DD-MM-YYYY")
                : " - ",
              education: item.highest_education ? item.highest_education : " - ",
              skills: item.skills ? item.skills : " - ",
              role: item.job_role ? item.job_role : " - ",
              experience: item.experience ? item.experience : " - ",
              specialization: item.degree ? item.degree : " - ",
              stream: item.stream ? item.stream : " - ",
              datasource: item.datasource ? item.datasource : " - ",
              company_visited: item.company_visited ? item.company_visited : " 0 ",
              // status: i % 2 === 0 ? "Placed" : i % 3 === 0 ? "Rejected" : "Shortlisted",
              // job_role: item.job_role ? item.job_role : " - ",
              industry_sector: item.mobile ? item.mobile : " - ",
              // date: item.creation_datetime
              //   ? moment(item.creation_datetime).format("DD-MM-YYYY")
              //   : " - ",
              // status: item.applicant_status ? item.applicant_status : " - ",
              status: item.status,
              resume: item.resume_cv,
              is_attended: item.attended,
              jobfair_attended: item.jobfair_attended ? item.jobfair_attended : " - ",
              jf_id: item.jf_id,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
    // setSearchCandData(data);
    // setCandList(data);
  };

  const fetchCompanyAttendedList = (Id) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobfair_attended`, {
        params: {
          jpr_id: Id,
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        // const data = [];
        // for (let i = 0; i < 10; i++) {
        //   data.push({
        //     key: i + 1,
        //     company_name: `Sapio ${i + 1}`,
        //     mobnum: "9669669669",
        //     email: "sapio@gmail.com",
        //     state: "Maharashtra",
        //     district: "Mumbai",
        //     address: "Andheri",
        //     cand_count: "10",
        //     modify_date: "13-09-2023",
        //     date_of_creation: "13-09-2023",
        //   });
        // }
        setJobfairdata(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              date: item.date ? item.date : " - ",
              job_fair_name: item.name ? item.name : " - ",
              job_role: " - ",
              no_of_candidates: " - ",
              job_description: " - ",
              job_details: item.job_detail,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchCompanyInteractedList = (candId, jfId) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/company_interacted`, {
        params: {
          candidate_id: candId,
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
          jf_id: jfId,
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        // const data = [];
        // for (let i = 0; i < 10; i++) {
        //   data.push({
        //     key: i + 1,
        //     company_name: `Sapio ${i + 1}`,
        //     mobnum: "9669669669",
        //     email: "sapio@gmail.com",
        //     state: "Maharashtra",
        //     district: "Mumbai",
        //     address: "Andheri",
        //     cand_count: "10",
        //     modify_date: "13-09-2023",
        //     date_of_creation: "13-09-2023",
        //   });
        // }
        // {
        //       company_name: "test company 1",
        //       job_role: "job_role",
        //       industry_sector: "industry_sector",
        //       date: "01-02-2023",
        //       status: "Shortlisted",
        //     },
        setData7(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              candidate_name: item.first_name + " " + item.last_name,
              org_name: item.org_name,
              job_role: item.job_role_name ? item.job_role_name : " - ",
              industry_sector: " - ",
              date: item.createddt ? moment(item.createddt).format("DD-MM-YYYY") : " - ",
              status: item.selected_status ? item.selected_status : " - ",
              resume: item.resume_cv,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchAllCompanyInteractedList = (candId) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/company_interacted`, {
        params: {
          candidate_id: candId,
          // jf_id: jfId,
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        // const data = [];
        // for (let i = 0; i < 10; i++) {
        //   data.push({
        //     key: i + 1,
        //     company_name: `Sapio ${i + 1}`,
        //     mobnum: "9669669669",
        //     email: "sapio@gmail.com",
        //     state: "Maharashtra",
        //     district: "Mumbai",
        //     address: "Andheri",
        //     cand_count: "10",
        //     modify_date: "13-09-2023",
        //     date_of_creation: "13-09-2023",
        //   });
        // }
        // {
        //       company_name: "test company 1",
        //       job_role: "job_role",
        //       industry_sector: "industry_sector",
        //       date: "01-02-2023",
        //       status: "Shortlisted",
        //     },
        setData7(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              candidate_name: item.first_name + " " + item.last_name,
              org_name: item.org_name,
              job_role: item.job_role_name ? item.job_role_name : " - ",
              industry_sector: " - ",
              date: item.createddt ? moment(item.createddt).format("DD-MM-YYYY") : " - ",
              status: item.selected_status ? item.selected_status : " - ",
              resume: item.resume_cv,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchCompanyList = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/job_provider_form`, {
        params: {
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        setData4(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              company_name: item.org_name,
              // job_role: "job_role",
              industry_sector: item.org_sector ? item.org_sector : " - ",
              required_candidates: item.candidate_req ? Number(item.candidate_req) : 0,
              cand_interacted: item.candidate_interacted ? item.candidate_interacted : 0,
              cand_shortlisted: item.candidate_shortlisted ? item.candidate_shortlisted : 0,
              // onboarding_status: "onboarded",
              job_fairs_attended: item.jf_attended ? Number(item.jf_attended) : 0,
              // status: true,
              firstnm: item.firstnm,
              lastnm: item.lastnm,
              mobnum: item?.mobnum
                ? item?.mobnum?.length === 12
                  ? item.mobnum?.substr(2)
                  : item.mobnum
                : "",
              email: item.email,
              state: item.org_state,
              district: item.org_district,
              address: item.org_address,
              cand_count: item.session_count ? Number(item.session_count) : 0,
              modify_date: moment(item.modifieddt).format("DD-MM-YYYY"),
              date_of_creation: moment(item.createddt).format("DD-MM-YYYY"),
              // onboarding_status: item.accepted_statuses,
              onboarding_status: false,
              status: item.is_active,
              org_address: item.org_address ? item.org_address : " - ",
            };
          })
        );
        // const data = [];
        // for (let i = 0; i < 10; i++) {
        //   data.push({
        //     key: i + 1,
        //     company_name: `Sapio ${i + 1}`,
        //     mobnum: "9669669669",
        //     email: "sapio@gmail.com",
        //     state: "Maharashtra",
        //     district: "Mumbai",
        //     address: "Andheri",
        //     cand_count: "10",
        //     modify_date: "13-09-2023",
        //     date_of_creation: "13-09-2023",
        //   });
        // }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchCandidateAttendedList = (Id) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobfair_attended`, {
        params: {
          candidate_id: Id,
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        // const data = [];
        // for (let i = 0; i < 10; i++) {
        //   data.push({
        //     key: i + 1,
        //     company_name: `Sapio ${i + 1}`,
        //     mobnum: "9669669669",
        //     email: "sapio@gmail.com",
        //     state: "Maharashtra",
        //     district: "Mumbai",
        //     address: "Andheri",
        //     cand_count: "10",
        //     modify_date: "13-09-2023",
        //     date_of_creation: "13-09-2023",
        //   });
        // }
        setJobfairdata(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              date: item.date ? item.date : " - ",
              job_fair_name: item.name ? item.name : " - ",
              job_role: " - ",
              no_of_candidates: " - ",
              job_description: " - ",
              job_details: item.job_detail,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchCandidateInteractedList = (jpId, jfId) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/candidate_interacted`, {
        params: {
          jpr_id: jpId,
          jf_id: jfId,
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        // const data = [];
        // for (let i = 0; i < 10; i++) {
        //   data.push({
        //     key: i + 1,
        //     company_name: `Sapio ${i + 1}`,
        //     mobnum: "9669669669",
        //     email: "sapio@gmail.com",
        //     state: "Maharashtra",
        //     district: "Mumbai",
        //     address: "Andheri",
        //     cand_count: "10",
        //     modify_date: "13-09-2023",
        //     date_of_creation: "13-09-2023",
        //   });
        // }
        setData6(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              candidate_name: item.first_name + " " + item.last_name,
              job_role: item.job_role ? item.job_role : " - ",
              industry_sector: " - ",
              date: item.creation_datetime
                ? moment(item.creation_datetime).format("DD-MM-YYYY")
                : " - ",
              status: item.applicant_status ? item.applicant_status : " - ",
              resume: item.resume_cv,
              jf_id: item.jf_id,
            };
          })
        );
        setTableModal1Data(
          res.data.map((item, id) => {
            return {
              key: item.registration_id,
              cand_name: item.first_name + " " + item.last_name,
              mobnum: item.mobile,
              email: item.email,
              date: item.creation_datetime
                ? moment(item.creation_datetime).format("DD-MM-YYYY")
                : " - ",
              company_role: item.job_role ? item.job_role : " - ",
              status: item.applicant_status ? item.applicant_status : " - ",
              resume: item.resume_cv,
              jf_id: item.jf_id,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchAllCandidateInteractedList = (jpId) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/candidate_interacted`, {
        params: {
          jpr_id: jpId,
          // jf_id: jfId,
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("data list:", res.data);
        // const data = [];
        // for (let i = 0; i < 10; i++) {
        //   data.push({
        //     key: i + 1,
        //     company_name: `Sapio ${i + 1}`,
        //     mobnum: "9669669669",
        //     email: "sapio@gmail.com",
        //     state: "Maharashtra",
        //     district: "Mumbai",
        //     address: "Andheri",
        //     cand_count: "10",
        //     modify_date: "13-09-2023",
        //     date_of_creation: "13-09-2023",
        //   });
        // }
        setData6(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              candidate_name: item.first_name + " " + item.last_name,
              job_role: item.job_role ? item.job_role : " - ",
              industry_sector: " - ",
              date: item.creation_datetime
                ? moment(item.creation_datetime).format("DD-MM-YYYY")
                : " - ",
              status: item.applicant_status ? item.applicant_status : " - ",
              resume: item.resume_cv,
              jf_id: item.jf_id,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  const fetchAllCandidateList = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/candidate_interacted`, {
        params: {
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("data list:", res.data);

        setSearchData5(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              candidate_name: item.first_name + " " + item.last_name,
              mobile_number: item?.mobile?.length === 12 ? item.mobile.substring(2) : item.mobile,
              email: item.email,
              date: item.createddt,
              // shortlisted_for: "onboarded",
              city: item.taluka ? item.taluka : "-",
              district: item.district ? item.district : "-",
              state: item.state ? item.state : "-",
              job_fair_attended: item.jobfair_attended ? item.jobfair_attended : " - ",
              // candidate_status: "Onhold",
              // status: true,
              resume: item.resume_cv,
              candidate_status: item.applicant_status,
              datasource: item.datasource ? item.datasource : " - ",
              job_role: item.job_role ? item.job_role : " - ",
              experience: item.experience ? item.experience : " - ",
              dob: item.date_of_birth ? item.date_of_birth : " - ",
              current_salary: item.current_salary ? item.current_salary : " - ",
              desired_salary: item.desired_salary ? item.desired_salary : " - ",
              degree: item.degree ? item.degree : " - ",
              highest_education: item.highest_education ? item.highest_education : " - ",
              skills: item.skills ? item.skills : " - ",
              jf_id: item.jf_id,
              company_visited: item.company_visited,
              applicant_status:
                item.applicant_status === "rejected"
                  ? "Rejected"
                  : item.applicant_status !== "-"
                  ? item.applicant_status
                  : "On Hold",
              company_name: item.company_name !== "-" ? item.company_name : " - ",
              job_title: item.job_title !== "-" ? item.job_title : " - ",
            };
          })
        );
        setData5(
          res.data?.map((item, id) => {
            return {
              key: item.registration_id,
              candidate_name: item.first_name + " " + item.last_name,
              mobile_number: item?.mobile?.length === 12 ? item.mobile.substring(2) : item.mobile,
              email: item.email,
              date:
                item.createddt === "" || item.createddt === "nan" || item.createddt === null
                  ? ""
                  : moment(item.createddt).format("DD-MM-YYYY"),
              // shortlisted_for: "onboarded",
              city: item.taluka ? item.taluka : "-",
              district: item.district ? item.district : "-",
              state: item.state ? item.state : "-",
              job_fair_attended: item.jobfair_attended ? item.jobfair_attended : " - ",
              // candidate_status: "Onhold",
              // status: true,
              resume: item.resume_cv,
              candidate_status: item.applicant_status,
              datasource: item.datasource ? item.datasource : " - ",
              job_role: item.job_role ? item.job_role : " - ",
              experience: item.experience ? item.experience : " - ",
              dob: item.date_of_birth ? item.date_of_birth : " - ",
              current_salary: item.current_salary ? item.current_salary : " - ",
              desired_salary: item.desired_salary ? item.desired_salary : " - ",
              degree: item.degree ? item.degree : " - ",
              highest_education: item.highest_education ? item.highest_education : " - ",
              skills: item.skills ? item.skills : " - ",
              jf_id: item.jf_id,
              company_visited: item.company_visited,
              applicant_status:
                item.applicant_status === "rejected"
                  ? "Rejected"
                  : item.applicant_status !== "-"
                  ? item.applicant_status
                  : "On Hold",
              company_name: item.company_name !== "-" ? item.company_name : " - ",
              job_title: item.job_title !== "-" ? item.job_title : " - ",
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err list:", err.response);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const fetchCandidateList1 = () => {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/candidate_interacted`, {
          params: {
            created_by: sessionStorage.getItem("register_id")
              ? sessionStorage.getItem("register_id")
              : "",
            status: candiatetable,
          },
        })
        .then((res) => {
          console.log("data list:", res.data);

          setSearchData5(
            res.data?.map((item, id) => {
              return {
                key: item.registration_id,
                candidate_name: item.first_name + " " + item.last_name,
                mobile_number: item?.mobile?.length === 12 ? item.mobile.substring(2) : item.mobile,
                email: item.email,
                date: item.createddt,
                // shortlisted_for: "onboarded",
                city: item.taluka ? item.taluka : "-",
                district: item.district ? item.district : "-",
                state: item.state ? item.state : "-",
                job_fair_attended: item.jobfair_attended ? item.jobfair_attended : " - ",
                // candidate_status: "Onhold",
                // status: true,
                resume: item.resume_cv,
                candidate_status: item.applicant_status,
                datasource: item.datasource ? item.datasource : " - ",
                job_role: item.job_role ? item.job_role : " - ",
                experience: item.experience ? item.experience : " - ",
                dob: item.date_of_birth ? item.date_of_birth : " - ",
                current_salary: item.current_salary ? item.current_salary : " - ",
                desired_salary: item.desired_salary ? item.desired_salary : " - ",
                degree: item.degree ? item.degree : " - ",
                highest_education: item.highest_education ? item.highest_education : " - ",
                skills: item.skills ? item.skills : " - ",
                jf_id: item.jf_id,
                company_visited: item.company_visited,
                applicant_status:
                  item.applicant_status === "rejected"
                    ? "Rejected"
                    : item.applicant_status !== "-"
                    ? item.applicant_status
                    : "On Hold",
                company_name: item.company_name !== "-" ? item.company_name : " - ",
                job_title: item.job_title !== "-" ? item.job_title : " - ",
              };
            })
          );
          setData5(
            res.data?.map((item, id) => {
              return {
                key: item.registration_id,
                candidate_name: item.first_name + " " + item.last_name,
                mobile_number: item?.mobile?.length === 12 ? item.mobile.substring(2) : item.mobile,
                email: item.email,
                date:
                  item.createddt === "" || item.createddt === "nan" || item.createddt === null
                    ? ""
                    : moment(item.createddt).format("DD-MM-YYYY"),
                // shortlisted_for: "onboarded",
                city: item.taluka ? item.taluka : "-",
                district: item.district ? item.district : "-",
                state: item.state ? item.state : "-",
                job_fair_attended: item.jobfair_attended ? item.jobfair_attended : " - ",
                // candidate_status: "Onhold",
                // status: true,
                resume: item.resume_cv,
                candidate_status: item.applicant_status,
                datasource: item.datasource ? item.datasource : " - ",
                job_role: item.job_role ? item.job_role : " - ",
                experience: item.experience ? item.experience : " - ",
                dob: item.date_of_birth ? item.date_of_birth : " - ",
                current_salary: item.current_salary ? item.current_salary : " - ",
                desired_salary: item.desired_salary ? item.desired_salary : " - ",
                degree: item.degree ? item.degree : " - ",
                highest_education: item.highest_education ? item.highest_education : " - ",
                skills: item.skills ? item.skills : " - ",
                jf_id: item.jf_id,
                company_visited: item.company_visited,
                applicant_status:
                  item.applicant_status === "rejected"
                    ? "Rejected"
                    : item.applicant_status !== "-"
                    ? item.applicant_status
                    : "On Hold",
                company_name: item.company_name !== "-" ? item.company_name : " - ",
                job_title: item.job_title !== "-" ? item.job_title : " - ",
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err list:", err.response);
          setIsLoading(false);
        });
    };

    fetchCandidateList1();
  }, [candiatetable]);

  const handleSearch = () => {
    console.log("search:", name);
    const newData =
      table === 0
        ? jfList.filter((ele) => {
            return name?.toLocaleLowerCase() === ""
              ? ele
              : ele.jfName?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
                name?.toLocaleLowerCase() === ""
              ? ele
              : ele.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
                name.toLocaleLowerCase() === ""
              ? ele
              : String(ele.mobnum).toLocaleLowerCase().includes(name.toLocaleLowerCase());
          })
        : table === 1
        ? jpList.filter((ele) => {
            return name?.toLocaleLowerCase() === ""
              ? ele
              : ele.company_name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
                name.toLocaleLowerCase() === ""
              ? ele
              : String(ele.mobnum).toLocaleLowerCase().includes(name.toLocaleLowerCase());
          })
        : table === 5
        ? searchData5.filter((ele) => {
            return name?.toLocaleLowerCase() === ""
              ? ele
              : ele.candidate_name?.toLocaleLowerCase().includes(name.toLocaleLowerCase());
          })
        : candList.filter((ele) => {
            return name?.toLocaleLowerCase() === ""
              ? ele
              : ele.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
                name.toLocaleLowerCase() === ""
              ? ele
              : String(ele.mobnum).toLocaleLowerCase().includes(name.toLocaleLowerCase());
          });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    table === 0
      ? setSearchData(newData)
      : table === 1
      ? setSearchJPData(newData)
      : setSearchCandData(newData);
  };

  const expandedRowRender = (row) => {
    console.log("row", row);
    const colName1a = [
      {
        title: "Job Role",
        dataIndex: "job_role",
        // fixed: "left",
        // width: 200,
        // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
        sorter: {
          compare: (a, b) => a?.job_role?.localeCompare(b?.job_role),
          multiple: 1,
        },
      },
      {
        title: "Candidate Required",
        dataIndex: "required_count",
        // fixed: "left",
        // width: 200,
        // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
        sorter: {
          compare: (a, b) => a?.required_count?.localeCompare(b?.required_count),
          multiple: 2,
        },
        render: (text) => (
          <span
            style={{
              // textDecoration: "underline",
              display: "flex",
              justifyContent: "center",
              // color: "#1B59F8",
              // fontFamily: "Inter",
              // fontSize: "16px",
              // fontWeight: 600,
            }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "Candidate Interacted",
        dataIndex: "interacted_count",
        // fixed: "left",
        // width: 200,
        // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
        sorter: {
          compare: (a, b) => a?.interacted_count?.localeCompare(b?.interacted_count),
          multiple: 3,
        },
        render: (text) => (
          <span
            style={{
              // textDecoration: "underline",
              display: "flex",
              justifyContent: "center",
              // color: "#1B59F8",
              // fontFamily: "Inter",
              // fontSize: "16px",
              // fontWeight: 600,
            }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "Job Description",
        dataIndex: "job_desc",
        // fixed: "left",
        // width: 200,
        // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
        sorter: {
          compare: (a, b) => a?.job_desc?.localeCompare(b?.job_desc),
          multiple: 4,
        },
        render: (text) => (
          <span
            style={{
              textDecoration: "underline",
              display: "flex",
              justifyContent: "center",
              color: "#1B59F8",
              // fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={() => (setJDModal(true), setJD(text))}
          >
            View
          </span>
        ),
      },
    ];
    setNewJPId(row.key);
    const onSelectChange = (newSelectedRowKeys) => {
      console.log("newkey", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    // fetchJPRequirements(row.key);
    return (
      <Table
        loading={jpReqData.length === 0 ? true : false}
        columns={colName1a}
        // dataSource={data}
        dataSource={jpReqData}
        pagination={false}
        rowSelection={rowSelection}
        exportableProps={{ showColumnPicker: true }}
        // onRow={(record, rowIndex) => {
        //   console.log(`record:${rowIndex}`, record);
        //   return expandedRowRender;
        //   // return expandedRowRender;
        //   // onClick: () => {
        //   //   console.log(`record:${rowIndex}`, record);
        //   //   return expandedRowRender;
        //   // };
        // }}
      />
    );
  };

  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/change_status`, {
        toggle: "jobfair",
        jf_id: data.key,
        status: false,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Job Fair Deactivated",
          //text: "Super Admin Deactivated",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchJFList();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"] ? e.data["User Message"] : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const ActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/change_status`, {
        toggle: "jobfair",
        jf_id: data.key,
        status: true,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Job Fair Activated",
          //text: "Super Admin Deactivated",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchJFList();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"] ? e.data["User Message"] : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const AcceptJPFunc = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/change_status`, {
        toggle: "jobprovider",
        jpr_id: data.key,
        jf_id: jfId,
        accepted_status: "Accepted",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Job Fair Request Accepted",
          //text: "Super Admin Deactivated",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchJPList(jfId);
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"] ? e.data["User Message"] : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const DeclineJPFunc = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/change_status`, {
        toggle: "jobprovider",
        jpr_id: data.key,
        jf_id: jfId,
        accepted_status: "Declined",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Job Fair Request Declined",
          //text: "Super Admin Deactivated",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchJPList(jfId);
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: e.data["Developer Message"] ? e.data["Developer Message"] : "Oops...",
          text: e.data["User Message"] ? e.data["User Message"] : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const fetchJFKpis = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jfkpi`, {
        params: {
          // toggle: "jf",
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("kpi jf:", res);
        setKpiArr([
          {
            heading: "Job Fairs Conducted",
            count1: res.data.total_conducted,
            img: candidates1,
          },
          {
            heading: "Ongoing Job Fairs",
            count1: res.data.ongoing_today,
            img: candidates2,
          },
          {
            heading: "Completed Job Fairs",
            count1: res.data.completed,
            img: candidates3,
          },
          {
            heading: "Upcoming Job Fairs",
            count1: res.data.planned,
            img: candidates2,
          },
          {
            heading: "Companies Registered",
            count1: res.data.company_registered,
            img: candidates3,
          },
          {
            heading: "Candidates Registered",
            count1: res.data.candidate_registered,
            img: candidates1,
          },
        ]);
        // setKPIObj1({
        //   total_jf: res.data.total_conducted,
        //   ongoing: res.data.ongoing_today,
        //   completed: res.data.completed,
        //   upcoming: res.data.planned,
        //   cand_reg: res.data.candidate_registered,
        //   comp_reg: res.data.company_registered,
        // });
      })
      .catch((err) => {
        console.log("kpi jf err:", err);
      });
  };

  const fetchJPKpis = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jfkpi`, {
        params: {
          toggle: "jp",
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("kpi jp:", res);
        setKPIObj2({
          total_comp: res.data.total_company,
          onboarded: res.data.onboarded,
          comp_attended: res.data.attended_compnies,
          shortlisted: res.data.shortlisted,
        });
      })
      .catch((err) => {
        console.log("kpi jp err:", err);
      });
  };
  const fetchJSKpis = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jfkpi`, {
        params: {
          toggle: "js",
          created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
        },
      })
      .then((res) => {
        console.log("kpi js:", res);
        setKPIObj3({
          total_cand: res.data.total_candidate,
          cand_active: res.data.all_registration,
          onHold: res.data.on_hold,
          shortlisted: res.data.shortlisted,
        });
      })
      .catch((err) => {
        console.log("kpi js err:", err);
      });
  };
  const onButtonClick = (url) => {
    const fileURL = window.URL.createObjectURL(url);
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = `Candidates_Export_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`;
    alink.click();
  };
  const exportCandApi = () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/export-candidate`, {
        params: {
          admin_id: sessionStorage.getItem("register_id"),
          status: candiatetable,
        },
        responseType: "blob",
      })
      .then((res) => {
        console.log("export", res.data);
        setTimeout(() => {
          waitingPopup.close();
        }, 1000);
        onButtonClick(res.data);
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Download successful",
            //text: err?.response?.data ? err.response.data["User Message"] : "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 1000);
      })
      .catch((err) => {
        console.log("err export", err.response);
        setTimeout(() => {
          waitingPopup.close();
        }, 1000);
        Swal.fire({
          icon: "error",
          title: err?.response?.data ? err.response.data["error"] : "Something went wrong",
          text: err?.response?.data ? err.response.data["error"] : "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    handleSearch();
  }, [name]);
  useEffect(() => {
    fetchJPRequirements(newJPId);
  }, [newJPId]);

  useEffect(() => {
    fetchJFList();
    fetchJFKpis();
    // fetchJPKpis();
    // fetchJSKpis();
  }, []);

  useEffect(() => {
    fetchAllCandidateInteractedList(text.key);
  }, [companyModal]);
  useEffect(() => {
    fetchAllCompanyInteractedList(text.key);
  }, [studentModal]);

  const handleMenuClick = (e, record) => {
    // console.log("Click on menu item:", e);
    // console.log("Row data:", record);
    if (e.key == 1) {
      setCandidatetable("");
      setPaginationState({ ...paginationState, page: 1 });
    } else if (e.key == 3) {
      setCandidatetable("On Hold");
      // setPaginationState({ ...paginationState, page: 1 });
    } else if (e.key == 4) {
      setCandidatetable("Shortlisted");
      // setPaginationState({ ...paginationState, page: 1 });
    } else if (e.key == 5) {
      setCandidatetable("Selected");
      // setPaginationState({ ...paginationState, page: 1 });
    } else if (e.key == 6) {
      setCandidatetable("Joined");
      // setPaginationState({ ...paginationState, page: 1 });
    } else {
      setCandidatetable("Rejected");
      // setPaginationState({ ...paginationState, page: 1 });
    }
  };

  const menu = () => (
    <Menu1 onClick={(e) => handleMenuClick(e)}>
      <Menu1.Item key="1">
        <a style={{ display: "flex" }} className="dropdown-item">
          &nbsp; All
        </a>
      </Menu1.Item>
      {/* <Menu1.Item key="2">
        <a style={{ display: "flex" }} className="dropdown-item">
          &nbsp; Applied
        </a>
      </Menu1.Item> */}

      <Menu1.Item key="3">
        <a style={{ display: "flex" }} className="dropdown-item">
          &nbsp; On Hold
        </a>
      </Menu1.Item>
      <Menu1.Item key="4">
        <a style={{ display: "flex" }} className="dropdown-item">
          &nbsp; Shortlisted
        </a>
      </Menu1.Item>
      <Menu1.Item key="5">
        <a style={{ display: "flex" }} className="dropdown-item">
          &nbsp; Selected
        </a>
      </Menu1.Item>
      <Menu1.Item key="6">
        <a style={{ display: "flex" }} className="dropdown-item">
          &nbsp; Joined
        </a>
      </Menu1.Item>
      <Menu1.Item key="7">
        <a style={{ display: "flex" }} className="dropdown-item">
          &nbsp; Rejected
        </a>
      </Menu1.Item>
    </Menu1>
  );

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={1}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
                {translate("jobfair")}
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" flexDirection="row" gap="10px" justifyContent="space-between">
              <SoftBox
                sx={{
                  height: "40px",
                  backgroundColor: "#FFF",
                  borderRadius: "2px",
                  cursor: "pointer",
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                width="40px"
                onClick={() => {
                  fetchStateList();
                  table === 0
                    ? (fetchJFList(), fetchJFKpis())
                    : table === 4
                    ? (fetchCompanyList(), fetchJPKpis())
                    : table === 5
                    ? (fetchAllCandidateList(), fetchJSKpis())
                    : (fetchJFList(), fetchJFKpis());

                  // window.location.reload()
                }}
              >
                <SoftBox>
                  <img src={refreshBtn} />
                </SoftBox>
              </SoftBox>
              {/*
                <SoftBox
                  width="120px"
                  // ml={{ lg: "1070px", md: "600px", xs: "300px" }}
                  sx={{
                    // position: "absolute",
                    // marginLeft:"1000px",
                    // marginLeft: "1000px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    // backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    border: "1px solid ",
                    cursor: "pointer",
                  }}
                // onclick to deactivate all
                >
                  
                  <SoftTypography
                    sx={{ fontSize: "15px", fontWeight: "medium", padding: "auto" }}
                    onClick={() => (
                      setShow(!show),
                      // setFileName(() => {
                      //   getFileName();
                      // })
                      getFileName()
                    )}
                  >
                    Export CSV
                  </SoftTypography>
                  <CSVLink data={selectedCSVData} headers={colHeaders}>export csv</CSVLink>
                </SoftBox>
                */}
            </SoftBox>
          </SoftBox>
          <SoftBox
            // display="flex"
            // flexDirection="row"
            // justifyContent="space-between"
            // width="100%"
            mb={2}
            // gap={2}
          >
            <Grid container spacing={1} sx={{ mt: 0 }}>
              {isLoading ? (
                <>
                  {Array.from(Array(6).keys()).map((v, i) => {
                    return (
                      <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                        <SBox1>
                          <SBox2>
                            <SVerticalBox>&nbsp;</SVerticalBox>

                            <SBox3>
                              <Box>
                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  sx={{ height: "14px", width: "120px", m: 0.5, mb: 1.7 }}
                                />

                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  sx={{ height: "20px", width: "90px", m: 0.5, mb: 1.3 }}
                                />
                              </Box>

                              <Box>
                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  sx={{ height: "42px", width: "44px" }}
                                />
                              </Box>
                            </SBox3>
                          </SBox2>
                        </SBox1>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <>
                  {kpiArr.map((v, i) => {
                    return (
                      <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                        <SBox1>
                          <SBox2>
                            <SVerticalBox>&nbsp;</SVerticalBox>
                            <SBox3>
                              <Box>
                                <SKPIHeading>{v.heading}</SKPIHeading>
                                <SKPICount>{v.count1}</SKPICount>
                              </Box>

                              <Box
                                sx={{
                                  height: "42px",
                                  width: "42px",
                                  backgroundColor: "#FFF5ED",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img src={v.img} alt="" height={"30px"} width={"30px"} />
                              </Box>
                            </SBox3>
                          </SBox2>
                        </SBox1>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </SoftBox>

          <SoftBox
            sx={
              matches
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }
            }
          >
            <SoftBox
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                mb: 2,
              }}
            >
              <SoftBox
                sx={{
                  border: "1px solid #000",
                  borderRadius: "5px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  width: "125px",
                  height: "45px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  p: 1,
                  textAlign: "center",
                  backgroundColor: table === 0 ? "#3B5092" : "#fff",
                }}
                onClick={() => {
                  setTable(0);
                  setCandidatetable("");
                  setSelectedRowKeys([]);
                  setTablerow([]);
                }}
              >
                <SoftTypography
                  sx={
                    table === 0
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // color: "#1B59F8",
                          color: "#fff",
                          fontSize: "16px",
                        }
                      : {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // color: "#000",
                          color: "#3B5092",
                          fontSize: "16px",
                        }
                  }
                >
                  {translate("alljobfair")}
                </SoftTypography>
              </SoftBox>

              <SoftBox
                sx={{
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                  width: "125px",
                  height: "45px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  p: 1,
                  textAlign: "center",
                  backgroundColor: table === 4 ? "#3B5092" : "#fff",
                }}
                onClick={() => {
                  setTable(4);
                  setCandidatetable("");
                  fetchCompanyList();
                  setSelectedRowKeys([]);
                  setTablerow([]);
                }}
              >
                <SoftTypography
                  sx={
                    table === 4
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // color: "#1B59F8",
                          color: "#fff",
                          fontSize: "16px",
                        }
                      : {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#3B5092",
                          fontSize: "16px",
                        }
                  }
                >
                  {translate("companylist")}
                </SoftTypography>
              </SoftBox>

              <SoftBox
                sx={{
                  border: "1px solid #000",
                  borderRadius: "5px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  width: "125px",
                  height: "45px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  p: 1,
                  textAlign: "center",
                  backgroundColor: table === 5 ? "#3B5092" : "#fff",
                }}
                onClick={() => {
                  setTable(5);
                  setCandidatetable("");
                  fetchAllCandidateList();
                  setSelectedRowKeys([]);
                  setTablerow([]);
                }}
              >
                <SoftTypography
                  sx={
                    table === 5
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // color: "#1B59F8",
                          color: "#fff",
                          fontSize: "16px",
                        }
                      : {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#3B5092",
                          fontSize: "16px",
                        }
                  }
                >
                  {translate("candidatelist")}
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            {/* {table === 5 && (
              <SoftBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  mb: 2,
                }}
              >
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: candiatetable === "" ? "#3B5092" : "#fff",
                    border: candiatetable !== "" ? "1px solid #3B5092" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 0.5,
                    pr: 0.5,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable("")}
                >
                  <SoftTypography
                    sx={
                      candiatetable === ""
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "#3B5092",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    
                    {"All"}
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: candiatetable === "Shortlisted" ? "#00981A" : "#fff",
                    border: candiatetable !== "Shortlisted" ? "1px solid #00981A" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 0.5,
                    pr: 0.5,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable("Shortlisted")}
                >
                  <SoftTypography
                    sx={
                      candiatetable === "Shortlisted"
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "rgba(0, 152, 26, 0.69)",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    
                    {"Shortlisted"}
                  </SoftTypography>
                </SoftBox>

                <SoftBox
                  sx={{
                    height: "40px",
                    // backgroundColor: "#FCF3CF",
                    backgroundColor: candiatetable === "On Hold" ? "#FFAE5E" : "#fff",
                    border: candiatetable !== "On Hold" ? "1px solid #FFAE5E" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 1,
                    pr: 1,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable("On Hold")}
                >
                  <SoftTypography
                    sx={
                      candiatetable === "On Hold"
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "#FFAE5E",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    
                    {"On Hold"}
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "40px",
                    // backgroundColor: "#FCF3CF",
                    backgroundColor: candiatetable === "Joined" ? "#00981A" : "#fff",
                    border: candiatetable !== "Joined" ? "1px solid #00981A" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 1,
                    pr: 1,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable("Joined")}
                >
                  <SoftTypography
                    sx={
                      candiatetable === "Joined"
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "#00981A",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    
                    {"Joined"}
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "40px",
                    // backgroundColor: "#F5B7B1",
                    backgroundColor:
                      candiatetable === "Rejected" ? "rgba(255, 0, 0, 0.56)" : "#fff",
                    border:
                      candiatetable !== "Rejected" ? "1px solid rgba(255, 0, 0, 0.56)" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 1,
                    pr: 1,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable("Rejected")}
                >
                  <SoftTypography
                    sx={
                      candiatetable === "Rejected"
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "rgba(255, 0, 0, 0.56)",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    
                    {"Rejected"}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )} */}

            {table === 2 && (
              <SoftBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  mb: 2,
                }}
              >
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    // border: "1px solid #1FBA77" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 0.5,
                    pr: 0.5,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => (
                    setTable(0), setCandidatetable(""), setSelectedRowKeys([]), setTablerow([])
                  )}
                >
                  <SoftTypography sx={{ color: "#fff", fontSize: "12px", fontWeight: "medium" }}>
                    Back
                  </SoftTypography>
                </SoftBox>

                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: candiatetable1 === "" ? "#3B5092" : "#fff",
                    border: candiatetable1 !== "" ? "1px solid #3B5092" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 0.5,
                    pr: 0.5,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable1("")}
                >
                  <SoftTypography
                    sx={
                      candiatetable1 === ""
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "#3B5092",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    {translate("all")}
                  </SoftTypography>
                </SoftBox>

                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: candiatetable1 === "On Hold" ? "#FFAE5E" : "#fff",
                    border: candiatetable1 !== "On Hold" ? "1px solid #FFAE5E" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 0.5,
                    pr: 0.5,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable1("On Hold")}
                >
                  <SoftTypography
                    sx={
                      candiatetable1 === "On Hold"
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "#FFAE5E",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    {translate("onhold")}
                  </SoftTypography>
                </SoftBox>

                <SoftBox
                  sx={{
                    height: "40px",
                    // backgroundColor: "#FCF3CF",
                    backgroundColor: candiatetable1 === "Shortlisted" ? "#00981A" : "#fff",
                    border: candiatetable1 !== "Shortlisted" ? "1px solid #00981A" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 1,
                    pr: 1,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable1("Shortlisted")}
                >
                  <SoftTypography
                    sx={
                      candiatetable1 === "Shortlisted"
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "#00981A",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    {translate("shortlisted")}
                  </SoftTypography>
                </SoftBox>

                <SoftBox
                  sx={{
                    height: "40px",
                    // backgroundColor: "#F5B7B1",
                    backgroundColor: candiatetable1 === "Rejected" ? "#FF0000" : "#fff",
                    border: candiatetable1 !== "Rejected" ? "1px solid #FF0000" : "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pl: 1,
                    pr: 1,
                    ml: "4px",
                    mr: "4px",
                  }}
                  onClick={() => setCandidatetable1("Rejected")}
                >
                  <SoftTypography
                    sx={
                      candiatetable1 === "Rejected"
                        ? {
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                        : {
                            color: "#FF0000",
                            fontSize: "12px",
                            fontWeight: "medium",
                          }
                    }
                  >
                    {translate("rejected")}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>

          <SoftBox display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
            <SoftBox
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SoftBox width={{ lg: "500px", md: "400px" }}>
                <SoftInput
                  isClearable
                  // width={{ lg: "1000px", md: "600px" }}
                  // width="1000px"
                  id="search-bar"
                  className="text"
                  // onInput={(e) => {
                  //   setSearchQuery(e.target.value);
                  // }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  placeholder={
                    table === 4
                      ? "Search by company name"
                      : table === 0
                      ? "Search by job fair name, SPOC name or mobile number"
                      : table === 5
                      ? "Search by candidate name or mobile number"
                      : "Search by name or mobile number"
                  }
                  size="small"
                />
              </SoftBox>

              <SoftBox display="flex" gap="10px">
                {table !== 0 && (
                  <Tooltip
                    key="1"
                    title={tablerow.length === 0 ? "Download All" : "Download"}
                    placement="top"
                  >
                    <SoftBox
                      sx={{
                        height: "33px",
                        backgroundColor: "#fff",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      width="40px"
                      onClick={() => {
                        table !== 5 && setShow(!show);
                        table !== 5 && getFileName();
                        table === 5 && exportCandApi();
                      }}
                    >
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 11.082V18.082M14 18.082L11.6667 15.7487M14 18.082L16.3333 15.7487M9.8 22.1654C6.32061 22.1654 3.5 19.3705 3.5 15.9229C3.5 13.0655 5.6 10.4258 8.75 9.91537C9.73809 7.56613 12.0766 5.83203 14.8041 5.83203C18.2981 5.83203 21.1537 8.54162 21.35 11.957C23.2042 12.7677 24.5 14.7574 24.5 16.9117C24.5 19.8132 22.1495 22.1654 19.25 22.1654L9.8 22.1654Z"
                          stroke="#3A3A3A"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </SoftBox>
                  </Tooltip>
                )}
                {/* <Tooltip key="2" title="Create Job Fair" placement="top">
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#3B5092",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mr="8px"
                    ml="8px"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => navigate("/job-fair-registration-page")}
                  >
                    <SoftBox sx={{ color: "#FFF" }}>+</SoftBox>
                  </SoftBox>
                </Tooltip> */}

                {table === 5 && (
                  <Tooltip key="3" title="Filter" placement="top">
                    <Dropdown overlay={() => menu()}>
                      <SoftBox
                        sx={{
                          height: "33px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "2px",
                          cursor: "pointer",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                        width="40px"
                        // onClick={() => setShowFilter(true)}
                      >
                        <SoftBox>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="34"
                            viewBox="0 0 39 37"
                            fill="none"
                          >
                            <rect width="38.8889" height="37" rx="2" fill="none" />
                            <path
                              d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                              stroke="#3A3A3A"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </SoftBox>
                      </SoftBox>
                    </Dropdown>
                  </Tooltip>
                )}
              </SoftBox>

              {/* {table !== 0 && (
                <SoftBox
                  sx={{
                    border: "1px solid #000",
                    borderRadius: "5px",
                    width: "150px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    fetchJPList();
                    setTable(0);
                  }}
                >
                  <SoftTypography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#000",
                      fontSize: "16px",
                    }}
                  >
                    Job Fair
                  </SoftTypography>
                </SoftBox>
              )} */}
            </SoftBox>
          </SoftBox>

          {table === 0 && (
            // <Table
            //   loading={isLoading}
            //   rowSelection={rowSelection}
            //   columns={colName}
            //   dataSource={searchData
            //     .filter((ele) =>
            //       statefilter?.toLocaleLowerCase() === ""
            //         ? ele
            //         : ele.state?.toLocaleLowerCase() === statefilter.toLocaleLowerCase()
            //     )
            //     .filter((ele) =>
            //       districtfilter?.toLocaleLowerCase() === ""
            //         ? ele
            //         : ele.district?.toLocaleLowerCase() === districtfilter.toLocaleLowerCase()
            //     )
            //     .filter((ele) =>
            //       jffilter?.toLocaleLowerCase() === ""
            //         ? ele
            //         : ele.jfStatus?.toLocaleLowerCase() === jffilter.toLocaleLowerCase()
            //     )
            //     .filter((ele) =>
            //       datefilter === ""
            //         ? ele
            //         : moment(ele.date).format("YYYY-MM-DD") ===
            //           moment(datefilter).format("YYYY-MM-DD")
            //     )}
            //   // scroll={searchData.length <= 2 ? { x: 2400, y: 400 } : { x: 2400 }}
            //   scroll={{ y: 400 }}
            //   pagination={{
            //     // defaultPageSize: ,
            //     // position: ["none", "bottomCenter"],
            //     pageSizeOptions: ["10", "20", "50", "100", "500"],
            //     total: paginationState.dataSize,
            //     showSizeChanger: true,
            //     // onShowSizeChange: { onShowSizeChange },
            //     onShowSizeChange: handlePageSizeChange,
            //     current: paginationState.page,
            //     onChange: (page, pageSize) =>
            //       setPaginationState({ ...paginationState, page: page }),
            //   }}
            // />
            <>
              {searchData.slice((page - 1) * limit, page * limit).map((item, id) => (
                <BoxGrid2 key={id}>
                  <Grid item lg={6} xs={12} width="50%">
                    <SoftBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRight: "1px solid #00000080",
                      }}
                    >
                      <SoftBox
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          // justifyContent: "space-between",
                        }}
                      >
                        <SoftBox>
                          <img src={jobfairSVG} alt="jobfairhand" />
                        </SoftBox>
                        <SoftBox
                          sx={{ fontSize: "20px", fontWeight: 500, cursor: "pointer" }}
                          onClick={() => {
                            toJFDetails(item);
                          }}
                          // onClick={() => (
                          //   setJobPostView(true), setTable(0), setName(""), setDetails(item)
                          // )}
                        >
                          {item.jfName}
                        </SoftBox>
                        <div
                          style={{
                            width: "80px",
                            height: "30px",
                            // border: "1px solid ",
                            marginLeft: "15px",
                            borderRadius: "15px",
                            backgroundColor:
                              item.jfStatus === "Inactive"
                                ? "#E51837"
                                : item.jfStatus === "Upcoming"
                                ? "#1FBA77"
                                : item.jfStatus === "Ongoing"
                                ? "#73B0F9"
                                : "#FF5C00",
                            // backgroundColor: details.status === 1 ? "#46B16B" : "#EFD6FF",
                            // cursor:
                            //   details.accepted_status === "Not Applied" &&
                            //   (details.jfStatus === "Upcoming" || details.jfStatus === "Ongoing")
                            //     ? "pointer"
                            //     : "no-drop",
                            color: "#fff",
                          }}
                        >
                          <p
                            style={{
                              // padding: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "12px",
                              padding: "5px 0px 5px",
                              gap: 2,
                            }}
                          >
                            {item?.jfStatus}
                          </p>
                        </div>
                      </SoftBox>

                      <SoftBox sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                        <SoftBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-between",
                              alignItems: "end",
                              gap: "15px",
                            }}
                          >
                            <SoftBox>
                              <img
                                src={calenderSVG}
                                style={{ width: "20px", height: "20px" }}
                                alt="jobfairhand"
                              />
                            </SoftBox>
                            <SoftBox sx={{ fontSize: "16px", fontWeight: 500 }}>
                              Job Fair Date :
                            </SoftBox>
                          </SoftBox>
                          <SoftTypography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 500,
                              // display: "flex",
                              // justifyContent: "flex-start",
                            }}
                          >
                            {item.date}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-between",
                              alignItems: "end",
                              gap: "15px",
                            }}
                          >
                            <SoftBox>
                              <img
                                src={clockSVG}
                                style={{ width: "20px", height: "20px" }}
                                alt="clock"
                              />
                            </SoftBox>
                            <SoftBox sx={{ fontSize: "16px", fontWeight: 500 }}>
                              Job Fair Time :
                            </SoftBox>
                          </SoftBox>
                          <SoftTypography sx={{ fontSize: "16px", fontWeight: 500 }}>
                            {item.time}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-between",
                              alignItems: "end",
                              gap: "15px",
                            }}
                          >
                            <SoftBox>
                              <img
                                src={locationSVG}
                                style={{ width: "20px", height: "20px" }}
                                alt="jobfairhand"
                              />
                            </SoftBox>
                            <SoftBox sx={{ fontSize: "16px", fontWeight: 500 }}>Venue :</SoftBox>
                          </SoftBox>
                          <SoftTypography sx={{ fontSize: "16px", fontWeight: 500 }}>
                            {item.venue}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item lg={6} xs={12} width="50%">
                    <SoftBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "8px",
                        // borderRight: "1px solid #00000080",
                      }}
                    >
                      <SoftBox
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <SoftBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "space-between",
                          }}
                        >
                          <SoftBox>
                            <img src={candidatesSVG} alt="allcandidates" />
                          </SoftBox>
                          <SoftBox sx={{ fontSize: "20px", fontWeight: 500 }}>
                            Candidates Status
                          </SoftBox>
                        </SoftBox>
                        {/* {item.accepted_status === "Not Applied" && (
                                      <SoftBox
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "30px",
                                          border: "1px solid #0B7B2A",
                                          borderRadius: "20px",
                                          fontSize: "14px",
                                          fontWeight: 500,
                                          p: 1,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setRequestModal(true),
                                            fetchjobrole(item.key),
                                            setJFId(item.key);
                                        }}
                                      >
                                        {"Enroll"}
                                      </SoftBox>
                                    )} */}
                      </SoftBox>
                      <SoftBox sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                        <SoftBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-between",
                              alignItems: "end",
                              gap: "15px",
                            }}
                          >
                            <SoftBox>
                              <img
                                src={registeredSVG}
                                style={{ width: "20px", height: "20px" }}
                                alt="registered"
                              />
                            </SoftBox>
                            <SoftBox sx={{ fontSize: "16px", fontWeight: 500 }}>
                              Registered :
                            </SoftBox>
                          </SoftBox>
                          <SoftTypography sx={{ fontSize: "16px", fontWeight: 500 }}>
                            {item.cand_reg}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-between",
                              alignItems: "end",
                              gap: "15px",
                            }}
                          >
                            <SoftBox>
                              <img
                                src={onholdSVG}
                                style={{ width: "20px", height: "20px" }}
                                alt="onhold"
                              />
                            </SoftBox>
                            <SoftBox sx={{ fontSize: "16px", fontWeight: 500 }}>
                              Under Evaluation :
                            </SoftBox>
                          </SoftBox>
                          <SoftTypography sx={{ fontSize: "16px", fontWeight: 500 }}>
                            {item.onhold}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-between",
                              alignItems: "end",
                              gap: "15px",
                            }}
                          >
                            <SoftBox>
                              <img
                                src={shortlistedSVG}
                                style={{ width: "20px", height: "20px" }}
                                alt="shortlisted"
                              />
                            </SoftBox>
                            <SoftBox sx={{ fontSize: "16px", fontWeight: 500 }}>Selected :</SoftBox>
                          </SoftBox>
                          <SoftTypography sx={{ fontSize: "16px", fontWeight: 500 }}>
                            {item.shortlisted}
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-between",
                              alignItems: "end",
                              gap: "15px",
                            }}
                          >
                            <SoftBox>
                              <img
                                src={registeredSVG}
                                style={{ width: "20px", height: "20px" }}
                                alt="rejected"
                              />
                            </SoftBox>
                            <SoftBox sx={{ fontSize: "16px", fontWeight: 500 }}>Rejected :</SoftBox>
                          </SoftBox>
                          <SoftTypography sx={{ fontSize: "16px", fontWeight: 500 }}>
                            {item.rejected}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </BoxGrid2>
              ))}
              {searchData.length !== 0 && (
                <SoftBox display="flex" justifyContent="end">
                  <Pagination
                    defaultCurrent={page}
                    total={searchData.length}
                    pageSize={limit}
                    pageSizeOptions={[4, 10, 20, 100]}
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    onChange={(page, pageSize) => {
                      setPage(page);
                    }}
                    showTotal={(total, range) => (
                      console.log("pagi", range),
                      (
                        <span>
                          Showing{" "}
                          <strong>
                            {range[0]} - {range[1]}
                          </strong>{" "}
                          results of {total}
                        </span>
                      )
                    )}
                  />
                </SoftBox>
              )}
            </>
          )}

          {table === 4 && (
            <SoftBox
              alignItems="flex-start"
              position="relative"
              borderRadius="10px"
              width="100%"
              className="batch-table"
              height="350px"
            >
              <Table
                loading={isLoading}
                rowSelection={rowSelection}
                columns={colName4}
                dataSource={data4
                  .filter((ele) => {
                    return name?.toLocaleLowerCase() === ""
                      ? ele
                      : ele.company_name?.toLocaleLowerCase().includes(name.toLocaleLowerCase());
                  })
                  .filter((ele) => {
                    return boardingfilter === ""
                      ? ele
                      : String(ele.onboarding_status) ===
                          (boardingfilter === "Onboarded"
                            ? "true"
                            : boardingfilter === "Not Onboarded"
                            ? "false"
                            : "");
                  })}
                scroll={data4.length <= 2 ? { x: 1000, y: 400 } : { x: 1000, y: 400 }}
                pagination={{
                  // defaultPageSize: ,
                  // position: ["none", "bottomCenter"],
                  total: paginationState.dataSize,
                  showSizeChanger: true,
                  // onShowSizeChange: { onShowSizeChange },
                  onShowSizeChange: handlePageSizeChange,
                  current: paginationState.page,
                  onChange: (page, pageSize) =>
                    setPaginationState({ ...paginationState, page: page }),
                  showTotal: (total, range) => (
                    // console.log("pagi", range),
                    <span>
                      Showing{" "}
                      <strong>
                        {range[0]} - {range[1]}
                      </strong>{" "}
                      results of {total}
                    </span>
                  ),
                }}
              />
            </SoftBox>
          )}

          {table === 5 && (
            <SoftBox
              alignItems="flex-start"
              position="relative"
              borderRadius="10px"
              width="100%"
              className="batch-table"
              height="400px"
            >
              <Table
                loading={isLoading}
                rowSelection={rowSelection}
                columns={colName5}
                dataSource={
                  // data5
                  data5
                  // .filter((ele) =>
                  //   name === ""
                  //     ? ele
                  //     : ele.candidate_name
                  //         .toLocaleLowerCase()
                  //         .includes(name.toLocaleLowerCase()) || name.toLocaleLowerCase() === ""
                  //     ? ele
                  //     : String(ele.mobile_number)
                  //         .toLocaleLowerCase()
                  //         .includes(name.toLocaleLowerCase())
                  // )
                  // .filter((ele) =>
                  //   candiatetable === ""
                  //     ? ele
                  //     : ele.applicant_status.toLocaleLowerCase() ===
                  //       candiatetable.toLocaleLowerCase()
                  // )
                  // .filter((ele) =>
                  //   statefilter === ""
                  //     ? ele
                  //     : ele.state.toLocaleLowerCase() === statefilter.toLocaleLowerCase()
                  // )
                  // .filter((ele) =>
                  //   districtfilter === ""
                  //     ? ele
                  //     : ele.district.toLocaleLowerCase() === districtfilter.toLocaleLowerCase()
                  // )
                }
                scroll={data5.length <= 2 ? { x: 2000, y: 400 } : { x: 2000, y: 400 }}
                pagination={{
                  // defaultPageSize: ,
                  // position: ["none", "bottomCenter"],
                  total: paginationState.dataSize,
                  showSizeChanger: true,
                  // onShowSizeChange: { onShowSizeChange },
                  onShowSizeChange: handlePageSizeChange,
                  current: paginationState.page,
                  onChange: (page, pageSize) =>
                    setPaginationState({ ...paginationState, page: page }),
                  showTotal: (total, range) => (
                    // console.log("pagi", range),
                    <span>
                      Showing{" "}
                      <strong>
                        {range[0]} - {range[1]}
                      </strong>{" "}
                      results of {total}
                    </span>
                  ),
                }}
              />
            </SoftBox>
          )}
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {table === 0 && (
                <>
                  {colHeaders.map((ele, index) => (
                    <>
                      <ul className="UlCSV" key={index}>
                        <input
                          type="checkbox"
                          checked={ele.isExportable}
                          onChange={(event) =>
                            handleFormChange12(event, index, colHeaders, setColHeaders)
                          }
                        />
                        <span className="ListCSV">&nbsp;{ele.label}</span>
                      </ul>
                    </>
                  ))}
                </>
              )}

              {table === 4 && (
                <>
                  {colHeaders1.map((ele, index) => (
                    <>
                      <ul className="UlCSV" key={index}>
                        <input
                          type="checkbox"
                          checked={ele.isExportable}
                          onChange={(event) =>
                            handleFormChange12(event, index, colHeaders1, setColHeaders1)
                          }
                        />
                        <span className="ListCSV">&nbsp;{ele.label}</span>
                      </ul>
                    </>
                  ))}
                </>
              )}

              {/*{table === 5 && (
                <>
                  {colHeaders2.map((ele, index) => (
                    <>
                      <ul className="UlCSV" key={index}>
                        <input
                          type="checkbox"
                          checked={ele.isExportable}
                          onChange={(event) =>
                            handleFormChange12(event, index, colHeaders2, setColHeaders2)
                          }
                        />
                        <span className="ListCSV">&nbsp;{ele.label}</span>
                      </ul>
                    </>
                  ))}
                </>
              )}*/}

              {table === 2 && (
                <>
                  {colHeaders3.map((ele, index) => (
                    <>
                      <ul className="UlCSV" key={index}>
                        <input
                          type="checkbox"
                          checked={ele.isExportable}
                          onChange={(event) =>
                            handleFormChange12(event, index, colHeaders3, setColHeaders3)
                          }
                        />
                        <span className="ListCSV">&nbsp;{ele.label}</span>
                      </ul>
                    </>
                  ))}
                </>
              )}

              {table === 1 && (
                <>
                  {colHeaders4.map((ele, index) => (
                    <>
                      <ul className="UlCSV" key={index}>
                        <input
                          type="checkbox"
                          checked={ele.isExportable}
                          onChange={(event) =>
                            handleFormChange12(event, index, colHeaders4, setColHeaders4)
                          }
                        />
                        <span className="ListCSV">&nbsp;{ele.label}</span>
                      </ul>
                    </>
                  ))}
                </>
              )}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={
                      table === 0
                        ? tablerow.length === 0
                          ? searchData
                          : tablerow
                        : table === 4
                        ? tablerow.length === 0
                          ? data4.filter((ele) => {
                              return name?.toLocaleLowerCase() === ""
                                ? ele
                                : ele.company_name
                                    ?.toLocaleLowerCase()
                                    .includes(name.toLocaleLowerCase());
                            })
                          : tablerow
                        : table === 5
                        ? tablerow.length === 0
                          ? data5
                              .filter((ele) =>
                                name === ""
                                  ? ele
                                  : ele.candidate_name
                                      .toLocaleLowerCase()
                                      .includes(name.toLocaleLowerCase()) ||
                                    name.toLocaleLowerCase() === ""
                                  ? ele
                                  : String(ele.mobile_number)
                                      .toLocaleLowerCase()
                                      .includes(name.toLocaleLowerCase())
                              )
                              .filter((ele) =>
                                candiatetable === ""
                                  ? ele
                                  : ele.applicant_status.toLocaleLowerCase() ===
                                    candiatetable.toLocaleLowerCase()
                              )
                          : tablerow
                        : table === 2
                        ? tablerow.length === 0
                          ? searchCandData
                          : tablerow
                        : table === 1
                        ? tablerow.length === 0
                          ? searchJPData
                          : tablerow
                        : tablerow.length === 0
                        ? searchData
                        : tablerow
                    }
                    filename={fileName}
                    headers={
                      table === 0
                        ? colHeaders.filter((ele) => ele.isExportable === true)
                        : table === 4
                        ? colHeaders1.filter((ele) => ele.isExportable === true)
                        : table === 5
                        ? colHeaders2.filter((ele) => ele.isExportable === true)
                        : table === 1
                        ? colHeaders4.filter((ele) => ele.isExportable === true)
                        : table === 2
                        ? colHeaders3.filter((ele) => ele.isExportable === true)
                        : colHeaders.filter((ele) => ele.isExportable === true)
                    }
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>

        <Modal
          show={download1}
          onHide={() => {
            setDownload1(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setDownload1(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>
              <>
                {columns === 1
                  ? colHeaders11.map((ele, index) => (
                      <>
                        <ul className="UlCSV" key={index}>
                          <input
                            type="checkbox"
                            checked={ele.isExportable}
                            onChange={(event) =>
                              handleFormChange12(event, index, colHeaders11, setColHeaders11)
                            }
                          />
                          <span className="ListCSV">&nbsp;{ele.label}</span>
                        </ul>
                      </>
                    ))
                  : colHeaders12.map((ele, index) => (
                      <>
                        <ul className="UlCSV" key={index}>
                          <input
                            type="checkbox"
                            checked={ele.isExportable}
                            onChange={(event) =>
                              handleFormChange12(event, index, colHeaders12, setColHeaders12)
                            }
                          />
                          <span className="ListCSV">&nbsp;{ele.label}</span>
                        </ul>
                      </>
                    ))}
              </>

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setDownload1(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={columns === 1 ? data7 : tableModal1Data}
                    filename={fileName}
                    headers={
                      columns === 1
                        ? colHeaders11.filter((ele) => ele.isExportable === true)
                        : colHeaders12.filter((ele) => ele.isExportable === true)
                    }
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>

        <Modal
          show={editadmin1}
          onHide={() => {
            Seteditadmin1(false);
          }}
          size="lg"
          centered
          style={{ marginTop: "52px" }}
        >
          <Modal.Header closeButton>Update Job Fair Details</Modal.Header>
          <Modal.Body>
            <SoftBox component="form" role="form" onSubmit={registrationJP.handleSubmit}>
              <Grid container>
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox p={2}>
                    <SoftBox component="form" role="form">
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Job Fair Name <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_name"
                                name="org_name"
                                onChange={(e) => {
                                  registrationJP.handleChange;
                                  setPageOne({
                                    ...pageOne,
                                    org_name: e.target.value,
                                  });
                                }}
                                value={registrationJP.values.org_name}
                              />
                              {registrationJP.touched.org_name && registrationJP.errors.org_name ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.org_name}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                State <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftSelect
                                options={state}
                                placeholder="Select State"
                                id="org_state"
                                name="org_state"
                                onChange={(e) => {
                                  registrationJP.setFieldValue("org_state", e.label);
                                  setSelectedState(e.label);
                                  setPageOne({
                                    ...pageOne,
                                    org_state: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationJP.values.org_state }}
                              />

                              {registrationJP.touched.org_state &&
                              registrationJP.errors.org_state ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.org_state}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                District <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftSelect
                                options={district}
                                placeholder=""
                                id="org_district"
                                name="org_district"
                                onChange={(e) => {
                                  registrationJP.setFieldValue("org_district", e.label);
                                  setPageOne({
                                    ...pageOne,
                                    org_district: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationJP.values.org_district }}
                              />
                              {registrationJP.touched.org_district &&
                              registrationJP.errors.org_district ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.org_district}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Locality <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftInput
                                type="text"
                                placeholder=""
                                id="locality"
                                name="locality"
                                onChange={(e) => {
                                  registrationJP.handleChange;
                                  setPageOne({
                                    ...pageOne,
                                    locality: e.target.value,
                                  });
                                }}
                                value={registrationJP.values.locality}
                              />
                              {registrationJP.touched.locality && registrationJP.errors.locality ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.locality}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Venue <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftInput
                                type="text"
                                placeholder=""
                                id="venue"
                                name="venue"
                                onChange={(e) => {
                                  registrationJP.handleChange;
                                  setPageOne({
                                    ...pageOne,
                                    venue: e.target.value,
                                  });
                                }}
                                value={registrationJP.values.venue}
                              />
                              {registrationJP.touched.venue && registrationJP.errors.venue ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.venue}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Date <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftInput
                                type="date"
                                placeholder=""
                                id="org_date"
                                name="org_date"
                                onChange={(e) => {
                                  registrationJP.handleChange;
                                  setPageOne({
                                    ...pageOne,
                                    org_date: e.target.value,
                                  });
                                }}
                                value={registrationJP.values.org_date}
                              />
                              {registrationJP.touched.org_date && registrationJP.errors.org_date ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.org_date}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Time <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftInput
                                type="time"
                                placeholder=""
                                id="org_time"
                                name="org_time"
                                onChange={(e) => {
                                  registrationJP.handleChange;
                                  setPageOne({
                                    ...pageOne,
                                    org_time: e.target.value,
                                  });
                                }}
                                value={registrationJP.values.org_time}
                              />
                              {registrationJP.touched.org_time && registrationJP.errors.org_time ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.org_time}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Upload Banner
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                ></SoftBox>
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                id="banner"
                                name="banner"
                                onChange={(e) => {
                                  // registrationJP.setFieldValue(
                                  //   "banner",
                                  //   e.target.files[0]
                                  // );
                                  setPageOne({
                                    ...pageOne,
                                    banner_img: e.target.files[0],
                                  });
                                }}
                              />
                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>
                              {registrationJP.touched.banner && registrationJP.errors.banner ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.banner}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox p={2}>
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4}>
                      SPOC Details
                    </SoftTypography>
                    <SoftBox component="form" role="form" onSubmit={registrationJP.handleSubmit}>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Name <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftInput
                                type="text"
                                placeholder=""
                                id="lastnm"
                                name="lastnm"
                                onChange={(e) => {
                                  registrationJP.handleChange;
                                  setPageOne({
                                    ...pageOne,
                                    lastnm: e.target.value,
                                  });
                                }}
                                value={registrationJP.values.lastnm}
                              />
                              {registrationJP.touched.lastnm && registrationJP.errors.lastnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.lastnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Mobile Number <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftInput
                                type="tel"
                                placeholder=""
                                inputProps={{
                                  maxLength: "10",
                                }}
                                id="mobnum"
                                name="mobnum"
                                onChange={(e) => {
                                  registrationJP.handleChange;
                                  setPageOne({
                                    ...pageOne,
                                    mobnum: e.target.value,
                                  });
                                }}
                                value={registrationJP.values.mobnum}
                              />
                              {registrationJP.touched.mobnum && registrationJP.errors.mobnum ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.mobnum}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <SoftBox>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                Email <sup style={{ color: "red" }}>*</sup>
                              </SoftTypography>

                              <SoftInput
                                type="email"
                                placeholder=""
                                id="email"
                                name="email"
                                onChange={(e) => {
                                  registrationJP.handleChange;
                                  setPageOne({
                                    ...pageOne,
                                    email: e.target.value,
                                  });
                                }}
                                value={registrationJP.values.email}
                              />
                              {registrationJP.touched.email && registrationJP.errors.email ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationJP.errors.email}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
            </SoftBox>
          </Modal.Body>
          <Modal.Footer>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", pb: 2 }}>
              <Box sx={{ p: 1 }}>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "45px",
                    backgroundColor: "#3B5092",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width={{ lg: "200px", sm: "100%", xs: "50%" }}
                  onClick={registrationJP.handleSubmit}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Submit
                  </SoftTypography>
                </SoftBox>
              </Box>

              <Box sx={{ p: 1 }}>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "45px",
                    backgroundColor: "#c4c4c4",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width={{ lg: "200px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    Seteditadmin1(false);
                    setPageOne({
                      org_name: "",
                      org_state: "",
                      org_district: "",
                      venue: "",
                      org_date: "",
                      org_time: "",
                      lastnm: "",
                      mobnum: "",
                      email: "",
                      id: "",
                      jplink: "",
                      jslink: "",
                    });
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#3B5092",
                      fontSize: "15px",
                      fontWeight: "medium",
                      lineHeight: "19px",
                    }}
                  >
                    Cancel
                  </SoftTypography>
                </SoftBox>
              </Box>
              <br />
            </Box>
          </Modal.Footer>
        </Modal>

        {/* Modal for Job description */}
        <Modal
          show={jdModal}
          onHide={() => {
            setJDModal(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV"></p>

                <button className="CloseBtn" onClick={() => setJDModal(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SoftTypography
                  fontWeight="medium"
                  fontSize="14px"
                  lineHeight="19px"
                  color="#3B5092"
                  marginBottom="30px"
                >
                  Job Description
                </SoftTypography>
                <SoftTypography fontWeight="small" fontSize="14px" lineHeight="19px" color="#000">
                  {jd}
                </SoftTypography>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* Modal for Job description */}
        {/* Modal for Candidates list for companies */}
        <Modal
          show={tableModal1}
          onHide={() => {
            setTableModal1(false);
            // setShowEditModal(false);
          }}
          centered
          size="xl"
          closeButton
          contentClassName="modal-height"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <SoftBox width="100%" display="flex" gap="20px" justifyContent="space-between">
                <SoftBox>
                  <SoftTypography>Candidate Interacted</SoftTypography>
                </SoftBox>
                <Tooltip placement="top" title="Download">
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#000000",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => (setDownload1(!download1), setColumns(2), getFileName())}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="34"
                      viewBox="0 0 46 34"
                      fill="none"
                    >
                      <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                      <path
                        d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.9531 16.5098V23.0102"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </Tooltip>
              </SoftBox>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SoftBox>
              {/* {console.log("Hiii", channelPartners)} */}
              <Table
                columns={tableCol1}
                dataSource={tableModal1Data}
                loading={isLoading}
                // exportable
                // rowSelection={rowSelection}
                scroll={{ x: 600, y: 400 }}
                // pagination={{
                //   total: paginationStateJob.dataSize,
                //   showSizeChanger: true,
                //   onShowSizeChange: handlePageSizeChange,
                //   current: paginationStateJob.page,
                //   onChange: (page, pageSize) =>
                //     setPaginationStateJob({ ...paginationStateJob, page: page }),
                // }}
              />
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* Modal for Candidates list for companies */}

        {/* Modal for companies list for Candidates */}
        <Modal
          show={tableModal2}
          onHide={() => {
            setTableModal2(false);
            // setShowEditModal(false);
          }}
          centered
          size="xl"
          closeButton
          contentClassName="modal-height"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <SoftBox width="100%" display="flex" gap="20px" justifyContent="space-between">
                <SoftBox>
                  <SoftTypography>Companies Interacted</SoftTypography>
                </SoftBox>
                <Tooltip placement="top" title="Download">
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#000000",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => (setDownload1(!download1), setColumns(1), getFileName())}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="34"
                      viewBox="0 0 46 34"
                      fill="none"
                    >
                      <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                      <path
                        d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.9531 16.5098V23.0102"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </Tooltip>
              </SoftBox>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SoftBox width="100%">
              {/* {console.log("Hiii", channelPartners)} */}
              <Table
                columns={colName7}
                dataSource={data7}
                loading={isLoading}
                // rowSelection={rowSelection}
                scroll={{ x: 400, y: 400 }}
                exportable
                // pagination={{
                //   total: paginationStateJob.dataSize,
                //   showSizeChanger: true,
                //   onShowSizeChange: handlePageSizeChange,
                //   current: paginationStateJob.page,
                //   onChange: (page, pageSize) =>
                //     setPaginationStateJob({ ...paginationStateJob, page: page }),
                // }}
              />
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* Modal for companies list for Candidates */}

        <Modal
          show={companyModal}
          onHide={() => {
            setCompanyModal(false);
            setJobid("");
          }}
          centered
          size="xl"
          closeButton
          contentClassName="modal-height"
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <Box sx={{ backgroundColor: "#FFFCF5" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#FFF0E7",
                  mb: 1,
                }}
              >
                <p></p>
                <p className="HeaderCSV2">Company Profile</p>

                <button className="CloseBtn" onClick={() => setCompanyModal(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px solid #848383",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mt: 2,
                            minHeight: "30px",
                          }}
                        >
                          <SoftTypography
                            fontStyle="normal"
                            fontWeight="small"
                            fontSize="14px"
                            lineHeight="18px"
                            sx={{ color: "#000" }}
                          >
                            <span style={{ color: "#848383" }}>Company Name:</span>{" "}
                            {text.company_name}
                          </SoftTypography>

                          <SoftTypography
                            fontStyle="normal"
                            fontWeight="small"
                            fontSize="14px"
                            lineHeight="18px"
                            sx={{ color: "#000" }}
                          >
                            <span style={{ color: "#848383" }}>Company Type:</span>{" "}
                            {text.industry_sector}
                          </SoftTypography>
                        </Box>
                        <hr />

                        <Grid container>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Location:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {text.org_address}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>State:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {text.state}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>District:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {text.district}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              {/* <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                              <Box sx={{ width: "100%", textAlign: "right" }}>
                                <SoftTypography
                                  fontStyle="normal"
                                  fontWeight="small"
                                  fontSize="14px"
                                  lineHeight="18px"
                                  sx={{ color: "#000" }}
                                >
                                  <span style={{ color: "#848383" }}>Taluka:</span>&nbsp;
                                </SoftTypography>
                              </Box>

                              <Box sx={{ width: "100%" }}>
                                <SoftTypography
                                  fontStyle="normal"
                                  fontWeight="small"
                                  fontSize="14px"
                                  lineHeight="18px"
                                  sx={{ color: "#000" }}
                                >
                                  {text.taluka}
                                </SoftTypography>
                              </Box>
                            </Box> */}
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={{ color: "#000", m: 0.5 }}
                              >
                                SPOC Details
                              </SoftTypography>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Name:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {text.firstnm + " " + text.lastnm}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Mobile Number:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#006CD0" }}
                                  >
                                    {text.mobnum}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Email:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#006CD0" }}
                                  >
                                    {text.email}
                                  </SoftTypography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px solid #848383",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            m: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ width: "100%" }}></Box>
                          <Box sx={{ width: "100%" }}>
                            <SoftTypography
                              fontStyle="normal"
                              fontWeight="600"
                              fontSize="18px"
                              lineHeight="18px"
                              sx={{ color: "#000", m: 0.5 }}
                            >
                              Interacted Candidates
                            </SoftTypography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              mr: 3,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setJobid("");
                              fetchAllCandidateInteractedList(text.key);
                            }}
                          >
                            <SoftBox
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize="14px"
                              // lineHeight="18px"
                              sx={{
                                color: "#fff",
                                backgroundColor: "#3B5092",
                                width: "80px",
                                height: "35px",
                                // m: 0.5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "10px",
                              }}
                            >
                              {translate("all")}
                            </SoftBox>
                          </Box>
                        </Box>
                        <Box className="batch-table">
                          <Table
                            loading={isLoading}
                            columns={colName6}
                            dataSource={data6}
                            scroll={data6.length <= 2 ? { x: 400, y: 400 } : { x: 400 }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      border: "1px solid #848383",
                      borderRadius: "10px",
                      width: "100%",
                      height: "100%",
                      textAlign: "center",
                    }}
                  >
                    <SoftTypography
                      fontStyle="normal"
                      fontWeight="600"
                      fontSize="18px"
                      lineHeight="18px"
                      sx={{
                        color: "#6C6C6C",
                        // m: 0.5,
                        // mt: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#FFF0E7",
                        height: "40px",
                        borderBottom: "1px solid #6C6C6C",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      Job Fair Attended
                    </SoftTypography>

                    {/* <hr /> */}
                    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                      <SoftTypography
                        fontStyle="normal"
                        fontWeight="small"
                        fontSize="14px"
                        lineHeight="18px"
                        sx={{ color: "#000", m: 0.5 }}
                      >
                        Date
                      </SoftTypography>

                      <SoftTypography
                        fontStyle="normal"
                        fontWeight="small"
                        fontSize="14px"
                        lineHeight="18px"
                        sx={{ color: "#000", m: 0.5 }}
                      >
                        Job Fair Name
                      </SoftTypography>
                    </Box>

                    <Box sx={{ height: "580px", overflow: "auto", p: 1 }}>
                      {jobfairdata.map((ele, id) => {
                        return (
                          <Box key={ele.key}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                p: 1,
                                cursor: "pointer",
                              }}
                              onClick={() => (
                                setJobid(id), fetchCandidateInteractedList(text.key, ele.key)
                              )}
                            >
                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={{ color: "#000", m: 0.5 }}
                              >
                                {ele.date}
                              </SoftTypography>

                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={
                                  jobid === id
                                    ? { color: "#1B59F8", m: 0.5 }
                                    : { color: "#000", m: 0.5 }
                                }
                              >
                                {ele.job_fair_name}
                              </SoftTypography>
                            </Box>

                            {jobid === id
                              ? ele.job_details?.map((item, id) => (
                                  <Box
                                    sx={{
                                      border: "1px solid #C4C4C4",
                                      borderRadius: "10px",
                                      m: 0.5,
                                      mb: 1,
                                      p: 1,
                                    }}
                                    key={id}
                                  >
                                    <Box sx={{ display: "flex", width: "100%" }}>
                                      <Box sx={{ width: "100%", textAlign: "left" }}>
                                        <SoftTypography
                                          fontStyle="normal"
                                          fontWeight="small"
                                          fontSize="14px"
                                          lineHeight="18px"
                                          sx={{ color: "#848383", m: 0.5 }}
                                        >
                                          Job Role:&nbsp;
                                        </SoftTypography>
                                      </Box>

                                      <Box sx={{ width: "100%", textAlign: "left" }}>
                                        <SoftTypography
                                          fontStyle="normal"
                                          fontWeight="small"
                                          fontSize="14px"
                                          lineHeight="18px"
                                          sx={{ color: "#000", m: 0.5 }}
                                        >
                                          {item.job_role}
                                        </SoftTypography>
                                      </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", width: "100%" }}>
                                      <Box sx={{ width: "100%", textAlign: "left" }}>
                                        <SoftTypography
                                          fontStyle="normal"
                                          fontWeight="small"
                                          fontSize="14px"
                                          lineHeight="18px"
                                          sx={{ color: "#848383", m: 0.5 }}
                                        >
                                          No. of Candidates:&nbsp;
                                        </SoftTypography>
                                      </Box>

                                      <Box sx={{ width: "100%", textAlign: "left" }}>
                                        <SoftTypography
                                          fontStyle="normal"
                                          fontWeight="small"
                                          fontSize="14px"
                                          lineHeight="18px"
                                          sx={{ color: "#000", m: 0.5 }}
                                        >
                                          {item.count}
                                        </SoftTypography>
                                      </Box>
                                    </Box>
                                    <hr style={{ color: "#848383" }} />

                                    <SoftTypography
                                      fontStyle="normal"
                                      fontWeight="small"
                                      fontSize="14px"
                                      lineHeight="18px"
                                      sx={{ color: "#848383", m: 0.5 }}
                                    >
                                      Job Description
                                    </SoftTypography>

                                    <Box textAlign="left">
                                      {/* <SoftTypography
                                        fontStyle="normal"
                                        fontWeight="small"
                                        fontSize="14px"
                                        lineHeight="18px"
                                        sx={{ color: "#000", m: 0.5 }}
                                      >
                                        {item.job_desc}
                                      </SoftTypography> */}
                                      <Typography
                                        variant="body2"
                                        sx={{ color: "#000000" }}
                                        dangerouslySetInnerHTML={createMarkup(
                                          item.job_desc ? item.job_desc : "<p>NA</p>"
                                        )}
                                      />
                                    </Box>
                                  </Box>
                                ))
                              : null}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Modal.Body>
        </Modal>

        <Modal
          show={studentModal}
          onHide={() => {
            setStudentModal(false);
            setJobid("");
          }}
          centered
          size="xl"
          closeButton
          contentClassName="modal-height"
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <Box sx={{ backgroundColor: "#FFFCF5" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#FFF0E7",
                  mb: 1,
                }}
              >
                <p></p>
                <p className="HeaderCSV2">Candidate Profile</p>

                <button className="CloseBtn" onClick={() => setStudentModal(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px solid #848383",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "space-between",
                            gap: "30px",
                            mt: 2,
                            mx: 2,
                            minHeight: "30px",
                          }}
                        >
                          <SoftBox
                            sx={{
                              // m: 1,
                              // mt: 2,
                              border: "2px solid #6C6C6C",
                              borderRadius: "50%",
                              width: "65px",
                              height: "65px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "start",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={
                                candDetails.profile !== "" && candDetails.profile
                                  ? candDetails.profile
                                  : defaultimg
                              }
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          </SoftBox>
                          <SoftBox
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              width: "60%",
                            }}
                          >
                            <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                textAlign="start"
                                sx={{ color: "#000" }}
                              >
                                <span style={{ color: "#848383" }}>Name:</span>{" "}
                                {`${candDetails.first_name} ${candDetails.last_name}`}
                              </SoftTypography>

                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                textAlign="start"
                                sx={{ color: "#000" }}
                              >
                                <span style={{ color: "#848383" }}>D.O.B:</span>{" "}
                                {moment(candDetails.date_of_birth).format("DD-MM-YYYY")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                textAlign="start"
                                sx={{ color: "#000" }}
                              >
                                <img src={img2} height={"20px"} alt="" />
                                &nbsp;{candDetails.mobile}
                              </SoftTypography>
                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                textAlign="start"
                                sx={{ color: "#000" }}
                              >
                                <img src={img1} height={"20px"} alt="" />
                                &nbsp;{candDetails.email}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Box>
                        <hr />

                        <Grid container>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {/* <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Aadhar Number:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {text.mobile}
                                  </SoftTypography>
                                </Box>
                              </Box> */}

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Highest Qualification:</span>
                                    &nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.highest_education
                                      ? candDetails.highest_education
                                      : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>
                                      College/Institution Name:
                                    </span>
                                    &nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.clgname ? candDetails.clgname : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Stream:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.stream ? candDetails.stream : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Experience:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.experience ? candDetails.experience : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Gender:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.gender ? candDetails.gender : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Marital Status:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.marital_status ? candDetails.marital_status : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Language Known:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.languages ? candDetails.languages : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Skills:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.skills ? candDetails.skills : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>

                              {/* <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Cast:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {text.cast}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Religion:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {text.religion}
                                  </SoftTypography>
                                </Box>
                              </Box> */}
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>State:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.state ? candDetails.state : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>District:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.district ? candDetails.district : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Taluka:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.taluka ? candDetails.taluka : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Address:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.address ? candDetails.address : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Pincode:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.pincode ? candDetails.pincode : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Current Salary:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.current_salary ? candDetails.current_salary : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Desired Salary:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.desired_salary ? candDetails.desired_salary : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                              <Box sx={{ display: "flex", width: "100%", m: 0.5 }}>
                                <Box sx={{ width: "100%", textAlign: "right" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    <span style={{ color: "#848383" }}>Desired Role:</span>&nbsp;
                                  </SoftTypography>
                                </Box>

                                <Box sx={{ width: "100%" }}>
                                  <SoftTypography
                                    fontStyle="normal"
                                    fontWeight="small"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    sx={{ color: "#000" }}
                                  >
                                    {candDetails.job_role ? candDetails.job_role : "-"}
                                  </SoftTypography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          border: "1px solid #848383",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            // m: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "#FFF0E7",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <Box sx={{ width: "100%" }}></Box>
                          <Box sx={{ width: "100%", textAlign: "center", m: 1 }}>
                            <SoftTypography
                              fontStyle="normal"
                              fontWeight="600"
                              fontSize="18px"
                              lineHeight="18px"
                              sx={{ color: "#000", my: "10px" }}
                            >
                              Interacted Company
                            </SoftTypography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              mr: 3,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setJobid("");
                              fetchAllCompanyInteractedList(text.key);
                            }}
                          >
                            <SoftBox
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize="14px"
                              // lineHeight="18px"
                              sx={{
                                color: "#fff",
                                backgroundColor: "#3B5092",
                                width: "80px",
                                height: "35px",
                                // m: 0.5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "10px",
                                my: "10px",
                              }}
                            >
                              {translate("all")}
                            </SoftBox>
                          </Box>
                        </Box>

                        <Box className="batch-table">
                          <Table
                            loading={isLoading}
                            columns={colName7}
                            dataSource={data7}
                            scroll={{ y: 400 }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      border: "1px solid #848383",
                      borderRadius: "10px",
                      width: "100%",
                      height: "100%",
                      textAlign: "center",
                    }}
                  >
                    <SoftTypography
                      fontStyle="normal"
                      fontWeight="600"
                      fontSize="18px"
                      // lineHeight="18px"
                      sx={{
                        color: "#6C6C6C",
                        // textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#FFF0E7",
                        height: "40px",
                        borderBottom: "1px solid #6C6C6C",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      Job Fair Attended
                    </SoftTypography>

                    {/* <hr /> */}
                    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                      <SoftTypography
                        fontStyle="normal"
                        fontWeight="small"
                        fontSize="14px"
                        lineHeight="18px"
                        sx={{ color: "#000", m: 0.5 }}
                      >
                        Date
                      </SoftTypography>

                      <SoftTypography
                        fontStyle="normal"
                        fontWeight="small"
                        fontSize="14px"
                        lineHeight="18px"
                        sx={{ color: "#000", m: 0.5 }}
                      >
                        Job Fair Name
                      </SoftTypography>
                    </Box>

                    <Box
                      sx={{
                        height: jobfairdata.length === 0 ? "100%" : "480px",
                        overflowY: "scroll",
                        p: 1,
                      }}
                    >
                      {jobfairdata.map((ele, id) => {
                        return (
                          <Box key={ele.id}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                p: 1,
                                cursor: "pointer",
                              }}
                              onClick={() => (
                                setJobid(id), fetchCompanyInteractedList(text.key, ele.key)
                              )}
                            >
                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={{ color: "#000", m: 0.5 }}
                              >
                                {ele.date}
                              </SoftTypography>

                              <SoftTypography
                                fontStyle="normal"
                                fontWeight="small"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={
                                  jobid === id
                                    ? { color: "#1B59F8", m: 0.5 }
                                    : { color: "#000", m: 0.5 }
                                }
                              >
                                {ele.job_fair_name}
                              </SoftTypography>
                            </Box>

                            {jobid === id
                              ? ele.job_details?.map((item, id) => (
                                  <Box
                                    sx={{
                                      border: "1px solid #C4C4C4",
                                      borderRadius: "10px",
                                      m: 0.5,
                                      mb: 1,
                                      p: 1,
                                    }}
                                    key={id}
                                  >
                                    <Box sx={{ display: "flex", width: "100%" }}>
                                      <Box sx={{ width: "100%", textAlign: "left" }}>
                                        <SoftTypography
                                          fontStyle="normal"
                                          fontWeight="small"
                                          fontSize="14px"
                                          lineHeight="18px"
                                          sx={{ color: "#848383", m: 0.5 }}
                                        >
                                          Job Role:&nbsp;
                                        </SoftTypography>
                                      </Box>

                                      <Box sx={{ width: "100%", textAlign: "left" }}>
                                        <SoftTypography
                                          fontStyle="normal"
                                          fontWeight="small"
                                          fontSize="14px"
                                          lineHeight="18px"
                                          sx={{ color: "#000", m: 0.5 }}
                                        >
                                          {item.job_role}
                                        </SoftTypography>
                                      </Box>
                                    </Box>

                                    <Box sx={{ display: "flex", width: "100%" }}>
                                      <Box sx={{ width: "100%", textAlign: "left" }}>
                                        <SoftTypography
                                          fontStyle="normal"
                                          fontWeight="small"
                                          fontSize="14px"
                                          lineHeight="18px"
                                          sx={{ color: "#848383", m: 0.5 }}
                                        >
                                          No. of Candidates:&nbsp;
                                        </SoftTypography>
                                      </Box>

                                      <Box sx={{ width: "100%", textAlign: "left" }}>
                                        <SoftTypography
                                          fontStyle="normal"
                                          fontWeight="small"
                                          fontSize="14px"
                                          lineHeight="18px"
                                          sx={{ color: "#000", m: 0.5 }}
                                        >
                                          {item.count}
                                        </SoftTypography>
                                      </Box>
                                    </Box>
                                    <hr style={{ color: "#848383" }} />

                                    <SoftTypography
                                      fontStyle="normal"
                                      fontWeight="small"
                                      fontSize="14px"
                                      lineHeight="18px"
                                      sx={{ color: "#848383", m: 0.5 }}
                                    >
                                      Job Description
                                    </SoftTypography>

                                    <Box textAlign="left">
                                      {/* <SoftTypography
                                        fontStyle="normal"
                                        fontWeight="small"
                                        fontSize="14px"
                                        lineHeight="18px"
                                        sx={{ color: "#000", m: 0.5 }}
                                      >
                                        {item.job_desc}
                                      </SoftTypography> */}
                                      <Typography
                                        variant="body2"
                                        sx={{ color: "#000000" }}
                                        dangerouslySetInnerHTML={createMarkup(
                                          item.job_desc ? item.job_desc : "<p>NA</p>"
                                        )}
                                      />
                                    </Box>
                                  </Box>
                                ))
                              : null}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Modal.Body>
        </Modal>

        <Index
          open={showFilter}
          setOpen={setShowFilter}
          statefilter={statefilter}
          setStatefilter={setStatefilter}
          districtfilter={districtfilter}
          setDistrictfilter={setDistrictfilter}
          datefilter={datefilter}
          setDatefilter={setDatefilter}
          resetfunc={resetfunc}
          jffilter={jffilter}
          setJffilter={setJffilter}
          table={table}
          educationfilter={educationfilter}
          setEducationfilter={setEducationfilter}
          rolefilter={rolefilter}
          setRolefilter={setRolefilter}
          degreefilter={degreefilter}
          setDegreefilter={setDegreefilter}
          boardingfilter={boardingfilter}
          setBoardingfilter={setBoardingfilter}
        />
      </DashboardLayout>
    </>
  );
};

export default JobFairs;
