import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
// Custom Components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import axios from "axios";
// Utilities
import { AGENT } from "utils/constant";
import { ADMIN } from "utils/constant";
import { spacing } from "@mui/system";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { validate } from "react-email-validator";
// Images
// import image from "../../assets/images/landing_img.png";
import image from "assets/sba_images/png/home_screen.png";
import logo from "../../assets/sba_images/png/SamarthaLogo.png";
import image1 from "../../assets/images/jobfairsvgs/jobfairBG.png";
import pageRoutes from "page.routes";
import Card from "@mui/material/Card";
import SoftSelect from "components/SoftSelect";
import img1 from "../../assets/images/Samarth - Logo 1.svg";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextField, IconButton, InputAdornment, outlinedInputClasses } from "@mui/material";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "#B3B6B7",
            borderRadius: "8px",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0dcaf0",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "#0dcaf0",
            },
          },
        },
      },
    },
  });

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: "white",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  fontSize: "12px",
}));

const Forgotpassword1 = () => {
  const transparent = false;
  const light = false;
  const [userRole, setUserRole] = useState(AGENT);
  const [step1, setStep1] = useState(false);
  const [page1, setpage1] = useState(true);
  const [checkEmail, setCheckEmail] = useState(false);
  const [passwordPage, setPasswordPage] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [backNav, setBackNav] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [passwordnew, setPasswordnew] = useState("");
  const [passwordconf, setPasswordconf] = useState("");
  const [verifycode, setVerifycode] = useState("");
  const [emailnew, setEmailnew] = useState("");

  const navigate = useNavigate();
  const outerTheme = useTheme();

  const CheckEmailFunc = async () => {
    try {
      const res = await axios.post();
      const data = await res.data;
    } catch (err) {
      console.log(err);
    }
  };
  const CheckEmailFunction = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setEmailnew(values.email);
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/auth/reset/password`,
          {
            email: values.email,
            type: "1",
          }
        );
        console.log(res.data);
        setTimeout(() => {
          waitingPopup.close();
        }, 0);
        setpage1(false);
        setPasswordPage(true);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong!",
          text: err.response.data["User Message"]
            ? err.response.data["User Message"]
            : "Something went wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  });
  const ChangePasswordFunction = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: "",
      verification_code: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Please enter password")
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
      verification_code: Yup.string().required("Please enter a verification code"),
    }),
    onSubmit: async (values, { resetForm }) => {
      delete values.confirm_password;
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/admin/auth/reset/password`,
          {
            type: "2",
            email: emailnew,
            verification_code: values.verification_code,
            password: values.password,
          }
        );
        setTimeout(() => {
          waitingPopup.close();
        }, 0);
        navigate("/login");
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong!",
          text: err.response.data["User Message"]
            ? err.response.data["User Message"]
            : "Something went wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  });
  const ChangePassword = async () => {
    if (passwordnew !== passwordconf) {
      Swal.fire({
        icon: "error",
        title: "Password should be same",
        //text: res.data["User Message"],
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      try {
        const res = await axios.post();
        const data = await res.data;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const changeUser = (e, type) => {
    // double clicking on same option deselects and sets type as null
    if (type !== null) {
      setUserRole(type);
    }
  };

  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    securityQuestionFunc();
  }, []);
  return (
    <>
      <PageLayout>
        <DefaultNavbar
          routes={pageRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
          light
        />
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          sx={
            window.location.host.split(".")[0] !== "jobfairadmin"
              ? {
                  backgroundColor: "#CBE3FF",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }
              : {
                  backgroundImage: ({
                    functions: { linearGradient, rgba },
                    palette: { gradients },
                  }) =>
                    image1 &&
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.1),
                      rgba(gradients.dark.state, 0.1)
                    )}, url(${image1})`,
                  // {rgba(59,80,146,0.85),url({image}),
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }
          }
          // sx={{
          //   backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          //     image &&
          //     `${linearGradient(
          //       rgba(gradients.dark.main, 0.6),
          //       rgba(gradients.dark.state, 0.6)
          //     )}, url(${image})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   backgroundRepeat: "no-repeat",
          // }}
        >
          <Card sx={{ overflow: "visible" }}>
            {/*
            {backNav ?
              null :
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                <button
                  className="login-btn"
                  style={{ margin: '16px' }}
                  onClick={() => {
                    navigate('/login')
                  }}
                // onKeyPress={(e) => handleKeyPress(e)}
                >
                  <span className="btn-txt">Back</span>
                </button>
              </Box>
            }
            */}

            <SoftBox
              pl={10}
              pr={10}
              pt={8}
              pb={10}
              px={{ xs: transparent ? 2 : 3, sm: transparent ? 1 : 2, lg: 15 }}
              margin="auto"
              height="70%"
              borderRadius="sm"
              shadow={transparent ? "none" : "md"}
              color={light ? "white" : "dark"}
              display="flex"
              flexDirection="column"
              gap="1em"
              justifyContent="center"
              alignItems="center"
              zIndex={3}
              sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
                backgroundColor: transparent ? "primary" : "#ffff",
                backdropFilter: transparent ? "none" : `blur(4px)`,
              })}
            >
              <img
                src={img1}
                height={50}
                //style={{ xs: { width: "20%" }, md: { width: "250px" } }}
              />
              <SoftTypography mt={3} fontWeight="600" lineHeight="23px" fontSize="18px">
                Forgot Password
              </SoftTypography>

              {page1 && (
                <SoftBox
                  borderRadius="sm"
                  sx={{
                    width: "100%",
                    background: "white",
                  }}
                >
                  <SoftBox pt={2} px={1} display="flex" flexDirection="column" gap="20px">
                    <SoftBox
                      width="395px"
                      component="form"
                      role="form"
                      onSubmit={CheckEmailFunction.handleSubmit}
                    >
                      <SoftTypography variant="h10" color="black" fontWeight="350" fontSize="15px">
                        Enter Your Email
                      </SoftTypography>
                      <SoftBox display="flex">
                        <SoftInput
                          type="email"
                          placeholder="Email Id"
                          sx={{
                            mr: 1,
                          }}
                          id="email"
                          name="email"
                          onChange={CheckEmailFunction.handleChange}
                          value={CheckEmailFunction.values.email}
                        />
                        <SoftButton
                          variant="gradient"
                          color="dark"
                          sx={{
                            minWidth: "110px",
                          }}
                          // onClick={() => {
                          //   //   setStep1(true);
                          //   setpage1(false);
                          //   setCheckEmail(true);
                          // }}
                          onClick={CheckEmailFunction.handleSubmit}
                        >
                          Check
                        </SoftButton>
                      </SoftBox>
                      {CheckEmailFunction.touched.email && CheckEmailFunction.errors.email ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {CheckEmailFunction.errors.email}
                        </span>
                      ) : null}

                      <Box sx={{ width: "100%", textAlign: "center", mt: 3 }}>
                        <SoftTypography
                          color="black"
                          fontWeight="350"
                          fontSize="15px"
                          onClick={() => {
                            navigate("/login");
                            setEmailnew("");
                            CheckEmailFunction.handleReset();
                          }}
                          sx={{
                            paddingRight: "12px",
                            // cursor: "pointer",
                          }}
                        >
                          Back to{" "}
                          <SoftTypography
                            component={Link}
                            to="/login"
                            variant="button"
                            color="#FFFFFF"
                            fontWeight="bold"
                            textGradient
                            sx={{ cursor: "pointer" }}
                          >
                            Login Page
                          </SoftTypography>
                        </SoftTypography>
                      </Box>
                    </SoftBox>
                    {step1 && (
                      <SoftBox>
                        <SoftBox display="flex" flexDirection="column" gap="20px">
                          <SoftTypography
                            display="flex"
                            justifyContent="center"
                            fontWeight="350"
                            fontSize="15px"
                          >
                            Reset Password via
                          </SoftTypography>
                          <SoftBox display="flex" flexDirection="row" gap="30px">
                            <SoftBox display="flex" flexDirection="row" gap="5px">
                              <input type="radio" value="Role" name="keyword" />
                              <SoftTypography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontWeight: "small",
                                  fontSize: "15px",
                                  lineHeight: "19px",
                                }}
                              >
                                Mobile Number
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" flexDirection="row" gap="5px">
                              <input type="radio" value="Role" name="keyword" />
                              <SoftTypography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontWeight: "small",
                                  fontSize: "15px",
                                  lineHeight: "19px",
                                }}
                              >
                                Security Question
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    )}
                  </SoftBox>

                  {/* <SoftBox px={2}>
                    <SoftBox mt={4} mb={1} align="center">
                      <Link to="/agent/business">
                        <SoftButton variant="gradient" color="warning">
                          Processed
                        </SoftButton>
                      </Link>
                    </SoftBox>
                  </SoftBox> */}
                </SoftBox>
              )}
              {checkEmail && (
                <SoftBox display="flex" flexDirection="column" gap="20px">
                  <SoftBox display="flex" flexDirection="column" width="395px">
                    <SoftBox fontWeight="600" fontSize="15px" lineHeight="26px">
                      Security Question
                    </SoftBox>
                    <SoftSelect
                      placeholder="Select Question"
                      options={questions}
                      id="security_ques"
                      name="security_ques"
                    />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column" width="395px">
                    <SoftBox fontWeight="600" fontSize="15px" lineHeight="26px">
                      Answer
                    </SoftBox>
                    <SoftInput type="text" placeholder="" id="security_ans" name="security_ans" />
                  </SoftBox>

                  {/* <SoftBox mt={2}>
                    <SoftTypography>Next</SoftTypography>
                  </SoftBox> */}
                  <SoftBox mt={1} display="flex" justifyContent="center">
                    <SoftButton
                      variant="gradient"
                      color="dark"
                      sx={{
                        minWidth: "110px",
                      }}
                      onClick={() => {
                        //   setStep1(true);
                        setCheckEmail(false);
                        setPasswordPage(true);
                      }}
                    >
                      Next
                    </SoftButton>
                  </SoftBox>

                  <Box sx={{ width: "100%", textAlign: "center", mt: 3 }}>
                    <SoftTypography
                      color="black"
                      fontWeight="350"
                      fontSize="15px"
                      onClick={() => {
                        navigate("/login");
                        setPasswordnew("");
                        setVerifycode("");
                      }}
                      sx={{
                        paddingRight: "12px",
                        // cursor: "pointer",
                      }}
                    >
                      Back to{" "}
                      <SoftTypography
                        component={Link}
                        to="/login"
                        variant="button"
                        color="#FFFFFF"
                        fontWeight="bold"
                        textGradient
                        sx={{ cursor: "pointer" }}
                      >
                        Login Page
                      </SoftTypography>
                    </SoftTypography>
                  </Box>
                </SoftBox>
              )}
              {passwordPage && (
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  gap="20px"
                  onSubmit={ChangePasswordFunction.handleSubmit}
                >
                  <SoftBox display="flex" flexDirection="column" width="350px" />
                  <SoftTypography
                    variant="h10"
                    color="black"
                    fontWeight="350"
                    fontSize="15px"
                    marginBottom="-12px"
                  >
                    New Password
                  </SoftTypography>

                  <ThemeProvider theme={customTheme(outerTheme)}>
                    <TextField
                      // id="outlined-size-small"
                      size="small"
                      fullWidth
                      type={showPassword1 ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ ml: "auto" }}>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword1((show) => !show)}
                              edge="end"
                              sx={{ color: "#B3B6B7", ml: "auto" }}
                            >
                              {showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      id="password"
                      name="password"
                      placeholder="Password"
                      onChange={ChangePasswordFunction.handleChange}
                      value={ChangePasswordFunction.values.password}
                    />
                  </ThemeProvider>
                  {ChangePasswordFunction.touched.password &&
                  ChangePasswordFunction.errors.password ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {ChangePasswordFunction.errors.password}
                    </span>
                  ) : null}

                  <SoftTypography
                    variant="h10"
                    color="black"
                    fontWeight="350"
                    fontSize="15px"
                    marginBottom="-12px"
                  >
                    Confirm Password
                  </SoftTypography>
                  <ThemeProvider theme={customTheme(outerTheme)}>
                    <TextField
                      // id="outlined-size-small"
                      size="small"
                      fullWidth
                      type={showPassword2 ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ ml: "auto" }}>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword2((show) => !show)}
                              edge="end"
                              sx={{ color: "#B3B6B7", ml: "auto" }}
                            >
                              {showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={ChangePasswordFunction.handleChange}
                      value={ChangePasswordFunction.values.confirm_password}
                      placeholder="Confirm Password"
                      id="confirm_password"
                      name="confirm_password"
                    />
                  </ThemeProvider>
                  {ChangePasswordFunction.touched.confirm_password &&
                  ChangePasswordFunction.errors.confirm_password ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {ChangePasswordFunction.errors.confirm_password}
                    </span>
                  ) : null}
                  <SoftTypography
                    variant="h10"
                    color="black"
                    fontWeight="350"
                    fontSize="15px"
                    marginBottom="-12px"
                  >
                    Verification Code
                  </SoftTypography>
                  <ThemeProvider theme={customTheme(outerTheme)}>
                    <TextField
                      size="small"
                      fullWidth
                      type="text"
                      id="verification_code"
                      name="verification_code"
                      onChange={ChangePasswordFunction.handleChange}
                      value={ChangePasswordFunction.values.verification_code}
                      placeholder="Verification Code"
                    />
                  </ThemeProvider>
                  {ChangePasswordFunction.touched.verification_code &&
                  ChangePasswordFunction.errors.verification_code ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {ChangePasswordFunction.errors.verification_code}
                    </span>
                  ) : null}

                  <SoftBox px={2}>
                    <SoftBox mt={4} mb={1} align="center">
                      <SoftButton
                        variant="gradient"
                        color="warning"
                        onClick={ChangePasswordFunction.handleSubmit}
                      >
                        Proceed
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                  <Box sx={{ width: "100%", textAlign: "center", mt: 1 }}>
                    <SoftTypography
                      color="black"
                      fontWeight="350"
                      fontSize="15px"
                      onClick={() => {
                        navigate("/login");
                      }}
                      sx={{
                        paddingRight: "12px",
                        // cursor: "pointer",
                      }}
                    >
                      Back to{" "}
                      <SoftTypography
                        component={Link}
                        to="/login"
                        variant="button"
                        color="#FFFFFF"
                        fontWeight="bold"
                        textGradient
                        sx={{ cursor: "pointer" }}
                      >
                        Login Page
                      </SoftTypography>
                    </SoftTypography>
                  </Box>
                </SoftBox>
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </PageLayout>
    </>
  );
};

export default Forgotpassword1;
