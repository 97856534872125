/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import moment from "moment";
// Data
import timelineData from "./TimelineItem/timelineData.js";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import FadeLoader from "react-spinners/FadeLoader";

function Timeline(props) {
  const { time, setTime, timelineLoading } = props;
  const [newtimelineData, setNewTimeLineData] = useState([]);
  {
    console.log("timelinedata in Timeline file", time);
  }
  // {
  //   time.map((ele, i) =>
  //     setNewTimeLineData([
  //       ...newtimelineData,
  //       {
  //         color: ele.status !== "" ? ele.status : "success",
  //         icon: "notifications",
  //         title: ele.type,
  //         dateTime: "22 DEC 7:20 PM",
  //         description:
  //           "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  //         badges: ["design"],
  //       },
  //     ])
  //   );
  // }
  // setNewTimeLineData(() =>
  //   time.map((ele, i) => ({
  //     color: ele.status !== "" ? ele.status : "success",
  //     icon: "notification",
  //     title: ele.type,
  //     dateTime: "22 DEC 7:20 PM",
  //     description:
  //       "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  //     badges: ["design"],
  //   }))
  // );
  console.log(time)
  const xyz = time.filter((item)=>(item.status!=="" )).filter((it)=>(it.status!==null )).map(
    (ele, i) => (
      console.log(`time ${i}`, ele),
      // ele.status && 
      {
        color: ele.status !== "" ? "success"  : "error",
        icon: "notification",
        title: ele.type,
        organization: ele.org_name || "-",
        jobpost: ele.job_title,
        dateTime: ele.date !== "" ? moment(ele.date).format("DD-MM-YYYY") : " - ",
        description: ele.status ? ele.status : "",
        // badges: ["design"],
      }
      // ele.status === null && {
      //   color: ele.status !== "" ? "success" : ele.status === null ? "error" : "error",
      //   icon: "notification",
      //   title: ele.type,
      //   dateTime: ele.date !== "" ? moment(ele.date).format("DD-MM-YYYY") : " - ",
      //   description: ele.status ? ele.status : "",
      // }
      // : {
      //     color: ele.status !== "" ? "success" : ele.status === null ? "error" : "error",
      //     icon: "notification",
      //     title: ele.type,
      //     dateTime: ele.date !== "" ? moment(ele.date).format("DD-MM-YYYY") : " - ",
      //     description: ele.status ? ele.status : "",
      //     // badges: ["design"],
      //   }
    )
  );
  console.log("timing", xyz);
  useEffect(() => {
    // setNewTimeLineData(time);
    setNewTimeLineData(xyz);
  }, []);
  {
    console.log("newtimeline ", xyz);
  }
  const renderTimelineItems = xyz
    .filter((item, id) => {
      return item !== "";
    })
    .reverse()
    .map(
      ({ color, icon, title, organization, dateTime, description, badges, lastItem, jobpost }) => (
        console.log("timeline title", title),
        console.log("timeline dateTime", dateTime),
        console.log("timeline organization", organization),
        console.log("timeline description", description),
        (
          <TimelineItem
            key={title + color}
            color={color}
            icon={dateTime ? "done" : ""}
            title={title}
            organization={organization}
            dateTime={dateTime}
            description={description}
            jobpost={jobpost}
            // badges={badges}
            lastItem={lastItem}
          />
        )
      )
    );
  console.log(timelineData);

  return (
    <>
      {" "}
      <SoftBox height="300px" sx={{ overflowY: "scroll" }}>
        <Grid container spacing={2} sx={{ mt: -3 }}>
          <Grid item xs={12} lg={12}>
            {console.log("renderTimelineItems", renderTimelineItems)}

            {timelineLoading ? (
              <SoftBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "50px",
                }}
              >
                <FadeLoader
                  color="#B3B6B7"
                  height={12}
                  width={5}
                  radius={2}
                  margin={10}
                  // style={{ display: "flex", justifyContent: "center" }}
                />
              </SoftBox>
            ) : (
              <TimelineList title="">{renderTimelineItems}</TimelineList>
            )}
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <TimelineList title="Timeline with dotted line" dark>
              {renderTimelineItems}
            </TimelineList>
          </Grid> */}
        </Grid>
      </SoftBox>
      {/* <Footer /> */}
    </>
  );
}
Timeline.propTypes = {
  time: PropTypes.array,
  setTime: PropTypes.array,
  timelineLoading: PropTypes.bool,
};

export default Timeline;
