import { Card, Stack } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import SoftSelect from "../../components/SoftSelect";
import SoftInput from "components/SoftInput";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { width } from "@mui/system";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const copy = require("clipboard-copy");
const Jobproviderreg = () => {
  const ref = useRef();
  const [resumefile, setResumeFile] = useState("");
  const [autopass, Setautopass] = useState(true);
  const [check, setCheck] = useState(false);
  const [crmOptinYes, setCrmOptinYes] = useState(false);
  const [crmOptinYes1, setCrmOptinYes1] = useState(false);
  const [crmOptinNo, setCrmOptinNo] = useState(false);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });
  const [copyPassword1, setCopyPassword1] = useState({
    value: "",
    copied: false,
  });
  const [copyPassword2, setCopyPassword2] = useState({
    value: "",
    copied: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [questions, setQuestions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  // const [formLink,setFormLink] = useState("");
  const [show, setShow] = useState(false);
  const [showpassword, Setshowpassword] = useState(false);
  const [displayPassword, setDisplayPassword] = useState("");
  const [addressChar, setAddressChar] = useState(250);
  // const [centerOwnerId,setCenterOwnerId]= useState("");
  const RoleOptions = [
    // { label: "Default", value: "Default" },
    // { label: "Retention", value: "Retention" },
    { label: "Requirement Based", value: "Requirement Based" },
    // { label: "End to End", value: "End to End" },
  ];
  const DatabaseOptions = [
    { label: "All", value: "All" },
    { label: "Self Database", value: "Self Database" },
    { label: "Samarth’s Database", value: "Samarth’s Database" },
  ];
  const downloadfile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      // [`Auto Generate Password : ${showpassword ? displayPassword : copyPassword.value}`],
      [`Auto Generate Password : ${displayPassword}`],
      {
        type: "text/plain",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = getFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setTimeout(() => {
      showAlert();
    }, 5000);
  };
  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    return "authentication_" + dformat + ".txt";
  };
  const handleResume = (File) => {
    const validExt = ["jpeg", "png"];
    const fileExt = File.name.split(".")[1];
    return validExt.includes(fileExt);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    console.log("resumefile ", file);
    if (file.size / 1024 < 5120) {
      setResumeFile(file);
    } else {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb and make sure format is either jpeg or png.",
        showConfirmButton: false,
        timer: 2000,
      });
      ref.current.value = "";
      setResumeFile("");
    }
  };

  const sizeOptions = [
    {
      label: "Tiny Unit",
      value: "Tiny Unit",
    },
    {
      label: "Small Unit",
      value: "Small Unit",
    },
    {
      label: "Medium Unit",
      value: "Medium Unit",
    },
    {
      label: "Large Unit",
      value: "Large Unit",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const industryOptions1 = [
    { label: "Textile and Clothing", value: "Textile and Clothing" },
    { label: "Building and Construction", value: "Building and Construction" },
    { label: "Automobile and Transportation", value: "Automobile and Transportation" },
    { label: "Organized and Unorganized Retail", value: "Organized and Unorganized Retail" },
    { label: "Gems and Jewellery", value: "Gems and Jewellery" },
    { label: "IT & Computers", value: "IT & Computers" },
    { label: "Furniture and Furnishing", value: "Furniture and Furnishing" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Leather and Leather Goods", value: "Leather and Leather Goods" },
    { label: "Tourism and Hospitality", value: "Tourism and Hospitality" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Mining", value: "Mining" },
    { label: "Households", value: "Households" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
    { label: "Others", value: "Others" },
  ];

  const sectorOption = [
    {
      label: "Public",
      value: "Public",
    },
    {
      label: "Private",
      value: "Private",
    },
    {
      label: "PSU",
      value: "PSU",
    },
  ];

  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data?.Password });
    });
  };
  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchIndustryFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setIndustryOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, [autopass]);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
    securityQuestionFunc();
    fetchIndustryFunc();
  }, []);

  useEffect(() => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);

  const showAlert = () =>
    Swal.fire({
      //position: "top",
      icon: "success",
      title: "Registration Successful",
      //text: "Job Provider registered successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      navigate("/jobprovider-list-page");
    });

  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState({
    email: "",
    firstnm: "",
    lastnm: "",
    mobnum: "",
    role_id: "",
    org_name: "",
    org_address: "",
    org_state: "",
    org_city: "",
    org_pincode: "",
    org_cin_num: "",
    org_size: "",
    org_type: "",
    org_sector: "",
    org_logo: "",
    role_access: "",
    data_access: "",
    crm_optin: "",
  });

  const registrationChannelPartner = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: formDetails.email ? formDetails.email : "",
      firstnm: formDetails.firstnm ? formDetails.firstnm : "",
      lastnm: formDetails.lastnm ? formDetails.lastnm : "",
      mobnum: formDetails.mobnum ? formDetails.mobnum : "",
      password: autopass ? copyPassword.value : copyPassword2.value,
            role_id: formDetails.role_id ? formDetails.role_id : "",
      org_name: formDetails.org_name ? formDetails.org_name : "",
      org_address: formDetails.org_address ? formDetails.org_address : "",
      org_state: formDetails.org_state ? formDetails.org_state : "",
      org_city: formDetails.org_city ? formDetails.org_city : "",
      org_pincode: formDetails.org_pincode ? formDetails.org_pincode : "",
      org_cin_num: formDetails.org_cin_num ? formDetails.org_cin_num : "",
      org_size: formDetails.size ? formDetails.size : "",
      org_type: formDetails.org_type ? formDetails.org_type : "",
      org_sector: formDetails.org_sector ? formDetails.org_sector : "",
      org_logo: formDetails.org_logo ? formDetails.org_logo : "",
      confirm_password: autopass ? copyPassword.value : copyPassword1.value,
            role_access: formDetails.role_access ? formDetails.role_access : "",
      data_access: formDetails.data_access ? formDetails.data_access : "",
      crm_optin: formDetails.crm_optin ? formDetails.crm_optin : "",
    },
    validationSchema: Yup.object().shape({
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
      org_name: Yup.string().required("Please enter organization name"),
      org_pincode: Yup.string()
        .notRequired()
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(6, "Please enter 6 digit number"),
      org_cin_num: Yup.string()
        .nullable()
        .notRequired()
        .matches(
          /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
          "Please enter valid CIN number"
        ),
      role_access: Yup.string().required("Please select role type"),
      org_logo: Yup.mixed()
      .notRequired()
      //.required("Upload Application")
      .test(
        "FILE_FORMAT",
        "Invalid format",
        (value) =>
          !value ||
          (value &&
            [
              "image/png",
              "image/jpg",
              "image/jpeg",
              "application/pdf",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/msword",
            ].includes(value?.type))
      )
      .test(
        "FILE_SIZE",
        "Please upload file < 5mb",
        (value) => !value || (value && value?.size / 1024 < 5120)
      ),
    }),
    onSubmit: (values) => {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      delete values.confirm_password;
      setIsLoading(true);
      setDisplayPassword(values.password);
      let formData = new FormData();
      formData.append("role_id", "JP");
      formData.append("createdby", registerID);
      formData.append("wa_notify", check ? "true" : "false");
      formData.append("firstnm", values.firstnm);
      formData.append("lastnm", values.lastnm);
      formData.append("mobnum", values.mobnum);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("org_name", values.org_name);
      formData.append("org_address", values.org_address);
      formData.append("org_state", values.org_state);
      formData.append("org_city", values.org_city);
      formData.append("org_pincode", values.org_pincode);
      formData.append("org_cin_num", values.org_cin_num);
      formData.append("org_size", values.org_size);
      formData.append("org_type", values.org_type);
      formData.append("org_sector", values.org_sector);
      formData.append("role_access", values.role_access);
      formData.append("data_access", values.data_access);
      formData.append("crm_optin", values.crm_optin);
      formData.append("org_logo", resumefile);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL_REGISTER}/jobprovider/register`, formData)
        .then((res) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          if (res.status === 201) {
            setShow(true);
            setResumeFile("");
            setIsLoading(false);
          } else {
            Swal.fire({
              icon: "info",
              title: err.response.data["Developer Message"]
                ? err.response.data["Developer Message"]
                : "Something went wrong!",
              text: err.response.data["User Message"]
                ? err.response.data["User Message"]
                : "Something went wrong!",
              showConfirmButton: false,
              timer: 3000,
            });
            setFormDetails({
              email: "",
              firstnm: "",
              lastnm: "",
              mobnum: "",
              role_id: "",
              org_name: "",
              org_address: "",
              org_state: "",
              org_city: "",
              org_pincode: "",
              org_cin_num: "",
              org_size: "",
              org_type: "",
              org_sector: "",
              org_logo: "",
              role_access: "",
              data_access: "",
              crm_optin: "",
            });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          Swal.fire({
            icon: "error",
            title: err.response?.data["Developer Message"]
              ? err.response?.data["Developer Message"]
              : "Something went wrong!",
            text: err.response?.data["User Message"]
              ? err.response?.data["User Message"]
              : "Something went wrong!",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  });
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              Company Registration
            </SoftTypography>
            <Grid container minWidth="70%">
              <SoftBox mt={4} width="100%" height="auto">
                <Card minWidth="100%" sx={{ overflow: "visible" }}>
                  <SoftBox pl={4} pr={14} pt={2} pb={3}>
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                      Company Details
                    </SoftTypography>
                    <SoftBox component="form" role="form">
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Name of Organization
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup> *</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_name"
                                name="org_name"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormDetails({
                                    ...formDetails,
                                    org_name: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.org_name}
                              />
                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_name &&
                              registrationChannelPartner.errors.org_name ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_name}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Address
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_address"
                                name="org_address"
                                inputProps={{
                                  maxLength: "250",
                                }}
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormDetails({
                                    ...formDetails,
                                    org_address: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.org_address}
                              />
                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {registrationChannelPartner.values.org_address?.length}/
                                {addressChar}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_address &&
                              registrationChannelPartner.errors.org_address ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_address}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  State
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftSelect
                                options={state}
                                placeholder="Select State"
                                id="org_state"
                                name="org_state"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_state", e.label);
                                  setSelectedState(e.label);
                                  setFormDetails({
                                    ...formDetails,
                                    org_state: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationChannelPartner.values.org_state }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_state &&
                              registrationChannelPartner.errors.org_state ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_state}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  District
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftSelect
                                options={district}
                                placeholder=""
                                id="org_city"
                                name="org_city"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_city", e.label);
                                  setFormDetails({
                                    ...formDetails,
                                    org_city: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationChannelPartner.values.org_city }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_city &&
                              registrationChannelPartner.errors.org_city ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_city}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Pincode
                                </SoftTypography>
                                {/*
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                                */}
                              </SoftBox>
                              <SoftInput
                                type="tel"
                                placeholder=""
                                inputProps={{
                                  maxLength: "6",
                                }}
                                id="org_pincode"
                                name="org_pincode"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormDetails({
                                    ...formDetails,
                                    org_pincode: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.org_pincode}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_pincode &&
                              registrationChannelPartner.errors.org_pincode ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_pincode}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Sector Type
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <SoftSelect
                                options={industryOptions}
                                placeholder="Select sector type"
                                id="org_type"
                                name="org_type"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_type", e.label);
                                  setFormDetails({
                                    ...formDetails,
                                    org_type: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationChannelPartner.values.org_type }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_type &&
                              registrationChannelPartner.errors.org_type ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_type}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>

                          {/*
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row" gap="5px">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Size of Unit
                                </SoftTypography>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontSize="14px" fontWeight="small">
                                    (Revenue based)
                                  </SoftTypography>
                                </SoftBox>
                              </SoftBox>
                              <SoftSelect
                                options={sizeOptions}
                                placeholder="Select Size of unit"
                                id="org_size"
                                name="org_size"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_size", e.label);
                                  // setSelectedState(e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_size }}
                              />

                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {" "}
                              </SoftTypography>

                              {registrationChannelPartner.touched.org_size &&
                                registrationChannelPartner.errors.org_size ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_size}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          */}

                          {/*
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftTypography fontWeight="medium" fontSize="16px">
                                CIN Number
                              </SoftTypography>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="org_cin_num"
                                name="org_cin_num"
                                inputProps={{ maxLength: 21, minLength: 21 }}
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.org_cin_num}
                              />
                              {registrationChannelPartner.touched.org_cin_num &&
                                registrationChannelPartner.errors.org_cin_num ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_cin_num}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                         */}
                        </Grid>
                      </SoftBox>

                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          {/*
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Sector
                                </SoftTypography>
                              </SoftBox>
                              <SoftSelect
                                options={sectorOption}
                                placeholder="Select Sector"
                                id="org_sector"
                                name="org_sector"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_sector", e.label);
                                  // setSelectedState(e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_sector }}
                              />
                              {registrationChannelPartner.touched.org_sector &&
                                registrationChannelPartner.errors.org_sector ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_sector}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        */}

                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  {/* Company Logo */}
                                  Organization Logo
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox> */}
                              </SoftBox>
                              <input
                                className="form-control"
                                type="file"
                                placeholder=""
                                id="org_logo"
                                name="org_logo"
                                accept="image/png, image/jpeg"
                                // ref={ref}
                                // onChange={handleFileUpload}
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "org_logo",
                                    e.target.files[0]
                                  );
                                  setResumeFile(e.target.files[0])
                                  setFormDetails({
                                    ...formDetails,
                                    org_logo: e.target.files[0],
                                  });
                                
                                }}
                              />
                              <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                {
                                  "Please upload files < 5mb and make sure format is either jpeg or png."
                                }
                              </SoftTypography>
                              {/* <SoftSelect
                                options={industryOptions}
                                placeholder="Select industry type"
                                id="org_type"
                                name="org_type"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue("org_type", e.label);
                                }}
                                value={{ label: registrationChannelPartner.values.org_type }}
                              /> */}
                              {registrationChannelPartner.touched.org_logo &&
                              registrationChannelPartner.errors.org_logo ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.org_logo}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      {/* <SoftBox
                            // display="flex"
                            // flexDirection="row"
                            // justifyContent="space-between"
                            mb={1}
                        ><Grid container spacing={8}>
                                <Grid item xs={12} lg={6} container spacing={3} >
                                    <SoftBox width="100%" pl={2} >
                                        <SoftTypography fontWeight="medium" fontSize="16px">
                                            Website
                                        </SoftTypography>
                                        <SoftInput type="text" placeholder=""
                                            id="org_website"
                                            name="org_website"
                                            onChange={registrationChannelPartner.handleChange}
                                            value={registrationChannelPartner.values.org_website}
                                        />
                                        {registrationChannelPartner.touched.org_website && registrationChannelPartner.errors.org_website ?
                                            (<span style={{ color: "red", fontSize: "12px" }}>{registrationChannelPartner.errors.org_website}</span>) : null
                                        }
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox> */}
                    </SoftBox>
                  </SoftBox>
                  <SoftBox pl={4} pr={14} pt={0} pb={0}>
                    <SoftTypography fontWeight="medium" fontSize="20px" mb={4}>
                      Contact Details
                    </SoftTypography>
                    <SoftBox
                      component="form"
                      role="form"
                      onSubmit={registrationChannelPartner.handleSubmit}
                    >
                      <SoftBox mb={3}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Email
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="email"
                                placeholder=""
                                id="email"
                                name="email"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormDetails({
                                    ...formDetails,
                                    email: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.email}
                              />
                              {registrationChannelPartner.touched.email &&
                              registrationChannelPartner.errors.email ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.email}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Mobile Number
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="tel"
                                placeholder=""
                                inputProps={{
                                  maxLength: "10",
                                }}
                                id="mobnum"
                                name="mobnum"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormDetails({
                                    ...formDetails,
                                    mobnum: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.mobnum}
                              />
                              <SoftBox display="flex" mt={1}>
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  checked={check}
                                  onChange={() => setCheck(!check)}
                                  placeholder=""
                                />
                                <SoftTypography fontWeight="medium" fontSize="12px">
                                  &nbsp; You&apos;ll be receiving updates on{" "}
                                  <Icon
                                    icon="mdi:whatsapp"
                                    color="green"
                                    width="12"
                                    inline={true}
                                  />{" "}
                                  Whatsapp
                                </SoftTypography>
                              </SoftBox>
                              {registrationChannelPartner.touched.mobnum &&
                              registrationChannelPartner.errors.mobnum ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.mobnum}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  First Name
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="firstnm"
                                name="firstnm"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormDetails({
                                    ...formDetails,
                                    firstnm: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.firstnm}
                              />
                              {registrationChannelPartner.touched.firstnm &&
                              registrationChannelPartner.errors.firstnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.firstnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Last Name
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="lastnm"
                                name="lastnm"
                                onChange={(e) => {
                                  registrationChannelPartner.handleChange;
                                  setFormDetails({
                                    ...formDetails,
                                    lastnm: e.target.value,
                                  });
                                }}
                                value={registrationChannelPartner.values.lastnm}
                              />
                              {registrationChannelPartner.touched.lastnm &&
                              registrationChannelPartner.errors.lastnm ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.lastnm}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={3}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                            <SoftBox
                              // pl={2}
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              height="auto"
                              sx={{
                                border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  defaultChecked="true"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      Setautopass(true);
                                    } else {
                                      Setautopass(false);
                                    }
                                  }}
                                />
                              </SoftBox>

                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="14px">
                                  Auto Generate Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="13px"
                                  lineHeight="16px"
                                >
                                  Password will be automatically generated after the registration is
                                  done.
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                            <SoftBox
                              // pl={2}
                              display="flex"
                              flexDirection="row"
                              width="100%"
                              height="75px"
                              sx={{
                                border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                              }}
                            >
                              <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                <input
                                  name="password"
                                  type="radio"
                                  onChange={() => {
                                    registrationChannelPartner.setFieldValue("password", "");
                                    setDisplayPassword(" ")
                                    registrationChannelPartner.setFieldValue("confirm_password","");
                                    Setautopass(false);
                                  }}
                                />
                              </SoftBox>
                              <SoftBox display="flex" flexDirection="column" my="5px">
                                <SoftTypography fontWeight="medium" fontSize="14px">
                                  Custom Password
                                </SoftTypography>
                                <SoftTypography
                                  fontWeight="small"
                                  fontSize="13px"
                                  lineHeight="16px"
                                >
                                  You can create a custom password for the user
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={5}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Password
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="password"
                                placeholder=""
                                disabled={autopass}
                                id="password"
                                name="password"
                                onChange={(e) => {
                                  // registrationChannelPartner.handleChange;
                                  setCopyPassword2({ ...copyPassword, value: e.target.value });
                                }}      
                                value={registrationChannelPartner.values.password}
                              />
                              {registrationChannelPartner.touched.password &&
                              registrationChannelPartner.errors.password ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.password}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Confirm Password
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftInput
                                type="password"
                                placeholder=""
                                disabled={autopass}
                                id="confirm_password"
                                name="confirm_password"
                                onChange={(e) => {
                                //  registrationChannelPartner.handleChange;


                                  setCopyPassword1({ ...copyPassword, value: e.target.value });
                                }}                        
                                value={registrationChannelPartner.values.confirm_password}
                              />
                              {registrationChannelPartner.touched.confirm_password &&
                              registrationChannelPartner.errors.confirm_password ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.confirm_password}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                      {/* access based  */}
                      <SoftBox mb={5}>
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Select Role
                                </SoftTypography>
                                <SoftBox
                                  sx={{
                                    color: "red",
                                    position: "inherit",
                                    paddingLeft: "2px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              <SoftSelect
                                options={RoleOptions}
                                placeholder="Select role"
                                id="role_access"
                                name="role_access"
                                onChange={(e) => {
                                  setSelectedRole(e.label);
                                  registrationChannelPartner.setFieldValue("role_access", e.label);
                                  setFormDetails({
                                    ...formDetails,
                                    role_access: e ? e.label : "",
                                  });
                                }}
                                value={{ label: registrationChannelPartner.values.role_access }}
                              />
                              {registrationChannelPartner.touched.role_access &&
                              registrationChannelPartner.errors.role_access ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.role_access}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          {selectedRole === "End to End" && (
                            <Grid item xs={12} lg={6} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="row">
                                  <SoftTypography fontWeight="medium" fontSize="16px">
                                    Database
                                  </SoftTypography>
                                  <SoftBox
                                    sx={{
                                      color: "red",
                                      position: "inherit",
                                      paddingLeft: "2px",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {/* <sup>*</sup> */}
                                  </SoftBox>
                                </SoftBox>
                                <SoftSelect
                                  options={DatabaseOptions}
                                  placeholder="Select role"
                                  id="data_access"
                                  name="data_access"
                                  onChange={(e) => {
                                    registrationChannelPartner.setFieldValue(
                                      "data_access",
                                      e.label
                                    );
                                    setFormDetails({
                                      ...formDetails,
                                      data_access: e ? e.label : "",
                                    });
                                  }}
                                  value={{ label: registrationChannelPartner.values.data_access }}
                                />
                                {/* {registrationChannelPartner.touched.data_access &&
                                registrationChannelPartner.errors.data_access ? (
                                  <span style={{ color: "red", fontSize: "12px" }}>
                                    {registrationChannelPartner.errors.data_access}
                                  </span>
                                ) : null} */}
                              </SoftBox>
                            </Grid>
                          )}
                        </Grid>
                      </SoftBox>
                      {/* access based  */}

                      {/* CRM Servicess access */}
                      {selectedRole === "End to End" && (
                        <SoftBox mb={5}>
                          <Grid container spacing={8}>
                            <Grid item xs={12} lg={10} container spacing={3}>
                              <SoftBox width="100%" pl={2}>
                                <SoftBox display="flex" flexDirection="column" gap={2}>
                                  <SoftTypography
                                    fontWeight="medium"
                                    fontSize="15px"
                                    lineHeight="18px"
                                    sx={{ color: "#FF0000" }}
                                  >
                                    ATTENTION
                                  </SoftTypography>
                                  <SoftBox display="flex" flexDirection="column" gap={1}>
                                    <SoftBox display="flex">
                                      <input
                                        type="checkbox"
                                        name="crm_optin"
                                        id="crm_optin"
                                        checked={crmOptinYes}
                                        // onChange={() => setCrmOptinYes(!crmOptinYes)}
                                        value={crmOptinYes}
                                        onChange={() => setCrmOptinYes(!crmOptinYes)}
                                        placeholder=""
                                      />
                                      <SoftTypography fontWeight="small" fontSize="12px" pl={1}>
                                        {/* &nbsp; You&apos;ll be receiving updates on{" "}
                                      <Icon
                                        icon="mdi:whatsapp"
                                        color="green"
                                        width="12"
                                        inline={true}
                                      />{" "} */}
                                        <b>
                                          Ask the parent company (SAPIO Analytics) to manage the
                                          candidate verification, interview and placements.
                                        </b>{" "}
                                        By continuing this SAPIO will use their counselors for the
                                        verification and interviews and will update with the final
                                        candidates.
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display="flex">
                                      <input
                                        type="checkbox"
                                        name="crm_optin"
                                        id="crm_optin"
                                        // checked={crmOptinNo}
                                        // onChange={() => setCrmOptinNo(!crmOptinNo)}
                                        checked={crmOptinYes1}
                                        value={crmOptinYes1}
                                        onChange={() => setCrmOptinYes1(!crmOptinYes1)}
                                        placeholder=""
                                      />
                                      <SoftTypography fontWeight="small" fontSize="12px" pl={1}>
                                        {/* &nbsp; You&apos;ll be receiving updates on{" "} */}
                                        {/* <Icon
                                        icon="mdi:whatsapp"
                                        color="green"
                                        width="12"
                                        inline={true}
                                      />{" "}
                                      Whatsapp */}
                                        <b>
                                          No, the company can take care to manage the candidate
                                          verification, interview and placements.
                                        </b>{" "}
                                        By continuing, you will need to take care of the CRM
                                        processes yourself.
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      )}
                      {/* CRM Servicess access */}
                      {/* <SoftBox
                        // display="flex"
                        // flexDirection="row"
                        // justifyContent="space-between"
                        mb={5}
                      >
                        <Grid container spacing={8}>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Security Question
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox>
                              </SoftBox>
                              
                              <SoftSelect
                                placeholder="select questions"
                                options={questions}
                                id="security_ques"
                                name="security_ques"
                                onChange={(e) => {
                                  registrationChannelPartner.setFieldValue(
                                    "security_ques",
                                    e.label
                                  );
                                }}
                                value={{ label: registrationChannelPartner.values.security_ques }}
                              />
                              {registrationChannelPartner.touched.security_ques &&
                              registrationChannelPartner.errors.security_ques ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ques}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={6} container spacing={3}>
                            <SoftBox width="100%" pl={2}>
                              <SoftBox display="flex" flexDirection="row">
                                <SoftTypography fontWeight="medium" fontSize="16px">
                                  Answer
                                </SoftTypography>
                                {/* <SoftBox sx={{ color: "red", position: "inherit" ,paddingLeft:"2px",fontSize:"15px"}}>
                                  <sup>*</sup>
                                </SoftBox> *
                              </SoftBox>
                              <SoftInput
                                type="text"
                                placeholder=""
                                id="security_ans"
                                name="security_ans"
                                onChange={registrationChannelPartner.handleChange}
                                value={registrationChannelPartner.values.security_ans}
                              />
                              {registrationChannelPartner.touched.security_ans &&
                              registrationChannelPartner.errors.security_ans ? (
                                <span style={{ color: "red", fontSize: "12px" }}>
                                  {registrationChannelPartner.errors.security_ans}
                                </span>
                              ) : null}
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox> */}
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={registrationChannelPartner.handleSubmit}
                    //onClick={registrationChannelPartner.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Submit
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      navigate("/admin-register");
                      setFormDetails({
                        email: "",
                        firstnm: "",
                        lastnm: "",
                        mobnum: "",
                        role_id: "",
                        org_name: "",
                        org_address: "",
                        org_state: "",
                        org_city: "",
                        org_pincode: "",
                        org_cin_num: "",
                        org_size: "",
                        org_type: "",
                        org_sector: "",
                        org_logo: "",
                        role_access: "",
                        data_access: "",
                        crm_optin: "",
                      });
                    }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
      {/* Modal After Submit */}

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          showAlert();
        }}
        centered
      >
        <Modal.Header closeButton>Retrieve Password</Modal.Header>

        <Modal.Body>
          <SoftBox display="flex">
            {/* Password: {showpassword ? copyPassword.value : "**************"} */}
            Password: {showpassword ? displayPassword : "**************"}
            <SoftBox
              ml={25}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                Setshowpassword(true);
                copy(`${displayPassword}`);
                copy(`${copyPassword.value}`);
              }}
            >
              <Icon icon="mdi:content-copy" color="#3b5092" width="20" />
            </SoftBox>
          </SoftBox>
        </Modal.Body>
        <Modal.Footer>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#3B5092",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => downloadfile()}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Download
            </SoftTypography>
          </SoftBox>
          <SoftBox
            pl={2}
            sx={{
              height: "45px",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
            width={{ lg: "225px", sm: "100%", xs: "50%" }}
            onClick={() => {
              setShow(false);
              showAlert();
            }}
          >
            <SoftTypography
              display="flex"
              justifyContent="center"
              sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}
            >
              Cancel
            </SoftTypography>
          </SoftBox>
        </Modal.Footer>
      </Modal>
      {/* Modal After Submit */}
    </>
  );
};

export default Jobproviderreg;
