import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";
import SoftTagInput from "components/SoftTagInput";
import { Table } from "antd";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Card } from "react-bootstrap";
import { data } from "pages/AntTableData/recommededJobSearchData";
//  modal imports
// import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import "pages/EditModals/editStyle.css";
import "./csStyle.css";

const RecommendSearch = () => {
  const [admindata, Setadmindata] = useState([]);
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterdetails, Setfilterdetails] = useState(false);
  const [tags, setTags] = useState(["Plumber", "Carpainter", "Welder", "Housekeeping"]);
  const updateTags = (newTags) => setTags(newTags);
  const handleCloseModal1 = () => {
    setShowModal1(false);
  };
  const handleCloseModal2 = () => {
    setShowModal2(false);
  };
  const handleCloseModal3 = () => {
    setShowModal3(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
  };

  const reporting = [
    {
      label: "Super Admin 1",
      value: "Super Admin 1",
    },
    {
      label: "Super Admin 2",
      value: "Super Admin 2",
    },
    {
      label: "Super Admin 3",
      value: "Super Admin 3",
    },
    {
      label: "Super Admin 4",
      value: "Super Admin 4",
    },
    {
      label: "Super Admin 5",
      value: "Super Admin 5",
    },
  ];
  const navigate = useNavigate();
  const columns = [
    {
      title: "Candidate Name",
      width: 200,
      dataIndex: "candidate_name",
      key: "candidate_name",
      fixed: "left",
      //   sorter: true,
      sorter: { compare: (a, b) => a.candidate_name - b.candidate_name },
    },
    {
      title: "Phone",
      //   width: 100,
      dataIndex: "phone",
      key: "phone",
      //   fixed: 'left',
      //   sorter: true,
    },
    { title: "Qualification", dataIndex: "qualification", key: "1" },
    // { title: 'Modified On', dataIndex: 'modified_on', key: '2' },
    { title: "Role", dataIndex: "role", key: "3" },
    { title: "Location", dataIndex: "location", key: "4" },
    { title: "Last Active", dataIndex: "last_active", key: "5" },
    {
      title: "Score",
      dataIndex: "score",
      sorter: { compare: (a, b) => a.score - b.score },
      key: "6",
      render: (text, record) => (
        <span>
          <SoftBadge
            variant="contained"
            color={text >= 80 ? "success" : text < 80 && text >= 45 ? "warning" : "error"}
            size="xs"
            badgeContent={
              text >= 80
                ? "Excellent Match"
                : text < 80 && text >= 45
                ? "Medium Match"
                : "Poor Match"
            }
            container
          />
        </span>
      ),
      // render:(text,record)=>{

      // // <Button>
      // //     {
      // //         text>=80 ? <div><p>Excellent</p></div> : (text<80 && text>=45) ? <div><p>Medium</p></div> :<div><p>Low</p></div>
      // //     }
      // // </Button>
      // // <a
      // //   className={
      // //     text >= 80
      // //       ? "btn btn-green"
      // //       : text<80 && text>=45
      // //       ? "btn btn-orange"

      // //       : "btn btn-red"
      // //   }
      // // >
      // //     {
      // //         text>=80 ? "Excellent" : (text<80 && text>=45) ? "Medium" :"Low"
      // //     }
      // // </a>
      // }
    },
    // { title: 'Column 7', dataIndex: 'address', key: '7' },
    // { title: 'Column 8', dataIndex: 'address', key: '8' },

    {
      title: "Action",
      key: "action",
      // sorter: false,
      fixed: "right",
      width: 80,
      render: (admindata) => (
        <div className="edit_icons">
          <div className="dropdown">
            <Button
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon icon="ion:ellipsis-horizontal" color="black" />
            </Button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a
                  className="dropdown-item"
                  href="#EditUser"
                  onClick={() => {
                    console.log("Shortlist modal");
                    setShowModal1(true);
                  }}
                >
                  Shorlist
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#Deleteuser"
                  onClick={() => {
                    console.log("View modal");
                    navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                  }}
                >
                  View
                </a>
              </li>
              {/* <li>
                      <a className="dropdown-item" href="#Deleteuser"
                       // onClick={() => { deActiveFun(admindata); }}
                      >
                        DeActive
                      </a>
                    </li> */}
            </ul>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftBox display="flex" flexDirection="row" justifyContent="space-between" width="92%">
              <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                Recommended candidates based on Search
              </SoftTypography>
              <SoftBox display="flex" flexDirection="row" gap="10px">
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#FFF",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="40px"
                >
                  <SoftBox>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="21"
                      viewBox="0 0 23 21"
                      fill="none"
                    >
                      <path
                        d="M20.2913 2.61183C19.7805 2.10083 19.1741 1.69547 18.5066 1.41891C17.8392 1.14235 17.1238 1 16.4013 1C15.6788 1 14.9634 1.14235 14.2959 1.41891C13.6285 1.69547 13.022 2.10083 12.5113 2.61183L11.4513 3.67183L10.3913 2.61183C9.3596 1.58013 7.96032 1.00053 6.50129 1.00053C5.04226 1.00053 3.64298 1.58013 2.61129 2.61183C1.5796 3.64352 1 5.04279 1 6.50183C1 7.96086 1.5796 9.36013 2.61129 10.3918L3.67129 11.4518L11.4513 19.2318L19.2313 11.4518L20.2913 10.3918C20.8023 9.88107 21.2076 9.27464 21.4842 8.60718C21.7608 7.93972 21.9031 7.22431 21.9031 6.50183C21.9031 5.77934 21.7608 5.06393 21.4842 4.39647C21.2076 3.72901 20.8023 3.12258 20.2913 2.61183Z"
                        stroke="#C4C4C4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  pl={2}
                  sx={{
                    height: "40px",
                    backgroundColor: "#3B5092",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  p="10px 22px"
                  width={{ lg: "97px", sm: "100%", xs: "50%" }}
                  onClick={() => setShowModal1(true)}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "medium",
                      lineHeight: "20px",
                    }}
                  >
                    Shortlist
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#FFF",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="40px"
                >
                  <SoftBox>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <rect width="38" height="38" rx="2" fill="white" />
                      <path
                        d="M28 22V26C28 26.5304 27.7893 27.0391 27.4142 27.4142C27.0391 27.7893 26.5304 28 26 28H12C11.4696 28 10.9609 27.7893 10.5858 27.4142C10.2107 27.0391 10 26.5304 10 26V22"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 17L19 22L24 17"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 22V10"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  sx={{
                    height: "40px",
                    backgroundColor: "#FFF",
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                  width="40px"
                >
                  <SoftBox onClick={() => setShowFilter(!showFilter)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="39"
                      height="37"
                      viewBox="0 0 39 37"
                      fill="none"
                    >
                      <rect width="38.8889" height="37" rx="2" fill="white" />
                      <path
                        d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                        stroke="#A4A4A4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox mt={4} width="100%" height="auto" display="flex">
              <SoftBox width={showFilter ? "70%" : "92%"}>
                {/* <Table {...tableProps} rowSelection={rowSelection} columns={colName} dataSource={data} /> */}
                <Table
                  {...tableProps}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={data}
                  scroll={{ x: 1300 }}
                />
              </SoftBox>

              <SoftBox minWidth="0%" ml={2} display={!showFilter && "none"}>
                <Grid container>
                  <SoftBox mt={2} width="100%" height="auto">
                    <Card>
                      <SoftBox display="flex" flexDirection="column" gap="10px">
                        <SoftBox display="flex" flexDirection="column" gap="20px" p={3}>
                          <SoftBox>
                            <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                              KEYWORDS
                            </SoftTypography>
                            <SoftTagInput
                              placeholder="Search...."
                              width="128px"
                              height="32px"
                              sx={{
                                borderRadius: "100px",
                                marginTop: "10px",
                                border: "1px solid #3B5092",
                                backgroundColor: "#F5F5F5",
                              }}
                              tags={tags}
                              onChange={updateTags}
                            />

                            {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                          </SoftBox>
                          <SoftBox>
                            <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                              LOCATION
                            </SoftTypography>
                            <SoftTagInput
                              placeholder="Search...."
                              width="128px"
                              height="32px"
                              sx={{
                                borderRadius: "100px",
                                marginTop: "10px",
                                border: "1px solid #3B5092",
                                backgroundColor: "#F5F5F5",
                              }}
                              tags={tags}
                              onChange={updateTags}
                            />
                          </SoftBox>
                          <SoftBox>
                            <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                              EXPERIENCE
                            </SoftTypography>
                            <SoftTagInput
                              placeholder="Search...."
                              width="128px"
                              height="32px"
                              sx={{
                                borderRadius: "100px",
                                marginTop: "10px",
                                border: "1px solid #3B5092",
                                backgroundColor: "#F5F5F5",
                              }}
                              tags={tags}
                              onChange={updateTags}
                            />
                          </SoftBox>
                          <SoftBox>
                            <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                              SALARY
                            </SoftTypography>
                            <SoftTagInput
                              placeholder="Search...."
                              width="128px"
                              height="32px"
                              sx={{
                                borderRadius: "100px",
                                marginTop: "10px",
                                border: "1px solid #3B5092",
                                backgroundColor: "#F5F5F5",
                              }}
                              tags={tags}
                              onChange={updateTags}
                            />
                          </SoftBox>
                          {!filterdetails && (
                            <>
                              <SoftBox>
                                <SoftTypography
                                  fontSize="15px"
                                  fontWeight="small"
                                  lineHeight="19px"
                                >
                                  Functional Details
                                </SoftTypography>
                                <SoftTagInput
                                  placeholder="Search...."
                                  width="128px"
                                  height="32px"
                                  sx={{
                                    borderRadius: "100px",
                                    marginTop: "10px",
                                    border: "1px solid #3B5092",
                                    backgroundColor: "#F5F5F5",
                                  }}
                                  tags={tags}
                                  onChange={updateTags}
                                />

                                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                              </SoftBox>
                              <SoftBox>
                                <SoftTypography
                                  fontSize="15px"
                                  fontWeight="small"
                                  lineHeight="19px"
                                >
                                  Industry
                                </SoftTypography>
                                <SoftTagInput
                                  placeholder="Search...."
                                  width="128px"
                                  height="32px"
                                  sx={{
                                    borderRadius: "100px",
                                    marginTop: "10px",
                                    border: "1px solid #3B5092",
                                    backgroundColor: "#F5F5F5",
                                  }}
                                  tags={tags}
                                  onChange={updateTags}
                                />
                              </SoftBox>
                              <SoftBox>
                                <SoftTypography
                                  fontSize="15px"
                                  fontWeight="small"
                                  lineHeight="19px"
                                >
                                  Educaation
                                </SoftTypography>
                                <SoftTagInput
                                  placeholder="Search...."
                                  width="128px"
                                  height="32px"
                                  sx={{
                                    borderRadius: "100px",
                                    marginTop: "10px",
                                    border: "1px solid #3B5092",
                                    backgroundColor: "#F5F5F5",
                                  }}
                                  tags={tags}
                                  onChange={updateTags}
                                />
                              </SoftBox>
                            </>
                          )}
                        </SoftBox>
                        <SoftBox display="flex" flexDirection="row" justifyContent="center" p={3}>
                          {/* <SoftBox pl={2} mx={2} sx={{ height: "40px", backgroundColor: "#008717", borderRadius: "100px", cursor: "pointer" }}
                                                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                                        // p="10px 25px"
                                                        display="flex" justifyContent="center" alignItems="center"
                                                        width={{ lg: "144px", sm: "100%", xs: "50%" }}
                                                        onClick={() => setShowFilter(false)}
                                                    >
                                                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "16px", fontWeight: "small", lineHeight: "20px" }}>
                                                            Save Searches
                                                        </SoftTypography>
                                                    </SoftBox > */}
                          <SoftBox
                            sx={{
                              height: "40px",
                              backgroundColor: "#3B5092",
                              borderRadius: "100px",
                              cursor: "pointer",
                            }}
                            // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                            // p="10px 25px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={{ lg: "150px", sm: "100%", xs: "50%" }}
                            onClick={() => setShowFilter(false)}
                          >
                            <SoftTypography
                              display="flex"
                              justifyContent="center"
                              sx={{
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: "small",
                                lineHeight: "20px",
                              }}
                            >
                              Apply Filter
                            </SoftTypography>
                          </SoftBox>
                          {/* <SoftBox pl={2} sx={{ height: "40px", backgroundColor: "#fff", borderRadius: "100px", cursor: "pointer" }}
                                                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                                        p="10px 25px"
                                                        width={{ lg: "144px", sm: "100%", xs: "50%" }}
                                                        onClick={() => { filterdetails ? Setfilterdetails(false) : Setfilterdetails(true) }}
                                                    >
                                                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "16px", fontWeight: "small", lineHeight: "20px" }}>
                                                            {filterdetails ? "Show Less" : "Show More"}
                                                        </SoftTypography>
                                                    </SoftBox > */}
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Grid>
              </SoftBox>
            </SoftBox>
            {/* {
                            showFilter && (
                                <Grid container>
                                    <SoftBox>
                                        <Grid container>
                                            <SoftBox mt={2} width="100%" height="auto">
                                                <Card>
                                                    <SoftBox display="flex" flexDirection="column" gap="10px" p={3}>
                                                        <SoftBox display="flex" flexDirection="column" gap="20px" p={3}>
                                                            <SoftBox>
                                                                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">KEYWORDS</SoftTypography>
                                                                <SoftTagInput
                                                                    placeholder="Search...." width="128px" height="32px" sx={{ borderRadius: "100px", marginTop: "10px", border: "1px solid #3B5092", backgroundColor: "#F5F5F5" }}
                                                                    tags={tags}
                                                                    onChange={updateTags}
                                                                />

                                                                {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> *
                                                            </SoftBox>
                                                            <SoftBox>
                                                                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">LOCATION</SoftTypography>
                                                                <SoftTagInput
                                                                    placeholder="Search...." width="128px" height="32px" sx={{ borderRadius: "100px", marginTop: "10px", border: "1px solid #3B5092", backgroundColor: "#F5F5F5" }}
                                                                    tags={tags}
                                                                    onChange={updateTags}
                                                                />
                                                            </SoftBox>
                                                            <SoftBox>
                                                                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">EXPERIENCE</SoftTypography>
                                                                <SoftTagInput
                                                                    placeholder="Search...." width="128px" height="32px" sx={{ borderRadius: "100px", marginTop: "10px", border: "1px solid #3B5092", backgroundColor: "#F5F5F5" }}
                                                                    tags={tags}
                                                                    onChange={updateTags}
                                                                />
                                                            </SoftBox>
                                                            <SoftBox>
                                                                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">SALARY</SoftTypography>
                                                                <SoftTagInput
                                                                    placeholder="Search...." width="128px" height="32px" sx={{ borderRadius: "100px", marginTop: "10px", border: "1px solid #3B5092", backgroundColor: "#F5F5F5" }}
                                                                    tags={tags}
                                                                    onChange={updateTags}
                                                                />
                                                            </SoftBox>
                                                            {filterdetails &&
                                                                <><SoftBox>
                                                                    <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">Functional Details</SoftTypography>
                                                                    <SoftTagInput
                                                                        placeholder="Search...." width="128px" height="32px" sx={{ borderRadius: "100px", marginTop: "10px", border: "1px solid #3B5092", backgroundColor: "#F5F5F5" }}
                                                                        tags={tags}
                                                                        onChange={updateTags} />

                                                                    {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> *
                                                                </SoftBox><SoftBox>
                                                                        <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">Industry</SoftTypography>
                                                                        <SoftTagInput
                                                                            placeholder="Search...." width="128px" height="32px" sx={{ borderRadius: "100px", marginTop: "10px", border: "1px solid #3B5092", backgroundColor: "#F5F5F5" }}
                                                                            tags={tags}
                                                                            onChange={updateTags} />
                                                                    </SoftBox><SoftBox>
                                                                        <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">Educaation</SoftTypography>
                                                                        <SoftTagInput
                                                                            placeholder="Search...." width="128px" height="32px" sx={{ borderRadius: "100px", marginTop: "10px", border: "1px solid #3B5092", backgroundColor: "#F5F5F5" }}
                                                                            tags={tags}
                                                                            onChange={updateTags} />
                                                                    </SoftBox></>
                                                            }
                                                        </SoftBox>
                                                        <SoftBox display="flex" flexDirection="row" justifyContent="space-between">
                                                            <SoftBox pl={2} mx={2} sx={{ height: "40px", backgroundColor: "#008717", borderRadius: "100px", cursor: "pointer" }}
                                                                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                                                // p="10px 25px"
                                                                display="flex" justifyContent="center" alignItems="center"
                                                                width={{ lg: "144px", sm: "100%", xs: "50%" }}
                                                                onClick={() => setShowFilter(false)}
                                                            >
                                                                <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "16px", fontWeight: "small", lineHeight: "20px" }}>
                                                                    Save Searches
                                                                </SoftTypography>
                                                            </SoftBox >
                                                            <SoftBox pl={2} sx={{ height: "40px", backgroundColor: "#3B5092", borderRadius: "100px", cursor: "pointer" }}
                                                                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                                                p="10px 25px"
                                                                width={{ lg: "144px", sm: "100%", xs: "50%" }}
                                                            // onClick={()=>setShow(true)}
                                                            >
                                                                <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "16px", fontWeight: "small", lineHeight: "20px" }}>
                                                                    Apply
                                                                </SoftTypography>
                                                            </SoftBox >
                                                            <SoftBox pl={2} sx={{ height: "40px", backgroundColor: "#fff", borderRadius: "100px", cursor: "pointer" }}
                                                                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                                                p="10px 25px"
                                                                width={{ lg: "144px", sm: "100%", xs: "50%" }}
                                                                onClick={() => { filterdetails ? Setfilterdetails(false) : Setfilterdetails(true) }}
                                                            >
                                                                <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "16px", fontWeight: "small", lineHeight: "20px" }}>
                                                                    {filterdetails ? "Show Less" : "Show More"}
                                                                </SoftTypography>
                                                            </SoftBox >
                                                        </SoftBox>
                                                    </SoftBox>
                                                </Card>
                                            </SoftBox>
                                        </Grid>
                                    </SoftBox>
                                </Grid>
                            )
                        } */}
            {/* <Grid container>
                        <SoftBox>
                            
                            <Grid container>
                                <SoftBox mt={4} width="100%" height="auto">
                                    <Card>
                                        <SoftBox alignItems="flex-start" position="relative" borderRadius="10px" width="1100px">
                                            {/* <SoftBox width="1074px" gap="20px" height="153px" p="20px 63px 10px" display="flex" flexDirection="column" >                                                    
                                            </SoftBox> *
                                            <Table {...tableProps} rowSelection={rowSelection} columns={columns} dataSource={data} 
                                                // scroll={{ x: 1300 }}
                                                />
                                        </SoftBox>
                                    </Card>
                                </SoftBox>
                            </Grid>
                            {/* <Grid container>
                                <SoftBox mt={4} width="100%" height="auto">
                                    <Card>
                                        <SoftBox>
                                            {/* <SoftBox width="1074px" gap="20px" height="153px" p="20px 63px 10px" display="flex" flexDirection="column" >                                                    
                                            </SoftBox> 
                                            <Table dataSource={data}/>
                                        </SoftBox>
                                    </Card>
                                </SoftBox>
                            </Grid> *
                        </SoftBox>
                        </Grid> */}

            {/* Modat 1 */}
            <Modal show={showModal1} onHide={handleCloseModal1} centered>
              <Modal.Header closeButton>
                <Modal.Title>Select from below options</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <SoftBox display="flex" flexDirection="row" gap="20px">
                  <SoftBox
                    // pl={2}
                    margin="auto"
                    sx={{
                      height: "35px",
                      backgroundColor: "#3B5092",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width="126px"
                    p="10px 20px"
                    // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      // navigate('/search/newsearch/recommend-search/allshortlist');
                      //activate modal2
                      setShowModal2(true);
                      setShowModal1(false);
                    }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "medium",
                        lineHeight: "15px",
                      }}
                    >
                      Existing Bucket
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    // pl={2}
                    margin="auto"
                    sx={{
                      height: "35px",
                      backgroundColor: "#3B5092",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width="126px"
                    p="10px 20px"
                    // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      // navigate('/search/newsearch/recommend-search/allshortlist');
                      //activate modal3
                      setShowModal3(true);
                      setShowModal1(false);
                    }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "medium",
                        lineHeight: "15px",
                      }}
                    >
                      Create Bucket
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Modal.Body>
              {/* <Modal.Footer  > */}
              {/* <SoftBox 
                                // pl={2} 
                                margin="auto"
                                sx={{ height: "35px", backgroundColor: "#3B5092", borderRadius: "10px", cursor: "pointer" }} 
                                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                width="126px" p="10px 20px"
                                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                onClick={()=>{
                                    navigate('/search/newsearch/recommend-search/allshortlist');
                                }}
                            >
                                <SoftTypography display="flex" justifyContent="center" 
                                    sx={{ color: "#fff", fontSize: "12px", fontWeight: "medium", lineHeight: "15px" }}
                                >
                                Create Bucket
                                </SoftTypography>
                            </SoftBox > */}
              {/* <Button variant="secondary" onClick={handleClose}>
                                Create
                            </Button> */}
              {/* <Button variant="primary" onClick={handleClose}>
                                Save Changes
                            </Button> */}
              {/* </Modal.Footer> */}
            </Modal>
            {/* Modat 1 */}

            {/* Shorlist Modal */}
            <Modal
              show={showModal3}
              onHide={handleCloseModal3}
              size="lg"
              centered
              style={{ marginTop: "52px" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Create a bucket</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  gap="20px"
                  width="750px"
                  marginTop="20px"
                  marginRight="10px"
                >
                  {/* <SoftBox>
                                        <SoftInput type="text" placeholder="Company Name"/>
                                    </SoftBox>
                                    <SoftBox>
                                        <SoftInput type="text" placeholder="Job Name"/>
                                    </SoftBox>
                                    <SoftBox display="flex" flexDirection="row" gap="20px">
                                        <SoftSelect options={reporting} placeholder="Company Name"/>
                                        <SoftSelect options={reporting} placeholder="Company Name"/>
                                    </SoftBox> */}

                  <SoftBox mb={3}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Organization Name
                          </SoftTypography>
                          <SoftInput type="text" placeholder="" />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Job Title
                          </SoftTypography>
                          <Card>
                            <SoftSelect options={reporting} />
                          </Card>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mb={3}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Job Type
                          </SoftTypography>
                          <Card>
                            <SoftSelect options={reporting} />
                          </Card>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Date of Posting
                          </SoftTypography>
                          <SoftInput type="text" placeholder="" />
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mb={3}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            No. of Professional
                          </SoftTypography>
                          <SoftInput type="text" placeholder="" />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Role/Designation
                          </SoftTypography>
                          <Card>
                            <SoftSelect options={reporting} />
                          </Card>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mb={3}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Valid Till
                          </SoftTypography>
                          <SoftInput type="text" placeholder="" />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Expected Salary
                          </SoftTypography>
                          <SoftBox display="flex" flexDirection="row" gap="20px">
                            <Card>
                              <SoftSelect options={reporting} />
                            </Card>
                            <Card>
                              <SoftSelect options={reporting} />
                            </Card>
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mb={3}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            State
                          </SoftTypography>
                          <Card>
                            <SoftSelect options={reporting} />
                          </Card>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            District
                          </SoftTypography>
                          <Card>
                            <SoftSelect options={reporting} />
                          </Card>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mb={3}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Industry
                          </SoftTypography>
                          <Card>
                            <SoftSelect options={reporting} />
                          </Card>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Qualification
                          </SoftTypography>
                          <Card>
                            <SoftSelect options={reporting} />
                          </Card>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mb={3}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={6} container spacing={3}>
                        <SoftBox width="100%" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Work from home availability
                          </SoftTypography>
                          <Card>
                            <SoftSelect options={reporting} />
                          </Card>
                        </SoftBox>
                      </Grid>
                      {/* <Grid item xs={12} lg={6} container spacing={3} >
                                                <SoftBox width="100%" pl={2} >
                                                    <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                                                        District
                                                    </SoftTypography>
                                                    <Card><SoftSelect options={reporting} /></Card>
                                                    
                                                </SoftBox>
                                            </Grid> */}
                    </Grid>
                  </SoftBox>
                  <SoftBox mb={0}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} lg={12} container spacing={3}>
                        <SoftBox width="100%" height="150px" pl={2}>
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Job Description
                          </SoftTypography>
                          {/* <Card><SoftSelect options={reporting} /></Card> */}
                          <SoftInput type="text" rows={5} />
                          {/* <SoftBox><textarea  rows={3} cols={40} /></SoftBox> */}
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                </SoftBox>
              </Modal.Body>
              <Modal.Footer>
                <SoftBox
                  // pl={2}
                  margin="auto"
                  sx={{
                    height: "35px",
                    backgroundColor: "#3B5092",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width="126px"
                  p="10px 20px"
                  // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    setShowModal3(false);
                    setShowModal1(false);
                    setShowModal2(true);
                    // navigate('/search/newsearch/recommend-search/allshortlist');
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "medium",
                      lineHeight: "15px",
                    }}
                  >
                    Create Bucket
                  </SoftTypography>
                </SoftBox>
                {/* <Button variant="secondary" onClick={handleClose}>
                                Create
                            </Button> */}
                {/* <Button variant="primary" onClick={handleClose}>
                                Save Changes
                            </Button> */}
              </Modal.Footer>
            </Modal>

            {/* Shortlist Modal */}
            {/* Select Bucket Modal */}
            <Modal show={showModal2} onHide={handleCloseModal2} centered>
              <Modal.Header closeButton>
                <Modal.Title>Select a Bucket</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <SoftBox display="flex" flexDirection="column" gap="20px">
                  <SoftBox>
                    <SoftInput type="text" placeholder="Select from following compaines" />
                  </SoftBox>
                  <SoftBox>
                    <SoftInput type="text" placeholder="Select from following job types" />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="row" gap="20px">
                    <SoftSelect options={reporting} placeholder="Location" />
                    <SoftSelect options={reporting} placeholder="Employment Type" />
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    justifyContent="center"
                    onClick={() => {
                      setShowModal3(true);
                      setShowModal2(false);
                    }}
                  >
                    <SoftTypography fontWeight="small" fontSize="14px" lineHeight="18px">
                      Could not find suitable bucket? Create Bucket
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Modal.Body>
              <Modal.Footer>
                <SoftBox
                  // pl={2}
                  margin="auto"
                  sx={{
                    height: "35px",
                    backgroundColor: "#3B5092",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                  width="126px"
                  p="10px 20px"
                  // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                  onClick={() => {
                    setShowModal1(false);
                    setShowModal2(false);
                    navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                  }}
                >
                  <SoftTypography
                    display="flex"
                    justifyContent="center"
                    sx={{
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "medium",
                      lineHeight: "15px",
                    }}
                  >
                    Select Bucket
                  </SoftTypography>
                </SoftBox>
                {/* <Button variant="secondary" onClick={handleClose}>
                                Create
                            </Button> */}
                {/* <Button variant="primary" onClick={handleClose}>
                                Save Changes
                            </Button> */}
              </Modal.Footer>
            </Modal>
            {/* Select Bucket Modal */}
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default RecommendSearch;
