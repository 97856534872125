import SoftBadge from "components/SoftBadge";
import { SelectColumnFilter } from "components/Filter/Filter";

// ProductsList page components
import ProductCell from "layouts/ecommerce/products/products-list/components/ProductCell";


// Badges
const cancelled = (
    <SoftBadge variant="contained" color="error" size="xs" badgeContent="Cancelled" container />
);
const completed = (
    <SoftBadge variant="contained" color="success" size="xs" badgeContent="Completed" container />
);

const pending = (
    <SoftBadge variant="contained" color="warning" size="xs" badgeContent="Pending" container />
);

const renderDash = ({ value }) => (value === null || value === undefined ? "-" : value);

// custom filter func to display all rows, if "All"(value = "") is selected
// does not support Multi Select feature
const customFilterFn = (rows, columnIds, filterValue) => {
    return filterValue === ""
        ? rows
        : rows.filter((row) => filterValue.includes(String(row.original[columnIds])));
};

/* eslint-disable react/prop-types */

const centeremployeetable = {
    columns: [
        {
            Header: "Name",
            accessor: "product",
            width: "15%",
            align: "left",
            disableFilters: true,
            Cell: ({ value: [name, data] }) => (
                <ProductCell
                    // image={data.image}
                    name={name}
                    checked={data.checked}
                // imageHeight="24px"
                // imageWidth="36px"
                />
            ),
        },
        {
            Header: "Center Name",
            accessor: "status",
            Cell: renderDash,
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
        {
            Header: "Location",
            accessor: "category",
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%"
        },
    
        {
            Header: "Created By",
            accessor: "company",
            Cell: renderDash,
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
        // { Header: "Email", accessor: "price", disableFilters: true, Cell: renderDash, width: "15%" },
        {
            Header: "Date Of Creation",
            accessor: "la",
            disableFilters: true,
            Cell: renderDash,
            width: "15%",
        },

        // {
        //     Header: "Email",
        //     accessor: "price",
        //     Cell: ({ value }) =>
        //         value === "Pending" ? pending : value === "Completed" ? completed : cancelled,
        //     Filter: SelectColumnFilter,
        //     disableFilters: true,
        //     width: "10%",
        // },
        {
            Header: "Phone Number",
            accessor: "pn",
            width: "15%",
            disableFilters: true,
            Cell: renderDash,
        },
        {
            Header: "Email",
            accessor: "price",
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
    ],

    rows: [
        {
            product: ["Kartik Joshi", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 0,
            status: "Dahisar Roajgar Kendra",
            paymentStatus: "Done",
            company: "Rajesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Varma", { checked: true }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Hardware",
            quantity: 0,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Rejected",
            company: "Jayesh Sharma",
            doc: "Saksham",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Kartik Joshi", { checked: false }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Hardware",
            quantity: 978,
            status: "Dahisar Roajgar Kendra",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Varma", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Workshop",
            quantity: 0,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Rejected",
            company: "Jayesh Sharma",
            doc: "Saksham",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Kartik Joshi", { checked: false }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 725,
            status: "Dahisar Roajgar Kendra",
            paymentStatus: "Done",
            company: "Rajesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Varma", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Hardware",
            quantity: 725,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Pending",
            company: "Jayesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Kartik Joshi", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 725,
            status: "Dahisar Roajgar Kendra",
            paymentStatus: "Done",
            company: "Rajesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Varma", { checked: true }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku: "",
            quantity: 51293,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Done",
            company: "Jayesh Sharma",
            doc: "Saksham",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Kartik Joshi", { checked: true }],
            category: "Dahisar",
            price: "jay@gmail.com",
            sku: "Dahisar Roajgar Kendra",
            quantity: 34,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Varma", { checked: false }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku:"Opp ,SBI Bank Navagam Main Road , Testnagar",
            quantity: 0,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Rejected",
            company: "Jayesh Sharma",
            doc: "Saksham",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Kartik Joshi", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Dahisar Roajgar Kendra",
            quantity: 32,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Varma", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com,999",
            sku:"Opp ,SBI Bank Navagam Main Road , Testnagar",
            quantity: 22,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Done",
            company: "Jayesh Sharma",
            doc: "Saksham",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Kartik Joshi", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Dahisar Roajgar Kendra",
            quantity: 725,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Varma", { checked: false }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku: "Opp ,SBI Bank Navagam Main Road , Testnagar",
            quantity: 0,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Rejected",
            company: "Jayesh Sharma",
            doc: "SBA",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Kartik Joshi", { checked: true }],
            category: "Dahisar",
            price: "jay@gmail.com",
            sku: "Dahisar Roajgar Kendra",
            quantity: 725,
            status: "Andheri Roajgar Kendra",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "Saksham",
            la:"28-2-2023",
            pn:"898776464"
        },
    ],
};

export default centeremployeetable;