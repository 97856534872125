import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Select from "react-select";
import PropTypes from "prop-types";
import "./SideDrawer.css";
import axios from "axios";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import SoftInput from "components/SoftInput";

const SideDrawer = (props) => {
  const {
    setOpen,
    statefilter,
    setStatefilter,
    districtfilter,
    setDistrictfilter,
    datefilter,
    setDatefilter,
    resetfunc,
    jffilter,
    setJffilter,
    table,
    educationfilter,
    setEducationfilter,
    rolefilter,
    setRolefilter,
    degreefilter,
    setDegreefilter,
    boardingfilter,
    setBoardingfilter
  } = props;
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }

  const [selectedFilterState, setSelectedFilterState] = useState("")
  const [filterState, setFilterState] = useState("")
  const [filterDistrict, setFilterDistrict] = useState("")
  const [jobrole, Setjobrole] = useState();
  const [education, Seteducation] = useState();
  const [degree, Setdergee] = useState();
  const [multiEducation, setMultiEducation] = useState([])
  const [multiRole, setMultiRole] = useState([])
  const [multiSpecialization, setMultiSpecialization] = useState([])


  useEffect(() => {
    if (degreefilter === "") {
      setMultiSpecialization([])
    }
  }, [degreefilter])

  useEffect(() => {
    if (educationfilter === "") {
      setMultiEducation([])
    }
  }, [educationfilter])

  useEffect(() => {
    if (rolefilter === "") {
      setMultiRole([])
    }
  }, [rolefilter])

  const option1 = [
    { value: 'Upcoming', label: 'Upcoming' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Inactive', label: 'Inactive' },
  ]

  const option2 = [
    { value: 'Onboarded', label: 'Onboarded' },
    { value: 'Not Onboarded', label: 'Not Onboarded' }
  ]

  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        //   setState(res.data);
        setFilterState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: statefilter,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        //   setDistrict(res.data);
        setFilterDistrict(res.data);
        // setSelectedFilterDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/specialization`)
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        Setjobrole(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchStateList();
    fetcheducation();
    fetchdegree();
    fetchjobrole()
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [statefilter]);

  return (
    <div className={drawerClasses}>
      <SoftBox
        sx={{
          height: "90%",
          backgroundColor: "#FFF",
          // borderBottom: "1px solid black",
          // borderRadius: "2px",
          cursor: "pointer",
          //   overFlowY: "scroll",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
        width="100%"
      >
        <SoftBox
          width="100%"
          sx={{
            height: "100%",
            backgroundColor: "#FFF",
            // borderBottom: "1px solid black",
            // borderRight: "1px solid black",
            // borderRadius: "2px",
            cursor: "pointer",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <SoftBox>Welcome filter</SoftBox> */}
          <SoftBox
            display="flex"
            justifyContent="space-between"
            // justifyConten="flex-end"
            alignItems="left"
            sx={{
              // height: "100%",
              backgroundColor: "#FFF",
              //   borderBottom: "1px solid black",
              // borderRight: "1px solid black",
              // borderRadius: "2px",
              padding: "0px 20px",
              cursor: "pointer",
            }}
          >
            <SoftTypography>Quick Filter</SoftTypography>
            <SoftBox onClick={() => props.setOpen(false)}>
              {" "}
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path
                  d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 
                  13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                ></path>
              </svg>{" "} */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style={{ height: "20px" }}
              >
                <path
                  d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 
                              13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                ></path>
              </svg>
            </SoftBox>
          </SoftBox>


          <SoftBox height="400px" padding="8px">
            {table !== 4 &&
              <>
                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    State
                  </SoftTypography>

                  <Select
                    placeholder="Search...."
                    isClearable
                    value={
                      statefilter !== ""
                        ? { label: statefilter, value: statefilter }
                        : null
                    }
                    // options={stateData}
                    options={filterState}
                    onChange={(e) =>
                      e ? setStatefilter(e.value) : setStatefilter("")
                    }
                    styles={stylesDD}
                  />
                  {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                </SoftBox>
                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    District
                  </SoftTypography>

                  <Select
                    isClearable
                    value={
                      districtfilter !== ""
                        ? { label: districtfilter, value: districtfilter }
                        : null
                    }
                    options={filterDistrict}
                    onChange={(e) =>
                      e ? setDistrictfilter(e.value) : setDistrictfilter("")
                    }
                    styles={stylesDD}
                  />
                </SoftBox>
              </>}

            {table === 0 ?
              <>
                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Date
                  </SoftTypography>

                  <SoftInput
                    type="date"
                    value={datefilter}
                    onChange={(e) => setDatefilter(e.target.value)}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Job fair status
                  </SoftTypography>

                  <Select
                    placeholder="Search...."
                    isClearable
                    value={
                      jffilter !== ""
                        ? { label: jffilter, value: jffilter }
                        : null
                    }
                    // options={stateData}
                    options={option1}
                    onChange={(e) =>
                      e ? setJffilter(e.value) : setJffilter("")
                    }
                    styles={stylesDD}
                  />
                  {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                </SoftBox>
              </> :
              null
            }


            {table === 4 ?
              <>
                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Onboarding Status
                  </SoftTypography>
                  <Select
                    placeholder="Search...."
                    isClearable
                    value={
                      boardingfilter !== ""
                        ? { label: boardingfilter, value: boardingfilter }
                        : null
                    }
                    options={option2}
                    onChange={(e) =>
                      e ? setBoardingfilter(e.value) : setBoardingfilter("")
                    }
                    styles={stylesDD}
                  />
                </SoftBox>
              </> :
              null
            }



            {table === 2 ?
              <>
                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Role
                  </SoftTypography>
                  <Select
                    options={jobrole}
                    value={multiRole}
                    placeholder="Search...."
                    isMulti
                    onChange={(e) => {
                      if (e) {
                        setMultiRole(e.map((item) => item));
                      } else {
                        setMultiRole([]);
                      }
                      setRolefilter(
                        e
                          .map((ele) => ele)
                          .map((item) => item.value)
                          .join()
                      );
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Education
                  </SoftTypography>
                  <Select
                    options={education}
                    // placeholder="Please enter Job Title"
                    value={multiEducation}
                    placeholder="Search...."
                    isMulti
                    onChange={(e) => {
                      if (e) {
                        setMultiEducation(e.map((item) => item));
                      } else {
                        setMultiEducation([]);
                      }
                      setEducationfilter(
                        e
                          .map((ele) => ele)
                          .map((item) => item.value)
                          .join()
                      );
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>

                <SoftBox margin="8px">
                  <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                    Specialization
                  </SoftTypography>
                  <Select
                    options={degree}
                    value={multiSpecialization}
                    placeholder="Search...."
                    isMulti
                    onChange={(e) => {
                      if (e) {
                        setMultiSpecialization(e.map((item) => item));
                      } else {
                        setMultiSpecialization([]);
                      }
                      setDegreefilter(
                        e
                          .map((ele) => ele)
                          .map((item) => item.value)
                          .join()
                      );
                    }}
                    styles={stylesDD}
                  />
                </SoftBox>
              </> :
              null
            }
          </SoftBox>

          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="center"
            gap="35px"
            px={3}
            py="24px 0px"
          >
            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#3B5092",
                borderRadius: "100px",
                cursor: "pointer",
              }}
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              // p="10px 25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={{ lg: "150px", sm: "100%", xs: "50%" }}
              onClick={() => {
                setOpen(false);
                resetfunc()
              }}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "small",
                  lineHeight: "20px",
                }}
              >
                Reset
              </SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#3B5092",
                borderRadius: "100px",
                cursor: "pointer",
              }}
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              // p="10px 25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={{ lg: "150px", sm: "100%", xs: "50%" }}
              onClick={() => { setOpen(false); }}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                sx={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "small",
                  lineHeight: "20px",
                }}
              >
                Apply Filter
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </div>
  );
};

SideDrawer.propTypes = {
  show: PropTypes.boolean,
  setOpen: PropTypes.boolean,
  statefilter: PropTypes.string,
  setStatefilter: PropTypes.string,
  districtfilter: PropTypes.string,
  setDistrictfilter: PropTypes.string,
  datefilter: PropTypes.string,
  setDatefilter: PropTypes.string,
  resetfunc: PropTypes.func,
  jffilter: PropTypes.string,
  setJffilter: PropTypes.string,
  table: PropTypes.table,
  educationfilter: PropTypes.string,
  setEducationfilter: PropTypes.string,
  rolefilter: PropTypes.string,
  setRolefilter: PropTypes.string,
  degreefilter: PropTypes.string,
  setDegreefilter: PropTypes.string,
  boardingfilter: PropTypes.string,
  setBoardingfilter: PropTypes.string,
};

export default SideDrawer;
