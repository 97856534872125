import SoftBadge from "components/SoftBadge";
import { SelectColumnFilter } from "components/Filter/Filter";
import { MdMoreVert } from 'react-icons/md';
// ProductsList page components
import ProductCell from "layouts/ecommerce/products/products-list/components/ProductCell";
import {useState,useRef} from 'react'
// Images
import car from "assets/images/product-icon/two_wheeler_icon.svg";
import health from "assets/images/product-icon/health_icon.svg";
import life from "assets/images/product-icon/term_icon.svg";
import travel from "assets/images/product-icon/travel_icon.svg";

// popover imports
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { Icon } from '@iconify/react';

// Badges
const cancelled = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="Cancelled" container />
);
const completed = (
  <SoftBadge variant="contained" color="success" size="xs" badgeContent="Completed" container />
);

const pending = (
  <SoftBadge variant="contained" color="warning" size="xs" badgeContent="Pending" container />
);

const renderDash = ({ value }) => (value === null || value === undefined ? "-" : value);

// custom filter func to display all rows, if "All"(value = "") is selected
// does not support Multi Select feature
const customFilterFn = (rows, columnIds, filterValue) => {
  return filterValue === ""
    ? rows
    : rows.filter((row) => filterValue.includes(String(row.original[columnIds])));
};

/* eslint-disable react/prop-types */

const ActionBtn = ()=>{
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  
    setOpen(false);
  };
  const handleListKeyDown = (event)=>{
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }
  return(
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MdMoreVert/>
      </Button>
      <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
         
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{overflow:"visible"}}
                  >
                    <MenuItem onClick={handleClose}>Edit Profile</MenuItem>
                    <MenuItem onClick={handleClose}>Reset Password</MenuItem>
                    <MenuItem onClick={handleClose}>Deactivate</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
      </Popper>
    </>
  )
}


// const colName = [
//   {title:"Organization",
//     dataIndex:"organization",
//     sorter: {
//       compare: (a, b) => a.organization - b.organization,
//       multiple: 1,
//     },
//   },
//   {title:"Location",
//   dataIndex:"location",
//       sorter: {
//       compare: (a, b) => a.location - b.location,
//       multiple: 2,
//     },
// },
// {title:"Name",
// dataIndex:"name",
//     sorter: {
//       compare: (a, b) => a.name - b.name,
//       multiple: 3,
//     },

// },
// {title:"Email",
// dataIndex:"email",
//     sorter: {
//       compare: (a, b) => a.email - b.email,
//       multiple: 4,
//     },

// },
// {title:"Phone Number",
// dataIndex:"phone_number",
//     sorter: {
//       compare: (a, b) => a.phone_number - b.phone_number,
//       multiple: 5,
//     },

// },
// {title:"Date Of Creation",
// dataIndex:"date_of_creation",
//     sorter: {
//       compare: (a, b) => a.date_of_creation - b.date_of_creation,
//       multiple: 6,
//     },

// },
// // {
// //   title: 'Action',
// //   key: 'operation',
// //   fixed: 'right',
// //   width: 50,
// //   render: () => <ActionBtn/>,
// // },
// {
//   title: 'Action',
//   key: 'action',
//   sorter: false,
//   width: '5%',
//   render: (admindata) => (
//     <div className="edit_icons">
//       <div className="dropdown">
//         <Button             
//           type="button"
//           id="dropdownMenuButton1"
//           data-bs-toggle="dropdown"
//           aria-expanded="false"
//         >
//           <Icon icon="ion:ellipsis-vertical" color="black" />
//         </Button>
//         <ul
//           className="dropdown-menu"
//           aria-labelledby="dropdownMenuButton1"
//         >
//           <li>
//             <a
//               className="dropdown-item"
//               href="#EditUser"
//               onClick={() =>{ Seteditadmin(true); Seteditdata(admindata)}}
//             >
//               Edit Profile
//             </a>
//           </li>
//           <li>
//             <a
//               className="dropdown-item"
//               href="#EditUser"
//               onClick={() =>{ Seteditadmin(true); Seteditdata(admindata)}}
//             >
//               Reset  Password
//             </a>
//           </li>
//           <li>
//             <a className="dropdown-item" href="#Deleteuser"
//               // onClick={() => {
//               //   if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
//               //     DeleteClientUser(e.id)
//               //   } else {
//               //     toast.error("Admin Cannot Delete")
//               //   }

//               // }}
//               >
//               Deactivate
//             </a>
//           </li>
//         </ul>
//       </div>
//     </div>
//   ),
// },
  
// ]

// const colName= [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//   },
//   {
//     title: 'Age',
//     dataIndex: 'age',
//   },
//   {
//     title: 'Address',
//     dataIndex: 'address',
//   },
// ];

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    organization: `Organization ${i}`,
    location: `Location ${i}`,
    name: `Edward King ${i}`,
    email: `user${i}@gmail.com`,
    center_name: `Andher Rojgar Center ${i+1}`,
    // {if(i%2==0){
    //   created_by: "SBA",
    // }else{
    //   created_by: "Super Admin"
    // }},
    created_by: "Super Admin",
    phone_number: `949588899${i+1}`,
    date_of_creation: `10/02/2022`,
    // address: `London, Park Lane no. ${i}`,
  });
}

const suparadminTableData = {
  columns: [
    {
      Header: "Organization",
      accessor: "product",
      width: "15%",
      align: "left",
      disableFilters: true,
      Cell: ({ value: [name, data] }) => (
        <ProductCell
         // image={data.image}
          name={name}
          checked={data.checked}
         // imageHeight="24px"
         // imageWidth="36px"
        />
      ),
    },
    { Header: "Location", 
    accessor: "category", 
    Filter: SelectColumnFilter, 
    disableFilters: true, 
    width: "10%" 
  },
    {
      Header: "Name",
      accessor: "company",
      Cell: renderDash,
      Filter: SelectColumnFilter,
      disableFilters: true,
      width: "10%",
    },
    { Header: "Email", accessor: "price", disableFilters: true, Cell: renderDash, width: "15%" },
    {
      Header: "Phone Number",
      accessor: "sku",
      disableFilters: true,
      Cell: renderDash,
      width: "15%",
    },
    {
      Header: "Date Of Creation",
      accessor: "doc",
      width: "15%",
      disableFilters: true,
      Cell: renderDash,
    },
    // {
    //   Header: "Job Status",
    //   accessor: "status",
    //   Cell: ({ value }) =>
    //     value === "Pending" ? pending : value === "Completed" ? completed : cancelled,
    //   Filter: SelectColumnFilter,
    //   disableFilters: true,
    //   width: "10%",
    // },
    // {
    //   Header: "Last Active",
    //   accessor: "paymentStatus",
    //   Filter: SelectColumnFilter,
    //   disableFilters: true,
    //   width: "30%",
    // },
  ],

  rows: [
    {
      checked: ["checked", {  checked: true }],
      product: ["SBA", {  checked: true }],
      category: "Dahisar",
      price: "raj@gmail.com",
      sku: 243598234,
      quantity: 0,
      status: "Completed",
      paymentStatus: "Done",
      company: "Rajesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", { checked: true }],
      category: "Andheri",
      price: "jay@gmail.com",
      sku: 877712,
      quantity: 0,
      status: "Cancelled",
      paymentStatus: "Rejected",
      company: "Jayesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["SBA", {  checked: false }],
      category: "Dahisar",
      price: "raj@gmail.com",
      sku: "0134729",
      quantity: 978,
      status: "Pending",
      paymentStatus: "Pending",
      company: "Rajesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: false }],
      category: "Andheri",
      price: "jay@gmail.com",
      sku: 113213,
      quantity: 0,
      status: "Cancelled",
      paymentStatus: "Rejected",
      company: "Jayesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: false }],
      category: "Dahisar",
      price: "raj@gmail.com",
      sku: 634729,
      quantity: 725,
      status: "Completed",
      paymentStatus: "Done",
      company: "Rajesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", { checked: false }],
      category: "Andheri",
      price: "jay@gmail.com",
      sku: 634729,
      quantity: 725,
      status: "Pending",
      paymentStatus: "Pending",
      company: "Jayesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", { checked: true }],
      category: "Dahisar",
      price: "raj@gmail.com",
      sku: 634729,
      quantity: 725,
      status: "Completed",
      paymentStatus: "Done",
      company: "Rajesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: true }],
      category: "Andheri",
      price: "raj@gmail.com",
      sku: "00121399",
      quantity: 51293,
      status: "Completed",
      paymentStatus: "Done",
      company: "Jayesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: true }],
      category: "Dahisar",
      price: "jay@gmail.com",
      sku: 434729,
      quantity: 34,
      status: "Pending",
      paymentStatus: "Pending",
      company: "Rajesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: false }],
      category: "Andheri",
      price: "raj@gmail.com",
      sku: 928341,
      quantity: 0,
      status: "Cancelled",
      paymentStatus: "Rejected",
      company: "Jayesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: true }],
      category: "Dahisar",
      price: "raj@gmail.com",
      sku: 9912834,
      quantity: 32,
      status: "Pending",
      paymentStatus: "Pending",
      company: "Rajesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: false }],
      category: "Andheri",
      price: "jay@gmail.com,999",
      sku: 561151,
      quantity: 22,
      status: "Completed",
      paymentStatus: "Done",
      company: "Jayesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: true }],
      category: "Dahisar",
      price: "raj@gmail.com",
      sku: 634729,
      quantity: 725,
      status: "Pending",
      paymentStatus: "Pending",
      company: "Rajesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", {  checked: false }],
      category: "Andheri",
      price: "raj@gmail.com",
      sku: "01827391",
      quantity: 0,
      status: "Cancelled",
      paymentStatus: "Rejected",
      company: "Jayesh Sharma",
      doc:"11-2-2022"
    },
    {
      product: ["Company1", { checked: true }],
      category: "Dahisar",
      price: "jay@gmail.com",
      sku: 63472,
      quantity: 725,
      status: "Pending",
      paymentStatus: "Pending",
      company: "Rajesh Sharma",
      doc:"11-2-2022"
    },
  ],
};

export { suparadminTableData,data};