import { Grid, Typography, Box, Switch, Skeleton } from "@mui/material";
import styled from "@emotion/styled";

export const SHeading = styled(Typography)({
  fontSize: "20px",
  fontWeight: 600,
  paddingLeft: "16px",
  color: "#000000",
  fontFamily: "Montserrat",
});

export const SBox1 = styled(Box)({
  width: "100%",
  background: "#FFFFFF",
  margin: "16px",
  marginTop: "0px",
  marginBottom: "0px",
  padding: "8px",
  borderRadius: "5px",
});

export const SBox2 = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
});

export const SVerticalBox = styled(Box)({
  height: "61px",
  width: "5px",
  background: "#3F4ED1",
  borderRadius: "10px",
});

export const SBox3 = styled(Box)({
  paddingLeft: "16px",
  paddingRight: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  background: "#FFFFFF",
});

export const SKPIHeading = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#6C6C6C",
  fontStyle: "normal",
  fontFamily: "Roboto",
});

export const SKPICount = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  fontStyle: "normal",
  fontFamily: "Roboto",
});

export const SKPISubHeading1 = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#848383",
  fontStyle: "normal",
  fontFamily: "Roboto",
});

export const SKPISubHeading2 = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#249557",
  fontStyle: "normal",
  fontFamily: "Roboto",
});

export const SBox4 = styled(Box)({
  margin: "4px",
  background: "#FFFFFF",
  borderRadius: "7px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
});

export const SGridBox = styled(Box)({
  width: "100%",
  margin: "16px",
  marginTop: "0px",
  marginBottom: "0px",
  padding: "8px",
  background: "#FFFFFF",
  minHeight: "500px",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)",
  borderRadius: "8px",
});

export const SGraphSubHeading = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#404040",
  fontStyle: "normal",
  // fontFamily: 'Inter',
  padding: "4px",
});

export const SBox5 = styled(Box)({
  padding: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const SUnderlineBox = styled(Box)({
  padding: "8px",
  paddingBottom: "0px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #BDC3C7",
});

export const SGHeading = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#000000",
  lineHeight: "1.625px",
  letterSpacing: "0.00938em",
  //   fontStyle: "normal",
  // fontFamily: 'Inter'
});

export const SGSubHeading = styled(Typography)({
  fontSize: "12px",
  fontWeight: 500,
  color: "#848383",
  fontStyle: "normal",
  // fontFamily: 'Inter'
});

export const SGTitles = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#404040",
  fontStyle: "normal",
  // fontFamily: 'Inter',
  padding: "8px",
});

export const SGToggles = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: "#404040",
  fontStyle: "normal",
  fontFamily: "Public Sans",
  padding: "8px",
});
