import React, { useState, useEffect } from "react";
import { Card, IconButton, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";
import jobprovidertable from "./jobseekartable";
import SoftSelect from "../../components/SoftSelect";
import SoftBadge from "components/SoftBadge";
import SoftInput from "components/SoftInput";
import { Link, useNavigate } from "react-router-dom";
import Mapdata from "pages/Mapdata/Mapdata";
// import { Table } from "ant-table-extensions";
import { Table, Dropdown } from "antd";
import axios from "axios";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import AdminEdit from "pages/EditModals/JobProvider/jobProviderEdit";
import PasswordResetJobProvider from "pages/EditModals/JobProvider/jobProviderPasswordReset";
import { Icon } from "@iconify/react";
// import { data } from "./jobprovidertable";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Form from "react-bootstrap/Form";
// import Index from "../FilterSlider/Index";
import Index from "pages/FilterSlider/index.js";
import "../style.css";
import FadeLoader from "react-spinners/FadeLoader";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ArticleIcon from "@mui/icons-material/Article";
import { Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import refreshBtn from "../../assets/images/refresh_btn.png";
import JobseekerEdit from "./JobseekerEdit";
import Alltabs from "pages/FilterSlider/Tab";
import { Alltabs2 } from "pages/FilterSlider/Tab";
import { TimelineTab } from "pages/FilterSlider/Tab";
import pdfView from "../../assets/images/logos/PDF_file_icon.png";
import Edit from "../../assets/images/Edit2.png";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import helpimg from "../../assets/images/helpimg.png";
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual';
import { useDispatch } from 'react-redux';


import { saveAs } from "file-saver";
// import uploadGif from "../../assets/images/animated-icon/upload-gif.gif";
// import uploadGif from "../../assets/images/animated-icon/file-upload-gif.gif";
import uploadGif from "../../assets/images/animated-icon/shortlist-gif.gif";

export const Jobseekar = () => {
  const ngrokLink = "https://6c52-110-226-182-108.ngrok-free.app";
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [registerID, setRegisterID] = useState("SA1");
  const [roleId, setRoleId] = useState("SA");
  const [admindata, Setadmindata] = useState([]);
  const [editadmin, Seteditadmin] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [editdata, Seteditdata] = useState({});
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [colHeadersFilterd, setColHeadersFilterd] = useState([]);
  const [showText, setShowText] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [actionModal, setactionModal] = useState(false);
  const [actionModal2, setactionModal2] = useState(false);
  const [instructionModal, setInstructionModal] = useState(false);
  const [CSVFile, setCSVFile] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [selectedDS, setSelectedDS] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tablerow, setTablerow] = useState([]);
  const [filtershow, setFiltershow] = useState(true);
  const [cplabel, setCPLabel] = useState("");
  const [multigender, setMultigender] = useState([]);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  var fileSaver = require("file-saver");
  const [KPIObj, setKPIObj] = useState({
    // job_seeekr_on_hold: "",
    // job_seeker_in_pipeline: "",
    // job_seekers_hired: "",
    // total_jobseekers: "",

    total_jobseekers: "",
    job_seekers_hired: "",
    job_seeker_in_pipeline: "",
    job_seeker_on_hold: "",
    job_seeker_shortlisted: "",
    job_seeker_uploaded: "",
    job_seeker_verified: "",
    job_seeker_connected: "",
  });
  const [multiCategory, setMultiCategory] = useState([]);
  const [categoryList, setCategoryList] = useState("");
  const [categoryLabel, setCategoryLabel] = useState("");

  /* Add seeker modal states */
  const [JSModal, setJSModal] = useState(false);

  /* Add seeker modal states */

  /* **** shortlismodals state **** */

  const [showShortlistOption, setShowShortlistOption] = useState(false);
  const [showshortlistModal1, setShowShortlistModal1] = useState(false);
  const [showShortlistModal2, setShowShortlistModal2] = useState(false);
  const [showShortlistModal3, setShowShortlistModal3] = useState(false);

  const [orgList, setOrgList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [jobId, setJobId] = useState("");
  const [orgId1, setOrgId1] = useState("");
  const [jobId1, setJobId1] = useState("");
  const [candidateIdsObj, setCandidateIdsObj] = useState({});
  const [shortlistObj, setShortlistObj] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [dataLength, setDataLength] = useState([]);

  const handleCloseShortlistModal1 = () => {
    setShowShortlistModal1(false);
    setShowShortlistOption(false);
  };
  const handleCloseShortlistModal2 = () => {
    setShowShortlistModal2(false);
    setShowShortlistOption(false);
    setRoleList([]);
    setOrgId("");
    setOrgId1("");
    // setShowShortlistModal1(true);
  };
  const handleCloseShortlistModal3 = () => {
    setShowShortlistModal3(false);
    setShowShortlistOption(false);
  };

  const TermSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: "#52af77",
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });

  const handleAgeChange = (event, newValue, activeThumb) => {
    // setAge(newValue === "" ? 26 : newValue);
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < 10) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 65 - 10);
        console.log("age slider:", [clamped, clamped + 10]);
        setAge([clamped, clamped + 10]);
      } else {
        const clamped = Math.max(newValue[1], 10);
        console.log("age slider:", [clamped - 10, clamped]);
        setAge([clamped - 10, clamped]);
      }
    } else {
      console.log("age slider:", newValue);
      setAge(newValue);
    }
  };

  const genderOption = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    // {
    //   label: "All",
    //   value: "All",
    // },
  ];

  const HandleTimeline = () => {
    console.log("IDDDD", candData.registration_id);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/all_timeline`,
        {
          params: {
            reg_id: candData?.registration_id,
          },
        }
      )
      .then((res) => {
        console.log("xyzzz", time);
        setTime(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOrgList = () => {
    const params = {
      // partner: "samarth",
      type: "dropdown",
      created_by: sessionStorage.getItem("register_id")
      ? sessionStorage.getItem("register_id")
      : "",
      // type: "detail",
    };
    // const headers = {
    //   "access-control-allow-origin": "*",
    //   "Content-type": "application/json; charset=UTF-8",
    // };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`
        // `${ngrokLink}/superadmin/candidatesearch/organisationdata?${paramString}`
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_List"]);
        setOrgList(res.data["Organisation_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchOgrRole = () => {
    const params = {
      orgid: orgId || orgId1,
      type: "dropdown",
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      // .get(`${ngrokLink}/superadmin/candidatesearch/jobrolelist?${paramString}`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata?${paramString}`
        // `${ngrokLink}/superadmin/candidatesearch/jobpostdata?${paramString}`
      )
      .then((res) => {
        // setDistrict(res.data);
        setRoleList(res.data["JobPost_List"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <a style={{ display: "flex" }} className="dropdown-item" href="#edit">
    //       <div>
    //         <Icon icon="mdi:lead-pencil" />
    //       </div>
    //       &nbsp; Edit
    //     </a>
    //   ),
    // },
    // {
    //   key: "2",
    //   label: (
    //     <a style={{ display: "flex" }} className="dropdown-item" href="#deactivate">
    //       <div>
    //         <Icon icon="fluent-mdl2:deactivate-orders" />
    //       </div>
    //       &nbsp; Deactivate
    //     </a>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <a style={{ display: "flex" }} className="dropdown-item" href="#activate">
    //       <div>
    //         <Icon icon="fluent-mdl2:deactivate-orders" />
    //       </div>
    //       &nbsp; Activate
    //     </a>
    //   ),
    // },
    {
      key: "4",
      label: (
        <a style={{ display: "flex" }} className="dropdown-item" href="#shortlist">
          <div>
            <Icon icon="gridicons:multiple-users" />
          </div>
          &nbsp; Shorlist
        </a>
      ),
    },
  ];

  const colGuidelines = [
    {
      title: "Column Name",
      dataIndex: "col_name",
      width: 200,
    },
    {
      title: "Sample Entry",
      dataIndex: "sample",
      width: 200,
    },
    {
      title: "Guide",
      // dataIndex: "guide",
      width: 300,
      render: (text) => (
        <>
          {text.guide === " - " ? (
            <p style={{ display: "flex", justifyContent: "center" }}>{text.guide}</p>
          ) : (
            <p style={{ display: "flex", justifyContent: "center" }}>{text.guide}</p>
          )}
        </>
      ),
    },
    {
      title: "Link",
      // dataIndex: "link",
      width: 500,
      render: (text) => (
        <>
          {text.link === " - " ? (
            <p style={{ display: "flex", justifyContent: "center" }}>{text.link}</p>
          ) : (
            <a href={text.link}>{text.link}</a>
          )}
        </>
      ),
      // render: (text) => {
      //   <>{text.link !== " - " ? <a href={text.link}>{text.link}</a> : " - "}</>;
      // },
    },
  ];

  const GuideData = [
    {
      col_name: "Mobile Number",
      sample: "9859859855",
      guide:
        "Please set the column cell for mobile number as Text format. Click on the link to know how to do this setting",
      link: "https://support.microsoft.com/en-us/office/format-numbers-as-text-583160db-936b-4e52-bdff-6f1863518ba4",
    },
    {
      col_name: "Date of birth",
      sample: "1994-11-05",
      guide:
        "Please set the column cell for date of birth in YYYY-MM-DD format . Click on the link to know how to do this setting",
      link: "https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e",
    },
    {
      col_name: "First Name/Name",
      sample: "Ramesh",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Last Name",
      sample: "Pawar",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Email",
      sample: "ramesh@gmail.com",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Gender",
      sample: "Male",
      guide: "Please enter from options (Male,Female,Others)",
      link: " - ",
    },
    {
      col_name: "State",
      sample: "Maharashtra",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "District",
      sample: "Mumbai",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Taluka",
      sample: "Andheri",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Current Salary",
      sample: "10000-15000",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Desired Salary",
      sample: "15000-20000",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Experience",
      sample: "0-1",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Job Role",
      sample: "Waiter",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Industry",
      sample: "Hospitality and Management",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Language",
      sample: "Hindi",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Degree",
      sample: "Bachelors of Ed",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Highest Education",
      sample: "HSC",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Stream",
      sample: "Commerce",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Skills",
      sample: "Communications",
      guide: " - ",
      link: " - ",
    },
    {
      col_name: "Resume Link",
      sample: "Enter link of resume",
      guide: " - ",
      link: " - ",
    },
    // {
    //   col_name: "Language",
    //   sample: "Hindi",
    //   guide: " - ",
    //   link: " - ",
    // },
  ];

  const selectBucket = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_role: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please select Organization name"),
      job_role: Yup.string().required("Please select Job role"),
    }),
    onSubmit: (values) => {
      handleCloseShortlistModal2();
      setLoadingModal(true);
      console.log("clicked handle submit");
      console.log(showText, "aaa");
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/shortlistcandidate`,
          {
            // org_name: values.org_name,
            // job_role: values.job_role,
            // shortlisted_id: selectedRowKeys,
            shortlistedid: showText === "" ? shortlistObj : { [showText.key]: showText.score },
            jobid: jobId,
            orgid: orgId,
            // shortlistedid: candidateIdsObj,
            // shortlistedby: "",
            // shortlisted_id: { "SMR-124536-JP1": "80", "SMR-124536-JP5": "40" },
            // jobid: "SMR-Job-JP",
            // orgid: "SMR-145256-JP",
          }
        )
        .then((res) => {
          console.log(res, "xyz");
          Swal.fire({
            icon: "success",
            // text: res.data["User Message"],
            title: "Candidate Shortlisted Successfully",
            html:
              "Total Count: " +
              `${res?.data?.total}` +
              "<br/>" +
              "Existing Count: " +
              `${res?.data?.skipped}`,
            showConfirmButton: true,
          }).then(() => {
            // navigate("/searchbyposting/createposting/recommendedPostList");
            setShowShortlistModal1(false);
            setShowShortlistModal2(false);
            setShowText("");
            setLoadingModal(false);
          });
          // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
        })
        .catch((err) => {
          console.log(err);
          setShowText("");
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Oops...",
            // text: res.data["User Message"],
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          }).then(() => setLoadingModal(false));
        });
    },
  });
  /* **** shortlismodals state **** */

  /* **** filter states **** */
  const [JSFilterdataSource, setJSFilterDataSource] = useState([]);
  const [selectedDSF, setSelectedDSF] = useState("");
  const [DSLabel, setDSLabel] = useState("");
  const stylesDD = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      // borderColor: state.isFocused ? "grey" : "red",
      fontSize: "14px",
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };

  /* ** Multi dd array states  ** */
  const [multiDS, setMultiDS] = useState([]);
  const [multiDistrict, setMultiDistrict] = useState([]);
  const [multiRole, setMultiRole] = useState([]);
  const [multiEducation, setMultiEducation] = useState([]);
  const [multiSpecialization, setMultiSpecialization] = useState([]);
  const [multiStream, setMultiStream] = useState([]);
  const [multiCps, setMultiCps] = useState([]);
  /* ** Multi dd array states  ** */
  const [education, Seteducation] = useState();
  const [streamdata, SetstreamData] = useState();
  const [degree, Setdergee] = useState([]);
  const [exp, Setexp] = useState();
  const [jobrole, Setjobrole] = useState();
  const [cpList, setCPList] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([
    { label: "Channel Partner", value: "CP" },
    { label: "Job Provider", value: "JP" },
    { label: "Job Fair", value: "JF" },
    { label: "Admin", value: "ADM" },
    { label: "ALL", value: "ALL" },
  ]);

  const [filterState, setFilterState] = useState([]);
  const [filterDistrict, setFilterDistrict] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [gendertype, setGenderType] = useState("");
  const [age, setAge] = useState([18, 45]);
  const [selectedCPs, setSelectedCPs] = useState("");
  const [selectedFilterState, setSelectedFilterState] = useState("");
  const [selectedFilterDistrict, setSelectedFilterDistrict] = useState("");
  const [qualification, setQualification] = useState("");
  const [stream, setStream] = useState("");
  const [experience, setExperirnce] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [salary, setSalary] = useState("");
  const [canId, setCanId] = useState("");
  const [desgination, setDesignation] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [candData, setCandData] = useState({});
  const [veridetails, setVeridetails] = useState({});
  const [veridetailsnew, setVeridetailsnew] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    date_of_birth: "",
    highest_education: "",
    current_salary: "",
    experience: "",
    job_role: "",
    dataSource: "",
    state: "",
    taluka: "",
    district: "",
    lastactive: "",
    modified: "",
  });
  const [filteroff, setFilteroff] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [time, setTime] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [jobrolenew, setjobrolenew] = useState([]);

  const handleVerifyDetailsNew = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/counsellor/associate/verify_details`, {
        verification_details: {
          registration_id: candData.registration_id,
          first_name: veridetailsnew.first_name,
          last_name: veridetailsnew.last_name,
          mobile: veridetailsnew.mobile,
          email: veridetailsnew.email,
          date_of_birth: veridetails.date_of_birth,
          highest_education: veridetails.highest_education,
          current_salary: veridetails.current_salary,
          job_role: veridetails.job_role,
          experience: veridetails.experience,
        },
        counsellor_id: canId,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "",
          text: "Profile updated successfully!",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          setShowEditModal(false);
          HandleFetchCandDetails(candData?.registration_id);
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "",
          text: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const resetFunc = () => {
    setFilteroff(true);
    setSelectedDSF("");
    setSelectedCPs("");
    setSelectedFilterState("");
    setSelectedFilterDistrict("");
    setDesignation("");
    setQualification("");
    setStream("");
    setSpecialization("");
    setExperirnce("");
    setSalary("");
    setGenderType("");
    setAge([18, 45]);
    setCPLabel("");
    setDSLabel("");
    setCategoryList("");
  };

  const setFunc = () => {
    setFilteroff(false);
  };

  useEffect(() => {
    filteroff &&  fetchFilteredCandidates();

  }, [filteroff]);

  const fetchexp = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/experience`)
      .then((res) => {
        Setexp(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDownload = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/downloadallcandidatedata`,
        {
          //responseType: 'arraybuffer',
          // shortlistedid: candidateIdsObj,
          objid: sessionStorage.getItem("dow_id"),
          email: sessionStorage.getItem("email"),
        }
      );
      // console.log(res.data, "xxx");
      // var blob = new Blob([res.data?.link], { type: ".csv" });
      // fileSaver.saveAs(blob, "download.csv");
      // const url = res.data.link;
      //     const a = document.createElement('a');
      //     a.href = url;
      //     a.download = 'candidate_data.csv';
      //     document.body.appendChild(a);
      //     a.click();
      //     window.URL.revokeObjectURL(url);
      Swal.fire({
        text: "File will be  mailed shortly to registered email Id (ETA 2 Minutes)",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = () => {
    if (tablerow.length !== 0) {
      setShow(!show);
      getFileName();
    } else {
      handleDownload();
    }
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        setjobrolenew(res.data);
        Setjobrole([...res.data, { label: "Others", value: "Others" }]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetcheducation = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchstream = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`)
      .then((res) => {
        SetstreamData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchdegree = () => {
    axios
      .get(`${process.env.REACT_APP_JOB_URL}/generic/degree`)
      .then((res) => {
        Setdergee(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchFilteredCandidates = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/candidatesearch`,
        {
          // jobid: jobId,
          // orgid: orgNameId,
          jobid: "",
          orgid: "",
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
          category: filteroff ? "" : categoryList ? categoryList : "",
          designation: filteroff ? "" : desgination ? desgination : "",
          salary: filteroff ? "" : salary ? salary : "",
          state: filteroff ? "" : selectedFilterState ? selectedFilterState : "",
          district: filteroff ? "" : selectedFilterDistrict ? selectedFilterDistrict : "",
          // industry: industry,
          cps: filteroff ? "" : selectedCPs ? selectedCPs : "",
          qualification: filteroff ? "" : qualification ? qualification : "",
          experience: filteroff ? "" : experience ? experience : "",
          stream: filteroff ? "" : stream ? stream : "",
          specialization: filteroff ? "" : specialization ? specialization : "",
          datasource: filteroff ? "" : selectedDSF ? selectedDSF : "",
          age: filteroff ? [18, 45] : age ? age : [18, 45],
          gender: filteroff ? "" : gendertype ? gendertype : "",
          // limit: 10,
          // offset: {paginationState.page !== 0 ? paginationState.page - 1 : 0},
          // offset: paginationState.page - 1,
          limit: pageSize,
          offset: paginationState.page - 1,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("dow_id", res.data.download_id);
          Setadmindata(
            res.data["JobSeeker_List"]?.map((index, id) => {
              return {
                key: index.registration_id,
                candidate: index.first_name + " " + index.last_name,
                // index?.first_name[0].toUpperCase() +
                // index.first_name.slice(1) +
                // " " +
                // index?.last_name[0].toUpperCase() +
                // index.last_name.slice(1),
                email: index.email === "nan" || index.email === "" ? "" : index.email,
                phone: index?.mobile
                  ? index?.mobile?.length === 12
                    ? index.mobile?.substr(2)
                    : index.mobile
                  : "",
                education:
                  index.highest_education === "nan" || index.highest_education === ""
                    ? ""
                    : index.highest_education,
                modified: moment(index.modifieddt).format("DD-MM-YYYY"),
                // skill: `skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index}`,
                skill: index.skills === "nan" || index.skills === "" ? "" : index.skills,
                // locatation: index.taluka !== "" ? index.taluka : " - ",
                state: index.state === "" || index.state === "nan" ? "" : index.state,
                district:
                  index.district === "" || index.district === "nan" || index.district === null
                    ? ""
                    : index.district,
                taluka:
                  index.taluka === "nan" || index.taluka === "" || index.taluka === null
                    ? ""
                    : index.taluka,
                datasource:
                  index.datasource === "" || index.datasource === "nan" ? "" : index.datasource,
                lastactive: moment(index.last_activedate).format("DD-MM-YYYY"),
                score: 0,
                degree: index.degree === "" || index.degree === "nan" ? "" : index.degree,
                stream: index.stream === "" || index.stream === "nan" ? "" : index.stream,
                desired_salary:
                  index.desired_salary === "" || index.desired_salary === "nan"
                    ? ""
                    : index.desired_salary,
                experience:
                  index.experience === "" || index.experience === "nan" ? "" : index.experience,
                first_name: index.first_name ? index.first_name : "",
                last_name: index.last_name ? index.last_name : "",
                gender: index.gender === "" || index.gender === "nan" ? "" : index.gender,
                age: index.age === "nan" || index.age === "" ? "" : index.age,
                desgination:
                  index.job_role === "nan" || index.job_role === "" ? "" : index.job_role,
              };
            })
          );
          setSearchData(
            res.data["JobSeeker_List"]?.map((index, id) => {
              return {
                key: index.registration_id,
                candidate: index.first_name + " " + index.last_name,
                // index?.first_name[0].toUpperCase() +
                // index.first_name.slice(1) +
                // " " +
                // index?.last_name[0].toUpperCase() +
                // index.last_name.slice(1),
                email: index.email === "nan" || index.email === "" ? "" : index.email,
                phone: index?.mobile
                  ? index?.mobile?.length === 12
                    ? index.mobile?.substr(2)
                    : index.mobile
                  : "",
                education:
                  index.highest_education === "nan" || index.highest_education === ""
                    ? ""
                    : index.highest_education,
                modified: moment(index.modifieddt).format("DD-MM-YYYY"),
                // skill: `skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index}`,
                skill: index.skills === "nan" || index.skills === "" ? "" : index.skills,
                // locatation: index.taluka !== "" ? index.taluka : " - ",
                state: index.state === "" || index.state === "nan" ? "" : index.state,
                district:
                  index.district === "" || index.district === "nan" || index.district === null
                    ? ""
                    : index.district,
                taluka:
                  index.taluka === "nan" || index.taluka === "" || index.taluka === null
                    ? ""
                    : index.taluka,
                datasource:
                  index.datasource === "" || index.datasource === "nan" ? "" : index.datasource,
                additional_data: index.additional_data !== "" ? index.additional_data : " - ",
                lastactive: moment(index.last_activedate).format("DD-MM-YYYY"),
                score: 0,
                degree: index.degree === "" || index.degree === "nan" ? "" : index.degree,
                stream: index.stream === "" || index.stream === "nan" ? "" : index.stream,
                data_status_type:
                  index.data_status_type === "" ||
                  index.data_status_type === "nan" ||
                  index.data_status_type === null
                    ? ""
                    : index.data_status_type,
                desired_salary:
                  index.desired_salary === "" || index.desired_salary === "nan"
                    ? ""
                    : index.desired_salary,
                experience:
                  index.experience === "" || index.experience === "nan" ? "" : index.experience,
                first_name: index.first_name ? index.first_name : "",
                last_name: index.last_name ? index.last_name : "",
                gender: index.gender === "" || index.gender === "nan" ? "" : index.gender,
                age: index.age === "nan" || index.age === "" ? "" : index.age,
                desgination:
                  index.job_role === "nan" || index.job_role === "" ? "" : index.job_role,
              };
            })
          );
          setPaginationState({ ...paginationState, dataSize: res.data["count"] });
          setIsLoading(false);
          setCandidateIdsObj(res.data["registration_id"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cpListFunc = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/channelpartner/account/details`,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      }
)
      .then((res) => {
        console.log("dd for :", res.data["cp_data"]);
        setCPList(res.data["cp_data"]);
      })
      .catch((err) => {
        console.log("dd for err:", err.response.data);
      });
  };

  useEffect(() => {
    if (salary === "") {
      setMultiSalary([]);
    }
  }, [salary]);

  useEffect(() => {
    if (gendertype === "") {
      setMultigender([]);
    }
  }, [gendertype]);

  useEffect(() => {
    if (specialization === "") {
      setMultiSpecialization([]);
    }
  }, [specialization]);

  useEffect(() => {
    if (stream === "") {
      setMultiStream([]);
    }
  }, [stream]);

  useEffect(() => {
    if (qualification === "") {
      setMultiEducation([]);
    }
  }, [qualification]);

  useEffect(() => {
    if (desgination === "") {
      setMultiRole([]);
    }
  }, [desgination]);

  useEffect(() => {
    if (selectedFilterDistrict === "") {
      setMultiDistrict([]);
    }
  }, [selectedFilterDistrict]);

  useEffect(() => {
    if (selectedCPs === "" || cplabel === "") {
      setMultiCps([]);
    }
  }, [selectedCPs, cplabel]);

  useEffect(() => {
    if (selectedDSF === "" || DSLabel === "") {
      setMultiDS([]);
    }
  }, [selectedDSF]);
  useEffect(() => {
    if (categoryList === "") {
      setMultiCategory([]);
    }
  }, [selectedDSF]);

  /* **** filter states **** */

  /* *** Create Bucket Modal states *** */
  const [orgNameID, setOrgNameID] = useState("");
  const [create, setCreate] = useState(false);
  const [createDS, setCreateDS] = useState(false);
  const [jobTitle, setJobtitle] = useState([]);
  const [jobType, SetjobType] = useState([]);
  const [allstate, Setallstate] = useState(false);
  const [multiSalary, setMultiSalary] = useState([]);
  const jobpostform = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      job_title: "",
      job_type: "",
      designation: "",
      applicantct: "",
      salary: "",
      jobpostdt: "",
      jobvaliddt: "",
      state: "",
      district: "",
      industry: "",
      qualification: "",
      stream: "",
      specialization: "",
      workfromhome: "",
      jobdesc: "",
      // createdby: resDATA,
      creator_role: "SA",
      headerimg: "",
      Experiance: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please Select Organization Name"),
      job_title: Yup.string().required(" Please enter Job Title"),
      // job_type: Yup.string().required(" Please enter Job Type"),
      // designation: Yup.string().required(" Please Select Designation"),
      // applicantct: Yup.string().required(" Please Select Applicanctct"),
      // salary: Yup.string().required(" Please Select Salary"),
      jobpostdt: Yup.string().required(" Please Select Job Post Date"),
      jobvaliddt: Yup.string().required(" Please Select Job Post till Date"),
      state: Yup.string().required(" Please Select State"),
      // district: Yup.string().required(" Please Select District"),
      //industry: Yup.string().required(" Please Select Industry"),
      // qualification: Yup.string().required(" Please Select Qualifaction"),
      // stream: Yup.string().required(" Please Select Stream"),
      // specialization: Yup.string().required(" Please Select Specialization"),
      // workfromhome: Yup.string().required(" Please Select "),
      // jobdesc: Yup.string().required(" Please Enter Job Description "),
      // Experiance: Yup.string().required(" Please Select Experiance"),
    }),
    onSubmit: (values) => {
      const job = new FormData();
      job.append("org_name", values.org_name);
      job.append("org_id", orgNameID);
      job.append("job_title", values.job_title);
      job.append("job_type", values.job_type);
      job.append("designation", values.designation);
      job.append("applicantct", values.applicantct);
      job.append("salary", values.salary);
      job.append("jobpostdt", values.jobpostdt);
      job.append("jobvaliddt", moment(values.jobvaliddt).format("YYYY-MM-DD HH:mm"));
      job.append("state", !allstate ? values.state : "");
      job.append("district", !allstate ? values.district : "");
      job.append("qualification", values.qualification);
      job.append("stream", values.stream);
      job.append("specialization", values.specialization);
      job.append("workfromhome", values.workfromhome);
      job.append("jobdesc", values.jobdesc);
      job.append("createdby", values.createdby);
      job.append("creator_role", values.creator_role);
      job.append("experience", values.Experiance);

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpost`,
          // `${ngrokLink}/superadmin/candidatesearch/jobpost`,
          job
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Job posting has been created successfully",
            }).then((result) => {
              setShowShortlistModal3(false);
              setShowShortlistModal1(false);
              setShowShortlistModal2(true);
            });
          
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "Oops...",
            text: err.response.data["User Message"],
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  });
  const fetchjobtype = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/jobtype`)
      .then((res) => {
        SetjobType(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobtitle`,
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/jobpostdata`, {
        params: {
          // org_name: orgNameID,
          orgid: orgNameID,
          type: "dropdown",
        },
      })
      .then((res) => {
        console.log(res.data.org_title);
        setJobtitle(res.data["JobPost_List"]);
       
      });
  }, [orgNameID]);
  useEffect(() => {
    fetchjobtype();
  }, []);

  const HandleFetchCandDetails = async (value) => {
    // setIsLoading(true);
    console.log("value", value);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/register`, {
        params: { reg_id: value },
      })
      .then((res) => {
        console.log(res.data.JobSeeker_List, "ppp");
        setCandData(res.data.JobSeeker_List);
        setVeridetails({
          first_name: res.data.JobSeeker_List.first_name,
          last_name: res.data.JobSeeker_List.last_name,
          email: res.data.JobSeeker_List.email,
          mobile: res.data.JobSeeker_List.mobile,
          date_of_birth: res.data.JobSeeker_List.date_of_birth,
          highest_education: res.data.JobSeeker_List.highest_education,
          current_salary: res.data.JobSeeker_List.current_salary,
          experience: res.data.JobSeeker_List.experience,
          job_role: res.data.JobSeeker_List.job_role,
          dataSource: res.data.JobSeeker_List.datasource,
          state: res.data.JobSeeker_List.state,
          taluka: res.data.JobSeeker_List.taluka,
          district: res.data.JobSeeker_List.district,
          lastactive: moment(res.data.JobSeeker_List.last_activedate).format("DD-MM-YYYY"),
          modified: moment(res.data.JobSeeker_List.last_modified_dt).format("DD-MM-YYYY"),
        });
        setVeridetailsnew({
          first_name: res.data?.JobSeeker_List?.first_name,
          last_name: res.data?.JobSeeker_List?.last_name,
          email: res.data?.JobSeeker_List?.email,
          mobile: res.data?.JobSeeker_List?.mobile,
          date_of_birth: res.data?.JobSeeker_List?.date_of_birth,
          highest_education: res.data.JobSeeker_List.highest_education,
          current_salary: res.data.JobSeeker_List.current_salary,
          experience: res.data.JobSeeker_List.experience,
          job_role: res.data.JobSeeker_List.job_role,
          dataSource: res.data.JobSeeker_List.datasource,
          state: res.data.JobSeeker_List.state,
          taluka: res.data.JobSeeker_List.taluka,
          district: res.data.JobSeeker_List.district,
          lastactive: moment(res.data.JobSeeker_List.last_activedate).format("DD-MM-YYYY"),
          modified: moment(res.data.JobSeeker_List.last_modified_dt).format("DD-MM-YYYY"),
        });
        setOpenModal(true);
  
      })
      .catch((err) => {
        console.log(err);
        // setIsLoading(false);
      });
  };

  /* *** Create Bucket Modal states *** */
  const [colHeaders, setColHeaders] = useState([
    {
      label: "Candidate Name",
      key: "candidate",
      isExportable: true,
    },
    {
      label: "Phone Number",
      key: "phone",
      isExportable: true,
    },
    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Education",
      key: "education",
      isExportable: true,
    },
    {
      label: "Gender",
      key: "gender",
      isExportable: true,
    },
    {
      label: "Age",
      key: "age",
      isExportable: true,
    },
    {
      label: "Modified On",
      key: "modified",
      isExportable: true,
    },
    {
      label: "Data Source",
      key: "datasource",
      isExportable: true,
    },
    {
      label: "Modified On",
      key: "modified",
      isExportable: true,
    },
    {
      label: "Skills",
      key: "skill",
      isExportable: true,
    },
    {
      label: "Role",
      key: "designation",
      isExportable: true,
    },
    {
      label: "Experience",
      key: "experience",
      isExportable: true,
    },
    {
      label: "Specialization",
      key: "degree",
      isExportable: true,
    },
    {
      label: "Stream",
      key: "stream",
      isExportable: true,
    },
    {
      label: "Salary",
      key: "desired_salary",
      isExportable: true,
    },
    {
      label: "Type",
      key: "data_status_type",
      isExportable: true,
    },
    {
      label: "State",
      key: "state",
      isExportable: true,
    },
    {
      label: "District",
      key: "district",
      isExportable: true,
    },
    {
      label: "Taluka",
      key: "taluka",
      isExportable: true,
    },
    {
      label: "Last active",
      key: "lastactive",
      isExportable: true,
    },
    {
      label: "Datasource",
      key: "datasource",
      isExportable: true,
    },
    {
      label: "Status",
      key: "data_source_type",
      isExportable: true,
    },
  ]);

  console.log(dataSource, "dataSource");

  const data = admindata?.map((index, id) => {
    return {
      key: index.registration_id,
      candidate: index.first_name,
      email: index.email !== "" ? index.email : " - ",
      phone: index.mobile,
      education: index.highest_education ? index.highest_education : " - ",
      modified: moment(index.modifieddt).format("DD-MM-YYYY"),
      // skill: `skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index}`,
      skill: index.skills ? index.skills : index.job_role ? index.job_role : " - ",
      // locatation: index.taluka !== "" ? index.taluka : " - ",
      state: index.state !== "" ? index.state : " - ",
      district:
        index.district === "" || index.district === "nan" || index.district === null
          ? ""
          : index.district,
      taluka:
        index.taluka === "nan" || index.taluka === "" || index.taluka === null ? "" : index.taluka,
      datasource: index.datasource !== "" ? index.datasource : " - ",
      lastactive: moment(index.last_activedate).format("DD-MM-YYYY"),
    };
  });

  const [searchData, setSearchData] = useState([]);
  const [name, setName] = useState("");
  const [searchBtn , setSearchBtn]=useState(0);
  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );

  console.log("shortlist object", shortlistObj);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(admindata, newSelectedRowKeys);
    setTablerow(selectedRows);
    setShortlistObj(
      selectedRows.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.key]: item.score === undefined ? 0 : item.score }),
        {}
      )
    );
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {
      // return item.key!==keyId
      // i => ids.includes( i.empid )
      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };

  const colName = [
    {
      title: "Candidate Name",
      // dataIndex: "candidate",
      fixed: "left",
      width: 250,
      render: (text, record) => (
        <p
          onClick={() => {
            setCanId(record.key);
            HandleFetchCandDetails(record.key);
          
          }}
          style={{
            cursor: "pointer",
            marginBottom: "-2px",
            color: "#3B5092",
            textDecorationLine: "underline",
          }}
        >
          {`${text.candidate}`}
        </p>
      ),
      sorter: {
        compare: (a, b) => a?.candidate?.localeCompare(b?.candidate),
        multiple: 1,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      fixed: "left",
      width: 150,
      sorter: {
        compare: (a, b) => a.phone - b.phone,
        multiple: 2,
      },
    },
    {
      title: "Email",
      //dataIndex: "email",
      width: 200,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 2,
      },
      render: (text) => <>{text.email ? text.email : "-"}</>,
    },
    {
      title: "Education",
      //dataIndex: "education",
      width: 150,
      sorter: {
        compare: (a, b) => a?.education?.localeCompare(b?.education),
        multiple: 3,
      },
      render: (text) => <>{text.education ? text.education : "-"}</>,
    },
    {
      title: "Gender",
      //dataIndex: "gender",
      width: 150,
      sorter: {
        compare: (a, b) => a?.gender?.localeCompare(b?.gender),
        multiple: 3,
      },
      render: (text) => <>{text.gender ? text.gender : "-"}</>,
    },
    {
      title: "Age",
      dataIndex: "age",
      width: 150,
      sorter: {
        compare: (a, b) => a?.age - b?.age,
        multiple: 3,
      },
    },
    {
      title: "Modified On",
      dataIndex: "modified",
      width: 150,
      sorter: {
        compare: (a, b) => moment(a.modified, "DD-MM-YYYY") - moment(b.modified, "DD-MM-YYYY"),
        multiple: 4,
      },
    },
    {
      title: "Skills",
      //dataIndex: "skill",
      width: 150,
      sorter: {
        compare: (a, b) => a?.skill?.localeCompare(b?.skill),
        multiple: 5,
      },
      render: (text) => <>{text.skill ? text.skill : "-"}</>,
    },
    {
      title: "Role",
      //dataIndex: "desgination",
      width: 150,
      sorter: {
        compare: (a, b) => a?.desgination?.localeCompare(b?.desgination),
        multiple: 6,
      },
      render: (text) => <>{text.desgination ? text.desgination : "-"}</>,
    },
    {
      title: "Experience",
      //dataIndex: "experience",
      width: 150,
      sorter: {
        compare: (a, b) => a?.experience?.localeCompare(b?.experience),
        multiple: 6,
      },
      render: (text) => <>{text.experience ? text.experience : "-"}</>,
    },
    {
      title: "Specialization",
      //dataIndex: "degree",
      width: 150,
      sorter: {
        compare: (a, b) => a?.degree?.localeCompare(b?.degree),
        multiple: 6,
      },
      render: (text) => <>{text.degree ? text.degree : "-"}</>,
    },
    {
      title: "Stream",
      //dataIndex: "stream",
      width: 150,
      sorter: {
        compare: (a, b) => a?.stream?.localeCompare(b?.stream),
        multiple: 6,
      },
      render: (text) => <>{text.stream ? text.stream : "-"}</>,
    },
    {
      title: "Salary",
      dataIndex: "desired_salary",
      width: 150,
      sorter: {
        compare: (a, b) => a?.desired_salary?.localeCompare(b?.desired_salary),
        multiple: 6,
      },
    },
    {
      title: "Type",
      //dataIndex: "data_status_type",
      width: 150,
      sorter: {
        compare: (a, b) => a?.data_status_type?.localeCompare(b?.data_status_type),
        multiple: 6,
      },
      render: (text) => <>{text.data_status_type === "" ? "-" : text.data_status_type}</>,
    },
    {
      title: "State",
      dataIndex: "state",
      width: 150,
      key: "state",
      sorter: {
        compare: (a, b) => a?.state?.localeCompare(b?.state),
        multiple: 4,
      },
    },
    {
      title: "District",
      //dataIndex: "district",
      width: 150,
      key: "district",
      sorter: {
        compare: (a, b) => a?.district?.localeCompare(b?.district),
        multiple: 4,
      },
      render: (text) => <>{text.district === "" ? "-" : text.district}</>,
    },
    {
      title: "Taluka",
      //dataIndex: "taluka",
      width: 150,
      key: "taluka",
      sorter: {
        compare: (a, b) => a?.taluka?.localeCompare(b?.taluka),
        multiple: 4,
      },
      render: (text) => <>{text.taluka ? text.taluka : "-"}</>,
    },
    {
      title: "Last Active",
      dataIndex: "lastactive",
      width: 150,
      sorter: {
        compare: (a, b) => moment(a.lastactive, "DD-MM-YYYY") - moment(b.lastactive, "DD-MM-YYYY"),
        multiple: 5,
      },
    },


    {
      title: "Data Source",
      dataIndex: "datasource",
      width: 150,
    
      sorter: {
        compare: (a, b) => a?.datasource?.localeCompare(b?.datasource),
        multiple: 6,
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      width: 150,
      render: (text, record) => (
    
        <div
          className="dropdown"
          style={{
            width: "100%",
            height: "30px",
            // border: "1px solid ",
            borderRadius: "15px",
            backgroundColor: text.data_status_type === 1 ? "#BDFDC8" : "#EFD6FF",
            // cursor: "pointer",
          }}
        >
          <p
            style={{
              // padding: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              padding: "5px 0px 5px",
            }}
            // className="dropdown-toggle"
            // data-bs-toggle="dropdown"
          >
            {text.data_status_type === 1 ? "Placed" : "Not Placed"}
          </p>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      // fixed: "right",
      width: 100,
      render: (admindata) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "1") {
                  Seteditadmin(true);
                  Seteditdata(admindata);
                }
          
                else {
                  setShowShortlistModal1(true);
                  setShowText(admindata);
                }
              },
            }}
          >
            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;

  const tableProps = {
    footer: () => "showing page 1 of 10",
   
  };

  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);

    // return "mis_data_" + dformat + ".txt";
  };

  const AllShortListFunc = () => {
    handleCloseShortlistModal2();
    setLoadingModal(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/shortlistcandidate`,
        {
      
          shortlistedid:sessionStorage.getItem("dow_id")?sessionStorage.getItem("dow_id"):'',
          jobid: jobId1,
          orgid: orgId1,
          limit: dataLength,
       
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status === 504 || res.status === 200) {
          Swal.fire({
            icon: "success",
            // text: res.data["User Message"],
            title: "Candidate Shortlisted Successfully",
            html:
              "Total Count: " +
              `${res?.data?.total}` +
              "<br/>" +
              "Existing Count: " +
              `${res?.data?.skipped}`,
            showConfirmButton: true,
          }).then(() => {
            // navigate("/searchbyposting/createposting/recommendedPostList");
            setShowShortlistModal1(false);
            setShowShortlistModal2(false);
            setShowText("");
            setLoadingModal(false);
            setShowShortlistOption(false);
            setDataLength("");
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setShowText("");
        Swal.fire({
          icon: "error",
          title: err.response.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Oops...",
          // text: res.data["User Message"],
          text: err.response.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        }).then(() => setLoadingModal(false));
      });
  };

  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
     
        deactivate_id: data.key,
    
        active: "false",
        deactivatedby: registerID,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "",
          //text: res.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        // fetchadminList();
        fatchcancidate();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
         
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };


  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
        setFilterState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedFilterState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
        setFilterDistrict(res.data);
        // setSelectedFilterDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchSalary = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/salary`)
      .then((res) => {
        console.log("Salary", res.data);
        setSalaryList(res.data);
      })
      .catch((e) => {
        console.log("salary", e);
      });
  };

  const bulkUploadfunc = () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let formData = new FormData();
    formData.append("jobseeker_file", CSVFile);
    formData.append("datasource", selectedDS);
    formData.append("partner_association", "samarth");
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/bulk/upload`,
        formData
      )
      .then((res) => {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "success",
          // title: "Success",
          // text: "File Uploaded successfully",
          title: "File Uploaded successfully",
          html:
            "Failed Upload : " +
            `${res.data["On Hold"]}` +
            "<br/>" +
            "Successfully Uploaded: " +
            `${res.data["Success"]}` + 
            "<br/>" +
            "Duplicate Upload: " +
            `${res.data["Duplicates"]}`

            ,
          showConfirmButton: true,
          confirmButtonText: "Data Quality Issue",
          // timer: 3000,
        }).then((result) => {
          if (result.isConfirmed) {
            if (res.data.link !== "") {
             
              const link = document.createElement("a");
              link.href = res.data.link;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              console.log("error");
            }
          }
        });
        setactionModal2(false);
        setShowFile(false);
      })
      .catch((err) => {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        console.log("error ", err);
        Swal.fire({
          icon: "error",
          title: res.data["Developer Message"] ? res.data["Developer Message"] : "Oops...",
          text: res.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const fetchDataSource = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/datasource`,
        // categoryList !== "" &&
        {
          params: { category: categoryList },
        }
      )
      .then((res) => {
        console.log("datasource", res.data["datasource_list"]);
        setDataSource(res.data["datasource_list"]);
        setJSFilterDataSource(res.data["datasource_list"]);
   
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fatchcancidate = async () => {
    setIsLoading(true);
    await axios
      .get(

        `${process.env.REACT_APP_BACKEND_URL_REGISTER}/jobseeker/register`,

        {
          params: {
            // limit: 10,
            limit: pageSize,
            offset: paginationState.page - 1,
            search_data: name,
          
                created_by: sessionStorage.getItem("register_id")
                ? sessionStorage.getItem("register_id")
                : "",
           
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, "his");
          setPaginationState({ ...paginationState, dataSize: res.data["count"] });
          setIsLoading(false);
          Setadmindata(
            res.data["JobSeeker_List"]?.map((index, id) => {
              return {
                key: index.registration_id,
                candidate: `${index.first_name} ${index.last_name}`,
                email: index.email === null ? " - " : index.email !== "" ? index.email : " - ",
                phone: index?.mobile
                  ? index?.mobile?.length === 12
                    ? index.mobile?.substr(2)
                    : index.mobile
                  : "",
                education: index.highest_education ? index.highest_education : " - ",
                modified: moment(index.modifieddt).format("DD-MM-YYYY"),
                // skill: `skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index}`,
                skill: index.skills ? index.skills : " - ",
                desgination: index.job_role ? index.job_role : " - ",
                state: index.state !== "" ? index.state : " - ",
                district:
                  index.district === "" || index.district === "nan" || index.district === null
                    ? ""
                    : index.district,
                taluka:
                  index.taluka === "nan" || index.taluka === "" || index.taluka === null
                    ? ""
                    : index.taluka,
                datasource: index.datasource !== "" ? index.datasource : " - ",
                lastactive: moment(index.last_activedate).format("DD-MM-YYYY"),
                data_status_type:
                  index.data_status_type === "" ||
                  index.data_status_type === "nan" ||
                  index.data_status_type === null
                    ? ""
                    : index.data_status_type,
                score: 0,
                degree: index.degree !== "" ? index.degree : " - ",
                stream: index.stream !== "" ? index.stream : " - ",
                desired_salary: index.desired_salary !== "" ? index.desired_salary : " - ",
                experience: index.experience !== "" ? index.experience : " - ",
                first_name: index.first_name !== "" ? index.first_name : " - ",
                last_name: index.last_name !== "" ? index.last_name : " - ",
                gender: index.gender !== "" ? index.gender : " - ",
                age: index.age !== "" ? index.age : " - ",
              };
            })
          );
          console.log(res.data.JobSeeker_List, "aaa");
          setSearchData(
            res.data["JobSeeker_List"]?.map((index, id) => {
              return {
                key: index.registration_id,
                candidate:
                  index?.first_name[0].toUpperCase() +
                  index.first_name.slice(1) +
                  " " +
                  index?.last_name[0].toUpperCase() +
                  index.last_name.slice(1),
                email: index.email === null ? " - " : index.email !== "" ? index.email : " - ",
                phone: index?.mobile
                  ? index?.mobile?.length === 12
                    ? index.mobile?.substr(2)
                    : index.mobile
                  : "",
                education: index.highest_education ? index.highest_education : " - ",
                modified: moment(index.modifieddt).format("DD-MM-YYYY"),
                // skill: `skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index}`,
                skill: index.skills ? index.skills : " - ",
                desgination: index.job_role ? index.job_role : " - ",
                state: index.state !== "" ? index.state : " - ",
                district:
                  index.district === "" || index.district === "nan" || index.district === null
                    ? ""
                    : index.district,
                taluka:
                  index.taluka === "nan" || index.taluka === "" || index.taluka === null
                    ? ""
                    : index.taluka,
                datasource: index.datasource !== "" ? index.datasource : " - ",
                additional_data: index.additional_data !== "" ? index.additional_data : " - ",
                lastactive: moment(index.last_activedate).format("DD-MM-YYYY"),
                data_status_type:
                  index.data_status_type === "" ||
                  index.data_status_type === "nan" ||
                  index.data_status_type === null
                    ? ""
                    : index.data_status_type,
                score: 0,
                degree: index.degree !== "" ? index.degree : " - ",
                stream: index.stream !== "" ? index.stream : " - ",
                desired_salary: index.desired_salary !== "" ? index.desired_salary : " - ",
                experience: index.experience !== "" ? index.experience : " - ",
                first_name: index.first_name !== "" ? index.first_name : " - ",
                last_name: index.last_name !== "" ? index.last_name : " - ",
                gender: index.gender !== "" ? index.gender : " - ",
                age: index.age !== "" ? index.age : " - ",
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchJobSeekersKPIs = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/fetch/kpi`,{
        params: {
          created_by: sessionStorage.getItem("register_id")
          ? sessionStorage.getItem("register_id")
          : "",
        }
      })
      .then((res) => {
        setKPIObj({
          // job_seeekr_on_hold: res.data["job_seeekr_on_hold"],
          // job_seeker_in_pipeline: res.data["job_seeker_in_pipeline"],
          // job_seekers_hired: res.data["job_seekers_hired"],
          // total_jobseekers: res.data["total_jobseekers"],
          total_jobseekers: res.data["total_jobseekers"],
          job_seekers_hired: res.data["job_seekers_hired"],
          job_seeker_in_pipeline: res.data["job_seeker_in_pipeline"],
          job_seeker_on_hold: res.data["job_seeker_on_hold"],
          job_seeker_shortlisted: res.data["job_seeker_shortlisted"],
          job_seeker_uploaded: res.data["job_seeker_uploaded"],
          job_seeker_verified: res.data["job_seeker_verified"],
          job_seeker_connected: res.data["job_seeker_connected"],
        });
        // setKPIObj({ ...KPIObj, job_seekers_hired: res.data["job_seekers_hired"] });
        // setKPIObj({ ...KPIObj, jobs_created: res.data["jobs_created"] });
        // setKPIObj({ ...KPIObj, registered_providers: res.data["registered_providers"] });
      })
      .catch((err) => {
        console.log("error kpi", err);
      });
  };
  const handleSearch = () => {
    const newData = admindata.filter((ele) => {
      return name?.toLocaleLowerCase() === ""
        ? ele
        : ele.candidate?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
          name.toLocaleLowerCase() === ""
        ? ele
        : String(ele.phone).toLocaleLowerCase().includes(name.toLocaleLowerCase());
    });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    setSearchData(newData);
  };

  const handleChange = (event) => {
    const value = Math.max(
      0,
      Math.min(
        paginationState.dataSize < 3000 ? paginationState.dataSize : 3000,
        Number(event.target.value)
      )
    );
    setDataLength(value);
  };

  const handleSearchAllData = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/dynamic/search`,
        {
          search_data: name,
        }
      )
      .then((res) => {
        console.log("searched data: ", res.data["search_data"]);
        Setadmindata(
          res.data["search_data"]?.map((index, id) => {
            return {
              key: index.registration_id,
              candidate:
                index?.first_name[0].toUpperCase() +
                index.first_name.slice(1) +
                " " +
                index?.last_name[0].toUpperCase() +
                index.last_name.slice(1),
              email: index.email === null ? " - " : index.email !== "" ? index.email : " - ",
              phone: index?.mobile
                ? index?.mobile?.length === 12
                  ? index.mobile?.substr(2)
                  : index.mobile
                : "",
              education: index.highest_education ? index.highest_education : " - ",
              modified: moment(index.modifieddt).format("DD-MM-YYYY"),
              // skill: `skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index}`,
              skill: index.skills ? index.skills : " - ",
              desgination: index.job_role ? index.job_role : " - ",
              degree: index.degree !== "" ? index.degree : " - ",
              state: index.state !== "" ? index.state : " - ",
              district:
                index.district === "" || index.district === "nan" || index.district === null
                  ? ""
                  : index.district,
              taluka:
                index.taluka === "nan" || index.taluka === "" || index.taluka === null
                  ? ""
                  : index.taluka,
              datasource: index.datasource !== "" ? index.datasource : " - ",
              lastactive: moment(index.last_activedate).format("DD-MM-YYYY"),
              data_status_type:
                index.data_status_type === "" ||
                index.data_status_type === "nan" ||
                index.data_status_type === null
                  ? ""
                  : index.data_status_type,
              score: 0,
              gender: index.gender !== "" ? index.gender : " - ",
              age: index.age !== "" ? index.age : " - ",
            };
          })
        );
        setSearchData(
          res.data["search_data"]?.map((index, id) => {
            return {
              key: index.registration_id,
              candidate:
                index?.first_name[0].toUpperCase() +
                index.first_name.slice(1) +
                " " +
                index?.last_name[0].toUpperCase() +
                index.last_name.slice(1),
              email: index.email === null ? " - " : index.email !== "" ? index.email : " - ",
              phone: index?.mobile
                ? index?.mobile?.length === 12
                  ? index.mobile?.substr(2)
                  : index.mobile
                : "",
              education: index.highest_education ? index.highest_education : " - ",
              modified: moment(index.modifieddt).format("DD-MM-YYYY"),
              // skill: `skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index} skill${index}`,
              skill: index.skills ? index.skills : " - ",
              desgination: index.job_role ? index.job_role : " - ",
              degree: index.degree !== "" ? index.degree : " - ",
              state: index.state !== "" ? index.state : " - ",
              district:
                index.district === "" || index.district === "nan" || index.district === null
                  ? ""
                  : index.district,
              taluka:
                index.taluka === "nan" || index.taluka === "" || index.taluka === null
                  ? ""
                  : index.taluka,
              datasource: index.datasource !== "" ? index.datasource : " - ",
              additional_data: index.additional_data !== "" ? index.additional_data : " - ",
              lastactive: moment(index.last_activedate).format("DD-MM-YYYY"),
              data_status_type:
                index.data_status_type === "" ||
                index.data_status_type === "nan" ||
                index.data_status_type === null
                  ? ""
                  : index.data_status_type,
              score: 0,
              gender: index.gender !== "" ? index.gender : " - ",
              age: index.age !== "" ? index.age : " - ",
            };
          })
        );
        setPaginationState({ ...paginationState, dataSize: res.data["count"] });
      })
      .catch((err) => {
        console.log("err:", err.response);
      });
  };
  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };


  useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);


  console.log(searchData, "ppp");

  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);
  useEffect(() => {
 
    fetchOrgList();
    fetchStateList();
    // fetchDataSource();
    fetchJobSeekersKPIs();
    fetchexp();
    fetchjobrole();
    fetchSalary();
    fetcheducation();
    fetchdegree();
    fetchstream();
    cpListFunc();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedFilterState]);
  useEffect(() => {
    // fatchcancidate();
    name !== "" && fatchcancidate();
  }, [paginationState.page, pageSize, searchBtn]);
  useEffect(() => {
    fetchOgrRole();
  }, [orgId, orgId1]);
  /* useeffect for filter function */
  useEffect(() => {

    !filtershow && name === "" && fetchFilteredCandidates();
  }, [paginationState.page, selectedDSF, pageSize]);
  /* useeffect for filter function */
  const filterOffsetFunc = () => {
    setPaginationState({ ...paginationState, page: 1 });
  };
  useEffect(() => {
    HandleTimeline();
  }, [candData]);
  useEffect(() => {
    fetchDataSource();
  }, [categoryList]);
  console.log("category list:", categoryList);
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox
          mt={2}
          // sx={{ overflowX: "hidden" }}
        >
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              {translate('candidates')}       
              </SoftTypography>
           
            </SoftBox>

            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#FFF",
                borderRadius: "2px",
                cursor: "pointer",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              width="40px"
              onClick={() => {
                fetchFilteredCandidates();
                fetchJobSeekersKPIs();
              }}
            >
              <SoftBox>
                <img src={refreshBtn} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalregister')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_jobseekers === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_jobseekers
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalconnect')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_connected === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_connected
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalverify')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_verified === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_verified
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalmatch')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_shortlisted === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_shortlisted
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalplaced')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seekers_hired === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seekers_hired
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalinpipeline')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
                // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seeker_in_pipeline === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seeker_in_pipeline
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
     
          <SoftBox alignItems="flex-start" position="relative">
            <SoftBox>
              <>
                <SoftBox mt={1} width="100%" height="auto" display="flex">
                  <SoftBox width="100%">
                    {!filtershow ? (
                      <>
                        <Grid container spacing={2} sx={{ mb: 3, mt: 3 }}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <SoftBox width={{ lg: "400px", md: "400px" }}>
                              <SoftBox
                                display="flex"
                                flexDirection="row"
                                gap="10px"
                                justifyContent="space-between"
                              >
                                <SoftInput
                                  type="search"
                                  // width={{ lg: "1000px", md: "600px" }}
                                  // width="1000px"
                                  id="search-bar"
                                  className="text"
                                  // onInput={(e) => {
                                  //   setSearchQuery(e.target.value);
                                  // }}
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  variant="outlined"
                                  placeholder="Search..."
                                  size="small"
                                />
                                  <SoftBox
                // pl={2}
                margin="auto"
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                width="126px"
                p="10px 20px"
                onClick={
                  () => setSearchBtn(searchBtn+1)
                             }
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "medium",
                    lineHeight: "15px",
                  }}
                >
              {translate('search')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                // pl={2}
                margin="auto"
                sx={{
                  height: "35px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width="126px"
                p="10px 20px"
                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  setName('')


                  fetchFilteredCandidates();
                  fetchJobSeekersKPIs();
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#3B5092",
                    fontSize: "12px",
                    fontWeight: "medium",
                    lineHeight: "15px",
                  }}
                >
              {translate('clear')}       
                </SoftTypography>
              </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <SoftBox
                              display="flex"
                              flexDirection="row"
                              gap="10px"
                              justifyContent="flex-end"
                            >
                              <SoftBox
                                pl={2}
                                sx={{
                                  height: "33px",
                                  backgroundColor: "#3B5092",
                                  borderRadius: "2px",
                                  cursor: selectedRowKeys.length !== 0 ? "no-drop" : "pointer",
                                  opacity: selectedRowKeys.length !== 0 ? 0.4 : 1,
                                }}
                                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                p="7px 22px"
                                width={{ lg: "200px", sm: "100%", xs: "50%" }}
                                // onClick={() => setShow(true)}
                                onClick={() => (
                                  selectedRowKeys.length === 0 && setShowShortlistModal1(true),
                                  setShowShortlistOption(true)
                                )}
                              >
                                <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  sx={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "medium",
                                    lineHeight: "20px",
                                  }}
                                >
                                  Bulk Shortlist
                                </SoftTypography>
                              </SoftBox>

                              <SoftBox
                                pl={2}
                                sx={{
                                  height: "33px",
                                  backgroundColor: "#3B5092",
                                  borderRadius: "2px",
                                  cursor: selectedRowKeys.length ? "pointer" : "no-drop",
                                }}
                                // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                p="7px 22px"
                                width={{ lg: "97px", sm: "100%", xs: "50%" }}
                                // onClick={() => setShow(true)}
                                onClick={() =>
                                  selectedRowKeys.length &&
                                  (setShowShortlistModal1(true), setShowShortlistOption(false))
                                }
                              >
                                <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  sx={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "medium",
                                    lineHeight: "20px",
                                  }}
                                >
              {translate('shortlist')}       
                                </SoftTypography>
                              </SoftBox>

                              <Tooltip key="1" title="Add Candidates" placement="top">
                                <SoftBox
                                  sx={{
                                    height: "33px",
                                    backgroundColor: "#3B5092",
                                    borderRadius: "2px",
                                    cursor: "pointer",
                                  }}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                  width="40px"
                                  onClick={() =>
                                    // navigate("/jobprovider-registration"),
                                    setJSModal(true)
                                  }
                                >
                                  <SoftBox
                                    sx={{ color: "#FFF", width: "40px", textAlign: "center" }}
                                  >
                                    +
                                  </SoftBox>
                                </SoftBox>
                              </Tooltip>

                              <Tooltip
                                key="2"
                                title={tablerow.length === 0 ? "Download All" : "Download"}
                                placement="top"
                              >
                                <SoftBox
                                  sx={{
                                    height: "33px",
                                    backgroundColor: "#000000",
                                    borderRadius: "2px",
                                    cursor: "pointer",
                                  }}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                  width="40px"
                                  onClick={() => {
                                    handleClick();
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="46"
                                    height="34"
                                    viewBox="0 0 46 34"
                                    fill="none"
                                  >
                                    <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                                    <path
                                      d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M22.9531 16.5098V23.0102"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                                      stroke="white"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </SoftBox>
                              </Tooltip>

                              <Tooltip key="3" title="Filter" placement="top">
                                <SoftBox
                                  sx={{
                                    height: "33px",
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: "2px",
                                    cursor: "pointer",
                                  }}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                  width="40px"
                                  onClick={() => {
                                    // showFilter ? setShowFilter(false) : setShowFilter(true);
                                    //setShowFilter(true);
                                    setFiltershow(true);
                                  }}
                                >
                                  <SoftBox>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="40"
                                      height="34"
                                      viewBox="0 0 39 37"
                                      fill="none"
                                    >
                                      <rect width="38.8889" height="37" rx="2" fill="white" />
                                      <path
                                        d="M28.8889 10H10L17.5556 18.9344V25.1111L21.3333 27V18.9344L28.8889 10Z"
                                        stroke="#A4A4A4"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </SoftBox>
                                </SoftBox>
                              </Tooltip>
                            </SoftBox>
                          </Grid>
                        </Grid>

                        <Table
                          loading={isLoading}
                          rowSelection={rowSelection}
                          columns={colName}
                          dataSource={searchData}
                          scroll={{ x: 1300, y: 400 }}
                          pagination={{
                            pageSizeOptions: ["10", "20", "50", "100", "500"],
                            total: paginationState.dataSize,
                            showSizeChanger: true,
                            onShowSizeChange: handlePageSizeChange,
                            current: paginationState.page,
                            onChange: (page, pageSize) =>
                              setPaginationState({ ...paginationState, page: page }),
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            background: "#FFFFFF",
                            mt: 3,
                            p: 3,
                            borderRadius: "10px",
                          }}
                        >
                          <SoftTypography fontSize="20px" fontWeight="600" lineHeight="19px">
                          {translate('loadcandidate')}       
                          </SoftTypography>

                          <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 2 }}>
                            <img src={helpimg} alt="" />

                            <SoftTypography fontSize="14px" fontWeight="small" lineHeight="19px">
                              &nbsp;{translate('thiswillhelp')}       

                            </SoftTypography>
                          </Box>

                          <Box
                            sx={{
                              p: 1,
                              background: "rgba(27, 89, 248, 0.05)",
                              border: "1px solid #C4C4C4",
                              borderRadius: "10px",
                            }}
                          >
                            <SoftTypography
                              fontSize="20px"
                              fontWeight="600"
                              lineHeight="19px"
                              margin="16px"
                              marginLeft="10px"
                            >
                              {translate('selectpref')}       


                            </SoftTypography>


                            <Grid container spacing={2} sx={{ maxWidth: "900px" }}>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                  Channel Partner
                </SoftTypography>
                <Select
                  isClearable
                  isMulti
                  // placeholder="Search...."
                  value={
                    cplabel !== ""
                      ? cplabel?.split(",").map(
                          (ele) =>
                            ele !== "null" && {
                              label: ele,
                              value: ele,
                            }
                        )
                      : multiCps
                  }
                  placeholder="Select channel partner"
                  options={cpList}
                  // onChange={(e) => setSelectedDSF(e.value)}
                  onChange={(e) => {
                    if (e) {
                      setMultiCps(e.map((item) => item));
                    } else {
                      setMultiCps([]);
                    }
                    setSelectedCPs(
                      e
                        .map((ele) => ele)
                        .map((item) => item.value)
                        .join()
                    );
                    setCPLabel(
                      e
                        .map((ele) => ele)
                        .map((item) => item.label)
                        .join()
                    );
                  }}
                  styles={stylesDD}
                />

                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Data Source
                                  </SoftTypography>

                                  <Select
                                    isClearable
                                    isMulti
                                    // placeholder="Search...."
                                    value={
                                      DSLabel !== ""
                                        ? DSLabel?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : multiDS
                                    }
                                    placeholder="Select data source"
                                    options={JSFilterdataSource}
                                    // onChange={(e) => setSelectedDSF(e.value)}
                                    onChange={(e) => {
                                      if (e) {
                                        setMultiDS(e.map((item) => item));
                                      } else {
                                        setMultiDS([]);
                                      }
                                      setSelectedDSF(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                      setDSLabel(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.label)
                                          .join()
                                      );
                                    }}
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <br />

                          <Box
                            sx={{
                              p: 1,
                              background: "rgba(27, 89, 248, 0.05)",
                              border: "1px solid #C4C4C4",
                              borderRadius: "10px",
                            }}
                          >
                            <Grid container spacing={2} sx={{ maxWidth: "900px" }}>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    State
                                  </SoftTypography>

                                  <Select
                                    placeholder="Select state"
                                    isClearable
                                    value={
                                      selectedFilterState !== ""
                                        ? { label: selectedFilterState, value: selectedFilterState }
                                        : null
                                    }
                                    // options={stateData}
                                    options={filterState}
                                    onChange={(e) =>
                                      e
                                        ? setSelectedFilterState(e.value)
                                        : setSelectedFilterState("")
                                    }
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    District
                                  </SoftTypography>

                                  <Select
                                    isMulti
                                    placeholder="Select districts"
                                    // value={
                                    //   selectedFilterDistrict !== ""
                                    //     ? { label: selectedFilterDistrict, value: selectedFilterDistrict }
                                    //     : null
                                    // }
                                    // options={districtData}
                                    options={district}
                                    styles={stylesDD}
                                    value={
                                      selectedFilterDistrict !== "" &&
                                      selectedFilterDistrict !== null
                                        ? selectedFilterDistrict?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : multiDistrict
                                    }
                                    onChange={(e) => {
                                      // console.log("district:");
                                      if (e) {
                                        setMultiDistrict(e.map((item) => item));
                                      } else {
                                        setMultiDistrict([]);
                                      }
                                      setSelectedFilterDistrict(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Role
                                  </SoftTypography>

                                  <Select
                                    placeholder="Select roles"
                                    isMulti
                                    options={jobrole}
                                    value={
                                      desgination !== ""
                                        ? desgination?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : multiRole
                                    }
                                    onChange={(e) => {
                                      console.log("multi desig:", e);
                                      if (e) {
                                        setMultiRole(e.map((item) => item));
                                      } else {
                                        setMultiRole([]);
                                      }
                                      setDesignation(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Qualification
                                  </SoftTypography>

                                  <Select
                                    isMulti
                                    options={education}
                                    value={
                                      qualification !== ""
                                        ? qualification?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : multiEducation
                                    }
                                    placeholder="Select qualifications"
                                    onChange={(e) => {
                                      if (e) {
                                        setMultiEducation(e.map((item) => item));
                                      } else {
                                        setMultiEducation([]);
                                      }
                                      setQualification(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Stream
                                  </SoftTypography>

                                  <Select
                               
                                    options={streamdata}
                                    isMulti
                                    value={
                                      stream !== ""
                                        ? stream?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : multiStream
                                    }
                                    placeholder="Select streams"
                                    onChange={(e) => {
                                      if (e) {
                                        setMultiStream(e.map((item) => item));
                                      } else {
                                        setMultiStream([]);
                                      }
                                      setStream(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Specialization
                                  </SoftTypography>

                                  <Select
                                   
                                    isMulti
                                    placeholder="Select specializations"
                                    options={degree}
                                    value={
                                      specialization !== ""
                                        ? specialization?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : multiSpecialization
                                    }
                                    onChange={(e) => {
                                      if (e) {
                                        setMultiSpecialization(e.map((item) => item));
                                      } else {
                                        setMultiSpecialization([]);
                                      }
                                      setSpecialization(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Experience
                                  </SoftTypography>

                                  <Select
                                    isClearable
                                    placeholder="Select experience"
                                    value={
                                      experience !== ""
                                        ? experience?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : null
                                    }
                                    options={exp}
                                    onChange={(e) =>
                                      e ? setExperirnce(e.value) : setExperirnce("")
                                    }
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Salary
                                  </SoftTypography>

                                  <Select
                                    isClearable
                                    isMulti
                                    placeholder="Select salary"
                                    value={
                                      salary !== ""
                                        ? salary?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : multiSalary
                                    }
                                    options={salaryList}
                                    // onChange={(e) => (e ? setSalary(e.value) : setSalary(""))}
                                    onChange={(e) => {
                                      if (e) {
                                        setMultiSalary(e.map((item) => item));
                                      } else {
                                        setMultiSalary([]);
                                      }
                                      setSalary(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Gender
                                  </SoftTypography>

                                  <Select
                                    isClearable
                                    isMulti
                                    placeholder="Select gender"
                                    options={genderOption}
                                    value={
                                      gendertype !== ""
                                        ? gendertype?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : multigender
                                    }
                                    onChange={(e) => {
                                      if (e) {
                                        setMultigender(e.map((item) => item));
                                      } else {
                                        setMultigender([]);
                                      }
                                      setGenderType(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                    styles={stylesDD}
                                  />
                                </Box>
                              </Grid>

                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 1 }}>
                                  <SoftTypography
                                    fontSize="15px"
                                    fontWeight="small"
                                    lineHeight="19px"
                                  >
                                    Age: {`${age[0]} - ${age[1]}`}
                                  </SoftTypography>

                                  <ThemeProvider theme={TermSlider}>
                                    <Slider
                                      // name="termValue"
                                      getAriaLabel={() => "Minimum distance shift"}
                                      valueLabelDisplay="auto"
                                      // aria-label="Default"
                                      // defaultValue={[26 - 36]}
                                      min={18}
                                      max={65}
                                      value={age}
                                      disableSwap
                                      onChange={handleAgeChange}
                                    />
                                  </ThemeProvider>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <br />

                          <SoftBox
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-start"
                            gap="25px"
                          >
                            <SoftBox
                              sx={{
                                height: "40px",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "8px",
                                border: "1px solid #3B5092",
                                cursor: "pointer",
                              }}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width={{ lg: "150px", sm: "100%", xs: "50%" }}
                              onClick={() => (setFiltershow(false), resetFunc())}
                            >
                              <SoftTypography
                                display="flex"
                                justifyContent="center"
                                sx={{
                                  color: "#3B5092",
                                  fontSize: "16px",
                                  fontWeight: "small",
                                  lineHeight: "20px",
                                }}
                              >
              {translate('reset')}       
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              sx={{
                                height: "40px",
                                backgroundColor: "#3B5092",
                                borderRadius: "100px",
                                cursor: "pointer",
                                borderRadius: "8px",
                              }}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width={{ lg: "150px", sm: "100%", xs: "50%" }}
                              onClick={() => (
                                setFiltershow(false),
                                filterOffsetFunc(),
                                fetchFilteredCandidates(),
                                setFunc()
                              )}
                            >
                              <SoftTypography
                                display="flex"
                                justifyContent="center"
                                sx={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontWeight: "small",
                                  lineHeight: "20px",
                                }}
                              >
              {translate('applyfilter')}       
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Box>
                      </>
                    )}
                  </SoftBox>
                </SoftBox>
              </>

              <SoftBox mt={1} width="100%" height="auto" display="flex">
                <SoftBox
                  // width={showFilter ? "70%" : "100%"}
                  width="100%"
                ></SoftBox>
                <SoftBox
                  minWidth="0%"
                  ml={2}
                  // display={!showFilter && "none"}
                  display="none"
                >
                  <Grid container>
                    <SoftBox mt={2} width="100%" height="auto">
                      <Card sx={{ overflow: "visible" }}>
                        <SoftBox display="flex" flexDirection="column" gap="10px">
                          <SoftBox display="flex" flexDirection="column" gap="20px" p={3}>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                Data Source
                              </SoftTypography>
                           
                              <Select
                                isClearable
                                isMulti
                                // placeholder="Search...."
                                value={multiDS}
                                placeholder="select data source"
                                options={JSFilterdataSource}
                                // onChange={(e) => setSelectedDSF(e.value)}
                                onChange={(e) => {
                                  if (e) {
                                    setMultiDS(e.map((item) => item));
                                  } else {
                                    setMultiDS([]);
                                  }
                                  setSelectedDSF(
                                    e
                                      .map((ele) => ele)
                                      .map((item) => item.value)
                                      .join()
                                  );
                                }}
                                styles={stylesDD}
                              />
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                Channel Partner
                              </SoftTypography>
                              <Select
                                isClearable
                                isMulti
                                // placeholder="Search...."
                                value={
                                  cplabel !== ""
                                    ? cplabel?.split(",").map(
                                        (ele) =>
                                          ele !== "null" && {
                                            label: ele,
                                            value: ele,
                                          }
                                      )
                                    : multiCps
                                }
                                placeholder="Select channel partner"
                                options={cpList}
                                onChange={(e) => {
                                  if (e) {
                                    setMultiCps(e.map((item) => item));
                                  } else {
                                    setMultiCps([]);
                                  }
                                  setSelectedCPs(
                                    e
                                      .map((ele) => ele)
                                      .map((item) => item.value)
                                      .join()
                                  );
                                  setCPLabel(
                                    e
                                      .map((ele) => ele)
                                      .map((item) => item.label)
                                      .join()
                                  );
                                }}
                                styles={stylesDD}
                              />
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                State
                              </SoftTypography>
                              {/* <SoftSelect
                              placeholder="Search...."
                              // width="128px"
                              // height="32px"
                              // sx={{
                              //   borderRadius: "100px",
                              //   marginTop: "10px",
                              //   border: "1px solid #3B5092",
                              //   backgroundColor: "#F5F5F5",
                              // }}
                              // tags={tags}
                              value={state !== "" ? { label: state, value: state } : null}
                              options={stateData}
                              onChange={(e) => setSelectState(e.value)}
                            /> */}
                              <Select
                                isClearable
                                placeholder="Search...."
                                value={
                                  selectedFilterState !== ""
                                    ? { label: selectedFilterState, value: selectedFilterState }
                                    : null
                                }
                                // options={stateData}
                                options={filterState}
                                onChange={(e) =>
                                  e ? setSelectedFilterState(e.value) : setSelectedFilterState("")
                                }
                                styles={stylesDD}
                              />
                              {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                District
                              </SoftTypography>
                              {/* <SoftSelect
                              placeholder="Search...."
                              // width="128px"
                              // height="32px"
                              // sx={{
                              //   borderRadius: "100px",
                              //   marginTop: "10px",
                              //   border: "1px solid #3B5092",
                              //   backgroundColor: "#F5F5F5",
                              // }}
                              // tags={tags}
                              value={district !== "" ? { label: district, value: district } : null}
                              options={districtData}
                              onChange={(e) => setSelectedDistrict(e.value)}
                            /> */}
                              <Select
                                isMulti
                                placeholder="Search...."
                                // value={
                                //   selectedFilterDistrict !== ""
                                //     ? { label: selectedFilterDistrict, value: selectedFilterDistrict }
                                //     : null
                                // }
                                // options={districtData}
                                options={filterDistrict}
                                // onChange={(e) => setSelectedFilterDistrict(e.value)}
                                styles={stylesDD}
                                value={multiDistrict}
                                onChange={(e) => {
                                  if (e) {
                                    setMultiDistrict(e.map((item) => item));
                                  } else {
                                    setMultiDistrict([]);
                                  }
                                  setSelectedFilterDistrict(
                                    e
                                      .map((ele) => ele)
                                      .map((item) => item.value)
                                      .join()
                                  );
                                }}
                              />

                              {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                Designation
                              </SoftTypography>
                              {/* <SoftSelect
                              placeholder="Search...."
                              value={
                                desgination !== ""
                                  ? { label: desgination, value: desgination }
                                  : null
                              }
                              options={jobrole}
                              onChange={(e) => setDesignation(e.value)}
                            /> */}
                              <Select
                                // placeholder="Please enter Job Title"

                                // value={
                                //   desgination !== ""
                                //     ? { label: desgination, value: desgination }
                                //     : null
                                // }
                                // onChange={(e) => setDesignation(e.value)}
                                placeholder="Search...."
                                isMulti
                                options={jobrole}
                                value={multiRole}
                                onChange={(e) => {
                                  if (e) {
                                    setMultiRole(e.map((item) => item));
                                  } else {
                                    setMultiRole([]);
                                  }
                                  setDesignation(
                                    e
                                      .map((ele) => ele)
                                      .map((item) => item.value)
                                      .join()
                                  );
                                }}
                                styles={stylesDD}
                              />

                              {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                Qualification
                              </SoftTypography>
                              {/* <SoftSelect
                              placeholder="Search...."
                              value={
                                qualification !== ""
                                  ? { label: qualification, value: qualification }
                                  : null
                              }
                              options={education}
                              onChange={(e) => setQualification(e.value)}
                            /> */}
                              <Select
                                // placeholder="Please enter Job Title"
                                // value={
                                //   qualification !== ""
                                //     ? { label: qualification, value: qualification }
                                //     : null
                                // }
                                // onChange={(e) => setQualification(e.value)}
                                isMulti
                                options={education}
                                value={multiEducation}
                                placeholder="Search...."
                                onChange={(e) => {
                                  if (e) {
                                    setMultiEducation(e.map((item) => item));
                                  } else {
                                    setMultiEducation([]);
                                  }
                                  setQualification(
                                    e
                                      .map((ele) => ele)
                                      .map((item) => item.value)
                                      .join()
                                  );
                                }}
                                styles={stylesDD}
                              />

                              {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                Stream
                              </SoftTypography>
                              {/* <SoftSelect
                              placeholder="Search...."
                              value={stream !== "" ? { label: stream, value: stream } : null}
                              options={streamdata}
                              onChange={(e) => setStream(e.value)}
                            /> */}
                              <Select
                                // placeholder="Please enter Job Title"

                                // value={stream !== "" ? { label: stream, value: stream } : null}
                                // onChange={(e) => setStream(e.value)}
                                options={streamdata}
                                isMulti
                                value={multiStream}
                                placeholder="Search...."
                                onChange={(e) => {
                                  if (e) {
                                    setMultiStream(e.map((item) => item));
                                  } else {
                                    setMultiStream([]);
                                  }
                                  setStream(
                                    e
                                      .map((ele) => ele)
                                      .map((item) => item.value)
                                      .join()
                                  );
                                }}
                                styles={stylesDD}
                              />
                              {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                Specialization
                              </SoftTypography>
                              {/* <SoftSelect
                              placeholder="Search...."
                              value={
                                specialization !== ""
                                  ? { label: specialization, value: specialization }
                                  : null
                              }
                              options={degree}
                              onChange={(e) => setSpecialization(e.value)}
                            /> */}
                              <Select
                                // placeholder="Please enter Job Title"
                                // value={
                                //   specialization !== ""
                                //     ? { label: specialization, value: specialization }
                                //     : null
                                // }
                                // onChange={(e) => setSpecialization(e.value)}
                                isMulti
                                placeholder="Search...."
                                options={degree}
                                value={multiSpecialization}
                                onChange={(e) => {
                                  if (e) {
                                    setMultiSpecialization(e.map((item) => item));
                                  } else {
                                    setMultiSpecialization([]);
                                  }
                                  setSpecialization(
                                    e
                                      .map((ele) => ele)
                                      .map((item) => item.value)
                                      .join()
                                  );
                                }}
                                styles={stylesDD}
                              />
                              {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                Experience
                              </SoftTypography>
                              {/* <SoftSelect
                              placeholder="Search...."
                              value={
                                experience !== "" ? { label: experience, value: experience } : null
                              }
                              options={exp}
                              onChange={(e) => setExperirnce(e.value)}
                            /> */}
                              <Select
                                isClearable
                                placeholder="Search...."
                                value={
                                  experience !== ""
                                    ? { label: experience, value: experience }
                                    : null
                                }
                                options={exp}
                                onChange={(e) => (e ? setExperirnce(e.value) : setExperirnce(""))}
                                styles={stylesDD}
                              />
                              {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography fontSize="15px" fontWeight="small" lineHeight="19px">
                                Salary
                              </SoftTypography>
                              {/* <SoftSelect
                              placeholder="Search...."
                              value={salary !== "" ? { label: salary, value: salary } : null}
                              options={salaryList}
                              onChange={(e) => setSalary(e.value)}
                            /> */}
                              <Select
                                isClearable
                                placeholder="Search...."
                                value={salary !== "" ? { label: salary, value: salary } : null}
                                options={salaryList}
                                onChange={(e) => (e ? setSalary(e.value) : setSalary(""))}
                                styles={stylesDD}
                              />
                              {/* <SoftInput type="text"  placeholder="Search...." width="128px" height="32px" sx={{borderRadius:"100px",marginTop:"10px",border:"1px solid #3B5092",backgroundColor:"#F5F5F5"}} /> */}
                            </SoftBox>
                          </SoftBox>
                          <SoftBox
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            gap="5px"
                            p={3}
                          >
                            {/* <SoftBox pl={2} mx={2} sx={{ height: "40px", backgroundColor: "#008717", borderRadius: "100px", cursor: "pointer" }}
                                                        // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                                                        // p="10px 25px"
                                                        display="flex" justifyContent="center" alignItems="center"
                                                        width={{ lg: "144px", sm: "100%", xs: "50%" }}
                                                        onClick={() => setShowFilter(false)}
                                                    >
                                                        <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "16px", fontWeight: "small", lineHeight: "20px" }}>
                                                            Save Searches
                                                        </SoftTypography>
                                                    </SoftBox > */}
                            <SoftBox
                              sx={{
                                height: "40px",
                                backgroundColor: "#3B5092",
                                borderRadius: "100px",
                                cursor: "pointer",
                              }}
                              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                              // p="10px 25px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width={{ lg: "150px", sm: "100%", xs: "50%" }}
                              onClick={() => (
                                // setSelectState(""),
                                setSelectedFilterState(""),
                                setSelectedFilterDistrict(""),
                                setDesignation(""),
                                setQualification(""),
                                setStream(""),
                                setSpecialization(""),
                                setExperirnce(""),
                                setSalary(""),
                                // fatchcancidate()
                                fetchFilteredCandidates()
                              )}
                            >
                              <SoftTypography
                                display="flex"
                                justifyContent="center"
                                sx={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontWeight: "small",
                                  lineHeight: "20px",
                                }}
                              >
              {translate('reset')}       
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              sx={{
                                height: "40px",
                                backgroundColor: "#3B5092",
                                borderRadius: "100px",
                                cursor: "pointer",
                              }}
                              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                              // p="10px 25px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width={{ lg: "150px", sm: "100%", xs: "50%" }}
                              onClick={() => (
                                setShowFilter(false),
                                // fatchcancidate(),
                                fetchFilteredCandidates()
                              )}
                            >
                              <SoftTypography
                                display="flex"
                                justifyContent="center"
                                sx={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontWeight: "small",
                                  lineHeight: "20px",
                                }}
                              >
              {translate('applyfilter')}       
                              </SoftTypography>
                            </SoftBox>
                        
                          </SoftBox>
                        </SoftBox>
                      </Card>
                    </SoftBox>
                  </Grid>
                </SoftBox>
              </SoftBox>

             
            </SoftBox>
          
          </SoftBox>
          {/* </Card> */}
        </SoftBox>

        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {colHeaders.map((ele, index) => (
                <>
                  <ul key={index} className="UlCSV">
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
                  {/* <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                      Download
                    </SoftTypography> */}
                  <CSVLink
                    data={tablerow.length === 0 ? searchData : tablerow}
                    // filename={"my-file.csv"}
                    filename={fileName}
                    headers={colHeaders.filter((ele) => ele.isExportable === true)}
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
          {/*
          <Modal.Footer>
            <SoftBox
              pl={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "45px",
                backgroundColor: "#fff",
                border: "1px solid",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width={{ lg: "225px", sm: "100%", xs: "50%" }}
            >
              <CSVLink
                data={searchData}
                // filename={"my-file.csv"}
                filename={fileName}
                headers={colHeaders.filter((ele) => ele.isExportable === true)}
              >
                export
              </CSVLink>
            </SoftBox>
          </Modal.Footer>
          </>*/}
        </Modal>
        {/* modal for export */}

        {/* Modat 1 */}
        <Modal
          show={actionModal2}
          onHide={() => {
            setactionModal2(false);
            // showAlert();
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Select from below options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SoftBox display="flex" flexDirection="row" gap="20px">
              {!showFile && (
                <>
                  <SoftBox
                    // pl={2}
                    margin="auto"
                    sx={{
                      height: "35px",
                      backgroundColor: "#3B5092",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width="150px"
                    p="10px 20px"
                    // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      // navigate('/search/newsearch/recommend-search/allshortlist');
                      //activate modal2
                      // setShowModal2(true);
                      setactionModal(false);
                      setInstructionModal(true);
                    }}
                  >
                    <Link to="/Data-Upload-Template.xlsx" target="_blank" download>
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "medium",
                          lineHeight: "15px",
                        }}
                      >
                        Download Template
                      </SoftTypography>
                    </Link>
                  </SoftBox>
                  <SoftBox
                    // pl={2}
                    margin="auto"
                    sx={{
                      height: "35px",
                      backgroundColor: "#3B5092",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width="150px"
                    p="10px 20px"
                    // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      // navigate('/search/newsearch/recommend-search/allshortlist');
                      //activate modal3
                      // setShowModal3(true);
                      setactionModal(false);
                      setShowFile(true);
                    }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "medium",
                        lineHeight: "15px",
                      }}
                    >
                      Bulk Upload
                    </SoftTypography>
                  </SoftBox>
                </>
              )}
              {showFile && (
                //   <SoftBox margin="auto" width="150px" p="10px 20px" display="flex" flexDirection="row">
                <SoftBox display="flex" flexDirection="column" gap="20px" width="100%">
                  {/* <SoftInput
                    type="file"
                    //   accept="file/csv"
                    onChange={(e) => {
                      // const file = e.target.files[0],
                      console.log("resumefile ", e.target.files[0]), setCSVFile(e.target.files[0]);
                    }}
                  /> */}
                  <input
                    className="form-control"
                    type="file"
                    //accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                    id="datasource"
                    name="datasource"
                    onChange={(e) => {
                      // const file = e.target.files[0],
                      console.log("resumefile ", e.target.files[0]), setCSVFile(e.target.files[0]);
                    }}
                  />
                  <SoftBox display="flex" flexDirection="column">
                    <SoftBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      // gap="10px"
                      // onClick={() => {
                      //   setCreate(!create);
                      // }}
                    >
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Data Source
                      </SoftTypography>
                      <SoftBox
                        display="flex"
                        flexDirection="row"
                        gap="10px"
                        onClick={() => {
                          setCreateDS(!createDS);
                          setSelectedDS("");
                        }}
                      >
                        <Tooltip
                          key="6"
                          title={!createDS ? `Add new data source` : `Select From Existing `}
                          placement="bottom"
                        >
                          <SoftBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ cursor: "pointer" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15px"
                              height="15px"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <rect width="10" height="10" rx="5" fill="#3B5092" />
                              <path
                                d="M5 2V8M2 5H8"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </SoftBox>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                    {createDS ? (
                      <SoftInput
                        type="text"
                        placeholder="Data Source"
                        // name="job_title"
                        // id="job_title"
                        onChange={(e) => (
                          setSelectedDS(e.target.value), console.log("sel:", selectedDS)
                        )}
                        value={selectedDS ? selectedDS : ""}
                      />
                    ) : (
                      <SoftSelect
                        options={dataSource}
                        onChange={(e) => setSelectedDS(e ? e.value : "")}
                      />
                    )}
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="row" gap="10px">
                    <SoftBox
                      margin="auto"
                      sx={{
                        height: "35px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      width="150px"
                      p="10px 20px"
                      onClick={bulkUploadfunc}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "medium",
                          lineHeight: "15px",
                        }}
                      >
                        Upload
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      margin="auto"
                      sx={{
                        height: "35px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      width="150px"
                      p="10px 20px"
                      onClick={() => setShowFile(false)}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "medium",
                          lineHeight: "15px",
                        }}
                      >
                        Back
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  {/* </SoftBox> */}
                </SoftBox>
              )}
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* Modat 1 */}

        {/* instruction modal */}
        <Modal
          show={instructionModal}
          onHide={() => {
            setInstructionModal(false);
            // showAlert();
          }}
          size="xl"
          centered
          // sx={{ marginTop: "50px" }}
          // mt="78px"
        >
          <Modal.Body>
            <SoftBox width="100%" display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton
                onClick={() => {
                  setInstructionModal(false);
                }}
              >
                <CloseIcon size="small" />
              </IconButton>
            </SoftBox>

            <SoftBox
              sx={{
                border: "1px solid #6c6c6c",
                borderRadius: "10px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <SoftTypography fontSize="20px" fontWeight="medium" marginTop="12px">
                Guidelines:
              </SoftTypography>
              <SoftTypography fontSize="16px">
                While uploading the file (only excel files accepted), you have to make sure that the
                following fields with exact headers are present. The system will accept files only
                if the following headers are present. Even if there is no data in any of the
                headers, the data will still be accepted (try to put as much data as possible). Any
                data without mobile number (in 10 digits) will be rejected.
              </SoftTypography>
              <br />

              <SoftTypography fontStyle="12px" fontSize="16px">
                <ul>
                  <li>First Name/ Name</li>
                  <li>Date of Birth (DD-MM-YYYY)</li>
                  <li>Mobile Number</li>
                  <li>District</li>
                  <li>State</li>
                  <li>Highest Education</li>
                </ul>
              </SoftTypography>
              <br />
            </SoftBox>

            <SoftBox sx={{ marginTop: "10px" }}>
              <SoftTypography fontSize="16px" marginBottom="16px">
                The following section explains how all the data should be entered.
              </SoftTypography>
              <Table columns={colGuidelines} dataSource={GuideData} pagination={false} mb={2} />
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/*instruction modal */}

        {/* Add Job seeker modal */}
        <Modal
          show={JSModal}
          onHide={() => {
            setJSModal(false);
            // setactionModal2(false);
            // showAlert();
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Choose type of upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SoftBox display="flex" flexDirection="row" gap="20px">
              {!showFile && (
                <>
                  <SoftBox
                    // pl={2}
                    margin="auto"
                    sx={{
                      height: "35px",
                      backgroundColor: "#3B5092",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width="150px"
                    p="10px 20px"
                    // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      // navigate("/jobseekar-registration");
                      setJSModal(false);
                    }}
                  >
                    <Link to="https://seeker.globalsapio.com/" target="_blank">
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "medium",
                          lineHeight: "15px",
                        }}
                      >
                        Register Job Seeker
                      </SoftTypography>
                    </Link>
                  </SoftBox>
                  <SoftBox
                    // pl={2}
                    margin="auto"
                    sx={{
                      height: "35px",
                      backgroundColor: "#3B5092",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width="150px"
                    p="10px 20px"
                    // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      // navigate('/search/newsearch/recommend-search/allshortlist');
                      //activate modal3
                      // setShowModal3(true);
                      // setactionModal(false);
                      // setShowFile(true);
                      setactionModal2(true);
                      setJSModal(false);
                    }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "medium",
                        lineHeight: "15px",
                      }}
                    >
                      Bulk Upload
                    </SoftTypography>
                  </SoftBox>
                </>
              )}
              {showFile && (
                //   <SoftBox margin="auto" width="150px" p="10px 20px" display="flex" flexDirection="row">
                <SoftBox display="flex" flexDirection="column" gap="20px" width="100%">
                  <SoftInput
                    type="file"
                    //   accept="file/csv"
                    onChange={(e) => {
                      // const file = e.target.files[0],
                      console.log("resumefile ", e.target.files[0]), setCSVFile(e.target.files[0]);
                    }}
                  />
                  <SoftSelect options={dataSource} onChange={(e) => setSelectedDS(e.value)} />
                  <SoftBox display="flex" flexDirection="row" gap="10px">
                    <SoftBox
                      margin="auto"
                      sx={{
                        height: "35px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      width="150px"
                      p="10px 20px"
                      onClick={bulkUploadfunc}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "medium",
                          lineHeight: "15px",
                        }}
                      >
                        Upload
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox
                      margin="auto"
                      sx={{
                        height: "35px",
                        backgroundColor: "#3B5092",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      width="150px"
                      p="10px 20px"
                      onClick={() => setShowFile(false)}
                    >
                      <SoftTypography
                        display="flex"
                        justifyContent="center"
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "medium",
                          lineHeight: "15px",
                        }}
                      >
                        Back
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  {/* </SoftBox> */}
                </SoftBox>
              )}
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* Add Job seeker modal */}

        {/* shortlist Modat 1 */}
        <Modal show={showshortlistModal1} onHide={handleCloseShortlistModal1} centered>
          <Modal.Header closeButton>
            <Modal.Title>Select from below options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SoftBox display="flex" flexDirection="row" gap="20px">
              <SoftBox
                // pl={2}
                margin="auto"
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width="126px"
                p="10px 20px"
                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  // navigate('/search/newsearch/recommend-search/allshortlist');
                  //activate modal2
                  // setShowModal2(true);
                  // setShowModal1(false);
                  setShowShortlistModal2(true);
                  setShowShortlistModal1(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "medium",
                    lineHeight: "15px",
                  }}
                >
                  Existing Bucket
                </SoftTypography>
              </SoftBox>
              <SoftBox
                // pl={2}
                margin="auto"
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width="126px"
                p="10px 20px"
                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                onClick={() => {
                  // navigate('/search/newsearch/recommend-search/allshortlist');
                  //activate modal3
                  setShowShortlistModal3(true);
                  setShowShortlistModal1(false);
                }}
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "medium",
                    lineHeight: "15px",
                  }}
                >
                  Create Bucket
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Modal.Body>
          {/* <Modal.Footer  > */}
          {/* <SoftBox 
                                // pl={2} 
                                margin="auto"
                                sx={{ height: "35px", backgroundColor: "#3B5092", borderRadius: "10px", cursor: "pointer" }} 
                                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                width="126px" p="10px 20px"
                                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                onClick={()=>{
                                    navigate('/search/newsearch/recommend-search/allshortlist');
                                }}
                            >
                                <SoftTypography display="flex" justifyContent="center" 
                                    sx={{ color: "#fff", fontSize: "12px", fontWeight: "medium", lineHeight: "15px" }}
                                >
                                Create Bucket
                                </SoftTypography>
                            </SoftBox > */}
          {/* <Button variant="secondary" onClick={handleClose}>
                                Create
                            </Button> */}
          {/* <Button variant="primary" onClick={handleClose}>
                                Save Changes
                            </Button> */}
          {/* </Modal.Footer> */}
        </Modal>
        {/* shortlist Modat 1 */}

        {/* Shorlist Modal 2 */}
        <Modal
          show={showShortlistModal3}
          onHide={handleCloseShortlistModal3}
          size="xl"
          centered
          style={{ marginBottom: "75px" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create a bucket</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SoftBox
              display="flex"
              flexDirection="column"
              gap="20px"
              // width="750px"
              // marginTop="20px"
              // marginRight="10px"
              padding="14px 30px 30px"
            >
              <SoftBox mb={3}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftBox
                        width="100%"
                        // pl={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <SoftBox display="flex" flexDirection="row">
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Organization Name
                          </SoftTypography>
                          <SoftBox
                            sx={{
                              color: "red",
                              position: "inherit",
                              paddingLeft: "2px",
                              fontSize: "15px",
                            }}
                          >
                            <sup>*</sup>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          display="flex"
                          flexDirection="row"
                          gap="10px"
                          onClick={() => {
                            navigate("/jobprovider-registration");
                          }}
                        >
                          <Tooltip key="1" title="Add a new organisation" placement="bottom">
                            <SoftBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <rect width="10" height="10" rx="5" fill="#3B5092" />
                                <path
                                  d="M5 2V8M2 5H8"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </SoftBox>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                      <SoftSelect
                        placeholder="Select organization name"
                        options={orgList}
                        value={orgList.filter((ele) => ele.value === orgNameID)}
                        onChange={(e) => (
                          jobpostform.setFieldValue("org_name", e.label), setOrgNameID(e.value)
                        )}
                      />
                      {jobpostform.touched.org_name && jobpostform.errors.org_name ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.org_name}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftBox
                        width="100%"
                        // pl={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <SoftBox display="flex" flexDirection="row">
                          <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px">
                            Job Title
                          </SoftTypography>
                          <SoftBox
                            sx={{
                              color: "red",
                              position: "inherit",
                              paddingLeft: "2px",
                              fontSize: "15px",
                            }}
                          >
                            <sup>*</sup>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          display="flex"
                          flexDirection="row"
                          gap="10px"
                          onClick={() => {
                            setCreate(!create);
                          }}
                        >
                          <Tooltip
                            key="2"
                            title={!create ? `Create New Job Title` : `Select From Existing `}
                            placement="bottom"
                          >
                            <SoftBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <rect width="10" height="10" rx="5" fill="#3B5092" />
                                <path
                                  d="M5 2V8M2 5H8"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </SoftBox>
                          </Tooltip>
                          {/* <SoftTypography
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  fontWeight="medium"
                                  fontSize="10px"
                                  lineHeight="17px"
                                >
                                  {!create ? `Create New Job Title` : `Select From Existing `}
                                </SoftTypography> */}
                        </SoftBox>
                      </SoftBox>
                      {/* <Card><SoftSelect options={reporting} /></Card> */}
                      {create ? (
                        <SoftInput
                          type="text"
                          placeholder="Job Title..."
                          name="job_title"
                          id="job_title"
                          onChange={jobpostform.handleChange}
                          value={jobpostform.values.job_title}
                        />
                      ) : (
                        // <Card>
                        <SoftSelect
                          placeholder="Please enter Job Title"
                          options={jobTitle}
                          onChange={
                            (e) => jobpostform.setFieldValue("job_title", e.label)
                            // setOrgNameID(e.value)
                          }
                        />
                        // </Card>
                      )}
                      {jobpostform.touched.job_title && jobpostform.errors.job_title ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.job_title}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mb={3}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Job Type
                      </SoftTypography>
                      {/* <Card> */}
                      <SoftSelect
                        placeholder="Select job type"
                        options={jobType}
                        onChange={(e) => {
                          jobpostform.setFieldValue("job_type", e.label);
                        }}
                      />
                      {/* </Card> */}
                      {jobpostform.touched.job_type && jobpostform.errors.job_type ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.job_type}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftBox display="flex">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Date of Posting
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftInput
                        type="date"
                        placeholder=""
                        name="jobpostdt"
                        id="jobpostdt"
                        onChange={jobpostform.handleChange}
                        value={jobpostform.values.jobpostdt}
                      />
                      {jobpostform.touched.jobpostdt && jobpostform.errors.jobpostdt ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.jobpostdt}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mb={3}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        No.of Professional
                      </SoftTypography>
                      <SoftInput
                        type="number"
                        placeholder=""
                        name="applicantct"
                        id="applicantct"
                        onChange={jobpostform.handleChange}
                        value={jobpostform.values.applicantct}
                      />
                      {jobpostform.touched.applicantct && jobpostform.errors.applicantct ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.applicantct}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Role
                      </SoftTypography>
                      {/* <Card> */}
                      <SoftSelect
                        placeholder="Select Role"
                        options={jobrolenew}
                        onChange={(e) => {
                          jobpostform.setFieldValue("designation", e.label);
                        }}
                      />
                      {/* </Card> */}
                      {jobpostform.touched.designation && jobpostform.errors.designation ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.designation}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mb={3}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftBox display="flex">
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Valid Till
                        </SoftTypography>
                        <SoftBox
                          sx={{
                            color: "red",
                            position: "inherit",
                            paddingLeft: "2px",
                            fontSize: "15px",
                          }}
                        >
                          <sup>*</sup>
                        </SoftBox>
                      </SoftBox>
                      <SoftInput
                        type="datetime-local"
                        placeholder=""
                        name="jobvaliddt"
                        id="jobvaliddt"
                        onChange={jobpostform.handleChange}
                        value={jobpostform.values.jobvaliddt}
                      />
                      {jobpostform.touched.jobvaliddt && jobpostform.errors.jobvaliddt ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.jobvaliddt}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Expected Salary
                      </SoftTypography>
                      <SoftSelect
                        options={salaryList}
                        placeholder="Select salary range"
                        onChange={(e) => {
                          jobpostform.setFieldValue("salary", e.label);
                        }}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mb={1}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2} display="flex">
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            Setallstate(true);
                          } else {
                            Setallstate(false);
                          }
                        }}
                      />
                      <SoftTypography
                        fontWeight="medium"
                        fontSize="16px"
                        lineHeight="20px"
                        ml={2}
                        mt={1}
                      >
                        Pan India
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              {!allstate && (
                <SoftBox mb={3}>
                  <Grid container spacing={8}>
                    <Grid item xs={12} lg={6} container spacing={3}>
                      <SoftBox width="100%" pl={2}>
                        <SoftBox display="flex" flexDirection="row">
                          <SoftTypography
                            fontWeight="medium"
                            fontSize="16px"
                            lineHeight="20px"
                            mb={1}
                          >
                            State
                          </SoftTypography>
                          <SoftBox
                            sx={{
                              color: "red",
                              position: "inherit",
                              paddingLeft: "2px",
                              fontSize: "15px",
                            }}
                          >
                            <sup>*</sup>
                          </SoftBox>
                        </SoftBox>
                        {/* <Card> */}
                        <SoftSelect
                          options={state}
                          onChange={(currentTarget) => (
                            setSelectedState(currentTarget.value),
                            !allstate ? jobpostform.setFieldValue("state", currentTarget.value) : ""
                          )}
                        />
                        {/* </Card> */}
                        {jobpostform.touched.state && jobpostform.errors.state ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {jobpostform.errors.state}{" "}
                          </span>
                        ) : null}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} lg={6} container spacing={3}>
                      <SoftBox width="100%" pl={2}>
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          District
                        </SoftTypography>
                        {/* <Card> */}
                        <SoftSelect
                          options={district}
                          onChange={(e) =>
                            !allstate ? jobpostform.setFieldValue("district", e.value) : ""
                          }
                        />
                        {/* </Card> */}
                        {jobpostform.touched.district && jobpostform.errors.district ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {jobpostform.errors.district}{" "}
                          </span>
                        ) : null}
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              )}
              <SoftBox mb={3}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Highest Education
                      </SoftTypography>
                      {/* <Card> */}
                      <SoftSelect
                        options={education}
                        onChange={(e) => jobpostform.setFieldValue("qualification", e.value)}
                      />
                      {/* </Card> */}
                      {jobpostform.touched.qualification && jobpostform.errors.qualification ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.qualification}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Stream
                      </SoftTypography>
                      {/* <Card> */}
                      <SoftSelect
                        options={streamdata}
                        onChange={(e) => jobpostform.setFieldValue("stream", e.value)}
                      />
                      {/* </Card> */}
                      {jobpostform.touched.stream && jobpostform.errors.stream ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.stream}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mb={3}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Specialization
                      </SoftTypography>
                      {/* <Card> */}
                      <SoftSelect
                        options={degree}
                        onChange={(e) => jobpostform.setFieldValue("specialization", e.value)}
                      />
                      {/* </Card> */}
                      {jobpostform.touched.qualification && jobpostform.errors.qualification ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.qualification}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox width="100%" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Experience
                      </SoftTypography>
                      {/* <Card> */}
                      <SoftSelect
                        options={exp}
                        onChange={(e) => jobpostform.setFieldValue("Experiance", e.value)}
                      />
                      {/* </Card> */}

                      {jobpostform.touched.Experiance && jobpostform.errors.Experiance ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.Experiance}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mb={3}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={6} container spacing={3}>
                    <SoftBox
                      width="100%"
                      pl={2}
                      display="flex"
                      flexDirection="row"
                      // alignItems="center"
                      gap="30px"
                      // justifyContent=""
                    >
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Work from home availability
                      </SoftTypography>
                      {/* <Card>
                              <SoftSelect
                                options={wfm}
                                onChange={(e) => jobpostform.setFieldValue("workfromhome", e.value)}
                              />
                            </Card> */}
                      <SoftBox display="flex" flexDirection="row" alignItems="center" gap="20px">
                        <SoftBox display="flex" flexDirection="row" gap="5px">
                          <input
                            type="radio"
                            value="Yes"
                            name="keyword"
                            // defaultChecked
                            // checked={jobpostform.workfromhome === "Yes"}
                            style={{ cursor: "pointer" }}
                            onChange={(e) => (
                              console.log("valuesss ", e.target.value),
                              jobpostform.setFieldValue("workfromhome", e.target.value),
                              // )
                              console.log("value radio ", jobpostform.workfromhome)
                            )}
                            // onChange={jobpostform.handleChange}
                          />
                          {/* <input
                                  type="checkbox"
                                  name="checkbox"
                                  value
                                  checked={check}
                                  onChange={() => setCheck(!check)}
                                  placeholder=""
                                /> */}
                          <SoftTypography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "small",
                              fontSize: "15px",
                              lineHeight: "19px",
                            }}
                          >
                            Yes
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" flexDirection="row" gap="5px">
                          <input
                            type="radio"
                            value="No"
                            name="keyword"
                            style={{ cursor: "pointer" }}
                            // checked={jobpostform.workfromhome === "No"}
                            onChange={(e) => (
                              console.log("valuesss ", e.target.value),
                              jobpostform.setFieldValue("workfromhome", e.target.value),
                              console.log("value radio ", jobpostform.workfromhome)
                            )}
                          />
                          <SoftTypography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "small",
                              fontSize: "15px",
                              lineHeight: "19px",
                            }}
                          >
                            No
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          display="flex"
                          flexDirection="row"
                          gap="5px"
                          sx={{ cursor: "pointer" }}
                        >
                          <input
                            type="radio"
                            value="Hybrid"
                            name="keyword"
                            // style={{ cursor: "pointer" }}
                            // checked={jobpostform.workfromhome === "Hybrid"}
                            onChange={(e) => (
                              console.log("valuesss ", e.target.value),
                              jobpostform.setFieldValue("workfromhome", e.target.value),
                              console.log("value radio ", jobpostform.workfromhome)
                            )}
                          />
                          <SoftTypography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "small",
                              fontSize: "15px",
                              lineHeight: "19px",
                              // cursor: "pointer",
                            }}
                          >
                            Hybrid
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox pl={2}>
                      {jobpostform.touched.workfromhome && jobpostform.errors.workfromhome ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.workfromhome}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mb={0}>
                <Grid container spacing={8}>
                  <Grid item xs={12} lg={12} container spacing={3}>
                    <SoftBox width="100%" height="150px" pl={2}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Job Description
                      </SoftTypography>
                      {/* <Card> */}
                      {/* <textarea
                        rows={5}
                        style={{ borderColor: "#fff", padding: "10px", fontSize: "14px" }}
                        name="jobdesc"
                        id="jobdesc"
                        onChange={jobpostform.handleChange}
                        value={jobpostform.values.jobdesc}
                      ></textarea> */}
                      <SoftInput
                        type="textarea"
                        rows={2}
                        // style={{ borderColor: "#fff", padding: "10px", fontSize: "14px" }}
                        name="jobdesc"
                        id="jobdesc"
                        placeholder="Job descriction........"
                        onChange={jobpostform.handleChange}
                        value={jobpostform.values.jobdesc}
                      />
                      {/* </Card> */}
                      {jobpostform.touched.jobdesc && jobpostform.errors.jobdesc ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {jobpostform.errors.jobdesc}{" "}
                        </span>
                      ) : null}
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </Modal.Body>
          <Modal.Footer>
            <SoftBox
              // pl={2}
              margin="25px auto"
              // margin="25px"
              sx={{
                height: "40px",
                backgroundColor: "#3B5092",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
              width="226px"
              p="10px 20px"
              // width={{ lg: "225px", sm: "100%", xs: "50%" }}
              // onClick={() => {
              // setShowShortlistModal3(false);
              // setShowShortlistModal1(false);
              // setShowShortlistModal2(true);
              //   // navigate('/search/newsearch/recommend-search/allshortlist');
              // }}
              onClick={jobpostform.handleSubmit}
            >
              <SoftTypography
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: "medium",
                  lineHeight: "18px",
                }}
              >
                Create Bucket
              </SoftTypography>
            </SoftBox>
          </Modal.Footer>
        </Modal>
        {/* Shortlist Modal 2 */}

        {/* Select Bucket Modal */}
        {/* // "0.5": */}

        <Modal
          show={showShortlistModal2}
          onHide={handleCloseShortlistModal2}
          // onClose={handleCloseShortlistModal2}
          centered
          // closeButton
          style={{ opacity: loadingModal ? "0.5" : "1" }}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Select a Bucket</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <SoftBox
              sx={{ display: "flex", justifyContent: "space-between", width: "100%", pb: 3 }}
            >
              <SoftTypography>Select a Bucket</SoftTypography>

              <SoftTypography onClick={handleCloseShortlistModal2} sx={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{ height: "20px" }}
                >
                  <path
                    d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 
                              13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                  ></path>
                </svg>
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" flexDirection="column" gap="20px">
              <SoftBox>
                <SoftSelect
                  options={orgList}
                  placeholder="Select from following compaines"
                  // onChange={(e) => (
                  //   selectBucket.setFieldValue("org_name", e.label), setOrgId(e.value)
                  // )}
                  onChange={(e) =>
                    !showShortlistOption
                      ? (selectBucket.setFieldValue("org_name", e.label), setOrgId(e.value))
                      : setOrgId1(e.value)
                  }
                />
                <SoftTypography fontWeight="small" fontSize="12px" padding="4px">
                  {selectBucket.touched.org_name && selectBucket.errors.org_name ? (
                    <span style={{ color: "red" }}>{selectBucket.errors.org_name}</span>
                  ) : null}
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftSelect
                  options={roleList}
                  placeholder="Select from following job roles"
                  onChange={(e) => {
                    !showShortlistOption
                      ? (selectBucket.setFieldValue("job_role", e.label), setJobId(e.jobid))
                      : setJobId1(e.jobid);
                  }}
                />
                <SoftTypography fontWeight="small" fontSize="12px" padding="4px">
                  {selectBucket.touched.job_role && selectBucket.errors.job_role ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {selectBucket.errors.job_role}
                    </span>
                  ) : null}
                </SoftTypography>
              </SoftBox>
              {showShortlistOption && (
                <SoftBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                    // marginTop: "30px",
                    gap: "10px",
                  }}
                >
                  <SoftTypography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "small",
                      lineHeight: "19px",
                    }}
                  >
                    Data count to upload from <b>{paginationState.dataSize}</b> total data:
                  </SoftTypography>
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    // justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox width="100%">
                      <SoftInput
                        type="number"
                        // maxValue={cpAssignPayload.candidates}
                        value={dataLength}
                        // onChange={(e) => setAssignCount(e.target.value)}
                        onChange={handleChange}
                      />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )}
              <SoftBox
                display="flex"
                justifyContent="center"
                onClick={() => {
                  setShowShortlistModal3(true);
                  setShowShortlistModal2(false);
                  // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                  navigate("/searchbyposting/createposting");
                }}
              >
                <SoftTypography fontWeight="small" fontSize="14px" lineHeight="18px">
                  Could not find suitable bucket? Create Bucket
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Modal.Body>
          <Modal.Footer>
            {showShortlistOption ? (
              <SoftBox
                // pl={2}
                margin="auto"
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width="126px"
                p="10px 20px"
                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                onClick={
                  () => AllShortListFunc()
                  // setShowModal1(false);
                  // setShowModal2(false);

                  // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                }
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "medium",
                    lineHeight: "15px",
                  }}
                >
                  Bulk Shortlist
                </SoftTypography>
              </SoftBox>
            ) : (
              <SoftBox
                // pl={2}
                margin="auto"
                sx={{
                  height: "35px",
                  backgroundColor: "#3B5092",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                // p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                width="126px"
                p="10px 20px"
                // width={{ lg: "225px", sm: "100%", xs: "50%" }}
                onClick={
                  selectBucket.handleSubmit
                  // setShowModal1(false);
                  // setShowModal2(false);

                  // navigate("/search/newsearch/recommend-search/allshortlist/viewCandidates");
                }
              >
                <SoftTypography
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "medium",
                    lineHeight: "15px",
                  }}
                >
                  Select Bucket
                </SoftTypography>
              </SoftBox>
            )}
            {/* <Button variant="secondary" onClick={handleClose}>
                                Create
                            </Button> */}
            {/* <Button variant="primary" onClick={handleClose}>
                                Save Changes
                            </Button> */}
          </Modal.Footer>
        </Modal>

        {/* Select Bucket Modal */}

        <Modal
          show={openModal}
          onHide={() => {
            setOpenModal(false);
            setShowEditModal(false);
          }}
          centered
          size="xl"
          closeButton
          contentClassName="modal-height"
        >
          <Modal.Body>
            <SoftBox height="70%">
              <SoftBox sx={{ display: "flex", justifyContent: "end", width: "100%", pb: 1 }}>
                {/* <SoftTypography>
                  {candData?.first_name ? candData?.first_name : "-"}{" "}
                  {candData?.last_name ? candData?.last_name : ""}
                </SoftTypography> */}

                <SoftTypography onClick={() => setOpenModal(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style={{ height: "20px" }}
                  >
                    <path
                      d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 
                              13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                    ></path>
                  </svg>
                </SoftTypography>
              </SoftBox>

              <SoftBox display="flex" gap="10px" width="100%">
                <SoftBox
                  width="65%"
                  height="max-content"
                  pb={2}
                  // sx={{border:1}}
                >
                  <SoftBox>
                    <SoftBox p={2} sx={{ border: 1, borderColor: "grey.300", borderRadius: 2 }}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        pb={1}
                        alignItems="center"
                        sx={{ borderBottom: 1, borderColor: "grey.300" }}
                      >
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          // onClick={() => setInputId(100)}
                        >
                          <SoftBox display="flex" gap="5px">
                            <SoftTypography fontWeight="400" fontSize="15px" lineHeight="30px">
                              Name:
                            </SoftTypography>
                            <SoftBox
                              display="flex"
                              justifyContent="space-around"
                              alignItems="center"
                            >
                              <SoftTypography fontSize="15px" sx={{ fontWeight: 500 }}>
                                {candData?.first_name ? candData?.first_name : "-"}{" "}
                                {candData?.last_name ? candData?.last_name : ""}
                                {/*{`${candData?.first_name} ? ` + " " + `${candData?.last_name}`}*/}
                              </SoftTypography>
                              &nbsp;&nbsp;
                              {/* <SoftBadge>Walk-in</SoftBadge> */}
                            </SoftBox>
                          </SoftBox>
                          {/* <SoftSelect /> */}
                        </SoftBox>

                        {candData?.email !== "" && (
                          <SoftBox sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
                            <MailOutlineIcon />
                            <SoftBox>
                              <SoftTypography fontSize="15px" sx={{ color: "#006CD0" }}>
                                {candData?.email !== "" ? candData.email : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        )}

                        {candData?.mobile !== "" && (
                          <SoftBox sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
                            <LocalPhoneIcon />
                            <SoftBox>
                              <SoftTypography fontSize="15px" sx={{ color: "#006CD0" }}>
                                {candData?.mobile !== ""
                                  ? candData?.mobile?.length === 12
                                    ? candData?.mobile?.substring(2)
                                    : candData.mobile
                                  : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        )}
{console.log(candData?.resume_cv , 'cv')}
                        {candData?.resume_cv !== null && candData?.resume_cv !== 'nan'? (
                          <SoftBox bgColor="#9747FF" px={1} sx={{ borderRadius: 1 }}>
                            <a href={candData?.resume_cv} target="_blank" rel="noreferrer">
                              <ArticleIcon color="white" />
                              <Button>
                                <SoftTypography bgColor="#9747FF" color="white" fontSize="15px">
                                  Resume
                                </SoftTypography>
                              </Button>
                            </a>
                          </SoftBox>
                        ) : (
                          <SoftBox bgColor="#dfc7ff" px={1} sx={{ borderRadius: 1 }}>
                            <ArticleIcon color="white" />
                            <Button disabled>
                              <SoftTypography bgColor="#dfc7ff" color="white" fontSize="15px">
                                Resume
                              </SoftTypography>
                            </Button>
                          </SoftBox>
                        )}

                        {showEditModal ? (
                          <SoftBox height="12px" width="12px" />
                        ) : (
                          <SoftBox display="flex" flexDirection="column" alignItems="center">
                            <img
                              src={Edit}
                              height="24px"
                              width="24px"
                              style={{ marginTop: "7px", cursor: "pointer" }}
                              onClick={() => setShowEditModal(true)}
                              // onClick={()=>seteditadmin(true)}
                            />
                            <SoftTypography fontSize="10px">Edit</SoftTypography>
                          </SoftBox>
                        )}
                      </SoftBox>

                      <SoftBox pb={4} sx={{ height: "250px", mt: 2, maxWidth: "1000px" }}>
                        <SoftBox width="100%" sx={{ display: "flex", gap: "20px" }}>
                          {/* <SoftBox sx={{ display: "flex", width: "50%" }}>
                          <SoftBox sx={{ width: "125px" }}>
                            <SoftTypography  fontSize="15px" lineHeight="30px">
                              Phone:
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox>
                            <SoftTypography sx={{fontWeight:500}} fontSize="15px" lineHeight="30px">
                              {candData?.mobile !== "" ? candData.mobile : "-"}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox> */}
                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "125px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                DOB:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.date_of_birth !== ""
                                  ? veridetails?.date_of_birth
                                  : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>

                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "170px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Qualification:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.highest_education !== ""
                                  ? veridetails?.highest_education
                                  : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox width="100%" sx={{ display: "flex", gap: "20px" }}>
                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "125px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                State:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.state !== "" ? veridetails.state : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>

                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "170px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                District:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.district !== "" ? veridetails.district : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox width="100%" sx={{ display: "flex", gap: "20px" }}>
                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "125px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Taluka:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.taluka !== "" ? veridetails?.taluka : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>

                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "170px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Skills:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {candData?.skills !== null ? candData?.skills : "Unavailable"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox width="100%" sx={{ display: "flex", gap: "20px" }}>
                          {/* <SoftBox sx={{ display: "flex", width: "50%" }}>
                          <SoftBox sx={{ width: "125px" }}>
                            <SoftTypography  fontSize="15px" lineHeight="30px">
                              DOB:
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox>
                            <SoftTypography sx={{fontWeight:500}} fontSize="15px" lineHeight="30px">
                              {veridetails?.date_of_birth !== "" ? veridetails?.date_of_birth : "-"}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox> */}

                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "125px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Experience:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.experience}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>

                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "170px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Data Source:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.dataSource !== "" ? veridetails?.dataSource : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox width="100%" sx={{ display: "flex", gap: "20px" }}>
                          {/* <SoftBox sx={{ display: "flex", width: "50%" }}>
                          <SoftBox sx={{ width: "125px" }}>
                            <SoftTypography  fontSize="15px" lineHeight="30px">
                              Qualification:
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox>
                            <SoftTypography sx={{fontWeight:500}} fontSize="15px" lineHeight="30px">
                              {veridetails?.highest_education !== ""
                                ? veridetails?.highest_education
                                : "-"}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox> */}

                          {/* <SoftBox sx={{ display: "flex", width: "50%" }}>
                          <SoftBox sx={{ width: "170px" }}>
                            <SoftTypography  fontSize="15px" lineHeight="30px">
                              Source of Data:
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox>
                            <SoftTypography sx={{fontWeight:500}} fontSize="15px" lineHeight="30px">
                              Job Portal
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox> */}
                        </SoftBox>

                        <SoftBox width="100%" sx={{ display: "flex", gap: "20px" }}>
                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "125px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Desired Role:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.job_role !== "" ? veridetails.job_role : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "170px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Current Salary:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.current_salary !== ""
                                  ? veridetails?.current_salary
                                  : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox width="100%" sx={{ display: "flex", gap: "20px" }}>
                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "125px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Company:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500, maxWidth: "200px" }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {candData?.industry !== "" ? candData.industry : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>

                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "170px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Bot Nurturing Done:
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                No
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox width="100%" sx={{ display: "flex", gap: "20px" }}>
                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "125px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Modified on:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.modified !== "" ? veridetails.modified : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>

                          <SoftBox sx={{ display: "flex", width: "50%" }}>
                            <SoftBox sx={{ width: "170px" }}>
                              <SoftTypography fontSize="15px" lineHeight="30px">
                                Last Active:
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                              <SoftTypography
                                sx={{ fontWeight: 500 }}
                                fontSize="15px"
                                lineHeight="30px"
                              >
                                {veridetails?.lastactive !== "" ? veridetails.lastactive : "-"}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox
                    width="100%"
                    mt={1}
                    // sx={{ p: 2, border:1, borderColor: 'grey.300', borderRadius:2}}
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "center",
                      border: 1,
                      borderColor: "grey.300",
                      borderRadius: 2,
                    }}
                  >
                    <SoftBox
                      sx={{
                        minWidth: { lg: "500px" },
                      }}
                    >
                      {console.log("timeeee", time, setTime, candData, veridetails)}
                      <Alltabs2
                        time={time}
                        setTime={setTime}
                        candData={candData}
                        veridetails={veridetails}
                        orgId={orgId}
                        jobId={jobId}
                      />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  width="35%"
                  p={2}
                  sx={{ border: 1, borderColor: "grey.300", borderRadius: 2 }}
                >
                  <SoftBox>
                    {console.log("timeeee", time, setTime, candData, veridetails)}
                    <TimelineTab
                      time={time}
                      setTime={setTime}
                      candData={candData}
                      veridetails={veridetails}
                    />
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Modal.Body>
        </Modal>

        <Modal
          show={showEditModal}
          onHide={() => {
            setShowEditModal(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header closeButton>Update Details</Modal.Header>
          <Modal.Body>
            <SoftBox>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    First Name
                  </SoftTypography>

                  <SoftInput
                    type="text"
                    placeholder=""
                    id="firstnm"
                    name="firstnm"
                    value={veridetailsnew?.first_name}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, first_name: e.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    Last Name
                  </SoftTypography>

                  <SoftInput
                    type="text"
                    placeholder=""
                    id="firstnm"
                    name="firstnm"
                    value={veridetailsnew?.last_name}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, last_name: e.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    Email
                  </SoftTypography>

                  <SoftInput
                    type="email"
                    placeholder=""
                    id="firstnm"
                    name="firstnm"
                    value={veridetailsnew?.email}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, email: e.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    Phone
                  </SoftTypography>

                  <SoftInput
                    type="tel"
                    sx={{
                      inputProps: {
                        maxLength: "10",
                      },
                    }}
                    placeholder=""
                    id="firstnm"
                    name="firstnm"
                    value={veridetailsnew?.mobile}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, mobile: e.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    D.O.B
                  </SoftTypography>

                  <SoftInput
                    type="date"
                    placeholder=""
                    id="firstnm"
                    name="firstnm"
                    value={veridetailsnew?.date_of_birth}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, date_of_birth: e.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    Qualification
                  </SoftTypography>

                  <Select
                    styles={stylesDD}
                    isClearable
                    options={education}
                    value={{
                      label: veridetailsnew?.highest_education,
                      value: veridetailsnew?.highest_education,
                    }}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, highest_education: e ? e.value : "" })
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    Current Salary
                  </SoftTypography>

                  <Select
                    styles={stylesDD}
                    isClearable
                    options={salaryList}
                    value={{
                      label: veridetailsnew?.current_salary,
                      value: veridetailsnew?.current_salary,
                    }}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, current_salary: e ? e.value : "" })
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    Desired Role
                  </SoftTypography>

                  <Select
                    styles={stylesDD}
                    isClearable
                    options={jobrolenew}
                    value={{ label: veridetailsnew?.job_role, value: veridetailsnew?.job_role }}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, job_role: e ? e.value : "" })
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <SoftTypography fontWeight="medium" fontSize="15px">
                    Experience
                  </SoftTypography>

                  <Select
                    styles={stylesDD}
                    isClearable
                    options={exp}
                    value={{ label: veridetailsnew?.experience, value: veridetailsnew?.experience }}
                    onChange={(e) =>
                      setVeridetailsnew({ ...veridetailsnew, experience: e ? e.value : "" })
                    }
                  />
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", p: 1 }}>
                <Box sx={{ p: 1 }}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => handleVerifyDetailsNew()}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Submit
                    </SoftTypography>
                  </SoftBox>
                </Box>

                <Box sx={{ p: 1 }}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                      border: "1px solid #3B5092",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "225px", sm: "100%", xs: "50%" }}
                    onClick={() => {
                      setShowEditModal(false);
                    }}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Box>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>

        {/* loading modal */}
        <Modal
          show={loadingModal}
          onHide={() => {
            setLoadingModal(false);
          }}
          centered
          size="sm"
          closeButton
          contentClassName="modal-height"
        >
          <Modal.Body>
            <SoftBox display="flex" justifyContent="center" alignItems="center">
              <SoftBox display="flex" flexDirection="column" gap={3}>
                {/* <SoftBox display="flex" justifyContent="center">
                  <img src={uploadGif} width="150px" height="150px" />
                </SoftBox> */}
                <SoftTypography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Uploading.....
                </SoftTypography>
                <SoftTypography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Please wait as we are uploading the file
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* loading modal */}
      </DashboardLayout>
    </>
  );
};

// export default Jobseekar;
