import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Row, Col } from "react-bootstrap";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from "axios";


const Centerheadreg = () => {

    const adminform = useFormik({
        enableReinitialize: true,
        initialValues: {
            organizationname: "",
            address: "",
            state: "",
            city: "",
            pincode: "",
            website: "",
            cinnumber: "",
            email: "",
            mobilenumber: "",
            firstname: "",
            lastname: "",
            aadharno: "",
            password: "",
            confirmpassword: "",
            seq_question: "",
            answer: ""
        },
        validationSchema: Yup.object().shape({
            organizationname: Yup.string().required("Enter Organization Name"),
            address: Yup.string().required("Enter Address Name"),
            state: Yup.string().required("Enter State Name"),
            city: Yup.string().required("Enter City Name"),
            pincode: Yup.string().required("Enter Pin Code").length(6, "PinCode Should Be 6 Digit").matches(/^[0-9]+$/, "Must be only digits"),
            website: Yup.string()
                .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    'Enter correct url!'
                )
                .required('Please Enter Website'),
            cinnumber: Yup.string().required("Enter Company CIN Number"),
            email: Yup.string().email("Enter Valid Email").required("Enter Email"),
            mobilenumber: Yup.string().required("Enter Mobile Number").length(10, "Mobile Number Should Be 10 Digit").matches(/^[0-9]+$/, "Must be only digits"),
            firstname: Yup.string().required("Enter First Name"),
            lastname: Yup.string().required("Enter Last Name"),
            password: Yup.string().min(8, 'Password must be 8 characters long')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter')
                .matches(/[^\w]/, 'Password requires a special charator'),
            aadharno: Yup.string().required("Enter Aadhar Card Number").length(12, "Aadhar Card Should Be 12 Digit"),
            seq_question: Yup.string().required("Select Question"),
            answer: Yup.string().required("Enter Answer"),
            confirmpassword: Yup.string().required("Please Enter Confirm Password").oneOf([Yup.ref('password'), null], 'Password must match')
        }),
        onSubmit: value => {
            console.log(value)
        }
    })

    const reporting = [
        {
            label: "Super Admin 1",
            value: "SA",
        },
        {
            label: "Super Admin 2",
            value: "SA",
        },
        {
            label: "Super Admin 3",
            value: "SA",
        },
        {
            label: "Super Admin 4",
            value: "SA",
        },
        {
            label: "Super Admin 5",
            value: "SA",
        },
    ];

    return (
        <>
            <DashboardLayout>
                <SoftBox mt={0} width="100%">
                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
                        <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                            Center Head
                        </SoftTypography>
                        <SoftBox width="100%" component="form" role="form"
                            onSubmit={adminform.handleSubmit}
                        >
                            <Grid container>
                                <SoftBox mt={4} width="100%" height="auto">
                                    <Card sx={{ overflow: "visible" }}>
                                        <SoftBox pl={2} pr={4} pt={2} pb={3}>

                                            <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                                Center Details
                                            </SoftTypography>
                                            <SoftBox component="form" role="form">
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftBox display="flex" flexDirection="row">
                                                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                                                        Center Name
                                                                    </SoftTypography>
                                                                    <SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox>
                                                                </SoftBox>
                                                                <SoftSelect placeholder="" options={reporting}
                                                                    id="organizationname"
                                                                    name="organizationname"
                                                                    onChange={adminform.handleChange}
                                                                    value={adminform.values.organizationname} />
                                                                {adminform.touched.organizationname && adminform.errors.organizationname ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.organizationname}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2} >
                                                                <SoftBox display="flex" flexDirection="row">
                                                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                                                        Location
                                                                    </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftSelect placeholder="" options={reporting}
                                                                    id="address"
                                                                    name="address"
                                                                    onChange={adminform.handleChange}
                                                                    value={adminform.values.address} />
                                                                {adminform.touched.address && adminform.errors.address ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.address}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                        <SoftBox pl={2} pr={4} pt={2} pb={4}>
                                            <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                                Center Head
                                            </SoftTypography>
                                            <SoftBox component="form" role="form">
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >
                                                    <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                                <SoftBox display="flex" flexDirection="row">
                                                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                                                        Name
                                                                    </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                < SoftInput placeholder=""
                                                                    id="email"
                                                                    name="email"
                                                                    onChange={adminform.handleChange}
                                                                    value={adminform.values.email} />
                                                                {adminform.touched.email && adminform.errors.email ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.email}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                                <SoftBox display="flex" flexDirection="row">
                                                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                                                        Phone
                                                                    </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="mobilenumber"
                                                                    name="mobilenumber"
                                                                    onChange={adminform.handleChange}
                                                                    value={adminform.values.mobilenumber} />
                                                                {adminform.touched.mobilenumber && adminform.errors.mobilenumber ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.mobilenumber}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                                <SoftBox
                                                    // display="flex"
                                                    // flexDirection="row"
                                                    // justifyContent="space-between"
                                                    mb={3}
                                                >  <Grid container spacing={8}>
                                                        <Grid item xs={12} lg={6} container spacing={3} >
                                                            <SoftBox width="100%" pl={2}>
                                                                <SoftBox display="flex" flexDirection="row">
                                                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                                                        Email
                                                                    </SoftTypography><SoftBox sx={{ color: "red", position: "inherit" }}><sup>*</sup></SoftBox></SoftBox>
                                                                <SoftInput type="text" placeholder=""
                                                                    id="firstname"
                                                                    name="firstname"
                                                                    onChange={adminform.handleChange}
                                                                    value={adminform.values.firstname} />
                                                                {adminform.touched.firstname && adminform.errors.firstname ?
                                                                    (<span style={{ color: "red", fontSize: "12px" }}>{adminform.errors.firstname}</span>) : null
                                                                }
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </SoftBox>
                            </Grid>
                            <Grid container mt={4} ml={2}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                                        <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#3B5092", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                            width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                            onClick={adminform.handleSubmit}>
                                            <SoftTypography display="flex" justifyContent="center" sx={{ color: "#fff", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                                Submit
                                            </SoftTypography>
                                        </SoftBox >
                                    </Grid>
                                    <Grid item xs={12} lg={6} sm={6} container spacing={3} >
                                        <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#FFFFFF", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                            width={{ lg: "225px", sm: "100%", xs: "50%" }} >
                                            <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                                Cancel
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </DashboardLayout>

        </>
    )
}

export default Centerheadreg