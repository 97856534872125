/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

function BasicLayout({ title, description, image, children }) {
  return (
    <PageLayout mx="auto">
      {/* <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        transparent
        light
      /> */}
      <SoftBox
        width="calc(100% - 0rem)"
        // minHeight="50vh"
        height="180vh"
        // borderRadius="lg"
        // mx={2}
        // my={2}
        // pt={6}
        // pb={28}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${image})`,
          // backgroundImage: url(`${image}`),
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",          
        }}
      >
        <Grid container spacing={3} justifyContent="center" >
          <Grid item container xs={12} lg={4} mt="20px" ml={{xs:"50px",md:"180px"}}>
            <SoftBox mt={1} mb={1} display="flex" justifyContent="center">
              <SoftTypography fontSize="35px" color="white" lineHeight="44px" fontWeight="medium" >
                {title}
              </SoftTypography>
            </SoftBox>            
          </Grid>
          <Grid ml="20px" md={8}>
            <SoftBox mb={2}>{children}</SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      {/* <SoftBox mt={{ xs: -13, lg: -24 }} px={1} width="calc(100% - 2rem)" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </SoftBox> */}
      {/* <Footer /> */}
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayout.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
