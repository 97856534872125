import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { Box, Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import PropTypes from "prop-types";
import '../editStyle.css'

const CounsellorEditProfile = (props) => {
    console.log(props, 'abc')
    const [resumefile, setResumeFile] = useState("");
    const [questions, setQuestions] = useState([]);
    const [centerOwnerList, setCenterOwnerList] = useState([]);
    const [selectCenterOwner, setSelectCenterOwner] = useState("");
    const [selectProjectOwner, setSelectProjectOwner] = useState("");
    const [registerID, setRegisterID] = useState("SA1");
    const ref = useRef(null)
    const navigate = useNavigate()

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file?.name.length !== 0 && (file?.size / 1024 < 5120)) {
            setResumeFile(file);
        }
        else if (file?.name.length !== 0 && (file?.size / 1024 > 5120)) {
            Swal.fire({
                icon: "error",
                title: "",
                text: "Please upload files < 5mb and make sure format is either jpg, jpeg or png.",
                showConfirmButton: false,
                timer: 2000,
            });
            ref.current.value = "";
            setResumeFile("");
        }
        else {
            console.log("No file")
        }
    };

    const securityQuestionFunc = () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
            .then((res) => {
                console.log("Questions get api", res.data);
                setQuestions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const partnerFunc = () => {
        const params = {
            partner_association: "samarth",
        };
        const paramString = new URLSearchParams(params).toString();
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partner?${paramString}`
                // {
                //     "partner_association":"samarth",
                //     // "partner_association": selectProjectOwner
                //     //getting option from backend
                // }
            )
            .then((res) => {
                console.log(res.data);
                setProjectOwnerList(res.data);
            })
            .catch((err) => {
                console.log(err.data);
            });
    };

    useEffect(() => {
        const params = {
            partner_association: selectProjectOwner,
        };
        const paramString = new URLSearchParams(params).toString();
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/partneradmin?${paramString}`
                // {
                //     "partner_association": selectProjectOwner
                // }
            )
            .then((res) => {
                console.log(res.data);
                setCenterOwnerList(res.data);
            })
            .catch((err) => {
                console.log(err.data);
            });
    }, [selectProjectOwner]);

    useEffect(() => {
        setSelectCenterOwner("");
        securityQuestionFunc();
        partnerFunc();
    }, []);

    const roleOptions = [
        { label: "Head", value: "Head" },
        { label: "Associate", value: "Associate" },
    ];

    const counsellorform = useFormik({
        enableReinitialize: true,
        initialValues: {
            createdby: "SA1",
            //   creator_role: "SA",
            role_id: "CO",
            email: props?.editdata?.email,
            mobnum: props?.editdata?.mobnum,
            firstnm: props?.editdata?.firstnm,
            lastnm: props?.editdata?.lastnm,
            security_ques: "",
            security_ans: "",
            role_type: props?.editdata?.role, //role_type
            assigned_to_owner: "", //org_id
            profile_picture: "",
            partner_association: "",
            assigned_to_owner: props?.editdata?.assigned_to_owner,
            supervisor: props?.editdata?.supervisor,
            security_ques: props?.editdata?.security_ques,
            security_ans: props?.editdata?.security_ans,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Please enter valid email").required("Please enter email"),
            mobnum: Yup.string()
                .required("Please enter mobile number")
                .length(10, "Mobile number should be 10 Digit")
                .matches(/^[0-9]+$/, "Must be only digits"),
            // aadhar: Yup.string().required("Please enter aadhar number").length(12,"Aadhar number should be 12 Digit"),
            // pannum: Yup.string().required("Please enter pan cand number").length(10,"Pancard number should be 10 Alpanumeric"),
            firstnm: Yup.string().required("Please enter first name"),
            lastnm: Yup.string().required("Please enter last name"),
            security_ques: Yup.string().required("Please select question"),
            security_ans: Yup.string().required("Please enter answer"),
            role_type: Yup.string().required("Please select role type"),
            //partner_association: Yup.string().required("Please assign project owner"),
            assigned_to_owner: Yup.string().required("Please assign center owner"),
            // profile_picture: Yup.string().required("Please upload profile photo "),
        }),
        onSubmit: async (values) => {
            let formData = new FormData();
            formData.append("role_id", "CO");
            formData.append("reg_id", props.editdata.key);
            // formData.append("creator_role", roleId);
            formData.append("createdby", registerID);
            formData.append("firstnm", values.firstnm);
            formData.append("lastnm", values.lastnm);
            formData.append("mobnum", values.mobnum);
            formData.append("email", values.email);
            formData.append("security_ques", values.security_ques);
            formData.append("security_ans", values.security_ans);
            formData.append("counsellor_type", values.role_type);
            formData.append("assigned_to_owner", values.assigned_to_owner);
            formData.append("assigned_to_owner_name", values.supervisor);
            formData.append("partner_association", "samarth");
            formData.append("profile_picture", resumefile);
            formData.append("updatedby", sessionStorage.getItem("register_id"))
            try {
                const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL_REGISTER}/counsellor/register`, formData)
                const data = await res.data
                Swal.fire({
                    icon: "success",
                    title: "Profile updated successfully",
                    //title: `${data["User Message"]}`,
                    showConfirmButton: false,
                    timer: 3000,
                }).then(() => {
                    navigate("/counselor-list-page");
                    props?.Seteditadmin(false);
                });
            }
            catch (err) {
                Swal.fire({
                    icon: "error",
                    title: res?.data["Developer Message"] ? res.data["Developer Message"] : "",
                    title: `${res.data["User Message"]}`,
                    showConfirmButton: false,
                    timer: 3000,
                }).then(() => {
                    navigate("/counselor-list-page");
                    props?.Seteditadmin(false);
                });
            }
        },
    });

    return (
        <>
            <Modal
                show={props?.editadmin} onHide={() => { props?.Seteditadmin(false) }}
                size="lg"
                centered
                style={{ marginTop: "52px" }}
            >
                <Modal.Header closeButton>Update Counsellor Details</Modal.Header>

                <Modal.Body>
                    <SoftBox mt={0}>
                        <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
                            {/* <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
                                Admin Registration
                            </SoftTypography> */}
                            <SoftBox component="form" role="form"
                                onSubmit={counsellorform.handleSubmit}
                            >
                                <Card sx={{ overflow: "visible" }}>
                                    <SoftBox width="100%" height="auto" >
                                        <SoftTypography fontWeight="medium" fontSize="20px" mb={4.5}>
                                            Counsellor Details
                                        </SoftTypography>
                                        <SoftBox component="form" role="form">
                                            <SoftBox
                                                // display="flex"
                                                // flexDirection="row"
                                                // justifyContent="space-between"
                                                mb={3}
                                            >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    First Name <sup style={{ color: 'red' }}>*</sup>
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                                                    {/* <sup>*</sup> */}
                                                                </SoftBox>
                                                            </SoftBox>
                                                            <SoftInput
                                                                type="email"
                                                                placeholder=""
                                                                id="firstnm"
                                                                name="firstnm"
                                                                onChange={counsellorform.handleChange}
                                                                value={counsellorform.values.firstnm}
                                                            />
                                                            {counsellorform.touched.firstnm &&
                                                                counsellorform.errors.firstnm ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.firstnm}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Last Name <sup style={{ color: 'red' }}>*</sup>
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                                                    {/* <sup>*</sup> */}
                                                                </SoftBox>
                                                            </SoftBox>
                                                            <SoftInput
                                                                type="text"
                                                                placeholder=""
                                                                id="lastnm"
                                                                name="lastnm"
                                                                onChange={counsellorform.handleChange}
                                                                value={counsellorform.values.lastnm}
                                                            />
                                                            {counsellorform.touched.lastnm && counsellorform.errors.lastnm ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.lastnm}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Email <sup style={{ color: 'red' }}>*</sup>
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                                                    {/* <sup>*</sup> */}
                                                                </SoftBox>
                                                            </SoftBox>
                                                            <SoftInput
                                                                type="email"
                                                                placeholder=""
                                                                id="email"
                                                                name="email"
                                                                onChange={counsellorform.handleChange}
                                                                value={counsellorform.values.email}
                                                            />
                                                            {counsellorform.touched.email && counsellorform.errors.email ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.email}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Mobile Number <sup style={{ color: 'red' }}>*</sup>
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                                                    {/* <sup>*</sup> */}
                                                                </SoftBox>
                                                            </SoftBox>
                                                            <SoftInput
                                                                type="tel"
                                                                inputProps={{
                                                                    maxLength: "10"
                                                                }}
                                                                placeholder=""
                                                                id="mobnum"
                                                                name="mobnum"
                                                                onChange={counsellorform.handleChange}
                                                                value={counsellorform.values.mobnum}
                                                            />
                                                            {counsellorform.touched.mobnum && counsellorform.errors.mobnum ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.mobnum}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox
                                                                display="flex"
                                                                flexDirection="row"
                                                                justifyContent="space-between"
                                                            >
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Upload
                                                                </SoftTypography>
                                                                <SoftBox
                                                                    display="flex"
                                                                    flexDirection="row"
                                                                    // alignItems="baseline"
                                                                    sx={{ color: "red", position: "inherit" }}
                                                                >
                                                                    {/* <sup>*</sup> */}
                                                                    <SoftTypography fontSize="14px" fontWeight="small">
                                                                        (Optional)
                                                                    </SoftTypography>
                                                                </SoftBox>
                                                            </SoftBox>
                                                            <input

                                                                type="file"
                                                                placeholder=""
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                ref={ref}
                                                                onChange={handleFileUpload}
                                                                className="form-control"
                                                            />
                                                            <SoftTypography fontSize="12px" fontWeight="medium" mt="5px">
                                                                {
                                                                    "Please upload files < 5mb and make sure format is either jpg, jpeg or png."
                                                                }
                                                            </SoftTypography>

                                                            {counsellorform.touched.profile_picture &&
                                                                counsellorform.errors.profile_picture ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.profile_picture}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Counsellor Role <sup style={{ color: 'red' }}>*</sup>
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                                                    {/* <sup>*</sup> */}
                                                                </SoftBox>
                                                            </SoftBox>

                                                            <SoftSelect
                                                                placeholder="select role type"
                                                                options={roleOptions}
                                                                id="role_type"
                                                                name="role_type"
                                                                onChange={(e) =>
                                                                    counsellorform.setFieldValue("role_type", e.label)
                                                                }
                                                                value={{ label: counsellorform.values.role_type }}
                                                            />
                                                            {counsellorform.touched.role_type &&
                                                                counsellorform.errors.role_type ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.role_type}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Security Question <sup style={{ color: 'red' }}>*</sup>
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                                                    {/* <sup>*</sup> */}
                                                                </SoftBox>
                                                            </SoftBox>

                                                            <SoftSelect
                                                                placeholder="select questions"
                                                                options={questions}
                                                                id="security_ques"
                                                                name="security_ques"
                                                                onChange={(e) =>
                                                                    counsellorform.setFieldValue("security_ques", e.label)
                                                                }
                                                                value={{ label: counsellorform.values.security_ques }}
                                                            />
                                                            {counsellorform.touched.security_ques &&
                                                                counsellorform.errors.security_ques ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.security_ques}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Answer <sup style={{ color: 'red' }}>*</sup>
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                                                    {/* <sup>*</sup> */}
                                                                </SoftBox>
                                                            </SoftBox>
                                                            <SoftInput
                                                                type="text"
                                                                placeholder=""
                                                                id="security_ans"
                                                                name="security_ans"
                                                                onChange={counsellorform.handleChange}
                                                                value={counsellorform.values.security_ans}
                                                            />
                                                            {counsellorform.touched.security_ans &&
                                                                counsellorform.errors.security_ans ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.security_ans}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>

                                                    <Grid item xs={12} lg={6}>
                                                        <SoftBox>
                                                            <SoftBox display="flex" flexDirection="row">
                                                                <SoftTypography fontWeight="medium" fontSize="16px">
                                                                    Supervisor <sup style={{ color: 'red' }}>*</sup>
                                                                </SoftTypography>
                                                                <SoftBox sx={{ color: "red", position: "inherit" }}>
                                                                    {/* <sup>*</sup> */}
                                                                </SoftBox>
                                                            </SoftBox>
                                                            <SoftSelect
                                                                placeholder="Assign Manager"
                                                                options={centerOwnerList}
                                                                id="assigned_to_owner"
                                                                name="assigned_to_owner"
                                                                onChange={(e) => {
                                                                    counsellorform.setFieldValue("assigned_to_owner", e.value);
                                                                    counsellorform.setFieldValue("supervisor", e.label);
                                                                    //setSelectCenterOwner(e.label);
                                                                }}
                                                                // value={{ label: counsellorform.values.assigned_to_owner }}
                                                                value={{ label: counsellorform.values.supervisor }}
                                                            />
                                                            {counsellorform.touched.assigned_to_owner &&
                                                                counsellorform.errors.assigned_to_owner ? (
                                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                                    {counsellorform.errors.assigned_to_owner}
                                                                </span>
                                                            ) : null}
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>
                                </Card>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>

                </Modal.Body>
                <Modal.Footer >
                    <SoftBox pb={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: "auto", mr: "auto" }}>
                        <Box sx={{ p: 1 }}>
                            <SoftBox
                                pl={2}
                                sx={{
                                    height: "45px",
                                    backgroundColor: "#3B5092",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                                p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                width={{ lg: "100%", sm: "100%", xs: "50%" }}
                                onClick={counsellorform.handleSubmit}
                            >
                                <SoftTypography
                                    display="flex"
                                    justifyContent="center"
                                    sx={{
                                        color: "#fff",
                                        fontSize: "15px",
                                        fontWeight: "medium",
                                        lineHeight: "19px",
                                    }}
                                >
                                    Submit
                                </SoftTypography>
                            </SoftBox>
                        </Box>

                        <Box sx={{ p: 1 }}>
                            <SoftBox pl={2} sx={{ height: "45px", backgroundColor: "#c4c4c4", borderRadius: "5px", cursor: "pointer" }} p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                                width={{ lg: "225px", sm: "100%", xs: "50%" }}
                                onClick={() => { props.Seteditadmin(false) }}
                            >
                                <SoftTypography display="flex" justifyContent="center" sx={{ color: "#3B5092", fontSize: "15px", fontWeight: "medium", lineHeight: "19px" }}>
                                    Cancel
                                </SoftTypography>
                            </SoftBox>
                        </Box>
                    </SoftBox>
                </Modal.Footer>
            </Modal>
        </>
    )
}
CounsellorEditProfile.propTypes = {
    editadmin: PropTypes.bool.isRequired,
    Seteditadmin: PropTypes.func.isRequired,
    editdata: PropTypes.object.isRequired,
    // h4: PropTypes.string.isRequired,
    // v1: PropTypes.string.isRequired,
    // v2: PropTypes.string.isRequired,
    // v3: PropTypes.string.isRequired,
    // v4: PropTypes.string.isRequired,
};
export default CounsellorEditProfile