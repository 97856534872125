import SoftBadge from "components/SoftBadge";
import { SelectColumnFilter } from "components/Filter/Filter";

// ProductsList page components
import ProductCell from "layouts/ecommerce/products/products-list/components/ProductCell";

// Images
import car from "assets/images/product-icon/two_wheeler_icon.svg";
import health from "assets/images/product-icon/health_icon.svg";
import life from "assets/images/product-icon/term_icon.svg";
import travel from "assets/images/product-icon/travel_icon.svg";
import Jobprovider from "./jobprovider";

// Badges
const cancelled = (
    <SoftBadge variant="contained" color="error" size="xs" badgeContent="Cancelled" container />
);
const completed = (
    <SoftBadge variant="contained" color="success" size="xs" badgeContent="Completed" container />
);

const pending = (
    <SoftBadge variant="contained" color="warning" size="xs" badgeContent="Pending" container />
);

const renderDash = ({ value }) => (value === null || value === undefined ? "-" : value);

// custom filter func to display all rows, if "All"(value = "") is selected
// does not support Multi Select feature
const customFilterFn = (rows, columnIds, filterValue) => {
    return filterValue === ""
        ? rows
        : rows.filter((row) => filterValue.includes(String(row.original[columnIds])));
};

/* eslint-disable react/prop-types */

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    organization: `Organization ${i}`,
    location: `Location ${i}`,
    name: `Edward King ${i}`,
    email: `user${i}@gmail.com`,
    center_name: `Andher Rojgar Center ${i+1}`,
    // {if(i%2==0){
    //   created_by: "SBA",
    // }else{
    //   created_by: "Super Admin"
    // }},
    created_by: "Super Admin",
    mobnum: `949588899${i+1}`,
    last_active: `10/02/2022`,
    // job_status: "false"
    job_status: `${(i%2===0) ? "true" : "false"}`,
  });
}

const jobprovidertable = {
    columns: [
        {
            Header: "Name",
            accessor: "product",
            width: "15%",
            align: "left",
            disableFilters: true,
            Cell: ({ value: [name, data] }) => (
                <ProductCell
                    // image={data.image}
                    name={name}
                    checked={data.checked}
                // imageHeight="24px"
                // imageWidth="36px"
                />
            ),
        },
        {
            Header: "Location",
            accessor: "category",
            Cell: renderDash,
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
        {
            Header: "Organization Type",
            accessor: "sku",
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%"
        },
    
        {
            Header: "Last Posted",
            accessor: "doc",
            Cell: renderDash,
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
        // { Header: "Email", accessor: "price", disableFilters: true, Cell: renderDash, width: "15%" },
        {
            Header: "Last Active",
            accessor: "la",
            disableFilters: true,
            Cell: renderDash,
            width: "15%",
        },

        {
            Header: "Job Status",
            accessor: "status",
            Cell: ({ value }) =>
                value === "Pending" ? pending : value === "Completed" ? completed : cancelled,
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "10%",
        },
        {
            Header: "Phone Number",
            accessor: "pn",
            width: "15%",
            disableFilters: true,
            Cell: renderDash,
        },
        {
            Header: "Email",
            accessor: "price",
            Filter: SelectColumnFilter,
            disableFilters: true,
            width: "30%",
        },
    ],

    rows: [
        {
            product: ["Mahesh Hardware", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 0,
            status: "Completed",
            paymentStatus: "Done",
            company: "Rajesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Hardware", { checked: true }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Hardware",
            quantity: 0,
            status: "Cancelled",
            paymentStatus: "Rejected",
            company: "Jayesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Hardware", { checked: false }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Hardware",
            quantity: 978,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Company1", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Workshop",
            quantity: 0,
            status: "Cancelled",
            paymentStatus: "Rejected",
            company: "Jayesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Company1", { checked: false }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 725,
            status: "Completed",
            paymentStatus: "Done",
            company: "Rajesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Company1", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com",
            sku: "Hardware",
            quantity: 725,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Jayesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Company1", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 725,
            status: "Completed",
            paymentStatus: "Done",
            company: "Rajesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Company1", { checked: true }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku: "Hardware",
            quantity: 51293,
            status: "Completed",
            paymentStatus: "Done",
            company: "Jayesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Hardware", { checked: true }],
            category: "Dahisar",
            price: "jay@gmail.com",
            sku: "Workshop",
            quantity: 34,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Hardware", { checked: false }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku:"Hardware",
            quantity: 0,
            status: "Cancelled",
            paymentStatus: "Rejected",
            company: "Jayesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Hardware", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 32,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Hardware", { checked: false }],
            category: "Andheri",
            price: "jay@gmail.com,999",
            sku:"Hardware",
            quantity: 22,
            status: "Completed",
            paymentStatus: "Done",
            company: "Jayesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Hardware", { checked: true }],
            category: "Dahisar",
            price: "raj@gmail.com",
            sku: "Hardware",
            quantity: 725,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Company1", { checked: false }],
            category: "Andheri",
            price: "raj@gmail.com",
            sku: "Workshop",
            quantity: 0,
            status: "Cancelled",
            paymentStatus: "Rejected",
            company: "Jayesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
        {
            product: ["Mahesh Hardware", { checked: true }],
            category: "Dahisar",
            price: "jay@gmail.com",
            sku: "Hardware",
            quantity: 725,
            status: "Pending",
            paymentStatus: "Pending",
            company: "Rajesh Sharma",
            doc: "11-2-2022",
            la:"28-2-2023",
            pn:"898776464"
        },
    ],
};
export default jobprovidertable
export  {data}
