import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";

export const Hrconsults = () => {
  return (
    <>
    <DashboardNavbar />
<DashboardLayout>  
<SoftBox mt={4}>
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
        <SoftBox lineHeight={1}>
          <SoftTypography variant="h5" fontWeight="medium">
            HR Consults
          </SoftTypography>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Brief summary  HR Consults
          </SoftTypography>
        </SoftBox>
        <Stack spacing={1} direction="row">
          {/* <Link to="/ecommerce/products/new-product">
            <SoftButton variant="gradient" color="info" size="small">
              + new product
            </SoftButton>
          </Link> */}
          {/* <SoftButton variant="outlined" color="info" size="small">
            import
          </SoftButton> */}
          <SoftButton variant="outlined" color="info" size="small">
            export
          </SoftButton>
        </Stack>
      </SoftBox>
      <DataTable
        table={dataTableData}
        entriesPerPage={{
          defaultValue: 7,
          entries: [5, 7, 10, 15, 20, 25],
        }}
        canSearch
      />
    </Card>
  </SoftBox>
</DashboardLayout>
</>
  )
}
