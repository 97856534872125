import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

import Swal from "sweetalert2";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const CenterEmployeeRegistration1 = () => {
  const navigate = useNavigate();
  const [autopass, Setautopass] = useState(true);
  const [copyPassword, setCopyPassword] = useState({
    value: "",
    copied: false,
  });
  const [questions, setQuestions] = useState([]);

  const securityQuestionFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/securityques`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fatchAutopass = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/genpass`).then((res) => {
      setCopyPassword({ ...copyPassword, value: res.data.Password });
    });
  };

  // autopass
  useEffect(() => {
    if (autopass) {
      fatchAutopass();
    }
    //   const resDATA = sessionStorage.getItem("register_id");
    //   const creatorRole = sessionStorage.getItem("creator_role");
    //   console.log(resDATA);
    //   console.log(creatorRole);
    //  if(resDATA){
    //     setRegisterID(()=> resDATA);
    //  }
    //  if(creatorRole){
    //   setRoleId(creatorRole);
    //  }
    //  console.log("createdby :",registerID);
    //  console.log("creator_role :",roleId);
  }, [autopass]);

  // securityques
  useEffect(() => {
    securityQuestionFunc();
  }, []);
  // const questions = [
  //   {
  //     label: "What was the house number and street name you lived in as a child?",
  //     value: "What was the house number and street name you lived in as a child?",
  //   },
  //   {
  //     label: "What were the last four digits of your childhood telephone number?",
  //     value: "What were the last four digits of your childhood telephone number?",
  //   },
  //   {
  //     label: "What primary school did you attend?",
  //     value: "What primary school did you attend?",
  //   },
  //   {
  //     label: "In what town or city was your first full time job?",
  //     value: "In what town or city was your first full time job?",
  //   },
  //   {
  //     label: "In what town or city did you meet your spouse or partner?",
  //     value: "In what town or city did you meet your spouse or partner?",
  //   },
  //   {
  //     label: "What is the middle name of your oldest child?",
  //     value: "What is the middle name of your oldest child?",
  //   },
  //   {
  //     label: "What are the last five digits of your driver's license number?",
  //     value: "What are the last five digits of your driver's license number?",
  //   },
  //   {
  //     label: "What is your grandmother's (on your mother's side) maiden name?",
  //     value: "What is your grandmother's (on your mother's side) maiden name?",
  //   },
  //   {
  //     label: "What is your spouse or partner's mother's maiden name?",
  //     value: "What is your spouse or partner's mother's maiden name?",
  //   },
  //   {
  //     label: "In what town or city did your parents meet?",
  //     value: "In what town or city did your parents meet?",
  //   },
  //   {
  //     label: "What time of the day were you born? (hh:mm)",
  //     value: "What time of the day were you born? (hh:mm)",
  //   },
  //   {
  //     label: "What time of the day was your first child born? (hh:mm)",
  //     value: "What time of the day was your first child born? (hh:mm)",
  //   },
  // ];
  const reporting = [
    {
      label: "Super Admin 1",
      value: "Super Admin 1",
    },
    {
      label: "Super Admin 2",
      value: "Super Admin 2",
    },
    {
      label: "Super Admin 3",
      value: "Super Admin 3",
    },
    {
      label: "Super Admin 4",
      value: "Super Admin 4",
    },
    {
      label: "Super Admin 5",
      value: "Super Admin 5",
    },
  ];

  const employeeform = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      mobnum: "",
      firstnm: "",
      lastnm: "",
      password: copyPassword.value ?? "",
      confirmpassword: copyPassword.value ?? "",
      security_ques: "",
      security_ans: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Please enter valid email").required("Please enter email"),
      mobnum: Yup.string()
        .required(" Please enter mobile number")
        .matches(/^[0-9]+$/, "Must be only digits")
        .length(10, "Please enter 10 digit number"),
      firstnm: Yup.string().required("Please enter first name"),
      lastnm: Yup.string().required(" Please enter last name"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a special charator"),
      security_ques: Yup.string().required("Please select a question "),
      security_ans: Yup.string().required("Please enter your answer"),
      confirmpassword: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
    }),
    onSubmit: (values) => {
      delete values.confirmpassword;
      let finalvalue = values;
      localStorage.setItem("cef", JSON.stringify(finalvalue));
      navigate("/center-employee-registration-page2");
    },
  });
  const showAlert = () =>
    Swal.fire({
      //position: "top",
      icon: "success",
      title: "Registration Successful",
      text: "Admin registered successfully",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      window.location = "/center-employee-registration-page2";
    });
  //   const handleRadiobutton = ()=>{
  //     setShowPasswordBox(!showPasswordBox);
  //   }
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0}>
          <SoftBox display="flex" flexDirection="column" alignItems="flex-start" p={0}>
            <SoftTypography fontStyle="normal" fontWeight="medium" fontSize="24px">
              New Center Employee Registration
            </SoftTypography>
            <SoftBox component="form" role="form" onSubmit={employeeform.handleSubmit}>
              <Grid container>
                <SoftBox mt={4} width="100%" height="auto">
                  <Card sx={{ overflow: "visible" }}>
                    <SoftBox mt={4} width="100%" height="auto">
                      <SoftBox pl={2} pr={4} pt={2} pb={3}>
                        <SoftTypography fontWeight="medium" fontSize="16px" mb={4.5}>
                          Contact Details
                        </SoftTypography>
                        <SoftBox component="form" role="form">
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Email
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="email"
                                    placeholder=""
                                    id="email"
                                    name="email"
                                    onChange={employeeform.handleChange}
                                    value={employeeform.values.email}
                                  />
                                  {employeeform.touched.email && employeeform.errors.email ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {employeeform.errors.email}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Mobile Number
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="mobnum"
                                    name="mobnum"
                                    onChange={employeeform.handleChange}
                                    value={employeeform.values.mobnum}
                                  />
                                  {employeeform.touched.mobnum && employeeform.errors.mobnum ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {employeeform.errors.mobnum}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      First Name
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="firstnm"
                                    name="firstnm"
                                    onChange={employeeform.handleChange}
                                    value={employeeform.values.firstnm}
                                  />
                                  {employeeform.touched.firstnm && employeeform.errors.firstnm ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {employeeform.errors.firstnm}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Last Name
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="lastnm"
                                    name="lastnm"
                                    onChange={employeeform.handleChange}
                                    value={employeeform.values.lastnm}
                                  />
                                  {employeeform.touched.lastnm && employeeform.errors.lastnm ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {employeeform.errors.lastnm}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3} pl={7} pt="27px">
                                <SoftBox
                                  // pl={2}
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                  height="auto"
                                  sx={{
                                    border: autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                    bgcolor: autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                    <input
                                      name="password"
                                      type="radio"
                                      defaultChecked="true"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          Setautopass(true);
                                        } else {
                                          Setautopass(false);
                                        }
                                      }}
                                    />
                                  </SoftBox>

                                  <SoftBox display="flex" flexDirection="column" my="5px">
                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                      Auto Generate Password
                                    </SoftTypography>
                                    <SoftTypography
                                      fontWeight="small"
                                      fontSize="15px"
                                      lineHeight="16px"
                                    >
                                      Password will be automatically generated after the
                                      registration is done.
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3} pl="44px" pt="27px">
                                <SoftBox
                                  // pl={2}
                                  display="flex"
                                  flexDirection="row"
                                  width="100%"
                                  height="75px"
                                  sx={{
                                    border: !autopass ? "1px solid #05A3CA" : "1px solid #C4C4C4",
                                    bgcolor: !autopass ? "rgba(241, 250, 255, 1)" : "#fff",
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center" mx={2} my={3}>
                                    <input
                                      name="password"
                                      type="radio"
                                      onChange={() => {
                                        employeeform.setFieldValue("password", "");
                                        employeeform.setFieldValue("confirm_password", "");
                                        Setautopass(false);
                                      }}
                                    />
                                  </SoftBox>
                                  <SoftBox display="flex" flexDirection="column" my="5px">
                                    <SoftTypography fontWeight="medium" fontSize="18px">
                                      Custom Password
                                    </SoftTypography>
                                    <SoftTypography
                                      fontWeight="small"
                                      fontSize="15px"
                                      lineHeight="16px"
                                    >
                                      You can create a custom password for the user
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Password
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="password"
                                    placeholder=""
                                    disabled={autopass}
                                    id="password"
                                    name="password"
                                    onChange={employeeform.handleChange}
                                    value={employeeform.values.password}
                                  />
                                  {employeeform.touched.password && employeeform.errors.password ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {employeeform.errors.password}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Confirm Password
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="password"
                                    placeholder=""
                                    disabled={autopass}
                                    id="confirmpassword"
                                    name="confirmpassword"
                                    onChange={employeeform.handleChange}
                                    value={employeeform.values.confirmpassword}
                                  />
                                  {employeeform.touched.confirmpassword &&
                                  employeeform.errors.confirmpassword ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {employeeform.errors.confirmpassword}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox mb={3}>
                            <Grid container spacing={8}>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Security Question
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftSelect
                                    placeholder="select questions"
                                    options={questions}
                                    id="security_ques"
                                    name="security_ques"
                                    onChange={(e) =>
                                      employeeform.setFieldValue("security_ques", e.label)
                                    }
                                    value={{ label: employeeform.values.security_ques }}
                                  />
                                  {employeeform.touched.security_ques &&
                                  employeeform.errors.security_ques ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {employeeform.errors.security_ques}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                              <Grid item xs={12} lg={6} container spacing={3}>
                                <SoftBox width="100%" pl={2}>
                                  <SoftBox display="flex" flexDirection="row">
                                    <SoftTypography fontWeight="medium" fontSize="16px">
                                      Answer
                                    </SoftTypography>
                                    <SoftBox sx={{ color: "red", position: "inherit" }}>
                                      <sup>*</sup>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftInput
                                    type="text"
                                    placeholder=""
                                    id="security_ans"
                                    name="security_ans"
                                    onChange={employeeform.handleChange}
                                    value={employeeform.values.security_ans}
                                  />
                                  {employeeform.touched.security_ans &&
                                  employeeform.errors.security_ans ? (
                                    <span style={{ color: "red", fontSize: "12px" }}>
                                      {employeeform.errors.security_ans}
                                    </span>
                                  ) : null}
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </SoftBox>
              </Grid>
            </SoftBox>
            <Grid container mt={4} ml={2}>
              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "100%", sm: "100%", xs: "50%" }}
                    onClick={employeeform.handleSubmit}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Next
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={6} sm={6} container spacing={3}>
                  <SoftBox
                    pl={2}
                    sx={{
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    p={{ lg: "13px 84px", sm: "13px 100px", xs: "13px 140px" }}
                    width={{ lg: "100%", sm: "100%", xs: "50%" }}
                    onClick={() => navigate("/super-admin-register")}
                  >
                    <SoftTypography
                      display="flex"
                      justifyContent="center"
                      sx={{
                        color: "#3B5092",
                        fontSize: "15px",
                        fontWeight: "medium",
                        lineHeight: "19px",
                      }}
                    >
                      Cancel
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
    </>
  );
};

export default CenterEmployeeRegistration1;
