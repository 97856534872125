import React, { useState, useEffect } from "react";
import { Card, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "../Business/data/tableData";
import jobprovidertable from "./jobprovidertable";
import SoftSelect from "../../components/SoftSelect";
import SoftBadge from "components/SoftBadge";
import { Link, useNavigate } from "react-router-dom";
import Mapdata from "pages/Mapdata/Mapdata";
import Grid from "@mui/material/Unstable_Grid2";
// import { Table } from "ant-table-extensions";
import { Table, Dropdown } from "antd";
import axios from "axios";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import AdminEdit from "pages/EditModals/JobProvider/jobProviderEdit";
import PasswordResetJobProvider from "pages/EditModals/JobProvider/jobProviderPasswordReset";
import { Icon } from "@iconify/react";
// import { data } from "./jobprovidertable";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import moment from "moment/moment";
import Swal from "sweetalert2";
import "../style.css";
import SoftInput from "components/SoftInput";
import FadeLoader from "react-spinners/FadeLoader";
import { Box, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import refreshBtn from "../../assets/images/refresh_btn.png";
import { withTranslate, IntlActions, useTranslate } from 'react-redux-multilingual'
import { useDispatch } from 'react-redux'


const Jobprovider = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [registerID, setRegisterID] = useState("SA1");
  const [onboard, setOnboard] = useState(true);
  const translate = useTranslate()
  const dispatch = useDispatch()

  const [roleId, setRoleId] = useState("SA");
  const [admindata, Setadmindata] = useState([]);
  const [editadmin, Seteditadmin] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [editdata, Seteditdata] = useState({});
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDistrict, setSelectedDistrict] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedCSVData, setSelectedCSVData] = useState([]);
  const [colHeadersFilterd, setColHeadersFilterd] = useState([]);
  const [name, setName] = useState("");
  const [newSearchData, setNewSearchData] = useState([]);
  const [tablerow, setTablerow] = useState([]);
  const [newName, setNewName] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchSet, setSearchSet] = useState(true);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    dataSize: "",
  });
  const [newRequestData, setNewRequestData] = useState([]);


  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (current, size) => {
    console.log("Selected page size:", size);
    setPageSize(size);
  };

  const filterOffsetFunc = () => {
    setPaginationState({ ...paginationState, page: 1 });
  };


  useEffect(() => {
    dispatch(IntlActions.setLocale(sessionStorage.getItem('lang')))

  }, []);

  const [KPIObj, setKPIObj] = useState({
    job_seeker_on_hold: "",
    job_seekers_hired: "",
    jobs_created: "",
    registered_providers: "",
    jobseeker_pipeline: "",
    total_jobseekers: "",
    js_shortlisted: "",
  });
  const [colHeaders, setColHeaders] = useState([
    {
      label: "Registration ID",
      key: "key",
      isExportable: true,
    },
    {
      label: "Organisation",
      key: "org_name",
      isExportable: true,
    },
    {
      label: "Organisation Type",
      key: "org_size",
      isExportable: true,
    },
    {
      label: "Name",
      key: "name",
      isExportable: true,
    },
    {
      label: "Address",
      key: "org_address",
      isExportable: true,
    },
    {
      label: "State",
      key: "org_state",
      isExportable: true,
    },
    {
      label: "District",
      key: "org_district",
      isExportable: true,
    },
    {
      label: "Sector",
      key: "industry",
      isExportable: true,
    },
    {
      label: "Last Active",
      key: "logindt",
      isExportable: true,
    },

    {
      label: "Phone Number",
      key: "mobnum",
      isExportable: true,
    },

    {
      label: "Email",
      key: "email",
      isExportable: true,
    },
    {
      label: "Status",
      key: "job_status",
      isExportable: true,
    },
  ]);
  const [searchData, setSearchData] = useState([]);
  // const data = admindata?.map((item, id) => {
  //   return {
  //     key: item.registration_id,
  //     // organization: item.org_name ,
  //     // location: item.org_address,
  //     name: `${item.firstnm} ${item.lastnm}`,
  //     // location: `${item.org_state} ${","} ${item.org_district}`,
  //     org_address: item.org_address,
  //     org_state: item.org_state,
  //     org_district: item.org_district,
  //     org_size: item.org_size,
  //     industry: item.org_type,
  //     org_sector: item.org_sector,
  //     logindt: item.logindt,
  //     job_status: item.is_active,
  //     firstnm: item.firstnm,
  //     lastnm: item.lastnm,
  //     security_ans: item.security_ans,
  //     security_ques: item.security_ques,
  //     email: item.email,
  //     mobnum: item.mobnum,
  //     org_pincode: item.org_pincode,
  //     org_address: item.org_address,
  //     org_cin_num: item.org_cin_num,
  //     org_district: item.org_district,
  //     org_state: item.org_state,
  //     org_type: item.org_type,
  //     org_name: item.org_name,
  //     date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
  //   };
  // });

  const items = [
    {
      key: '1',
      label: (
        <a
          style={{ display: "flex" }}
          className="dropdown-item"
          href="#EditUser"
        >
          <div>
            <Icon icon="mdi:lead-pencil" />
          </div>
          &nbsp; Edit Profile
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          className="dropdown-item"
          href="#EditUser"
          style={{ display: "flex" }}
        >
          <div>
            <Icon icon="mdi:password-reset" />
          </div>
          &nbsp; Reset Password
        </a>
      ),
    },
  ];
  const colName1 = [
    {
      title: "Registration ID",
      dataIndex: "key",
      width: 200,
      fixed: "left",
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
        multiple: 1,
      },
    },
    {
      title: "Organisation",
      dataIndex: "org_name",
      fixed: "left",
      width: 200,
      sorter: {
        // compare: (a, b) => a.org_name - b.org_name,
        compare: (a, b) => a?.org_name?.localeCompare(b?.org_name),
        multiple: 5,
      },
    },
    {
      title: "Organisation Type",
      //dataIndex: "org_size",
      width: 200,
      sorter: {
        // compare: (a, b) => a.org_size - b.org_size,
        compare: (a, b) => a?.org_size?.localeCompare(b?.org_size),
        multiple: 6,
      },
      render: (text) => (
        <>
          {text.org_size === null || text.org_size === "" || text.org_size === "nan"
            ? " - "
            : text.org_size}
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      //fixed: "left",
      width: 200,
      sorter: {
        // compare: (a, b) => a.name - b.name,
        compare: (a, b) => a?.firstnm?.localeCompare(b?.firstnm),
        multiple: 1,
      },
    },
    {
      title: "Address",
      width: 150,
      sorter: {
        compare: (a, b) => a?.org_address?.localeCompare(b?.org_address),
        multiple: 2,
      },
      render: (text) => (
        <>
          {text.org_address === null || text.org_address === "" || text.org_address === "nan"
            ? " - "
            : text.org_address}
        </>
      ),
    },
    {
      title: "State",
      dataIndex: "org_state",
      width: 150,
      sorter: {
        // compare: (a, b) => a.org_state - b.org_state,
        compare: (a, b) => a?.org_state?.localeCompare(b?.org_state),
        multiple: 3,
      },
    },
    {
      title: "District",
      //dataIndex: "org_district",
      width: 150,
      sorter: {
        // compare: (a, b) => a.org_district - b.org_district,
        compare: (a, b) => a?.org_district?.localeCompare(b?.org_district),
        multiple: 4,
      },
      render: (text) => (
        <>
          {text.org_district === null || text.org_district === "" || text.org_district === "nan"
            ? " - "
            : text.org_district}
        </>
      ),
    },
    {
      title: "Sector",
      //dataIndex: "industry",
      width: 150,
      sorter: {
        compare: (a, b) => a?.industry?.localeCompare(b?.industry),
        multiple: 7,
      },
      render: (text) => (
        <>
          {text.industry === null || text.industry === "" || text.industry === "nan"
            ? " - "
            : text.industry}
        </>
      ),
    },
    {
      title: "Last Active",
      dataIndex: "logindt",
      width: 150,
      sorter: {
        // compare: (a, b) => a.logindt - b.logindt,
        compare: (a, b) => moment(a.logindt, "DD-MM-YYYY") - moment(b.logindt, "DD-MM-YYYY"),
        multiple: 9,
      },
      render: (text) => <>{text === null ? " - " : moment(text).format("DD-MM-YYYY")}</>,
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      width: 150,
      sorter: {
        compare: (a, b) => a.mobnum - b.mobnum,
        multiple: 10,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
      sorter: {
        // compare: (a, b) => a.email - b.email,
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 11,
      },
    },
    {
      title: "Role Type",
      dataIndex: "role_access",
      width: 200,
      sorter: {
        // compare: (a, b) => a.email - b.email,
        compare: (a, b) => a?.role_access?.localeCompare(b?.role_access),
        multiple: 12,
      },
    },
    {
      title: "Status",

      key: "6",
      width: 250,
      sorter: {
        compare: (a, b) => a.onboarding_status - b.onboarding_status,
        multiple: 7,
      },
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            // flexDirection: "row",
            justifyContent: "center",
            // alignContent: "center",
          }}
        >
          <span
            style={{
              backgroundColor: text?.onboarding_status === true ? "#53CA96" : "#FFA978",
              color: "#fff",
              display: "flex",
              // flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              borderRadius: "10px",
              width: "150px",
              cursor: "pointer",
            }}
            // onclick api to onboard
            onClick={() => OnboardFunc(text)}
          >
            {text?.onboarding_status === true ? "Onboarded" : "Not Onboarded"}
          </span>
        </div>
      ),
    },
  ];
  const fetchRequestList = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata`,
        {
          params: {
            limit: 10,
            limit: pageSize,
            offset: paginationState.page - 1,
            search_data: name,
            onboarded: 0,
            type: "detail",
            created_by: sessionStorage.getItem("register_id")
            ? sessionStorage.getItem("register_id")
            : "",
          },
        }
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_Detail"]);
        setLoading(false);
        Setadmindata(
          res.data["Organisation_Detail"]?.map((item, id) => ({
            key: item.registration_id,
     
            name: `${item?.firstnm} ${item?.lastnm}`,
            org_logo: item.org_logo,
            org_address: item.org_address,
            org_state: item.org_state,
            org_district: item.org_district,
            org_size: item.org_size,
            industry: item.org_type,
            org_sector: item.org_sector,
            logindt: item.logindt,
            job_status: item.is_active,
            firstnm: item.firstnm,
            lastnm: item.lastnm,
            security_ans: item.security_ans,
            security_ques: item.security_ques,
            email: item.email,
            mobnum: item?.mobnum?.length === 12 ? item?.mobnum?.substring(2) : item?.mobnum,
            org_pincode: item.org_pincode,
            org_address: item.org_address,
            org_cin_num: item.org_cin_num,
            org_district: item.org_district,
            org_state: item.org_state,
            org_type: item.org_type,
            org_name: item.org_name,
            date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
            role_access:
              item.role_access === "Default"
                ? "Job Fair"
                : item.role_access === ""
                ? " - "
                : item.role_access,
            data_access: item.data_access,
            company_info: item.company_info,
            crm_optin: item.crm_optin,
            onboarding_status: item.onboarded === 1 ? true : false,
          }))
        );
        setNewRequestData(
          res.data["Organisation_Detail"]?.map((item, id) => ({
            key: item.registration_id,
       
            name: `${item?.firstnm} ${item?.lastnm}`,
            org_logo: item.org_logo,
            org_address: item.org_address,
            org_state: item.org_state,
            org_district: item.org_district,
            org_size: item.org_size,
            industry: item.org_type,
            org_sector: item.org_sector,
            logindt: item.logindt,
            job_status: item.is_active,
            firstnm: item.firstnm,
            lastnm: item.lastnm,
            security_ans: item.security_ans,
            security_ques: item.security_ques,
            email: item.email,
            mobnum: item?.mobnum?.length === 12 ? item?.mobnum?.substring(2) : item?.mobnum,
            org_pincode: item.org_pincode,
            org_address: item.org_address,
            org_cin_num: item.org_cin_num,
            org_district: item.org_district,
            org_state: item.org_state,
            org_type: item.org_type,
            org_name: item.org_name,
            date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
            role_access:
              item.role_access === "Default"
                ? "Job Fair"
                : item.role_access === ""
                ? " - "
                : item.role_access,
            data_access: item.data_access,
            company_info: item.company_info,
            crm_optin: item.crm_optin,
            onboarding_status: item.onboarded === 1 ? true : false,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
        //setLoading(false)
      });
  };

  const handleFormChange12 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    setState(data);
  };
  const handleCheckboxChange = (item) =>
    setColHeadersFilterd(
      colHeaders.filter((ele, id) => {
        return ele.isExportable !== "true";
      })
    );
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    filterData(admindata, newSelectedRowKeys);
    setTablerow(selectedRows)
  };
  const filterData = (data, keyId) => {
    const newData = data.filter((item, id) => {

      return keyId.includes(item.key);
    });
    console.log(newData);
    setSelectedCSVData(newData);
  };
  console.log("selected data", selectedCSVData);
  // console.log("isActive ", isActive);
  const colName = [
 
    {
      title: "Registration ID",
      dataIndex: "key",
      width: 200,
      fixed: "left",
      // render: (text, record) => <>{`${text.firstnm}` + " " + `${text.lastnm}`}</>,
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
        multiple: 1,
      },
    },
    {
      title: "Organisation",
      dataIndex: "org_name",
      fixed: "left",
      width: 200,
      sorter: {
        // compare: (a, b) => a.org_name - b.org_name,
        compare: (a, b) => a?.org_name?.localeCompare(b?.org_name),
        multiple: 5,
      },
    },
    {
      title: "Organisation Type",
      //dataIndex: "org_size",
      width: 200,
      sorter: {
        // compare: (a, b) => a.org_size - b.org_size,
        compare: (a, b) => a?.org_size?.localeCompare(b?.org_size),
        multiple: 6,
      },
      render: (text) => <>{text.org_size === null || text.org_size === "" || text.org_size === "nan" ? " - " : text.org_size}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
      //fixed: "left",
      width: 200,
      sorter: {
        // compare: (a, b) => a.name - b.name,
        compare: (a, b) => a?.firstnm?.localeCompare(b?.firstnm),
        multiple: 1,
      },
    },
    {
      title: "Address",
      //dataIndex: "org_address",
      width: 150,
      sorter: {
        // compare: (a, b) => a.org_address - b.org_address,
        compare: (a, b) => a?.org_address?.localeCompare(b?.org_address),
        multiple: 2,
      },
      render: (text) => <>{text.org_address === null || text.org_address === "" || text.org_address === "nan" ? " - " : text.org_address}</>,
    },
    {
      title: "State",
      dataIndex: "org_state",
      width: 150,
      sorter: {
        // compare: (a, b) => a.org_state - b.org_state,
        compare: (a, b) => a?.org_state?.localeCompare(b?.org_state),
        multiple: 3,
      },
    },
    {
      title: "District",
      //dataIndex: "org_district",
      width: 150,
      sorter: {
        // compare: (a, b) => a.org_district - b.org_district,
        compare: (a, b) => a?.org_district?.localeCompare(b?.org_district),
        multiple: 4,
      },
      render: (text) => <>{text.org_district === null || text.org_district === "" || text.org_district === "nan" ? " - " : text.org_district}</>,
    },
    {
      title: "Sector",
      //dataIndex: "industry",
      width: 150,
      sorter: {
        compare: (a, b) => a?.industry?.localeCompare(b?.industry),
        multiple: 7,
      },
      render: (text) => <>{text.industry === null || text.industry === "" || text.industry === "nan" ? " - " : text.industry}</>,
    },
    /*
    {
      title: "Sector",
      dataIndex: "org_sector",
      width: 150,
      sorter: {
        // compare: (a, b) => a.org_sector - b.sector,
        compare: (a, b) => a?.org_sector?.localeCompare(b?.org_sector),
        multiple: 8,
      },
      render: (text) => <>{text === null ? " - " : text}</>,
    },
    */
    {
      title: "Last Active",
      dataIndex: "logindt",
      width: 150,
      sorter: {
        // compare: (a, b) => a.logindt - b.logindt,
        compare: (a, b) => moment(a.logindt, "DD-MM-YYYY") - moment(b.logindt, "DD-MM-YYYY"),
        multiple: 9,
      },
      render: (text) => <>{text === null ? " - " : moment(text).format("DD-MM-YYYY")}</>,
    },
    {
      title: "Phone Number",
      dataIndex: "mobnum",
      width: 150,
      sorter: {
        compare: (a, b) => a.mobnum - b.mobnum,
        multiple: 10,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
      sorter: {
        // compare: (a, b) => a.email - b.email,
        compare: (a, b) => a?.email?.localeCompare(b?.email),
        multiple: 11,
      },
    },

    {
      title: "Status",

      key: "6",
      width: 150,
      sorter: {
        compare: (a, b) => a.job_status - b.job_status,
        multiple: 7,
      },
      render: (text, record) => (
   
        <div
          className="dropdown"
          style={{
            width: "100%",
            height: "30px",
            // border: "1px solid ",
            borderRadius: "15px",
            backgroundColor: text.job_status ? "#BDFDC8" : "#EFD6FF",
            cursor: "pointer",
          }}
        >
          <p
            style={{
              // padding: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              padding: "5px 0px 5px",
            }}
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            {text.job_status ? "Active" : "Deactivate"}
          </p>
          <div className="dropdown-menu">
            <a
              className="dropdown-item"
              href="#"
              onClick={() => (text.job_status ? deActiveFun(text) : ActiveFun(text))}
            >
              {/* <i
                className={
                  text.job_status
                    ? "fa fa-dot-circle-o text-danger"
                    : "fa fa-dot-circle-o text-success"
                }
              /> */}
              {text.job_status ? "Deactivate" : "Active"}
            </a>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      sorter: false,
      // fixed: "right",
      width: 100,
      render: (admindata) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === '1') {
                  Seteditadmin(true);
                  Seteditdata(admindata);
                }
                else {
                  setResetPassword(true);
                  Seteditdata(admindata);
                }
              }
            }}>

            <a>
              <Icon icon="ion:ellipsis-vertical" color="black" />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];

  const searchFunc = () => {
    setSearchSet(false);
    const newData = searchData.filter((ele) => {
      return newName?.toLocaleLowerCase() === ""
        ? ele
        : ele.firstnm?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
          newName?.toLocaleLowerCase() === ""
          ? ele
          : ele.org_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
            newName?.toLocaleLowerCase() === ""
            ? ele
            : String(ele.mobnum?.toLocaleLowerCase()).includes(String(newName.toLocaleLowerCase()));
    });
    setNewSearchData(newData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;



  const getFileName = () => {
    let d = new Date();
    let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

    console.log("getCurrentDate : ", dformat);
    const file = "mis_data_" + dformat + ".csv";
    setFileName(file);
    // return "mis_data_" + dformat + ".txt";
  };

  const deActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
     
        deactivate_id: data.key,

        active: "false",
        deactivatedby: registerID,
      })
      .then((res) => {
        console.log("res deactivate", res);
        Swal.fire({
          icon: "success",
          title: "Job provider deactivated",
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        // fetchadminList();
        fetchOrgList();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data["User Message"]
            ? err.response.data["User Message"]
            : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const ActiveFun = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/deactivateuser`, {
        // deactivated_by: roleId,
        // registration_id:data.registration_id
        // "deactivate_id":data.key,
        // "role_id":roleId,
        // "partner-association":"samarth",
        // "deactivatedby": registerID
        deactivate_id: data.key,
        // deactivate_role: roleId,
        // "partner-association": "samarth",
        active: "true",
        deactivatedby: registerID,
      })
      .then((res) => {
        console.log("res deactivate", res);
        Swal.fire({
          icon: "success",
          title: "Job provider activated",
          //text: res.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        // fetchadminList();
        fetchOrgList();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data["User Message"]
            ? err.response.data["User Message"]
            : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  // const fetchadminList = () => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobprovider/auth/jobprovider/fetch/details`
  //     )
  //     .then((res) => {
  //       console.log(res.data["job_provider_details"]);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  const fetchOrgList = () => {
    const params = {
      // partner: "",
      // type: "dropdown",
      type: "detail",
    };
    // const headers = {
    //   "access-control-allow-origin": "*",
    //   "Content-type": "application/json; charset=UTF-8",
    // };
    setLoading(true);
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?${paramString}`,
        {
          params: {
            // limit: 10,
            onboarded: 1,

            limit: pageSize,
            offset: paginationState.page - 1,
            search_data: name,
            created_by: sessionStorage.getItem("register_id")
      ? sessionStorage.getItem("register_id")
      : "",
          },
        }
        // headers
      )
      .then((res) => {
        console.log("org list", res.data["Organisation_Detail"]);
        setLoading(false);
        // Setadmindata(res.data["Organisation_Detail"]);
        // setSearchData(res.data["Organisation_Detail"]);
        Setadmindata(
          res.data["Organisation_Detail"]?.map((item, id) => ({
            key: item.registration_id,
            // organization: item.org_name ,
            // location: item.org_address,
            name:
              `${item?.firstnm[0].toUpperCase() + item?.firstnm.slice(1)}` +
              " " + 
              `${ item?.lastnm[0].toUpperCase()  + item?.lastnm.slice(1)}`,
            // location: `${item.org_state} ${","} ${item.org_district}`,
            org_logo: item.org_logo,
            org_address: item.org_address,
            org_state: item.org_state,
            org_district: item.org_district,
            org_size: item.org_size,
            industry: item.org_type,
            org_sector: item.org_sector,
            logindt: item.logindt,
            job_status: item.is_active,
            firstnm: item.firstnm,
            lastnm: item.lastnm,
            security_ans: item.security_ans,
            security_ques: item.security_ques,
            email: item.email,
            mobnum: item?.mobnum?.length === 12 ? item?.mobnum?.substring(2) : item?.mobnum,
            org_pincode: item.org_pincode,
            org_address: item.org_address,
            org_cin_num: item.org_cin_num,
            org_district: item.org_district,
            org_state: item.org_state,
            org_type: item.org_type,
            org_name: item.org_name,
            date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
            role_access: item.role_access,
            data_access: item.data_access,
            company_info: item.company_info,
            crm_optin: item.crm_optin,
          }))
        );
        setSearchData(
          res.data["Organisation_Detail"]?.map((item, id) => ({
            key: item.registration_id,
            // organization: item.org_name ,
            // location: item.org_address,
            name:
              `${item?.firstnm[0].toUpperCase() + item?.firstnm.slice(1)}` +
              " " + 
              `${item?.lastnm[0].toUpperCase() + item?.lastnm.slice(1)}`,
            org_logo: item.org_logo,
            org_address: item.org_address,
            org_state: item.org_state,
            org_district: item.org_district,
            org_size: item.org_size,
            industry: item.org_type,
            org_sector: item.org_sector,
            logindt: item.logindt,
            job_status: item.is_active,
            firstnm: item.firstnm,
            lastnm: item.lastnm,
            security_ans: item.security_ans,
            security_ques: item.security_ques,
            email: item.email,
            mobnum: item?.mobnum?.length === 12 ? item?.mobnum?.substring(2) : item?.mobnum,
            org_pincode: item.org_pincode,
            org_address: item.org_address,
            org_cin_num: item.org_cin_num,
            org_district: item.org_district,
            org_state: item.org_state,
            org_type: item.org_type,
            org_name: item.org_name,
            date_of_creation: moment(item.createddt).utc().format("DD-MM-YYYY"),
            role_access: item.role_access,
            data_access: item.data_access,
            company_info: item.company_info,
            crm_optin: item.crm_optin,
          }))
        );
        setPaginationState({ ...paginationState, dataSize: res.data["count"] });
      })
      .catch((err) => {
        console.log(err);
        //setLoading(false)
      });
  };

  console.log(admindata, "pqr");
   const OnboardFunc = async (data) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobprovider/dashboard/change_jp_onboarding`,
        {
          jp_id: data.key,
          onboard: 1,
        }
      )
      .then((res) => {
        console.log("res deactivate", res);
        Swal.fire({
          icon: "success",
          title: "Job Provider Onboarded Successfully",
          //text: res.data["Developer Message"],
          showConfirmButton: false,
          timer: 3000,
        });
        fetchRequestList();
      })
      .catch((err) => {
        console.log("onboared err:", err);
        Swal.fire({
          icon: "error",
          title: err?.response?.data["Developer Message"]
            ? err.response.data["Developer Message"]
            : "Something went wrong",
          text: err?.response?.data["User Message"]
            ? err.response.data["User Message"]
            : "Something went wrong",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };


  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchJobProviderKPIs = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobprovider/dashboard/fetch/kpi`, {
        params: {
         
          created_by: sessionStorage.getItem("register_id")
    ? sessionStorage.getItem("register_id")
    : "",
        },
      } )
      .then((res) => {
        setKPIObj({
          job_seeker_on_hold: res.data["job_seeker_on_hold"],
          job_seekers_hired: res.data["job_seekers_hired"],
          jobs_created: res.data["jobs_created"],
          registered_providers: res.data["registered_providers"],
          jobseeker_pipeline: res.data["jobseeker_pipeline"],
          total_jobseekers: res.data["total_jobseekers"],
          js_shortlisted: res.data["js_shortlisted"],
        });
        // setKPIObj({ ...KPIObj, job_seekers_hired: res.data["job_seekers_hired"] });
        // setKPIObj({ ...KPIObj, jobs_created: res.data["jobs_created"] });
        // setKPIObj({ ...KPIObj, registered_providers: res.data["registered_providers"] });
      })
      .catch((err) => {
        console.log("error kpi", err);
      });
  };
  const handleSearch = () => {
    const newData = admindata.filter((ele) => {
      return newName?.toLocaleLowerCase() === ""
        ? ele
        : ele.name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
          newName?.toLocaleLowerCase() === ""
          ? ele
          : ele.org_name?.toLocaleLowerCase().includes(newName.toLocaleLowerCase()) ||
            newName.toLocaleLowerCase() === ""
            ? ele
            : String(ele.mobnum).toLocaleLowerCase().includes(newName.toLocaleLowerCase());
    });
    // .filter((y) => y.department == (department1 == "" ? y.department : department1))
    // .filter((z) => z.employement_type == (type1 == "" ? z.employement_type : type1));
    // setSearchData(newData);
    // setSearchData(newData);
    onboard ? setSearchData(newData) : setNewRequestData(newData);

  };
  useEffect(() => {
    handleSearch();
  }, [newName]);
  useEffect(() => {
    // if (autopass) {
    //   fatchAutopass();
    // }
    const resDATA = sessionStorage.getItem("register_id");
    const creatorRole = sessionStorage.getItem("creator_role");
    console.log(resDATA);
    console.log(creatorRole);
    if (resDATA) {
      setRegisterID(() => resDATA);
    }
    if (creatorRole) {
      setRoleId(creatorRole);
    }
    console.log("createdby :", registerID);
    console.log("creator_role :", roleId);
  }, []);

  useEffect(() => {
    // fetchadminList();
    fetchJobProviderKPIs();
    fetchOrgList();
    fetchStateList();
  }, []);
  useEffect(() => {
    onboard && fetchOrgList();
    !onboard && fetchRequestList();
  }, [onboard]);
  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);
  console.log("KPIobj ", KPIObj);
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <SoftBox mt={0} sx={{ overflowX: "hidden" }}>
          {/* <Card> */}
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start">
            <SoftBox lineHeight={1} mb={3}>
              <SoftTypography fontWeight="medium" fontSize="24px" lineHeight="30px">
              {translate('jobProvider')}       
              </SoftTypography>
            </SoftBox>
        
            <SoftBox
              sx={{
                height: "40px",
                backgroundColor: "#FFF",
                borderRadius: "2px",
                cursor: "pointer",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
              width="40px"
              onClick={() => {
                // fetchOrgList();
                fetchJobProviderKPIs();
                setOnboard(true);

                // window.location.reload()
              }}
            >
              <SoftBox>
                <img src={refreshBtn} />
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalJP')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.registered_providers === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.registered_providers
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalJobs')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.jobs_created === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.jobs_created
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalseekers')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.total_jobseekers === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.total_jobseekers
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={2}
            gap={2}
          >
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('totalseekershortlisted')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.js_shortlisted === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.js_shortlisted
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('seekerhired')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.job_seekers_hired === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.job_seekers_hired
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox
              width="33.33%"
              display=""
              sx={{
                height: "135px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <SoftBox display="flex" justifyContent="" mt={3}>
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="small"
                  fontSize="14px"
                  lineHeight="18px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
              {translate('seekerpipeline')}       
                </SoftTypography>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={3.5}
              // mb={1}
              >
                <SoftTypography
                  fontStyle="normal"
                  fontWeight="medium"
                  fontSize="28px"
                  lineHeight="35px"
                  ml={2}
                  sx={{ color: "#000" }}
                >
                  {/* {"3500 "} */}
                  {KPIObj.jobseeker_pipeline === "" ? (
                    <FadeLoader color="#B3B6B7" height={12} width={5} radius={2} margin={2} />
                  ) : (
                    KPIObj.jobseeker_pipeline
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
       
          <SoftBox alignItems="flex-start" position="relative">
            <SoftBox>
             
              <SoftBox display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
                <SoftBox width={{ lg: "500px", md: "400px" }}>
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    gap="10px"
                    justifyContent="space-between"
                  >
                    <SoftInput
                      isClearable
                      // width={{ lg: "1000px", md: "600px" }}
                      // width="1000px"
                      id="search-bar"
                      className="text"
                      // onInput={(e) => {
                      //   setSearchQuery(e.target.value);
                      // }}
                      // height="40px"
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      variant="outlined"
                      placeholder="Search by organisation name, name and phone number"
                      size="small"
                    />
                  
                  </SoftBox>
                </SoftBox>

             
                <SoftBox
                  display="flex"
                  flexDirection="row"
                  gap="10px"
                  justifyContent="space-between"
                >
                     <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: !onboard ? "#3B5092" : "#fff",
                      border: !onboard ? "none" : "1px solid #3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100px"
                    onClick={() => setOnboard(false)}
                  >
                    <SoftBox sx={{ color: !onboard ? "#FFF" : "#3B5092", fontSize: "14px" }}>
                    {translate('requested')}       
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: onboard ? "#3B5092" : "#fff",
                      border: onboard ? "none" : "1px solid #3B5092",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100px"
                    onClick={() => setOnboard(true)}
                  >
                    <SoftBox sx={{ color: onboard ? "#FFF" : "#3B5092", fontSize: "14px" }}>
                    {translate('onboarded')}       
                    </SoftBox>
                  </SoftBox>
                

                  <Tooltip key="1" title={tablerow.length === 0 ? "Download All" : "Download"} placement="top">
                    <SoftBox
                      sx={{
                        height: "33px",
                        backgroundColor: "#000000",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                      width="40px"
                      onClick={() => (
                        setShow(!show),
                        // setFileName(() => {
                        //   getFileName();
                        // })
                        getFileName()
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="46"
                        height="34"
                        viewBox="0 0 46 34"
                        fill="none"
                      >
                        <rect width="45.8992" height="33.0097" rx="5" fill="#111727" />
                        <path
                          d="M20.0547 20.1191L22.9438 23.0082L25.8328 20.1191"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22.9531 16.5098V23.0102"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M29.3651 20.9074C29.9931 20.4658 30.464 19.8357 30.7095 19.1084C30.955 18.381 30.9624 17.5944 30.7305 16.8626C30.4986 16.1308 30.0396 15.4919 29.4201 15.0387C28.8005 14.5854 28.0526 14.3414 27.285 14.342H26.3749C26.1577 13.4954 25.7513 12.7091 25.1862 12.0424C24.6211 11.3756 23.9121 10.8457 23.1126 10.4926C22.3131 10.1395 21.4439 9.97232 20.5705 10.0037C19.697 10.0351 18.8421 10.2643 18.07 10.6739C17.2979 11.0836 16.6288 11.663 16.1131 12.3687C15.5974 13.0743 15.2485 13.8877 15.0926 14.7477C14.9368 15.6078 14.978 16.4919 15.2133 17.3337C15.4486 18.1754 15.8718 18.9528 16.451 19.6073"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </SoftBox>
                  </Tooltip>
                

                  <SoftBox
                    sx={{
                      height: "33px",
                      backgroundColor: "#3B5092",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // p={{ lg: "10px ", sm: "10px", xs: "10px" }}
                    width="40px"
                    onClick={() => navigate("/jobprovider-registration")}
                  >
                    <SoftBox sx={{ color: "#FFF" }}>+</SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>

              
              {onboard ? (
                <Table
              
                  loading={loading}
                  rowSelection={rowSelection}
                  columns={colName}
                  dataSource={searchData}
                
                  pagination={{
   
                    pageSizeOptions: ["10", "20", "50", "100", "500"],
                    total: paginationState.dataSize,
                    showSizeChanger: true,
                    // onShowSizeChange: { onShowSizeChange },
                    onShowSizeChange: handlePageSizeChange,
                    current: paginationState.page,
                    onChange: (page, pageSize) =>
                      setPaginationState({ ...paginationState, page: page }),
                    showTotal: (total) => (
                      <span>
                        Showing <strong>{total}</strong> results of {total}
                      </span>
                    ),
                  }}
                  scroll={searchData.length <= 2 ? { x: 1300, y: 400 } : { x: 1300, y: 400 }}
                />
              ) : (
                <Table
        
                  loading={loading}
                  rowSelection={rowSelection}
                  columns={colName1}
                  dataSource={newRequestData}
        
                  pagination={{
     
                    pageSizeOptions: ["10", "20", "50", "100", "500"],
                    total: paginationState.dataSize,
                    showSizeChanger: true,
                    // onShowSizeChange: { onShowSizeChange },
                    onShowSizeChange: handlePageSizeChange,
                    current: paginationState.page,
                    onChange: (page, pageSize) =>
                      setPaginationState({ ...paginationState, page: page }),
                    showTotal: (total) => (
                      <span>
                        Showing <strong>{total}</strong> results of {total}
                      </span>
                    ),
                  }}
                  scroll={searchData.length <= 2 ? { x: 1300, y: 400 } : { x: 1300, y: 400 }}
                />
              )}
              <AdminEdit
                editadmin={editadmin}
                Seteditadmin={Seteditadmin}
                editdata={editdata}
                // fetchadminList={fetchadminList}
                fetchadminList={fetchOrgList}
              />

              {/* reset password  */}
              <PasswordResetJobProvider
                editadmin={resetPassword}
                Seteditadmin={setResetPassword}
                editdata={editdata}
              />
            </SoftBox>
          
          </SoftBox>
          {/* </Card> */}
        </SoftBox>
        {/* modal for export */}
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
        >
          <Modal.Body>
            <SoftBox sx={{ m: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className="HeaderCSV">Select columns to export</p>

                <button className="CloseBtn" onClick={() => setShow(false)}>
                  <span>
                    <CloseIcon fontSize="small" />
                  </span>
                </button>
              </Box>

              {colHeaders.map((ele, index) => (
                <>
                  <ul key={index} className="UlCSV">
                    <input
                      type="checkbox"
                      checked={ele.isExportable}
                      onChange={(event) =>
                        handleFormChange12(event, index, colHeaders, setColHeaders)
                      }
                    />
                    <span className="ListCSV">&nbsp;{ele.label}</span>
                  </ul>
                </>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="login-btn4" onClick={() => setShow(false)}>
                  <span className="login-btn4-Text">Cancel</span>
                </button>

                <button className="login-btn3">
            
                  <CSVLink
                    data={tablerow.length === 0 ? searchData : tablerow}
                    // filename={"my-file.csv"}
                    filename={fileName}
                    headers={colHeaders.filter((ele) => ele.isExportable === true)}
                  >
                    <span className="login-btn3-Text">Export</span>
                  </CSVLink>
                </button>
              </Box>
            </SoftBox>
          </Modal.Body>
        </Modal>
        {/* modal for export */}
      </DashboardLayout>
    </>
  );
};

export default Jobprovider;
