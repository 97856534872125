// const actions = [
//   {
//     label: "Whatsapp Yet to Send",
//     value: "Whatsapp Yet to Send",
//   },
//   {
//     label: "",
//     value: "",
//   },
// ];

export const broadcast_statusList = [
  { label: "Approached", value: "success" },
  { label: "Not Yet Approached", value: "Not Yet Approached" },
  { label: "Failed", value: "fail" },
];

export const WA_statusList = [
  { label: "Yet to start", value: "Yet to start" },
  { label: "In progress", value: "In progress" },
  { label: "Interested", value: "Interested" },
  { label: "Not interested", value: "Not interested" },
];

export const responseList = [
  { label: "Interested", value: "Interested" },
  { label: "Not Interested", value: "Not Interested" },
  { label: "Read", value: "Read" },
  { label: "Delivered", value: "Delivered" },
];

export const callStatus = [
  {
    label: "Not Answered/Ringing",
    value: "Not Answered/Ringing",
  },
  {
    label: "Call Disconnected without conversation",
    value: "Call Disconnected without conversation",
  },
  {
    label: "Wrong Number",
    value: "Wrong Number",
  },
  {
    label: "Switched Off",
    value: "Switched Off",
  },
  {
    label: "Answered",
    value: "Answered",
  },
];

export const JobStatus = [
  {
    label: "Actively looking for job",
    value: "Actively looking for job",
  },
  {
    label: "Can consider a new job ",
    value: "Can consider a new job ",
  },
  {
    label: "Not looking for job now",
    value: "Not looking for job now",
  },
  {
    label: "Not looking for job in the long run",
    value: "Not looking for job in the long run",
  },
];

export const pwdOption = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const readyResume = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Prepared by Samarth",
    value: "Prepared by Samarth",
  },
];

export const nextStepStatus = [
  {
    label: "Placeable Now",
    value: "Placeable Now",
  },
  {
    label: "Not Placeable",
    value: "Not Placeable",
  },
  {
    label: "Shortlisted for future",
    value: "Shortlisted for future",
  },
];

export const statusList = [
  //WA status
  // { label: "Whatsapp Verification in Progress", value: "Whatsapp Verification in Progress" },
  // { label: "Whatsapp Verified", value: "Whatsapp Verified" },
  // { label: "Read", value: "Read" },
  // { label: "deliverd", value: "deliverd" },
  { label: "Bot yet to engage", value: "Bot yet to engage" },
  { label: "Bot successfully engaged", value: "Bot successfully engaged" },
  // call status
  {
    label: "Not Answered/Ringing",
    value: "Not Answered/Ringing",
  },
  {
    label: "Call Disconnected without conversation",
    value: "Call Disconnected without conversation",
  },
  {
    label: "Wrong Number",
    value: "Wrong Number",
  },
  {
    label: "Switched Off",
    value: "Switched Off",
  },
  {
    label: "Answered",
    value: "Answered",
  },
  // job status
  {
    label: "Actively looking for job",
    value: "Actively looking for job",
  },
  {
    label: "Can consider a new job ",
    value: "Can consider a new job ",
  },
  {
    label: "Not looking for job now",
    value: "Not looking for job now",
  },
  {
    label: "Not looking for job in the long run",
    value: "Not looking for job in the long run",
  },
  // ready resume
  // next step status
  {
    label: "Placeable Now",
    value: "Placeable Now",
  },
  {
    label: "Not Placeable",
    value: "Not Placeable",
  },
  {
    label: "Shortlisted for future",
    value: "Shortlisted for future",
  },
  // jpStatus
  {
    label: "CV Shared with Client",
    value: "CV Shared with Client",
  },
  {
    label: "CV Shortlisted by Client",
    value: "CV Shortlisted by Client",
  },
  {
    label: "Interview Scheduled by Client",
    value: "Interview Scheduled by Client",
  },
  {
    label: "Client Rejected by Candidate",
    value: "Client Rejected by Candidate",
  },
  {
    label: "CV Rejected by Client",
    value: "CV Rejected by Client",
  },
  // interviewStatus
  {
    label: "First Round of Interview done ",
    value: "First Round of Interview done ",
  },
  {
    label: "Final Rounds of Interview done",
    value: "Final Rounds of Interview done",
  },
  {
    label: "Rejected in First Round ",
    value: "Rejected in First Round ",
  },
  {
    label: "Rejected in Later Rounds",
    value: "Rejected in Later Rounds ",
  },
  {
    label: "Rejected by Candidate",
    value: "Rejected by Candidate",
  },
  {
    label: "Shortlisted",
    value: "Shortlisted",
  },
  // selectedStatus
  {
    label: "Selected by Client",
    value: "Selected by Client",
  },
  {
    label: "Rejected by Client",
    value: "Rejected by Client",
  },
  {
    label: "Rejected by Candidate",
    value: "Rejected by Candidate",
  },
  {
    label: "Rejected in Later Rounds",
    value: "Rejected in Later Rounds ",
  },
  {
    label: "Accepted by Candidate",
    value: "Accepted by Candidate",
  },
  // joiningStatus
  {
    label: "Joining Date Awaited",
    value: "Joining Date Awaited",
  },
  {
    label: "Joining Date Given ",
    value: "Joining Date Given ",
  },
  {
    label: "Joining Done",
    value: "Joining Done",
  },
  {
    label: "Joining Rejected by Candidate",
    value: "Joining Rejected by Candidate",
  },
  {
    label: "Joining Rejected by Candidate",
    value: "Joining Rejected by Candidate",
  },
  // retentionStatus
  {
    label: "Positive Feedback by Client and Employee",
    value: "Positive Feedback by Client and Employee",
  },
  {
    label: "Neutral Feedback by Client and Employee",
    value: "Neutral Feedback by Client and Employee",
  },
  {
    label: "Negative Feedback by Client",
    value: "Negative Feedback by Client",
  },
  {
    label: "Negative Feedback by Employee ",
    value: "Negative Feedback by Employee ",
  },
  {
    label: "Status Unclear",
    value: "Status Unclear",
  },
];

export const jpStatus = [
  {
    label: "CV Shared with Client",
    value: "CV Shared with Client",
  },
  {
    label: "CV Shortlisted by Client",
    value: "CV Shortlisted by Client",
  },
  {
    label: "Interview Scheduled by Client",
    value: "Interview Scheduled by Client",
  },
  {
    label: "Client Rejected by Candidate",
    value: "Client Rejected by Candidate",
  },
  {
    label: "CV Rejected by Client",
    value: "CV Rejected by Client",
  },
];

export const interviewStatus = [
  {
    label: "First Round of Interview done ",
    value: "First Round of Interview done ",
  },
  {
    label: "Final Rounds of Interview done",
    value: "Final Rounds of Interview done",
  },
  {
    label: "Rejected in First Round ",
    value: "Rejected in First Round ",
  },
  {
    label: "Rejected in Later Rounds",
    value: "Rejected in Later Rounds ",
  },
  {
    label: "Rejected by Candidate",
    value: "Rejected by Candidate",
  },
  {
    label: "Shortlisted",
    value: "Shortlisted",
  },
];

export const selectedStatus = [
  {
    label: "Selected by Client",
    value: "Selected by Client",
  },
  {
    label: "Rejected by Client",
    value: "Rejected by Client",
  },
  {
    label: "Rejected by Candidate",
    value: "Rejected by Candidate",
  },
  {
    label: "Rejected in Later Rounds",
    value: "Rejected in Later Rounds ",
  },
  {
    label: "Accepted by Candidate",
    value: "Accepted by Candidate",
  },
];

export const joiningStatus = [
  {
    label: "Joining Date Awaited",
    value: "Joining Date Awaited",
  },
  {
    label: "Joining Date Given ",
    value: "Joining Date Given ",
  },
  {
    label: "Joining Done",
    value: "Joining Done",
  },
  {
    label: "Joining Rejected by Candidate",
    value: "Joining Rejected by Candidate",
  },
  {
    label: "Joining Rejected by Candidate",
    value: "Joining Rejected by Candidate",
  },
];

export const retentionStatus = [
  {
    label: "Positive Feedback by Client and Employee",
    value: "Positive Feedback by Client and Employee",
  },
  {
    label: "Neutral Feedback by Client and Employee",
    value: "Neutral Feedback by Client and Employee",
  },
  {
    label: "Negative Feedback by Client",
    value: "Negative Feedback by Client",
  },
  {
    label: "Negative Feedback by Employee ",
    value: "Negative Feedback by Employee ",
  },
  {
    label: "Status Unclear",
    value: "Status Unclear",
  },
];
